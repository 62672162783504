import { store } from 'store';
import { PermissionEnum } from 'types/dto/auth-service';
import { Action } from 'types/token';

export const can = (action: Action, item: PermissionEnum) => {
  const permissionsTree = store.getState().me.data?.decoded?.permissions;

  try {
    if (!permissionsTree) {
      throw new Error(`permissions not found`);
    }

    const permissions = permissionsTree[item];

    if (!permissions) {
      throw new Error(`item '${item}' not found`);
    }

    const value = permissions[action];

    if (typeof value !== 'boolean') {
      throw new Error(`action '${action}' not found`);
    }

    return value;
  } catch (error) {
    console.error(error.message);

    return false;
  }
};
