import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { FieldErrors } from 'react-hook-form';

import { AnyObject } from 'types';
import { Struct } from 'utils/struct';

export const getRequiredFields = async <T extends AnyObject>(schema: Struct<T, any>, values: T) => {
  const { errors } = await superstructResolver(schema)(values, undefined, {
    fields: {},
    shouldUseNativeValidation: false,
  });

  return errors as FieldErrors<T>;
};

export const useRequired = <T extends AnyObject>(schema: Struct<T, any>, values: T) => {
  const [requiredFields, setRequiredFields] = useState<FieldErrors<T>>({});

  useAsyncEffect(async () => {
    const errors = await getRequiredFields(schema, (values ?? {}) as T);

    setRequiredFields(errors);
  }, [JSON.stringify(values)]);

  return { requiredFields };
};
