import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DashboardExtend from 'screens/DashboardExtend/DashboardExtend';
import DashboardMain from 'components/Dashboard';
import DashboardHRManager from 'screens/DashboardHRManager/DashboardHRManager';
import { ROUTES } from 'constants/routes';
import PrivateRoute from 'routes/PrivateRoute';
import { getUserRole } from 'roles/userCan';
import Page404 from 'components/Page404';
import { RoleEnum } from 'types/dto/auth-service';
import DashboardClient from 'screens/DashboardClient/lazy';
import DashboardSeller from 'screens/DashboardSeller/lazy';
import DashboardSettlement from 'screens/DashboardSettlement/DashboardSettlement';

const redirectMap = {
  default: ROUTES.DASHBOARD.EXTEND,
  [RoleEnum.ROLE_HR_MANAGER]: ROUTES.DASHBOARD.MEDICINE,
  [RoleEnum.ROLE_CLIENT]: ROUTES.DASHBOARD.CLIENT,
  [RoleEnum.ROLE_PARTNER_SELLER]: ROUTES.DASHBOARD.SELLER,
};

const NewClientDashboard = lazy(() => import('screens/NewClientDashboard/NewClientDashboard'));

const Dashboard = () => {
  const userRole = getUserRole();

  return (
    <Switch>
      <PrivateRoute exact role={['DASHBOARD', 'READ']} path={ROUTES.DASHBOARD.CLIENT} component={NewClientDashboard} />
      <PrivateRoute exact role={['DASHBOARD', 'READ']} path={ROUTES.DASHBOARD.SELLER} component={DashboardSeller} />
      <PrivateRoute
        exact
        role={['DASHBOARD', 'MEDICINE']}
        path={ROUTES.DASHBOARD.MEDICINE}
        component={DashboardHRManager}
      />
      <PrivateRoute exact role={['DASHBOARD', 'EXTEND']} path={ROUTES.DASHBOARD.EXTEND} component={DashboardExtend} />
      <PrivateRoute exact role={['DASHBOARD', 'ANALYTICS']} path={ROUTES.DASHBOARD.MAIN} component={DashboardMain} />
      <PrivateRoute
        exact
        role={['DASHBOARD', 'SETTLEMENT']}
        path={ROUTES.DASHBOARD.SETTLEMENT}
        component={DashboardSettlement}
      />
      <Route
        exact
        path={ROUTES.DASHBOARD.ROOT}
        component={() => (
          <Redirect to={userRole ? redirectMap[userRole] ?? redirectMap.default : redirectMap.default} />
        )}
      />

      <Route component={Page404} />
    </Switch>
  );
};

export default Dashboard;
