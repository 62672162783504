import { rules } from './rules';
import jwt from 'jsonwebtoken';
import { TokenDto } from 'types/token';
import { RoleEnum } from 'types/dto/auth-service';

export const getUserRole = () => {
  const token = localStorage.getItem('accessToken');

  const user = jwt.decode(token!) as TokenDto;

  if (user && user.AUTHORITIES_CLAIM) {
    return user.AUTHORITIES_CLAIM;
  }
};

export const getUserRoleNext = () => {
  const tokenRaw = localStorage.getItem('accessToken');

  if (!tokenRaw) {
    // throw new Error('token not found');
    return;
  }

  const tokenDecoded = jwt.decode(tokenRaw);

  if (!tokenDecoded || typeof tokenDecoded === 'string') {
    // throw new Error('token invalid');
    return;
  }

  if (!tokenDecoded.AUTHORITIES_CLAIM) {
    // throw new Error('role not found');
    return;
  }

  return tokenDecoded.AUTHORITIES_CLAIM as RoleEnum;
};

export const checkPermissions = (page: string, action: string, isHideForClient?: boolean) => {
  const userRole = getUserRole();
  if (!userRole) return false;

  if (userRole === 'ROLE_CLIENT' && isHideForClient) return false;
  const permissions = rules[userRole];
  return permissions && permissions[page.toUpperCase()] && permissions[page.toUpperCase()][action.toUpperCase()];
};

export const roleApproved = () => {
  const userRole = getUserRole();
  return userRole && !!rules[userRole];
};

export const isShowComponent = () => {
  const userRole = getUserRole();

  if (!userRole) {
    return false;
  }

  return [
    RoleEnum.ROLE_ADMIN,
    RoleEnum.ROLE_INSURANCE_ADMIN,
    RoleEnum.ROLE_INSURANCE_MANAGER,
    RoleEnum.ROLE_INSURANCE_CLAIM_MANAGER,
    RoleEnum.ROLE_PARTNER_ADMIN,
    RoleEnum.ROLE_PARTNER_MANAGER,
    RoleEnum.ROLE_HR_MANAGER,
  ].includes(userRole);
};
