import dayjs from 'dayjs';
import { t } from 'i18next';
import { LabelInValue } from 'types';
import { object, Infer, optional, number, date, string, nullable, refiner, customObject } from 'utils/struct';

export const schema = object({
  actDate: refiner(nullable(date()), (value, ctx) => {
    const { dateTo } = ctx.branch[0] as Values;

    if (!value) {
      return t('schema.required');
    }

    if (dateTo) {
      if (dayjs(value).isBefore(dayjs(dateTo).startOf('day'))) {
        return 'cannot be less than dateTo';
      }

      return true;
    }

    return true;
  }),
  partnerId: refiner(nullable(customObject<LabelInValue<number>>()), (value) => {
    if (!value) {
      return t('schema.required');
    }

    return true;
  }),
  agentContractId: refiner(nullable(customObject<LabelInValue<number>>()), (value) => {
    if (!value) {
      return t('schema.required');
    }

    return true;
  }),
  from: nullable(date()),
  dateFrom: refiner(nullable(date()), (value, ctx) => {
    const { dateTo } = ctx.branch[0] as Values;

    if (!value) {
      return t('schema.required');
    }

    if (dayjs(value).isAfter(dayjs().endOf('day'))) {
      return t('contract_list.dateFrom', { context: 'today' });
    }

    if (dateTo) {
      if (dayjs(value).isAfter(dayjs(dateTo))) {
        return t('contract_list.dateFrom', { context: 'dateTo' });
      }

      return true;
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value, ctx) => {
    const { dateFrom } = ctx.branch[0] as Values;

    if (!value) {
      return t('schema.required');
    }

    if (dayjs(value).isAfter(dayjs().endOf('day'))) {
      return t('contract_list.dateTo', { context: 'today' });
    }

    if (dateFrom) {
      if (dayjs(value).isBefore(dayjs(dateFrom))) {
        return t('contract_list.dateTo', { context: 'dateFrom' });
      }

      return true;
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  actDate: null,
  partnerId: null,
  agentContractId: null,
  from: null,
  dateFrom: dayjs().startOf('month'),
  dateTo: dayjs(),
};
