import axios from 'axios';

export interface Currency {
  code: string;
  name: string;
  symbol: string;
}

export interface CurrencyResponse {
  currencies: Currency[];
}

export const getCurrencyData = () => {
  const params = {
    fields: 'currencies',
  };

  return axios.get<CurrencyResponse[]>(`https://restcountries.com/v2/all`, { params });
};
