import { t } from 'i18next';
import dayjs from 'dayjs';

import { ProgramCode } from 'types';
import {
  CalculatedInsuranceObjectDto,
  ChronoUnitSimplifiedDuplicate,
  ContractDto,
  RegistrationZoneGroupType,
  RelationType,
} from 'types/dto/contracts-service';
import { dateFormat } from 'utils/formatters';
import { ContractNumberFormat, Currency, InsuranceRateDto } from 'types/dto/configuration-service';
import { ValuesType } from 'utility-types';

// TODO front applicable only for OSG
export const getTitle = ({
  signDate,
  contractNumber,
  subAgreementNumber,
  relationType = RelationType.NONE,
}: {
  signDate: string | undefined;
  contractNumber: string | undefined;
  subAgreementNumber?: number;
  relationType?: RelationType;
}) => {
  const fromDate = dateFormat(dayjs(signDate));

  if ([RelationType.PROLONGATION, RelationType.COPY, RelationType.RE_REGISTRATION].includes(relationType)) {
    return t('osago.title', { context: 'contract', contractNumber, fromDate });
  }

  if (relationType === RelationType.SUB_AGREEMENT || subAgreementNumber) {
    return t('osago.title', { context: 'agreement', contractNumber, fromDate, subAgreementNumber });
  }

  if (relationType === RelationType.NONE && contractNumber) {
    return t('osago.title', { context: 'contract', contractNumber, fromDate });
  }

  return t('osago.title');
};

export type Zone = `${RegistrationZoneGroupType}/${string}`;

export const toRegistrationZone = (zone?: RegistrationZoneGroupType, city?: string) => {
  if (!zone || !city) {
    return undefined;
  }

  return `${zone}/${city}` as Zone;
};

export const fromRegistrationZone = (registrationZone: Zone) => {
  const [zone, city] = registrationZone.split('/');

  return { zone: zone as RegistrationZoneGroupType, city };
};

export const identifierMap: { [key in ProgramCode | 'default']?: string } = {
  MZ_CI_A: '101',
  MZ_CI_B: '102',
  MZ_CI_C: '103',
  MK_D_I: '091',
  default: '090',
};

export const sortRates = (rates: InsuranceRateDto[]) => {
  const sortOrder = [ChronoUnitSimplifiedDuplicate.DAYS, ChronoUnitSimplifiedDuplicate.MONTHS];
  // map for efficient lookup of sortIndex
  const ordering = {};

  sortOrder.forEach((el, i) => {
    ordering[el] = i;
  });

  return rates.sort(
    (a, b) =>
      ordering[a.duration.durationUnit] - ordering[b.duration.durationUnit] ||
      a.duration.duration - b.duration.duration,
  );
};

export const getCurrencies = (rates: InsuranceRateDto[]) =>
  Array.from(new Set(rates.map((el) => el.currency).filter(Boolean) as Currency[]));

export const validateContractNumber = ({
  value,
  contractNumberPattern,
}: {
  value: string;
  contractNumberPattern: ContractNumberFormat | undefined;
}) => {
  if (contractNumberPattern === ContractNumberFormat.GENERAL_TEN_DIGITS) {
    if (value.length !== 10) {
      return t('schema.size', { count: 10 });
    }

    return true;
  }

  if (contractNumberPattern === ContractNumberFormat.CUSTOM_FIVE_DIGITS) {
    if (value.length !== 5) {
      return t('schema.size', { count: 5 });
    }

    return true;
  }

  if (contractNumberPattern === ContractNumberFormat.WITHOUT_TEMPLATE) {
    if (!/^[^\s]+$/i.test(value)) {
      return t('vzr.contractNumberMainPart', { context: 'withoutTemplate' });
    }

    if (value.length < 8 || value.length > 35) {
      return t('schema.sizeRange', { min: 8, max: 35 });
    }

    return true;
  }

  if (contractNumberPattern === ContractNumberFormat.SPECIFIC_ALPHA_NUMERIC) {
    if (!/^[A-Z]{2}\d{6}$/.test(value)) {
      return t('vzr.contractNumberMainPart', { context: 'specificAlphaNumeric' });
    }

    return true;
  }

  if (contractNumberPattern === ContractNumberFormat.CUSTOM_NINE_DIGITS) {
    if (value.length !== 9) {
      return t('schema.size', { count: 9 });
    }

    return true;
  }

  return true;
};

// TODO front check all prev values, cuz was a bug in VZR
export const getСontractObjects = (prev: ContractDto['contractObjects'], next: CalculatedInsuranceObjectDto[]) => {
  const contractObjects: ContractDto['contractObjects'] = next.map((o, index) => {
    const contractObjectPrev = prev[index];
    const risks = contractObjectPrev.risks ?? [];

    const contractObjectNext: ValuesType<ContractDto['contractObjects']> = {
      ...contractObjectPrev,
      // TODO back incompatible interfaces
      risks: o.risks?.map((r, i) => {
        const risk = risks.length ? risks[i] : null;

        return {
          ...(risk ?? {}),
          name: r.riskName,
          insuranceAmount: r.insuranceAmount,
          exchangedInsuranceAmount: r.exchangedInsuranceAmount,
          paymentAmount: r.paymentAmount,
          paymentAmountCurrencyNotAccounted: r.paymentAmountCurrencyNotAccounted,
          franchise: r.franchise,
          franchiseValueType: r.franchiseValueType,
          currency: r.currency,
          exchangedCurrency: r.exchangedCurrency,
        };
      }),
    };

    return contractObjectNext;
  });

  return contractObjects;
};
