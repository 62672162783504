import React, { useEffect, useReducer } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Card, Col, Row, Typography } from 'antd';
import { DatePicker } from 'components/next/DatePicker/DatePicker';
import PageHeader from 'components/next/PageHeader/PageHeader';
import { Select } from 'components/next/Select/Select';
import { ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { checkPermissions } from 'roles/userCan';
import { LocationState } from 'types';
import { css } from '@emotion/css';
import { ContractImeiDuplicationsView } from 'types/dto/contracts-service';
import useAsyncEffect from 'use-async-effect';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import { PickerSharedProps } from 'rc-picker/lib/Picker';
import {
  getContractsWithDublicate,
  getInsurenceCasesInfo,
  getPartners,
} from 'store/dashboardSettlement/dashboardSettlement';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducer';
import { Dictionary } from 'lodash';
import { ImeiWhichDublicateTable } from './ImeiWhichDublicateTable/ImeiWhichDublicateTable';
import { ContractsWithTheSameImeiTable } from './ContractsWithTheSameImeiTable/ContractsWithTheSameImeiTable';
import { Group } from 'components/next/Group/Group';
import { TextField } from 'components/next/TextField/TextField';
import { currencyFormat } from 'utils/formatters';
import config from 'config';

const cssCard = css`
  &.ant-card {
    border-radius: 12px;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

const cssItem = css`
  white-space: nowrap;
`;

export interface Data {
  imei: string;
  contracts: ContractImeiDuplicationsView[];
}
interface State {
  companyId: number;
  period: Dayjs;
  allImeiDublicate: ContractImeiDuplicationsView[];
  selectedImei: string;
}

interface Item {
  label: string;
  total: number;
  quantity: number;
}

const DashboardSettlement = () => {
  const { t } = useTranslation();
  const location = useLocation<LocationState>();

  const dispatch = useDispatch();
  const partnerStored = useSelector((store: AppState) => store.me.data?.details?.partner);
  const insuranceCompany = useSelector((store: AppState) => store.me.data?.details?.insuranceCompany);
  const partners = useSelector((store: AppState) => store.dashboardSettlement.partners);
  const insuranceCaseInfo = useSelector((store: AppState) => store.dashboardSettlement.insuranceCaseInfo);
  const contractsWithDublicateImeiGroup = useSelector(
    (store: AppState) => store.dashboardSettlement.contractsWithDublicateImei.dataGroup,
  );

  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      companyId: 0,
      period: dayjs(),
      allImeiDublicate: [],
      selectedImei: '',
    },
  );

  useAsyncEffect(async () => {
    await dispatch(getPartners());
  }, []);

  useAsyncEffect(async () => {
    try {
      const partner = partners.data.find((c) => c.id === state.companyId);
      const startDate = state.period.utc().startOf('month').format();
      const endDate = state.period.utc().endOf('month').format();
      const insuranceCompanyId = insuranceCompany?.id ?? partner?.insuranceCompanyId;
      const partnerId = partnerStored?.id ?? partner?.partnerId;

      dispatch(
        getContractsWithDublicate({
          conclusionDate: `${startDate}..${endDate}`,
          insuranceCompanyId: insuranceCompanyId?.toString(),
          partnerId: partnerId?.toString(),
          page_size: -1,
        }),
      );

      dispatch(
        getInsurenceCasesInfo({
          startDate,
          endDate,
          insuranceCompanyId: insuranceCompanyId,
          partnerId: partnerId,
        }),
      );
    } catch (error) {}
  }, [state.companyId, state.period]);

  useEffect(() => {
    if (!contractsWithDublicateImeiGroup) {
      return;
    }

    const keys = Object.keys(contractsWithDublicateImeiGroup);

    setState({ allImeiDublicate: contractsWithDublicateImeiGroup[keys[0]] ?? [], selectedImei: keys[0] });
  }, [contractsWithDublicateImeiGroup]);

  const partnersOptions: DefaultOptionType[] = (
    [0, 1].includes(partners.data.length)
      ? partners.data
      : [{ id: 0, name: t('dashboard_extend.ALL') }, ...partners.data]
  ).map((el) => ({
    value: el.id ?? 0,
    label: el.name,
  }));

  const onChangeCompany: SelectProps['onChange'] = (value) => {
    setState({ companyId: Number(value) });
  };

  const onChangeDate: PickerSharedProps<Dayjs>['onChange'] = (value) => {
    if (!value) {
      return;
    }

    setState({ period: value });
  };

  const convertGroupToArr = (group: Dictionary<ContractImeiDuplicationsView[]> | null) => {
    if (!group) {
      return [];
    }

    let arr: Data[] = [];

    for (let key in group) {
      arr.push({ imei: key, contracts: group[key] });
    }

    return arr;
  };

  const emeiWitchDublicateUnique = convertGroupToArr(contractsWithDublicateImeiGroup);

  const onChangeImei = (imei: string) => {
    if (!contractsWithDublicateImeiGroup) {
      return;
    }

    setState({ allImeiDublicate: contractsWithDublicateImeiGroup[imei], selectedImei: imei });
  };

  const menu = [
    {
      path: ROUTES.DASHBOARD.EXTEND,
      breadcrumbName: 'Dashboard-1',
      visible: checkPermissions('DASHBOARD', 'EXTEND'),
    },
    {
      path: ROUTES.DASHBOARD.MAIN,
      breadcrumbName: 'Dashboard-2',
      visible: checkPermissions('DASHBOARD', 'ANALYTICS'),
    },
    {
      path: ROUTES.DASHBOARD.MEDICINE,
      breadcrumbName: 'Dashboard - Medicine',
      visible: checkPermissions('DASHBOARD', 'MEDICINE'),
    },
    {
      path: ROUTES.DASHBOARD.SETTLEMENT,
      breadcrumbName: 'Dashboard - Settlement',
      visible: checkPermissions('DASHBOARD', 'SETTLEMENT'),
    },
  ];

  const infoAboutCompany: Item[] = [
    {
      label: t('dashboard_settlement.submission_documents'),
      total: insuranceCaseInfo.data?.documentCollectionClaimStatistic?.sum ?? 0,
      quantity: insuranceCaseInfo.data?.documentCollectionClaimStatistic?.count ?? 0,
    },
    {
      label: t('dashboard_settlement.expert_evaluation'),
      total: insuranceCaseInfo.data?.paymentCalculationClaimStatistic?.sum ?? 0,
      quantity: insuranceCaseInfo.data?.paymentCalculationClaimStatistic?.count ?? 0,
    },
    {
      label: t('dashboard_settlement.payment_awaiting'),
      total: insuranceCaseInfo.data?.paymentAwaitingClaimStatistic?.sum ?? 0,
      quantity: insuranceCaseInfo.data?.paymentAwaitingClaimStatistic?.count ?? 0,
    },
    {
      label: t('dashboard_settlement.paid'),
      total: insuranceCaseInfo.data?.paymentClaimStatistic?.sum ?? 0,
      quantity: insuranceCaseInfo.data?.paymentClaimStatistic?.count ?? 0,
    },
  ];

  return (
    <>
      <PageHeader
        title={t('dashboard.title')}
        breadcrumb={{
          routes: [
            { path: ROUTES.DASHBOARD.ROOT, breadcrumbName: t('dashboard.title') },
            {
              path: '',
              breadcrumbName:
                menu.filter((el) => el.visible).find((m) => m.path === location.pathname)?.breadcrumbName ?? 'unknown',
              children: menu.filter((m) => m.path !== location.pathname && m.visible),
            },
          ],
        }}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row align="middle" gutter={[16, 16]}>
            <Col>
              <Typography.Text>{t('dashboard_settlement.data_for_the_period')} </Typography.Text>
            </Col>

            <Col>
              <DatePicker
                allowClear={false}
                defaultValue={state.period}
                disabledDate={(date) => !dayjs(date).isBetween('2020-01-01', dayjs().endOf('month'))}
                onChange={onChangeDate}
                colorBase="white"
                picker="month"
                format={config.format.monthYear}
              />
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <Select
            value={[0, 1].includes(partners.data.length) ? partners.data[0]?.id : state.companyId}
            options={partnersOptions}
            label={t('dashboard_settlement.company')}
            onChange={onChangeCompany}
            disabled={partners.data.length === 1}
            loading={partners.loading}
            colorBase="white"
            showSearch
          />
        </Col>
        <Col span={18}>
          <Group>
            {infoAboutCompany.map((el, i) => {
              return (
                <TextField key={i} label={el.label} colorBase="white">
                  <Typography.Text strong className={cssItem}>
                    {t('dashboard_settlement.insurance_cases', {
                      quantity: el.quantity,
                      totalSum: currencyFormat(el.total),
                    })}
                  </Typography.Text>
                </TextField>
              );
            })}
          </Group>
        </Col>

        <Col span={12}>
          <Card className={cssCard} title={t('dashboard_settlement.title_table_imei_which_dublicate')}>
            <ImeiWhichDublicateTable
              onChangeImei={onChangeImei}
              emeiWitchDublicateUnique={emeiWitchDublicateUnique}
              selectedImei={state.selectedImei}
            />
          </Card>
        </Col>

        <Col span={24}>
          <Card
            className={cssCard}
            title={t('dashboard_settlement.title_table_contracts_with_the_same_imei', {
              imei: state.selectedImei,
            })}
          >
            <ContractsWithTheSameImeiTable allImeiDublicate={state.allImeiDublicate} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DashboardSettlement;
