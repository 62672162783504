import React, { useState, useEffect } from 'react';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { css } from '@emotion/css';

import { AppDispatch, AppState } from 'store/reducer';
import { useRequired } from 'hooks/useRequired';
import { CurrencyField } from 'components/next/NumberField/NumberField';
import { initialValues, schema, Values } from './BalanceForm.schema';
import { Select } from 'components/next/Select/Select';
import { InsuranceCompanyView, PartnerView } from 'types/dto/contracts-service';
import { getImeiBalanceInfo, getFondyBalanceInfo, getInsuranceCompanys } from 'store/dashboardExtend/dashboardExtend';
import { getUserRole } from 'roles/userCan';
import { RoleEnum } from 'types/dto/auth-service';

import pallete from 'styles/pallete';
import { DefaultOptionType } from 'antd/lib/select';

const cssHeader = css`
  border-bottom: 2px solid ${pallete.gray.light};
  padding: 5px 0;
  margin: 15px 0 15px 0;
  &:first-child {
    margin: 0 0 15px 0;
  }
`;

const cssLowMoney = css`
  color: var(--ant-error-color);
`;

const defaultSmsService = 'Mobizon';
const defaultImeiService = 'ImeiDB';
const defaultPaymentnSystem = 'Fondy';
const lowLimitOfFundsUAH = 500;
const lowLimitOfFundsUSD = 15;

const getDefaultValues = (partners: PartnerView[]): Values => {
  return {
    insuranceCompanyId: partners[0].insuranceCompanyId ?? 0,
    paymentSystem: '',
    merchant: '',
    paymentSystemBalance: '',
  };
};

export const BalanceForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const mobizonBalanceInfo = useSelector((store: AppState) => store.dashboardExtend.mobizonBalanceInfo.data);
  const imeiBalanceInfo = useSelector((store: AppState) => store.dashboardExtend.imeiBalanceInfo.data);
  const fondyBalanceInfo = useSelector((store: AppState) => store.dashboardExtend.fondyBalanceInfo.data);
  const partners = useSelector((store: AppState) => store.dashboardExtend.partners.data);
  const insuranceCompanies = useSelector((store: AppState) => store.dashboardExtend.insuranceCompanies.data);
  const [adminInsuranceCompanies, setAdminInsuranceCompanies] = useState<InsuranceCompanyView[]>([]);

  const userRole = getUserRole();

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Values>({
    shouldUnregister: false,
    resolver: superstructResolver(schema),
    defaultValues: {
      ...initialValues,
      ...getDefaultValues(partners),
    },
    mode: 'onSubmit',
  });

  const { requiredFields } = useRequired(schema, watch());
  const insuranceCompanyId = watch('insuranceCompanyId');

  useEffect(() => {
    dispatch(getImeiBalanceInfo());
    if (userRole === RoleEnum.ROLE_ADMIN || userRole === RoleEnum.ROLE_INSURANCE_ADMIN) {
      dispatch(getInsuranceCompanys());
    }
  }, []);

  useEffect(() => {
    if (insuranceCompanies?.length) {
      const filteredCompanies = insuranceCompanies.filter((el) => {
        if (el.name === 'INSK') {
          setValue('insuranceCompanyId', el.id ?? 0);
          return el;
        }
        if (el.name === 'СХІД-ЗАХІД') {
          return el;
        }
      });
      setAdminInsuranceCompanies(filteredCompanies);
    }
  }, [insuranceCompanies]);

  useEffect(() => {
    if (insuranceCompanyId) {
      dispatch(getFondyBalanceInfo({ insuranceProgramId: insuranceCompanyId }))
        .then((res) => {
          if (res.accounts && Object.keys(res.accounts).length) {
            const firstMerchant = Object.keys(res.accounts)[0];
            setValue('paymentSystem', defaultPaymentnSystem);
            setValue('merchant', firstMerchant ?? '');
            setValue('paymentSystemBalance', String(res.accounts[firstMerchant][0].balance_db) ?? '');
          }
        })
        .catch(() => {});
    }
  }, [insuranceCompanyId]);

  const smsServiceOptions: DefaultOptionType[] = [defaultSmsService].map((el) => {
    return {
      value: el,
      label: el,
    };
  });

  const imeiServiceOptions: DefaultOptionType[] = [defaultImeiService].map((el) => {
    return {
      value: el,
      label: el,
    };
  });

  const partnersOptions: DefaultOptionType[] =
    userRole === RoleEnum.ROLE_ADMIN || userRole === RoleEnum.ROLE_INSURANCE_ADMIN
      ? adminInsuranceCompanies.map((el) => ({
          value: el.id ?? 0,
          label: el.name ?? '',
        }))
      : partners.map((el) => ({
          value: el.id ?? 0,
          label: el.insuranceCompanyName ?? '',
        }));

  const paymentSystemOptions: DefaultOptionType[] = [defaultPaymentnSystem].map((el) => {
    return {
      value: el,
      label: el,
    };
  });

  const merchantOptions: DefaultOptionType[] = [].map((el) => {
    return {
      value: el,
      label: el,
    };
  });

  return (
    <>
      <div className={cssHeader}>{t('dashboard_extend.balance_sms')}</div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Select
            value={defaultSmsService}
            onChange={() => {}}
            label={t('dashboard_extend.sms_service')}
            options={smsServiceOptions}
            colorBase="grey"
            disabled
          />
        </Col>
        <Col span={12}>
          <CurrencyField
            value={mobizonBalanceInfo?.data?.balance ?? 0}
            label={t('dashboard_extend.funds_in_account')}
            colorBase="grey"
            readOnly
            suffix={` ${mobizonBalanceInfo?.data?.currency ?? ''}`}
            className={Number(mobizonBalanceInfo?.data?.balance ?? 0) <= lowLimitOfFundsUAH ? cssLowMoney : ''}
          />
        </Col>
      </Row>
      <div className={cssHeader}>{t('dashboard_extend.balance_imei')}</div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Select
            value={defaultImeiService}
            onChange={() => {}}
            label={t('dashboard_extend.imei_service')}
            options={imeiServiceOptions}
            colorBase="grey"
            disabled
          />
        </Col>
        <Col span={12}>
          <CurrencyField
            value={imeiBalanceInfo?.balance ?? 0}
            label={t('dashboard_extend.funds_in_account')}
            colorBase="grey"
            readOnly
            suffix={` ${imeiBalanceInfo?.currency ?? ''}`}
            className={(imeiBalanceInfo?.balance ?? 0) <= lowLimitOfFundsUSD ? cssLowMoney : ''}
          />
        </Col>
      </Row>
      <div className={cssHeader}>{t('dashboard_extend.balance_payment_system')}</div>
      <form>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Controller
              name="insuranceCompanyId"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Select
                  name={name}
                  errors={errors}
                  onChange={(v) => onChange(v)}
                  value={value}
                  label={t('dashboard_extend.insurance_company')}
                  options={partnersOptions}
                  requiredFields={requiredFields}
                  colorBase="grey"
                  disabled={userRole !== RoleEnum.ROLE_ADMIN}
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="paymentSystem"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Select
                  name={name}
                  errors={errors}
                  onChange={(v) => onChange(v)}
                  value={value}
                  label={t('dashboard_extend.payment_system')}
                  options={paymentSystemOptions}
                  requiredFields={requiredFields}
                  colorBase="grey"
                  disabled
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="merchant"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Select
                  name={name}
                  errors={errors}
                  onChange={(v) => onChange(v)}
                  value={value}
                  label={t('dashboard_extend.merchant')}
                  options={merchantOptions}
                  requiredFields={requiredFields}
                  colorBase="grey"
                  disabled
                />
              )}
            />
          </Col>
          <Col span={12}>
            <Controller
              name="paymentSystemBalance"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <CurrencyField
                  name={name}
                  errors={errors}
                  requiredFields={requiredFields}
                  onChange={(v) => onChange(v)}
                  value={value}
                  label={t('dashboard_extend.funds_in_account')}
                  colorBase="grey"
                  readOnly
                  suffix={` ${
                    fondyBalanceInfo?.accounts && Object.keys(fondyBalanceInfo?.accounts).length
                      ? fondyBalanceInfo.accounts[Object.keys(fondyBalanceInfo.accounts)[0]][0].currency
                      : ''
                  }`}
                  className={
                    (fondyBalanceInfo?.accounts && Object.keys(fondyBalanceInfo?.accounts).length
                      ? fondyBalanceInfo.accounts[Object.keys(fondyBalanceInfo.accounts)[0]][0].balance_db ?? 0
                      : 0) <= lowLimitOfFundsUAH
                      ? cssLowMoney
                      : ''
                  }
                />
              )}
            />
          </Col>
        </Row>
      </form>
    </>
  );
};
