import { apiConfiguration } from 'api/configuration';
import { apiLoyalty } from 'api/loyalty';
import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { List1Params, PromoCodeView, ResultListDtoPromoCodeView } from 'types/dto/configuration-service';
import { CountryView } from 'types/dto/location-service';
import { ListParams, OwnerType, ResultListDtoContractReferralBonusesView } from 'types/dto/loyalty-service';
import { getPageBy, getPrivateFilters, getSortBy } from 'utils/request';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Bonuses extends List {
  loading: boolean;
  data: ResultListDtoContractReferralBonusesView | null;
}

interface PromoCodes extends List {
  data: ResultListDtoPromoCodeView | null;
  loading: boolean;
}

interface BonusesInfo {
  policiesCount: number;
  contractPaymentAmount: number;
  bonusSum: number;
}

const DEFAULT_PAGINATION = 6;

export const actions = {
  setBonusesInfo: (payload: Partial<BonusesInfo>) => ({ type: 'CLIENT_BONUSES/SET_BONUSES_INFO', payload } as const),
  setBonuses: (payload: Partial<Bonuses>) => ({ type: 'CLIENT_BONUSES/SET_BONUSES', payload } as const),
  setPromoCodes: (payload: Partial<PromoCodes>) => ({ type: 'CLIENT_BONUSES/SET_PROMOCODES', payload } as const),
};

export const getBonusesInfo =
  (userId: number): ThunkType<void> =>
  (dispatch) => {
    return apiLoyalty.contractReferralBonusesController
      .list({
        total: true,
        count: true,
        totalAttributes: 'contractPaymentAmount,bonusSum',
        ownerId: userId.toString(),
        ownerType: OwnerType.CLIENT,
      })
      .then((res) =>
        dispatch(
          actions.setBonusesInfo({
            contractPaymentAmount: res.data.total?.contractPaymentAmount,
            bonusSum: res.data.total?.bonusSum,
            policiesCount: res.data.count,
          }),
        ),
      )
      .catch(() => {});
  };

export const requestPromoCodes =
  (params: List1Params): ThunkType<Promise<ResultListDtoPromoCodeView>> =>
  (dispatch, getState) => {
    const promo = getState().clientBonuses.promoCodes;
    const sorting = getSortBy(promo.sorting);
    const userId = getState().me.data?.details?.id;
    return apiConfiguration.promoCodeController
      .list1({
        count: true,
        distinct: true,
        clientId: (userId ?? 0).toString(),
        sorting: sorting ? `${sorting},-id` : '-id',
        ...getPrivateFilters(promo.privateFilters),
        ...params,
      })
      .then((res) => {
        return res.data;
      });
  };

export const getPromoCodes = (): ThunkType<void> => (dispatch, getState) => {
  const promo = getState().clientBonuses.promoCodes;

  dispatch(actions.setPromoCodes({ loading: true }));

  dispatch(requestPromoCodes({ ...getPageBy(promo.pagination) }))
    .then((res) => {
      dispatch(actions.setPromoCodes({ loading: false, data: res }));
    })
    .catch((error) => {
      dispatch(actions.setPromoCodes({ loading: false, data: null }));
    });
};

export const requestBonuses =
  (params: ListParams): ThunkType<Promise<ResultListDtoContractReferralBonusesView>> =>
  (dispatch, getState) => {
    const bonuses = getState().clientBonuses.bonuses;
    const sorting = getSortBy(bonuses.sorting);
    const userId = getState().me.data?.details?.id;

    return apiLoyalty.contractReferralBonusesController
      .list({
        count: true,
        distinct: true,
        attributes: 'bonusSum,contractPaymentAmount,contractNumber,createDate,bonusType,id',
        ownerId: (userId ?? 0).toString(),
        sorting: sorting ? `${sorting},-id` : '-id',
        ...getPrivateFilters(bonuses.privateFilters),
        ...params,
      })
      .then((res) => {
        return res.data;
      });
  };

export const getBonuses = (): ThunkType<void> => (dispatch, getState) => {
  const bonuses = getState().clientBonuses.bonuses;

  dispatch(actions.setBonuses({ loading: true }));

  dispatch(requestBonuses({ ...getPageBy(bonuses.pagination) }))
    .then((res) => {
      dispatch(actions.setBonuses({ loading: false, data: res }));
    })
    .catch((error) => {
      dispatch(actions.setBonuses({ loading: false, data: null }));
    });
};

export interface StoreClientBonuses {
  bonusesInfo: BonusesInfo;
  bonuses: Bonuses;
  promoCodes: PromoCodes;
}
export const initialState: StoreClientBonuses = {
  bonusesInfo: {
    policiesCount: 0,
    contractPaymentAmount: 0,
    bonusSum: 0,
  },

  bonuses: {
    loading: false,
    data: null,
    pagination: {
      page: 1,
      pageSize: DEFAULT_PAGINATION,
    },
    sorting: { columnKey: 'createDate', order: 'descend' },
    privateFilters: {},
  },
  promoCodes: {
    loading: false,
    data: null,
    pagination: {
      page: 1,
      pageSize: DEFAULT_PAGINATION,
    },
    sorting: { columnKey: 'createDate', order: 'descend' },
    privateFilters: {},
  },
};

const reducer = (state = initialState, action: InferActionTypes): StoreClientBonuses => {
  switch (action.type) {
    case 'CLIENT_BONUSES/SET_BONUSES': {
      return {
        ...state,
        bonuses: { ...state.bonuses, ...action.payload },
      };
    }

    case 'CLIENT_BONUSES/SET_PROMOCODES': {
      return {
        ...state,
        promoCodes: { ...state.promoCodes, ...action.payload },
      };
    }

    case 'CLIENT_BONUSES/SET_BONUSES_INFO': {
      return {
        ...state,
        bonusesInfo: { ...state.bonusesInfo, ...action.payload },
      };
    }
    default:
      return state;
  }
};

export default reducer;
