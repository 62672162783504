import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageHeader from 'components/PageHeader';
import ServiceForm from './components/ServiceForm';
import { getSublimitsChildless, getSubsublimits, setPagination } from 'store/sublimits/actions';
import { getServiceById } from 'store/institutions/actions';
import { getServiceTypeServices } from 'store/medApp/actions';
import { AppState } from '../../store/reducer';

const ServiceDetails: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const serviceId = history.location.pathname.split('/')[3];
  const isEdit = serviceId !== 'new';

  const fetchSublimits = () => dispatch(getSublimitsChildless());
  const fetchSubsublimits = () => dispatch(getSubsublimits());
  const fetchServiceById = (id) => dispatch(getServiceById(id));
  const setSublimitPagination = (page, perPage) => dispatch(setPagination(page, perPage));
  const fetchServiceTypes = () => dispatch(getServiceTypeServices());

  const { sublimitsData, subsublimitsData } = useSelector(
    ({ sublimits: { sublimitsChildlessData, subsublimitsData } }: AppState) => ({
      sublimitsData: sublimitsChildlessData,
      subsublimitsData: subsublimitsData?.resultList || [],
    }),
  );

  const { serviceData, error } = useSelector(({ institutions: { serviceById, error } }: AppState) => ({
    serviceData: serviceById || {},
    error,
  }));

  const { serviceTypes } = useSelector(({ medApp: { serviceTypeServices } }: AppState) => ({
    serviceTypes: serviceTypeServices || [],
  }));

  useEffect(() => {
    if (isEdit) fetchServiceById(serviceId);
    fetchServiceTypes();
    setSublimitPagination(1, 300);
    fetchSubsublimits();
    fetchSublimits();
  }, []);

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('services.home'), href: ROUTES.DASHBOARD.ROOT },
          { label: t('services.list'), href: ROUTES.MEDAPP.SERVICES.ROOT },
          { label: t('services.service_data') },
        ]}
      />
      <PageHeader title={t('services.service_data')} />
      <ServiceForm
        serviceData={serviceData}
        serviceTypes={serviceTypes}
        sublimitsData={sublimitsData}
        subsublimitsData={subsublimitsData}
        errorMessage={error}
        isEdit={isEdit}
      />
    </>
  );
};

export default ServiceDetails;
