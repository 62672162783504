import * as termApi from 'api/termination';
import { ThunkAction } from 'redux-thunk';
import { StoreType } from 'types/store';
import { Action } from 'redux';

import onDownload from 'callbacks/onDownload';

type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const getTerminationClaimPdf =
  (terminationId: number, t: any): ThunkResult =>
  async () => {
    await onDownload(() => termApi.printContract(terminationId));
  };
