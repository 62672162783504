import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import Button from 'components/next/Button/Button';
import pallete from 'styles/pallete';

export const cssTileContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2vw;
`;
const cssRoot = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: ${pallete.basic.white};
  border-radius: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;
const cssIconContainer = css`
  padding: 8px;
  border-radius: 16px;
  background-color: ${pallete.blueGray.lightest};
`;
const cssIcon = css`
  &.anticon {
    font-size: 40px;
    color: ${pallete.blueGray.main};
  }
`;
const cssTitle = css`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export interface Props {
  title: string;
  icon: React.ReactElement;
  handleClick: () => void;
}

const Tile = ({ title, handleClick, icon }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cssRoot}>
      <div className={cssIconContainer}>{React.cloneElement(icon, { className: cssIcon })}</div>
      <div className={cssTitle}>{title}</div>
      <Button block type="secondary" onClick={handleClick}>
        {t('settings.review_button')}
      </Button>
    </div>
  );
};

export default Tile;
