import React from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { IconDots, IconEye } from 'components/next/Icon/Icon';
import { ContractImeiDuplicationsView } from 'types/dto/contracts-service';
import { generatePath, useHistory } from 'react-router-dom';
import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import { checkPermissions } from 'roles/userCan';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

interface IProps {
  record: ContractImeiDuplicationsView;
}

const Menu = ({ record }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();

  const items: (ItemType & { visible: boolean })[] = [
    {
      key: 'view',
      icon: <IconEye />,
      label: t('table.review'),
      visible: true,
      disabled: !checkPermissions('CONTRACT', 'READ'),
      onClick: () => {
        history.push(generatePath(ROUTES.CONTRACTS_LIST.DETAILS, { id: record.id }));
      },
    },
  ];

  const itemsVisible = items.filter((menuItem) => menuItem.visible).map(({ visible, ...menuItem }) => menuItem);

  return (
    <>
      <Dropdown menu={{ items: itemsVisible }} trigger={['hover']}>
        <IconDots />
      </Dropdown>
    </>
  );
};

const MemoizedMenu = React.memo(Menu);

export default MemoizedMenu;
