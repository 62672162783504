import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { css, cx } from '@emotion/css';
import { FieldErrors } from 'react-hook-form';

import { ColorBase, colorBaseMap, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

interface Props extends InputProps {
  label?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  colorBase?: ColorBase;
  errorPosition?: Position;
  classNameContainer?: string;
  loading?: boolean;
  isRequired?: boolean;
  hasError?: boolean;
}

export const InputField = ({
  name,
  errors,
  requiredFields,
  label,
  colorBase = 'none',
  className,
  errorPosition,
  classNameContainer,
  loading,
  isRequired,
  hasError,
  ...props
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      isRequired={isRequired}
      hasError={hasError}
      label={label}
      colorBase={colorBase}
      errorPosition={errorPosition}
      className={classNameContainer}
    >
      <Input
        data-test={name}
        name={name}
        className={cx(
          css`
            background: ${colorBaseMap[colorBase]};
            border-radius: 8px;
            border: 1px solid var(--pallete-redesign-gray-light);
            padding: 12px 16px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 167%;
            height: auto;
            max-height: 51px;

            :focus {
              box-shadow: none;
            }

            .ant-input {
              border: none;
              background: ${colorBaseMap[colorBase]};

              :focus {
                box-shadow: none;
              }
            }

            &.ant-input[disabled] {
              background: ${colorBaseMap[colorBase]};
            }
          `,
          className,
        )}
        suffix={loading ? <LoadingOutlined /> : undefined}
        {...props}
      />
    </InputContainer>
  );
};
