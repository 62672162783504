import React from 'react';
import styles from './PhoneNumberInput.module.less';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

interface IProps {
  title: string;
  value?: string;
  isRequired?: boolean;
  onChange?: (value: string) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  suffix?: any;
  disabled?: boolean;
  readOnly?: boolean;
  mask?: string;
  isMask?: boolean;
  format?: string;
  placeholder?: string;
}

export default class PhoneNumberInput extends React.Component<IProps> {
  handleInput = ({ value }: { value: string }) => {
    const { onChange }: any = this.props;
    const d = value && value.replace(/[\s,()-]/g, '');
    onChange(d);
  };

  render() {
    const {
      title,
      value,
      isRequired,
      onFocus,
      onBlur,
      suffix,
      disabled,
      readOnly = false,
      mask,
      isMask = true,
      format = '(##) ###-##-##',
      placeholder = '',
    } = this.props;

    let newValue = value;

    if (String(value).includes('+380') && isMask) {
      newValue = String(value).split('+380')[1];
    }

    if (String(value)[0] === '0') {
      newValue = String(value).slice(1);
    }

    return (
      <div className={styles.formPhoneNumber__container}>
        <p>
          {title}
          {isRequired && <span>*</span>}
        </p>
        <div className="phone-input-holder">
          {isMask ? (
            <span
              className={classNames('phone_prefix', styles.prefix, {
                [styles.disabled_prefix]: disabled,
              })}
            >
              {mask || '+380'}
            </span>
          ) : null}
          <NumberFormat
            onFocus={onFocus}
            onBlur={onBlur}
            className={classNames('phone_input', styles.phoneInput, {
              [styles.phoneInputWithoutMask]: !isMask,
            })}
            format={format}
            value={newValue}
            disabled={disabled}
            mask=" "
            // onValueChange={this.handleInput}
            onChange={(e) => this.handleInput({ value: e.target.value })}
            data-id="signup_phone"
            readOnly={readOnly}
            placeholder={placeholder}
          />
        </div>

        {suffix && suffix}
      </div>
    );
  }
}
