import React, { ChangeEventHandler } from 'react';
import Input from 'components/Input';
import Button from 'components/Button';
import styles from './ClinicPickerHeader.module.less';
import { useTranslation } from 'react-i18next';

interface ClinicPickerHeaderProps {
  query: string;
  onChange: (query: string) => void;
  onClick: () => void;
}

export const ClinicPickerHeader: React.FC<ClinicPickerHeaderProps> = ({ query, onChange, onClick }) => {
  const { t } = useTranslation();

  const handleQueryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.clinicHeader}>
      <Input
        placeholder={t('appointments.search_placeholder')}
        value={query}
        onChange={handleQueryChange}
        className={styles.input}
      />
      <Button className={styles.filtersBtn} onClick={onClick}>
        {t('appointments.filters')}
      </Button>
    </div>
  );
};
