import createInstance from './axios';
import { searchParamsType } from 'types/deviceSearch';
import momentTz from 'moment-timezone';
import { AxiosResponse } from 'axios';
import { IContract, IUniqueEmei, IUniqueNumber, СontractInfoDto, ContractObjectsDto } from '../types/contractDetails';
import {
  AccountTypeCode,
  CurrentUserDto,
  ObjectSubtypeDto,
  ContractIntegrationHistoryDto,
  ContractDto,
  ContractConfigurationDto,
  WithdrawVerifyWarningsDto,
  ResultListDtoBrandView,
  OptionDto,
  AgentContractDto,
  DeviceDetailsWrapperDto,
  ResultListDtoContractPaymentView,
  ContractClaimDetailsDto,
  ContractTerminationDetailsDto,
  ResultListDtoContractView,
  MajorContractDto,
  InsuranceObjectDetailsDto,
} from 'types/dto/contracts-service';
import { InsurancePolicyDto, InsuranceProductCode } from 'types/dto/configuration-service';
import { Api } from 'types/dto/contracts-service';
import { onFulfilledResponce, onRejectedResponce, onFulfilledRequest, onRejectedRequest } from 'api/axios/axios-next';

export const apiContracts = new Api({
  baseURL: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_CONTRACTS_SERVICE}`,
});

apiContracts.instance.interceptors.request.use(onFulfilledRequest, onRejectedRequest);
apiContracts.instance.interceptors.response.use(onFulfilledResponce, onRejectedResponce(apiContracts.instance));

const contractsAPI = createInstance('CONTRACTS');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');
const contractsFilesAPI = createInstance('CONTRACTS', 'file');
const configApi = createInstance('CONFIGURATION');
const proxyApi = createInstance('PROXY');
const configService = createInstance('CONFIGURATION');

export const getContracts = ({ ...params }) => {
  return contractsAPI.get<ResultListDtoContractView>('/contracts/?distinct', {
    params: {
      ...params,
      current: true,
      test: false,
    },
  });
};

export const getConfigs = (partnerId, insuranceProgramId) => {
  return contractsFilesAPI.get<ResultListDtoContractView>(
    `/contracts/documents/configurations?partnerId=${partnerId}&insuranceProgramId=${insuranceProgramId}`,
  );
};

export const documentUpload = async (claimId: number, documentTypeCode: String, formData: any) => {
  return contractsFilesAPI.post(`/contracts/${claimId}/documents/${documentTypeCode}/files`, formData);
};

// TODO Add types
export const getDocumentUploadFileInfo = ({ claimId, documentTypeCode, fileKey }): Promise<AxiosResponse<any>> => {
  return contractsFilesAPI.get(`/contracts/${claimId}/documents/${documentTypeCode}/files/${fileKey}`, {
    responseType: 'arraybuffer',
  });
};

export const downloadAllUploadFiels = ({ claimId, documentTypeCode }) => {
  return contractsFilesAPI.get(`/contracts/${claimId}/documents/${documentTypeCode}/zip`, {
    responseType: 'blob',
  });
};

export const getSubAgreements = ({ ...params }) => {
  return contractsAPI.get<ResultListDtoContractView>('/contracts/sub-agreements/', {
    params: {
      ...params,
    },
  });
};

export const getContractDetails = async (id: number) => contractsAPI.get<ContractDto>(`/contracts/${id}`);

export const getTerminationsDetailsTable = async (id: number) =>
  contractsAPI.get<ContractTerminationDetailsDto>(`/contracts/${id}/termination-details`);

export const getRepaymentsDetailsTable = async (id: number, params?) =>
  contractsAPI.get<ContractClaimDetailsDto[]>(`/contracts/${id}/claim-details`, { params: { ...params } });

export const getPaymentDetailsTable = async (contractId, params?: any) =>
  contractsAPI.get<ResultListDtoContractPaymentView>(
    `/contract-payments/payments?count&total&sorting=expectedPaymentDate,id`,
    {
      params: {
        contractId: contractId,
        totalAttributes: 'expectedPaymentAmount,paymentAmount,leftToPay',
        attributes:
          'id,periodStartDate,periodEndDate,expectedPaymentAmount,expectedPaymentDate,paymentAmount,paymentDate,checkNumber,leftToPay,contractId,insuranceCompanyId,partnerId,partnerDepartmentId',
        ...params,
      },
    },
  );

export const getTablePageData = (contractId, { filters, sorting, pagination }) => {
  const otherFilters = {
    ...filters.insuranceCompanyId,
  };
  const sortingQuery = sorting.direction === 'DESC' ? `-${sorting.field},-id` : `${sorting.field},id`;
  if (otherFilters) delete otherFilters.name;
  const requestParams = {
    page: pagination.current,
    page_size: pagination.perPage,
    sorting: sortingQuery,
    distinct: true,
    ...otherFilters,
  };
  //@ts-ignore
  Object.keys(requestParams).forEach((key) => !requestParams[key] && delete requestParams[key]);

  return contractsAPI.get<ResultListDtoContractPaymentView>('/contract-payments/payments?count&total', {
    params: {
      contractId: contractId,
      totalAttributes: 'expectedPaymentAmount,paymentAmount,leftToPay',
      attributes:
        'id,periodStartDate,periodEndDate,expectedPaymentAmount,expectedPaymentDate,paymentAmount,paymentDate,checkNumber,leftToPay,contractId,insuranceCompanyId,partnerId,partnerDepartmentId',
      ...requestParams,
    },
  });
};

export const getInsuranceProgramRisks = async (id: number) => {
  return configApi.get<OptionDto[]>(`/insurance-programs/${id}/risks`);
};

export const getInsuranceProgramData = async (id: number) => {
  return contractsAPI.get(`/insurance-programs/${id}`);
};

export const getInsurancePrograms = async (type?: string) =>
  contractsAPI.get(
    `/insurance-programs?attributes=id,name&distinct&insuranceProductCode=${type ? type : InsuranceProductCode.MZ}`,
  );

export const getInsuranceObjectSubTypes = async (id: number) =>
  contractsAPI.get<OptionDto[]>(`/insurance-objects/subtypes`, {
    params: { insuranceProgramId: id },
  });

export const addContractDetails = async (contract: any) =>
  contractsAPI.post(`/contracts`, {
    ...contract,
  });

export const updateContractDetails = async (contract: any, contractId: any) =>
  contractsAPI.put(`/contracts/${contractId}`, {
    ...contract,
  });

export const getImeiData = async (imei: string) => {
  return contractsAPI.get<DeviceDetailsWrapperDto>(`/device-details/imei/${imei}`);
};

export const getUniqueImeiData = async (data: IUniqueEmei): Promise<AxiosResponse<any>> => {
  return data.imei
    ? contractsAPI.post(`/insurance-objects/electronic-devices/imei/validation`, { ...data })
    : contractsAPI.post('/insurance-objects/vehicles/vin-number/validation', { ...data });
};

export const getUniqueNumber = async (data: IUniqueNumber): Promise<AxiosResponse<any>> => {
  return contractsAPI.post('/insurance-objects/vehicles/registration-number/validation', { ...data });
};

export const getDataCalculation = async (
  insuranceProgramId: number,
  saleSum: number,
  duration: number | string,
  partnerId: number,
  objectsQuantity?: number,
  isProlog?: boolean,
  originalContractId?: number,
  contractObjects?: ContractObjectsDto[],
  durationUnit?: string,
  insurancePolicyId?: number,
  contractDate?: string,
  prolongatedFromInsuranceRateId?: number,
  installmentPaymentPeriodType?: string,
  contractInfo?: СontractInfoDto,
  insuranceSumId?: number,
) => {
  return contractsAPI.post('/contracts/calculations', {
    insuranceProgramId,
    saleSum: saleSum ? saleSum : undefined,
    duration,
    durationUnit: durationUnit ? durationUnit : 'MONTHS',
    prolongation: isProlog,
    contractId: isProlog ? originalContractId : undefined,
    partnerId,
    objectsQuantity,
    contractObjects,
    insurancePolicyId,
    contractDate,
    prolongatedFromInsuranceRateId,
    installmentPaymentPeriodType,
    contractInfo,
    insuranceSumId,
  });
};

export const searchContracts = async (data?: searchParamsType) => {
  const requestParams = data || {};

  //@ts-ignore
  Object.keys(requestParams).forEach((key) => !requestParams[key] && delete requestParams[key]);

  return contractsAPI.get<InsuranceObjectDetailsDto[]>('insurance-objects', {
    // default values on backend: page: 1, page_size: 50
    params: {
      ...requestParams,
    },
  });
};

export const getInsuredEventAccountTypes = () => contractsAPI.get<AccountTypeCode[]>(`/claims/account-types`);

export const getAgentContracts = (id: string) => {
  return contractsAPI.get<AgentContractDto[]>(`/agent-contracts?partnerId=${id}`);
};

export const getUsersInfo = () => {
  return contractsAPI.get<CurrentUserDto>('/users/current');
};

export const downloadContractPdf = (id: number | string) => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/contracts/${id}/pdf-report`, {
    params: {
      timeZone,
    },
  });
};

export const sendOffer = (id: number): Promise<AxiosResponse<void>> => {
  return contractsPdfAPI.post<void>(`/contracts/${id}/send-offer`);
};

export const signContractClaim = async (
  id: number,
  token?: string,
  russianCitizen?: boolean,
  localeLanguage?: string,
) =>
  contractsAPI.post<void>(
    `contracts/${id}/sign?${russianCitizen !== undefined ? `russianCitizen=${russianCitizen}&` : ''}token=${token}${
      localeLanguage ? `&localeLanguage=${localeLanguage}` : ''
    }`,
  );

export const sendSignVerification = async (id: number) => contractsAPI.post<void>(`contracts/${id}/sign-verification`);

export const getRisks = async (id: number) => contractsAPI.get<OptionDto[]>(`contracts/${id}/risks`);

export const printContract = async (id: number, lang?: string) => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/contracts/${id}/pdf-report${lang ? `?claimReportLanguage=${lang}` : ''}`, {
    params: {
      timeZone,
    },
  });
};

export const getBrandsList = (brand?: string, type?: string, subtype?: string) => {
  return contractsAPI.get<ResultListDtoBrandView>('/insurance-objects/brands', { params: { brand, type, subtype } });
};

export const withdrawSign = (id: number) => {
  return contractsAPI.post<void>(`/contracts/${id}/withdraw-sign`);
};

export const withdrawCheck = (id: number | undefined) => {
  return contractsAPI.post<ContractDto>(`/contracts/${id}/withdraw-verify`);
};

export const withDrawCheckWarnings = (id: number | undefined) => {
  return contractsAPI.get<WithdrawVerifyWarningsDto>(`contracts/${id}/withdraw-verify/warnings`);
};

export const getContractNumberEnding = (params) => {
  return contractsAPI.post<ContractConfigurationDto>(`/contracts/configurations`, params);
};

export const sendReconciliation = (params: Partial<IContract>, contractId: number) => {
  return contractsAPI.post<ContractDto>(`/contracts/${contractId}/verify`, params);
};

export const getTransferHistories = (originalContractId: number | string): Promise<AxiosResponse<any>> => {
  return contractsAPI.get<ContractIntegrationHistoryDto[]>(`/contracts/${originalContractId}/integration-histories`, {
    params: {
      attributes: 'operationType',
    },
  });
};

export const getEstateTypes = () => {
  return contractsAPI.get<('APARTMENT' | 'PRIVATE_HOUSE' | 'COTTAGE' | 'TOWN_HOUSE')[]>(
    '/insurance-objects/estate-types',
  );
};

export const getEstateSubTypes = (insuranceProgramId) => {
  return contractsAPI.get<ObjectSubtypeDto[]>(`/insurance-objects/types-subtypes`, {
    params: {
      objectType: 'ESTATE',
      insuranceProgramId: insuranceProgramId,
    },
  });
};

export const generateContractNumber = (insuranceProgramCode: string) => {
  return contractsAPI.get<string>(`/contracts/generate-contract-number?insuranceProgramCode=${insuranceProgramCode}`, {
    responseType: 'text',
  });
};

export const onSendContractOnSign = (id: number) => {
  return contractsAPI.post<void>(`/contracts/${id}/send-client-on-sign`);
};

export const getUserContracts = async () => {
  const { data } = await proxyApi.get(`/contracts/insurance-objects?current=true`);
  return data;
};

export const getContractById = async (id: number) => {
  const { data } = await proxyApi.get<ContractDto>(`/contracts/${id}`);
  return data;
};

export const sendClientSignVerification = async (id: string) => {
  const { data } = await proxyApi.post(`contracts/external/${id}/client-sign-verification?needClientSign=false`);
  return data;
};

export const sendClientSign = async (id: string, token: number) => {
  const { data } = await proxyApi.post(`contracts/external/${id}/client-sign?token=${token}&needClientSign=false`);
  return data;
};

export const getMajorContracts = async () => {
  const { data } = await contractsAPI.get<MajorContractDto[]>(`/contracts/majorcontracts`);
  return data;
};

export const getDurationListeForProlongation = async (id: number) => {
  const { data } = await configService.get<InsurancePolicyDto[]>(
    `/insurance-policies?insuranceProgramId=${id}&policyType=PROLONGATION`,
  );
  return data;
};
