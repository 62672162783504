import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { CompanyView, List8Params, ResultListDtoContractTerminationView } from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/TerminationsList/Filters/Filters.schema';
import config from 'config';
import { InsuranceProgramView, List3Params, List4Params } from 'types/dto/configuration-service';
import { apiContracts } from 'api/contracts';
import { apiConfiguration } from 'api/configuration';
import onDownload from 'callbacks/onDownload';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoContractTerminationView | null;
}
type Filters = Values & { initialization: boolean };
interface InsurancePrograms {
  loading: boolean;
  data: InsuranceProgramView[];
}
interface Partners {
  loading: boolean;
  data: CompanyView[];
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'TERMINATIONS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'TERMINATIONS/SET_FILTERS', payload } as const),
  setInsurancePrograms: (payload: Partial<InsurancePrograms>) =>
    ({ type: 'TERMINATIONS/SET_INSURANCE_PROGRAMS', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'TERMINATIONS/SET_PARTNERS', payload } as const),
};

export const downloadReport = (): ThunkType<void> => async (dispatch, getState) => {
  const terminations = getState().terminationList;
  const sorting = getSortBy(terminations.data.sorting);

  await onDownload(() =>
    apiContracts.contractTerminationController
      .printXlsxContractTerminationList(
        {
          timeZone: dayjs.tz.guess(),
          insuranceProgramId: terminations.filters?.insuranceProgramId,
          partnerId: terminations.filters?.partnerId,
          sorting: sorting ? sorting : '-terminationDate',
          terminationDate: getDateRange(terminations.filters?.dateFrom, terminations.filters?.dateTo) ?? '',
          ...getPrivateFilters(terminations.data.privateFilters),
          // TODO back
        } as any,
        { format: 'blob' },
      )
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const requestData =
  (params: List8Params = {}): ThunkType<Promise<ResultListDtoContractTerminationView>> =>
  async (dispatch, getState) => {
    const terminations = getState().terminationList;
    const sorting = getSortBy(terminations.data.sorting);

    return apiContracts.contractTerminationController
      .list8({
        count: true,
        total: true,
        totalAttributes: 'refundSum,claimPaymentSum',
        insuranceProgramId: terminations.filters?.insuranceProgramId?.toString(),
        partnerId: terminations.filters?.partnerId?.toString(),
        terminationDate: getDateRange(terminations.filters?.dateFrom, terminations.filters?.dateTo) ?? '',
        sorting: sorting ? sorting : '-terminationDate',
        ...getPrivateFilters(terminations.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const terminations = getState().terminationList;

  dispatch(actions.setData({ loading: true, data: null }));

  dispatch(requestData({ ...getPageBy(terminations.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false, data: null }));
    });
};

export const loadInsurancePrograms =
  (query: List4Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setInsurancePrograms({ loading: true, data: [] }));

    apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        page_size: -1,
        attributes: 'id,name',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: [] }));
      });
  };

export const loadPartners = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setPartners({ loading: true, data: [] }));

  apiContracts.companyController
    .list9({
      partnerId: '<>null',
      insuranceCompanyId: '<>null',
      partnerDepartmentId: 'null',
    })
    .then((res) => {
      dispatch(actions.setPartners({ loading: false, data: res.data }));
    })
    .catch(() => {
      dispatch(actions.setPartners({ loading: false, data: [] }));
    });
};

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadInsurancePrograms());
  dispatch(loadPartners());
};

export interface StoreTerminations {
  data: Data;
  filters: Filters;
  insurancePrograms: InsurancePrograms;
  partners: Partners;
}
export const initialState: StoreTerminations = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'terminationDate', order: 'descend' },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  insurancePrograms: { loading: false, data: [] },
  partners: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreTerminations => {
  switch (action.type) {
    case 'TERMINATIONS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'TERMINATIONS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'TERMINATIONS/SET_INSURANCE_PROGRAMS':
      return { ...state, insurancePrograms: { ...state.insurancePrograms, ...action.payload } };
    case 'TERMINATIONS/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
