import axios, { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { notification } from 'antd';
import i18n from 'i18next';
import { ErrorsDto } from 'types';

import history from 'routes/history';
import { ROUTES } from 'constants/routes';

// please prefer generation types via 'npm run cli' if it possible
type Service = 'CONFIGURATION' | 'CONTRACTS' | 'CHAT' | 'PROXY' | 'INSTITUTION' | 'APPOINTMENT';

const baseURLMap: Record<Service, string> = {
  CONFIGURATION: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_CONFIGURATION_SERVICE}`,
  CONTRACTS: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_CONTRACTS_SERVICE}`,
  CHAT: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_CHATS_SERVICE}`,
  PROXY: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_PROXY_SERVICE}`,
  INSTITUTION: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_INSTITUTION_SERVICE}`,
  APPOINTMENT: `${process.env.REACT_APP_CHISW_API_HOST}${process.env.REACT_APP_APPOINTMENT_SERVICE}`,
};

const getAxiosInstance = (service: Service, flag?: String) => {
  switch (flag) {
    case 'pdf':
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/pdf',
        },
        responseType: 'blob',
      });
    case 'file':
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'application/json',
        },
        responseType: 'json',
      });
    case 'uploadFile':
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          Authorization: localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'json',
      });
    default:
      return axios.create({
        baseURL: baseURLMap[service],
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        responseType: 'json',
      });
  }
};

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token: string) => {
  refreshSubscribers.map((cb) => cb(token));
};

export const onFulfilledRequest = (config: AxiosRequestConfig) => {
  // console.log('onFulfilledRequest', config);
  const accessToken = getAccessToken();

  if (!accessToken) {
    return config;
  }

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
};

export const onRejectedRequest = (error) => {
  // console.log('onRejectedRequest', error);
  return Promise.reject(error);
};

export const onFulfilledResponce = (response: AxiosResponse<any>) => {
  // console.log('onFulfilledResponce', response);
  return response;
};

export const onRejectedResponce = (instance: AxiosInstance) => (error: AxiosError) => {
  // console.log('onRejectedResponce', error);
  const config: AxiosRequestConfig = error.config;
  const statusCodeFamily = Math.floor((error.request?.status ?? 0) / 100);

  // for Network Errors
  if (statusCodeFamily === 0) {
    notification.error({ message: i18n.t('error_code.network') });

    return Promise.reject(undefined);
  }

  // for server errors
  if (statusCodeFamily === 5) {
    if (Array.isArray(error?.response?.data.errors)) {
      (error?.response?.data as ErrorsDto).errors.forEach((err) => {
        notification.error({ message: i18n.t('error_code.5xx'), description: i18n.t(`error_code.${err.code}`) });
      });
    } else {
      notification.error({ message: i18n.t('error_code.5xx') });
    }
    return Promise.reject(undefined);
  }

  if (error.request?.status === 403) {
    notification.error({ message: i18n.t('error_code.403') });

    return Promise.reject(undefined);
  }

  if (error.response?.status === 401 && getAccessToken() !== null && window.location.pathname !== '/login') {
    if (!isRefreshing) {
      isRefreshing = true;

      const refreshURL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_AUTH_SERVICE}/api/auth/refresh_token`;

      axios
        .get(refreshURL, { headers: { Authorization: `Bearer ${getAccessToken()}` } })
        .then(({ data }) => {
          instance.defaults.headers.Authorization = `Bearer ${data.access_token}`;
          localStorage.setItem('accessToken', data.access_token);
          onRefreshed(data.access_token);
          refreshSubscribers = [];
          isRefreshing = false;
        })
        .catch(() => {
          const code = localStorage.getItem('companyCode');
          history.push(code ? ROUTES.AUTH.LOGIN : ROUTES.PARTNER_AUTH.LOGIN);
          isRefreshing = false;
        });
    }

    const retryOrigReq = new Promise((resolve) => {
      subscribeTokenRefresh((token: string) => {
        config.headers.Authorization = `Bearer ${token}`;
        resolve(instance(config));
      });
    });

    return retryOrigReq;
  }

  return Promise.reject(error);
};

const createInstance = (service: Service, flag?: String) => {
  const axiosInstance = getAxiosInstance(service, flag);

  axiosInstance.interceptors.request.use(onFulfilledRequest);
  axiosInstance.interceptors.response.use(onFulfilledResponce, onRejectedResponce(axiosInstance));

  return axiosInstance;
};

export default createInstance;
