import i18n from 'i18next';
import isEmail from 'is-email';

import { string, refiner, object, Infer, boolean } from 'utils/struct';

export const schema = object({
  username: refiner(string(), (value) => {
    if (!value) {
      return i18n.t('log_in.enter_email_or_phone').toString();
    }

    if (/^\+/.test(value.trim())) {
      if (!/^\+\d+$/.test(value.trim())) {
        return i18n.t('schema.phoneNumber').toString();
      }

      return true;
    }

    if (!isEmail(value.trim())) {
      return i18n.t('schema.email').toString();
    }

    return true;
  }),
  password: refiner(string(), (value) => {
    if (!value) {
      return i18n.t('log_in.enter_password').toString();
    }

    if (!/^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{8,})*$/.test(value.trim())) {
      return i18n.t('passwordRecovery.password_must_contain_at_least_8_characters').toString();
    }

    return true;
  }),
  remember: boolean(),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  username: '',
  password: '',
  remember: true,
};
