import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Menu, MenuProps } from 'antd';
import history from 'routes/history';
import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';

import { getProducts } from 'store/insuranceProducts/actions';
import { apiAuth } from 'api/auth';
import { css, cx } from '@emotion/css';
import {
  IconArrowRight,
  IconBell,
  IconBonuses,
  IconHomeRedesign,
  IconLogoutRedesign,
  IconPolicies,
  IconRemove,
  IconSetting,
} from 'components/next/Icon/Icon';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';
import { getUserRoleNext } from 'roles/userCan';
import { RoleEnum } from 'types/dto/auth-service';

const cssRoot = css`
  overflow-x: clip;
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1000;
  padding: 32px 12px 65px;
  /* Hide scrollbar for Chrome, Safari and Opera/ */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

const cssSiderWrapperCollapsed = css`
  width: 80px;
`;

const cssSiderWrapper = css`
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1000;
  width: 310px;
  transition: all 0.3s;
`;

const cssLogoWrapper = css`
  display: flex;
  align-items: center;
  margin-bottom: 110px;
  padding: 0 10px;
  height: 40px;
`;

const cssLogo = css`
  height: 40px;
  max-width: 245px;
  object-fit: contain;
`;

const cssLogoMini = css`
  height: 40px;
  max-width: 40px;
  object-fit: cover;
`;

const cssButton = css`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--pallete-bluegray-lightest);
  margin: 0 auto;
`;

const cssCollapseButton = css`
  border: 6px solid var(--pallete-bluegray-lighter);
  transition: border 0.1s;
  position: absolute;
  right: 0;
  top: 100px;
  transform: translateX(50%);
  background: var(--pallete-basic-white);
  cursor: pointer;
  width: 46px;
  height: 46px;
  border-radius: 17px;
  z-index: 1001;
`;

const cssButtonActive = css`
  border: 6px solid #a5a5a8;
  cursor: pointer;
`;

const cssMask = css`
  z-index: 999;
  height: 100vh;
  background: transparent;
  transition: background 0.2s;
  width: 0px;
  position: fixed;
`;

const cssMaskActive = css`
  background: rgba(49, 49, 49, 0.39);
  width: 100%;
`;

const cssMenuWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const cssMenu = css`
  &.ant-menu-vertical {
    border: none;
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 8px;
    padding: 0;

    .ant-menu-title-content {
      margin-left: 0;
      font-size: 16px;
      color: var(--pallete-redesign-gray-main);
    }

    .anticon {
      min-width: 54px;
      padding: 12px 16px;
      border-radius: 8px;
      font-size: 24px !important;
      color: var(--pallete-redesign-gray-main);
      line-height: 0 !important;
    }

    &:hover {
      background: var(--pallete-redesign-gray-lighter);
    }

    &:active {
      background: none;
    }
  }

  &.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0;
  }

  && .ant-menu-item-selected {
    background: var(--pallete-redesign-gray-main);

    .ant-menu-title-content > a {
      color: var(--pallete-basic-white);
    }

    .anticon {
      color: var(--pallete-basic-white);
    }
  }
`;

const cssLogoutItem = css`
  .anticon {
    padding: 12px 15px;

    svg {
      margin-left: 3px;
    }
  }
`;

const SideMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState(['dashboard']);

  const whiteLabel = useSelector((store: AppState) => store.me.whiteLabel?.data);

  useEffect(() => {
    // TODO fix this request
    dispatch(getProducts(t));
    const pathArr = history.location.pathname.split('/');
    const rootPath = pathArr[1];
    const isCreateContract = pathArr[2] === 'contract' && pathArr[3] === 'create';

    if (selectedKeys[0] === rootPath && selectedKeys[0] !== 'createContract' && !isCreateContract) return;

    if (ROUTES.CONTRACTS_LIST.ROOT.includes(rootPath)) setCollapsed(true);
    if (rootPath === 'dashboard') setCollapsed(true);
    if (rootPath !== 'dashboard') {
      if (isCreateContract) {
        return setSelectedKeys(['createContract']);
      }
      setSelectedKeys([rootPath]);
    } else {
      setSelectedKeys(['home']);
    }
  }, [history.location]);

  const onLogoutClick = useCallback(async () => {
    await apiAuth.authenticationEndpoints.logout();
    const role = getUserRoleNext();
    dispatch({ type: 'RESET_STORE' });
    sessionStorage.removeItem('currentCurrency');
    localStorage.removeItem('companyCode');
    localStorage.removeItem('accessToken');

    const clientAuthParams = localStorage.getItem('clientAuthParams');
    history.push(
      role === RoleEnum.ROLE_CLIENT
        ? `${ROUTES.AUTH.CLIENT_LOGIN}${clientAuthParams || ''}`
        : ROUTES.PARTNER_AUTH.LOGIN,
    );
  }, []);

  useEffect(() => {
    document.body.style.overflow = !collapsed ? 'hidden' : '';
  }, [collapsed]);

  const onClick: MenuProps['onClick'] = (e) => {
    setCollapsed(true);
    setSelectedKeys([e.key]);
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const itemsTop: (ItemType & { visible: boolean; 'data-test'?: string })[] = [
    {
      key: 'dashboard',
      icon: <IconHomeRedesign />,
      label: <Link to={ROUTES.DASHBOARD.ROOT}>{t('side_menu.home')}</Link>,
      visible: true,
      'data-test': 'Dashboard-Button',
    },
    {
      key: 'policies',
      icon: <IconPolicies />,
      label: <Link to={ROUTES.CLIENT.POLICIES}>{t('side_menu.policies')}</Link>,
      visible: true,
      'data-test': 'Policies-Button',
    },
    {
      key: 'notification',
      icon: <IconBell />,
      label: <Link to={ROUTES.CLIENT.NOTIFICATION}>{t('side_menu.notification')}</Link>,
      visible: true,
      'data-test': 'Notification-Button',
    },
    {
      key: 'bonuses',
      icon: <IconBonuses />,
      label: <Link to={ROUTES.CLIENT_BONUSES.ROOT}>{t('bonuses.bonuses')}</Link>,
      visible: true,
      'data-test': 'Bonuses-Button',
    },
  ];

  const itemsBottom: (ItemType & { visible: boolean; 'data-test'?: string })[] = [
    {
      key: 'settings',
      icon: <IconSetting />,
      label: <Link to={ROUTES.SETTINGS.ROOT}>{t('side_menu.settings')}</Link>,
      visible: false,
      'data-test': 'Settings-Button',
    },
    {
      key: 'logout',
      icon: <IconLogoutRedesign />,
      label: t('side_menu.log_out'),
      visible: true,
      className: cssLogoutItem,
      onClick: () => onLogoutClick(),
      'data-test': 'Logout-Button',
    },
  ];

  const itemsTopVisible = itemsTop.filter((item) => item.visible).map(({ visible, ...item }) => item);
  const itemsBottomVisible = itemsBottom.filter((item) => item.visible).map(({ visible, ...item }) => item);

  return (
    <>
      <div className={cx(cssSiderWrapper, { [cssSiderWrapperCollapsed]: collapsed })}>
        <div className={cx(cssButton, cssCollapseButton, { [cssButtonActive]: !collapsed })} onClick={toggle}>
          {collapsed ? (
            <IconArrowRight color={'var(--pallete-bluegray-main)'} />
          ) : (
            <IconRemove color={'var(--pallete-bluegray-main)'} />
          )}
        </div>
        <>
          <Layout.Sider
            theme="light"
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={cssRoot}
            collapsedWidth={80}
            width={310}
            id="sider-Menu"
          >
            <div className={cssLogoWrapper}>
              {collapsed ? (
                whiteLabel?.logoMiniUrl ? (
                  <img src={whiteLabel?.logoMiniUrl} alt="LogoSmall" className={cssLogoMini} />
                ) : null
              ) : (
                <img src={whiteLabel?.logoUrl} alt="Logo" className={cssLogo} />
              )}
            </div>
            <div className={cssMenuWrapper}>
              <Menu className={cssMenu} selectedKeys={selectedKeys} items={itemsTopVisible} onClick={onClick} />
              <Menu className={cssMenu} selectedKeys={selectedKeys} items={itemsBottomVisible} onClick={onClick} />
            </div>
          </Layout.Sider>
          <div onClick={() => setCollapsed(!collapsed)} className={cx(cssMask, { [cssMaskActive]: !collapsed })} />
        </>
      </div>
    </>
  );
};

export default SideMenu;
