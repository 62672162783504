import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';

import { AnyObject } from 'types';
import pallete from 'styles/pallete';

const cssContainer = ({ colorBase }: { colorBase: ColorBase }) => css`
  position: relative;
  border-radius: 12px;
  background: ${colorBaseMap[colorBase]};
`;
const cssLabel = css`
  margin-left: 11px;
  padding-top: 5px;
  color: ${pallete.blueGray.main};
  transition: all 0.25s;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const cssActive = ({ hasError }: { hasError: boolean }) => css`
  color: ${hasError ? 'var(--ant-error-color)' : 'var(--ant-primary-color)'};
`;
const cssError = ({ errorPosition }: { errorPosition: Position }) => css`
  color: var(--ant-error-color);
  font-size: 12px;
  position: ${errorPosition};
`;
const cssAsterisk = css`
  color: var(--ant-error-color);
`;

export type Position = 'absolute' | 'initial';
export type ColorBase = 'none' | 'white' | 'grey';

const colorBaseMap: Record<ColorBase, string> = {
  none: 'none',
  white: pallete.basic.white,
  grey: pallete.blueGray.light,
};

interface LabelProps {
  label: string;
  focus: boolean;
  required: boolean;
  hasError: boolean;
}
export const Label = ({ label, focus, required, hasError }: LabelProps) => {
  return (
    <div className={cx(cssLabel, { [cssActive({ hasError })]: focus })}>
      {label}
      {required ? <span className={cssAsterisk}>*</span> : ''}
    </div>
  );
};

interface ErrorProps {
  message: string;
  errorPosition?: Position;
}
export const Error = ({ message, errorPosition = 'initial' }: ErrorProps) => {
  return (
    <div
      className={cx(
        cssError({ errorPosition }),
        // useses for scroll to the element
        'errorFormItem_message',
      )}
    >
      {message}
    </div>
  );
};

interface Props {
  label?: string;
  children?: React.ReactNode;
  className?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
}

// TODO front refactor for more customization
export const InputContainer = ({
  children,
  label,
  className,
  colorBase = 'none',
  errorPosition,
  requiredFields,
  hasError,
  isRequired,
  name,
  errors,
}: Props) => {
  const [focus, setFocus] = useState(false);
  const _required = isRequired ?? Boolean((requiredFields ?? {})[name ?? '']);
  const _hasError = hasError ?? Boolean((errors ?? {})[name ?? '']);

  return (
    // empty div needs for keeping all info into 1 node
    <div>
      <div
        id={name}
        className={cx(
          // needs for Group component
          'input-container_body',
          cssContainer({ colorBase }),
          className,
        )}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        {label !== undefined && <Label label={label} focus={focus} required={_required} hasError={_hasError} />}
        {children}
      </div>

      <ErrorMessage
        errors={errors ?? {}}
        name={name ?? ''}
        render={({ message }) => <Error message={message} errorPosition={errorPosition} />}
      />
    </div>
  );
};
