import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/next/Table/Table';
import { TransformedRisk } from 'screens/DashboardExtend/ByRisk/ByRisk.model';
import { currencyFormat } from 'utils/formatters';

interface Props {
  data: TransformedRisk[];
  currency: string;
}

const ByRiskTable = ({ data, currency }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<TransformedRisk>[] = [
    {
      title: t('dashboard_extend.byRisk.productName'),
      key: 'productName',
      render: (_, record) => record.productName,
    },
    {
      title: t('dashboard_extend.byRisk.riskName'),
      key: 'riskName',
      render: (_, record) => record.riskName,
    },
    {
      title: t('dashboard_HR.by_risks_contracts_count'),
      key: 'countContracts',
      render: (_, record) => record.countContracts,
    },
    {
      title: t('dashboard_extend.byRisk.contractPaymentSum'),
      key: 'contractRiskPaymentSum',
      render: (_, record) => currencyFormat(record.contractRiskPaymentSum ?? 0, currency),
    },
    {
      title: t('dashboard_HR.by_risks_claims_count'),
      key: 'countClaims',
      render: (_, record) => record.countClaims,
    },
    {
      title: t('dashboard_HR.by_risks_claims_sum'),
      key: 'claimRiskPaymentSum',
      render: (_, record) => currencyFormat(record.claimRiskPaymentSum ?? 0, currency),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => `${record.productId}-${record.riskId}`}
      scroll={{ x: 650, y: 265 }}
    />
  );
};

const MemoizedByRiskTable = React.memo(ByRiskTable);

export default MemoizedByRiskTable;
