import { SalesStatisticsDtoBigDecimal, ClaimPaymentsStatisticDto } from 'types/dto/contracts-service';

export const modifyDeviceTypes = (data: any, t: any) =>
  data.map((item) => {
    switch (item.name) {
      case `Гаджети здоров'я`:
        return { ...item, name: t('device.name_1') };
      case `Смартфони`:
        return { ...item, name: t('device.name_2') };
      case `Фени`:
        return { ...item, name: t('device.name_3') };
      case `Вантажні`:
        return { ...item, name: t('device.name_4') };
      case `Фітнес-браслети`:
        return { ...item, name: t('device.name_5') };
      case `Водонагрівачі`:
        return { ...item, name: t('device.name_6') };
      case `Роботи-пилососи`:
        return { ...item, name: t('device.name_7') };
      case `Гарнітури та геймпади`:
        return { ...item, name: t('device.name_8') };
      case `Мото`:
        return { ...item, name: t('device.name_9') };
      case `Бездротові геймпади`:
        return { ...item, name: t('device.name_10') };
      case `Духові шафи`:
        return { ...item, name: t('device.name_11') };
      case `Мишки і клавіатури`:
        return { ...item, name: t('device.name_12') };
      case `Витяжки`:
        return { ...item, name: t('device.name_13') };
      case `Проектори`:
        return { ...item, name: t('device.name_14') };
      case `Внутренняя отделка, инженерные сети и оборудование`:
        return { ...item, name: t('device.name_15') };
      case `Планшети`:
        return { ...item, name: t('device.name_16') };
      case `Окуляри віртуальної реальності`:
        return { ...item, name: t('device.name_17') };
      case `Акустики`:
        return { ...item, name: t('device.name_18') };
      case `Монітори`:
        return { ...item, name: t('device.name_19') };
      case `Тостери`:
        return { ...item, name: t('device.name_20') };
      case `Вантажні`:
        return { ...item, name: t('device.name_21') };
      case `Жорсткі диски для ноутбука`:
        return { ...item, name: t('device.name_22') };
      case `Телефони (кнопкові)`:
        return { ...item, name: t('device.name_23') };
      case `Моноблоки`:
        return { ...item, name: t('device.name_24') };
      case `Ноутбуки`:
        return { ...item, name: t('device.name_25') };
      case `Гарнітури Bluetooth`:
        return { ...item, name: t('device.name_26') };
      case `Неттопи та Системні блоки`:
        return { ...item, name: t('device.name_27') };
      case `Причепи`:
        return { ...item, name: t('device.name_28') };
      case `Телевізори`:
        return { ...item, name: t('device.name_29') };
      case `Товари розумний дім`:
        return { ...item, name: t('device.name_30') };
      case `Трактор`:
        return { ...item, name: t('device.name_31') };
      case `Ігрові консолі`:
        return { ...item, name: t('device.name_32') };
      case `Конструктивные элементы, коммуникации`:
        return { ...item, name: t('device.name_33') };
      case `Зволожувачі`:
        return { ...item, name: t('device.name_34') };
      case `Квартира`:
        return { ...item, name: t('device.name_35') };
      case `Материнські плати і відеокарти`:
        return { ...item, name: t('device.name_36') };
      case `Гарнітури для ПК`:
        return { ...item, name: t('device.name_37') };
      case `Медіаплеєри`:
        return { ...item, name: t('device.name_38') };
      case `Гражданская ответственность`:
        return { ...item, name: t('device.name_39') };
      case `Док-станції для зарядки`:
        return { ...item, name: t('device.name_40') };
      case `Плойки`:
        return { ...item, name: t('device.name_41') };
      case `Роутери`:
        return { ...item, name: t('device.name_42') };
      case `Екшн-камери`:
        return { ...item, name: t('device.name_43') };
      case `Зовнішні акумулятори`:
        return { ...item, name: t('device.name_44') };
      case `Рухоме майно`:
        return { ...item, name: t('device.name_45') };
      case `Блендери`:
        return { ...item, name: t('device.name_46') };
      case `Смарт-годинники`:
        return { ...item, name: t('device.name_47') };
      case `Морозильні камери`:
        return { ...item, name: t('device.name_48') };
      case `Праски`:
        return { ...item, name: t('device.name_49') };
      case `Відповідальність`:
        return { ...item, name: t('device.name_50') };
      case `Внутрішнє оздоблення`:
        return { ...item, name: t('device.name_51') };
      case `Саундбари`:
        return { ...item, name: t('device.name_52') };
      case `Пилососи`:
        return { ...item, name: t('device.name_53') };
      case `Сушильні барабани`:
        return { ...item, name: t('device.name_54') };
      case `Проекційні екрани`:
        return { ...item, name: t('device.name_55') };
      case `Миючі пилососи`:
        return { ...item, name: t('device.name_56') };
      case `Легкові`:
        return { ...item, name: t('device.name_57') };
      case `Пральні машини`:
        return { ...item, name: t('device.name_58') };
      case `Квадрокоптери`:
        return { ...item, name: t('device.name_59') };
      case `Бутербродниці`:
        return { ...item, name: t('device.name_60') };
      case `Звукові панелі`:
        return { ...item, name: t('device.name_61') };
      case `Електронні книги`:
        return { ...item, name: t('device.name_62') };
      case `Автобуси`:
        return { ...item, name: t('device.name_63') };
      case `Електротранспорт`:
        return { ...item, name: t('device.name_64') };
      case `Веб-камери`:
        return { ...item, name: t('device.name_65') };
      case `Внутреннее оснащение`:
        return { ...item, name: t('device.name_66') };
      case `Зовнішні колонки`:
        return { ...item, name: t('device.name_67') };
      case `Програмовані роботи`:
        return { ...item, name: t('device.name_68') };
      case `Чайники`:
        return { ...item, name: t('device.name_69') };
      case `Грилі`:
        return { ...item, name: t('device.name_70') };
      case `Павербанки`:
        return { ...item, name: t('device.name_71') };
      case `Конструктивні елементи, комунікації`:
        return { ...item, name: t('device.name_72') };
      case `Посудомийні машини`:
        return { ...item, name: t('device.name_73') };
      case `Принтери`:
        return { ...item, name: t('device.name_74') };
      default:
        return item;
    }
  });

export const modifyClaimPayments = (data: ClaimPaymentsStatisticDto[], t: any): ClaimPaymentsStatisticDto[] =>
  data.map((item) => {
    switch (item.name) {
      case `Ремонт через магазин`:
        return { ...item, name: t('claim.payments.name_1') };
      case `Експрес-виплата`:
        return { ...item, name: t('claim.payments.name_2') };
      case `Ремонт через сервісний центр, обраний самостійно`:
        return { ...item, name: t('claim.payments.name_3') };
      case `Виплата`:
        return { ...item, name: t('claim.payments.name_4') };
      default:
        return item;
    }
  });

export const modifyOrdinaryPayments = (data: SalesStatisticsDtoBigDecimal[], t: any): SalesStatisticsDtoBigDecimal[] =>
  data.map((item) => {
    switch (item.name) {
      case `Смартфони`:
        return { ...item, name: t('ordinary.payments.name_1') };
      case `Зовнішні колонки`:
        return { ...item, name: t('ordinary.payments.name_2') };
      default:
        return item;
    }
  });
