import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Spin, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import { RangeValue } from 'rc-picker/lib/interface';
import useAsyncEffect from 'use-async-effect';
import { useLocation } from 'react-router-dom';
import { RangePickerSharedProps } from 'rc-picker/lib/RangePicker';
import { css } from '@emotion/css';

import config from 'config';
import Button from 'components/next/Button/Button';
import { Select } from 'components/next/Select/Select';
import PageHeader from 'components/next/PageHeader/PageHeader';
import { RangePicker } from 'components/next/DatePicker/DatePicker';
import { getMajorContracts } from 'api/contracts';
import {
  DaysStatisticsDto,
  InsuranceProgramStatisticsDto,
  ProductRiskStatisticsDto,
  MajorContractDto,
  PartnerView,
} from 'types/dto/contracts-service';
import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { LocationState } from 'types';
import { checkPermissions } from 'roles/userCan';

import ByDays from './ByDays/ByDays';
import ByProgram from './ByProgram/ByProgram';
import ByRisk from './ByRisk/ByRisk';
import { apiContracts } from 'api/contracts';
import { Group } from 'components/next/Group/Group';
import { TextField } from 'components/next/TextField/TextField';
import { currencyFormat } from 'utils/formatters';
import { currencyMap } from 'config/currency';

const cssItem = css`
  white-space: nowrap;
`;
const cssTotal = css`
  margin-right: 14px;
`;

interface Item {
  label: string;
  total?: number;
  paymentAmount: number;
}

interface State {
  partners: PartnerView[];
  hasLoadedPartners: boolean;
  companyId: number;
  contracts: MajorContractDto[];
  hasLoadedContracts: boolean;
  contractId: number;
  period: RangeValue<Dayjs>;
  infoItems: Item[];
  loadingGeneralInfo: boolean;
  daysStatistics: DaysStatisticsDto[];
  loadingDaysStatistics: boolean;
  programStatistics: InsuranceProgramStatisticsDto[];
  loadingProgramStatistics: boolean;
  risksStatistics: ProductRiskStatisticsDto[];
  loadingRiskStatistics: boolean;
  currencyCode: string;
  isMonthPeriod: boolean;
}

const DashboardExtend = () => {
  const { t } = useTranslation();
  const location = useLocation<LocationState>();

  const partnerStored = useSelector((store: AppState) => store.me.data?.details?.partner);
  const insuranceCompany = useSelector((store: AppState) => store.me.data?.details?.insuranceCompany);

  const defaultInfoItems = [
    { label: t('dashboard_HR.info_insured'), paymentAmount: 0 },
    { label: t('dashboard_HR.info_terminated'), paymentAmount: 0 },
    { label: t('dashboard_HR.info_declared'), paymentAmount: 0 },
    { label: t('dashboard_HR.info_paid'), paymentAmount: 0 },
    { label: t('dashboard_HR.info_liability_limit'), paymentAmount: 0 },
    { label: t('dashboard_HR.info_corporate_limit'), paymentAmount: 0 },
  ];

  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      partners: [],
      hasLoadedPartners: false,
      companyId: 0,
      contracts: [],
      hasLoadedContracts: false,
      contractId: 0,
      period: [dayjs(), dayjs()],
      infoItems: defaultInfoItems,
      loadingGeneralInfo: false,
      daysStatistics: [],
      loadingDaysStatistics: false,
      programStatistics: [],
      loadingProgramStatistics: false,
      risksStatistics: [],
      loadingRiskStatistics: false,
      currencyCode: 'UAH',
      isMonthPeriod: true,
    },
  );

  useAsyncEffect(async () => {
    try {
      setState({
        loadingGeneralInfo: true,
        loadingDaysStatistics: true,
        loadingProgramStatistics: true,
        loadingRiskStatistics: true,
      });

      if (!state.hasLoadedPartners) {
        await apiContracts.partnerController
          .list2({ distinct: true, page_size: -1 })
          .then((res) => {
            setState({ hasLoadedPartners: true, partners: res.data.resultList ?? [] });
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              notification.error({ message: error.response.data.message });
            }
          });
      }

      if (!state.hasLoadedContracts) {
        await getMajorContracts()
          .then((res) => {
            setState({
              hasLoadedContracts: true,
              contracts: res ?? [],
            });
          })
          .catch((error) => {
            setState({
              hasLoadedContracts: true,
              loadingGeneralInfo: false,
              loadingDaysStatistics: false,
              loadingProgramStatistics: false,
              loadingRiskStatistics: false,
            });

            if (error?.response?.data?.message) {
              notification.error({ message: error.response.data.message });
            }
          });
      }

      const partner = state.partners.find((c) => c.id === state.companyId);

      const queryParams = {
        generalContractId: !!state.contractId ? state.contractId : undefined,
        insuranceCompanyId: insuranceCompany ? insuranceCompany.id : partner?.insuranceCompanyId,
        partnerId: partnerStored ? partnerStored.id : partner?.partnerId,
        startDate: state.period && state.period[0] ? state.period[0].utc().startOf('month').format() : '',
        endDate: state.period && state.period[1] ? state.period[1].utc().endOf('month').format() : '',
      };

      await apiContracts.statisticsController
        .dashboardGeneralInfo(queryParams)
        .then((res) => {
          setState({
            loadingGeneralInfo: false,
            infoItems: [
              {
                label: t('dashboard_HR.info_insured'),
                total: res.data?.countContracts ?? 0,
                paymentAmount: res.data?.contractsPaymentsSum ?? 0,
              },
              {
                label: t('dashboard_HR.info_terminated'),
                total: res.data?.countTerminations ?? 0,
                paymentAmount: res.data?.terminationsPaymentsSum ?? 0,
              },
              {
                label: t('dashboard_HR.info_declared'),
                total: res.data?.countClaims ?? 0,
                paymentAmount: res.data?.claimsPaymentsSum ?? 0,
              },
              {
                label: t('dashboard_HR.info_paid'),
                total: res.data?.countPayed ?? 0,
                paymentAmount: res.data?.payedPaymentsSum ?? 0,
              },

              {
                label: t('dashboard_HR.info_liability_limit'),
                paymentAmount: res.data?.generalLimit ?? 0,
              },
              {
                label: t('dashboard_HR.info_corporate_limit'),
                paymentAmount: res.data?.currentLimit ?? 0,
              },
            ],
          });
        })
        .catch((error) => {
          setState({ loadingGeneralInfo: false, infoItems: defaultInfoItems });

          if (error?.response?.data?.message) {
            notification.error({ message: error.response.data.message });
          }
        });

      await apiContracts.statisticsController
        .getDaysStatistics(queryParams)
        .then((res) => {
          setState({ loadingDaysStatistics: false, daysStatistics: res.data });
        })
        .catch((error) => {
          setState({ loadingDaysStatistics: false });

          if (error?.response?.data?.message) {
            notification.error({ message: error.response.data.message });
          }
        });

      await apiContracts.statisticsController
        .programPaymentInfo(queryParams)
        .then((res) => {
          setState({ loadingProgramStatistics: false, programStatistics: res.data.programStatistics });
        })
        .catch((error) => {
          setState({ loadingProgramStatistics: false, programStatistics: [] });

          if (error?.response?.data?.message) {
            notification.error({ message: error.response.data.message });
          }
        });

      await apiContracts.statisticsController
        .riskPaymentInfo(queryParams)
        .then((res) => {
          setState({ loadingRiskStatistics: false, risksStatistics: res.data });
        })
        .catch((error) => {
          setState({ loadingRiskStatistics: false, risksStatistics: [] });

          if (error?.response?.data?.message) {
            notification.error({ message: error.response.data.message });
          }
        });
    } catch (error) {}
  }, [state.contractId, state.companyId, state.period]);

  const onChangeCompany: SelectProps['onChange'] = (value) => {
    setState({ companyId: Number(value) });
  };

  const onChangeContract: SelectProps['onChange'] = (value) => {
    setState({ contractId: Number(value) });
  };

  const onChangeDate: RangePickerSharedProps<Dayjs>['onChange'] = (value) => {
    if (!value) {
      return;
    }

    setState({
      period: value,
      isMonthPeriod: dayjs(value[0]).format('MMM YYYY') === dayjs(value[1]).format('MMM YYYY'),
    });
  };

  const partnersOptions: DefaultOptionType[] = (
    [0, 1].includes(state.partners.length)
      ? state.partners
      : [{ id: 0, name: t('dashboard_extend.ALL') }, ...state.partners]
  ).map((el) => ({
    value: el.id ?? 0,
    label: el.name,
  }));

  const contractOptions: DefaultOptionType[] = [
    { generalContractId: 0, generalContractNumber: t('dashboard_extend.ALL') },
    ...state.contracts,
  ].map((el) => ({
    value: el.generalContractId ?? 0,
    label: el.generalContractNumber,
  }));

  const currency = currencyMap[state.currencyCode] ?? state.currencyCode;

  const onStartDayClick = () => {
    const contractDay =
      state.contracts.find(({ generalContractId }) => state.contractId === generalContractId)?.contractStartDate ??
      dayjs();
    setState({ period: [dayjs(contractDay), dayjs()] });
  };

  const menu = [
    {
      path: ROUTES.DASHBOARD.EXTEND,
      breadcrumbName: 'Dashboard-1',
      visible: checkPermissions('DASHBOARD', 'EXTEND'),
    },
    {
      path: ROUTES.DASHBOARD.MAIN,
      breadcrumbName: 'Dashboard-2',
      visible: checkPermissions('DASHBOARD', 'ANALYTICS'),
    },
    {
      path: ROUTES.DASHBOARD.MEDICINE,
      breadcrumbName: 'Dashboard - Medicine',
      visible: checkPermissions('DASHBOARD', 'MEDICINE'),
    },
    {
      path: ROUTES.DASHBOARD.SETTLEMENT,
      breadcrumbName: 'Dashboard - Settlement',
      visible: checkPermissions('DASHBOARD', 'SETTLEMENT'),
    },
  ];

  return (
    <>
      <PageHeader
        title={t('dashboard_HR.home')}
        breadcrumb={{
          routes: [
            { path: ROUTES.DASHBOARD.ROOT, breadcrumbName: t('dashboard_HR.home') },
            {
              path: '',
              breadcrumbName:
                menu.filter((el) => el.visible).find((m) => m.path === location.pathname)?.breadcrumbName ?? 'unknown',
              children: menu.filter((m) => m.path !== location.pathname && m.visible),
            },
          ],
        }}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row align="bottom" justify="space-between" gutter={[16, 16]}>
            <Col span={12}>
              <Row align="middle" gutter={[16, 16]}>
                <Col>
                  <Typography.Text>{t('dashboard_extend.data_for_the_period')} </Typography.Text>
                </Col>
                <Col>
                  <RangePicker
                    allowClear={false}
                    disabledDate={(date) => !dayjs(date).isBetween('2018-01-01', dayjs().endOf('month'))}
                    colorBase="white"
                    value={state.period}
                    onChange={onChangeDate}
                    picker="month"
                    format={config.format.monthYear}
                    renderExtraFooter={() => {
                      return (
                        <Row justify="center" style={{ margin: '8px 0' }}>
                          <Col>
                            <Button onClick={onStartDayClick} disabled={!state.contractId}>
                              {t('dashboard_HR.from_start')}
                            </Button>
                          </Col>
                        </Row>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col span={4}>
              <Select
                value={[0, 1].includes(state.partners.length) ? state.partners[0]?.id : state.companyId}
                options={partnersOptions}
                label={t('dashboard_HR.partner')}
                onChange={onChangeCompany}
                loading={!state.hasLoadedPartners}
                colorBase="white"
                disabled={!checkPermissions('DASHBOARD', 'ANALYTICS')}
              />
            </Col>
            <Col span={8}>
              <Select
                value={state.contractId}
                options={contractOptions}
                label={t('dashboard_HR.contract')}
                onChange={onChangeContract}
                loading={!state.hasLoadedContracts}
                colorBase="white"
                showSearch
              />
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Spin spinning={state.loadingGeneralInfo}>
            <Group>
              {state.infoItems.map((el, i) => {
                const total = el.total ? (
                  <span className={cssTotal}>{t('dashboard_extend.contract', { count: el.total })}</span>
                ) : null;

                return (
                  <TextField key={i} label={el.label} colorBase="white">
                    <Typography.Text strong className={cssItem}>
                      {total} {currencyFormat(el.paymentAmount, currency)}
                    </Typography.Text>
                  </TextField>
                );
              })}
            </Group>
          </Spin>
        </Col>

        <Col sm={24} md={12} xl={8} span={24}>
          <Spin spinning={state.loadingDaysStatistics} size="large">
            <ByDays data={state.daysStatistics} isMonthPeriod={state.isMonthPeriod} currency={currency} />
          </Spin>
        </Col>

        <Col sm={24} md={12} xl={8} span={24}>
          <Spin spinning={state.loadingProgramStatistics} size="large">
            <ByProgram data={state.programStatistics} currency={currency} />
          </Spin>
        </Col>

        <Col sm={24} md={12} xl={8} span={24}>
          <Spin spinning={state.loadingRiskStatistics} size="large">
            <ByRisk data={state.risksStatistics} currency={currency} />
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default DashboardExtend;
