import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { css } from '@emotion/css';

import ClientAuthContainer from 'components/nextRedesign/ClientAuthContainer/ClientAuthContainer';
import { breakpoints } from 'styles/breakpoints';

import Recovery from './Recovery/Recovery';
import Password from './Password/Password';

const cssHeader = css`
  &.ant-typography {
    font-size: 44px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: ${breakpoints.small}) {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
`;

export type Step = 'recovery' | 'password';
export interface State {
  step: Step;
  isVerificationModalOpen: boolean;
  isSuccessModalOpen: boolean;
  login: string;
  token: string;
  password: string;
  confirmPassword: string;
}

const ClientPasswordRecovery = () => {
  const { t } = useTranslation();

  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      step: 'recovery',
      isVerificationModalOpen: false,
      isSuccessModalOpen: false,
      login: '',
      token: '',
      password: '',
      confirmPassword: '',
    },
  );

  const steps: Record<Step, React.ReactNode> = {
    recovery: Recovery({ state, setState }),
    password: Password({ state, setState }),
  };

  return (
    <ClientAuthContainer>
      <Typography.Title className={cssHeader}>
        {state.step === 'recovery' ? t('log_in.forgot_password') : t('log_in.create_your_password')}
      </Typography.Title>

      {steps[state.step]}
    </ClientAuthContainer>
  );
};

export default ClientPasswordRecovery;
