import React from 'react';
import { IconProps } from '@ant-design/compatible/lib/icon';
import { css, cx } from '@emotion/css';
import { iconMap, Type } from './SVG';
import palleteRedesign from 'styles/palleteRedesign';

type BaseColor = 'primary' | 'warning' | 'error' | 'success' | 'gray' | 'white' | 'black' | 'grayLighter';
type Color = BaseColor | 'inherit';
type Background = 'primary' | 'warning' | 'error' | 'success' | 'green' | 'yellow' | 'red' | 'grayLighter' | 'none';

export interface Props extends IconProps {
  type: Type;
  color?: Color;
  background?: Background;
  size?: string;
}

const Icon = ({ type, color = 'inherit', background = 'none', className, size = '16px', ...props }: Props) => {
  const IconComponent = iconMap[type];

  const colorMap: Record<Color, string> = {
    primary: 'var(--ant-primary-color)',
    warning: palleteRedesign.warning.main,
    error: palleteRedesign.error.main,
    success: palleteRedesign.success.main,
    gray: palleteRedesign.gray.main,
    white: palleteRedesign.basic.white,
    black: palleteRedesign.basic.black,
    grayLighter: palleteRedesign.gray.light,
    inherit: 'inherit',
  };

  const backgroundMap: Record<Background, string> = {
    primary: 'var(--ant-primary-color-outline)',
    warning: palleteRedesign.warning.light,
    error: palleteRedesign.error.light,
    success: palleteRedesign.success.light,
    green: palleteRedesign.success.main,
    yellow: palleteRedesign.warning.main,
    red: palleteRedesign.error.main,
    grayLighter: palleteRedesign.gray.lighter,
    none: 'none',
  };

  return (
    <IconComponent
      className={cx(
        css`
          && {
            font-size: ${size};
            padding: 8px;
            border-radius: 8px;
            background: ${backgroundMap[background]};
            color: ${colorMap[color]};
          }
        `,
        className,
      )}
      {...props}
    />
  );
};

export default Icon;
