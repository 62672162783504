import { createTypes } from 'redux-compose-reducer';
import * as sublimitsAPI from 'api/sublimits';
import { AppState } from 'store/reducer';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';

type TSetSorting = { field: string; direction: string };

export const TYPES = createTypes('sublimits', [
  'setPending',
  'setError',
  'setSublimits',
  'setSublimitsChildless',
  'setSubsublimits',
  'setSublimitById',
  'setSubsublimitById',
  'setSorting',
  'setPagination',
  'setFilters',
]);

const setPending = (pending: boolean) => ({
  type: TYPES.setPending,
  payload: { pending },
});

const setError = (error) => ({
  type: TYPES.setError,
  payload: { error },
});

export const getSublimits = () => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await sublimitsAPI.getSublimits();
    dispatch({ type: TYPES.setSublimits, payload: { sublimits: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const getSublimitsChildless = () => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await sublimitsAPI.getSublimitsChildless();
    dispatch({ type: TYPES.setSublimitsChildless, payload: { sublimitsChildless: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const getSubsublimits = () => async (dispatch, getState) => {
  const {
    sublimits: { pagination, sorting, filters },
  }: AppState = getState();
  try {
    dispatch(setPending(true));
    const response = await sublimitsAPI.getSubsublimits({ pagination, sorting, filters });
    dispatch({ type: TYPES.setSubsublimits, payload: { subsublimits: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const getSublimitById = (id) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await sublimitsAPI.getSublimitsById(id);
    dispatch({ type: TYPES.setSublimitById, payload: { sublimit: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const getSubsublimitById = (id) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await sublimitsAPI.getSubsublimitsById(id);
    dispatch({ type: TYPES.setSubsublimitById, payload: { subsublimit: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const addSublimit = (data) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await sublimitsAPI.addSublimit(data);
    const response = await sublimitsAPI.getSublimits();
    dispatch({ type: TYPES.setSublimits, payload: { sublimits: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const addSubsublimit = (data) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await sublimitsAPI.addSubsublimit(data);
    history.push(ROUTES.MEDAPP.SUBLIMITS.ROOT);
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const editSublimit = (id, data) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await sublimitsAPI.editSublimit(id, data);
    const response = await sublimitsAPI.getSublimits();
    dispatch({ type: TYPES.setSublimits, payload: { sublimits: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const editSubsublimit = (id, data) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await sublimitsAPI.editSubsublimit(id, data);
    history.push(ROUTES.MEDAPP.SUBLIMITS.ROOT);
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const setSorting = ({ field, direction }: TSetSorting) => ({
  type: TYPES.setSorting,
  payload: { field, direction },
});

export const setPagination = (current: number, perPage: number) => (dispatch) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setFilters = (sublimitName: string, subsublimitName: string, multiFieldFilter: boolean) => (dispatch) => {
  dispatch({ type: TYPES.setFilters, payload: { sublimitName, subsublimitName, multiFieldFilter } });
};
