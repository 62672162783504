import i18n from 'translations/i18n';
import { ActionTypes, List } from 'types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/reducer';
import { notification } from 'antd';
import history from 'routes/history';
import { apiContracts } from 'api/contracts';
import config from 'config';
import dayjs from 'dayjs';
import { apiConfiguration } from 'api/configuration';
import { ROUTES } from 'constants/routes';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface InsuredPersonsModal {
  isCreate?: boolean;
  isOpen?: boolean;
  loading?: boolean;
}

export const actions = {
  setInsuredPersonsModal: (payload: InsuredPersonsModal | null, isFullReset?: boolean) =>
    ({ type: 'PROFILE/SET_INSURED_PERSONS_MODAL', payload, isFullReset } as const),
};

export interface StoreProfile {
  insuredPersonsModal: InsuredPersonsModal | null;
}

export const initialState: StoreProfile = {
  insuredPersonsModal: null,
};

const reducer = (state = initialState, action: InferActionTypes): StoreProfile => {
  switch (action.type) {
    case 'PROFILE/SET_INSURED_PERSONS_MODAL':
      return {
        ...state,
        insuredPersonsModal: action.isFullReset
          ? null
          : {
              ...state.insuredPersonsModal,
              ...action.payload,
            },
      };
    default:
      return state;
  }
};

export default reducer;
