import IconButton from '../../../uikit/IconButton';
import Close from '../../../Button/icons/Close';
import React from 'react';

const ClearButton = (props) => (
  <IconButton {...props} size={'small'} variant={'gray'}>
    <Close />
  </IconButton>
);

export default ClearButton;
