import React, { useState, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash/fp';

import Modal from '../../components/Modal';
import Previewer from '../../components/Previewer';
import { Scrollbars } from 'react-custom-scrollbars';
import ItemsList, { builders, selectors } from '../../components/List';
import { useModal } from 'screens/Appointments/hooks/useModal';
import { ChevronRightIcon, Check } from 'components/Icons';
import { AType, RawType } from 'types/institutions';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';
import { Picker, WithErrors, WithVariants } from 'types/appointments';
import { defaultValues } from 'screens/Appointments/hooks/useForm';

import styles from './AppointmentType.module.less';

export const AppointmentTypePicker: React.FC<Picker<RawType> & WithErrors & WithVariants<AType>> = ({
  defaultValue,
  error,
  variants,
  onClose,
  disabled,
  clearError,
}) => {
  const { isOpen, open, close, confirm, picked, onPick } = useModal(onClose, defaultValue);

  const { t } = useTranslation();

  const [node, setNode] = useState<AType>();

  useEffect(() => {
    if (defaultValue.id === -1) {
      onPick(defaultValue);
      setNode(undefined);
    }
  }, [defaultValue]);

  const handleNodeClick = (node: AType) => {
    if (node.children?.length > 0) {
      return setNode(node);
    }
    onPick(isEqual(node, picked) ? defaultValues.type : node);
  };

  const onSubmit = () => {
    clearError('type');
    setNode(undefined);
    confirm();
  };

  return (
    <>
      <Previewer
        displayValue={defaultValue.name || defaultValue.claimNumber}
        onClick={open}
        errorMessage={error ? t('appointments.selector_type_title') : ''}
        title={defaultValue || error ? t('appointments.type_title') : t('appointments.selector_type_title')}
        disabled={disabled}
      />
      {isOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              onPick(defaultValue);
              setNode(undefined);
              close();
            }}
          >
            <Modal.Header
              title={node ? t('appointments.by_appointment') : t('appointments.type_title')}
              onClose={() => {
                onPick(defaultValue);
                setNode(undefined);
                close();
              }}
              renderArrow={!!node}
              onArrowClick={() => setNode(void 0)}
            />
            <Modal.Body>
              <Scrollbars autoHeight autoHeightMax={MAX_SCROLLBAR_HEIGHT}>
                <ItemsList
                  items={node?.children ?? variants}
                  itemBuilder={builders.combine(
                    builders.byName,
                    // builders.whether(selectors.byIdAndChild(picked?.id, picked?.parentId), builders.byN),
                    builders.whether(
                      selectors.hasChildren,
                      builders.postfix(
                        builders.whether(
                          selectors.byId(picked?.parentId),
                          builders.node(<span className={styles.match}>{picked?.claimNumber}</span>),
                        ),
                        builders.node(<ChevronRightIcon />),
                      ),
                    ),
                    builders.whether(selectors.checkClaim, builders.byClaim),
                  )}
                  selector={selectors.byId(picked?.id, picked?.parentId)}
                  onItemClick={handleNodeClick}
                />
              </Scrollbars>
            </Modal.Body>
            <Modal.Confirm disabled={isEqual(defaultValue, picked)} onClick={onSubmit} />
          </OutsideClickHandler>
        </Modal>
      )}
    </>
  );
};
