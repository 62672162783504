import React, { useState, FunctionComponent } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Typography } from 'antd';
import classNames from 'classnames';

import { ROUTES } from 'constants/routes';
import { initialState, initialActiveState } from './userState';

import styles from '../auth.module.less';
import sign from './SignUp.module.less';
import arrowLeft from 'assets/img/layout/arrowLeft.svg';

import Header from '../components/header';
import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import HintCounter from '../ManagerSignUp/widgets/HintCounter';

import { passwordRegExp, countdownDelay, asyncErr } from '../ManagerSignUp/utils';
import { TActiveState, TStepValues, IInsurerSignup } from './types';
import SpinnerModal from '../ManagerSignUp/widgets/SpinnerModal';
import { validateUser, getSignupToken, sendSignupData, sendSignupToken } from './catchHandlers';
import { setAsyncError } from '../ManagerSignUp/catchHandlers/getSignupToken';
import history from '../../../routes/history';
import { useTranslation } from 'react-i18next';

const InsurerSignUp: FunctionComponent<IInsurerSignup> = ({ form, form: { getFieldValue, validateFields } }) => {
  const [step, setStep] = useState<number>(0);
  const [values, setValues] = useState<TStepValues>(initialState);
  const [fieldsActiveState, setFieldsActiveState] = useState<TActiveState>(initialActiveState);
  const [pending, setPending] = useState<boolean>(false);

  const handleFields = (eventName: string, fieldName: string): void => {
    if (!!getFieldValue(fieldName)) return;
    setFieldsActiveState({ ...fieldsActiveState, [fieldName]: eventName === 'focus' });
  };

  const onTimeEnd = (): void => {
    setStep(0);
    setValues({ ...initialState, phoneNumber: values.phoneNumber });
    setFieldsActiveState({ ...initialActiveState });
  };

  const changeStep = (step: number): void => {
    setStep(step);
  };

  const redirectToLogin = () => {
    history.push(ROUTES.AUTH.LOGIN);
  };

  const loginHandler = (): void => {
    redirectToLogin();
  };

  const setBeforeSubmit = (fields: TStepValues): void => {
    setValues({
      ...values,
      ...fields,
      phoneNumber: fields.phoneNumber || values.phoneNumber,
      token: fields.token || values.token,
    });
  };

  const setPendingState = (state: boolean): void => setPending(state);

  const onSuccess = (fields: TStepValues): void => {
    if (fields) {
      setValues({ ...fields });
    }
    setStep(step + 1);
  };

  const resendSms = () => {
    getSignupToken({ ...values, phoneNumber: `+380${values.phoneNumber}` }, null, saveAfterResendCode, form, t);
  };

  const saveAfterResendCode = (fields: TStepValues) => {
    if (fields) {
      setValues({ ...fields });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    validateFields((err, fields: TStepValues): void => {
      if (err) return;

      switch (step) {
        case 0:
          setBeforeSubmit({ ...fields, phoneNumber: fields.phoneNumber });
          validateUser(fields, setPendingState, onSuccess, form, t);
          break;
        case 1:
          setBeforeSubmit({ ...fields, token: fields.token });
          sendSignupToken({ ...fields, phoneNumber: `+380${fields.phoneNumber}` }, setPendingState, onSuccess, form, t);
          break;
        case 2:
          const { password, confirmPassword } = fields;
          if (password === confirmPassword && passwordRegExp.test(password)) {
            const createdObject = {
              ...fields,
              token: values.token,
              phoneNumber: `+380${values.phoneNumber}`,
              email: fields.email || null,
            };
            setBeforeSubmit(fields);
            sendSignupData(createdObject, setPendingState, loginHandler, form, t);
          } else {
            setAsyncError([{ field: 'confirmPassword', code: 'no_match' }], form, asyncErr);
          }
          break;
      }
    });
  };
  const { t } = useTranslation();

  return (
    <div className={styles.authWrapper}>
      <Header />
      <div className={classNames(styles.formContainer, sign.signup_container)}>
        <span className={sign.green_text}>{`${t('signup.step')} ${step + 1 + '/' + 3}`}</span>
        <Form onSubmit={handleSubmit} className="login-form">
          {(step === 1 || step === 2) && <HintCounter step={step} delay={countdownDelay} onTimeEnd={onTimeEnd} />}
          {step === 0 && <FirstStep form={form} fieldsActiveState={fieldsActiveState} values={values} />}
          {step === 1 && (
            <SecondStep
              fieldsActiveState={fieldsActiveState}
              handleFields={handleFields}
              values={values}
              changeStep={changeStep}
              resendSms={resendSms}
              form={form}
            />
          )}
          {step === 2 && (
            <ThirdStep form={form} fieldsActiveState={fieldsActiveState} handleFields={handleFields} values={values} />
          )}
        </Form>
        <div className={sign.bottomLink}>
          {step > 0 ? (
            <div className={styles.backButton}>
              <Button type="link" ghost onClick={() => setStep(0)}>
                <img className={sign.leftIcon} src={arrowLeft} alt="right icon" />
                <Typography.Text className={classNames(sign.small_text, sign.gray_text)}>
                  {t('signup.back')}
                </Typography.Text>
              </Button>
            </div>
          ) : (
            <div className={classNames(styles.registration, sign.small_text, sign.gray_text)}>
              <span className={sign.leftIcon}>{t('signup.have_a_account')}</span>
              <span onClick={redirectToLogin} className={styles.link}>
                <span className={classNames(sign.small_text, sign.green_text)}>{t('signup.log_in')}</span>
              </span>
            </div>
          )}
          <SpinnerModal isOpen={pending} />
        </div>
      </div>
    </div>
  );
};

export default Form.create<IInsurerSignup>({ name: 'InsurerSignup' })(InsurerSignUp);
