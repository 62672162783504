import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Login from 'screens/Login/Login';
import ClientLoginNext from 'screens/ClientLogin/ClientLogin';
import ClientSignUpNext from 'screens/ClientSignUp/ClientSignUp';
import ClientPasswordRecovery from 'screens/ClientPasswordRecovery/ClientPasswordRecovery';
import PasswordRecovery from 'components/auth/PasswordRecovery';
import PasswordRecoveryNext from 'screens/PasswordRecovery/PasswordRecovery';
import SignUpClient from 'components/auth/SignUp';
import SignUp from 'screens/SignUp/SignUp';
import LayoutRouting from './LayoutRouting';

import history from './history';
import ErrorPageBoundary from './ErrorPageBoundary';
import { ClientSign } from '../components/ClientSign/ClientSign';
import { PaymentResultPage } from '../components/ClientSign/pages/PaymentResultPage';
import JoinConsultationForDoctor from 'screens/JoinConsultationForDoctor';
import VideoChatForDoctor from 'screens/VideoChatForDoctor';
import PageLoading from 'components/PageLoading';
import { RoleEnum } from 'types/dto/auth-service';
import { ClientLayoutRouting } from './ClientLayoutRouting';
import { getUserRoleNext } from 'roles/userCan';

const PaymentFondy = lazy(() => import('screens/PaymentFondy/PaymentFondy'));

const Routes = () => {
  const role = getUserRoleNext();
  return (
    <Suspense fallback={<PageLoading />}>
      <Router history={history}>
        <ErrorPageBoundary>
          <Switch>
            <Redirect exact from={ROUTES.ROOT} to={ROUTES.PARTNER_AUTH.LOGIN} />
            <Route exact path={ROUTES.JOIN_CONSULTATION_FOR_DOCTOR} component={JoinConsultationForDoctor} />
            <Route exact path={ROUTES.VIDEO_CHAT_FOR_DOCTOR} component={VideoChatForDoctor} />
            <Route exact path={ROUTES.PARTNER_AUTH.LOGIN} component={Login} />
            <Route exact path={ROUTES.AUTH.LOGIN} component={ClientLoginNext} />
            <Route exact path={ROUTES.AUTH.CLIENT_LOGIN} component={ClientLoginNext} />
            <Route exact path={ROUTES.AUTH.CLIENT_SIGNUP} component={ClientSignUpNext} />
            <Route exact path={ROUTES.AUTH.FORGOT_PASSWORD} component={ClientPasswordRecovery} />
            <Route exact path={ROUTES.PARTNER_AUTH.PASSWORD_RECOVERY} component={PasswordRecoveryNext} />
            <Route exact path={ROUTES.AUTH.PASSWORD_RECOVERY} component={PasswordRecovery} />
            <Route exact path={ROUTES.AUTH.INSURER_SIGNUP} component={SignUpClient} />
            <Route exact path={ROUTES.AUTH.SIGN_UP} component={SignUp} />
            <Route exact path={ROUTES.CLIENT_SIGN} component={ClientSign} />
            <Route exact path={ROUTES.CLIENT_PAYMENT} component={PaymentResultPage} />
            <Route exact path={ROUTES.PAYMENT_FONFY} component={PaymentFondy} />
            <Route component={role === RoleEnum.ROLE_CLIENT ? ClientLayoutRouting : LayoutRouting} />
          </Switch>
        </ErrorPageBoundary>
      </Router>
    </Suspense>
  );
};

export default Routes;
