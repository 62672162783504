const coordinatesRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
const enterpeiceCodeRegex = /^\d{8}$|^\d{10}$/;

export const FormRules = (t) => {
  const NAME_RULES = [
    {
      required: true,
      message: t('validation.enter_data'),
    },
    { min: 1, message: 'Мінімальна кількість - 1 символ' },
    { max: 50, message: 'Максимальна кількість - 50 символів' },
  ];
  const USREOU_RULES = [
    {
      required: true,
      message: t('validation.enter_data'),
    },
    { pattern: new RegExp(enterpeiceCodeRegex), message: t('clinics.enterprise_code_min_max') },
  ];
  const COORDINATE_RULES = [
    {
      required: true,
      message: t('validation.enter_data'),
    },
    { pattern: new RegExp(coordinatesRegex), message: t('clinics.wrong_format') },
  ];

  return {
    NAME_RULES,
    USREOU_RULES,
    COORDINATE_RULES,
  };
};
