import i18n from 'i18next';
import isEmail from 'is-email';

import { string, refiner, object, Infer } from 'utils/struct';

export const schema = object({
  login: refiner(string(), (value) => {
    if (!value) {
      return i18n.t('passwordRecovery.please_enter_your_e-mail_or_phone').toString();
    }

    if (/^\+/.test(value.trim())) {
      if (!/^\+\d+$/.test(value.trim())) {
        return i18n.t('schema.phoneNumber').toString();
      }

      return true;
    }

    if (!isEmail(value)) {
      return i18n.t('schema.email').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  login: '',
};
