import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';

import ItemsList from '../../components/List';
import { Time, ISlot, ITime } from 'types/appointments';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';

import styles from './Time.module.less';

export const formatHours = (hour: number): string => {
  const integer = Math.trunc(hour);
  const fractional = hour - integer;
  return [(integer < 10 ? '0' : '') + integer, fractional * 60 + (fractional === 0 ? '0' : '')].join(':');
};

interface TimePickerProps {
  time: Time;
  slots: Array<ISlot>;
  onChange: (value: ITime) => void;
}

export const TimePickerDrEleks: React.FC<TimePickerProps> = ({ time, slots, onChange }) => {
  const PLANK_HEIGHT_STANDARD = 54;

  const { t } = useTranslation();

  const handleClick = ({ slot, venueRef, userRef, timeTableStartTime, timeTableEndTime }: ISlot) => {
    if (
      !time.start ||
      (time.end && slot > time.end && slot - time.end > 0.5) ||
      (time.start && slot < time.start && time.start - slot > 0.5)
    ) {
      return onChange({
        time: { start: slot, end: slot + 0.5 },
        venueRef,
        userRef,
        timeTableStartTime,
        timeTableEndTime,
      });
    }
    if (time.start === slot) {
      return onChange({
        time: { start: undefined, end: undefined },
        venueRef,
        userRef,
        timeTableStartTime,
        timeTableEndTime,
      });
    }

    onChange({
      time: { start: slot, end: slot + 0.5 },
      venueRef,
      userRef,
      timeTableStartTime,
      timeTableEndTime,
    });
  };

  const selector = (hour: number) => {
    return (
      time?.start === hour ||
      (time.start && time.end && hour - time.start === 0.5 && hour !== time.end) ||
      (time.start && time.end && hour > time.start && hour < time.end)
    );
  };

  const factorHeight = (hour: number) => {
    const factor = time.end && time.start ? (time.end - time.start) / 0.5 : 1;
    return PLANK_HEIGHT_STANDARD * (time.start === hour ? factor : 1);
  };

  return (
    <Scrollbars autoHeight autoMaxHeight={MAX_SCROLLBAR_HEIGHT}>
      {slots.length ? (
        <ItemsList
          itemClassName={styles.listItem}
          items={slots}
          itemBuilder={(el) =>
            time.start && time.end && el.slot > time?.start && el.slot < time?.end ? null : (
              <div onClick={() => handleClick(el)} className={styles.item}>
                <p className={styles.hours}>{formatHours(el.slot)}</p>
                <div
                  className={classNames(styles.plank, selector(el.slot) && styles.selected)}
                  style={{
                    height: factorHeight(el.slot),
                  }}
                />
              </div>
            )
          }
        />
      ) : (
        <div className={styles.dateMessage}>{t('appointments.selector_date')}</div>
      )}
    </Scrollbars>
  );
};
