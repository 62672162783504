import React from 'react';
import { PageHeader as BasePageHeader } from 'antd';
import { Link } from 'react-router-dom';
import { BreadcrumbProps } from 'antd/lib/breadcrumb/Breadcrumb';
import { PageHeaderProps } from 'antd/lib/page-header';
import { css, cx } from '@emotion/css';

const cssRoot = css`
  &.ant-page-header {
    padding-right: 0;
    padding-left: 0;
  }

  .ant-page-header-heading-title {
    white-space: initial;
    text-overflow: initial;
  }

  .ant-page-header-heading-tags {
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-page-header-heading-left {
    flex-wrap: wrap;
  }

  .ant-page-header-heading {
    flex-wrap: wrap;
  }

  .ant-space {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const itemRender: BreadcrumbProps['itemRender'] = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;

  return last ? route.breadcrumbName : <Link to={route.path}>{route.breadcrumbName}</Link>;
};

interface Props extends PageHeaderProps {
  children?: React.ReactNode;
}

export const PageHeader = ({ title, breadcrumb, className, ...props }: Props) => {
  return (
    <BasePageHeader
      title={title}
      breadcrumb={{ itemRender, ...breadcrumb }}
      className={cx(cssRoot, className)}
      {...props}
    />
  );
};

export default PageHeader;
