import { ThunkAction } from 'redux-thunk';
import { notification } from 'antd';

import { AppState } from 'store/reducer';
import { ActionTypes } from 'types';
import { ListActivitiesParams, UserActivityLogView } from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import { Values, initialValues } from 'screens/User/UserActivity/Filters/Filters.schema';
import { getDateRange2 } from 'utils/request';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

type Filters = Values;
interface Data {
  loading: boolean;
  data: UserActivityLogView[];
  filters: Filters;
}

export const actions = {
  setActivity: (payload: Partial<Data>) => ({ type: 'user_activity/SET_DATA', payload } as const),
};

export const getUserActivity =
  (query: ListActivitiesParams): ThunkType<void> =>
  (dispatch, getState) => {
    const activity = getState().user.activity.data;

    dispatch(actions.setActivity({ loading: true, data: [] }));

    apiContracts.userController
      .listActivities({
        attributes: 'activityDate,shortName,login,action,userId',
        page_size: -1,
        activityDate: getDateRange2(activity.filters?.dateFrom, activity.filters?.dateTo, { keepDate: true }) ?? '',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setActivity({ loading: false, data: res.data.resultList }));
      })
      .catch((error) => {
        dispatch(actions.setActivity({ loading: false, data: [] }));

        if (error === undefined) {
          return Promise.reject();
        }

        notification.error({ message: error.message });

        return Promise.reject();
      });
  };

export interface StoreUserActivity {
  data: Data;
}
const initialState: StoreUserActivity = {
  data: { loading: false, data: [], filters: initialValues },
};

const reducer = (state = initialState, action: InferActionTypes): StoreUserActivity => {
  switch (action.type) {
    case 'user_activity/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
