import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/next/Button/Button';

interface Props {
  isRunning: boolean;
  timerUI: string;
  handleResend: () => void;
}

// TODO front is it need?
export const Resend = ({ isRunning, timerUI, handleResend }: Props) => {
  const { t } = useTranslation();

  return (
    <Button onClick={handleResend} disabled={Boolean(isRunning)} type="link" data-test="resend">
      {isRunning ? `${t('signup.send_again')} ${timerUI}` : t('signup.send_again')}
    </Button>
  );
};
