import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import {
  PartnerView,
  ResultListDtoAuthUserView,
  ResultListDtoUserActivityLogView,
  ListActivitiesParams,
  ResultListDtoDepartmentView,
  List5Params,
} from 'types/dto/contracts-service';
import { getDateRange2, getDateRange3, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/UserActivities/Filters/Filters.schema';
import config from 'config';
import { apiContracts } from 'api/contracts';
import onDownload from 'callbacks/onDownload';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoUserActivityLogView | null;
}
type Filters = Values & { initialization: boolean };
interface Partners {
  loading: boolean;
  data: PartnerView[];
  id: number | null;
}
interface Departments {
  loading: boolean;
  data: ResultListDtoDepartmentView | null;
}
interface Logins {
  loading: boolean;
  data: ResultListDtoUserActivityLogView | null;
}
interface ShortNames {
  loading: boolean;
  data: ResultListDtoUserActivityLogView | null;
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'USER_ACTIVITIES/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'USER_ACTIVITIES/SET_FILTERS', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'USER_ACTIVITIES/SET_PARTNERS', payload } as const),
  setDepartments: (payload: Partial<Departments>) => ({ type: 'USER_ACTIVITIES/SET_DEPARTMENTS', payload } as const),
  setLogins: (payload: Partial<Logins>) => ({ type: 'USER_ACTIVITIES/SET_LOGINS', payload } as const),
  setShortNames: (payload: Partial<ShortNames>) => ({ type: 'USER_ACTIVITIES/SET_SHORT_NAMES', payload } as const),
};

export const downloadReport = (): ThunkType<void> => async (dispatch, getState) => {
  const userActivities = getState().userActivities;
  const sorting = getSortBy(userActivities.data.sorting);

  await onDownload(() =>
    apiContracts.userController
      .printXlsxActivityLogsReport(
        {
          // TODO back
          // @ts-ignore
          timeZone: dayjs.tz.guess(),
          partnerId: userActivities.filters.partnerId?.toString(),
          partnerDepartmentId: userActivities.filters.partnerDepartmentId?.toString(),
          shortName: userActivities.filters.shortName ?? undefined,
          login: userActivities.filters.login ?? undefined,
          activityDate: getDateRange3(userActivities.filters?.dateFrom, userActivities.filters?.dateTo) ?? '',
          sorting,
          ...getPrivateFilters(userActivities.data.privateFilters),
        },
        { format: 'blob' },
      )
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const requestData =
  (params: ListActivitiesParams = {}): ThunkType<Promise<ResultListDtoUserActivityLogView>> =>
  (dispatch, getState) => {
    const userActivities = getState().userActivities;
    const sorting = getSortBy(userActivities.data.sorting);

    return apiContracts.userController
      .listActivities({
        count: true,
        attributes:
          'shortName,activityDate,login,partnerDepartmentName,partnerName,role,action,entityId,id,partnerId,partnerDepartmentId,details',
        partnerId: userActivities.filters.partnerId?.toString(),
        partnerDepartmentId: userActivities.filters.partnerDepartmentId?.toString(),
        shortName: userActivities.filters.shortName ?? undefined,
        login: userActivities.filters.login ?? undefined,
        activityDate: getDateRange2(userActivities.filters?.dateFrom, userActivities.filters?.dateTo) ?? '',
        sorting,
        ...getPrivateFilters(userActivities.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const getData = (): ThunkType<void> => (dispatch, getState) => {
  const userActivities = getState().userActivities;

  dispatch(actions.setData({ loading: true }));

  dispatch(requestData({ ...getPageBy(userActivities.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export const getPartners = (): ThunkType<void> => (dispatch, getState) => {
  const userActivities = getState().userActivities;

  dispatch(actions.setPartners({ loading: true }));

  apiContracts.partnerController
    .list2({
      distinct: true,
      page_size: -1,
    })
    .then((res) => {
      dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch(() => {
      dispatch(actions.setPartners({ loading: false }));
    });
};

export const getDepartments =
  (query: List5Params = {}): ThunkType<void> =>
  (dispatch, getState) => {
    const userActivities = getState().userActivities;

    dispatch(actions.setDepartments({ loading: true, data: null }));

    apiContracts.departmentController
      .list5({
        distinct: true,
        active: String(true),
        attributes: 'name,partnerDepartmentId',
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setDepartments({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setDepartments({ loading: false }));
      });
  };

export const getLogins =
  (value: string): ThunkType<void> =>
  (dispatch, getState) => {
    dispatch(actions.setLogins({ loading: true }));

    apiContracts.userController
      .listActivities({
        distinct: true,
        page_size: -1,
        attributes: 'login',
        sorting: 'login',
        login: value,
      })
      .then((res) => {
        dispatch(actions.setLogins({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setLogins({ loading: false }));
      });
  };

export const getShortNames =
  (value: string): ThunkType<void> =>
  (dispatch, getState) => {
    dispatch(actions.setShortNames({ loading: true }));

    apiContracts.userController
      .listActivities({
        distinct: true,
        page_size: -1,
        attributes: 'shortName',
        sorting: 'shortName',
        shortName: value,
      })
      .then((res) => {
        dispatch(actions.setShortNames({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setShortNames({ loading: false }));
      });
  };

export const initializeFilters = (): ThunkType<void> => (dispatch, getState) => {
  dispatch(actions.setFilters({ initialization: false }));

  dispatch(getPartners());
  dispatch(getDepartments());
};

export interface StoreUserActivities {
  data: Data;
  filters: Filters;
  partners: Partners;
  departments: Departments;
  logins: Logins;
  shortNames: ShortNames;
}
export const initialState: StoreUserActivities = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  partners: { loading: false, data: [], id: null },
  departments: { loading: false, data: null },
  logins: { loading: false, data: null },
  shortNames: { loading: false, data: null },
};

const reducer = (state = initialState, action: InferActionTypes): StoreUserActivities => {
  switch (action.type) {
    case 'USER_ACTIVITIES/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'USER_ACTIVITIES/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'USER_ACTIVITIES/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'USER_ACTIVITIES/SET_DEPARTMENTS':
      return { ...state, departments: { ...state.departments, ...action.payload } };
    case 'USER_ACTIVITIES/SET_LOGINS':
      return { ...state, logins: { ...state.logins, ...action.payload } };
    case 'USER_ACTIVITIES/SET_SHORT_NAMES':
      return { ...state, shortNames: { ...state.shortNames, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
