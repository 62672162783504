import React from 'react';

import Table from 'components/Table';

import InsuranceRisksColumns from './columns/InsuranceRisksColumns';
import ReinsuranceColumns from './columns/ReinsuranceColumns';

import styles from './ContactDetailsTable.module.less';
import { getFranchiseType } from 'utils/franchiseType';
import { formatInsuranceRisks } from './helper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currencyAdapter, useCurrency } from '../../../../hooks/useCurrency';
import { getAffordableInsuranceProgram } from 'screens/ContractDetails/utils';
import { AppState } from 'store/reducer';

interface ITableProps {
  data: any;
  keyStep: string;
}

export default ({ data, keyStep }: ITableProps) => {
  const { t } = useTranslation();
  const { currency } = useCurrency();
  const { insuranceProgram, insurancePrograms, currentCompanyCurrency, contractInfo } = useSelector(
    (state: AppState) => ({
      insuranceProgram: state.contractDetails.contract.insuranceProgram,
      contractInfo: state.contractDetails.contract.contractInfo,
      insurancePrograms: state.contractDetails.insurancePrograms,
      currentCompanyCurrency: state.userLegacy.currentCompanyCurrency,
    }),
  );

  const contractTypeConfig = {
    insurancePrograms: insurancePrograms,
    programCode: insuranceProgram?.code,
    programId: insuranceProgram.id,
  };

  const { vfAccidentMax, isVZR } = getAffordableInsuranceProgram(contractTypeConfig);

  const modifiedInsRisk: any[] = InsuranceRisksColumns(t, currency).map(({ title, key, dataIndex, width }) => {
    const basicColumn = {
      title,
      dataIndex,
      key,
      width,
    };
    switch (dataIndex) {
      case 'insuranceAmount': {
        return {
          ...basicColumn,
          align: 'right',
          render(row, rec) {
            const currentCurrency = currentCompanyCurrency || currency;
            if (isVZR) {
              return contractInfo.currency ? `${row} ${contractInfo.currency}` : `${row}`;
            }

            return `${row} ${
              currencyAdapter(rec.currencyCode, vfAccidentMax ? 'TJS' : '') || vfAccidentMax ? 'TJS' : currentCurrency
            }`;
          },
        };
      }
      case 'paymentAmount': {
        return {
          ...basicColumn,
          align: 'right',
          render(row, rec) {
            const currentCurrency = currentCompanyCurrency || currency;
            return `${row} ${
              currencyAdapter(rec.currencyCode, vfAccidentMax || isVZR ? 'TJS' : '') || vfAccidentMax || isVZR
                ? 'TJS'
                : currentCurrency
            }`;
          },
        };
      }
      case 'franchise':
        return {
          ...basicColumn,
          render: (value: any) => {
            const currentData = data.find((item) => item.franchise === value);
            return <span>{getFranchiseType(currentData.franchiseValueType, currentData.franchise)}</span>;
          },
        };
      default: {
        return basicColumn;
      }
    }
  });

  const formattedData = data.map((item) => {
    return {
      ...item,
      paymentAmount: item.calculatedPaymentAmount ? item.calculatedPaymentAmount : item.paymentAmount,
    };
  });

  const renderColumns = () => {
    switch (keyStep) {
      case '1': {
        return modifiedInsRisk;
      }
      case '5': {
        return ReinsuranceColumns(t);
      }
      default: {
        const currentCurrency = currentCompanyCurrency || currency;
        return InsuranceRisksColumns(t, currentCurrency);
      }
    }
  };

  return (
    <Table
      className={styles.table}
      columns={renderColumns()}
      pagination={false}
      scroll={{ x: 1280 }}
      rowKey={(record: any) => `${record.riskNumber}`}
      dataSource={formatInsuranceRisks(formattedData)}
      bordered
    />
  );
};
