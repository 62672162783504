import { notification } from 'antd';
import * as auth from 'api/auth';
import { asyncErr } from '../../ManagerSignUp/utils';
import { setAsyncError } from '../../ManagerSignUp/catchHandlers/getSignupToken';
import { TStepValues } from '../types';

type setLoader = (state: boolean) => void;
type onSuccess = (data?: any) => void;

export const validateUser = (
  fields: TStepValues,
  setLoader: setLoader,
  onSuccess: onSuccess,
  form: any,
  t: any,
): void => {
  setLoader(true);
  const nextStep = () =>
    getSignupToken({ ...fields, phoneNumber: `+380${fields.phoneNumber}` }, setLoader, onSuccess, form, t);
  auth
    .validateInsurerUser({ phoneNumber: `+380${fields.phoneNumber}` })
    .then(() => {
      nextStep();
    })
    .catch((error) => {
      setLoader(false);

      error && error?.response && error?.response?.data?.errors
        ? setAsyncError(error.response.data.errors, form, asyncErr, nextStep)
        : notification.error({ message: t('signup.something_went_wrong_login') });
    });
};

export const getSignupToken = (
  fields: TStepValues,
  setLoader: setLoader | null,
  onSuccess: onSuccess,
  form: any,
  t: any,
): void => {
  setLoader && setLoader(true);
  auth
    .getInsurerSignupToken(fields)
    .then(() => {
      setLoader && setLoader(false);
      onSuccess();
    })
    .catch((error) => {
      setLoader && setLoader(false);

      error && error?.response && error?.response?.data?.errors
        ? setAsyncError(error.response.data.errors, form, asyncErr)
        : notification.error({ message: t('signup.something_went_wrong_login') });
    });
};

export const sendSignupToken = (
  fields: TStepValues,
  setLoader: setLoader | null,
  onSuccess: onSuccess,
  form: any,
  t: any,
): void => {
  const token: string = fields.token;
  setLoader && setLoader(true);
  auth
    .sendInsurerSignupToken(token, fields)
    .then((response) => {
      setLoader && setLoader(false);
      onSuccess({
        ...response.data,
        token,
        phoneNumber: response.data.phoneNumber?.replace('+380', '') || fields.phoneNumber.replace('+380', ''),
      });
    })
    .catch((error) => {
      setLoader && setLoader(false);

      error && error?.response?.data?.errors
        ? setAsyncError(error.response.data.errors, form, asyncErr)
        : notification.error({ message: t('signup.something_went_wrong_login') });
    });
};

export const sendSignupData = (
  fields: TStepValues,
  setLoader: setLoader,
  onSuccess: onSuccess,
  form: any,
  t: any,
): void => {
  setLoader(true);
  auth
    .registerInsurer(fields)
    .then(() => {
      setLoader(false);
      onSuccess();
      notification.success({
        message: t('signup.successfully_registered'),
      });
    })
    .catch((error) => {
      setLoader(false);
      error && error?.response?.data?.errors
        ? setAsyncError(error.response.data.errors, form, asyncErr)
        : notification.error({ message: t('signup.something_went_wrong_login') });
    });
};
