import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import moment from 'moment';

export interface ITimestampProps {
  classes: any;
  timestamp: string | Date;
}

const Timestamp: React.FC<ITimestampProps> = ({ timestamp, classes }) => {
  const [date, time] = moment(timestamp).format('DD.MM.YYYY/HH:mm').split('/');

  return timestamp && moment(timestamp).isValid() ? (
    <span className={classes.root}>
      <span className={classes.date}>{date}</span>
      <span className={classes.separator}>&nbsp;/&nbsp;</span>
      <span className={classes.time}>{time}</span>
    </span>
  ) : null;
};

export default withStyles(styles)(Timestamp);
