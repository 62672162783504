import { Dayjs } from 'dayjs';

import { PageBy, PrivateFilters, SortBy } from 'types';
import config from 'config';

export const getSortBy = (sortBy?: SortBy | null) => {
  if (!sortBy) {
    return undefined;
  }

  if (Array.isArray(sortBy)) {
    return undefined;
  }

  return sortBy.order
    ? sortBy.order === 'descend'
      ? `-${sortBy.columnKey}`
      : sortBy.columnKey?.toString()
    : undefined;
};

interface Options {
  keepDate?: boolean;
}

/**
 * @returns 2019-01-25..2019-01-25
 */
export const getDateRange = (dateFrom: Dayjs | undefined | null, dateTo: Dayjs | undefined | null) => {
  if (!dateFrom || !dateTo) {
    return undefined;
  }

  return `${dateFrom.startOf('day').utc(true).format(config.format.dateBack)}..${dateTo
    .endOf('day')
    .utc(true)
    .format(config.format.dateBack)}`;
};

/**
 * @returns 2019-01-25T02:00:00.000Z..2019-01-25T02:00:00.000Z
 */
export const getDateRange2 = (
  dateFrom: Dayjs | undefined | null,
  dateTo: Dayjs | undefined | null,
  options?: Options,
) => {
  if (!dateFrom || !dateTo) {
    return undefined;
  }

  if (options?.keepDate) {
    return `${dateFrom.utc(true).toISOString()}..${dateTo.utc(true).toISOString()}`;
  }

  return `${dateFrom.startOf('day').utc(true).toISOString()}..${dateTo.endOf('day').utc(true).toISOString()}`;
};

/**
 * @returns 2023-03-01T00:00:00..2023-03-01T00:00:00
 */
export const getDateRange3 = (
  dateFrom: Dayjs | undefined | null,
  dateTo: Dayjs | undefined | null,
  options?: Options,
) => {
  if (!dateFrom || !dateTo) {
    return undefined;
  }

  if (options?.keepDate) {
    return `${dateFrom.utc(true).format('YYYY-MM-DDTHH:mm:ss')}..${dateTo.utc(true).format('YYYY-MM-DDTHH:mm:ss')}`;
  }

  return `${dateFrom.startOf('day').utc(true).format('YYYY-MM-DDTHH:mm:ss')}..${dateTo
    .endOf('day')
    .utc(true)
    .format('YYYY-MM-DDTHH:mm:ss')}`;
};

export const getPageBy = (pageBy?: PageBy | null) => {
  if (!pageBy) {
    return undefined;
  }

  return {
    page: pageBy.page,
    page_size: pageBy.pageSize,
  };
};

export const getPrivateFilters = (filters?: PrivateFilters) => {
  if (!filters) {
    return undefined;
  }

  return Object.entries(filters).reduce((acc: Record<string, string>, [key, value]) => {
    if (!value) {
      return acc;
    }

    if (value.length === 0) {
      return acc;
    }

    acc[key] = value?.join('|');

    return acc;
  }, {});
};
