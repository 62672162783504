import React from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/next/PageHeader/PageHeader';
import Tile, { Props as TileProps, cssTileContainer } from 'components/next/Tile/Tile';
import { IconChat, IconClinic, IconListBox } from 'components/next/Icon/Icon';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';

interface ITile extends TileProps {
  isVisible: boolean;
}

const Consultations = () => {
  const { t } = useTranslation();

  const tiles: ITile[] = [
    {
      title: t('consultations.appointment'),
      icon: <IconClinic />,
      handleClick: () => history.push(ROUTES.CONSULTATIONS.APPOINTMENTS),
      isVisible: true,
    },
    {
      title: t('consultations.chats'),
      icon: <IconChat />,
      handleClick: () => history.push(ROUTES.CONSULTATIONS.CHATS),
      isVisible: true,
    },
    {
      title: t('consultations.history'),
      icon: <IconListBox />,
      handleClick: () => history.push(ROUTES.CONSULTATIONS.HISTORY),
      isVisible: true,
    },
  ];

  return (
    <>
      <PageHeader
        title={t('consultations.page_title')}
        breadcrumb={{
          routes: [
            { path: ROUTES.DASHBOARD.ROOT, breadcrumbName: t('consultations.home') },
            { path: '', breadcrumbName: t('consultations.page_title') },
          ],
        }}
      />

      <div className={cssTileContainer}>
        {tiles
          .filter((el) => el.isVisible)
          .map((el) => (
            <Tile key={el.title} title={el.title} handleClick={el.handleClick} icon={el.icon} />
          ))}
      </div>
    </>
  );
};

export default Consultations;
