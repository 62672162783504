import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification, Row, Col, Typography } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';
import { useTimer } from 'react-timer-hook';

import { Password } from 'components/next/Password/Password';
import Button from 'components/next/Button/Button';
import { checkOnlineStatus, zeroPad } from 'utils/helpers';
import { ROUTES } from 'constants/routes';
import { UseHistory } from 'types';
import { useRequired } from 'hooks/useRequired';
import { apiContracts } from 'api/contracts';

import { schema, defaultValues, Values } from './ConfirmPassword.schema';
import { State } from '../PasswordRecovery';
import config from 'config/config';

const cssSubmit = css`
  width: 150px;
`;

type Props = {
  state: State;
};

const ConfirmPassword = ({ state }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const [loading, setLoading] = useState(false);
  const timer = useTimer({
    expiryTimestamp: new Date(Date.now() + config.RESET_PASSWORD_TIMEOUT),
    onExpire: () => history.push(ROUTES.PARTNER_AUTH.LOGIN),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
    mode: 'onChange',
  });
  const { requiredFields } = useRequired(schema, watch());

  const onSubmit = (values: Values) => {
    setLoading(true);

    apiContracts.userController
      .resetPassword({
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
        token: state.token.toString(),
      })
      .then(() => {
        history.push(ROUTES.PARTNER_AUTH.LOGIN);
        // #TODO add wrapper
        notification.success({
          message: checkOnlineStatus(t('passwordRecovery.your_password_has_been_changed')),
        });
      })
      .catch(() => {
        notification.error({
          message: 'Smth went wrong',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timerUI = `${zeroPad(timer.minutes)}:${zeroPad(timer.seconds)}`;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Col>{t('passwordRecovery.time_left')}</Col>

          <Col>
            <Typography.Text strong type="warning">
              {timerUI}
            </Typography.Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <form onSubmit={handleSubmit(onSubmit)} data-test="form">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Controller
                name="newPassword"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <Password
                    name={name}
                    errors={errors}
                    requiredFields={requiredFields}
                    value={value}
                    onChange={(v) => onChange(v)}
                    label={t('passwordRecovery.new_password')}
                    colorBase="grey"
                  />
                )}
              />
            </Col>

            <Col span={24}>
              <Controller
                name="confirmNewPassword"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <Password
                    name={name}
                    errors={errors}
                    requiredFields={requiredFields}
                    value={value}
                    onChange={(v) => onChange(v)}
                    label={t('passwordRecovery.duplicate_new_password')}
                    colorBase="grey"
                  />
                )}
              />
            </Col>

            <Col span={24}>
              <Button type="primary" size="large" htmlType="submit" loading={loading} className={cssSubmit}>
                {t('passwordRecovery.save')}
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default ConfirmPassword;
