import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DaysStatisticsDto } from 'types/dto/contracts-service';
import Button from 'components/next/Button/Button';
import { IconXLSX } from 'components/next/Icon/Icon';
import { apiContracts } from 'api/contracts';
import onDownload from 'callbacks/onDownload';

import Card, { cssTitle } from 'screens/DashboardExtend/Card/Card';
import Chart from './ByDaysChart/ByDaysChart';
import Table from './ByDaysTable/ByDaysTable';
import Switch from 'screens/DashboardExtend/Switch/Switch';
import { transformDataForChart } from './ByDays.model';

interface Props {
  data: DaysStatisticsDto[];
  isMonthPeriod: boolean;
  currency: string;
}

const ByDays = ({ data, isMonthPeriod, currency }: Props) => {
  const { t } = useTranslation();
  const [isChart, setIsChart] = useState(true);

  const transformedDataforChart = useMemo(() => transformDataForChart(data, isMonthPeriod), [data]);
  const hasData = Boolean(data.length);

  const onClickExcel = () => {
    onDownload(() =>
      apiContracts.statisticsController
        .printXlsxDaysStatistics(data, { format: 'blob' })
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

  return (
    <Card
      isChart={isChart}
      hasData={hasData}
      title={
        <>
          <span className={cssTitle}>{t('dashboard_HR.general_payments')}</span>
          {hasData && (
            <Switch
              checked={isChart}
              onChange={() => {
                setIsChart(!isChart);
              }}
            />
          )}
        </>
      }
      extra={
        hasData && (
          <Button onClick={onClickExcel}>
            <IconXLSX color="primary" />
            Excel
          </Button>
        )
      }
    >
      {isChart ? (
        <Chart data={transformedDataforChart} isMonthPeriod={isMonthPeriod} currency={currency} />
      ) : (
        <Table data={data} currency={currency} />
      )}
    </Card>
  );
};

export default ByDays;
