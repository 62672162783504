import { createTypes } from 'redux-compose-reducer';
import { Dispatch } from 'redux';
import { AppState } from '../reducer';
import { IPagination } from '../../components/TableFooter/TableFooter';
import * as UserActivitiesAPI from 'api/UserActivities';

export const TYPES = createTypes('usersActivities', [
  'setData',
  'setQuery',
  'setLoading',
  'setPagination',
  'getUsersActionTypes',
  'removeQuery',
]);

export const fetch = (logContractId?: number) => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch({ type: TYPES.setLoading, payload: true });

  const {
    usersActivities: { query, pagination },
  } = getState();

  const {
    data: { resultList: activities = [], count = 0 },
  } = await UserActivitiesAPI.fetch(
    {
      ...query,
      ...(logContractId ? { logContractId } : {}),
    },
    pagination,
  );
  dispatch({ type: TYPES.setData, payload: { activities, count } });
  dispatch({ type: TYPES.setPagination, payload: { ...pagination, total: count } });
  dispatch({ type: TYPES.setLoading, payload: false });
};

export const setQuery = (query: any) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setQuery, payload: query });
};

export const removeQuery = (fieldName: string) => (dispatch) => {
  dispatch({ type: TYPES.removeQuery, payload: fieldName });
};

export const setPagination = (pagination: IPagination) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setPagination, payload: pagination });
};

export const getUsersActionTypes = () => async (dispatch: Dispatch) => {
  try {
    const response = await UserActivitiesAPI.getUserActivityActionTypes();
    dispatch({ type: TYPES.getUsersActionTypes, payload: response.data });
  } catch (e) {
    console.error(e);
  }
};
