import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';

import { AnyObject } from 'types';
import palleteRedesign from 'styles/palleteRedesign';

const cssContainer = css`
  position: relative;
  border-radius: 8px;
`;
const cssLabel = css`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 167%;
  margin-bottom: 4px;
  color: ${palleteRedesign.gray.light};
  transition: all 0.25s;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const cssError = css`
  color: ${palleteRedesign.error.main};
  font-size: 12px;
`;
const cssAsterisk = css`
  color: ${palleteRedesign.error.main};
`;

export type Position = 'absolute' | 'initial';
export type ColorBase = 'none' | 'white' | 'grey';

export const colorBaseMap: Record<ColorBase, string> = {
  none: 'none',
  white: palleteRedesign.basic.white,
  grey: palleteRedesign.gray.lighter,
};

interface LabelProps {
  label: string;
  focus: boolean;
  required: boolean;
  hasError: boolean;
}
export const Label = ({ label, focus, required, hasError }: LabelProps) => {
  return (
    <div
      className={cx(cssLabel, {
        [css`
          color: ${hasError ? palleteRedesign.error.main : palleteRedesign.gray.main};
        `]: focus,
      })}
    >
      {label}
      {required ? <span className={cssAsterisk}>*</span> : ''}
    </div>
  );
};

interface ErrorProps {
  message: string;
  errorPosition?: Position;
  errorClassName?: string;
}
export const Error = ({ message, errorPosition = 'initial', errorClassName }: ErrorProps) => {
  return (
    <div
      className={cx(
        cssError,
        // useses for scroll to the element
        'errorFormItem_message',
        css`
          position: ${errorPosition};
        `,
        errorClassName,
      )}
    >
      {message}
    </div>
  );
};

interface Props {
  label?: string;
  children: React.ReactNode;
  className?: string;
  errorClassName?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
}

// TODO front refactor for more customization
export const InputContainer = ({
  children,
  label,
  className,
  errorClassName,
  colorBase = 'none',
  errorPosition,
  requiredFields,
  hasError,
  isRequired,
  name,
  errors,
}: Props) => {
  const [focus, setFocus] = useState(false);
  const _required = isRequired ?? Boolean((requiredFields ?? {})[name ?? '']);
  const _hasError = hasError ?? Boolean((errors ?? {})[name ?? '']);

  return (
    <div>
      <div id={name} className={cx(className)} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
        {label !== undefined && <Label label={label} focus={focus} required={_required} hasError={_hasError} />}
        <div
          className={cx(
            cssContainer,
            css`
              background: ${colorBaseMap[colorBase]};
            `,
          )}
        >
          {children}
        </div>
      </div>

      <ErrorMessage
        errors={errors ?? {}}
        name={name ?? ''}
        render={({ message }) => (
          <Error message={message} errorPosition={errorPosition} errorClassName={errorClassName} />
        )}
      />
    </div>
  );
};
