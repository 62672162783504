import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as auth from 'api/auth';
import { useParams } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Typography, notification, Tooltip } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import CountDown from 'components/CountDown';
import Header from '../components/header';

import styles from '../auth.module.less';
import { checkInsuranceCode } from 'api/auth';
import { checkOnlineStatus } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { Params } from 'types';

const passwordRegExp = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\\S+$).{8,}$');
const countdownDelay = 299000;

const PasswordRecoveryForm = (props: FormComponentProps) => {
  const { getFieldDecorator } = props.form;
  const [step, setStep] = useState(0);
  const [delay, setDelay] = useState(countdownDelay);
  const [error, setError] = useState(false);
  const [innerInputValue, setInnerInputValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [touched, setTouched] = useState(false);
  const { id: insuranceCode } = useParams<Params>();
  const { t } = useTranslation();

  const getValidation = (code) => {
    checkInsuranceCode(code)
      .then((data) => {
        localStorage.setItem('companyCode', code);
      })
      .catch((er) => {
        history.push('/404');
      });
  };

  useEffect(() => {
    if (insuranceCode) {
      getValidation(insuranceCode);
    }
  }, []);

  const [values, setValues] = useState({
    login: '',
    token: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [fieldsActiveState, setFieldsActiveState] = useState({
    login: false,
    token: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const redirectToLogin = () => {
    history.push(insuranceCode ? ROUTES.AUTH.LOGIN : ROUTES.PARTNER_AUTH.LOGIN);
  };

  const handleFields = (eventName: string, fieldName: string) => {
    if (!!props.form.getFieldValue(fieldName)) {
      return;
    } else {
      if (eventName === 'focus') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: true });
        setFocused(true);
      }
      if (eventName === 'blur') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: false });
        setFocused(false);
      }
    }
  };

  const renderCountDown = () => (
    <div className={styles.countDownContainer}>
      <div>{t('passwordRecovery.time_left')}</div>
      <CountDown delay={delay} onTick={() => setDelay(delay - 1000)} onComplete={redirectToLogin} />
    </div>
  );

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError(false);
    props.form.validateFields((err, fields) => {
      if (!err) {
        switch (step) {
          case 0:
            auth
              .sendResetPasswordSms(fields.login, !!insuranceCode)
              .then(() => {
                setError(false);
                setValues({ ...values, login: fields.login });
                setStep(1);
              })
              .catch(() => setError(true));
            break;
          case 1:
            auth
              .verifySmsCode(values.login, fields.token)
              .then(() => {
                setError(false);
                setValues({ ...values, token: fields.token });
                setStep(2);
                setDelay(countdownDelay);
              })
              .catch(() => setError(true));
            break;
          case 2:
            const { token } = values;
            const { newPassword, confirmNewPassword } = fields;
            if (newPassword === confirmNewPassword && passwordRegExp.test(newPassword)) {
              auth
                .resetPassword({ newPassword, confirmNewPassword, token })
                .then(() => {
                  redirectToLogin();
                  notification.success({
                    message: t('passwordRecovery.your_password_has_been_changed'),
                  });
                })
                .catch(() => setError(true));
            } else {
              setError(true);
            }
            break;
        }
      }
    });
  };

  const onChangeInputs = (e) => {
    setInnerInputValue(e?.target?.value);
    if (!touched) {
      setTouched(true);
    }

    if (innerInputValue !== e?.target?.value || innerInputValue === '' || !innerInputValue) {
      setError(false);
    }
  };

  const redirectToSignUp = () => {
    history.push(insuranceCode ? ROUTES.AUTH.INSURER_SIGNUP.replace(':id', `${insuranceCode}`) : ROUTES.AUTH.SIGN_UP);
  };

  return (
    <div className={styles.authWrapper}>
      <Header />
      <div className={styles.formContainer}>
        <Form onSubmit={handleSubmit} className="login-form">
          <Typography.Title level={3}>{t('passwordRecovery.pass_recovery')}</Typography.Title>
          {(step === 1 || step === 2) && renderCountDown()}
          {step === 0 && (
            <>
              <Form.Item
                {...(error && {
                  validateStatus: 'error',
                  help: checkOnlineStatus(t('passwordRecovery.account_not_found')),
                })}
              >
                <Tooltip
                  trigger="focus"
                  title={t('passwordRecovery.please_enter_your_e-mail_or_phone')}
                  placement="topLeft"
                  visible={focused && !touched && !innerInputValue}
                >
                  {getFieldDecorator('login', {
                    rules: [
                      {
                        required: true,
                        message: checkOnlineStatus(t('passwordRecovery.please_enter_your_e-mail_or_phone')),
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      onFocus={(e) => handleFields(e.type, 'login')}
                      onBlur={(e) => handleFields(e.type, 'login')}
                      data-id="passwordRecovery_login"
                      onChange={onChangeInputs}
                    />,
                  )}
                </Tooltip>
                <div className={classNames(styles.inputLabel, { [styles.labelActive]: fieldsActiveState.login })}>
                  {t('passwordRecovery.your_login')}
                </div>
              </Form.Item>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                  {t('passwordRecovery.continue')}
                </Button>
              </Form.Item>
            </>
          )}
          {step === 1 && (
            <>
              <Form.Item
                {...(error && {
                  validateStatus: 'error',
                  help: checkOnlineStatus(t('passwordRecovery.check_the_data')),
                })}
              >
                {getFieldDecorator('token', {
                  rules: [{ required: true, message: checkOnlineStatus(t('passwordRecovery.please_enter_the_code')) }],
                })(
                  <Input
                    size="large"
                    onFocus={(e) => handleFields(e.type, 'token')}
                    onBlur={(e) => handleFields(e.type, 'token')}
                  />,
                )}
                <div className={classNames(styles.inputLabel, { [styles.labelActive]: fieldsActiveState.token })}>
                  {t('passwordRecovery.code_from_sms')}
                </div>
              </Form.Item>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                  {t('passwordRecovery.continue')}
                </Button>
              </Form.Item>
            </>
          )}
          {step === 2 && (
            <>
              <Form.Item
                {...(error && {
                  validateStatus: 'error',
                  help: checkOnlineStatus(t('passwordRecovery.incorrect_password')),
                })}
              >
                {getFieldDecorator('newPassword', {
                  rules: [{ required: true, message: checkOnlineStatus(t('passwordRecovery.please_enter_password')) }],
                })(
                  <Input.Password
                    size="large"
                    onFocus={(e) => handleFields(e.type, 'newPassword')}
                    onBlur={(e) => handleFields(e.type, 'newPassword')}
                    type="password"
                  />,
                )}
                <div className={classNames(styles.inputLabel, { [styles.labelActive]: fieldsActiveState.newPassword })}>
                  {t('passwordRecovery.new_password')}
                </div>
              </Form.Item>
              <Form.Item
                {...(error && {
                  validateStatus: 'error',
                  help: checkOnlineStatus(t('passwordRecovery.incorrect_password')),
                })}
              >
                {getFieldDecorator('confirmNewPassword', {
                  rules: [{ required: true, message: checkOnlineStatus(t('passwordRecovery.enter_password_again')) }],
                })(
                  <Input.Password
                    size="large"
                    onFocus={(e) => handleFields(e.type, 'confirmNewPassword')}
                    onBlur={(e) => handleFields(e.type, 'confirmNewPassword')}
                    type="password"
                  />,
                )}
                <div
                  className={classNames(styles.inputLabel, {
                    [styles.labelActive]: fieldsActiveState.confirmNewPassword,
                  })}
                >
                  {t('passwordRecovery.duplicate_new_password')}
                </div>
              </Form.Item>
              <span className={styles.info}>* {t('passwordRecovery.password_must_contain_at_least_8_characters')}</span>
              <Form.Item>
                <Button size="large" type="primary" htmlType="submit" className="login-form-button">
                  {t('passwordRecovery.save')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
        <div className={styles.registration}>
          {t('passwordRecovery.no_account')}
          <span onClick={redirectToSignUp} className={styles.link}>
            &nbsp;{t('passwordRecovery.register')}
          </span>
          <span> {t('passwordRecovery.or')}</span>{' '}
          <span onClick={redirectToLogin} className={styles.link}>
            {t('passwordRecovery.log_in')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Form.create({ name: 'passwordRecovery' })(PasswordRecoveryForm);
