import React, { useEffect, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Button, Tooltip } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Form } from '@ant-design/compatible';
import { useTranslation } from 'react-i18next';

import styles from './InsurerInfo.module.less';

interface IInsurerInfo {
  setRussianCitizen: (russianCitizen: boolean) => void;
}

const InsurerInfo: React.FC<IInsurerInfo & FormComponentProps> = ({ form, setRussianCitizen }) => {
  const { t } = useTranslation();

  const { getFieldDecorator, getFieldValue } = form;

  const russianCitizen = getFieldValue('russianCitizen');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setRussianCitizen(russianCitizen);
  }, [russianCitizen]);

  const onHandleOpen = () => {
    setIsOpen(true);
  };

  const onHandleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.insurerInfo}>
      <Tooltip placement="leftBottom" title={t('clientSign.insurer_info_tooltip')}>
        <div className={styles.header}>
          <div>
            {getFieldDecorator('russianCitizen', {
              initialValue: true,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </div>
          <div className={styles.title}>{t('clientSign.insurer_info_title')}</div>
        </div>
      </Tooltip>

      {!isOpen && (
        <div className={styles.showMore}>
          <Button onClick={onHandleOpen} type="ghost">
            Детальніше
            <DownOutlined />
          </Button>
        </div>
      )}

      <div className={`${styles.details} ${isOpen ? styles.open : ''}`}>
        {isOpen && (
          <>
            <div>
              <a target="_blanck" href={'https://zakon.rada.gov.ua/laws/show/187-2022-%D0%BF#Text'}>
                {t('clientSign.insurer_info_link')}
              </a>
            </div>
            {t('clientSign.insurer_info_detail')}
          </>
        )}
      </div>
      {isOpen && (
        <div className={styles.upIcon} onClick={onHandleClose}>
          <UpOutlined />
        </div>
      )}
    </div>
  );
};

export default Form.create<IInsurerInfo & FormComponentProps>({ name: 'insurerInfo' })(InsurerInfo);
