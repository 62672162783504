import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import Button from 'components/nextRedesign/Button/Button';
import palleteRedesign from 'styles/palleteRedesign';

const cssModal = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
`;
const cssModalHeader = css`
  &.ant-typography {
    color: ${palleteRedesign.basic.black};
    font-size: 44px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    margin: 0;
  }
`;
const cssModalText = css`
  margin: 30px 0;
  color: ${palleteRedesign.basic.black};
  font-size: 16px;
  text-align: center;
`;
const cssModalSubmit = css`
  width: 100%;
  max-width: 360px;
`;

const Success = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();

  return (
    <div className={cssModal}>
      <Typography.Title className={cssModalHeader}>{t('signup.welcome')}</Typography.Title>
      <div className={cssModalText}>{t('signup.you_have_registered')}</div>
      <Button
        type="primary"
        size="large"
        className={cssModalSubmit}
        onClick={() => history.push(ROUTES.AUTH.CLIENT_LOGIN)}
      >
        Ok
      </Button>
    </div>
  );
};
export default Success;
