import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col, notification, Divider, Modal } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import config from 'config';
import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { useRequired } from 'hooks/useRequired';
import { apiContracts } from 'api/contracts';
import { ErrorsDto, UseHistory } from 'types';
import Link from 'components/nextRedesign/Link/Link';
import Icon from 'components/nextRedesign/Icon/Icon';
import Button from 'components/nextRedesign/Button/Button';
import { LabeledValue, Select } from 'components/nextRedesign/Select/Select';
import { DatePicker } from 'components/nextRedesign/DatePicker/DatePicker';
import { InputField } from 'components/nextRedesign/InputField/InputField';
import Language from 'components/nextRedesign/ClientAuthContainer/Language/Language';
import { breakpoints } from 'styles/breakpoints';
import { getCountries } from 'store/clientAuth/clientAuth';

import Verification from '../Verification/Verification';
import { State } from '../ClientSignUp';
import { schema, defaultValues, Values } from './SignUp.schema';

const cssWrapper = css`
  width: 812px;
  max-width: 812px;
  margin: 0 auto;
`;
const cssFooter = css`
  max-width: 390px;
  margin: 100px auto 0;
  @media (max-width: ${breakpoints.small}) {
    margin: 60px auto 0;
  }
`;
const cssExternalLinks = css`
  width: 100%;
  display: flex;
  justify-content: center;
  span {
    margin-left: 5px;
  }
`;
const cssPartnerLink = css`
  display: flex;
  align-items: center;
`;
const cssIcon = css`
  padding: 0 !important;
  margin-right: 10px;
`;

const cssSubmitWrapper = css`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
const cssSubmit = css`
  max-width: 388px;
  width: 100%;
`;

type Props = {
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const SignUp = ({ state, setState }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const dispatch = useDispatch();

  const countries = useSelector((store: AppState) => store.clientAuth.countries);

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { requiredFields } = useRequired(schema, watch());
  const watchedFields = watch();

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const onSubmit = async (values: Values) => {
    setLoading(true);
    apiContracts.clientRegistrationController
      .validateEmailAndSendEmailVerification({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        birthDate: values.birthDate?.format(config.format.dateBack) ?? '',
        locality: values.locality ?? '',
        street: values.street,
      })
      .then(() => {
        setState({ ...values, isVerificationModalOpen: true });
      })
      .catch((error) => {
        const errorMap = {
          default: t('signup.something_went_wrong_login'),
          EMAIL_ALREADY_REGISTERED: t('signup.email_already_registered'),
        };
        (error?.response?.data as ErrorsDto | undefined)?.errors.forEach((err) => {
          const message = errorMap[err.code] ?? errorMap.default;
          notification.error({ message });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countriesOptions: LabeledValue[] = countries.data.map((el, i) => ({
    key: el.name ?? i,
    value: el.name,
    label: el.name,
  }));

  return (
    <>
      <div className={cssWrapper}>
        <form onSubmit={handleSubmit(onSubmit)} data-test="form">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 32]}>
                <Col span={12}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputField
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('signup.email')}
                        colorBase="grey"
                        placeholder={t('signup.enter_email')}
                        errorPosition="absolute"
                      />
                    )}
                  />
                </Col>
                <Col span={6}>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputField
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('signup.first_name')}
                        colorBase="grey"
                        placeholder={t('signup.enter_first_name')}
                        errorPosition="absolute"
                      />
                    )}
                  />
                </Col>
                <Col span={6}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputField
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('signup.last_name')}
                        colorBase="grey"
                        placeholder={t('signup.enter_last_name')}
                        errorPosition="absolute"
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 32]}>
                <Col span={12}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputField
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('signup.phone_number')}
                        colorBase="grey"
                        errorPosition="absolute"
                      />
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name="locality"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <Select
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('signup.country')}
                        colorBase="grey"
                        options={countriesOptions}
                        placeholder={t('signup.enter_country')}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[16, 32]}>
                <Col span={12}>
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <DatePicker
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('signup.date_of_birth')}
                        colorBase="grey"
                        errorPosition="absolute"
                      />
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Controller
                    name="street"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputField
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('signup.address')}
                        colorBase="grey"
                        placeholder={t('signup.enter_address')}
                        errorPosition="absolute"
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <div className={cssSubmitWrapper}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className={cssSubmit}
              loading={loading}
              disabled={
                !watchedFields.email && !watchedFields.firstName && !watchedFields.lastName && !watchedFields.birthDate
              }
            >
              {t('signup.confirm')}
            </Button>
          </div>
        </form>

        <div className={cssFooter}>
          <div className={cssExternalLinks}>
            {t('signup.have_a_account')}
            <Link
              onClick={() => {
                history.push(ROUTES.AUTH.CLIENT_LOGIN);
              }}
            >
              {t('signup.log_in')}
            </Link>
          </div>
          <Divider style={{ margin: '20px 0' }} />
          <Row justify="space-between" align="middle" style={{ width: '100%' }}>
            <Col>
              <Link
                className={cssPartnerLink}
                onClick={() => {
                  history.push(ROUTES.PARTNER_AUTH.LOGIN);
                }}
              >
                <Icon type="briefcase" size="24px" color="black" className={cssIcon} />
                {t('log_in.enter_as_partner')}
              </Link>
            </Col>
            <Col>
              <Language />
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        footer={null}
        maskClosable={false}
        destroyOnClose
        open={state.isVerificationModalOpen}
        onCancel={() => setState({ isVerificationModalOpen: false })}
        width={650}
        centered
      >
        <Verification state={state} setState={setState} />
      </Modal>
    </>
  );
};

export default SignUp;
