import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { FieldErrors } from 'react-hook-form';

import { Position, InputContainer, ColorBase } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

interface Props extends InputProps {
  label?: string;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
  colorBase?: ColorBase;
}

export const Password = ({
  name,
  errors,
  requiredFields,
  label,
  classNameContainer,
  errorPosition,
  isRequired,
  hasError,
  colorBase = 'none',
  ...props
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      isRequired={isRequired}
      hasError={hasError}
      label={label}
      className={classNameContainer}
      errorPosition={errorPosition}
      colorBase={colorBase}
    >
      <Input.Password data-test={name} bordered={false} {...props} />
    </InputContainer>
  );
};
