import React from 'react';
import { Tooltip } from 'antd';
import i18n from 'i18next';

import { IconStatusEdit, IconStatusError, IconStatusSuccess } from 'components/next/Icon/Icon';
import { ContractStatus } from 'types/dto/contracts-service';

const map: Record<ContractStatus, () => JSX.Element> = {
  ACTIVE: () => (
    <Tooltip title={i18n.t(`enums.ContractStatus.ACTIVE`)}>
      <IconStatusSuccess color="primary" />
    </Tooltip>
  ),
  FINISHED: () => (
    <Tooltip title={i18n.t(`enums.ContractStatus.FINISHED`)}>
      <IconStatusError color="error" />
    </Tooltip>
  ),
  DRAFT: () => (
    <Tooltip title={i18n.t(`enums.ContractStatus.DRAFT`)}>
      <IconStatusEdit color="warning" />
    </Tooltip>
  ),
  DECLAIMED: () => (
    <Tooltip title={i18n.t(`enums.ContractStatus.DECLAIMED`)}>
      <IconStatusEdit color="warning" />
    </Tooltip>
  ),
};

interface Props {
  status: ContractStatus | undefined;
}

const Status = ({ status }: Props) => {
  if (!status) {
    return null;
  }

  const Component = map[status];

  return <Component />;
};

export default Status;
