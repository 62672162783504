import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash/fp';

import Modal from '../../components/Modal';
import Previewer from '../../components/Previewer';
import ItemsList, { builders, selectors } from '../../components/List';
import { useModal } from 'screens/Appointments/hooks/useModal';
import { Service, RawService } from 'types/institutions';
import { ChevronRightIcon } from 'components/Icons';
import { Picker, WithVariants, WithErrors } from 'types/appointments';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';
import { defaultValues } from 'screens/Appointments/hooks/useForm';

import styles from './ServicePicker.module.less';

export const ServicePicker: React.FC<Picker<RawService> & WithErrors & WithVariants<Service>> = ({
  variants,
  error,
  defaultValue,
  onClose,
  disabled,
  clearError,
}) => {
  // const consultationId = 21;

  const { t } = useTranslation();

  const { isOpen, open, close, confirm, picked, onPick } = useModal(onClose, defaultValue);

  // Просматриваемый узел дерева (которым является список сервисов)
  const [node, setNode] = useState<Service>();

  const handleNodeClick = (node: Service) => {
    if (node.children?.length > 0) {
      return setNode(node);
    }
    onPick(isEqual(node, picked) ? defaultValues.service : node);
  };

  const onSubmit = () => {
    clearError('service');
    clearError('serviceDrEleks');
    setNode(undefined);
    confirm();
  };

  return (
    <>
      <Previewer
        displayValue={defaultValue.name}
        onClick={open}
        title={defaultValue.name || error ? t('appointments.service') : t('appointments.selector_service')}
        disabled={disabled}
        errorMessage={error ? t('appointments.selector_service') : ''}
      />
      {isOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              onPick(defaultValue);
              setNode(undefined);
              close();
            }}
          >
            <Modal.Header
              title={node ? node.name : t('appointments.services_list')}
              onClose={() => {
                onPick(defaultValue);
                setNode(undefined);
                close();
              }}
              renderArrow={!!node}
              onArrowClick={() => setNode(void 0)}
            />
            <Modal.Body>
              <Scrollbars autoHeight autoHeightMax={MAX_SCROLLBAR_HEIGHT}>
                <ItemsList
                  items={node?.children ?? variants}
                  itemBuilder={builders.combine(
                    builders.byName,
                    // builders.whether(selectors.byId(picked?.id, picked?.parentId), builders.byN),
                    builders.whether(
                      selectors.hasChildren,
                      builders.postfix(
                        builders.whether(
                          selectors.byId(picked?.parentId),
                          builders.node(<span className={styles.match}>{picked?.name}</span>),
                        ),
                        builders.node(<ChevronRightIcon />),
                      ),
                    ),
                  )}
                  selector={selectors.byId(picked?.id, picked?.parentId)}
                  onItemClick={handleNodeClick}
                />
              </Scrollbars>
            </Modal.Body>
            <Modal.Confirm disabled={isEqual(defaultValue, picked)} onClick={onSubmit} />
          </OutsideClickHandler>
        </Modal>
      )}
    </>
  );
};
