import createInstance from './axios/axios';
import { AxiosResponse } from 'axios';
import { IInsuranceCompany } from 'types/insuranceCompany';
import { ResolutionType, InsuranceCompanyDto } from 'types/dto/contracts-service';

const api = createInstance('CONTRACTS');

//configuration
export const getInsuranceCompany = (id: number) => {
  return api.get<InsuranceCompanyDto>(`/insurance-companies/${id}`);
};

// configuration sending form
export const updateInsuranceCompanyConfig = (id: number, fieldsValue: IInsuranceCompany) => {
  return api.put<InsuranceCompanyDto>(`/insurance-companies/${id}`, fieldsValue);
};

export const sendInsuranceCompanyConfig = (fieldsValue: IInsuranceCompany) => {
  return api.post<InsuranceCompanyDto>('/insurance-companies', fieldsValue);
};

export const getResolution = (contractId: string | number, riskId: string | number) => {
  return api.get<Record<ResolutionType, string>>(`/contracts/${contractId}/risks/${riskId}/resolution-types`);
};

//set logo
export const setLogo = async (insuranceId: number, logoData: any): Promise<AxiosResponse<any>> =>
  api.post(`/insurance-companies/${insuranceId}/file`, logoData);

//get logo
export const getLogo = async (insuranceId: number): Promise<AxiosResponse<any>> =>
  api.get(`/insurance-companies/${insuranceId}/file`, { responseType: 'arraybuffer' });
