import React from 'react';
import { Select as SelectAntd } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { css, cx } from '@emotion/css';
import { FieldErrors } from 'react-hook-form';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

const cssRoot = css`
  width: 100%;
  height: 30px;
`;

interface Props<T> extends SelectProps<T> {
  label?: string;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
}

export const Select = <T,>({
  name,
  errors,
  requiredFields,
  label,
  classNameContainer,
  className,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  value,
  onChange,
  ...props
}: Props<T>) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <SelectAntd<T>
        data-test={name}
        className={cx(cssRoot, className)}
        // need for render option list under select (not body)
        // TODO frond find solution for correct display the list near parent node
        // getPopupContainer={(triggerNode) => triggerNode}
        filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
        // convert undefined to null for consistency with react-hook-form
        onChange={(value, options) =>
          onChange?.(value === undefined || value === null ? (null as unknown as T) : value, options)
        }
        // convert null to undefined for consistency with react-hook-form
        value={value === null ? undefined : value}
        bordered={false}
        {...props}
      />
    </InputContainer>
  );
};
