import { ThunkAction } from 'redux-thunk';
import { notification } from 'antd';

import { AppState } from 'store/reducer';
import { ActionTypes } from 'types';
import { CountryView } from 'types/dto/location-service';
import { ClientRegistrationAccountDto, PersonCounterparty } from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import { apiLocations } from 'api/locations';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Countries {
  loading: boolean;
  data: CountryView[];
}

interface UserRegistration {
  loading: boolean;
  data: PersonCounterparty | null;
}

export const actions = {
  setCountries: (payload: Partial<Countries>) => ({ type: 'CLIENT_AUTH/SET_COUNTRIES', payload } as const),
  setUserRegistration: (payload: Partial<UserRegistration>) =>
    ({ type: 'CLIENT_AUTH/SET_USER_REGISTRATION', payload } as const),
};

export const getCountries = (): ThunkType<void> => (dispatch) => {
  dispatch(actions.setCountries({ loading: true }));
  const params = { page_size: -1 };

  return apiLocations.countriesController
    .list(params)
    .then((res) => {
      dispatch(actions.setCountries({ loading: false, data: res.data.resultList }));
    })
    .catch(() => {
      dispatch(actions.setCountries({ loading: false }));
    });
};

export const setUserRegistration =
  (seed: ClientRegistrationAccountDto): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setUserRegistration({ loading: true }));

    return apiContracts.clientRegistrationController
      .registration(seed)
      .then((res) => {
        dispatch(actions.setUserRegistration({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setUserRegistration({ loading: false }));
      });
  };

export interface StoreClientAuth {
  countries: Countries;
  userRegistration: UserRegistration;
}
export const initialState: StoreClientAuth = {
  countries: { loading: false, data: [] },
  userRegistration: { loading: false, data: null },
};

const reducer = (state = initialState, action: InferActionTypes): StoreClientAuth => {
  switch (action.type) {
    case 'CLIENT_AUTH/SET_COUNTRIES':
      return { ...state, countries: { ...state.countries, ...action.payload } };
    case 'CLIENT_AUTH/SET_USER_REGISTRATION':
      return { ...state, userRegistration: { ...state.userRegistration, ...action.payload } };
    default:
      return state;
  }
};

export default reducer;
