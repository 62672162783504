import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import TableStyles from './CustomTable.module.less';
import { CloseIcon } from 'components/Icons';
import cs from 'classnames';
import Table from '../Table';
import TableFooter from '../TableFooter';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { IPagination } from '../TableFooter/TableFooter';
import { useTranslation } from 'react-i18next';

interface IProps {
  columns: any;
  data: any;
  pageSize: number;
  page: number;
  pending: boolean;
  total: number;
  filtersInfo: any;
  clearFilters: any;
  handleFilters: any;
  handleTableChange: any;
  onPageSizeChange?: any;
  onPageChange?: any;
  isRowClick?: boolean;
  rowClickHandler?: any;
  className?: string;
  showedLabel?: string;
  classes?: any;
  rowKey?: any;
  expandIconColumnIndex?: any; // add types
  expandIconAsCell?: any;
  expandedRowRender?: any;
  expandRowByClick?: any;
  defaultExpandedRowKeys?: any;
  expandIcon?: any;
  expandable?: any;
  contentHeight?: number;
  disablePerPageChange?: boolean;
  disableTableFooter?: boolean;
  xValue?: number;
  dataIdPagination?: string;
  dataIdSize?: string;
  dataIdSizeOptions?: string;
}

const CustomTable = ({
  columns,
  data,
  pageSize,
  classes,
  page,
  pending,
  total,
  filtersInfo,
  clearFilters,
  handleFilters,
  handleTableChange,
  onPageSizeChange,
  onPageChange,
  isRowClick,
  defaultExpandedRowKeys,
  rowClickHandler,
  className,
  rowKey = (record: any) => `${record.id}`,
  showedLabel = 'договорiв',
  contentHeight,
  xValue,
  disablePerPageChange,
  dataIdSize,
  dataIdSizeOptions,
  dataIdPagination,
  disableTableFooter,
  expandIconColumnIndex,
  expandedRowRender,
  expandIcon,
  expandable,
  expandRowByClick,
  expandIconAsCell,
  ...props
}: IProps) => {
  const { t } = useTranslation();
  const [pagination, setPagination] = useState<IPagination>({ size: pageSize, total, page });

  useEffect(() => {
    if (pagination.size !== pageSize && pagination.size === pageSize) {
      onPageSizeChange(pagination.size);
    }
    if (pagination.page !== page || pagination.size !== pageSize) {
      onPageChange(pagination.page, pagination.size);
    }
  }, [pagination.size, pagination.page]);

  useEffect(() => {
    setPagination({ size: pageSize, total, page });
  }, [pageSize, page, total]);

  return (
    <div className={cs(className, classes.root)}>
      {!Object.keys(filtersInfo).every((item) => filtersInfo[item] === null) &&
        Object.keys(filtersInfo).length !== 0 && (
          <div className={cs(TableStyles.privateFiltersControls__container, classes.reset)}>
            <Button type="link" style={{ borderRight: '1px solid #E4E6E7', borderRadius: 0 }} onClick={clearFilters}>
              <CloseIcon /> {t('table.clear_all')}
            </Button>
          </div>
        )}
      <Table
        {...props}
        className={cs(className, classes.table)}
        scroll={{ x: xValue || 1560, y: `calc(100vh - ${contentHeight}px)` }}
        loading={pending}
        pagination={false}
        columns={columns}
        bordered
        dataSource={data}
        rowKey={rowKey}
        expandRowByClick={expandRowByClick}
        expandIconColumnIndex={expandIconColumnIndex}
        // TODO front legacy
        // @ts-ignore
        expandIconAsCell={expandIconAsCell}
        expandedRowRender={expandedRowRender}
        defaultExpandedRowKeys={defaultExpandedRowKeys}
        expandIcon={expandIcon}
        onChange={(p, filterList, sorter) => {
          if (Object.keys(sorter).length === 0) {
          } else {
            handleTableChange(sorter);
          }
        }}
        onRow={(record) => ({
          onClick: (evt) => {
            evt.stopPropagation();
            isRowClick && rowClickHandler(record);
          },
        })}
      />
      {!disableTableFooter && (
        <TableFooter
          disablePerPageChange={disablePerPageChange}
          dataIdSize={dataIdSize}
          dataIdSizeOptions={dataIdSizeOptions}
          dataIdPagination={dataIdPagination}
          className={classes.footer}
          pagination={pagination}
          onChange={setPagination}
          total={total}
        />
      )}
    </div>
  );
};

export default withStyles(styles as any)(CustomTable);
