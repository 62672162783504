import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { getPrivateFilters, getSortBy } from 'utils/request';
import {
  InsuranceObjectResponseDto,
  FindInsuranceObjectResponseDtoParams,
  List7Params,
  ResultListDtoContractView,
  List10Params,
  ResultListDtoContractClaimView,
  List8Params,
  ResultListDtoContractTerminationView,
} from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import { Values, initialValues } from 'screens/ClientPolicies/Policies/Filters/Filters.schema';
import dayjs from 'dayjs';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface InsuranceObjects extends List {
  loading: boolean;
  data: InsuranceObjectResponseDto | null;
  isFiltersOpen: boolean;
}

interface Contracts extends List {
  loading: boolean;
  data: ResultListDtoContractView | null;
  isFiltersOpen: boolean;
}

interface Claims {
  loading: boolean;
  data: ResultListDtoContractClaimView | null;
}
interface Terminations {
  loading: boolean;
  data: ResultListDtoContractTerminationView | null;
}

type Filters = Values & { initialization: boolean };

const DEFAULT_PAGINATION = 6;

export const actions = {
  reset: () => ({ type: 'CLIENT_POLICIES/RESET' } as const),
  setInsuranceObjects: (payload: Partial<InsuranceObjects>) =>
    ({ type: 'CLIENT_POLICIES/SET_INSURANCE_OBJECTS', payload } as const),
  setContracts: (payload: Partial<Contracts>) => ({ type: 'CLIENT_POLICIES/SET_CONTRACTS', payload } as const),
  setClaims: (payload: Partial<Claims>) => ({ type: 'CLIENT_POLICIES/SET_CLAIMS', payload } as const),
  setTerminations: (payload: Partial<Terminations>) => ({ type: 'CLIENT_POLICIES/SET_TERMINATIONS', payload } as const),
  setPoliciesView: (payload: 'rows' | 'tile') => ({ type: 'CLIENT_POLICIES/SET_POLICIES_VIEW', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'CLIENT_POLICIES/SET_FILTERS', payload } as const),
};
export const getInsuranceObjects =
  (params: FindInsuranceObjectResponseDtoParams = {}): ThunkType<Promise<InsuranceObjectResponseDto>> =>
  async (dispatch, getState) => {
    const { insuranceObjects, filters } = getState().clientPolicies;
    const sorting = getSortBy(insuranceObjects.sorting);

    return apiContracts.insuranceObjectController
      .findInsuranceObjectResponseDto({
        current: 'true',
        page: insuranceObjects.pagination?.page,
        page_size: insuranceObjects.pagination?.pageSize,
        sorting: sorting ? `${sorting},-contractId` : '-contractId',
        contractStatus: filters.contractStatus?.toString(),
        contractNumber: filters.contractNumber ? filters.contractNumber : undefined,
        insuranceProductCode: filters.insuranceProductCode ? filters.insuranceProductCode : undefined,
        name: filters.name ? filters.name : undefined,
        startDate: filters.startDate ? dayjs(filters.startDate).format() : undefined,
        endDate: filters.endDate ? dayjs(filters.endDate).format() : undefined,
        ...getPrivateFilters(insuranceObjects.privateFilters),
        ...params,
      })
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  };

export const getContract =
  (id: string): ThunkType<Promise<ResultListDtoContractView>> =>
  async (dispatch, getState) => {
    dispatch(actions.setContracts({ loading: true, data: null }));

    return apiContracts.contractController
      .list7({
        id,
      })
      .then((res) => {
        dispatch(actions.setContracts({ loading: false, data: res.data }));
        return res.data;
      })
      .catch(() => {
        dispatch(actions.setContracts({ loading: false, data: null }));
        return Promise.reject(undefined);
      });
  };

export const getContracts =
  (params: List7Params = {}): ThunkType<Promise<ResultListDtoContractView>> =>
  async (dispatch, getState) => {
    const { contracts, filters } = getState().clientPolicies;
    const sorting = getSortBy(contracts.sorting);

    return apiContracts.contractController
      .list7({
        current: 'true',
        count: true,
        page: contracts.pagination?.page,
        page_size: contracts.pagination?.pageSize,
        sorting: sorting ? `${sorting},-id` : '-id',
        status: filters.contractStatus?.toString(),
        number: filters.contractNumber ? filters.contractNumber : undefined,
        insuranceProductCode: filters.insuranceProductCode ? filters.insuranceProductCode : undefined,
        insuranceObjectName: filters.name ? filters.name : undefined,
        startDate: filters.startDate ? dayjs(filters.startDate).format() : undefined,
        endDate: filters.endDate ? dayjs(filters.endDate).format() : undefined,
        ...getPrivateFilters(contracts.privateFilters),
        ...params,
      })
      .then(({ data }) => {
        return data;
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  };

export const loadContracts = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setContracts({ loading: true, data: null }));

  dispatch(getContracts())
    .then((res) => {
      dispatch(actions.setContracts({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setContracts({ loading: false, data: null }));
    });
};

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setFilters({ initialization: false }));
};

export const getClaims =
  (params: List10Params = {}): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setClaims({ loading: true, data: null }));
    apiContracts.contractClaimController
      .list10(params)
      .then((res) => {
        dispatch(actions.setClaims({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setClaims({ loading: false, data: null }));
      });
  };

export const getTerminations =
  (params: List8Params = {}): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setTerminations({ loading: true, data: null }));
    apiContracts.contractTerminationController
      .list8(params)
      .then((res) => {
        dispatch(actions.setTerminations({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setTerminations({ loading: false, data: null }));
      });
  };

export interface StoreClientPolicies {
  insuranceObjects: InsuranceObjects;
  contracts: Contracts;
  policiesView: 'rows' | 'tile';
  filters: Filters;
  claims: Claims;
  terminations: Terminations;
}

export const initialState: StoreClientPolicies = {
  insuranceObjects: {
    loading: false,
    data: null,
    isFiltersOpen: false,
    pagination: {
      page: 1,
      pageSize: DEFAULT_PAGINATION,
    },
    sorting: { columnKey: 'conclusionDate', order: 'descend' },
    privateFilters: {},
  },
  contracts: {
    loading: false,
    data: null,
    isFiltersOpen: false,
    pagination: {
      page: 1,
      pageSize: DEFAULT_PAGINATION,
    },
    sorting: { columnKey: 'conclusionDate', order: 'descend' },
    privateFilters: {},
  },
  policiesView: 'rows',
  filters: { ...initialValues, initialization: true },
  claims: {
    loading: false,
    data: null,
  },
  terminations: {
    loading: false,
    data: null,
  },
};

const reducer = (state = initialState, action: InferActionTypes) => {
  switch (action.type) {
    case 'CLIENT_POLICIES/RESET': {
      return initialState;
    }

    case 'CLIENT_POLICIES/SET_INSURANCE_OBJECTS': {
      return {
        ...state,
        insuranceObjects: {
          ...state.insuranceObjects,
          ...action.payload,
        },
      };
    }

    case 'CLIENT_POLICIES/SET_CONTRACTS': {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          ...action.payload,
        },
      };
    }

    case 'CLIENT_POLICIES/SET_POLICIES_VIEW': {
      return {
        ...state,
        policiesView: action.payload,
      };
    }

    case 'CLIENT_POLICIES/SET_FILTERS': {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    }

    case 'CLIENT_POLICIES/SET_CLAIMS': {
      return {
        ...state,
        claims: {
          ...state.claims,
          ...action.payload,
        },
      };
    }

    case 'CLIENT_POLICIES/SET_TERMINATIONS': {
      return {
        ...state,
        terminations: {
          ...state.terminations,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
