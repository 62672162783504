import moment from 'moment';

import createInstance from './axios';
import { ResultListDtoInsuranceProductView } from 'types/dto/configuration-service';

const api = createInstance('CONFIGURATION');

export const getInsuranceProducts = (partnerId?: number) => {
  const isShow = window.location.href.includes('insurance-programs');

  const baseParams = !isShow
    ? {
        distinct: true,
        attributes: 'id,name,code',
        deactivationDate: `>=${moment().format('YYYY-MM-DD')}`,
      }
    : { distinct: true, attributes: 'id,name,code' };
  const params = partnerId ? { ...baseParams, partnerId } : baseParams;

  return api.get<ResultListDtoInsuranceProductView>('/insurance-products', { params });
};
