import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { Action } from 'types/store';

import { TYPES } from './actions';
import { AgentModal, IPartnerCreateStore } from './types';

export const initialState: IPartnerCreateStore = {
  insuranceCompanies: [],
  types: [],
  insurancePrograms: [],
  insuranceProgramConfiguration: [],
  insuranceProgramsModal: [],
  data: {
    id: 0,
    name: '',
    shortName: '',
    code: '',
    type: '',
    insuranceCompanyId: 0,
    insuranceCompanyName: '',
    enterpriseCode: '',
    createdDate: '',
    activationDate: '',
    deactivationDate: '',
    phoneNumber: '',
    directorFullName: '',
    active: false,
    address: {
      locality: '',
      street: '',
    },
  },
  agents: [],
  agentModal: null,
  program: {},
  regions: {
    data: [],
  },
  subDivisionsList: {
    resultList: [],
    count: 0,
  },
  sunDivisionsSorting: {
    field: '',
    direction: '',
  },
  optionsTab: {
    expressAutoPaymentTimeoutInMin: 15,
    expressPaymentWorkingDays: 7,
    clientSignContractEnabled: false,
    acquirerBank: '',
    acquirerBankUrl: '',
    discountParam: [],
  },
  regionsTab: [],
  internetSalesTab: [],
  internetSalesInsurancePrograms: [],
  internetSalesSubdivision: [],
  units: [],
  maxValue: 0,
};

const setSorting = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  const { field, direction } = payload;
  if (direction) {
    return {
      ...state,
      sunDivisionsSorting: {
        field: field,
        direction: direction,
      },
    };
  }
  return {
    ...state,
    sunDivisionsSorting: initialState.sunDivisionsSorting,
  };
};

const getInsuranceCompanies = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    insuranceCompanies: payload.data.resultList,
  };
};

const getPartnersTypes = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    types: payload.data,
  };
};

const getPartnersData = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    data: payload.data,
  };
};

const getPartnersOptionsTabData = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    optionsTab: {
      expressAutoPaymentTimeoutInMin: payload.expressAutoPaymentTimeoutInMin
        ? payload.expressAutoPaymentTimeoutInMin
        : initialState.optionsTab.expressAutoPaymentTimeoutInMin,
      expressPaymentWorkingDays: payload.expressPaymentWorkingDays
        ? payload.expressPaymentWorkingDays
        : initialState.optionsTab.expressPaymentWorkingDays,
      clientSignContractEnabled: payload.clientSignContractEnabled,
      acquirerBank: payload.acquirerBank ? payload.acquirerBank : initialState.optionsTab.acquirerBank,
      acquirerBankUrl: payload.acquirerBankUrl ? payload.acquirerBankUrl : initialState.optionsTab.acquirerBankUrl,
      discountParam: payload.partnerDiscounts,
    },
  };
};

const getPartnersInternetSalesTabData = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    internetSalesTab: payload,
  };
};

const getCompaniesInternetSales = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    internetSalesInsurancePrograms: payload,
  };
};

const getSubdivisionInternetSales = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    internetSalesSubdivision: payload,
  };
};

const getPartnersRegionsTabData = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    regionsTab: payload,
  };
};

const getAgentsData = (state: IPartnerCreateStore, { payload }: AnyAction): IPartnerCreateStore => {
  return {
    ...state,
    agents: payload.data,
  };
};

const getInsPrograms = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    insurancePrograms: payload,
  };
};
const getInsProgramsConfiguration = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    insuranceProgramConfiguration: payload.resultList,
  };
};

const getProgram = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    program: payload,
  };
};

const getInsProgramsModal = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    insuranceProgramsModal: payload,
  };
};

const getRegions = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    regions: payload,
  };
};

const getSubDivisions = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    subDivisionsList: payload,
  };
};

const resetProgram = (state: IPartnerCreateStore): IPartnerCreateStore => {
  return {
    ...state,
    program: initialState.program,
  };
};

const resetPartnerData = (state: IPartnerCreateStore): IPartnerCreateStore => {
  return {
    ...state,
    data: {
      ...initialState.data,
    },
  };
};

const setOptions = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    optionsTab: {
      ...state.optionsTab,
      [payload.name]: payload.value,
    },
  };
};
const setUnits = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    units: payload.data,
  };
};
const setMax = (state: IPartnerCreateStore, { payload }): IPartnerCreateStore => {
  return {
    ...state,
    maxValue: payload.data,
  };
};

const setAgentModal = (state: IPartnerCreateStore, action: Action<{ payload: AgentModal }>) => {
  return {
    ...state,
    agentModal: { ...state.agentModal, ...action.payload },
  };
};

const closeAgentModal = (state: IPartnerCreateStore) => {
  return {
    ...state,
    agentModal: null,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    getInsuranceCompanies,
    getPartnersTypes,
    getPartnersData,
    getPartnersOptionsTabData,
    getPartnersRegionsTabData,
    getAgentsData,
    resetPartnerData,
    getInsPrograms,
    getInsProgramsConfiguration,
    getProgram,
    getInsProgramsModal,
    resetProgram,
    getRegions,
    getSubDivisions,
    setSorting,
    setOptions,
    getPartnersInternetSalesTabData,
    getCompaniesInternetSales,
    getSubdivisionInternetSales,
    setUnits,
    setMax,
    setAgentModal,
    closeAgentModal,
  },
});
