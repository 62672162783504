import createInstance from './axios';
import momentTz from 'moment-timezone';

const termAPI = createInstance('CONTRACTS');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');

export const printContract = async (id: number) => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/contract-terminations/${id}/pdf-report`, {
    params: {
      timeZone,
    },
  });
};

export const downLoadFiles = async (page: string | undefined, id: number | string, documentTypeCode: String) => {
  const downLoadURL =
    page === 'termination'
      ? `/contract-terminations/${id}/documents/${documentTypeCode}/files`
      : `/claims/${id}/documents/${documentTypeCode}/files`;
  return termAPI.get(downLoadURL, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
      ContentType: 'application/x-www-form-urlencoded',
    },
  });
};

export const documentDelete = async (page: string | undefined, id: number | string, documentTypeCode: String) => {
  const downLoadURL =
    page === 'termination'
      ? `/contract-terminations/${id}/documents/${documentTypeCode}/files`
      : `/claims/${id}/documents/${documentTypeCode}/files`;
  return termAPI.delete(downLoadURL);
};
