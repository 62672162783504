import React from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/css';

import { AppState } from 'store/reducer';
import { breakpoints } from 'styles/breakpoints';

const cssLogos = css`
  display: flex;
  align-items: center;
  margin-bottom: 74px;
  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 30px;
  }
`;

const cssLogo = css`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 245px;
  max-height: 54px;
  overflow: hidden;
  img {
    max-height: 54px;
    max-width: 245px;
    object-fit: contain;
  }
`;

const Header = () => {
  const whiteLabel = useSelector((store: AppState) => store.me.whiteLabel?.data);

  return (
    <div className={cssLogos}>
      <div className={cssLogo}>
        <img src={whiteLabel?.logoUrl} alt="Logo" />
      </div>
    </div>
  );
};

export default Header;
