import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification, Row, Col, Modal, Checkbox } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import config from 'config';
import { ErrorsDto } from 'types';
import { Password as PasswordField } from 'components/nextRedesign/Password/Password';
import Button from 'components/nextRedesign/Button/Button';
import Link from 'components/nextRedesign/Link/Link';
import { InputContainer } from 'components/nextRedesign/InputContainer/InputContainer';
import palleteRedesign from 'styles/palleteRedesign';
import { apiContracts } from 'api/contracts';
import { breakpoints } from 'styles/breakpoints';

import { schema, defaultValues, Values } from './Password.schema';
import { State } from '../ClientSignUp';
import Success from '../Success/Success';

const cssWrapper = css`
  max-width: 400px;
  @media (max-width: ${breakpoints.small}) {
    max-width: 300px;
  }
`;
const cssSubmit = css`
  margin-top: 40px;
  width: 100%;
`;
const cssInfo = css`
  color: ${palleteRedesign.gray.main};
`;

type Props = {
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const Password = ({ state, setState }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const watchedFields = watch();
  const isAnyFieldEmpty = Object.values(watchedFields).every((value) => !value);

  const onSubmit = (values: Values) => {
    setLoading(true);
    apiContracts.clientRegistrationController
      .registration({
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        phoneNumber: state.phoneNumber,
        birthDate: state.birthDate?.format(config.format.dateBack) ?? '',
        locality: state.locality ?? '',
        street: state.street,
        password: values.password,
        confirmPassword: values.confirmPassword,
      })
      .then(() => {
        setState({ isSuccessModalOpen: true });
      })
      .catch((error) => {
        const errorMap = {
          default: t('signup.something_went_wrong_login'),
          ENTITY_ALREADY_EXISTS: t('signup.already_exists'),
        };
        (error?.response?.data as ErrorsDto | undefined)?.errors.forEach((err) => {
          const message = errorMap[err.code] ?? errorMap.default;
          notification.error({ message });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={cssWrapper}>
      <form onSubmit={handleSubmit(onSubmit)} data-test="form">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Controller
              name="password"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <PasswordField
                  name={name}
                  errors={errors}
                  value={value}
                  onChange={(v) => onChange(v)}
                  label={t('signup.password')}
                  colorBase="grey"
                  placeholder={t('log_in.enter_your_password')}
                />
              )}
            />
          </Col>

          <Col span={24}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <PasswordField
                  name={name}
                  errors={errors}
                  value={value}
                  onChange={(v) => onChange(v)}
                  label={t('signup.repeat_password')}
                  colorBase="grey"
                  placeholder={t('log_in.enter_your_password')}
                />
              )}
            />
          </Col>

          <Col span={24}>
            <Controller
              name="termsAgree"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <InputContainer name={name} errors={errors} errorPosition="absolute">
                  <Checkbox onChange={({ target }) => onChange(target.checked)} checked={value} data-test={name}>
                    <span className={cssInfo}>
                      {t('signup.i_agree_with')}
                      <Link onClick={() => {}}>{t('signup.terms_of_use')}</Link>
                      {t('signup.of_the_site')}
                    </span>
                  </Checkbox>
                </InputContainer>
              )}
            />
          </Col>
        </Row>

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={cssSubmit}
          loading={loading}
          disabled={isAnyFieldEmpty}
        >
          {t('signup.register')}
        </Button>
      </form>
      <Modal
        footer={null}
        maskClosable={false}
        closable={false}
        destroyOnClose
        open={state.isSuccessModalOpen}
        onCancel={() => setState({ isSuccessModalOpen: false })}
        width={650}
        centered
      >
        <Success />
      </Modal>
    </div>
  );
};

export default Password;
