import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import columnsList from './columns';
import DateLabel from 'components/DateLabel';
import { moneyFormatter } from 'utils/helpers';
import PrivateFilter from 'components/PrivateFilter';
import styles from '../ContractDetailsTables.module.less';
import { FilterIcon } from 'components/Icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilteredPayments,
  getTablePage,
  setPagination,
  setPaymentsList,
  setSorting,
} from 'store/contractDetails/actions';
import { paymentsSelector } from './selectors/selector';
import CustomTable from 'components/CustomTable';
import { PaymentsStatistics } from './PaymentsStatistic';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../../../../hooks/useCurrency';
import { AppState } from 'store/reducer';
import { getAffordableInsuranceProgram } from 'screens/ContractDetails/utils';

interface ITableProps {
  contractId: number;
  insuranceSumCurrency?: string;
}

export default ({ contractId, insuranceSumCurrency }: ITableProps) => {
  const [filtersInfo, setFiltersInfo] = useState({} || null);
  const [clearedFilters, setClearedFilters] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { currentCompanyCurrency, insurancePrograms, insuranceProgram } = useSelector((store: AppState) => ({
    currentCompanyCurrency: store.userLegacy.currentCompanyCurrency,
    insurancePrograms: store.contractDetails.insurancePrograms,
    insuranceProgram: store.contractDetails.contract.insuranceProgram,
  }));

  const contractTypeConfig = {
    insurancePrograms: insurancePrograms,
    programCode: insuranceProgram?.code,
    programId: insuranceProgram.id,
  };

  const { isVZR } = getAffordableInsuranceProgram(contractTypeConfig);

  const { currency } = useCurrency(isVZR ? 'TJS' : undefined);

  const {
    data,
    total,
    loading,
    pagination: { perPage, current },
  } = useSelector(paymentsSelector);

  useEffect(() => {
    dispatch(setPaymentsList(contractId, t));
  }, [contractId]);

  const handleFilters = (value: {}) => {
    setFiltersInfo({
      ...filtersInfo,
      ...value,
    });
  };

  const getDataFromPrivateFilter = (
    filtersInfoo: { [key: string]: any } | null,
    pagination?: { current: number; perPage: number },
  ) => {
    if (pagination) {
      dispatch(setPagination(pagination.current, Number(pagination.perPage) || 1));
    } else {
      dispatch(setPagination(1, Number(perPage) || 1));
    }

    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });
    setFiltersInfo({
      ...filtersInfoo,
    });

    dispatch(
      getFilteredPayments(
        contractId,
        {
          ...filtersInfoo,
        },
        t,
      ),
    );
  };

  const filteredBySearching = (filterBy: string, value: string, type: string) => {
    if (type === 'date') {
      setFiltersInfo({
        [filterBy]: [`${value[0]}..${value[1]}`],
      });
    }

    getDataFromPrivateFilter(type === 'date' ? { [filterBy]: [`${value[0]}..${value[1]}`] } : { [filterBy]: value });
  };

  const clearFilter = () => {
    setClearedFilters(false);
  };

  const getDataPrivateFilter = (filtersInfoo: { [key: string]: any } | null) => {
    filtersInfoo &&
      Object.keys(filtersInfoo).forEach((key: any) => {
        if (!filtersInfoo[key] || filtersInfoo[key] === null || (filtersInfoo[key] && !filtersInfoo[key].length)) {
          filtersInfoo && delete filtersInfoo[key];
        }
      });
  };

  const getTablePageData = () => {
    dispatch(getTablePage(contractId));
  };

  const getColumnSearchProps = (dataIndex: string, isDate?: boolean) => {
    const filtersInfo = {};
    const filteredValue = null;

    return {
      filteredValue,
      filters:
        data &&
        data.map((item: any) => {
          return { value: String(item[dataIndex]) };
        }),
      filterIcon: (filtered: boolean) => (
        <div>
          <FilterIcon
            color={filtered ? '#fff' : null}
            title={t('contract_list.filter_select')}
            className={classnames({ active: filtered })}
          />
        </div>
      ),
      filterDropdown: (record: any) => {
        return (
          <PrivateFilter
            record={record}
            dataIndex={dataIndex}
            filtersInfo={filtersInfo}
            setFiltersInfo={setFiltersInfo}
            getDataFromPrivateFilter={getDataFromPrivateFilter}
            getDataPrivateFilter={getDataPrivateFilter}
            filteredBySearching={filteredBySearching}
            isDate={isDate}
            getTablePage={getTablePageData}
            isClearedFilters={clearedFilters}
            clearAllFilters={clearFilter}
          />
        );
      },
    };
  };

  const columns = columnsList(t).map(({ title, value }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
      width: 100,
      ...getColumnSearchProps(value),
      sorter: true,
    };
    switch (value) {
      case 'expectedPaymentDate':
      case 'paymentDate': {
        return {
          ...basicColumn,
          render: (date: any) => <DateLabel>{date}</DateLabel>,
        };
      }
      case 'periodStartDate':
      case 'periodEndDate': {
        return {
          ...basicColumn,
          width: 80,
          render: (date: any) => <DateLabel>{date}</DateLabel>,
        };
      }
      case 'expectedPaymentAmount':
      case 'paymentAmount': {
        return {
          ...basicColumn,
          width: 105,
          render: (value: number) =>
            `${moneyFormatter(value)} ${insuranceSumCurrency || currentCompanyCurrency || currency} `,
        };
      }
      case 'leftToPay': {
        return {
          ...basicColumn,
          render: (value: any) => (value ? moneyFormatter(value) : '-'),
        };
      }
      case 'checkNumber': {
        return {
          ...basicColumn,
          width: 125,
          render: (value: any) => (value === null ? '' : value || '-'),
        };
      }
      case 'id': {
        return {
          ...basicColumn,
          sorter: false,
          filtered: false,
          filters: null,
          filterIcon: null,
          filterDropdown: null,
          width: 30,
          //@ts-ignore
          render: (t, r, index) => index + 1,
        };
      }
      default: {
        return basicColumn;
      }
    }
  });

  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? 'ASC' : 'DESC';
      }
      return '';
    };

    const formatSorting = {
      field: sorting.field,
      direction: formatDirection(sorting.order),
    };
    dispatch(setSorting(formatSorting));
    getDataFromPrivateFilter(filtersInfo);
  };

  const clearFilters = () => {
    dispatch(getTablePage(contractId));
    setFiltersInfo({});
    setClearedFilters(true);
  };

  return (
    <>
      <CustomTable
        className={styles.table}
        page={current}
        contentHeight={410}
        pageSize={perPage}
        total={total || 0}
        pending={loading || false}
        data={data}
        columns={columns}
        disableTableFooter={true}
        filtersInfo={filtersInfo}
        clearFilters={clearFilters}
        handleFilters={handleFilters}
        handleTableChange={handleTableChange}
        rowKey={'id'}
      />
      <PaymentsStatistics insuranceSumCurrency={insuranceSumCurrency} isVZR={isVZR} />
    </>
  );
};
