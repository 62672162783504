export const adapter = (t) => {
  return {
    'signatory.phoneNumber': {
      name: 'phoneNumber',
      REQUIRED: t('validation.enter_phone_number_correctly_error'),
      INVALID_LENGTH: t('validation.enter_phone_number_correctly_error'),
      INVALID_PARAMETER_SIZE: t('validation.enter_phone_number_correctly_error'),
      INVALID_PHONE_NUMBER_FORMAT: t('validation.enter_phone_number_correctly_error'),
    },
    'contractObject.pid': {
      name: 'productId',
      REQUIRED: t('validation.enter_serial_number'),
      INVALID_LENGTH: t('validation.min_10_max_36_characters'),
      INVALID_PARAMETER_SIZE: t('validation.min_10_max_36_characters'),
    },
    'signatory.companyName': {
      name: 'fullName',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.enter_less_150_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_150_characters'),
    },
    'signatory.firstName': {
      name: 'firstName',
      REQUIRED: t('validation.enter_correctly_name'),
      INVALID_LENGTH: t('validation.enter_less_50_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_50_characters'),
    },
    'signatory.lastName': {
      name: 'fullName',
      REQUIRED: t('validation.enter_correctly_full_name'),
      INVALID_LENGTH: t('validation.enter_less_50_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_50_characters'),
    },
    'signatory.street': {
      name: 'streetAddress',
      REQUIRED: t('validation.enter_correctly_street_address'),
      INVALID_LENGTH: t('validation.enter_less_255_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_255_characters'),
    },
    'signatory.locality': {
      REQUIRED: t('validation.enter_correctly_street_locality'),
      INVALID_LENGTH: t('validation.enter_less_255_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_255_characters'),
    },
    'contractBeneficiary.companyName': {
      name: 'fullName',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.enter_less_150_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_150_characters'),
    },
    'contractBeneficiary.firstName': {
      name: 'firstName',
      REQUIRED: t('validation.enter_correctly_name'),
      INVALID_LENGTH: t('validation.enter_less_50_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_50_characters'),
    },
    'contractBeneficiary.lastName': {
      name: 'fullName',
      REQUIRED: t('validation.enter_correctly_full_name'),
      INVALID_LENGTH: t('validation.enter_less_50_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_50_characters'),
    },
    'contractBeneficiary.street': {
      name: 'streetAddress',
      REQUIRED: t('validation.enter_correctly_street_address'),
      INVALID_LENGTH: t('validation.enter_less_255_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_255_characters'),
    },
    'contractBeneficiary.locality': {
      REQUIRED: t('validation.enter_correctly_street_locality'),
      INVALID_LENGTH: t('validation.enter_less_255_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_255_characters'),
    },
    'contractObject.brandModel': {
      name: 'productType',
      REQUIRED: t('validation.make_or_model'),
      INVALID_LENGTH: t('validation.min_6_max_18_characters'),
      INVALID_PARAMETER_SIZE: t('validation.min_6_max_18_characters'),
    },
    'contractObject.brand': {
      name: 'brand',
      REQUIRED: t('validation.enter_brand'),
      INVALID_LENGTH: t('validation.min_2_max_18_characters'),
      INVALID_PARAMETER_SIZE: t('validation.min_2_max_18_characters'),
    },
    'contractObject.model': {
      name: 'model',
      REQUIRED: t('validation.enter_model'),
      INVALID_LENGTH: t('validation.min_2_max_18_characters'),
      INVALID_PARAMETER_SIZE: t('validation.min_2_max_18_characters'),
    },
    'contractObject.purchaseInfo.checkNumber': {
      name: 'numberCheck',
      REQUIRED: t('validation.enter_check_number'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_50_characters'),
      INVALID_LENGTH: t('validation.enter_less_50_characters'),
    },
    contractNumber: {
      name: 'contractNumberMainPart',
      NOT_UNIQUE: t('validation.contract_number_main_part'),
    },
    'contractObject.purchaseInfo.saleSum': {
      name: 'sum',
      REQUIRED: t('validation.enter_check_number'),
      INVALID_PARAMETER_SIZE: t('validation.min_3_max_16_characters'),
      INVALID_LENGTH: t('validation.min_3_max_16_characters'),
    },
    duration: {
      name: 'validityPeriod',
      REQUIRED: t('validation.choose_term_insurance'),
      CONTRACT_DURATION_NOT_VALID: t('validation.choose_correctly_term_insurance'),
    },
    'recipient.code': {
      name: 'ipn',
      REQUIRED: t('validation.enter_data'),
      INVALID_PARAMETER_SIZE: t('validation.max_8_characters'),
      INVALID_LENGTH: t('validation.incorrect_number_characters'),
      INVALID_LENGTH_SHOULD_BE_10: t('validation.max_10_characters'),
      INVALID_LENGTH_SHOULD_BE_8: t('validation.max_8_characters'),
    },
    'recipient.firstName': {
      name: 'firstName',
      REQUIRED: t('validation.enter_correctly_name'),
      INVALID_LENGTH: t('validation.enter_less_50_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_50_characters'),
    },
    'recipient.lastName': {
      name: 'lastName',
      REQUIRED: t('validation.enter_correctly_full_name'),
      INVALID_LENGTH: t('validation.enter_less_50_characters'),
      INVALID_PARAMETER_SIZE: t('validation.enter_less_50_characters'),
    },
    'recipient.accountNumber': {
      name: 'iban',
      REQUIRED: t('validation.enter_data'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.not_correct_format'),
    },
    paymentRecipient: {
      name: 'iban',
      REQUIRED: t('validation.enter_data'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.not_correct_format'),
    },
    'recipient.bankName': {
      name: 'bank',
      REQUIRED: t('validation.please_select_bank'),
      INVALID_LENGTH: t('validation.max_150_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_150_characters'),
    },

    description: {
      name: 'inevDescription',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.max_1024_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_1024_characters'),
    },
    damage: {
      name: 'inevDamage',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.max_1024_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_1024_characters'),
    },
    competentAuthorities: {
      name: 'inevCompetentAuthorities',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.max_1024_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_1024_characters'),
    },
    place: {
      name: 'place',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.max_1024_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_1024_characters'),
    },
    contactPhone: {
      name: 'phone',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.max_13_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_13_characters'),
      INVALID_PHONE_NUMBER_FORMAT: t('validation.enter_data_correctly'),
    },
    contactEmail: {
      name: 'email',
      REQUIRED: t('validation.enter_data'),
      INVALID_LENGTH: t('validation.max_50_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_50_characters'),
      INVALID_EMAIL_FORMAT: t('validation.enter_data_correctly'),
    },
    recipient: {
      name: 'iban',
      REQUIRED: t('validation.enter_data'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.enter_data_correctly'),
    },
    riskId: {
      name: 'risk',
      REQUIRED: t('validation.select_risk'),
    },
    'objectRecipient.destination': {
      name: 'objectRecipient.destination',
      REQUIRED: t('validation.enter_data'),
    },
  };
};

export const expressAdapter = (t) => {
  return {
    'expressPayment.paymentRecipient.accountNumber': {
      name: 'accountNumber',
      REQUIRED: t('validation.account_number'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.enter_data_correctly'),
      CARD_ACCOUNT_TYPE_REQUIRED: t('validation.enter_card_account_instead'),
    },
    'expressPayment.paymentRecipient': {
      name: 'accountNumber',
      REQUIRED: t('validation.account_number'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.enter_data_correctly'),
      CARD_ACCOUNT_TYPE_REQUIRED: t('validation.enter_card_account_instead'),
    },
    'paymentRecipient.accountNumber': {
      name: 'accountNumber',
      REQUIRED: t('validation.account_number'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.enter_data_correctly'),
      CARD_ACCOUNT_TYPE_REQUIRED: t('validation.enter_card_account_instead'),
    },
    paymentRecipient: {
      name: 'accountNumber',
      REQUIRED: t('validation.account_number'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.enter_data_correctly'),
      CARD_ACCOUNT_TYPE_REQUIRED: t('validation.enter_card_account_instead'),
    },
    recipient: {
      name: 'accountNumber',
      REQUIRED: t('validation.account_number'),
      INVALID_PAYMENT_RECIPIENT_ACCOUNT: t('validation.enter_data_correctly'),
      CARD_ACCOUNT_TYPE_REQUIRED: t('validation.enter_card_account_instead'),
    },
    'expressPayment.paymentRecipient.bankName': {
      name: 'bankName',
      REQUIRED: t('validation.please_select_bank'),
      INVALID_LENGTH: t('validation.max_150_characters'),
      INVALID_PARAMETER_SIZE: t('validation.max_150_characters'),
    },
    'expressPayment.paymentRecipient.code': {
      name: 'code',
      REQUIRED: t('validation.enter_data'),
      INVALID_PARAMETER_SIZE: t('validation.max_10_characters'),
      INVALID_LENGTH: t('validation.max_10_characters'),
    },
  };
};

export const fondyErrors = (t) => {
  return {
    PAYMENT_ERROR_IN_PAYMENT_SYSTEM: t('validation.payment_error_please_try_again'),
    INVALID_CARD_NUMBER: t('validation.incorrect_card_format'),
    INVALID_CARD_EXPIRY_DATE: t('validation.invalid_card_expiration_date'),
    DECLINE_NOT_SUFFICIENT_FUNDS: t('validation.insufficient_funds_card'),
    RECEIVER_CARD_DECLINED_BY_ISSUER: t('validation.senders_card_blocked'),
    INSUFFICIENT_FUNDS_ON_BALANCE_P2P_CREDIT: t('validation.not_enough_money'),
    DECLINE_REFER_TO_CARD_ISSUER: t('validation.bank_rejected_transaction'),
    PAYMENT_DECLINED_BY_PAYMENT_SYSTEM: t('validation.error_transferring_funds'),
  };
};
