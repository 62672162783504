import contentDisposition from 'content-disposition';
import contentType from 'content-type';

export const parseHeaders = (headers: any) => {
  const parsedContentDisposition = contentDisposition.parse(headers['content-disposition']);
  const parsedContentType = contentType.parse(headers['content-type']);

  return {
    parsedContentType,
    parsedContentDisposition,
  };
};

export const downloadFile = ({ blob, name, open }: { blob: Blob; name: string; open?: boolean }) => {
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');

  if (open) {
    link.target = '_blank';
  } else {
    link.download = name;
  }

  link.href = fileURL;
  link.click();
};
