const columnsList = (t) => {
  return [
    {
      title: '№',
      value: 'id',
    },
    {
      title: t('contract_details.activity_date_user_activity'),
      value: 'activityDate',
    },
    {
      title: t('contract_details.action_user_activity'),
      value: 'action',
    },
    {
      title: t('contract_details.partner_name_user_activity'),
      value: 'partnerName',
    },
    {
      title: t('contract_details.short_name_user_activity'),
      value: 'shortName',
    },
    {
      title: t('contract_details.role_user_activity'),
      value: 'role',
    },
    {
      title: t('contract_details.department_user_activity'),
      value: 'partnerDepartmentName',
    },
    {
      title: t('contract_details.login_user_activity'),
      value: 'login',
    },
  ];
};

export default columnsList;
