import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps, PickerProps } from 'antd/es/date-picker/generatePicker';
import { css, cx } from '@emotion/css';
import i18next from 'i18next';
import { FieldErrors } from 'react-hook-form';

import { PickerAdapter } from 'utils/dayjs-adapter';
import locales from 'config/locales';
import { AnyObject, Language } from 'types';
import config from 'config';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';

const cssRoot = css`
  width: 100%;
  height: 30px;
`;

const formatMap = {
  month: config.format.monthYear,
  year: config.format.year,
  default: config.format.date,
};

type Props = PickerProps<Dayjs>;

export const DatePickerBase = (props: Props) => {
  return (
    <PickerAdapter
      format={formatMap[props.picker ?? 'default'] ?? formatMap.default}
      locale={locales[i18next.language as Language].antd.DatePicker}
      {...props}
    />
  );
};

type DatePickerProps = Props & {
  label?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
};

export const DatePicker = ({
  label,
  classNameContainer,
  name,
  errors,
  requiredFields,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  className,
  value,
  ...props
}: DatePickerProps) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <PickerAdapter
        data-test={name}
        name={name}
        className={cx(cssRoot, className)}
        format={formatMap[props.picker ?? 'default'] ?? formatMap.default}
        locale={locales[i18next.language as Language].antd.DatePicker}
        bordered={false}
        value={dayjs(value).isValid() ? value : null}
        {...props}
      />
    </InputContainer>
  );
};

type RangeDatePickerProps = RangePickerProps<Dayjs> & {
  label?: string;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
};

export const RangePicker = ({
  label,
  classNameContainer,
  name,
  errors,
  requiredFields,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  className,
  ...props
}: RangeDatePickerProps) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <PickerAdapter.RangePicker
        data-test={name}
        name={name}
        className={cx(cssRoot, className)}
        locale={locales[i18next.language as Language].antd.DatePicker}
        format={config.format.date}
        bordered={false}
        {...props}
      />
    </InputContainer>
  );
};
