import getRedirectState from 'utils/redirectState';
import history from '../routes/history';
import { ROUTES } from './routes';

export const userTypesAdapter = (t?) => {
  return {
    PERSON: t('constants.individual'),
    COMPANY: t('constants.legal_entity'),
    ENTREPRENEUR: t('constants.FOP'),
  };
};
export const userTypes = (t) => {
  return [
    { title: userTypesAdapter(t).PERSON, value: 'PERSON', key: userTypesAdapter(t).PERSON },
    { title: userTypesAdapter(t).COMPANY, value: 'COMPANY', key: userTypesAdapter(t).COMPANY },
    { title: userTypesAdapter(t).ENTREPRENEUR, value: 'ENTREPRENEUR', key: userTypesAdapter(t).ENTREPRENEUR },
  ];
};

export const redirectToContract = (id, record?) => {
  history.push({
    pathname: `${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${id}`,
    state: getRedirectState(record?.insuranceObjectType),
  });
};

export const durationAdapter = (unit: number, format: string, t): string => {
  switch (unit) {
    case 5:
    case 6:
    case 12:
    case 15:
      return `${unit} ${format === 'MONTHS' ? t('constants.months') : t('constants.days')}`;
    case 36:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      return `${unit} ${t('constants.months')}`;
    case 365:
    case 180:
    case 90:
      return `${unit} ${t('constants.days')}`;
    case 3:
    case 24:
      return `${unit} ${t('constants.month')}`;
    case 21:
    case 1:
      return `${unit} ${t('constants.moon')}`;
    default:
      return `${unit} ${t('constants.month')}`;
  }
};

export const policyMessage =
  'Я заявляю, що відомості, зазначені у цій Заяві, є дійсними, і ніяка суттєва інформація, вказана у цій заяві не була прихована чи перекручена. Якщо ці відомості частково або повністю зміняться, я зобов‘язуюсь сповістити про це Страховика у письмовій формі протягом 3 (трьох) робочих днів з моменту настання змін.\n' +
  'Я згоден на те, що ця Заява, після її підписання з мого боку і сплати зазначеного у Заяві страхового платежу, стане невідꞌємною частиною Договору добровільного комплексного страхування майна та фінансових ризиків за програмою «MOBI ZAHIST», надалі – Договір, на умовах Загальних умов договору добровільного комплексного страхування майна та фінансових ризиків за програмою «MOBI ZAHIST», що міститься на офіційній WEB-сторінці ТДВ СК "СХIД-ЗАХIД": https://shid-zahid.com.ua \n' +
  'Я проінформований, що, зазначивши у цій Заяві завідомо неправильні дані, або здійснивши будь-які шахрайські дії щодо Майна протягом строку дії Договору страхування, можу отримати з боку Страховика – ТДВ СК "СХIД-ЗАХIД" відмову у виплаті страхового відшкодування.\n' +
  'Вказане у Заяві Майно не буде використовуватись особами, які не мають на це права.\n' +
  'Я поінформований, що в разі отримання від Страховика суми відшкодування збитків, заподіяних Майну з боку третіх осіб, я втрачаю право на отримання цієї суми від осіб, винних у заподіянні збитків.\n' +
  'Я ознайомлений з умовами і Правилами страхування.';

export const clientSignMessage =
  'Я заявляю, що відомості, зазначені у цій Заяві, є дійсними, і ніяка суттєва інформація, вказана у цій заяві не була прихована чи перекручена. Якщо ці відомості частково або повністю зміняться, я зобов‘язуюсь сповістити про це Страховика у письмовій формі протягом 3 (трьох) робочих днів з моменту настання змін.\n' +
  'Я згоден на те, що ця Заява, після її підписання з мого боку і сплати зазначеного у Заяві страхового платежу, стане невідꞌємною частиною Договору добровільного комплексного страхування майна та фінансових ризиків за програмою «MOBI ZAHIST», надалі – Договір, на умовах Загальних умов договору добровільного комплексного страхування майна та фінансових ризиків за програмою «MOBI ZAHIST», що міститься на офіційній WEB-сторінці СК «ІННОВАЦІЙНИЙ СТРАХОВИЙ КАПІТАЛ»: https://shid-zahid.com.ua \n' +
  'Я проінформований, що, зазначивши у цій Заяві завідомо неправильні дані, або здійснивши будь-які шахрайські дії щодо Майна протягом строку дії Договору страхування, можу отримати з боку Страховика – СК «ІННОВАЦІЙНИЙ СТРАХОВИЙ КАПІТАЛ» відмову у виплаті страхового відшкодування.\n' +
  'Вказане у Заяві Майно не буде використовуватись особами, які не мають на це права.\n' +
  'Я поінформований, що в разі отримання від Страховика суми відшкодування збитків, заподіяних Майну з боку третіх осіб, я втрачаю право на отримання цієї суми від осіб, винних у заподіянні збитків.\n' +
  'Я ознайомлений з умовами і Правилами страхування.';

export const signContractMessage =
  'Я заявляю, що дані, зазначені в цій Заяві, яка є частиною Договору страхування, є правдивими.\n' +
  'Якщо вони зміняться, я зобов‘язуюсь повідомити про це Страховика у письмовій формі протягом 3 (трьох) робочих днів з моменту настання змін. Я проінформований, що зазначення мною неправдивою інформації в цій Заяві може бути підставою відмови у виплаті страхового відшкодування. Я підтверджую, що вказане у Заяві Майно не буде використовуватись особами, які не мають на це права. \n' +
  'Я згоден, що в разі отримання від Страховика відшкодування, я втрачаю право на отримання коштів від осіб, винних у заподіянні збитків. Я підтверджую, що ознайомлений із умовами Договору страхування та Правилами страхування.';

export const supplementaryAgreementMessage =
  'Я заявляю, що відомості, зазначені у цій Заяві, є дійсними, і ніяка суттєва інформація, вказана у цій заяві не була прихована чи перекручена.\n' +
  'Якщо ці відомості частково або повністю зміняться, я зобов‘язуюсь сповістити про це Страховика у письмовій формі протягом 3 (трьох) робочих днів з моменту настання змін.\n' +
  'Я згоден на те, що ця Заява, після її підписання з мого боку, стане невідꞌємною частиною Договору добровільного комплексного страхування майна та фінансових ризиків за програмою «MOBI ZAHIST», надалі – Договір, на умовах Загальних умов договору добровільного комплексного страхування майна та фінансових ризиків за програмою «MOBI ZAHIST», що міститься на офіційній WEB-сторінці ТДВ СК "СХIД-ЗАХIД": https://shid-zahid.com.ua/ \n' +
  'Я проінформований, що, зазначивши у цій Заяві завідомо неправильні дані, або здійснивши будь-які шахрайські дії щодо Майна протягом строку дії Договору страхування, можу отримати з боку Страховика – ТДВ СК "СХIД-ЗАХIД" відмову у виплаті страхового відшкодування.\n' +
  'Вказане у Заяві Майно не буде використовуватись особами, які не мають на це права.\n' +
  'Я поінформований, що в разі отримання від Страховика суми відшкодування збитків, заподіяних Майну з боку третіх осіб, я втрачаю право на отримання цієї суми від осіб, винних у заподіянні збитків.\n' +
  'Я ознайомлений з умовами і Правилами страхування.';

export const policyMessageCovid =
  ' Я заявляю, що відомості, зазначені у цій Заяві, є дійсними і ніяка суттєва інформація, вказана у цій Заяві, не була мною прихована чи перекручена.\n' +
  ' Якщо ці відомості частково або повністю зміняться, я зобов‘язуюсь сповістити про це Страховика у письмовій формі протягом строку, що вказаний в Договорі страхування, з моменту настання змін. \n ' +
  ' Я згоден на те, що ця Заява після її підписання з мого боку і сплати зазначеного у Заяві страхового платежу, стане невідꞌємною частиною Договору добровільного страхування здоров’я на випадок коронавірусної хвороби за програмою «Covid19» (надалі – Договір) на умовах Загальних умов договору добровільного страхування здоров’я на випадок коронавірусної хвороби за програмою «Covid19», що міститься на офіційній WEB-сторінці ТДВ СК "СХIД-ЗАХIД": https://shid-zahid.com.ua.\n' +
  ' Я проінформований про те, що, зазначивши у цій Заяві завідомо неправдиві відомості або здійснивши будь-які шахрайські дії щодо умов Договору, можу отримати з боку Страховика – ТДВ СК "СХIД-ЗАХIД" відмову у здійсненні страхової виплати. \n' +
  ' Я ознайомлений з умовами і Правилами страхування.';

export const insuredPolicyMessage =
  'Я заявляю про те, що зазначена в цьому повідомленні пошкоджене майно не є застрахованим в інших страхових компаніях; за даним фактом страхової події мною не було отримано будь-якої компенсації від винуватця події чи інших осіб.';

export const insuredClaimMessage = `Я заявляю, що дані, зазначені в цій Заяві, яка є частиною Договору страхування, є правдивими. Якщо вони зміняться, я зобов‘язуюсь повідомити про це Страховика у письмовій формі протягом 3 (трьох) робочих днів з моменту настання змін. Я проінформований, що зазначення мною неправдивою інформації в цій Заяві може бути підставою відмови у виплаті страхового відшкодування. Я підтверджую, що вказане у Заяві Майно не буде використовуватись особами, які не мають на це права. Я згоден, що в разі отримання від Страховика відшкодування, я втрачаю право на отримання коштів від осіб, винних у заподіянні збитків. Я підтверджую, що ознайомлений із умовами Договору страхування та Правилами страхування.`;

export const terminationPolicyMessage =
  'На виконання вимог Закону України «Про  захист персональних даних», підписанням цієї заяви Страхувальник та Застраховані особи надають свою згоду здійснювати Страховиком дії з їх персональними даними, які пов’язані зі збиранням, реєстрацією, накопиченням, зберіганням, адаптуванням, зміною, поновленням, використанням і поширенням (розповсюдженням, реалізацією, передачею), знеособленням, знищенням відомостей про неї.';
