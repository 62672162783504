/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface InsuranceCompanyDto {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface RolePermissionMixedDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  parentId?: number;
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
  export?: boolean;
  createDisabled?: boolean;
  readDisabled?: boolean;
  updateDisabled?: boolean;
  deleteDisabled?: boolean;
  exportDisabled?: boolean;
}

export interface RolePermissionsDto {
  insuranceCompany?: InsuranceCompanyDto;
  roleName?: string;
  permissions?: Record<string, RolePermissionMixedDto>;
}

export interface UpdatePhoneDto {
  old_phone?: string;
  new_phone?: string;
  email?: string;
}

export interface UpdateEmailDto {
  old_email?: string;
  new_email?: string;
}

export interface EndpointPermissionDto {
  /** @format int64 */
  id?: number;
  permission: PermissionEnum;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
  export: boolean;
  /** @format int64 */
  endpointId: number;
}

export enum PermissionEnum {
  INSURANCE_CONTRACT_HIGH_LEVEL = 'INSURANCE_CONTRACT_HIGH_LEVEL',
  ADDITIONAL_CONTRACT_HIGH_LEVEL = 'ADDITIONAL_CONTRACT_HIGH_LEVEL',
  INSURANCE_CASE_HIGHEST_LEVEL = 'INSURANCE_CASE_HIGHEST_LEVEL',
  DASHBOARD = 'DASHBOARD',
  ACT_HIGH_LEVEL = 'ACT_HIGH_LEVEL',
  SETTINGS = 'SETTINGS',
  DRAFT_CONTRACT_HIGH_LEVEL = 'DRAFT_CONTRACT_HIGH_LEVEL',
  READY_CONTRACT_HIGH_LEVEL = 'READY_CONTRACT_HIGH_LEVEL',
  DRAFT_ADD_CONTRACT_HIGH_LEVEL = 'DRAFT_ADD_CONTRACT_HIGH_LEVEL',
  READY_ADD_CONTRACT_HIGH_LEVEL = 'READY_ADD_CONTRACT_HIGH_LEVEL',
  TERMINATION = 'TERMINATION',
  INSURANCE_CASE_REGISTRY = 'INSURANCE_CASE_REGISTRY',
  STATEMENT_INSURANCE_CASE = 'STATEMENT_INSURANCE_CASE',
  INSURANCE_CASE_HIGH_LEVEL = 'INSURANCE_CASE_HIGH_LEVEL',
  DASHBOARD_MEDICAL = 'DASHBOARD_MEDICAL',
  DASHBOARD_SELLER = 'DASHBOARD_SELLER',
  DASHBOARDMANAGER1 = 'DASHBOARD_MANAGER_1',
  DASHBOARDMANAGER2 = 'DASHBOARD_MANAGER_2',
  DASHBOARD_CLIENT = 'DASHBOARD_CLIENT',
  ACT_REGISTRY = 'ACT_REGISTRY',
  ACT = 'ACT',
  PERSONAL_SETTINGS = 'PERSONAL_SETTINGS',
  USER_SYSTEM = 'USER_SYSTEM',
  USER_ACTIVITY = 'USER_ACTIVITY',
  DEPARTMENTS = 'DEPARTMENTS',
  INSURANCE_PROGRAMS = 'INSURANCE_PROGRAMS',
  ROLES_AND_PERMISSIONS = 'ROLES_AND_PERMISSIONS',
  INSURANCE_COMPANIES = 'INSURANCE_COMPANIES',
  BRAND_CATALOG = 'BRAND_CATALOG',
  PARTNER_REGISTRY = 'PARTNER_REGISTRY',
  LPU_CATALOG = 'LPU_CATALOG',
  SERVICE_CATALOG = 'SERVICE_CATALOG',
  SUBLIM_CATALOG = 'SUBLIM_CATALOG',
  KIND_OF_INSURANCE_AND_RISK_REGISTRY = 'KIND_OF_INSURANCE_AND_RISK_REGISTRY',
  CONTRACT_REGISTRY = 'CONTRACT_REGISTRY',
  DRAFT_CONTRACT = 'DRAFT_CONTRACT',
  SIGNATURE_DRAFT_CONTRACT = 'SIGNATURE_DRAFT_CONTRACT',
  SENDING_GENERAL_OFFERTA_DRAFT_CONTRACT = 'SENDING_GENERAL_OFFERTA_DRAFT_CONTRACT',
  SENDING_INDIVIDUAL_OFFERTA_DRAFT_CONTRACT = 'SENDING_INDIVIDUAL_OFFERTA_DRAFT_CONTRACT',
  READY_CONTRACT = 'READY_CONTRACT',
  REMOVE_SIGNATURE_FROM_READY_CONTRACT = 'REMOVE_SIGNATURE_FROM_READY_CONTRACT',
  REVIZE_DELETE_REVIZE = 'REVIZE_DELETE_REVIZE',
  CHANGE_DATA_BUTTON = 'CHANGE_DATA_BUTTON',
  PROLONGATION_BUTTON = 'PROLONGATION_BUTTON',
  PROGRAM_CHANGE_BUTTON = 'PROGRAM_CHANGE_BUTTON',
  STATEMENT_TERMINATION_CONTRACT = 'STATEMENT_TERMINATION_CONTRACT',
  STATEMENT_EVENT = 'STATEMENT_EVENT',
  SENDING_GENERAL_OFFERTA_READY_CONTRACT = 'SENDING_GENERAL_OFFERTA_READY_CONTRACT',
  SENDING_INDIVIDUAL_OFFERTA_READY_CONTRACT = 'SENDING_INDIVIDUAL_OFFERTA_READY_CONTRACT',
  SENDING_CLIENT_TO_SIGNATURE = 'SENDING_CLIENT_TO_SIGNATURE',
  DRAFT_ADD_CONTRACT = 'DRAFT_ADD_CONTRACT',
  SENDING_GENERAL_OFFERTA_DRAFT_ADD_CONTRACT = 'SENDING_GENERAL_OFFERTA_DRAFT_ADD_CONTRACT',
  SENDING_INDIVIDUAL_OFFERTA_DRAFT_ADD_CONTRACT = 'SENDING_INDIVIDUAL_OFFERTA_DRAFT_ADD_CONTRACT',
  READY_ADD_CONTRACT = 'READY_ADD_CONTRACT',
  CHANGE_DATA_BUTTON_AC = 'CHANGE_DATA_BUTTON_AC',
  PROLONGATION_BUTTON_AC = 'PROLONGATION_BUTTON_AC',
  PROGRAM_CHANGE_BUTTON_AC = 'PROGRAM_CHANGE_BUTTON_AC',
  SENDING_GENERAL_OFFERTA_READY_ADD_CONTRACT = 'SENDING_GENERAL_OFFERTA_READY_ADD_CONTRACT',
  SENDING_INDIVIDUAL_OFFERTA_READY_ADD_CONTRACT = 'SENDING_INDIVIDUAL_OFFERTA_READY_ADD_CONTRACT',
  STATEMENT_TERMINATION_AC = 'STATEMENT_TERMINATION_AC',
  STATEMENT_EVENT_AC = 'STATEMENT_EVENT_AC',
  TERMINATION_REGISTRY = 'TERMINATION_REGISTRY',
  STATEMENT_TERMINATION = 'STATEMENT_TERMINATION',
  SUPPORTING_DOCUMENTS_IC = 'SUPPORTING_DOCUMENTS_IC',
  REGISTRATION_AMOUNT_PAID = 'REGISTRATION_AMOUNT_PAID',
  REGISTRATION_INSURANCE_CASE = 'REGISTRATION_INSURANCE_CASE',
  EXPRESS_PAYMENT = 'EXPRESS_PAYMENT',
  ANNULMENT = 'ANNULMENT',
  STATEMENT_INSURANCE_EVENT = 'STATEMENT_INSURANCE_EVENT',
  BUTTON_REPORT_MANAGER_CK = 'BUTTON_REPORT_MANAGER_CK',
  INSURANCE_CASE = 'INSURANCE_CASE',
  ACCEPTANCE_DOC_PAYMENT = 'ACCEPTANCE_DOC_PAYMENT',
  ACCEPTANCE_DECISION_PAYMENT = 'ACCEPTANCE_DECISION_PAYMENT',
  BUTTON_PAYMENT = 'BUTTON_PAYMENT',
  BUTTON_CANCELED = 'BUTTON_CANCELED',
  BUTTON_ANNUL = 'BUTTON_ANNUL',
  DEVICE_REGISTRY = 'DEVICE_REGISTRY',
  INSURANCE_CONTRACTS = 'INSURANCE_CONTRACTS',
  STATEMENT_EVENT_IC = 'STATEMENT_EVENT_IC',
  CLINIC_APPOINTMENT = 'CLINIC_APPOINTMENT',
  ARCHIVE = 'ARCHIVE',
  SERVICES = 'SERVICES',
  INTEGRATION_WITH_SYSTEMS = 'INTEGRATION_WITH_SYSTEMS',
  CHAT_DOCTOR = 'CHAT_DOCTOR',
}

export interface CreateUpdateUserDto {
  email?: string;
  userName?: string;
  status?: UserStatus;
  roles?: RoleEnum[];
  /** Tenant is a batch of ids (insurance company id, partner id, partner department id) delimited by '/'. Used in system to restrict   access to some data which owners is not entities of specified ids. Example: 1/2/3 or 1/2 - where 1 is an insurance company id, 2 is partner id   and 3 is a partner department id. Can be empty for users (or entities) which is not depends on exact partner or insurance company or department   and every of elements presence can be also variative. */
  tenant?: string;
  lang?: string;
  phone_number?: string;
}

export enum RoleEnum {
  ROLE_SYSTEM = 'ROLE_SYSTEM',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_PARTNER_SELLER = 'ROLE_PARTNER_SELLER',
  ROLE_PARTNER_MANAGER = 'ROLE_PARTNER_MANAGER',
  ROLE_PARTNER_ADMIN = 'ROLE_PARTNER_ADMIN',
  ROLE_INSURANCE_MANAGER = 'ROLE_INSURANCE_MANAGER',
  ROLE_INSURANCE_CLAIM_MANAGER = 'ROLE_INSURANCE_CLAIM_MANAGER',
  ROLE_INSURANCE_ADMIN = 'ROLE_INSURANCE_ADMIN',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_SALE_REGISTER_MANAGER = 'ROLE_SALE_REGISTER_MANAGER',
  ROLE_SYSTEM_PARTNER = 'ROLE_SYSTEM_PARTNER',
  ROLE_AGGREGATOR = 'ROLE_AGGREGATOR',
  ROLE_HR_MANAGER = 'ROLE_HR_MANAGER',
  ROLE_PARTNER_ASSISTANCE = 'ROLE_PARTNER_ASSISTANCE',
  ROLE_AGENT_SELLER = 'ROLE_AGENT_SELLER',
}

export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface UserDto {
  /** @format int64 */
  id?: number;
  userName?: string;
  email?: string;
  phoneNumber?: string;
  status?: UserStatus;
  /** Tenant is a batch of ids (insurance company id, partner id, partner department id) delimited with '/'. Used in system to restrict   access to some data which owners is not entities of specified ids. Example: 1/2/3 or 1/2 - where 1 is an insurance company id, 2 is partner id   and 3 is a partner department id. Can be empty for users (or entities) which is not depends on exact partner or insurance company or department   and every of elements presence can be also variative. */
  tenant?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format date-time */
  signupDate?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  lang?: string;
  roles?: string[];
}

export interface ResetPasswordWithOldDto {
  oldPassword?: string;
  /** @pattern |^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$ */
  newPassword: string;
  confirmNewPassword: string;
  password?: string;
  confirmPassword?: string;
}

export interface UserRoleDto {
  user_name?: string;
  role_name?: string;
}

/** reset.password.token.validation.dto */
export interface ResetPasswordTokenValidationDto {
  login?: string;
  token?: string;
}

/** Data to reset password. Should contain OTP token, new password and password confirmation (it should be equals) and old pass */
export interface ResetPasswordDto {
  token?: string;
  /** @pattern |^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{6,}$ */
  newPassword: string;
  confirmNewPassword: string;
  password?: string;
  confirmPassword?: string;
}

/** Pair of username and password to authenticate user */
export interface UserCredentialsDto {
  username: string;
  password: string;
}

export interface AuthenticationResponseDto {
  user?: UserDto;
  access_token?: string;
  refresh_token?: string;
}

export interface TwoFactorAuthIdentityResponse {
  /** @format int64 */
  identity?: number;
  code?: string;
}

/** Pair of username and password to authenticate user */
export interface UserCredentialsWithOTP {
  username: string;
  password: string;
  otp: string;
}

export interface RoleView {
  /** @format int64 */
  roleId?: number;
  roleName?: string;
  /** @format int64 */
  userCount?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceCompanyName?: string;
  /** @format date-time */
  changeDate?: string;
}

export interface EndpointDto {
  /** @format int64 */
  id?: number;
  endpoint?: string;
  requestMethod?: RequestMethod;
}

export enum RequestMethod {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
  TRACE = 'TRACE',
}

export interface ServiceEndpointDto {
  serviceName?: string;
  serviceEndpoints?: EndpointDto[];
}

export interface UserView {
  /** @format int64 */
  id?: number;
  status?: UserStatus;
  phoneNumber?: string;
  email?: string;
  tenant?: string;
  role?: RoleEnum;
  /** @format date-time */
  signupDate?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
}

export interface ResetPasswordSmsDto {
  token?: string;
}

export interface FindUsersParams {
  /** Whether include results total or not (used for total row) */
  params: boolean;
}

export interface LoginSystemClientParams {
  /** Service id, same as username in users authentication */
  client_id: string;
  /** Secret is like a password for usual clients. It is control word based on which service getting access to system */
  client_secret: string;
}

export interface ListRolesParams {
  /** @format int64 */
  insuranceCompanyId?: number;
}

export interface FindUserByPhoneNumberForContractParams {
  phoneNumber: string;
}

export interface FindUserByPhoneNumber1111Params {
  role: RoleEnum;
  /** Tenant is a batch of ids (insurance company id, partner id, partner department id) delimited with '/'. Used in system to restrict access to some data which owners is not entities of specified ids. Example: 1/2/3 or 1/2 - where 1 is an insurance company id, 2 is partner idand 3 is a partner department id. Can be empty for users (or entities) which is not depends on exact partner or insurance company or departmentand every of elements presence can be also variative. */
  tenant: string;
  phoneNumber: string;
  /** (email, phone number) uniq identifier of user account. It can be an email or phone number */
  login: string;
  email: string;
}

export interface GeneratePasswordResetTokenParams {
  /** (email, phone number) uniq identifier of user account. It can be an email or phone number */
  login: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios-next';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://test.eks.inrisk.com.ua' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Authentication service
 * @version 1.0.0
 * @baseUrl https://test.eks.inrisk.com.ua
 *
 * Service to getting access to INRISK system. To provide access used JWT access token which required in every secured endpoints of other system's microservices. There is 2 types of authorization implemented. Plain login-password and two-factor authorization which requires confirmation of OTP code sent to owners phone number.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  roleController = {
    /**
     * No description
     *
     * @tags role-controller
     * @name ReadRole
     * @summary read role
     * @request GET:/roles/{roleId}
     * @secure
     */
    readRole: (roleId: number, params: RequestParams = {}) =>
      this.request<Record<string, RolePermissionMixedDto>, any>({
        path: `/roles/${roleId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags role-controller
     * @name UpdateRole
     * @summary update role
     * @request PUT:/roles/{roleId}
     * @secure
     */
    updateRole: (roleId: number, data: RolePermissionsDto, params: RequestParams = {}) =>
      this.request<Record<string, RolePermissionMixedDto>, any>({
        path: `/roles/${roleId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags role-controller
     * @name DeleteRole
     * @summary delete role
     * @request DELETE:/roles/{roleId}
     * @secure
     */
    deleteRole: (roleId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/roles/${roleId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags role-controller
     * @name CreateRole
     * @summary create role
     * @request POST:/roles
     * @secure
     */
    createRole: (data: RolePermissionsDto, params: RequestParams = {}) =>
      this.request<Record<string, RolePermissionMixedDto>, any>({
        path: `/roles`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags role-controller
     * @name ListUserPermissionsById
     * @summary list user permissions by id
     * @request GET:/roles/user/{userId}
     * @secure
     */
    listUserPermissionsById: (userId: number, params: RequestParams = {}) =>
      this.request<Record<string, RolePermissionMixedDto>, any>({
        path: `/roles/user/${userId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags role-controller
     * @name GetDefaultPermissions
     * @summary get default permissions
     * @request GET:/roles/permissions/default
     * @secure
     */
    getDefaultPermissions: (params: RequestParams = {}) =>
      this.request<Record<string, RolePermissionMixedDto>, any>({
        path: `/roles/permissions/default`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags role-controller
     * @name ListRoles
     * @summary list roles
     * @request GET:/roles/all
     * @secure
     */
    listRoles: (query: ListRolesParams, params: RequestParams = {}) =>
      this.request<RoleView[], any>({
        path: `/roles/all`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  userEndpoints = {
    /**
     * No description
     *
     * @tags User Endpoints
     * @name UpdatePhone
     * @summary update phone
     * @request PUT:/internal/api/user/phone
     * @secure
     */
    updatePhone: (data: UpdatePhoneDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internal/api/user/phone`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name UpdateEmail
     * @summary update email
     * @request PUT:/internal/api/user/email
     * @secure
     */
    updateEmail: (data: UpdateEmailDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internal/api/user/email`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name GetUser
     * @summary get user
     * @request GET:/api/user/{id}
     * @secure
     */
    getUser: (id: number, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/user/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name Update1
     * @summary update
     * @request PUT:/api/user/{id}
     * @secure
     */
    update1: (id: number, data: CreateUpdateUserDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/user/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name UpdatePassword
     * @summary update password
     * @request PUT:/api/user/{id}/password
     * @secure
     */
    updatePassword: (id: number, data: ResetPasswordWithOldDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/user/${id}/password`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name UpdatePasswordWithCheckOld
     * @summary update password with check old
     * @request PUT:/api/user/{id}/password/reset
     * @secure
     */
    updatePasswordWithCheckOld: (id: number, data: ResetPasswordWithOldDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/user/${id}/password/reset`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name ChangeUserRole
     * @summary change user role
     * @request PUT:/api/user/role
     * @secure
     */
    changeUserRole: (data: UserRoleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/user/role`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name DeactivateUsers
     * @summary deactivate users
     * @request PUT:/api/user/deactivate
     * @secure
     */
    deactivateUsers: (data: number[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/user/deactivate`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name FindUsers
     * @summary find users
     * @request GET:/api/user
     * @secure
     */
    findUsers: (query: FindUsersParams, params: RequestParams = {}) =>
      this.request<UserView[], any>({
        path: `/api/user`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name Create1
     * @summary create
     * @request POST:/api/user
     * @secure
     */
    create1: (data: CreateUpdateUserDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name FindUserByPhoneNumberForContract
     * @summary find user by phone number for contract
     * @request GET:/api/user/search/contract
     * @secure
     */
    findUserByPhoneNumberForContract: (query: FindUserByPhoneNumberForContractParams, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/user/search/contract`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name FindUserByPhoneNumber1111
     * @summary find user by email
     * @request GET:/api/user/search
     * @secure
     */
    findUserByPhoneNumber1111: (query: FindUserByPhoneNumber1111Params, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/user/search`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User Endpoints
     * @name GetCurrentUser
     * @summary get current user
     * @request GET:/api/user/current
     * @secure
     */
    getCurrentUser: (params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/user/current`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  endpointPermissionController = {
    /**
     * No description
     *
     * @tags endpoint-permission-controller
     * @name Update
     * @summary update
     * @request PUT:/endpoint-permissions/{id}
     * @secure
     */
    update: (id: number, data: EndpointPermissionDto, params: RequestParams = {}) =>
      this.request<EndpointPermissionDto, any>({
        path: `/endpoint-permissions/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags endpoint-permission-controller
     * @name Delete
     * @summary delete
     * @request DELETE:/endpoint-permissions/{id}
     * @secure
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/endpoint-permissions/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags endpoint-permission-controller
     * @name Create
     * @summary create
     * @request POST:/endpoint-permissions
     * @secure
     */
    create: (data: EndpointPermissionDto, params: RequestParams = {}) =>
      this.request<EndpointPermissionDto, any>({
        path: `/endpoint-permissions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags endpoint-permission-controller
     * @name GetEndpointPermissionByEndpointId
     * @summary get endpoint permission by endpoint id
     * @request GET:/endpoint-permissions/endpoint/{endpointId}
     * @secure
     */
    getEndpointPermissionByEndpointId: (endpointId: number, params: RequestParams = {}) =>
      this.request<EndpointPermissionDto[], any>({
        path: `/endpoint-permissions/endpoint/${endpointId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags endpoint-permission-controller
     * @name GetAllPermission
     * @summary get all permission
     * @request GET:/endpoint-permissions/all-permission
     * @secure
     */
    getAllPermission: (params: RequestParams = {}) =>
      this.request<PermissionEnum[], any>({
        path: `/endpoint-permissions/all-permission`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  resetPasswordEndpoints = {
    /**
     * @description Checking user's login and incoming OTP with corresponding in DB
     *
     * @tags Reset Password Endpoints
     * @name ValidateToken
     * @summary validate token
     * @request POST:/api/user/password/reset/token
     * @secure
     */
    validateToken: (data: ResetPasswordTokenValidationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/user/password/reset/token`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Applying new password to user's account
     *
     * @tags Reset Password Endpoints
     * @name ResetPassword
     * @summary reset password
     * @request POST:/api/user/password/reset
     * @secure
     */
    resetPassword: (data: ResetPasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/user/password/reset`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description This endpoint validate your login for presence in system and prepare one time password (OTP) which required on next step of password resetting to confirm account owner. OTP sends in SMS to account owner's phone number or email if in login was specified email
     *
     * @tags Reset Password Endpoints
     * @name GeneratePasswordResetToken
     * @summary generate password reset token
     * @request GET:/api/user/password/reset/tokens
     * @secure
     */
    generatePasswordResetToken: (query: GeneratePasswordResetTokenParams, params: RequestParams = {}) =>
      this.request<ResetPasswordSmsDto, any>({
        path: `/api/user/password/reset/tokens`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  authenticationEndpoints = {
    /**
     * No description
     *
     * @tags Authentication endpoints
     * @name Logout
     * @summary logout
     * @request POST:/api/auth/logout
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/logout`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Plain authentication based on username and password. Returns both access token and refresh token.
     *
     * @tags Authentication endpoints
     * @name Login
     * @summary login
     * @request POST:/api/auth/login
     * @secure
     */
    login: (data: UserCredentialsDto, params: RequestParams = {}) =>
      this.request<AuthenticationResponseDto, any>({
        path: `/api/auth/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Authentication for internal services. This additional layer of security present according to microservice architecture of system and protects the volatile system endpoints from external calls
     *
     * @tags Authentication endpoints
     * @name LoginSystemClient
     * @summary login system client
     * @request POST:/api/auth/login/client
     * @secure
     */
    loginSystemClient: (query: LoginSystemClientParams, params: RequestParams = {}) =>
      this.request<AuthenticationResponseDto, any>({
        path: `/api/auth/login/client`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Direct client login without two-factor authentication.
     *
     * @tags Authentication endpoints
     * @name ClientLoginWithout2Fa
     * @summary client login without 2 fa
     * @request POST:/api/auth/client/login
     * @secure
     */
    clientLoginWithout2Fa: (data: UserCredentialsDto, params: RequestParams = {}) =>
      this.request<AuthenticationResponseDto, any>({
        path: `/api/auth/client/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Two factor login used for clients of insurance company. Two factor authorization receives username and password like a plain login endpoint but to confirm user access it sends an sms to users phone number with OTP code. OTP code is just a four-digit number which will be used in next step of authentication
     *
     * @tags Authentication endpoints
     * @name TwoFactorLogin
     * @summary two factor login
     * @request POST:/api/auth/2fa/login
     * @secure
     */
    twoFactorLogin: (data: UserCredentialsDto, params: RequestParams = {}) =>
      this.request<TwoFactorAuthIdentityResponse, any>({
        path: `/api/auth/2fa/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Second step of two-factor login. In this endpoint will be validated OTP code and returned both access and refresh tokens
     *
     * @tags Authentication endpoints
     * @name TwoFactorCheck
     * @summary two factor check
     * @request POST:/api/auth/2fa/check/{id}
     * @secure
     */
    twoFactorCheck: (id: number, data: UserCredentialsWithOTP, params: RequestParams = {}) =>
      this.request<AuthenticationResponseDto, any>({
        path: `/api/auth/2fa/check/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Refresh endpoint used to refresh present access token. In your 'Authentication' header should be present refresh token on which based new access will be created. According to security principals access token should not be available a long time. So your session in system will not be so long. But refresh token helps with it. Giving possibility to exchange old access to new. Especially it is important for mobile applications.
     *
     * @tags Authentication endpoints
     * @name Refresh
     * @summary refresh
     * @request GET:/api/auth/refresh_token
     * @secure
     */
    refresh: (params: RequestParams = {}) =>
      this.request<AuthenticationResponseDto, any>({
        path: `/api/auth/refresh_token`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  endpointController = {
    /**
     * No description
     *
     * @tags endpoint-controller
     * @name GetAllByServiceName
     * @summary get all by service name
     * @request GET:/endpoints/{serviceName}
     * @secure
     */
    getAllByServiceName: (serviceName: string, params: RequestParams = {}) =>
      this.request<ServiceEndpointDto, any>({
        path: `/endpoints/${serviceName}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags endpoint-controller
     * @name GetServiceNames
     * @summary get service names
     * @request GET:/endpoints/service-names
     * @secure
     */
    getServiceNames: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/endpoints/service-names`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  userVerificationController = {
    /**
     * @description Send an email with random access OTP to confirm email confirm user
     *
     * @tags User Verification Controller
     * @name CreateAndSendVerificationEmail
     * @summary create and send verification email
     * @request GET:/api/auth/verify/email
     * @secure
     */
    createAndSendVerificationEmail: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/verify/email`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
}
