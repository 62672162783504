import { t } from 'i18next';
import { TABS } from 'screens/Discounts/utils';
import { getSelectedValue } from 'screens/RegisterOfTypes/utils';
import { setFilterActionSaved, setFilterCoefficientSaved, setFilterPromoSaved } from 'store/discounts/actions';
import { IRegisterTypesOption } from 'types/registerTypes';
import { IFilterObj } from './types';

export const filtersDefaultValue = t('settings.filters_all');

export const firstOption = { value: 'Усі', name: 'Усі', id: filtersDefaultValue };

export const getAllOptions = (options: { [key: string]: string | number }[]) => {
  return [{ ...firstOption }, ...options];
};
export const getAddAll = (options: any[]) => {
  return [{ ...firstOption }, options].flat();
};
export const getAllIdes = (array: any[]) => {
  const arrayId: string[] = array.map((el) => `_${el.id}_`);
  const modifiedArray = arrayId.toString().split(',').join('');
  return modifiedArray;
};
export const getAllTypes = (array: any[]) => {
  return array
    .map((el) => `_${el}_`)
    .toString()
    .split(',')
    .join('');
};

export const onHandleChange = (name: string, options: any[], setState: (state: IRegisterTypesOption) => void) => {
  const selectedValue = getSelectedValue(name, options);
  setState(selectedValue || firstOption);
};

export const setFilterSave = (tabName: string, data) => async (dispatch) => {
  tabName === TABS.Action
    ? dispatch(setFilterActionSaved(data))
    : tabName === TABS.Coefficient
    ? dispatch(setFilterCoefficientSaved(data))
    : dispatch(setFilterPromoSaved(data));
};
export const getInitialValues = (
  filterAction: IFilterObj,
  filterCoefficient: IFilterObj,
  filterPromo: IFilterObj,
  tabName: string,
  selectName: string,
) => {
  return tabName === TABS.Action
    ? filterAction[selectName] || firstOption
    : tabName === TABS.Coefficient
    ? filterCoefficient[selectName] || firstOption
    : tabName === TABS.Promo
    ? filterPromo[selectName] || firstOption
    : firstOption;
};
