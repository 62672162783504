import { Language } from 'types';

export const langMapToFront: Record<string, Language> = {
  ua: Language.uk_UA,
  ru: Language.ru_RU,
  en: Language.en_US,
};

export const langMapToBack: Record<Language, string> = {
  [Language.uk_UA]: 'ua',
  [Language.ru_RU]: 'ru',
  [Language.en_US]: 'en',
};
