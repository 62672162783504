import createInstance from './axios';

const appointmentApi = createInstance('APPOINTMENT');

export const getSublimits = async () => {
  const { data } = await appointmentApi.get('/sublimits');
  return data;
};

export const getSublimitsChildless = async () => {
  const { data } = await appointmentApi.get('/sublimits/childless');
  return data;
};

export const getSubsublimits = async ({ pagination, sorting, filters }) => {
  const filtersData = { ...filters };

  Object.keys(filtersData).forEach((key) => {
    if (filtersData[key] === null || !filtersData[key]) {
      delete filtersData[key];
    } else {
      return filtersData[key];
    }
  });

  const requestParams = {
    sorting: sorting.direction === 'DESC' ? `-${sorting.field}` : `${sorting.field}`,
    page: pagination.current,
    page_size: pagination.perPage,
    count: true,
    ...filtersData,
  };

  const { data } = await appointmentApi.get('/subsublimits', {
    params: {
      ...requestParams,
    },
  });
  return data;
};

export const getSublimitsById = async (id) => {
  const { data } = await appointmentApi.get(`/sublimits/${id}`);
  return data;
};

export const getSubsublimitsById = async (id) => {
  const { data } = await appointmentApi.get(`/subsublimits/${id}`);
  return data;
};

export const addSublimit = async (body) => {
  const { data } = await appointmentApi.post('/sublimits/add', body);
  return data;
};

export const addSubsublimit = async (body) => {
  const { data } = await appointmentApi.post('/subsublimits/add', body);
  return data;
};

export const editSublimit = async (id, body) => {
  const { data } = await appointmentApi.put(`/sublimits/${id}`, body);
  return data;
};

export const editSubsublimit = async (id, body) => {
  const { data } = await appointmentApi.put(`/subsublimits/${id}`, body);
  return data;
};
