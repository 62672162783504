import React from 'react';
import { css, cx } from '@emotion/css';

import pallete from 'styles/pallete';
import { ColorBase } from '../InputContainer/InputContainer';
import { colorBaseMap } from 'components/nextRedesign/InputContainer/InputContainer';

const cssWrapper = css`
  border-radius: 12px;
`;
const cssRoot = css`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  &&& > * {
    height: auto;
    border-radius: 0;
    flex-grow: 1;

    .input-container_body {
      border-radius: 0;
    }

    :not(:last-child) {
      .input-container_body {
        border-right: 1px solid ${pallete.blueGray.main};
      }
    }

    :first-child {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;

      > .input-container_body {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
      }
    }

    :last-child {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;

      > .input-container_body {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }
`;
const cssStub = ({ colorBase, width = '100%' }: { colorBase: ColorBase; width?: string }) => css`
  width: ${width};
  background: ${colorBaseMap[colorBase]};
`;

interface PropsGroup {
  children: React.ReactNode;
  className?: string;
}

export const Group = ({ children, className }: PropsGroup) => {
  return (
    <div className={cssWrapper}>
      <div className={cx(cssRoot, className)}>{children}</div>
    </div>
  );
};

interface PropsStub {
  colorBase: ColorBase;
  className?: string;
  width?: string;
}

export const Stub = ({ className, colorBase, width }: PropsStub) => {
  return <div className={cx(cssStub({ colorBase, width }), className)} />;
};
