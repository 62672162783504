import React, { useRef, useEffect } from 'react';
import Message from '../Message';
import Scrollbar, { ScrollbarRef } from '../Scrollbar';
import { Message as TMessage } from 'types/chats';
import styles from './MessagesList.module.less';

interface MessagesProps {
  height?: number;
  history: Array<TMessage>;
  companionName: string;
  uuid: string;
}

const Messages: React.FC<MessagesProps> = ({ companionName, height, history, uuid }) => {
  const ref = useRef<ScrollbarRef>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollToBottom();
    }
  }, [history, ref]);

  return (
    <div className={styles.container}>
      <Scrollbar innerRef={ref} height={height}>
        <div className={styles.messages}>
          {history.map((message) => (
            <Message key={message.timetoken} {...message} uuid={uuid} companion={companionName} />
          ))}
        </div>
      </Scrollbar>
    </div>
  );
};

export default Messages;
