export const ROUTES = {
  ROOT: '/',
  CLIENT_SIGN: '/contracts/external/:id',
  CLIENT_PAYMENT: '/contracts/external/:id/order/:id',
  PAYMENT_FONFY: '/payment/fondy/:orderId',
  CLIENT_PAGE: '/client-page',
  PARTNER_AUTH: {
    LOGIN: '/login',
    PASSWORD_RECOVERY: '/password-recovery',
    INSURER_SIGNUP: '/client-signup',
  },
  CONSULTATIONS: {
    ROOT: '/consultations',
    APPOINTMENTS: '/consultations/appointments',
    CHATS: '/consultations/chats',
    HISTORY: '/consultations/history',
    SUBEVENT: '/consultations/history/:id',
  },
  JOIN_CONSULTATION_FOR_DOCTOR: '/j/:id',
  VIDEO_CHAT_FOR_DOCTOR: '/video-consultation',
  AUTH: {
    LOGIN: '/client-login',
    CLIENT_LOGIN: '/personal-account/login',
    CLIENT_SIGNUP: '/personal-account/signup',
    FORGOT_PASSWORD: '/personal-account/password-recovery',
    PASSWORD_RECOVERY: '/insurance-companies/:id/password-recovery',
    INSURER_SIGNUP: '/insurance-companies/:id/client-signup',
    SIGN_UP: '/signup',
  },
  DASHBOARD: {
    ROOT: '/dashboard',
    EXTEND: '/dashboard/extend',
    MAIN: '/dashboard/main',
    MEDICINE: '/dashboard/medicine',
    CLIENT: '/dashboard/client',
    SELLER: '/dashboard/seller',
    SETTLEMENT: '/dashboard/settlement',
  },
  CLIENT: {
    POLICIES: '/policies',
    NOTIFICATION: '/notification',
    PROFILE: '/profile',
    CONTRACT_CREATE: '/contract/create',
  },

  CLIENT_BONUSES: {
    ROOT: '/bonuses',
  },
  CONTRACTS_LIST: {
    ROOT: '/contracts-list',
    CONTRACT: {
      ROOT: '/contracts-list/contract',
    },
    CREATE: '/contracts-list/contract/create',
    WIDGET: '/contracts-list/widget/create',
    DETAILS: '/contracts-list/contract/:id',
  },
  REGISTRATION_DEVICES: {
    ROOT: '/registration-devices',
    EDIT: {
      ROOT: '/registration-devices/edit',
    },
  },
  ACTS_LIST: {
    ROOT: '/acts',
    CREATE: {
      ROOT: '/acts/create',
    },
    CREATE_NEXT: '/acts/create',
    DETAILS: '/acts/:id',
    READ: {
      ROOT: '/acts/details/:id',
    },
  },
  INSURANCE_PROGRAMS: {
    ROOT: '/insurance-programs',
    PROGRAM: {
      ROOT: '/insurance-programs/:id',
    },
    CREATE: '/insurance-programs/create',
    READ: '/insurance-programs/:id',
  },
  SEARCH: {
    ROOT: '/search',
  },
  TERMINATIONS: {
    ROOT: '/contracts-list/contract/:id/terminations',
    READ: '/contracts-list/contract/:id/terminations/:terminationId',
  },
  TERMINATIONS_LIST: {
    ROOT: '/terminations-list',
  },
  INSURED_EVENTS: {
    LIST: {
      ROOT: '/insured-events',
    },
    READ: {
      ROOT: '/insured-events/:id',
    },
    CREATE: {
      ROOT: '/insured-events/create?contractId=:contractId',
    },
  },
  USERS: {
    ROOT: '/users',
    CREATE: '/users/create',
    DETAILS: '/users/:id',
  },
  INSURANCE: {
    ROOT: '/insurance/:id',
  },
  USERS_ACTIVITIES: {
    ROOT: '/user-activities',
  },
  SETTINGS: {
    ROOT: '/settings',
  },
  DEPARTMENTS: {
    ROOT: '/departments',
    READ: {
      ROOT: '/departments/:id',
    },
    CREATE: {
      ROOT: '/departments/create',
    },
  },
  INSURANCE_COMPANIES: {
    LIST: {
      ROOT: '/settings/insurance-companies',
    },
    SETTINGS: {
      ROOT: '/settings/insurance-companies/settings/:id',
    },
    CREATE_SETTINGS: {
      ROOT: '/settings/insurance-companies/settings',
    },
  },
  INTEGRATIONS: {
    LIST: '/settings/integrations',
  },
  BRAND_PAGE: {
    READ: {
      ROOT: '/settings/brands',
    },
  },
  PERMISSIONS: {
    ROOT: '/permissions',
  },
  UI: {
    ROOT: '/ui',
  },
  NOT_FOUND: {
    ROOT: '/404',
  },
  PARTNERS_LIST: {
    ROOT: '/settings/partners',
    CREATE: '/settings/partners/create',
    READ: {
      ROOT: '/settings/partners/:id',
    },
    PARTNER_AGENT: {
      ROOT: '/settings/partners/:partnerId/agents/:id',
    },
  },
  MEDAPP: {
    ROOT: '/medapp',
    CLINICS: {
      ROOT: '/medapp/clinics',
      DETAILS: {
        ROOT: '/medapp/clinics/:id',
      },
    },
    SUBLIMITS: {
      ROOT: '/medapp/sublimits',
      DETAILS: {
        ROOT: '/medapp/sublimits/:id',
      },
    },
    SERVICES: {
      ROOT: '/medapp/services',
      DETAILS: {
        ROOT: '/medapp/services/:id',
      },
    },
  },
  REGISTER_TYPES: {
    ROOT: '/settings/register-types',
  },
  DISCOUNT: {
    ROOT: '/settings/discount',
  },
};
