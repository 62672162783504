import { nonempty, string, object, Infer, number, refiner, email, date, optional, nullable } from 'utils/struct';

export const schema = object({
  password: refiner(nonempty(string(() => 'Enter the password')), (value) => {
    if (!/^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{8,})*$/.test(value)) {
      return 'The password must be at least 8 characters long, at least one digit and at least one uppercase letter. The password must be in Latin letters only.';
    }

    return true;
  }),
  autoCompleteGoogle: nonempty(string()),
  autoCompleteGoogle2: nonempty(string()),
  numberAsNumber: optional(nullable(number())),
  numberAsString: optional(string()),
  select: optional(nullable(number())),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  password: '5',
  autoCompleteGoogle: 'Харьков, Харьковская область, Украина',
  autoCompleteGoogle2: '',
  numberAsNumber: 5555,
  numberAsString: '4444',
  select: 1,
};
