import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { checkPermissions, roleApproved } from 'roles/userCan';
import Page404 from '../components/Page404';
import { Action } from 'types/token';
import { can } from 'utils/can';
import config from 'config';
import { PermissionEnum } from 'types/dto/auth-service';

import Page403 from './Page403';

const PrivateRoute: React.FC<any> = ({ component: Component, user, role: [page, action], ...rest }) => {
  if (localStorage.getItem('accessToken') && roleApproved()) {
    const notUserId = page === 'USER_CARD' && +rest?.computedMatch?.params?.id !== +user?.userId;
    if (checkPermissions(page, action) && !notUserId) {
      return <Route {...rest} component={Component} />;
    }
    return <Route {...rest} component={Page404} />;
  }
  return <Redirect to={ROUTES.AUTH.LOGIN} />;
};

export default PrivateRoute;

interface Props extends RouteProps {
  action: Action;
  item: PermissionEnum;
}

export const PrivateRouteNext = ({ action, item, ...props }: Props) => {
  if (!localStorage.getItem(config.storage.ACCESS_TOKEN)) {
    return <Redirect to={ROUTES.AUTH.LOGIN} />;
  }

  const isCan = can(action, item);

  if (!isCan) {
    return <Route {...props} component={Page403} />;
  }

  return <Route {...props} />;
};
