import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classnames';

import FormSelect from 'components/FormSelect';
import { MicrophoneIcon } from 'components/Icons';

import styles from './Devices.module.less';

interface DevicesProps {
  publisher: any;
  audioLevel: number;
}
interface IDevicesList {
  audiooutput: IDevice[];
  audioinput: IDevice[];
  videoinput: IDevice[];
}

interface IDevice {
  value: string;
  title: string;
  key: string;
}

const Devices: React.FC<DevicesProps> = ({ publisher, audioLevel }) => {
  // @ts-ignore
  const OT = window.OT;
  const { t } = useTranslation();

  const defaultDevices = { audiooutput: [], audioinput: [], videoinput: [] };
  const [devicesList, setDevicesList] = useState<IDevicesList>(defaultDevices);
  const [activeDynamic, setActiveDynamic] = useState('');
  const [activeMicrophone, setActiveMicrophone] = useState('');
  const [activeCamera, setActiveCamera] = useState('');

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(function (devices) {
      const groupedDevices = devices.reduce((acc: IDevicesList, el) => {
        if (el.kind) {
          acc[el.kind].push({ title: el.label, value: el.deviceId, key: el.deviceId });
        }
        return acc;
      }, defaultDevices);

      setDevicesList(groupedDevices);
      setActiveDynamic(groupedDevices.audiooutput[0].value);
      setActiveMicrophone(groupedDevices.audioinput[0].value);
      setActiveCamera(groupedDevices.videoinput[0].value);
    });
  }, []);

  const onChangeOutput = (id) => {
    OT.setAudioOutputDevice(id);
    setActiveDynamic(id);
  };

  const onChangeMicrophone = (id) => {
    publisher
      ?.setAudioSource(id)
      .then(() => {
        setActiveMicrophone(id);
      })
      .catch((error) => console.error(error.name));
  };

  const onChangeCamera = (id) => {
    publisher
      ?.setVideoSource(id)
      .then(() => {
        setActiveCamera(id);
      })
      .catch((error) => console.error(error.name));
  };

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={styles.devicesHeader}>{t('chats.devices_dynamic')}</div>
        <FormSelect onChange={(id) => onChangeOutput(id)} options={devicesList.audiooutput} value={activeDynamic} />
      </div>
      <div className={styles.column}>
        <div className={styles.devicesHeader}>{t('chats.devices_microphone')}</div>
        <FormSelect
          onChange={(id) => onChangeMicrophone(id)}
          options={devicesList.audioinput}
          value={activeMicrophone}
        />
        <div className={styles.microphoneWrapper}>
          <MicrophoneIcon />
          <div className={styles.audioLevelWrapper}>
            {new Array(10).fill(null).map((el, index) => (
              <div className={cls(styles.audioLevel, { [styles.active]: index < audioLevel })} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.devicesHeader}>{t('chats.devices_camera')}</div>
        <FormSelect onChange={(id) => onChangeCamera(id)} options={devicesList.videoinput} value={activeCamera} />
      </div>
    </div>
  );
};

export default Devices;
