import React, { useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from 'antd';
import { Col, Row, Typography } from 'antd';

import AuthContainer from 'components/next/AuthContainer/AuthContainer';
import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';

import Verification from './Verification/Verification';
import ConfirmPassword from './ConfirmPassword/ConfirmPassword';
import Reset from './Reset/Reset';

type Step = 'reset' | 'verification' | 'confirmPassword';
export interface State {
  step: Step;
  token: string;
  login: string;
}

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const screens = Grid.useBreakpoint();
  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      step: 'reset',
      token: '',
      login: '',
    },
  );

  const steps: Record<Step, () => React.ReactElement> = {
    reset: () => <Reset setState={setState} />,
    verification: () => <Verification state={state} setState={setState} />,
    confirmPassword: () => <ConfirmPassword state={state} />,
  };

  const Step = steps[state.step];

  return (
    <AuthContainer>
      <Typography.Title level={screens.lg ? 1 : 3}>{t('passwordRecovery.pass_recovery')}</Typography.Title>

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Step />
        </Col>

        <Col span={24}>
          {t('passwordRecovery.no_account')}{' '}
          <Typography.Link onClick={() => history.push(ROUTES.AUTH.SIGN_UP)}>
            {t('passwordRecovery.register')}
          </Typography.Link>{' '}
          {t('passwordRecovery.or')}{' '}
          <Typography.Link onClick={() => history.push(ROUTES.PARTNER_AUTH.LOGIN)}>
            {t('passwordRecovery.log_in')}
          </Typography.Link>
        </Col>
      </Row>
    </AuthContainer>
  );
};

export default PasswordRecovery;
