import React, { ChangeEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { useChannelInfoById } from 'api/channel';
import { ROUTES } from 'constants/routes';
import Input from 'components/Input';
import { DoctorIcon } from 'components/Icons';

import styles from './JoinConsultationForDoctor.module.less';

const JoinConsultationForDoctor: React.FC = () => {
  const { t } = useTranslation();

  const [doctorName, setDoctorName] = useState('');

  const { data, history, channelId } = useChannelInfoById();

  const handleDoctorNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setDoctorName(e.target.value);
  };

  const handleGoForward = () => {
    if (data) {
      history.push(ROUTES.VIDEO_CHAT_FOR_DOCTOR, {
        channelId,
        duration: data.duration,
        sessionId: data.sessionId,
        token: data.token,
        consultationDate: data.consultationDate,
        doctorType: data.doctorType,
        user: data.user,
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.paper}>
        <div>
          <h2>{t('doctor.join_consultation')}</h2>
          <h3>
            {t('chats.start_at')} {data?.dateSlot?.time_from || '--:--'}
          </h3>
        </div>
        <form className={styles.inputWrapper} onSubmit={handleGoForward}>
          <div className={styles.inputLabel}>{t('doctor.your_full_name')}</div>
          <div className={styles.inputContainer}>
            <Input
              className={styles.input}
              fullWidth
              placeholder={t('doctor.placeholder')}
              value={doctorName}
              onChange={handleDoctorNameChange}
            />
            <DoctorIcon />
          </div>
          <Button className={styles.button} disabled={!doctorName} htmlType="submit">
            {t('doctor.login')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default JoinConsultationForDoctor;
