import { createTypes } from 'redux-compose-reducer';
import * as institutionsAPI from 'api/institution';
import { AppState } from 'store/reducer';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';

type TSetSorting = { field: string; direction: string };

export const TYPES = createTypes('institutions', [
  'setPending',
  'setError',
  'setServices',
  'setServiceById',
  'setSorting',
  'setPagination',
  'setFilters',
  'setClasses',
  'setInstitutionsClassesByInsuranceProgramId',
  'setBrands',
  'setInstitutionsBrandsByInsuranceProgramId',
  'setInstitutions',
  'setInsuranceProgramInstitutions',
  'addInsuranceProgram',
  'createAppoinmentDrEleks',
]);

const setPending = (pending: boolean) => ({
  type: TYPES.setPending,
  payload: { pending },
});

const setError = (error: any) => ({
  type: TYPES.setError,
  payload: { error },
});

export const getServices = () => async (dispatch, getState) => {
  const {
    institutions: { pagination, sorting, filters },
  }: AppState = getState();
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getAllServices({ pagination, sorting, filters });
    dispatch({ type: TYPES.setServices, payload: { services: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const getServiceById = (id) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getServiceById(id);
    dispatch({ type: TYPES.setServiceById, payload: { service: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const addService = (data) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await institutionsAPI.addService(data);
    history.push(ROUTES.MEDAPP.SERVICES.ROOT);
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const editService = (id, data) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await institutionsAPI.editService(id, data);
    history.push(ROUTES.MEDAPP.SERVICES.ROOT);
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const getInstitutionsClasses = () => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getInstitutionsClasses();
    dispatch({ type: TYPES.setClasses, payload: { classes: response } });
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const getInstitutionsClassesByInsuranceProgramId = (id) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getInstitutionsClassesByInsuranceProgramId(id);
    dispatch({
      type: TYPES.setInstitutionsClassesByInsuranceProgramId,
      payload: { classesByInsuranceProgramId: response },
    });
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
    dispatch({
      type: TYPES.setInstitutionsClassesByInsuranceProgramId,
      payload: { classesByInsuranceProgramId: [] },
    });
  } finally {
    dispatch(setPending(false));
  }
};

export const getInstitutionsBrands = () => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getInstitutionsBrands();
    dispatch({ type: TYPES.setBrands, payload: { brands: response } });
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const getInstitutionsBrandsByInsuranceProgramId = (id) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getInstitutionsBrandsByInsuranceProgramId(id);
    dispatch({
      type: TYPES.setInstitutionsBrandsByInsuranceProgramId,
      payload: { brandsByInsuranceProgramId: response },
    });
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
    dispatch({
      type: TYPES.setInstitutionsBrandsByInsuranceProgramId,
      payload: { brandsByInsuranceProgramId: [] },
    });
  } finally {
    dispatch(setPending(false));
  }
};

export const getInstitutions = (pagination, filters?) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getInstitutions(pagination, filters);
    dispatch({ type: TYPES.setInstitutions, payload: { institutions: response } });
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const getInsuranceProgramInstitutions = (id) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await institutionsAPI.getInsuranceProgramInstitutions(id);
    dispatch({ type: TYPES.setInsuranceProgramInstitutions, payload: { insuranceProgramInstitutions: response } });
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
    dispatch({ type: TYPES.setInsuranceProgramInstitutions, payload: { insuranceProgramInstitutions: [] } });
  } finally {
    dispatch(setPending(false));
  }
};

export const addInsuranceProgram = (data, successFn) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await institutionsAPI.addInsuranceProgram(data);
    dispatch({ type: TYPES.addInsuranceProgram });
    successFn();
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
  } finally {
    dispatch(setPending(false));
  }
};

export const createAppoinmentDrEleks = (data, successFn, errorFn) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await institutionsAPI.createDoctorEleksAppoinment(data);
    dispatch({ type: TYPES.createAppoinmentDrEleks });
    successFn();
  } catch (error) {
    dispatch(setError(error?.response?.data?.message));
    errorFn(error?.response?.data?.message);
  } finally {
    dispatch(setPending(false));
  }
};

export const setSorting = ({ field, direction }: TSetSorting) => ({
  type: TYPES.setSorting,
  payload: { field, direction },
});

export const setPagination = (current: number, perPage: number) => (dispatch) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setFilters = (serviceName: string, serviceTypeName: string) => (dispatch) => {
  dispatch({ type: TYPES.setFilters, payload: { serviceName, serviceTypeName } });
};
