import { userTypesAdapter } from '../../../../constants/contractData';
import DateLabel from '../../../DateLabel';
import React from 'react';
import { renderStatus } from '../../../../utils/statuses';
import { moneyFormatter } from '../../../../utils/helpers';
import TableTools from './TableTools';
import DropCustom from '../../ContractListTable/TableTools/TableToolsHandler';

const columnsList = (t) => {
  return [
    {
      title: t('contract_details.status_sub_agreements'),
      value: 'status',
      width: 85,
      dataIndex: 'status',
      key: 'status',
      render: (id: any) => renderStatus(id),
    },
    {
      title: t('contract_list.subAgreementType'),
      value: 'subAgreementType',
      width: 200,
      dataIndex: 'subAgreementType',
      key: 'subAgreementType',
      render: (value) => (value ? t(`enums.SubAgreementType.${value}`) : null),
    },
    {
      title: t('contract_details.number_sub_agreements'),
      value: 'number',
      dataIndex: 'number',
      key: 'number',
      width: 250,
    },
    {
      title: t('contract_details.phone_number_sub_agreements'),
      value: 'phoneNumber',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
    },
    {
      title: t('contract_details.client_type_sub_agreements'),
      value: 'clientType',
      dataIndex: 'clientType',
      key: 'clientType',
      render: (value) => userTypesAdapter(t)[value],
      width: 150,
    },
    {
      title: t('contract_details.client_name_sub_agreements'),
      value: 'clientName',
      dataIndex: 'clientName',
      key: 'clientName',
      width: 230,
    },
    {
      title: t('contract_details.client_locality_sub_agreements'),
      value: 'clientLocality',
      dataIndex: 'clientLocality',
      key: 'clientLocality',
      width: 230,
    },
    {
      title: t('contract_details.client_street_sub_agreements'),
      value: 'clientStreet',
      dataIndex: 'clientStreet',
      key: 'clientStreet',
      width: 150,
    },
    {
      title: t('contract_details.beneficiary_phone_sub_agreements'),
      value: 'beneficiaryPhoneNumber',
      dataIndex: 'beneficiaryPhoneNumber',
      key: 'beneficiaryPhoneNumber',
      width: 190,
    },
    {
      title: t('contract_details.beneficiary_type_sub_agreements'),
      value: 'beneficiaryType',
      dataIndex: 'beneficiaryType',
      key: 'beneficiaryType',
      render: (value) => userTypesAdapter(t)[value],
      width: 160,
    },
    {
      title: t('contract_details.beneficiary_name_sub_agreements'),
      value: 'beneficiaryName',
      dataIndex: 'beneficiaryName',
      key: 'beneficiaryName',
      width: 230,
    },
    {
      title: t('contract_details.beneficiary_locality_sub_agreements'),
      value: 'beneficiaryLocality',
      dataIndex: 'beneficiaryLocality',
      key: 'beneficiaryLocality',
      width: 190,
      render: (value, record) => `${value}, ${record.beneficiaryStreet}`,
    },
    {
      title: t('contract_details.check_number_sub_agreements'),
      value: 'check_number',
      dataIndex: 'check_number',
      key: 'check_number',
      width: 190,
    },
    {
      title: t('contract_details.sale_date_sub_agreements'),
      value: 'saleDate',
      key: 'saleDate',
      dataIndex: 'saleDate',
      render: (date: any) => <DateLabel>{date}</DateLabel>,
      width: 150,
    },
    {
      title: t('contract_details.insurance_amount_sub_agreements'),
      value: 'insuranceAmount',
      key: 'insuranceAmount',
      dataIndex: 'insuranceAmount',
      width: 150,
      render: (value: number) => `${moneyFormatter(value)} ₴`,
    },
    {
      title: t('contract_details.cost_insurance_sub_agreements'),
      value: 'calculatedPaymentAmount',
      key: 'calculatedPaymentAmount',
      dataIndex: 'calculatedPaymentAmount',
      render: (value: number) => `${moneyFormatter(value)} ₴`,
      width: 190,
    },
    {
      title: t('contract_details.subtype_sub_agreements'),
      value: 'insuranceObjectSubtype',
      key: 'insuranceObjectSubtype',
      dataIndex: 'insuranceObjectSubtype',
      width: 150,
    },
    {
      title: t('contract_details.brand_model_sub_agreements'),
      value: 'insuranceObjectName',
      key: 'insuranceObjectName',
      dataIndex: 'insuranceObjectName',
      width: 200,
    },
    {
      title: t('contract_details.object_pid_sub_agreements'),
      value: 'insuranceObjectPid',
      key: 'insuranceObjectPid',
      dataIndex: 'insuranceObjectPid',
      width: 200,
    },
    {
      title: t('contract_details.conclusion_date_sub_agreements'),
      value: 'conclusionDate',
      key: 'conclusionDate',
      dataIndex: 'conclusionDate',
      width: 150,
      render: (date: any) => <DateLabel>{date}</DateLabel>,
    },
    {
      title: t('contract_details.start_date_sub_agreements'),
      value: 'startDate',
      width: 150,
      key: 'startDate',
      dataIndex: 'startDate',
      render: (date: any) => <DateLabel>{date}</DateLabel>,
    },
    {
      title: t('contract_details.end_date_sub_agreements'),
      value: 'endDate',
      key: 'endDate',
      dataIndex: 'endDate',
      width: 150,
      render: (date: any) => <DateLabel>{date}</DateLabel>,
    },
    {
      title: t('contract_details.payment_amount_sub_agreements'),
      value: 'paymentAmount',
      key: 'paymentAmount',
      dataIndex: 'paymentAmount',
      render: (value: number) => `${moneyFormatter(value)} ₴`,
      width: 150,
    },
    {
      title: '',
      value: 'id',
      key: 'id',
      dataIndex: 'id',
      width: 50,
      fixed: 'right' as 'right',
      render: (id: number, record: any) => <DropCustom Component={TableTools} id={id} data={record} />,
    },
  ];
};

export default columnsList;
