import { composeReducer } from 'redux-compose-reducer';

import moment from 'moment';
import config from 'config';

import { contractListType } from 'types/contractList';

const initialState: contractListType = {
  data: [],
  total: 0,
  pending: false,
  error: false,
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'conclusionDate',
    direction: 'DESC',
  },
  filters: {
    dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    phoneNumber: '',
    insuranceCompanyName: '',
    insuranceCompanyId: {
      insuranceCompanyId: null,
      partnerId: null,
      partnerDepartmentId: null,
      name: null,
    },
    insuranceProgramName: '',
    insuranceProgramId: {
      id: null,
      name: null,
    },
    subdivisionName: '',
    subdivisionId: { id: null, name: null, key: null },
  },
  privateFilterItems: {},
  companies: [],
  insuranceProgram: [],
  subdivisions: [],
  privateFilteredData: [],
  totalInfo: null,
};

export default composeReducer({
  initialState,
});
