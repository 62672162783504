import { ThunkAction } from 'redux-thunk';
import dayjs, { Dayjs } from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes, AnyObject, List, PrivateFilters, SortBy } from 'types';
import {
  ClaimStatus,
  GetDocumentConfirmationsParams,
  InsuranceCompanyView,
  List10Params,
  List9Params,
  ResolutionType,
  ResultListDtoContractClaimDocumentView,
  ResultListDtoContractClaimView,
} from 'types/dto/contracts-service';
import { getDateRange, getDateRange2, getDateRange3, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import config from 'config';
import { Values, initialValues, SortingType } from 'screens/InsuredEvents/list/Filters/Filters.schema';
import { apiContracts } from 'api/contracts';
import { apiConfiguration } from 'api/configuration';
import onDownload from 'callbacks/onDownload';
import { List3Params, List4Params } from 'types/dto/configuration-service';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

export enum TabKey {
  CASE_REPORTS = 'CASE_REPORTS',
  DOCUMENT_CONFIRMATION = 'DOCUMENT_CONFIRMATION',
  CHECKOUT = 'CHECKOUT',
  INSURANCE_CASES = 'INSURANCE_CASES',
  EXPRESS_PAYMENT = 'EXPRESS_PAYMENT',
  CANCELED = 'CANCELED',
}

type Filters = Values & {
  initialization: boolean;
};
interface Data {
  activeTab: TabKey;
}
interface InsuranceCompanies {
  loading: boolean;
  data: InsuranceCompanyView[];
}
interface Partners {
  loading: boolean;
  data: InsuranceCompanyView[];
}
interface InsurancePrograms {
  loading: boolean;
  data: InsuranceCompanyView[];
}
interface CaseReports extends List {
  loading: boolean;
  data: ResultListDtoContractClaimView | null;
}
interface DocumentConfirmation extends List {
  loading: boolean;
  data: ResultListDtoContractClaimDocumentView | null;
}
interface Checkout extends List {
  loading: boolean;
  data: ResultListDtoContractClaimView | null;
}
interface InsuranceCases extends List {
  loading: boolean;
  data: ResultListDtoContractClaimView | null;
}
interface ExpressPayments extends List {
  loading: boolean;
  data: ResultListDtoContractClaimView | null;
}
interface Canceled extends List {
  loading: boolean;
  data: ResultListDtoContractClaimView | null;
}

const sortingKeyMap: Record<
  SortingType,
  {
    key: string;
    sortingRequest: (props: {
      dateFrom: Dayjs | null | undefined;
      dateTo: Dayjs | null | undefined;
    }) => Record<string, string>;
    sortingDownload: (props: {
      dateFrom: Dayjs | null | undefined;
      dateTo: Dayjs | null | undefined;
    }) => Record<string, string>;
  }
> = {
  [SortingType.CLAIM]: {
    key: 'claimDate',
    sortingRequest: ({ dateFrom, dateTo }) => ({ claimDate: getDateRange2(dateFrom, dateTo) ?? '' }),
    sortingDownload: ({ dateFrom, dateTo }) => ({ claimDate: getDateRange3(dateFrom, dateTo) ?? '' }),
  },
  [SortingType.INCIDENT]: {
    key: 'incidentDate',
    sortingRequest: ({ dateFrom, dateTo }) => ({ incidentDate: getDateRange(dateFrom, dateTo) ?? '' }),
    sortingDownload: ({ dateFrom, dateTo }) => ({ incidentDate: getDateRange(dateFrom, dateTo) ?? '' }),
  },
  [SortingType.PAYMENT]: {
    key: 'paymentDate',
    sortingRequest: ({ dateFrom, dateTo }) => ({ paymentDate: getDateRange(dateFrom, dateTo) ?? '' }),
    sortingDownload: ({ dateFrom, dateTo }) => ({ paymentDate: getDateRange(dateFrom, dateTo) ?? '' }),
  },
};

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'INSURED_EVENTS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'INSURED_EVENTS/SET_FILTERS', payload } as const),
  setInsuranceCompanies: (payload: Partial<InsuranceCompanies>) =>
    ({ type: 'INSURED_EVENTS/SET_INSURANCE_COMPANIES', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'INSURED_EVENTS/SET_PARTNERS', payload } as const),
  setInsurancePrograms: (payload: Partial<InsurancePrograms>) =>
    ({ type: 'INSURED_EVENTS/SET_INSURANCE_PROGRAMS', payload } as const),
  setCaseReports: (payload: Partial<CaseReports>) => ({ type: 'INSURED_EVENTS/SET_CASE_REPORTS', payload } as const),
  setDocumentConfirmation: (payload: Partial<DocumentConfirmation>) =>
    ({ type: 'INSURED_EVENTS/SET_DOCUMENT_CONFIRMATION', payload } as const),
  setCheckout: (payload: Partial<Checkout>) => ({ type: 'INSURED_EVENTS/SET_CHECKOUT', payload } as const),
  setInsuranceCases: (payload: Partial<InsuranceCases>) =>
    ({ type: 'INSURED_EVENTS/SET_INSURANCE_CASES', payload } as const),
  setExpressPayments: (payload: Partial<ExpressPayments>) =>
    ({ type: 'INSURED_EVENTS/SET_EXPRESS_PAYMENTS', payload } as const),
  setCanceled: (payload: Partial<Canceled>) => ({ type: 'INSURED_EVENTS/SET_CANCELED', payload } as const),
};

export const downloadReport =
  ({
    sorting,
    privateFilters,
    claimStatus,
    resolutionType,
  }: {
    sorting: SortBy | undefined;
    privateFilters: PrivateFilters | undefined;
    claimStatus: ClaimStatus | undefined;
    resolutionType: TabKey | undefined;
  }): ThunkType<void> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    await onDownload(() =>
      apiContracts.contractClaimController
        .printXlsxNotification(
          {
            distinct: true,
            timeZone: dayjs.tz.guess(),
            insuranceCompanyId: insuredEvents.filters.insuranceCompanyId,
            partnerId: insuredEvents.filters.partnerId,
            insuranceProgramId: insuredEvents.filters.insuranceProgramId,
            ...sortingKeyMap[insuredEvents.filters.sorting].sortingDownload({
              dateFrom: insuredEvents.filters.dateFrom,
              dateTo: insuredEvents.filters.dateTo,
            }),
            claimStatus,
            resolutionType,
            sorting: getSortBy(sorting),
            ...getPrivateFilters(privateFilters),
            // TODO back
          } as any,
          { format: 'blob' },
        )
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

export const loadInsuranceCompanies = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setInsuranceCompanies({ loading: true }));

  apiContracts.insuranceCompanyController
    .list4({ distinct: true, attributes: 'id,name', page_size: -1 })
    .then((res) => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch(() => {
      dispatch(actions.setInsuranceCompanies({ loading: false }));
    });
};

export const loadPartners =
  (query: List9Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartners({ loading: true, data: [] }));

    apiContracts.companyController
      .list9({
        partnerId: '<>null',
        partnerDepartmentId: 'null',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setPartners({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setPartners({ loading: false, data: [] }));
      });
  };

export const loadInsurancePrograms =
  (query: List4Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setInsurancePrograms({ loading: true, data: [] }));

    apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        attributes: 'id,name',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: [] }));
      });
  };

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  const insuranceCompany = getState().me.data?.details?.insuranceCompany;

  if (insuranceCompany) {
    dispatch(actions.setFilters({ insuranceCompanyId: insuranceCompany.id }));
    dispatch(actions.setInsuranceCompanies({ data: [{ id: insuranceCompany.id, name: insuranceCompany.name }] }));
  }

  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadInsuranceCompanies());
  dispatch(loadPartners());
  dispatch(loadInsurancePrograms());
};

export const requestData =
  (params: List10Params = {}): ThunkType<Promise<ResultListDtoContractClaimView>> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    return apiContracts.contractClaimController
      .list10({
        count: true,
        distinct: true,
        total: true,
        totalAttributes: 'expectedLossSum,paymentAmount,paid',
        insuranceCompanyId: insuredEvents.filters.insuranceCompanyId?.toString(),
        partnerId: insuredEvents.filters.partnerId?.toString(),
        insuranceProgramId: insuredEvents.filters.insuranceProgramId?.toString(),
        ...sortingKeyMap[insuredEvents.filters.sorting].sortingRequest({
          dateFrom: insuredEvents.filters?.dateFrom,
          dateTo: insuredEvents.filters?.dateTo,
        }),
        ...params,
      })
      .then((res) => res.data);
  };

export const requestCaseReports =
  (params: List10Params = {}): ThunkType<Promise<ResultListDtoContractClaimView>> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    return dispatch(
      requestData({
        claimStatus: ClaimStatus.REPORTED,
        sorting: insuredEvents.caseReports.sorting
          ? getSortBy(insuredEvents.caseReports.sorting)
          : `-${sortingKeyMap[insuredEvents.filters.sorting].key}`,
        ...getPrivateFilters(insuredEvents.caseReports.privateFilters),
        ...params,
      }),
    );
  };

export const loadCaseReports = (): ThunkType<void> => async (dispatch, getState) => {
  const insuredEvents = getState().insuredEventList;

  try {
    dispatch(actions.setCaseReports({ loading: true }));

    const res = await dispatch(requestCaseReports({ ...getPageBy(insuredEvents.caseReports.pagination) }));

    dispatch(actions.setCaseReports({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setCaseReports({ loading: false, data: {} }));
  }
};

export const requestDocumentConfirmation =
  (params: GetDocumentConfirmationsParams = {}): ThunkType<Promise<ResultListDtoContractClaimDocumentView>> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    return apiContracts.contractClaimController
      .getDocumentConfirmations({
        count: true,
        distinct: true,
        total: true,
        totalAttributes: 'expectedLossSum,paymentAmount,paid',
        insuranceCompanyId: insuredEvents.filters.insuranceCompanyId?.toString(),
        partnerId: insuredEvents.filters.partnerId?.toString(),
        // TODO back
        // @ts-ignore
        insuranceProgramId: insuredEvents.filters.insuranceProgramId,
        claimStatus: ClaimStatus.DOCUMENTS_COLLECTION,
        ...sortingKeyMap[insuredEvents.filters.sorting].sortingRequest({
          dateFrom: insuredEvents.filters?.dateFrom,
          dateTo: insuredEvents.filters?.dateTo,
        }),
        sorting: insuredEvents.documentConfirmation.sorting
          ? getSortBy(insuredEvents.documentConfirmation.sorting)
          : `-${sortingKeyMap[insuredEvents.filters.sorting].key}`,
        ...getPrivateFilters(insuredEvents.documentConfirmation.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadDocumentConfirmation = (): ThunkType<void> => async (dispatch, getState) => {
  const insuredEvents = getState().insuredEventList;

  try {
    dispatch(actions.setDocumentConfirmation({ loading: true }));

    const res = await dispatch(
      requestDocumentConfirmation({
        ...getPageBy(insuredEvents.documentConfirmation.pagination),
      }),
    );

    dispatch(actions.setDocumentConfirmation({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setDocumentConfirmation({ loading: false, data: {} }));
  }
};

export const requestCheckout =
  (params: List10Params = {}): ThunkType<Promise<ResultListDtoContractClaimView>> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    return dispatch(
      requestData({
        claimStatus: ClaimStatus.PAYMENT_CALCULATION,
        sorting: insuredEvents.checkout.sorting
          ? getSortBy(insuredEvents.checkout.sorting)
          : `-${sortingKeyMap[insuredEvents.filters.sorting].key}`,
        ...getPrivateFilters(insuredEvents.checkout.privateFilters),
        ...params,
      }),
    );
  };

export const loadCheckout = (): ThunkType<void> => async (dispatch, getState) => {
  const insuredEvents = getState().insuredEventList;

  try {
    dispatch(actions.setCheckout({ loading: true }));

    const res = await dispatch(requestCheckout({ ...getPageBy(insuredEvents.checkout.pagination) }));

    dispatch(actions.setCheckout({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setCheckout({ loading: false, data: {} }));
  }
};

export const requestInsuranceCases =
  (params: List10Params = {}): ThunkType<Promise<ResultListDtoContractClaimView>> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    return dispatch(
      requestData({
        sorting: insuredEvents.insuranceCases.sorting
          ? getSortBy(insuredEvents.insuranceCases.sorting)
          : `-${sortingKeyMap[insuredEvents.filters.sorting].key}`,
        ...getPrivateFilters(insuredEvents.insuranceCases.privateFilters),
        ...params,
      }),
    );
  };

export const loadInsuranceCases = (): ThunkType<void> => async (dispatch, getState) => {
  const insuredEvents = getState().insuredEventList;

  try {
    dispatch(actions.setInsuranceCases({ loading: true }));

    const res = await dispatch(requestInsuranceCases({ ...getPageBy(insuredEvents.insuranceCases.pagination) }));

    dispatch(actions.setInsuranceCases({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setInsuranceCases({ loading: false, data: {} }));
  }
};

export const requestExpressPayments =
  (params: AnyObject = {}): ThunkType<Promise<ResultListDtoContractClaimView>> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    return dispatch(
      requestData({
        claimStatus: ClaimStatus.PAYMENT_AWAITING,
        resolutionType: ResolutionType.EXPRESS_PAYMENT,
        sorting: insuredEvents.expressPayments.sorting
          ? getSortBy(insuredEvents.expressPayments.sorting)
          : `-${sortingKeyMap[insuredEvents.filters.sorting].key}`,
        ...getPrivateFilters(insuredEvents.expressPayments.privateFilters),
        ...params,
      }),
    );
  };

export const loadExpressPayments = (): ThunkType<void> => async (dispatch, getState) => {
  const insuredEvents = getState().insuredEventList;

  try {
    dispatch(actions.setExpressPayments({ loading: true }));

    const res = await dispatch(requestExpressPayments({ ...getPageBy(insuredEvents.expressPayments.pagination) }));

    dispatch(actions.setExpressPayments({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setExpressPayments({ loading: false, data: {} }));
  }
};

export const requestCanceled =
  (params: AnyObject = {}): ThunkType<Promise<ResultListDtoContractClaimView>> =>
  async (dispatch, getState) => {
    const insuredEvents = getState().insuredEventList;

    return dispatch(
      requestData({
        claimStatus: ClaimStatus.ANNUL,
        sorting: insuredEvents.canceled.sorting
          ? getSortBy(insuredEvents.canceled.sorting)
          : `-${sortingKeyMap[insuredEvents.filters.sorting].key}`,
        ...getPrivateFilters(insuredEvents.canceled.privateFilters),
        ...params,
      }),
    );
  };

export const loadCanceled = (): ThunkType<void> => async (dispatch, getState) => {
  const insuredEvents = getState().insuredEventList;

  try {
    dispatch(actions.setCanceled({ loading: true }));

    const res = await dispatch(requestCanceled({ ...getPageBy(insuredEvents.canceled.pagination) }));

    dispatch(actions.setCanceled({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setCanceled({ loading: false, data: {} }));
  }
};

export interface StoreInsuredEvents {
  data: Data;
  filters: Filters;
  insuranceCompanies: InsuranceCompanies;
  partners: Partners;
  insurancePrograms: InsurancePrograms;
  caseReports: CaseReports;
  documentConfirmation: DocumentConfirmation;
  checkout: Checkout;
  insuranceCases: InsuranceCases;
  expressPayments: ExpressPayments;
  canceled: Canceled;
}
export const initialState: StoreInsuredEvents = {
  data: { activeTab: TabKey.INSURANCE_CASES },
  filters: { ...initialValues, initialization: true },
  insuranceCompanies: { loading: false, data: [] },
  partners: { loading: false, data: [] },
  insurancePrograms: { loading: false, data: [] },
  caseReports: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
  documentConfirmation: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
  checkout: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
  insuranceCases: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
  expressPayments: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
  canceled: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
};

const reducer = (state = initialState, action: InferActionTypes): StoreInsuredEvents => {
  switch (action.type) {
    case 'INSURED_EVENTS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'INSURED_EVENTS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'INSURED_EVENTS/SET_INSURANCE_COMPANIES':
      return { ...state, insuranceCompanies: { ...state.insuranceCompanies, ...action.payload } };
    case 'INSURED_EVENTS/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'INSURED_EVENTS/SET_INSURANCE_PROGRAMS':
      return { ...state, insurancePrograms: { ...state.insurancePrograms, ...action.payload } };
    case 'INSURED_EVENTS/SET_CASE_REPORTS':
      return { ...state, caseReports: { ...state.caseReports, ...action.payload } };
    case 'INSURED_EVENTS/SET_DOCUMENT_CONFIRMATION':
      return { ...state, documentConfirmation: { ...state.documentConfirmation, ...action.payload } };
    case 'INSURED_EVENTS/SET_CHECKOUT':
      return { ...state, checkout: { ...state.checkout, ...action.payload } };
    case 'INSURED_EVENTS/SET_INSURANCE_CASES':
      return { ...state, insuranceCases: { ...state.insuranceCases, ...action.payload } };
    case 'INSURED_EVENTS/SET_EXPRESS_PAYMENTS':
      return { ...state, expressPayments: { ...state.expressPayments, ...action.payload } };
    case 'INSURED_EVENTS/SET_CANCELED':
      return { ...state, canceled: { ...state.canceled, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
