import { ThunkAction } from 'redux-thunk';
import { DeepPartial } from 'utility-types';
import { mergePartially } from 'merge-partially';
import dayjs from 'dayjs';
import i18n from 'i18next';
import { notification } from 'antd';

import { AppState } from 'store/reducer';
import { ActionTypes, ErrorsDto } from 'types';
import {
  ContractTerminationDocumentDto,
  ContractTerminationDto,
  DocumentTypeCode,
  FileStorageDto,
  GetTerminationDocumentConfigurationsParams,
  PaymentSystemType,
  PayoutTerminationRecipientDto,
  ReceivedDocumentDto,
  SendingType,
  TerminationInitiatorDto,
} from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import { AccountTypeCode, InsuranceProgramView, TerminationCauseDto } from 'types/dto/configuration-service';
import { apiConfiguration } from 'api/configuration';
import onDownload from 'callbacks/onDownload';
import { poll } from 'utils/helpers';
import config from 'config';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data {
  loading: boolean;
  data: ContractTerminationDto | null;
  loadingDocuments: boolean;
  isPoolingDIA: boolean;
  loadingLinkDIA: boolean;
  loadingSign: boolean;
  loadingSave: boolean;
}
interface AccountTypes {
  loading: boolean;
  data: AccountTypeCode[];
}
interface Initiators {
  loading: boolean;
  data: TerminationInitiatorDto[];
}
interface Causes {
  loading: boolean;
  data: TerminationCauseDto[];
}

interface PaymentSystems {
  loading: boolean;
  data: PaymentSystemType[];
}

export const actions = {
  reset: () => ({ type: 'TERMINATION/RESET' } as const),
  setData: (payload: DeepPartial<Data>) => ({ type: 'TERMINATION/SET_DATA', payload } as const),
  setAccountTypes: (payload: Partial<AccountTypes>) => ({ type: 'TERMINATION/SET_ACCOUNT_TYPES', payload } as const),
  setInitiators: (payload: Partial<Initiators>) => ({ type: 'TERMINATION/SET_INITIATORS', payload } as const),
  setCauses: (payload: Partial<Causes>) => ({ type: 'TERMINATION/SET_CAUSES', payload } as const),
  setPaymentSystems: (payload: Partial<PaymentSystems>) =>
    ({ type: 'TERMINATION/SET_PAYMENT_SYSTEM', payload } as const),
};

export const downloadPDF = async (id: number) => {
  await onDownload(() =>
    apiContracts.contractTerminationController
      .getContactTerminationPdf({ id, timeZone: dayjs.tz.guess() }, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const getData =
  (id: number): ThunkType<Promise<ContractTerminationDto>> =>
  async (dispatch) => {
    try {
      const { data } = await apiContracts.contractTerminationController.read8(id);

      dispatch(actions.setData({ data }));

      return data;
    } catch (error) {
      return Promise.reject();
    }
  };

export const removeSignature =
  (id: number): ThunkType<Promise<void>> =>
  async () => {
    try {
      return apiContracts.contractTerminationController.removeSignature(id).then(() => Promise.resolve());
    } catch (error) {
      return Promise.reject();
    }
  };

export const getTemplate =
  (contractId: number): ThunkType<void> =>
  async (dispatch) => {
    try {
      dispatch(actions.setData({ loading: true }));

      const res = await apiContracts.contractTerminationController.getTemplate({ contractId });

      dispatch(actions.setData({ loading: false, data: res.data }));
    } catch (error) {
      dispatch(actions.setData({ loading: false }));
    }
  };

export const getDocuments =
  (query: GetTerminationDocumentConfigurationsParams): ThunkType<Promise<ContractTerminationDocumentDto[]>> =>
  async (dispatch) => {
    try {
      dispatch(actions.setData({ loadingDocuments: true }));

      const res = await apiContracts.contractTerminationController.getTerminationDocumentConfigurations(query);

      // TODO back incompatible interfaces
      const documents: ContractTerminationDocumentDto[] = (res.data.configuration ?? []).map((el) => {
        return {
          id: el.documentType?.id,
          obligatory: el.obligatory,
          informationObligatory: el.informationObligatory,
          documentType: el.documentType ?? { id: 0, code: DocumentTypeCode.PASSPORT, name: '' },
          sharableFromDia: el.sharableFromDia,
        };
      });

      dispatch(actions.setData({ loadingDocuments: false, data: { documents, refundSum: res.data.refundSum } }));

      return documents;
    } catch (error) {
      dispatch(actions.setData({ loadingDocuments: false }));

      return Promise.reject();
    }
  };

export const getAccountTypes =
  (terminationInitiatorId: number): ThunkType<void> =>
  async (dispatch, getState) => {
    const termination = getState().termination.data.data;

    try {
      dispatch(actions.setAccountTypes({ loading: true, data: [] }));

      const res = await apiConfiguration.terminationConfigurationController.getAccountTypes({
        insuranceProgramId: termination?.contract?.insuranceProgramId ?? 0,
        terminationInitiatorId,
      });

      dispatch(actions.setAccountTypes({ loading: false, data: res.data }));
    } catch (error) {
      dispatch(actions.setAccountTypes({ loading: false }));
    }
  };

export const getInitiators = (): ThunkType<void> => async (dispatch, getState) => {
  const termination = getState().termination.data.data;

  try {
    dispatch(actions.setInitiators({ loading: true }));

    const res = await apiContracts.contractTerminationController.getInitiators({
      contractId: termination?.contract?.id ?? 0,
    });

    dispatch(actions.setInitiators({ loading: false, data: res.data }));
  } catch (error) {
    dispatch(actions.setInitiators({ loading: false }));
  }
};

export const getCauses =
  (terminationInitiatorId: number): ThunkType<void> =>
  async (dispatch, getState) => {
    const termination = getState().termination.data.data;

    try {
      dispatch(actions.setCauses({ loading: true, data: [] }));

      const res = await apiConfiguration.terminationConfigurationController.getTerminationCauses({
        terminationInitiatorId: terminationInitiatorId.toString(),
        insuranceProgramId: (termination?.contract?.insuranceProgramId ?? 0).toString(),
      });

      dispatch(actions.setCauses({ loading: false, data: res.data }));
    } catch (error) {
      dispatch(actions.setCauses({ loading: false }));
    }
  };

export const poolingDIA = (): ThunkType<Promise<ReceivedDocumentDto[]>> => async (dispatch, getState) => {
  const termination = getState().termination.data.data;

  dispatch(actions.setData({ isPoolingDIA: true }));

  return poll({
    cb: () =>
      apiContracts.contractTerminationController.isDocumentsReceived(termination?.id ?? 0).then((res) => res.data),
    predicate: (res) => res.every((el) => el.received),
    interval: 3 * 1000,
    timeout: config.DIA_TIMEOUT,
  }).finally(() => {
    dispatch(actions.setData({ isPoolingDIA: false }));
  });
};

export const sendDIADeeplink = (): ThunkType<Promise<void>> => async (dispatch, getState) => {
  const termination = getState().termination.data.data;

  dispatch(actions.setData({ loadingLinkDIA: true }));

  return apiContracts.contractTerminationController
    .sendDiaDeeplinkToShareDocuments(termination?.id ?? 0)
    .then(() => {
      dispatch(actions.setData({ loadingLinkDIA: false }));

      return undefined;
    })
    .catch(() => {
      dispatch(actions.setData({ loadingLinkDIA: false }));

      return Promise.reject();
    });
};

export const sendSmsVerification =
  ({ sendingType }: { sendingType: SendingType }): ThunkType<Promise<void>> =>
  (dispatch, getState) => {
    const termination = getState().termination.data.data;

    if (!termination) {
      return Promise.reject();
    }

    const modifiedTermination = {
      ...termination,
      recipient: {
        ...termination.recipient,
        accountNumber:
          termination?.recipient.accountNumber && !termination.recipient.accountNumber.toString().startsWith('*')
            ? termination.recipient.accountNumber
            : undefined,
      },
    };

    return apiContracts.contractTerminationController
      .sendSignVerification1({ id: termination.id ?? 0, sendingType }, modifiedTermination)
      .then(() => Promise.resolve())
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject();
        }

        notification.error({ message: error.message });

        return Promise.reject();
      });
  };

export const signTermination =
  (token?: string): ThunkType<Promise<void>> =>
  (dispatch, getState) => {
    const termination = getState().termination.data.data;

    dispatch(actions.setData({ loadingSign: true }));

    return apiContracts.contractTerminationController
      .signContractTermination({ id: termination?.id ?? 0, token })
      .then(() => {
        notification.success({ message: i18n.t('termination_details.signed_successfully') });
      })
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject();
        }

        notification.error({ message: i18n.t('popup.your_code_invalid') });

        return Promise.reject();
      })
      .finally(() => {
        dispatch(actions.setData({ loadingSign: false }));
      });
  };

export const getInsurancePrograms = (): ThunkType<Promise<InsuranceProgramView[]>> => (dispatch, getState) => {
  const termination = getState().termination.data.data;

  return apiConfiguration.insuranceProgramController
    .list4({
      distinct: true,
      attributes: 'id,name,terminationOtpDisabled',
      id: termination?.contract?.insuranceProgramId?.toString(),
    })
    .then((res) => res.data.resultList ?? []);
};

export const saveDocuments =
  (
    documents: { code: DocumentTypeCode; file: File }[],
  ): ThunkType<Promise<PromiseSettledResult<FileStorageDto>[] | void>> =>
  (dispatch, getState) => {
    if (documents.length === 0) {
      return Promise.resolve();
    }

    const termination = getState().termination.data.data;

    return Promise.allSettled(
      documents.map((el) => {
        return apiContracts.contractTerminationController
          .saveFile3(termination?.id ?? 0, el.code, { file: el.file })
          .then((res) => {
            notification.success({ message: i18n.t('termination_details.file_saved_successfully') });

            return res.data;
          })
          .catch(() => {
            notification.error({ message: i18n.t('termination_details.file_saved_error') });

            return Promise.reject();
          });
      }),
    ).catch((error) => {
      if (error === undefined) {
        return Promise.reject();
      }

      if (Array.isArray(error?.response?.data.errors)) {
        (error?.response?.data as ErrorsDto).errors.forEach((err) => {
          notification.error({ message: i18n.t(`error_code.${err.code}`) });
        });
      } else {
        notification.error({ message: error.message });
      }

      return Promise.reject();
    });
  };

export const createTermination =
  (seed: ContractTerminationDto): ThunkType<Promise<ContractTerminationDto>> =>
  async (dispatch, getState) => {
    dispatch(actions.setData({ loadingSave: true }));

    try {
      const { data } = await apiContracts.contractTerminationController.create6(seed);

      notification.success({ message: i18n.t('termination_details.saved_successfully') });

      dispatch(actions.setData({ loadingSave: false, data }));

      return data;
    } catch (error) {
      dispatch(actions.setData({ loadingSave: false }));

      if (error === undefined) {
        return Promise.reject();
      }

      if (Array.isArray(error?.response?.data.errors)) {
        (error?.response?.data as ErrorsDto).errors.forEach((err) => {
          notification.error({ message: i18n.t(`error_code.${err.code}`) });
        });
      } else {
        notification.error({ message: error.message });
      }

      return Promise.reject();
    }
  };

export const updateTermination =
  (seed: ContractTerminationDto): ThunkType<Promise<ContractTerminationDto>> =>
  async (dispatch, getState) => {
    const termination = getState().termination.data.data;

    dispatch(actions.setData({ loadingSave: true }));

    try {
      const { data } = await apiContracts.contractTerminationController.update8(termination?.id ?? 0, seed);

      notification.success({ message: i18n.t('termination_details.saved_successfully') });

      dispatch(actions.setData({ loadingSave: false, data }));

      return data;
    } catch (error) {
      dispatch(actions.setData({ loadingSave: false }));

      if (error === undefined) {
        return Promise.reject();
      }

      if (Array.isArray(error?.response?.data.errors)) {
        (error?.response?.data as ErrorsDto).errors.forEach((err) => {
          notification.error({ message: i18n.t(`error_code.${err.code}`) });
        });
      } else {
        notification.error({ message: error.message });
      }

      return Promise.reject();
    }
  };

export const downloadDocument =
  (documentTypeCode: DocumentTypeCode): ThunkType<void> =>
  async (dispatch, getState) => {
    const termination = getState().termination.data.data;

    await onDownload(() =>
      apiContracts.contractTerminationController
        .downloadFile6(termination?.id ?? 0, documentTypeCode, { format: 'blob' })
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

export const deleteDocument =
  (documentTypeCode: DocumentTypeCode): ThunkType<Promise<void>> =>
  (dispatch, getState) => {
    const termination = getState().termination.data.data;

    dispatch(actions.setData({ loadingDocuments: true }));

    return apiContracts.contractTerminationController
      .removeFile7(termination?.id ?? 0, documentTypeCode)
      .then(() => {
        dispatch(actions.setData({ loadingDocuments: false }));

        notification.success({ message: i18n.t('termination_details.file_deleted_successfully') });
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingDocuments: false }));

        if (error !== undefined) {
          notification.error({ message: error.message });
        }

        return Promise.reject();
      });
  };

export const getPaymentsSystems =
  (companyId: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setPaymentSystems({ loading: true }));

    return apiContracts.insuranceCompanyController
      .paymentSystemList(companyId)
      .then(({ data }) => {
        dispatch(actions.setPaymentSystems({ data, loading: false }));
      })
      .catch((error) => {
        dispatch(actions.setPaymentSystems({ loading: false }));
        if (error !== undefined) {
          notification.error({ message: error.message });
        }
      });
  };

export const confirmPayout =
  (terminationId: number, seed: PayoutTerminationRecipientDto): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiContracts.contractTerminationController
      .confirmPayout(terminationId, seed)
      .then(() => {
        notification.success({ message: i18n.t('termination_details.saved_successfully') });
        return undefined;
      })
      .catch((error) => {
        const codeMap: Record<string, string> = {
          CONTRACT_TERMINATION_ALREADY_PAID: i18n.t('insurance.accept_all_documents'),
          INVALID_PAYMENT_RECIPIENT_ACCOUNT: i18n.t('termination_details.invalid_payment_recipient_account'),
          CONTRACT_TERMINATION_NOT_SIGNED: i18n.t('termination_details.contract_termination_not_signed'),
          PAYOUT_LIMIT_EXCEEDED: i18n.t('termination_details.payout_limit_exceeded', { count: 25000 }),
          DEFAULT: i18n.t('termination_details.something_went_wrong'),
        };

        if (error?.response) {
          if (error?.response?.data?.errors) {
            error?.response?.data?.errors.forEach((item) => {
              const message: string = codeMap[item.code];

              if (!message) {
                return notification.error({ message: codeMap.DEFAULT });
              }

              notification.error({ message });
            });
          }
        } else {
          notification.error({
            message:
              error.message === 'Network Error' ? i18n.t('modal.no_connection_payment') : i18n.t('modal.error_payment'),
          });
        }
        return Promise.reject(undefined);
      });
  };
export interface StoreTermination {
  data: Data;
  accountTypes: AccountTypes;
  initiators: Initiators;
  causes: Causes;
  paymentSystems: PaymentSystems;
}
export const initialState: StoreTermination = {
  data: {
    loading: false,
    data: null,
    loadingDocuments: false,
    isPoolingDIA: false,
    loadingLinkDIA: false,
    loadingSign: false,
    loadingSave: false,
  },
  accountTypes: { loading: false, data: [] },
  initiators: { loading: false, data: [] },
  causes: { loading: false, data: [] },
  paymentSystems: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreTermination => {
  switch (action.type) {
    case 'TERMINATION/RESET':
      return initialState;
    case 'TERMINATION/SET_DATA':
      return { ...state, data: mergePartially.shallow(state.data, action.payload as Data) };
    case 'TERMINATION/SET_ACCOUNT_TYPES':
      return { ...state, accountTypes: { ...state.accountTypes, ...action.payload } };
    case 'TERMINATION/SET_INITIATORS':
      return { ...state, initiators: { ...state.initiators, ...action.payload } };
    case 'TERMINATION/SET_CAUSES':
      return { ...state, causes: { ...state.causes, ...action.payload } };
    case 'TERMINATION/SET_PAYMENT_SYSTEM': {
      return { ...state, paymentSystems: { ...state.paymentSystems, ...action.payload } };
    }

    default:
      return state;
  }
};

export default reducer;
