import React from 'react';
import history from 'routes/history';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import car from 'assets/img/layout/Car.svg';
import plane from 'assets/img/layout/plane.svg';
import { ROUTES } from 'constants/routes';
import { setBeneficiaryUpdated } from 'store/contractDetails/actions';
import { InsuranceProductCode } from 'types/dto/configuration-service';

import styles from '../CreateModal.module.less';

interface IProduct {
  name: string;
  code: string;
  handleClose: () => void;
  handleClick?: (code: string) => void;
}

export const WidgetProducts = ({ name, code, handleClose, handleClick }: IProduct) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const redirectOn = (redirectParam: string) => {
    history.push({ pathname: ROUTES.CONTRACTS_LIST.WIDGET, state: redirectParam });
    handleClose();
    history.go(0);
  };

  const getImg = (code) => {
    switch (code) {
      case InsuranceProductCode.VZR:
        return plane;
      default:
        return car;
    }
  };

  const getAlt = (code) => {
    switch (code) {
      case InsuranceProductCode.VZR:
        return 'vzr icon';
      default:
        return 'car icon';
    }
  };

  const getName = (code) => {
    switch (code) {
      case InsuranceProductCode.VZR:
        return 'ВЗР';
      case InsuranceProductCode.OSG:
        return 'OSAGO';
      default:
        return '';
    }
  };

  const onClickProduct = () => {
    if (handleClick) {
      handleClick(code);
    }
    dispatch(setBeneficiaryUpdated(false));
    redirectOn.call(null, code);
  };

  return (
    <div className={styles.content_item} onClick={onClickProduct} data-id={`${name}-Product`}>
      <div className={styles.content_icon}>
        <img src={getImg(code)} alt={getAlt(code)} className={styles.icon} />
      </div>
      <div className={styles.content_text}>{getName(code)}</div>
    </div>
  );
};
