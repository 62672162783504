import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { FieldErrors } from 'react-hook-form';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

interface Props extends InputProps {
  label?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  colorBase?: ColorBase;
  errorPosition?: Position;
  classNameContainer?: string;
  loading?: boolean;
  isRequired?: boolean;
  hasError?: boolean;
}

export const InputField = ({
  name,
  errors,
  requiredFields,
  label,
  colorBase = 'none',
  errorPosition,
  classNameContainer,
  loading,
  isRequired,
  hasError,
  ...props
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      isRequired={isRequired}
      hasError={hasError}
      label={label}
      colorBase={colorBase}
      errorPosition={errorPosition}
      className={classNameContainer}
    >
      <Input
        data-test={name}
        name={name}
        suffix={loading ? <LoadingOutlined /> : undefined}
        bordered={false}
        {...props}
      />
    </InputContainer>
  );
};
