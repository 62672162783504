import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';

import { Table } from 'components/next/Table/Table';
import { DaysStatisticsDto } from 'types/dto/contracts-service';
import { currencyFormat, dateFormat } from 'utils/formatters';

interface Props {
  data: DaysStatisticsDto[];
  currency: string;
}

const ByDaysTable = ({ data, currency }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<DaysStatisticsDto>[] = [
    {
      title: t('dashboard_extend.byMonth.date'),
      key: 'signDate',
      render: (_, r) => dateFormat(dayjs(r.signDate)),
    },
    {
      title: t('dashboard_extend.byMonth.countContracts'),
      key: 'countContracts',
      render: (_, r) => r.countContracts,
    },
    {
      title: t('dashboard_extend.byMonth.contractPaymentSum'),
      key: 'contractPaymentSum',
      render: (_, r) => currencyFormat(r.contractPaymentSum ?? 0, currency),
    },
    {
      title: t('dashboard_extend.byMonth.countClaims'),
      key: 'countClaims',
      render: (_, r) => r.countClaims,
    },
    {
      title: t('dashboard_extend.byMonth.claimPaymentSum'),
      key: 'claimPaymentSum',
      render: (_, r) => currencyFormat(r.claimPaymentSum ?? 0, currency),
    },
  ];

  return <Table columns={columns} dataSource={data} rowKey={(r) => r.signDate ?? ''} scroll={{ x: 500, y: 265 }} />;
};

const MemoizedByDaysTable = React.memo(ByDaysTable);

export default MemoizedByDaysTable;
