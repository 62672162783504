import React from 'react';

import { css, cx } from '@emotion/css';
interface Props {
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
}

// #TODO adapter for Typography.Link
const Link = ({ children, className, ...props }: Props) => {
  const cssRoot = css`
    color: var(--ant-primary-color);
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  `;

  return (
    <span className={cx(cssRoot, className)} {...props}>
      {children}
    </span>
  );
};

export default Link;
