import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { css } from '@emotion/css';

import { AppState } from 'store/reducer';
import Icon from 'components/nextRedesign/Icon/Icon';
import InriskLogo from 'components/nextRedesign/Icon/logoInrisk.svg';
import { IconTelegram } from 'components/next/Icon/Icon';

const cssFooter = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const cssLink = css`
  display: flex;
  align-items: center;
`;

const cssInner = css`
  display: flex;
  align-items: center;
  font-weight: 500;
  width: 25%;
`;

const cssLogo = css`
  width: 140px;
  height: 32px;
  margin-left: 6px;
`;

export const ClientFooter = () => {
  const whiteLabeling = useSelector((store: AppState) => store.me.whiteLabel?.data?.whiteLabeling);

  return (
    <div className={cssFooter}>
      <div className={cssInner}>
        <div>powered by</div>
        <img src={InriskLogo} alt="logo" className={cssLogo} />
      </div>

      <Row
        justify="end"
        className={css`
          width: 75%;
        `}
      >
        <Col span={6} offset={2}>
          {whiteLabeling?.phoneNumber && (
            <a href={`tel:${whiteLabeling.phoneNumber}`} className={cssLink}>
              <Icon type="phone" size="20px" color="primary" /> {whiteLabeling.phoneNumber}
            </a>
          )}
        </Col>
        <Col span={6} offset={2}>
          {whiteLabeling?.telegram && (
            <a href={whiteLabeling.telegram} target="_blank" rel="noopener noreferrer" className={cssLink}>
              <IconTelegram color="primary" />
              {whiteLabeling.telegram}
            </a>
          )}
        </Col>
        <Col span={6} offset={2}>
          {whiteLabeling?.email && (
            <a href={`mailto:${whiteLabeling.email}`} className={cssLink}>
              <Icon type="email" size="20px" color="primary" /> {whiteLabeling.email}
            </a>
          )}
        </Col>
      </Row>
    </div>
  );
};
