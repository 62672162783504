import { TFunction } from 'i18next';

import { durationAdapter } from 'constants/contractData';
import { ISelectProgramDuration } from 'types/contractDetails';
import { InsuranceRateDto } from 'types/dto/configuration-service';

export const getInsuranceProgram = (state, payload) => {
  let program = (state.contract?.insuranceProgram?.id && state.contract.insuranceProgram) || '';

  if (!program) {
    payload?.insurancePrograms?.map((item) => {
      if (item.code.includes('MZ_B')) {
        program = item;
      } else if (item.code.includes('CV_A')) {
        program = item;
      }
    });
  }
  if (!program) {
    program = payload?.insurancePrograms?.[0];
  }

  return program;
};

export const getContractNumberForAPEXSK = (
  prefixCheck: string,
  identifier: string,
  year: string,
  contractNumber?: string,
) => {
  const prefix = prefixCheck.includes('TT') ? prefixCheck.split('TT')[1] : prefixCheck;
  const contractNumberForAPEXSK = `${prefix}/${identifier}/${year}${contractNumber || ''}`;
  return contractNumberForAPEXSK;
};

export const getFormattedDuration = (duration: InsuranceRateDto[], t: TFunction) => {
  const mapperDuration = duration.map((item) => ({ ...item.duration, id: item.id }));

  const formattedDuration: ISelectProgramDuration[] = mapperDuration.reduce((acc, curr) => {
    return [
      ...acc,
      {
        title: durationAdapter(curr.duration ?? 0, curr.durationUnit ?? '', t),
        value: curr.duration ?? 0,
        key: curr.duration ?? 0,
        id: curr.id ?? 0,
      },
    ];
  }, []);

  return formattedDuration;
};
