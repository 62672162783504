import createInstance from './axios';
import { ExpressPaymentDto, PaymentSystemType, ValidationErrorDto } from 'types/dto/contracts-service';
import { PaymentRejectionCauseDto } from 'types/dto/configuration-service';
import { apiContracts } from './contracts';
const expressApi = createInstance('CONTRACTS');
const configService = createInstance('CONFIGURATION');

interface IExpressPayment {
  claimId?: number | string;
  expressPaymentData?: any;
  rejectId?: number | string;
  paymentSystemType?: string;
}

export const getExpressPaymentTemplate = ({ claimId }: IExpressPayment) =>
  expressApi.get<ExpressPaymentDto>(`/claims/${claimId}/express-payments/templates`);

export const sendExpressPaymentTemplate = ({ expressPaymentData, claimId }: IExpressPayment) => {
  return expressApi.post<ValidationErrorDto[]>(`/claims/${claimId}/express-payments`, {
    ...expressPaymentData.expressPayment,
  });
};

export const confirmPayment = (claimId: number, typeOfPayment: PaymentSystemType) => {
  return apiContracts.contractClaimController.confirmPayment({ id: claimId, payment_system_type: typeOfPayment });
};

export const getRejectionCauses = (insuranceProgramId: number) =>
  configService.get<PaymentRejectionCauseDto[]>(
    `claims-configurations/payment-rejection-causes?insuranceProgramId=${insuranceProgramId}`,
  );

export const rejectExpressPayment = ({ claimId, rejectId }: IExpressPayment) =>
  expressApi.get<void>(`/claims/${claimId}/express-payments/reject`, { params: { rejectId } });

export const getExpressPaymentStatus = ({ claimId }: IExpressPayment) =>
  expressApi.get<
    | 'REPORTED'
    | 'DOCUMENTS_COLLECTION'
    | 'PAYMENT_CALCULATION'
    | 'PAYMENT_AWAITING'
    | 'PAYMENT'
    | 'PAYMENT_REJECTED'
    | 'ANNUL'
  >(`/claims/${claimId}/status`);
