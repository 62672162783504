import i18n from 'translations/i18n';
import { ActionTypes, List } from 'types';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/reducer';
import { notification } from 'antd';
import history from 'routes/history';
import {
  AgentDto,
  InsuranceCompanyView,
  InsuranceProgramDto,
  List5Params,
  List6Params,
  ListParams,
  OptionDto,
  PartnerDto,
  PartnerProgramDto as ContractPartnerProgramDto,
  PartnerProgramParametersDto,
  PartnerRegionDto,
  ResultListDtoAuthUserView,
  ResultListDtoDepartmentView,
  ResultListDtoUserActivityLogView,
  ListAllParams,
  PartnerInsuranceCompanyView,
  GetInsuranceCompaniesParams,
  PartnerDiscountType,
  ResultListDtoAgentContractView,
  AgentContractView,
} from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import { getDateRange3, getPageBy, getSortBy } from 'utils/request';
import config from 'config';
import dayjs from 'dayjs';
import {
  ConfigurationInsuranceProgramDto,
  PartnerProgramDto,
  InsuranceProgramView,
  RoundingTypePair,
  ResultListDtoInternetSalesView,
  InternetSalesView,
  CreateUpdateInternetSalesDto,
  ContractDraftStoragePeriodTypePair,
  ContractNumberFormat,
  PairContractNumberFormatString,
  DiscountUnit,
  PartnerDiscountDto,
} from 'types/dto/configuration-service';
import { apiConfiguration } from 'api/configuration';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Regions {
  data?: PartnerRegionDto[];
  loading?: boolean;
}
interface RegionModal {
  isCreate?: boolean;
  loading?: boolean;
  details?: PartnerRegionDto;
}

interface Agents {
  data?: AgentDto[];
  loading: boolean;
}

interface CompanyModal {
  isCreate?: boolean;
  loading?: boolean;
  details?: InsuranceCompanyView;
}

interface AgentModal {
  isCreate?: boolean;
  loading?: boolean;
  details?: AgentDto;
}

interface Users extends List {
  data?: ResultListDtoAuthUserView | null;
  loading?: boolean;
}

interface UsersActivity extends List {
  data?: ResultListDtoUserActivityLogView | null;
  loading?: boolean;
}
interface Data {
  data?: PartnerDto | null;
  loading?: boolean;
  saveLoading?: boolean;
}

interface InternetSales {
  data?: ResultListDtoInternetSalesView | null;
  loading?: boolean;
}

interface InternetSalesModal {
  isOpen?: boolean;
  isCreate?: boolean;
  loading?: boolean;
  details?: InternetSalesView;
}

interface InsurancePrograms {
  data?: PartnerProgramDto[];
  loading?: boolean;
}

interface InternetSalesDepartments {
  data?: OptionDto[];
  loading?: boolean;
}
interface Subdivisions extends List {
  data?: ResultListDtoDepartmentView | null;
  loading?: boolean;
}

interface InsuranceCompanies {
  data?: InsuranceCompanyView[];
  loading?: boolean;
}

interface InsuranceCompaniesAll {
  data?: PartnerInsuranceCompanyView[];
  loading?: boolean;
}

interface Programs {
  data?: PartnerProgramDto[];
  loading?: boolean;
}

interface ProgramModal {
  isOpen?: boolean;
  isCreate?: boolean;
  loading?: boolean;
  partnerProgramId?: number | null;
  partnerProgram?: ContractPartnerProgramDto | null;
  roundingTypes?: RoundingTypePair[];
  contractDraftStoragePeriodTypes?: ContractDraftStoragePeriodTypePair[];
  requiredContractVerify?: boolean;
  availablePartnerPrograms?: InsuranceProgramDto[];
  currentProgramConfig?: ConfigurationInsuranceProgramDto | null;
  contractNumberFormat?: PairContractNumberFormatString[];
}

interface GlobalProgramsConfiguration {
  data?: InsuranceProgramView[];
  loading?: boolean;
}

interface PartnerOptions {
  data?: PartnerProgramParametersDto | null;
  loading?: boolean;
}

interface PartnerDiscounts {
  data?: PartnerDiscountDto[] | null;
  agentContracts?: AgentContractView[];
  contractDiscountUnit?: DiscountUnit[];
  programDiscountUnit?: DiscountUnit[];
  loading?: boolean;
}

export const actions = {
  resetPartner: () => ({ type: 'PARTNER/RESET' } as const),
  setUsers: (payload: Users) => ({ type: 'PARTNER/SET_USERS', payload } as const),
  setAgents: (payload: Agents) => ({ type: 'PARTNER/SET_AGENTS', payload } as const),
  setPartner: (payload: Data) => ({ type: 'PARTNER/SET_PARTNER', payload } as const),
  setRegions: (payload: Regions) => ({ type: 'PARTNER/SET_REGIONS', payload } as const),
  setPrograms: (payload: Programs) => ({ type: 'PARTNER/SET_PROGRAMS', payload } as const),
  setSubdivisions: (payload: Subdivisions) => ({ type: 'PARTNER/SET_SUBDIVISIONS', payload } as const),
  setUsersActivity: (payload: UsersActivity) => ({ type: 'PARTNER/SET_USERS_ACTIVITY', payload } as const),
  setInternetSales: (payload: InternetSales) => ({ type: 'PARTNER/SET_INTERNET_SALES', payload } as const),
  setPartnerOptions: (payload: PartnerOptions) => ({ type: 'PARTNER/SET_PARTNER_OPTIONS', payload } as const),
  setGlobalProgramsConfiguration: (payload: GlobalProgramsConfiguration) =>
    ({ type: 'PARTNER/SET_GLOBAL_PROGRAM_CONFIGURATION', payload } as const),
  setRegionModal: (payload: RegionModal | null, isFullReset?: boolean) =>
    ({ type: 'PARTNER/SET_REGION_MODAL', payload, isFullReset } as const),
  setAgentModal: (payload: AgentModal | null, isFullReset?: boolean) =>
    ({ type: 'PARTNER/SET_AGENT_MODAL', payload, isFullReset } as const),
  setCompanyModal: (payload: CompanyModal | null, isFullReset?: boolean) =>
    ({ type: 'PARTNER/SET_COMPANY_MODAL', payload, isFullReset } as const),
  setInternetSalesModal: (payload: InternetSalesModal | null, isFullReset?: boolean) =>
    ({ type: 'PARTNER/SET_INTERNET_SALES_MODAL', payload, isFullReset } as const),
  setInsurancePrograms: (payload: InsurancePrograms) => ({ type: 'PARTNER/SET_INSURANCE_PROGRAMS', payload } as const),
  setInternetSalesDepartments: (payload: InternetSalesDepartments | null) =>
    ({ type: 'PARTNER/SET_INTERNET_SALES_DEPARTMENTS', payload } as const),
  setInsuranceCompanies: (payload: InsuranceCompanies) =>
    ({ type: 'PARTNER/SET_INSURANCE_COMPANIES', payload } as const),
  setAllInsuranceCompanies: (payload: InsuranceCompanies) =>
    ({ type: 'PARTNER/SET_ALL_INSURANCE_COMPANIES', payload } as const),
  setPartnerInsuranceCompanies: (payload: InsuranceCompanies) =>
    ({ type: 'PARTNER/SET_PARTNER_INSURANCE_COMPANIES', payload } as const),
  setProgramModal: (payload: ProgramModal | null, isFullReset?: boolean) =>
    ({ type: 'PARTNER/SET_PROGRAM_MODAL', payload, isFullReset } as const),
  setPartnerDiscounts: (payload: PartnerDiscounts) => ({ type: 'PARTNER/SET_PARTNER_DISCOUNT', payload } as const),
};

export const getPartner =
  (partnerId: number): ThunkType<Promise<PartnerDto>> =>
  (dispatch) => {
    dispatch(actions.setPartner({ loading: true }));

    return apiContracts.partnerController
      .read2(partnerId)
      .then(({ data }) => {
        dispatch(actions.setPartner({ data, loading: false }));
        return data;
      })
      .catch((error) => {
        dispatch(actions.setPartner({ loading: false }));
        return Promise.reject(undefined);
      });
  };

export const updatePartner =
  (partnerId: number, seed: PartnerDto): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setPartner({ saveLoading: true }));

    return apiContracts.partnerController
      .update2(partnerId, seed)
      .then(({ data }) => {
        notification.success({ message: i18n.t('popup.update_successfully') });
        dispatch(actions.setPartner({ data, saveLoading: false }));
      })
      .catch((error) => {
        dispatch(actions.setPartner({ saveLoading: false }));

        if (Array.isArray(error?.response?.data.errors)) {
          error?.response?.data.errors.forEach((item) => {
            if (item.code === 'NOT_UNIQUE' && item.field === 'code') {
              notification.error({
                message: i18n.t('popup.partner_code_already_exists'),
              });

              return;
            }

            if (item.code === 'NOT_UNIQUE' && item.field === 'phoneNumber') {
              notification.error({
                message: i18n.t('popup.partner_code_phone_number'),
              });

              return;
            }

            if (item.code === 'NOT_UNIQUE' && item.field === 'enterpriseCode') {
              notification.error({
                message: i18n.t('popup.partner_esreou_already_exists'),
              });

              return;
            }

            if (item.code === 'CHANGE_TYPE_PROHIBITED_IF_CONTRACT_IS_PRESENT') {
              notification.error({
                message: i18n.t('popup.partner_code_type_of_partner'),
              });

              return;
            }

            if (item.code === 'INVALID_PHONE_NUMBER_FORMAT') {
              notification.error({
                message: i18n.t('validation.INVALID_PHONE_NUMBER_FORMAT'),
              });

              return;
            }

            notification.error({
              message: i18n.t('popup.save_error'),
            });
          });
        }
      });
  };

export const createPartner =
  (seed: PartnerDto): ThunkType<void> =>
  (dispatch) => {
    apiContracts.partnerController
      .create1(seed)
      .then(({ data }) => {
        notification.success({ message: i18n.t('popup.created_successfully') });
        history.replace(generatePath(ROUTES.PARTNERS_LIST.READ.ROOT, { id: data.id }));
      })
      .catch((error) => {
        if (Array.isArray(error?.response?.data.errors)) {
          error?.response?.data.errors.forEach((item) => {
            if (item.code === 'NOT_UNIQUE' && item.field === 'code') {
              notification.error({
                message: i18n.t('popup.partner_code_already_exists'),
              });

              return;
            }

            if (item.code === 'NOT_UNIQUE' && item.field === 'phoneNumber') {
              notification.error({
                message: i18n.t('popup.partner_code_phone_number'),
              });

              return;
            }

            if (item.code === 'NOT_UNIQUE' && item.field === 'enterpriseCode') {
              notification.error({
                message: i18n.t('popup.partner_esreou_already_exists'),
              });

              return;
            }

            if (item.code === 'CHANGE_TYPE_PROHIBITED_IF_CONTRACT_IS_PRESENT') {
              notification.error({
                message: i18n.t('popup.partner_code_type_of_partner'),
              });

              return;
            }

            if (item.code === 'INVALID_PHONE_NUMBER_FORMAT') {
              notification.error({
                message: i18n.t('validation.INVALID_PHONE_NUMBER_FORMAT'),
              });

              return;
            }

            notification.error({
              message: i18n.t('popup.save_error'),
            });
          });
        }
      });
  };

export const getRegions =
  (partnerId: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setRegions({ loading: true }));

    return apiContracts.partnerController
      .getRegions(partnerId)
      .then(({ data }) => {
        dispatch(actions.setRegions({ data, loading: false }));
      })
      .catch((error) => {
        dispatch(actions.setRegions({ data: [], loading: false }));
      });
  };

export const deleteRegion =
  (regionId: number): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiContracts.partnerController
      .deleteRegion(regionId)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.region_was_deleted') });
        return undefined;
      })
      .catch((error) => {
        notification.error({ message: error.message });
        return Promise.reject(undefined);
      });
  };

export const updateRegion =
  (partnerId: number, regionId: number, data: PartnerRegionDto): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiContracts.partnerController
      .updateRegion(partnerId, regionId, data)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.saved') });
        return Promise.resolve(undefined);
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  };

export const createRegions =
  (partnerId: number, data: PartnerRegionDto[]): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiContracts.partnerController
      .saveRegions(partnerId, data)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.region_was_added') });
        return Promise.resolve(undefined);
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          error?.response?.data?.errors.forEach((item) => {
            if (item.code.includes('already exist'))
              notification.error({
                message: i18n.t('popup.region_already_exists'),
              });
          });
        }

        return Promise.reject(undefined);
      });
  };

export const getAgentsByPartnerId =
  (partnerId: number): ThunkType<Promise<AgentDto[]>> =>
  (dispatch) => {
    dispatch(actions.setAgents({ loading: true }));

    return apiContracts.agentController
      .getAgents({ partnerId })
      .then(({ data }) => {
        dispatch(actions.setAgents({ data, loading: false }));
        return data;
      })
      .catch((error) => {
        dispatch(actions.setAgents({ data: [], loading: false }));
        return Promise.reject(undefined);
      });
  };

export const deleteAgent =
  (agentId: number): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiContracts.agentController
      .delete1(agentId)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.agent_was_deleted') });
        return undefined;
      })
      .catch((error) => {
        notification.error({ message: error.message });
        return Promise.reject(undefined);
      });
  };

const requestUsersData =
  (params: ListParams = {}): ThunkType<Promise<ResultListDtoUserActivityLogView>> =>
  async (dispatch, getState) => {
    const partner = getState().partnerCreateNext.data;
    const users = getState().partnerCreateNext.users;
    const sorting = getSortBy(users.sorting);
    const endData = partner.data?.deactivationDate ? dayjs(partner.data?.deactivationDate) : dayjs();

    return apiContracts.userController
      .list({
        count: true,
        partnerId: (partner.data?.id ?? 0).toString(),
        insuranceCompanyId: (partner.data?.insuranceCompanyId ?? 0)?.toString(),
        signupDate: getDateRange3(dayjs(partner.data?.createdDate), endData),
        sorting: sorting ? sorting : undefined,
        ...params,
      })
      .then((res) => res.data);
  };

export const getUsers = (): ThunkType<void> => async (dispatch, getState) => {
  const users = getState().partnerCreateNext.users;
  dispatch(actions.setUsers({ loading: true }));

  dispatch(requestUsersData({ ...getPageBy(users.pagination) }))
    .then((res) => {
      dispatch(actions.setUsers({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setUsers({ loading: false }));
    });
};

const requestUsersActivityData =
  (params: ListParams = {}): ThunkType<Promise<ResultListDtoAuthUserView>> =>
  async (dispatch, getState) => {
    const partner = getState().partnerCreateNext.data;
    const usersActivity = getState().partnerCreateNext.usersActivity;
    const sorting = getSortBy(usersActivity.sorting);
    const endData = partner.data?.deactivationDate ? dayjs(partner.data?.deactivationDate) : dayjs();

    return apiContracts.userController
      .listActivities({
        count: true,
        partnerId: (partner.data?.id ?? 0).toString(),
        activityDate: getDateRange3(dayjs(partner.data?.createdDate), endData),
        sorting: sorting ? sorting : undefined,
        ...params,
      })
      .then((res) => res.data);
  };

export const getUsersActivity = (): ThunkType<void> => async (dispatch, getState) => {
  const usersActivity = getState().partnerCreateNext.usersActivity;
  dispatch(actions.setUsersActivity({ loading: true }));

  dispatch(requestUsersActivityData({ ...getPageBy(usersActivity.pagination) }))
    .then((res) => {
      dispatch(actions.setUsersActivity({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setUsersActivity({ loading: false }));
    });
};

export const getPartnerDepartmentPrograms =
  (partnerId: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setInternetSales({ loading: true }));

    return apiConfiguration.internetSalesController
      .list2({ partnerId: String(partnerId) })
      .then(({ data }) => {
        dispatch(actions.setInternetSales({ loading: false, data }));
      })
      .catch(() => {
        dispatch(actions.setInternetSales({ data: null, loading: false }));
      });
  };

export const getInsProgramsForExternal =
  (partnerId: number): ThunkType<Promise<PartnerProgramDto[]>> =>
  (dispatch) => {
    dispatch(actions.setInsurancePrograms({ loading: true }));

    return apiConfiguration.partnerProgramController
      .findByPartnerId(partnerId)
      .then(({ data }) => {
        dispatch(actions.setInsurancePrograms({ loading: false, data }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: [] }));
        return Promise.reject(undefined);
      });
  };

export const getInsDepartmantsForExternal =
  (partnerId: number, insuranceProgramId?: number): ThunkType<Promise<OptionDto[]>> =>
  (dispatch) => {
    dispatch(actions.setInternetSalesDepartments({ loading: true }));
    return apiContracts.departmentController
      .listAvailableDepartmentsByPartner({ partnerId, insuranceProgramId })
      .then(({ data }) => {
        dispatch(actions.setInternetSalesDepartments({ loading: false, data }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setInternetSalesDepartments({ loading: false }));
        return Promise.reject(undefined);
      });
  };

export const createToken =
  (data: CreateUpdateInternetSalesDto): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiConfiguration.internetSalesController
      .create3(data)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.token_was_added') });
        return Promise.resolve(undefined);
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  };

export const editToken =
  (id: number, data: CreateUpdateInternetSalesDto): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiConfiguration.internetSalesController
      .update4(id, data)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.token_was_edited') });
        return undefined;
      })
      .catch((error) => {
        notification.error({ message: error.message });
        return Promise.reject(undefined);
      });
  };

export const deleteSalesToken =
  (id: number): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiConfiguration.internetSalesController
      .delete2(id)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.token_was_deactivated') });
        return undefined;
      })
      .catch((error) => {
        notification.error({ message: error.message });
        return Promise.reject(undefined);
      });
  };

const requestSubdivisions =
  (params: List5Params = {}): ThunkType<Promise<ResultListDtoAuthUserView>> =>
  async (dispatch, getState) => {
    const partner = getState().partnerCreateNext.data;
    const subdivisions = getState().partnerCreateNext.subdivisions;
    const sorting = getSortBy(subdivisions.sorting);

    return apiContracts.departmentController
      .list5({
        count: true,
        partnerId: (partner.data?.id ?? 0).toString(),
        sorting: sorting ? sorting : undefined,
        ...params,
      })
      .then((res) => res.data);
  };

export const getSubdivisions = (): ThunkType<void> => async (dispatch, getState) => {
  const subdivisions = getState().partnerCreateNext.subdivisions;
  dispatch(actions.setSubdivisions({ loading: true }));

  dispatch(requestSubdivisions({ ...getPageBy(subdivisions.pagination) }))
    .then((res) => {
      dispatch(actions.setSubdivisions({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setSubdivisions({ loading: false }));
    });
};

export const getInsuranceCompanies = (): ThunkType<void> => (dispatch) => {
  dispatch(actions.setInsuranceCompanies({ loading: true }));

  return apiContracts.insuranceCompanyController
    .list4({
      distinct: true,
      attributes: 'id,name',
      page_size: -1,
    })
    .then(({ data }) => {
      dispatch(actions.setInsuranceCompanies({ data: data.resultList, loading: false }));
    })
    .catch(() => {
      dispatch(actions.setInsuranceCompanies({ data: [], loading: false }));
    });
};
// TODO Agency portal backup
// export const getInsuranceCompanies =
//   (partnerId: string): ThunkType<void> =>
//   (dispatch) => {
//     dispatch(actions.setInsuranceCompanies({ loading: true }));

//     return apiContracts.insuranceCompanyController
//       .list4({
//         partnerId,
//       })
//       .then(({ data }) => {
//         dispatch(actions.setInsuranceCompanies({ data: data.resultList, loading: false }));
//       })
//       .catch(() => {
//         dispatch(actions.setInsuranceCompanies({ data: [], loading: false }));
//       });
//   };

// export const getParnerInsuranceCompanies =
//   (params?: GetInsuranceCompaniesParams): ThunkType<void> =>
//   (dispatch) => {
//     dispatch(actions.setPartnerInsuranceCompanies({ loading: true }));

//     return apiContracts.partnerController
//       .getInsuranceCompanies({
//         distinct: true,
//         attributes:
//           'id,insuranceCompanyId,insuranceCompanyName,enterpriseCode,email,directorFullName,postAddress,phoneNumber,createdBy,createdAt,deactivatedAt',
//         page_size: -1,
//         sorting: 'id',
//         ...params,
//       })
//       .then(({ data }) => {
//         dispatch(actions.setPartnerInsuranceCompanies({ data: data.resultList, loading: false }));
//         return undefined;
//       })
//       .catch((error) => {
//         dispatch(actions.setPartnerInsuranceCompanies({ data: [], loading: false }));
//         return Promise.reject(undefined);
//       });
//   };

// export const getAllInsuranceCompanies =
//   (params?: ListAllParams): ThunkType<void> =>
//   (dispatch) => {
//     dispatch(actions.setAllInsuranceCompanies({ loading: true }));

//     return apiContracts.insuranceCompanyController
//       .listAll({
//         distinct: true,
//         attributes: 'id,name,enterpriseCode',
//         page_size: -1,
//         sorting: 'id',
//         ...params,
//       })
//       .then(({ data }) => {
//         dispatch(actions.setAllInsuranceCompanies({ data: data.resultList, loading: false }));
//       })
//       .catch(() => {
//         dispatch(actions.setAllInsuranceCompanies({ data: [], loading: false }));
//       });
//   };

// export const addInsuranceCompany =
//   (partnerId: number, insuranceCompanyId: number): ThunkType<void> =>
//   (dispatch) => {
//     return apiContracts.partnerController
//       .addInsuranceCompany(partnerId, insuranceCompanyId)
//       .then(({ data }) => {
//         notification.success({ message: i18n.t('partner_creation.saved') });
//         return data;
//       })
//       .catch((error) => {
//         return Promise.reject(undefined);
//       });
//   };

export const getPartnerPrograms =
  (partnerId: number): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setPrograms({ loading: true }));

    return apiConfiguration.partnerProgramController
      .findByPartnerId(partnerId)
      .then(({ data }) => {
        dispatch(actions.setPrograms({ data, loading: false }));
      })
      .catch(() => {
        dispatch(actions.setPrograms({ data: [], loading: false }));
      });
  };

export const getGlobalProgramsConfiguration =
  (partnerId: number): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setGlobalProgramsConfiguration({ loading: true }));

    return apiConfiguration.insuranceProgramController
      .list4({ partnerId: partnerId.toString(), page_size: -1 })
      .then(({ data }) => {
        dispatch(actions.setGlobalProgramsConfiguration({ data: data.resultList, loading: false }));
      })
      .catch(() => {
        dispatch(actions.setGlobalProgramsConfiguration({ data: [], loading: false }));
      });
  };

export const getRoundingTypes =
  (programId: number, partnerId: number): ThunkType<Promise<RoundingTypePair[]>> =>
  (dispatch) => {
    return apiConfiguration.partnerProgramController
      .findRoundingTypes({
        insuranceProgramId: programId,
        id: partnerId,
      })
      .then(({ data }) => {
        dispatch(actions.setProgramModal({ roundingTypes: data }));
        return data;
      })
      .catch((error) => {
        return Promise.reject(undefined);
      });
  };

export const getContractDraftStoragePeriodTypes =
  (programId: number, partnerId: number): ThunkType<Promise<ContractDraftStoragePeriodTypePair[]>> =>
  (dispatch) => {
    return apiConfiguration.partnerProgramController
      .findContractDraftStoragePeriodTypes({
        insuranceProgramId: programId,
        id: partnerId,
      })
      .then(({ data }) => {
        dispatch(actions.setProgramModal({ contractDraftStoragePeriodTypes: data }));
        return data;
      })
      .catch((error) => {
        return Promise.reject(undefined);
      });
  };

export const getPartnerProgram =
  (partnerProgramId: number): ThunkType<Promise<ContractPartnerProgramDto>> =>
  (dispatch) => {
    return apiContracts.partnerProgramController
      .read3(partnerProgramId)
      .then(({ data }) => {
        dispatch(actions.setProgramModal({ partnerProgram: data }));
        return data;
      })
      .catch((error) => {
        return Promise.reject(undefined);
      });
  };

export const createPartnerProgram =
  (seed: ContractPartnerProgramDto): ThunkType<Promise<ContractPartnerProgramDto>> =>
  (dispatch) => {
    return apiContracts.partnerProgramController
      .save(seed)
      .then(({ data }) => {
        notification.success({ message: i18n.t('partner_creation.saved') });
        return data;
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          error?.response?.data?.errors.forEach((item) => {
            if (item.code === 'AUTOMATIC_NUMBER_GENERATION_IS_DISABLED_IN_THE_PARTNER_PROGRAM') {
              notification.error({
                message: i18n.t('partner_creation.need_activate_autogeneration_in_partner'),
              });
            }
            if (item.code === 'AUTOMATIC_NUMBER_GENERATION_IS_DISABLED_IN_THE_INSURANCE_PROGRAM') {
              notification.error({
                message: i18n.t('partner_creation.need_activate_autogeneration'),
              });
            }
          });
        }
        return Promise.reject(undefined);
      });
  };

export const updatePartnerProgram =
  (partnerProgramId: number, seed: ContractPartnerProgramDto): ThunkType<Promise<ContractPartnerProgramDto>> =>
  (dispatch) => {
    return apiContracts.partnerProgramController
      .update3(partnerProgramId, seed)
      .then(({ data }) => {
        notification.success({ message: i18n.t('partner_creation.saved') });
        return data;
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          error?.response?.data?.errors.forEach((item) => {
            if (item.code === 'AUTOMATIC_NUMBER_GENERATION_IS_DISABLED_IN_THE_PARTNER_PROGRAM') {
              notification.error({
                message: i18n.t('partner_creation.need_activate_autogeneration_in_partner'),
              });
            }
            if (item.code === 'AUTOMATIC_NUMBER_GENERATION_IS_DISABLED_IN_THE_INSURANCE_PROGRAM') {
              notification.error({
                message: i18n.t('partner_creation.need_activate_autogeneration'),
              });
            }
          });
        }
        return Promise.reject(undefined);
      });
  };

export const getAvaileblePartnerPrograms =
  (partnerId: number): ThunkType<Promise<InsuranceProgramDto[]>> =>
  (dispatch) => {
    return apiContracts.insuranceProgramController
      .listAvailableInsuranceProgramsForPartner({ partnerId })
      .then(({ data }) => {
        dispatch(actions.setProgramModal({ availablePartnerPrograms: data }));
        return data;
      })
      .catch((error) => {
        return Promise.reject(undefined);
      });
  };
export const getCurrentProgramConfig =
  (programId: number): ThunkType<Promise<ConfigurationInsuranceProgramDto>> =>
  (dispatch) => {
    return apiConfiguration.insuranceProgramController
      .getInsuranceProgram({ id: programId })
      .then(({ data }) => {
        dispatch(actions.setProgramModal({ currentProgramConfig: data }));
        return data;
      })
      .catch((error) => {
        return Promise.reject(undefined);
      });
  };

export const getPartnerDiscountUnits = (): ThunkType<void> => (dispatch) => {
  Promise.all([
    apiConfiguration.partnerDiscountController.getPartnerDiscountUnits({
      partnerDiscountType: PartnerDiscountType.CONTRACT_DISCOUNT,
    }),
    apiConfiguration.partnerDiscountController.getPartnerDiscountUnits({
      partnerDiscountType: PartnerDiscountType.PROGRAM_DISCOUNT,
    }),
  ]).then((res) => {
    const [contractDiscountUnit, programDiscountUnit] = res;

    dispatch(
      actions.setPartnerDiscounts({
        contractDiscountUnit: contractDiscountUnit.data,
        programDiscountUnit: programDiscountUnit.data,
      }),
    );
  });
};

export const getPartnerDiscounts =
  (partnerId: number): ThunkType<Promise<PartnerDiscountDto[]>> =>
  (dispatch) => {
    return apiConfiguration.partnerDiscountController
      .getPartnerDiscounts(partnerId)
      .then((res) => {
        dispatch(actions.setPartnerDiscounts({ data: res.data }));
        return res.data;
      })
      .catch(() => {
        dispatch(actions.setPartnerDiscounts({ data: [] }));
        return Promise.reject(undefined);
      });
  };

export const updatePartnerDiscounts =
  (partnerId: number, discounts: PartnerDiscountDto[]): ThunkType<void> =>
  (dispatch) => {
    return apiConfiguration.partnerDiscountController
      .updatePartnerDiscount(partnerId, discounts)
      .then((res) => {})
      .catch(() => {});
  };

export const getAgentContracts = (): ThunkType<void> => (dispatch) => {
  return apiContracts.agentContractController
    .availableAgentContracts({
      distinct: true,
      attributes: 'id,contractNumber,contractDate,insuranceCompanyId,partnerId,agentId',
    })
    .then((res) => {
      dispatch(actions.setPartnerDiscounts({ agentContracts: res.data.resultList }));
    })
    .catch(() => {
      dispatch(actions.setPartnerDiscounts({ agentContracts: [] }));
    });
};

//TODO front maybe this endpoint need for Partner Discounts
// export const getMaxCommision =
//   (obj: GetMaxAgentContractCommissionParams): ThunkResult =>
//   async (dispatch) => {
//     try {
//       const max = await apiConfiguration.partnerProgramController.getMaxAgentContractCommission(obj, {
//         // TODO front is it need for latest version of axios?
//         paramsSerializer: (params) => {
//           return queryString.stringify(params);
//         },
//       });
//       dispatch({
//         type: TYPES.setMax,
//         payload: {
//           data: max.data,
//         },
//       });
//       return max.data;
//     } catch (e) {
//       notification.error({
//         message: 'Fail update',
//       });
//     }
//   };

export const getPartnerOptions =
  (partnerId: number): ThunkType<Promise<PartnerProgramParametersDto>> =>
  (dispatch) => {
    dispatch(actions.setPartnerOptions({ loading: true }));

    return apiContracts.partnerController
      .getProgramParameters(partnerId)
      .then(({ data }) => {
        dispatch(actions.setPartnerOptions({ data, loading: false }));
        return data;
      })
      .catch((error) => {
        dispatch(actions.setPartnerOptions({ loading: false }));
        return Promise.reject(undefined);
      });
  };

export const updatePartnerOptions =
  (partnerId: number, data: PartnerProgramParametersDto): ThunkType<void> =>
  (dispatch) => {
    return apiContracts.partnerController
      .updatePartnerPrograms(partnerId, data)
      .then(() => {
        return Promise.resolve(undefined);
      })
      .catch((error) => {
        return Promise.reject(undefined);
      });
  };

export const getContractNumberFormat = (): ThunkType<Promise<PairContractNumberFormatString[]>> => (dispatch) => {
  return apiConfiguration.partnerProgramController
    .findContractFormatNumbers()
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(undefined);
    });
};
export interface StorePartnerCreate {
  data: Data;
  regions: Regions;
  agents: Agents;
  regionModal: RegionModal | null;
  companyModal: CompanyModal | null;
  agentModal: AgentModal | null;
  internetSalesModal: InternetSalesModal | null;
  insurancePrograms: InsurancePrograms;
  users: Users;
  usersActivity: UsersActivity;
  internetSales: InternetSales;
  internetSalesDepartments: InternetSalesDepartments;
  subdivisions: Subdivisions;
  insuranceCompanies: InsuranceCompanies;
  allInsuranceCompanies: InsuranceCompanies;
  partnerInsuranceCompanies: InsuranceCompaniesAll;
  programs: Programs;
  programModal: ProgramModal | null;
  globalProgramsConfiguration: GlobalProgramsConfiguration;
  partnerOptions: PartnerOptions;
  partnerDiscounts: PartnerDiscounts;
}

export const initialState: StorePartnerCreate = {
  data: {
    data: null,
    loading: false,
    saveLoading: false,
  },
  regions: { data: [], loading: false },
  agents: { data: [], loading: false },
  regionModal: null,
  companyModal: null,
  agentModal: null,
  internetSalesModal: null,
  insurancePrograms: {
    data: [],
    loading: false,
  },
  users: {
    data: null,
    loading: false,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'signupDate', order: 'descend' },
  },
  usersActivity: {
    data: null,
    loading: false,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'activityDate', order: 'descend' },
  },
  subdivisions: {
    data: null,
    loading: false,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'createdDate', order: 'descend' },
  },
  internetSales: {
    data: null,
    loading: false,
  },
  internetSalesDepartments: {
    data: [],
    loading: false,
  },
  insuranceCompanies: {
    data: [],
    loading: false,
  },
  allInsuranceCompanies: {
    data: [],
    loading: false,
  },
  partnerInsuranceCompanies: {
    data: [],
    loading: false,
  },
  programs: {
    data: [],
    loading: false,
  },
  globalProgramsConfiguration: {
    data: [],
    loading: false,
  },
  programModal: {
    isOpen: false,
    isCreate: false,
    loading: false,
    partnerProgramId: null,
    partnerProgram: null,
    roundingTypes: [],
    contractDraftStoragePeriodTypes: [],
    requiredContractVerify: false,
    availablePartnerPrograms: [],
    currentProgramConfig: null,
  },
  partnerOptions: {
    data: null,
    loading: false,
  },
  partnerDiscounts: {
    data: null,
    loading: false,
    agentContracts: [],
    contractDiscountUnit: [],
    programDiscountUnit: [],
  },
};

const reducer = (state = initialState, action: InferActionTypes) => {
  switch (action.type) {
    case 'PARTNER/RESET': {
      return initialState;
    }

    case 'PARTNER/SET_PARTNER': {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case 'PARTNER/SET_REGIONS':
      return {
        ...state,
        regions: {
          ...state.regions,
          ...action.payload,
        },
      };
    case 'PARTNER/SET_REGION_MODAL':
      return {
        ...state,
        regionModal: action.isFullReset
          ? null
          : {
              ...state.regionModal,
              ...action.payload,
            },
      };
    case 'PARTNER/SET_AGENTS':
      return {
        ...state,
        agents: {
          ...state.agents,
          ...action.payload,
        },
      };
    case 'PARTNER/SET_COMPANY_MODAL':
      return {
        ...state,
        companyModal: action.isFullReset
          ? null
          : {
              ...state.companyModal,
              ...action.payload,
            },
      };
    case 'PARTNER/SET_AGENT_MODAL':
      return {
        ...state,
        agentModal: action.isFullReset
          ? null
          : {
              ...state.agentModal,
              ...action.payload,
            },
      };

    case 'PARTNER/SET_USERS':
      return {
        ...state,
        users: {
          ...state.users,
          ...action.payload,
        },
      };

    case 'PARTNER/SET_USERS_ACTIVITY': {
      return {
        ...state,
        usersActivity: {
          ...state.usersActivity,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_INTERNET_SALES': {
      return {
        ...state,
        internetSales: {
          ...state.internetSales,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_INTERNET_SALES_DEPARTMENTS': {
      return {
        ...state,
        internetSalesDepartments: {
          ...state.internetSalesDepartments,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_INTERNET_SALES_MODAL': {
      return {
        ...state,
        internetSalesModal: action.isFullReset
          ? null
          : {
              ...state.internetSalesModal,
              ...action.payload,
            },
      };
    }

    case 'PARTNER/SET_INSURANCE_PROGRAMS': {
      return {
        ...state,
        insurancePrograms: {
          ...state.insurancePrograms,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_SUBDIVISIONS': {
      return {
        ...state,
        subdivisions: {
          ...state.subdivisions,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_INSURANCE_COMPANIES': {
      return {
        ...state,
        insuranceCompanies: {
          ...state.insuranceCompanies,
          ...action.payload,
        },
      };
    }
    case 'PARTNER/SET_ALL_INSURANCE_COMPANIES': {
      return {
        ...state,
        allInsuranceCompanies: {
          ...state.allInsuranceCompanies,
          ...action.payload,
        },
      };
    }
    case 'PARTNER/SET_PARTNER_INSURANCE_COMPANIES': {
      return {
        ...state,
        partnerInsuranceCompanies: {
          ...state.partnerInsuranceCompanies,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_PROGRAMS': {
      return {
        ...state,
        programs: {
          ...state.programs,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_GLOBAL_PROGRAM_CONFIGURATION': {
      return {
        ...state,
        globalProgramsConfiguration: {
          ...state.globalProgramsConfiguration,
          ...action.payload,
        },
      };
    }

    case 'PARTNER/SET_PROGRAM_MODAL': {
      return {
        ...state,
        programModal: action.isFullReset
          ? null
          : {
              ...state.programModal,
              ...action.payload,
            },
      };
    }

    case 'PARTNER/SET_PARTNER_OPTIONS': {
      return {
        ...state,
        partnerOptions: {
          ...state.partnerOptions,
          ...action.payload,
        },
      };
    }
    case 'PARTNER/SET_PARTNER_DISCOUNT': {
      return {
        ...state,
        partnerDiscounts: {
          ...state.partnerDiscounts,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
