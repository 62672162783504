import i18n from 'translations/i18n';
import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { InsuranceObjectResponseDto } from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface InsuranceObjects extends List {
  data: InsuranceObjectResponseDto | null;
  loading: boolean;
}

export const actions = {
  setInsuranceObjects: (payload: Partial<InsuranceObjects>) =>
    ({ type: 'CLIENT_DASHBOARD/SET_INSURANCE_OBJECTS', payload } as const),
  setInsuranceView: (payload: 'rows' | 'tile') => ({ type: 'CLIENT_DASHBOARD/SET_INSURANCE_VIEW', payload } as const),
};

export const getInsuranceObjects = (): ThunkType<void> => (dispatch, getState) => {
  const insuranceObjects = getState().clientDashboard.insuranceObjects;
  dispatch(actions.setInsuranceObjects({ loading: true, data: null }));

  return apiContracts.insuranceObjectController
    .findInsuranceObjectResponseDto({
      current: 'true',
      page: insuranceObjects.pagination?.page,
      page_size: insuranceObjects.pagination?.pageSize,
    })
    .then(({ data }) => {
      dispatch(actions.setInsuranceObjects({ loading: false, data }));
    })
    .catch(() => {
      dispatch(actions.setInsuranceObjects({ loading: false, data: null }));
    });
};

export interface StoreClientDashboard {
  insuranceObjects: InsuranceObjects;
  insuranceView: 'rows' | 'tile';
}

export const initialState: StoreClientDashboard = {
  insuranceObjects: {
    data: null,
    loading: false,
    pagination: {
      page: 1,
      pageSize: 6,
    },
  },
  insuranceView: 'tile',
};

const reducer = (state = initialState, action: InferActionTypes) => {
  switch (action.type) {
    case 'CLIENT_DASHBOARD/SET_INSURANCE_OBJECTS': {
      return {
        ...state,
        insuranceObjects: {
          ...state.insuranceObjects,
          ...action.payload,
        },
      };
    }

    case 'CLIENT_DASHBOARD/SET_INSURANCE_VIEW': {
      return {
        ...state,
        insuranceView: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
