import i18n from 'translations/i18n';
import { object, number, string, Infer, refiner, optional } from 'utils/struct';

export const schema = object({
  insuranceCompanyId: optional(number()),
  paymentSystem: refiner(optional(string()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  merchant: optional(string()),
  paymentSystemBalance: optional(string()),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  insuranceCompanyId: 0,
  paymentSystem: '',
  merchant: '',
  paymentSystemBalance: '',
};
