import { createTypes } from 'redux-compose-reducer';

export const TYPES = createTypes('insuranceProgramsList', [
  'setTablePage',
  'setPagination',
  'setSorting',
  'getPrivateFiltersValues',
  'getPrivateFiltersData',
  'setFilters',
  'setAction',
]);

export const getAction = (actionType: string) => async (dispatch: any) => {
  dispatch({
    type: TYPES.setAction,
    payload: {
      action: actionType,
    },
  });
};
