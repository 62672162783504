import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification, Row, Col } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { InputField } from 'components/next/InputField/InputField';
import Button from 'components/next/Button/Button';
import { useRequired } from 'hooks/useRequired';
import { checkOnlineStatus } from 'utils/helpers';
import { apiContracts } from 'api/contracts';

import { State } from '../PasswordRecovery';
import { schema, defaultValues, Values } from './Reset.schema';

const cssSubmit = css`
  width: 150px;
`;

type Props = {
  setState: React.Dispatch<Partial<State>>;
};

const Reset = ({ setState }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { requiredFields } = useRequired(schema, watch());

  const onSubmit = (values: Values) => {
    setLoading(true);

    apiContracts.userController
      .sendPasswordResetSms({ login: values.login })
      .then(() => {
        setState({ step: 'verification', login: values.login });
      })
      .catch(() => {
        reset();

        notification.error({
          message: checkOnlineStatus(t('passwordRecovery.account_not_found')),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test="form">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Controller
            name="login"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <InputField
                name={name}
                errors={errors}
                requiredFields={requiredFields}
                value={value}
                onChange={(v) => onChange(v)}
                label={t('passwordRecovery.your_login')}
                colorBase="grey"
              />
            )}
          />
        </Col>

        <Col span={24}>
          <Button type="primary" size="large" htmlType="submit" loading={loading} className={cssSubmit}>
            {t('passwordRecovery.continue')}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default Reset;
