import { ThunkAction } from 'redux-thunk';
import { notification } from 'antd';
import { t } from 'i18next';
import { mergePartially } from 'merge-partially';
import { DeepPartial } from 'utility-types';

import { AppState } from 'store/reducer';
import { ActionTypes, ErrorsDto } from 'types';
import {
  DepartmentView,
  InsuranceCompanyView,
  List2Params,
  List5Params,
  PartnerView,
  UserDto,
  UserInsuranceProgramDto,
  UserRole,
} from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import { apiConfiguration } from 'api/configuration';
import { InsuranceProgramView, List4Params } from 'types/dto/configuration-service';
import history from 'routes/history';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'constants/routes';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data {
  loading: boolean;
  data: UserDto | null;
  saving: boolean;
}
interface InsuranceCompanies {
  loading?: boolean;
  data?: InsuranceCompanyView[];
}
interface Partners {
  loading?: boolean;
  data?: PartnerView[];
}
interface PartnerDepartmentRegions {
  loading?: boolean;
  data?: DepartmentView[];
}
interface PartnerDepartments {
  loading?: boolean;
  data?: DepartmentView[];
}
interface Roles {
  loading?: boolean;
  data?: UserRole[];
}
interface InsurancePrograms {
  loading?: boolean;
  data?: InsuranceProgramView[];
}
interface SetDataOptions {
  fullMerge?: boolean;
}

export const actions = {
  reset: () => ({ type: 'user_main/RESET' } as const),
  setData: (payload: DeepPartial<Data>, options?: SetDataOptions) =>
    ({ type: 'user_main/SET_DATA', payload, options } as const),
  setInsuranceCompanies: (payload: InsuranceCompanies) =>
    ({ type: 'user_main/SET_INSURANCE_COMPANIES', payload } as const),
  setPartners: (payload: Partners) => ({ type: 'user_main/SET_PARTNERS', payload } as const),
  setPartnerDepartmentRegions: (payload: PartnerDepartmentRegions) =>
    ({ type: 'user_main/SET_PARTNER_DEPARTMENT_REGIONS', payload } as const),
  setPartnerDepartments: (payload: PartnerDepartments) =>
    ({ type: 'user_main/SET_PARTNER_DEPARTMENTS', payload } as const),
  setRoles: (payload: Roles) => ({ type: 'user_main/SET_ROLES', payload } as const),
  setInsurancePrograms: (payload: InsurancePrograms) =>
    ({ type: 'user_main/SET_INSURANCE_PROGRAMS', payload } as const),
};

export const getUser =
  (id: number): ThunkType<void> =>
  (dispatch, getState) => {
    dispatch(actions.setData({ loading: true, data: null }));

    apiContracts.userController
      .read(id)
      .then((res) => {
        dispatch(actions.setData({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setData({ loading: false, data: null }));

        if (error === undefined) {
          return Promise.reject();
        }

        notification.error({ message: error.message });

        return Promise.reject();
      });
  };

export const createUser =
  (data: UserDto): ThunkType<void> =>
  (dispatch, getState) => {
    dispatch(actions.setData({ saving: true }));

    apiContracts.userController
      .create(data)
      .then((res) => {
        notification.success({ message: t('popup.created') });

        dispatch(actions.setData({ saving: false, data: res.data }, { fullMerge: true }));

        history.push(generatePath(ROUTES.USERS.DETAILS, { id: res.data.id }));
      })
      .catch((error) => {
        dispatch(actions.setData({ saving: false }));

        if (error === undefined) {
          return Promise.reject();
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            if (err.code === 'PHONE_NUMBER_ALREADY_REGISTERED') {
              notification.error({ message: t('validation.PHONE_NUMBER_ALREADY_REGISTERED') });

              return;
            }

            if (err.code === 'EMAIL_ALREADY_REGISTERED') {
              notification.error({ message: t('validation.EMAIL_ALREADY_REGISTERED') });

              return;
            }

            notification.error({ message: err.code });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject();
      });
  };

export const updateUser =
  (id: number, data: UserDto): ThunkType<Promise<UserDto>> =>
  (dispatch, getState) => {
    dispatch(actions.setData({ saving: true }));

    return apiContracts.userController
      .update(id, data)
      .then((res) => {
        notification.success({ message: t('popup.updated') });

        dispatch(actions.setData({ saving: false, data: res.data }, { fullMerge: true }));

        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setData({ saving: false }));

        if (error === undefined) {
          return Promise.reject();
        }

        notification.error({ message: error.message });

        return Promise.reject();
      });
  };

export const getRoles = (): ThunkType<Promise<UserRole[]>> => (dispatch, getState) => {
  dispatch(actions.setRoles({ loading: true, data: [] }));

  // TODO back query optional
  return apiContracts.userController
    .listRoles({ user: {} })
    .then((res) => {
      dispatch(actions.setRoles({ loading: false, data: res.data }));

      return res.data;
    })
    .catch((error) => {
      dispatch(actions.setRoles({ loading: false, data: [] }));

      if (error === undefined) {
        return Promise.reject();
      }

      notification.error({ message: error.message });

      return Promise.reject();
    });
};

export const getInsuranceCompanies = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setInsuranceCompanies({ loading: true, data: [] }));

  apiContracts.insuranceCompanyController
    .list4({
      distinct: true,
      attributes: 'id,name',
      page_size: -1,
    })
    .then((res) => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch(() => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: [] }));
    });
};

export const getPartners =
  (query: List2Params): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartners({ loading: true, data: [] }));

    apiContracts.partnerController
      .list2({ distinct: true, page_size: -1, ...query })
      .then((res) => {
        dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPartners({ loading: false, data: [] }));
      });
  };

export const getPartnerDepartmentRegions =
  (query: List5Params): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartnerDepartmentRegions({ loading: true, data: [] }));

    apiContracts.departmentController
      .list5({ distinct: true, attributes: 'regionId,region', active: String(true), ...query })
      .then((res) => {
        dispatch(actions.setPartnerDepartmentRegions({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPartnerDepartmentRegions({ loading: false, data: [] }));
      });
  };

export const getPartnerDepartments =
  (query: List5Params): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartnerDepartments({ loading: true, data: [] }));

    apiContracts.departmentController
      .list5({
        distinct: true,
        attributes: 'name,id',
        active: String(true),
        ...query,
      })
      .then((res) => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: [] }));
      });
  };

export const getInsurancePrograms =
  (query: List4Params): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setInsurancePrograms({ loading: true, data: [] }));

    apiConfiguration.insuranceProgramController
      .list4({
        attributes: 'id,name',
        distinct: true,
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: res.data.resultList ?? [] }));

        const insurancePrograms: UserInsuranceProgramDto[] = (res.data.resultList ?? []).map((el) => ({
          insuranceProgram: { id: el.id, name: el.name },
        }));

        dispatch(actions.setData({ data: { insurancePrograms } }));
      })
      .catch(() => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: [] }));
      });
  };

export interface StoreUserMain {
  data: Data;
  insuranceCompanies: InsuranceCompanies;
  partners: Partners;
  partnerDepartmentRegions: PartnerDepartmentRegions;
  partnerDepartments: PartnerDepartments;
  roles: Roles;
  insurancePrograms: InsurancePrograms;
}
const initialState: StoreUserMain = {
  data: {
    loading: false,
    data: null,
    saving: false,
  },
  insuranceCompanies: { loading: false, data: [] },
  partners: { loading: false, data: [] },
  partnerDepartmentRegions: { loading: false, data: [] },
  partnerDepartments: { loading: false, data: [] },
  roles: { loading: false, data: [] },
  insurancePrograms: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreUserMain => {
  switch (action.type) {
    case 'user_main/RESET':
      return initialState;
    case 'user_main/SET_DATA':
      return {
        ...state,
        data: action.options?.fullMerge
          ? { ...state.data, ...(action.payload as Data) }
          : mergePartially.shallow(state.data, action.payload as Data),
      };
    case 'user_main/SET_INSURANCE_COMPANIES':
      return { ...state, insuranceCompanies: { ...state.insuranceCompanies, ...action.payload } };
    case 'user_main/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'user_main/SET_PARTNER_DEPARTMENT_REGIONS':
      return { ...state, partnerDepartmentRegions: { ...state.partnerDepartmentRegions, ...action.payload } };
    case 'user_main/SET_PARTNER_DEPARTMENTS':
      return { ...state, partnerDepartments: { ...state.partnerDepartments, ...action.payload } };
    case 'user_main/SET_ROLES':
      return { ...state, roles: { ...state.roles, ...action.payload } };
    case 'user_main/SET_INSURANCE_PROGRAMS':
      return { ...state, insurancePrograms: { ...state.insurancePrograms, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
