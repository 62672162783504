/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AccountTypeCode {
  CARD = 'CARD',
  IBAN = 'IBAN',
  CASH_DESK = 'CASH_DESK',
}

export enum TerminationInitiatorCode {
  INSURER = 'INSURER',
  POLICY_HOLDER = 'POLICY_HOLDER',
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  INSURANCE_COMPANY_MAINTENANCE = 'INSURANCE_COMPANY_MAINTENANCE',
  INSURANCE_COMPANY_NO_MONEY_BACK = 'INSURANCE_COMPANY_NO_MONEY_BACK',
}

export interface TerminationInitiatorConfigurationDto {
  /** @format int64 */
  terminationInitiatorId?: number;
  name: TerminationInitiatorCode;
  accountTypes: AccountTypeCode[];
  terminationCausesIds: number[];
}

export enum DocumentTypeCode {
  PASSPORT = 'PASSPORT',
  PASSPORTPAGE2 = 'PASSPORT_PAGE_2',
  PASSPORTPAGE3 = 'PASSPORT_PAGE_3',
  PASSPORT_RESIDENCE_PLACE = 'PASSPORT_RESIDENCE_PLACE',
  IDENTIFICATION_NUMBER = 'IDENTIFICATION_NUMBER',
  AUTHORITIES_INFORMATION = 'AUTHORITIES_INFORMATION',
  SERVICE_CENTER_CONCLUSION = 'SERVICE_CENTER_CONCLUSION',
  INVOICE = 'INVOICE',
  AGENT_ACT = 'AGENT_ACT',
  FISCAL_CHECK = 'FISCAL_CHECK',
  EQUIPMENT_WARRANTY_CARD = 'EQUIPMENT_WARRANTY_CARD',
  DAMAGED_EQUIPMENT_PHOTO = 'DAMAGED_EQUIPMENT_PHOTO',
  RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS = 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS',
  EXTRACT_FROM_UNIFIED_REGISTER_PRE_TRIAL_INVESTIGATIONS = 'EXTRACT_FROM_UNIFIED_REGISTER_PRE_TRIAL_INVESTIGATIONS',
  RESOLUTION_ON_CLOSING_CRIMINAL_PROCEEDINGS_WITH_INDICATION_GUILTY_PERSONSSIGNS_ILLEGAL_ACTIONS = 'RESOLUTION_ON_CLOSING_CRIMINAL_PROCEEDINGS_WITH_INDICATION_GUILTY_PERSONSSIGNS_ILLEGAL_ACTIONS',
  COURT_DECISION_TO_ESTABLISH_GUILT_OF_PERSON_WHO_CAUSED_DAMAGE = 'COURT_DECISION_TO_ESTABLISH_GUILT_OF_PERSON_WHO_CAUSED_DAMAGE',
  DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID = 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID',
  AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION = 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION',
  FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT = 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT',
  DOCUMENTS_WITH_IMEI = 'DOCUMENTS_WITH_IMEI',
  INSURANCE_FISCAL_CHECK = 'INSURANCE_FISCAL_CHECK',
  PACKAGING = 'PACKAGING',
  DAMAGED_EQUIPMENT = 'DAMAGED_EQUIPMENT',
  SERVICE_CENTER_ACCOUNT = 'SERVICE_CENTER_ACCOUNT',
  CHARGER_AND_OTHER_COMPONENTS = 'CHARGER_AND_OTHER_COMPONENTS',
  OTHER_DOCUMENTS = 'OTHER_DOCUMENTS',
  FISCAL_CHECK_OTHER_RISKS = 'FISCAL_CHECK_OTHER_RISKS',
  INSURANCE_FISCAL_CHECK_OTHER_RISKS = 'INSURANCE_FISCAL_CHECK_OTHER_RISKS',
  PASSPORT_OTHER_RISKS = 'PASSPORT_OTHER_RISKS',
  PASSPORTPAGE2OTHERRISKS = 'PASSPORT_PAGE_2_OTHER_RISKS',
  PASSPORT_RESIDENCE_PLACE_OTHER_RISKS = 'PASSPORT_RESIDENCE_PLACE_OTHER_RISKS',
  IDENTIFICATION_NUMBER_OTHER_RISKS = 'IDENTIFICATION_NUMBER_OTHER_RISKS',
  DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGED_EQUIPMENT_OTHER_RISKS = 'DAMAGED_EQUIPMENT_OTHER_RISKS',
  EQUIPMENT_WARRANTY_CARD_OTHER_RISKS = 'EQUIPMENT_WARRANTY_CARD_OTHER_RISKS',
  CHARGER_AND_OTHER_COMPONENTS_OTHER_RISKS = 'CHARGER_AND_OTHER_COMPONENTS_OTHER_RISKS',
  PACKAGING_OTHER_RISKS = 'PACKAGING_OTHER_RISKS',
  AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION_OTHER_RISKS = 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION_OTHER_RISKS',
  INVOICE_OTHER_RISKS = 'INVOICE_OTHER_RISKS',
  AGENT_ACT_OTHER_RISKS = 'AGENT_ACT_OTHER_RISKS',
  REPAIRED_DEVICE_PHOTO = 'REPAIRED_DEVICE_PHOTO',
  REPAIRED_DEVICE_PHOTO_OTHER_RISKS = 'REPAIRED_DEVICE_PHOTO_OTHER_RISKS',
  FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT_OTHER_RISKS = 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT_OTHER_RISKS',
  RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS_OTHER_RISKS = 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS_OTHER_RISKS',
  AUTHORITIES_INFORMATION_OTHER_RISKS = 'AUTHORITIES_INFORMATION_OTHER_RISKS',
  OTHER_DOCUMENTS_OTHER_RISKS = 'OTHER_DOCUMENTS_OTHER_RISKS',
  TERMINATION_FISCAL_CHECK = 'TERMINATION_FISCAL_CHECK',
  DAMAGEDEQUIPMENTPHOTO2WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_2_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO3WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_3_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO4WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_4_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO5WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_5_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO6WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_6_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  COVID_TEST = 'COVID_TEST',
  HOSPITAL_STAY_CERTIFICATE = 'HOSPITAL_STAY_CERTIFICATE',
  DOCTORS_APPOINTMENT = 'DOCTORS_APPOINTMENT',
  EXPENSES_DOCUMENTS = 'EXPENSES_DOCUMENTS',
  PASSPORT_OR_BIRTH_CERTIFICATE = 'PASSPORT_OR_BIRTH_CERTIFICATE',
  PAYMENT_RECIPIENTS_PASSPORT = 'PAYMENT_RECIPIENTS_PASSPORT',
  PAYMENT_RECIPIENTS_CODE = 'PAYMENT_RECIPIENTS_CODE',
  MEDICAL_PRACTICE_LICENSE_COPY = 'MEDICAL_PRACTICE_LICENSE_COPY',
  DEATH_CERTIFICATE = 'DEATH_CERTIFICATE',
  CAUSE_OF_DEATH_CERTIFICATE = 'CAUSE_OF_DEATH_CERTIFICATE',
  INHERITANCE_DOCUMENT = 'INHERITANCE_DOCUMENT',
  CONTRACT_CLAIM_REPORT = 'CONTRACT_CLAIM_REPORT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  PASSPORT_INSURANCE = 'PASSPORT_INSURANCE',
  PASSPORTINSURANCEPAGE2 = 'PASSPORT_INSURANCE_PAGE_2',
  PASSPORTINSURANCEPAGE3 = 'PASSPORT_INSURANCE_PAGE_3',
  IDENTIFICATION_NUMBER_INSURANCE = 'IDENTIFICATION_NUMBER_INSURANCE',
  PASSPORT_DRIVER = 'PASSPORT_DRIVER',
  PASSPORTDRIVERPAGE2 = 'PASSPORT_DRIVER_PAGE_2',
  PASSPORTDRIVERPAGE3 = 'PASSPORT_DRIVER_PAGE_3',
  IDENTIFICATION_NUMBER_DRIVER = 'IDENTIFICATION_NUMBER_DRIVER',
  VEHICLE_REGISTRATION_CERTIFICATE = 'VEHICLE_REGISTRATION_CERTIFICATE',
  AUTHORITY_RELEVANT_CERTIFICATE = 'AUTHORITY_RELEVANT_CERTIFICATE',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  CALCULATION_SERVICE_STATION = 'CALCULATION_SERVICE_STATION',
  OWNERSHIP_DOCUMENT = 'OWNERSHIP_DOCUMENT',
  SERVICE_DEPARTMENT_DOCUMENTS = 'SERVICE_DEPARTMENT_DOCUMENTS',
  INVESTIGATION_DOCUMENT = 'INVESTIGATION_DOCUMENT',
  LIST_OF_DAMAGED_PROPERTY = 'LIST_OF_DAMAGED_PROPERTY',
  AMOUNT_OF_DAMAGES_CONFIRMATION_DOCUMENT = 'AMOUNT_OF_DAMAGES_CONFIRMATION_DOCUMENT',
  INSURANCE_CONTRACT = 'INSURANCE_CONTRACT',
  INSURED_VEHICLE_REGISTRATION_CERTIFICATE = 'INSURED_VEHICLE_REGISTRATION_CERTIFICATE',
  INSURED_DRIVER_LICENSE = 'INSURED_DRIVER_LICENSE',
  INSURED_PASSPORT_DRIVER = 'INSURED_PASSPORT_DRIVER',
  INSURED_IDENTIFICATION_NUMBER_DRIVER = 'INSURED_IDENTIFICATION_NUMBER_DRIVER',
  RECRUITMENT_ORDER = 'RECRUITMENT_ORDER',
  CASUALTY_VEHICLE_REGISTRATION_CERTIFICATE = 'CASUALTY_VEHICLE_REGISTRATION_CERTIFICATE',
  CASUALTY_DRIVER_LICENSE = 'CASUALTY_DRIVER_LICENSE',
  CASUALTY_PASSPORT_DRIVER = 'CASUALTY_PASSPORT_DRIVER',
  CASUALTY_IDENTIFICATION_NUMBER_DRIVER = 'CASUALTY_IDENTIFICATION_NUMBER_DRIVER',
  REFERENCE_FROM_THE_COMPETENT_AUTHORITIES_OF_THE_MINISTRY_OF_INTERNAL_AFFAIRS_MINISTRY_OF_EMERGENCIES_EUROPEAN_PROTOCOL_ETC = 'REFERENCE_FROM_THE_COMPETENT_AUTHORITIES_OF_THE_MINISTRY_OF_INTERNAL_AFFAIRS_MINISTRY_OF_EMERGENCIES_EUROPEAN_PROTOCOL_ETC',
  DECISION_OF_THE_ADMINISTRATIVE_COURT = 'DECISION_OF_THE_ADMINISTRATIVE_COURT',
  DOCUMENT_FROM_THE_SERVICE_STATION_EXAMINATION_CALCULATION_OF_THE_INSURER = 'DOCUMENT_FROM_THE_SERVICE_STATION_EXAMINATION_CALCULATION_OF_THE_INSURER',
  CERTIFICATE_OF_TEMPORARY_INCAPACITY_FOR_WORK_SICK_LEAVES_MEDICAL_REPORTS_AND_BILLS = 'CERTIFICATE_OF_TEMPORARY_INCAPACITY_FOR_WORK_SICK_LEAVES_MEDICAL_REPORTS_AND_BILLS',
  MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP = 'MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP',
  ORIGINAL_INHERITANCE_DOCUMENT = 'ORIGINAL_INHERITANCE_DOCUMENT',
  APPLICATION_FOR_PAYMENT_OF_THE_INSURED_CASUALTY = 'APPLICATION_FOR_PAYMENT_OF_THE_INSURED_CASUALTY',
  BENEFICIARY_LETTER = 'BENEFICIARY_LETTER',
  ACT_OF_INSPECTION = 'ACT_OF_INSPECTION',
  PHOTO_OF_A_DAMAGED_CAR = 'PHOTO_OF_A_DAMAGED_CAR',
  RESOLUTION_OF_THE_ADMINISTRATIVE_COURT_OR_RESOLUTION_ON_THE_CLOSURE_OF_CRIMINAL_PROCEEDINGS = 'RESOLUTION_OF_THE_ADMINISTRATIVE_COURT_OR_RESOLUTION_ON_THE_CLOSURE_OF_CRIMINAL_PROCEEDINGS',
  SALE_REGISTER_ANALOGUE_PHOTO = 'SALE_REGISTER_ANALOGUE_PHOTO',
  SALEREGISTERPHOTO1 = 'SALE_REGISTER_PHOTO_1',
  SALEREGISTERPHOTO2 = 'SALE_REGISTER_PHOTO_2',
  SALEREGISTERPHOTO3 = 'SALE_REGISTER_PHOTO_3',
  SALEREGISTERPHOTO4 = 'SALE_REGISTER_PHOTO_4',
  SALEREGISTERPHOTO5 = 'SALE_REGISTER_PHOTO_5',
  INSURANCE_COMPANY_LOGO = 'INSURANCE_COMPANY_LOGO',
  SERVICE_CENTER_EXPERTISE = 'SERVICE_CENTER_EXPERTISE',
  COPY_CONTRACT = 'COPY_CONTRACT',
  ORGANIZATION_ACCOUNT = 'ORGANIZATION_ACCOUNT',
  COPY_DOCUMENT_ACCIDENT_FACT = 'COPY_DOCUMENT_ACCIDENT_FACT',
  DISABLED_CHILD = 'DISABLED_CHILD',
  EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT = 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT',
  PASSPORT_DMS_INSURANCE_PROGRAM = 'PASSPORT_DMS_INSURANCE_PROGRAM',
  PASSPORT_OF_PAYER_OR_OTHER_DOCUMENT = 'PASSPORT_OF_PAYER_OR_OTHER_DOCUMENT',
  REFERRALS_FOR_CONSULTATIONS = 'REFERRALS_FOR_CONSULTATIONS',
  COPIES_OF_SURVEY_OR_RESEARCH_RESULTS = 'COPIES_OF_SURVEY_OR_RESEARCH_RESULTS',
  PRESCRIPTIONS_OR_OTHER_MEDICAL_DOCUMENTS_INDICATING_THE_NAME_OF_THE_INSURED_PERSON = 'PRESCRIPTIONS_OR_OTHER_MEDICAL_DOCUMENTS_INDICATING_THE_NAME_OF_THE_INSURED_PERSON',
  EXTRACT_FROM_OUTPATIENT_CARD = 'EXTRACT_FROM_OUTPATIENT_CARD',
  ORIGINAL_PAYMENT_DOCUMENT = 'ORIGINAL_PAYMENT_DOCUMENT',
  ACT_OF_PERFORMED_WORKS_RENDERED_SERVICE_THAT_PROVIDED_SERVICE = 'ACT_OF_PERFORMED_WORKS_RENDERED_SERVICE_THAT_PROVIDED_SERVICE',
  LICENSE_FOR_THE_IMPLEMENTATION_OF_RELEVANT_TYPE_OF_MEDICAL_PRACTICE_EXCEPT_PUBLIC_MEDICAL_INSTITUTIONS = 'LICENSE_FOR_THE_IMPLEMENTATION_OF_RELEVANT_TYPE_OF_MEDICAL_PRACTICE_EXCEPT_PUBLIC_MEDICAL_INSTITUTIONS',
  PASSPORT_RUS = 'PASSPORT_RUS',
  COPY_MEDICAL_CERTIFICATE_RUS = 'COPY_MEDICAL_CERTIFICATE_RUS',
  ORGANIZATION_ACCOUNT_RUS = 'ORGANIZATION_ACCOUNT_RUS',
  INHERITANCE_DOCUMENT_RUS = 'INHERITANCE_DOCUMENT_RUS',
  EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT_RUS = 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT_RUS',
  COPY_DOCUMENT_ACCIDENT_FACT_RUS = 'COPY_DOCUMENT_ACCIDENT_FACT_RUS',
  COPY_MEDICAL_CERTIFICATE_OF_MEDICAL_SOCIAL_COMMISSION_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS = 'COPY_MEDICAL_CERTIFICATE_OF_MEDICAL_SOCIAL_COMMISSION_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS',
  COPY_MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS = 'COPY_MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS',
  OTHER_DOCUMENTS_RUS = 'OTHER_DOCUMENTS_RUS',
  CHECK_PURCHASE_MEDICINES = 'CHECK_PURCHASE_MEDICINES',
  ORIGINALS_RECIPES = 'ORIGINALS_RECIPES',
  PHOTO_DEVICE = 'PHOTO_DEVICE',
}

export interface DocumentTypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: DocumentTypeCode;
}

export interface TerminationDocumentConfigurationDto {
  /** @format int64 */
  id?: number;
  documentType?: DocumentTypeDto;
  visible?: boolean;
  obligatory?: boolean;
  informationObligatory?: boolean;
  /** @format int64 */
  documentTypeId: number;
  initiator?: TerminationInitiatorCode;
  calculable?: boolean;
  sharableFromDia?: boolean;
}

export interface ConfigurationInsuranceTypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  licenseSeries?: string;
  /** @format int64 */
  licenseNumber?: number;
  /** @format date */
  licenseIssueDate?: string;
  legislativeDocument?: string;
  /** @format int64 */
  insuranceRuleId?: number;
  insuranceRuleName?: string;
  obligatory?: boolean;
}

export interface ConfigurationRiskDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format date */
  creationDate?: string;
  insuranceRule?: OptionDto;
  insuranceType?: ConfigurationInsuranceTypeDto;
  /** @format int64 */
  insuranceTypeId?: number;
  isObligatory?: boolean;
  resolutionTypes?: RiskResolutionTypeDto[];
}

export interface OptionDto {
  /** @format int64 */
  id?: number;
  name?: string;
}

export enum ResolutionType {
  EXPRESS_PAYMENT = 'EXPRESS_PAYMENT',
  SHOP_SERVICE_REPAIR = 'SHOP_SERVICE_REPAIR',
  SELF_SERVICE_REPAIR = 'SELF_SERVICE_REPAIR',
  PAYMENT = 'PAYMENT',
  SERVICE_STATION_PAYMENT = 'SERVICE_STATION_PAYMENT',
  MEDICAL_ASSISTANCE = 'MEDICAL_ASSISTANCE',
  MEDICAL_INSTITUTION = 'MEDICAL_INSTITUTION',
  INSURED = 'INSURED',
}

export interface RiskResolutionTypeDto {
  /** @format int64 */
  id?: number;
  type?: ResolutionType;
  name?: string;
  active?: boolean;
  /** @format date-time */
  createdDate?: string;
}

export enum DiscountMeasurementType {
  ABSOLUTE_VALUE = 'ABSOLUTE_VALUE',
  PERCENT = 'PERCENT',
}

export enum DiscountStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
}

export enum InitiatorType {
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  PARTNER_BY_COMMISSION = 'PARTNER_BY_COMMISSION',
}

export interface PromoCodeDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceCompanyId: number;
  insuranceCompanyName: string;
  /** @format int64 */
  partnerId?: number;
  partnerName?: string;
  /** @uniqueItems true */
  partnerDepartments?: number[];
  /** @format int64 */
  insuranceProductId?: number;
  insurancePrograms?: number[];
  type: PromoCodeType;
  /** @format int32 */
  maxNumberOfUses: number;
  /** @format int32 */
  actualUsedCount?: number;
  initiator: InitiatorType;
  /**
   * @minLength 6
   * @maxLength 20
   */
  promo: string;
  status?: DiscountStatus;
  /**
   * @max 0
   * @exclusiveMax false
   */
  amount: number;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  updatedBy?: string;
  /** @format date */
  updatedDate?: string;
  discountMeasurementType: DiscountMeasurementType;
  /** @format int64 */
  clientId?: number;
  promoUnmasked?: string;
  updatable?: boolean;
}

export enum PromoCodeType {
  ONE_OFF = 'ONE_OFF',
  REUSABLE = 'REUSABLE',
}

export enum ChronoUnitSimplifiedDuplicate {
  NANOS = 'NANOS',
  MICROS = 'MICROS',
  MILLIS = 'MILLIS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  HALF_DAYS = 'HALF_DAYS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
  DECADES = 'DECADES',
  CENTURIES = 'CENTURIES',
  MILLENNIA = 'MILLENNIA',
  ERAS = 'ERAS',
  FOREVER = 'FOREVER',
}

export interface ProlongationConfigurationDto {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  beforeContractEnding?: number;
  beforeContractEndingUnit?: ChronoUnitSimplifiedDuplicate;
  /** @format int32 */
  afterContractEnding?: number;
  afterContractEndingUnit?: ChronoUnitSimplifiedDuplicate;
}

export enum Currency {
  UAH = 'UAH',
  USD = 'USD',
  EUR = 'EUR',
}

export enum FranchiseValueType {
  PERCENT = 'PERCENT',
  FIXED_SUM = 'FIXED_SUM',
  NO_VALUE = 'NO_VALUE',
}

export interface ProgramRiskDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceTypeId: number;
  /** @format int64 */
  insuranceRuleId: number;
  /** @format int64 */
  riskId: number;
  liabilityLimit?: number;
  franchiseValueType: FranchiseValueType;
  franchise?: number;
  riskFranchises?: RiskFranchiseDto[];
  /**
   * @max 1
   * @exclusiveMax false
   */
  sharePercent: number;
  expressPaymentAvailable?: boolean;
  resolutionTypes?: RiskResolutionTypeDto[];
  currency?: Currency;
}

export interface RiskFranchiseDto {
  /** @format int64 */
  id?: number;
  franchise?: number;
  /** @format int64 */
  programRiskId?: number;
}

export enum PaymentMerchantType {
  ACCEPT_PAY = 'ACCEPT_PAY',
  PAYOUT = 'PAYOUT',
}

export interface PaymentSystemConfigurationDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  merchantId?: string;
  apiKey?: string;
  paymentSystemType?: PaymentSystemType;
  /** @format int32 */
  priority?: number;
  paymentMerchantType?: PaymentMerchantType;
}

export enum PaymentSystemType {
  FONDY = 'FONDY',
  CONCORD = 'CONCORD',
}

export interface ConfigurationPartnerProgramAgentDto {
  /** @format int64 */
  id?: number;
  visible?: boolean;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  agentId?: number;
  feePercent?: number;
  insuranceAdjustmentRate?: number;
  depreciationAdjustmentRate?: number;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
}

export enum ContractConclusionFlow {
  REGULAR = 'REGULAR',
  WIDGET = 'WIDGET',
  ANY = 'ANY',
}

export enum ContractDraftStoragePeriodType {
  TWO_MONTH = 'TWO_MONTH',
  MONTH = 'MONTH',
  THREE_MONTH = 'THREE_MONTH',
  SIX_MONTH = 'SIX_MONTH',
  ONE_YEAR = 'ONE_YEAR',
}

export enum ContractNumberFormat {
  GENERAL_TEN_DIGITS = 'GENERAL_TEN_DIGITS',
  CUSTOM_FIVE_DIGITS = 'CUSTOM_FIVE_DIGITS',
  WITHOUT_TEMPLATE = 'WITHOUT_TEMPLATE',
  SPECIFIC_ALPHA_NUMERIC = 'SPECIFIC_ALPHA_NUMERIC',
  CUSTOM_NINE_DIGITS = 'CUSTOM_NINE_DIGITS',
}

export enum ContractVerificationType {
  MANUAL = 'MANUAL',
  AUTO_AT_SIGNING = 'AUTO_AT_SIGNING',
  AUTO_AFTER_PAYMENT = 'AUTO_AFTER_PAYMENT',
}

export enum DiscountUnit {
  PERCENT = 'PERCENT',
  COMMISSION = 'COMMISSION',
  MONEY_SUM = 'MONEY_SUM',
}

export interface EmailNotificationConfigurationDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  partnerProgramId?: number;
  type?: EmailTemplateType;
  template?: string[];
  senderEmail?: string;
  senderPassword?: string;
  senderHost?: string;
  senderPort?: string;
  emailSubject?: string;
  senderName?: string;
}

export enum EmailTemplateType {
  CONTRACT_DRAFT_PAYMENT_NOTIFICATION = 'CONTRACT_DRAFT_PAYMENT_NOTIFICATION',
  CONTRACT_SIGN_OTP = 'CONTRACT_SIGN_OTP',
  DOCUMENTS_UPLOAD = 'DOCUMENTS_UPLOAD',
  CONTRACT_OFFER = 'CONTRACT_OFFER',
  CLAIM_SIGN_INFO = 'CLAIM_SIGN_INFO',
  USER_SIGNUP = 'USER_SIGNUP',
  PARTNERSHIP = 'PARTNERSHIP',
  CONTRACT_PROMO_CODE_NOTIFICATION = 'CONTRACT_PROMO_CODE_NOTIFICATION',
}

export interface PartnerDiscountDto {
  /** @format int64 */
  id?: number;
  type: PartnerDiscountType;
  allowed?: boolean;
  agentContracts?: number[];
  minAmount: number;
  maxAmount: number;
  currency?: string;
  unit: DiscountUnit;
}

export enum PartnerDiscountType {
  PROGRAM_DISCOUNT = 'PROGRAM_DISCOUNT',
  CONTRACT_DISCOUNT = 'CONTRACT_DISCOUNT',
}

export interface PartnerProgramDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;
  agents?: ConfigurationPartnerProgramAgentDto[];
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
  visible?: boolean;
  clientSignContractEnabled?: boolean;
  installmentPaymentEnabled?: boolean;
  expressPaymentEnabled?: boolean;
  deferredPaymentEnabled?: boolean;
  /** @format int32 */
  expressAutoPaymentTimeoutInMin?: number;
  /** @format int32 */
  expressPaymentWorkingDays?: number;
  objectConfiguration?: Record<string, string>;
  /** @format int32 */
  countPayments?: number;
  roundingType?: RoundingType;
  programRoundingType?: RoundingType;
  contractDraftTtl?: ContractDraftStoragePeriodType;
  programContractDraftTtl?: ContractDraftStoragePeriodType;
  autoGenerationNumberEnabled?: boolean;
  partnerDiscounts?: PartnerDiscountDto[];
  /** @format int32 */
  insurerContractsLimit?: number;
  emailNotificationConfigurations?: EmailNotificationConfigurationDto[];
  contractNumberFormat?: ContractNumberFormat;
  contractConclusionFlow?: ContractConclusionFlow;
  contractVerificationType?: ContractVerificationType;
}

export enum RoundingType {
  HUNDREDTHS = 'HUNDREDTHS',
  TENTHS = 'TENTHS',
  UNITS = 'UNITS',
  TENS = 'TENS',
  HUNDREDS = 'HUNDREDS',
  THOUSANDS = 'THOUSANDS',
}

export interface SavePartnerProgramRequestDto {
  partnerProgram?: PartnerProgramDto;
  partnerDepartments?: number[];
}

export interface LocalTime {
  /** @format int32 */
  hour?: number;
  /** @format int32 */
  minute?: number;
  /** @format int32 */
  second?: number;
  /** @format int32 */
  nano?: number;
}

export interface PartnerDepartmentConfigurationDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  workDayStart?: LocalTime;
  workDayEnd?: LocalTime;
}

export enum CoefficientGroup {
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  REGISTRATION_ZONE = 'REGISTRATION_ZONE',
  VEHICLE_USAGE_SCOPE = 'VEHICLE_USAGE_SCOPE',
  HOLDER_TYPE = 'HOLDER_TYPE',
  USAGE_PERIOD = 'USAGE_PERIOD',
  INSURE_ACTIVITY = 'INSURE_ACTIVITY',
  CONTRACT_PERIOD = 'CONTRACT_PERIOD',
  CONTRACT_TYPE = 'CONTRACT_TYPE',
  BONUS_MALUS = 'BONUS_MALUS',
  PREFERENTIAL = 'PREFERENTIAL',
}

export interface MtsbuCoefficientsRegistryDto {
  /** @format int64 */
  id?: number;
  group: CoefficientGroup;
  type: string;
  lowerLimit: number;
  upperLimit?: number;
  description?: string;
  editable?: boolean;
}

export interface CityZoneDto {
  /** @format int64 */
  id?: number;
  cityName?: string;
  /** @format int64 */
  mtsbuId?: number;
  zone?: RegistrationZoneGroupType;
}

export enum RegistrationZoneGroupType {
  ZONE1 = 'ZONE_1',
  ZONE2 = 'ZONE_2',
  ZONE3 = 'ZONE_3',
  ZONE4 = 'ZONE_4',
  ZONE5 = 'ZONE_5',
  ZONE6 = 'ZONE_6',
}

export interface CreateUpdateInternetSalesDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  partnerProgramId?: number;
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  departmentId: number;
  /** @format uuid */
  token?: string;
  webSite?: string;
  /** @format int64 */
  insuranceCompanyId: number;
}

export interface InternetSalesDto {
  /** @format int64 */
  id?: number;
  partnerProgramDto?: PartnerProgramDto;
  /** @format int64 */
  departmentId: number;
  /** @format uuid */
  token?: string;
  webSite?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
}

export interface Duration {
  /** @format int32 */
  duration: number;
  durationUnit: ChronoUnitSimplifiedDuplicate;
}

export interface InsuranceRateDto {
  /** @format int64 */
  id?: number;
  duration: Duration;
  rate: number;
  /**
   * @minLength 0
   * @maxLength 3
   */
  contractNumberEnding: string;
  insuranceSums?: InsuranceSumDto[];
  userName?: string;
  /** @format date-time */
  editingDateTime?: string;
  subscription?: boolean;
  prolongation?: boolean;
  currency?: Currency;
}

export interface InsuranceSumDto {
  /** @format int64 */
  id?: number;
  insuranceSum?: number;
  paymentAmount?: number;
  objectConfiguration?: object;
}

export enum ContractCalculatorType {
  SPECIAL = 'SPECIAL',
  BASIC = 'BASIC',
  BASIC_FIXED_RATE = 'BASIC_FIXED_RATE',
}

export enum InsuranceObjectType {
  ELECTRONIC_DEVICE = 'ELECTRONIC_DEVICE',
  PERSON = 'PERSON',
  VEHICLE = 'VEHICLE',
  ESTATE = 'ESTATE',
}

export interface InsuranceProgramDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceCompanyId: number;
  /** @format int64 */
  insuranceProductId: number;
  /** @format int64 */
  contractTypeId: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  name: string;
  code?: string;
  /**
   * @minLength 3
   * @maxLength 3
   */
  codeNumeric: string;
  /**
   * @max 1
   * @exclusiveMax false
   */
  businessExpensesPercent: number;
  /** @format int64 */
  claimManagerId: number;
  /** @format int64 */
  signingAgentId: number;
  /** @format date */
  createdDate: string;
  objectType?: InsuranceObjectType;
  calculatorType?: ContractCalculatorType;
  /** @format int64 */
  individualTermsContractTemplateId?: number;
  /** @format int64 */
  claimTemplateId?: number;
  /** @format int64 */
  insuranceActTemplateId?: number;
  /** @format int64 */
  terminationTemplateId?: number;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  isActive?: boolean;
  contractStartPeriod?: Duration;
  installmentEnabled?: boolean;
  prolongationConfiguration?: ProlongationConfigurationDto;
  autoGenerationNumberEnabled?: boolean;
  medAppEnabled?: boolean;
  generalContractEnabled?: boolean;
  requiredContractVerify?: boolean;
  fixedStartDateEnabled?: boolean;
  roundingType?: RoundingType;
  contractDraftTtl?: ContractDraftStoragePeriodType;
  widgetType?: WidgetType;
}

export enum WidgetType {
  OSAGO = 'OSAGO',
  VZR = 'VZR',
  NONE = 'NONE',
}

export interface CoefficientDto {
  /** @format int64 */
  id?: number;
  group: CoefficientGroup;
  type: string;
  description?: string;
  rate: number;
  lowerLimit?: number;
  upperLimit?: number;
  userName?: string;
  /** @format date-time */
  createdDate?: string;
  mappingFields?: object;
  editable?: boolean;
}

export interface DepreciationRateDto {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  operatingTimeFrom: number;
  /** @format int32 */
  operatingTimeTo: number;
  operatingTimeUnit: ChronoUnitSimplifiedDuplicate;
  /**
   * @max 1
   * @exclusiveMax false
   */
  rate: number;
}

export interface InsurancePolicyDto {
  /** @format int64 */
  id?: number;
  name?: string;
  policyType?: PolicyType;
  rates?: InsuranceRateDto[];
  depreciationRates?: DepreciationRateDto[];
  /** @format date */
  applicablePeriodFrom: string;
  /** @format date */
  applicablePeriodTo: string;
}

export enum PolicyType {
  ORDINAL = 'ORDINAL',
  PARTNER_PROMOTION = 'PARTNER_PROMOTION',
  INSURANCE_COMPANY_PROMOTION = 'INSURANCE_COMPANY_PROMOTION',
  PROLONGATION = 'PROLONGATION',
}

export enum InstallmentPaymentPeriodType {
  ONE_PAYMENT = 'ONE_PAYMENT',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export interface ProgramInstallmentPaymentDto {
  /** @format int64 */
  id?: number;
  installmentPaymentPeriodType: InstallmentPaymentPeriodType;
  /** @format int32 */
  countPeriodFirstPayment?: number;
  minimalInsuranceSumInstallmentPayment: number;
  /** @format int32 */
  minimalContractDurationPaymentPeriod: number;
  rate?: number;
  autoPaymentEnabled?: boolean;
}

export enum CounterpartyType {
  PERSON = 'PERSON',
  ENTREPRENEUR = 'ENTREPRENEUR',
  COMPANY = 'COMPANY',
}

export interface DiscountDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceCompanyId: number;
  insuranceCompanyName: string;
  /** @format int64 */
  partnerId: number;
  partnerName: string;
  /** @uniqueItems true */
  partnerDepartments: number[];
  insuranceProducts: number[];
  insurancePrograms?: number[];
  /** @uniqueItems true */
  agentContracts: number[];
  type: DiscountType;
  initiator: InitiatorType;
  autoApplicable?: boolean;
  /**
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  status?: DiscountStatus;
  /**
   * @min -1
   * @exclusiveMin false
   * @max 2
   * @exclusiveMax false
   */
  amount?: number;
  /**
   * @min 0
   * @exclusiveMin false
   * @max 3
   * @exclusiveMax false
   */
  coefficient?: number;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  /** @uniqueItems true */
  durations: Duration[];
  holderType?: CounterpartyType;
  contractNumberEnding?: string;
  updatedBy?: string;
  /** @format date */
  updatedDate?: string;
  discountInfo?: object;
}

export enum DiscountType {
  PROMOTION = 'PROMOTION',
  PARTNER_COEFFICIENT = 'PARTNER_COEFFICIENT',
  PROMO_CODE = 'PROMO_CODE',
}

export interface PaymentRejectionCauseDetailDto {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 20
   */
  clause: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  details: string;
}

export interface PaymentRejectionCauseDto {
  /** @format int64 */
  id?: number;
  /** @format date-time */
  createdDate?: string;
  rejectionCause?: RejectionCauseDto;
  paymentRejectionCauseDetails: PaymentRejectionCauseDetailDto[];
}

export interface RejectionCauseDto {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 500
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  code: string;
}

export interface AssistanceContactDto {
  /** @format int64 */
  id?: number;
  contactType?: ContactType;
  contactData?: string;
  label?: string;
}

export interface ClaimsConfigurationDto {
  claimsDocuments?: ResolutionTypeConfigurationDto[];
  paymentRejectionCauses?: PaymentRejectionCauseDto[];
}

export interface CoefficientsContainerDto {
  k1?: CoefficientDto[];
  k2?: CoefficientDto[];
  k3?: CoefficientDto[];
  k4?: CoefficientDto[];
  k5?: CoefficientDto[];
  k6?: CoefficientDto[];
  k7?: CoefficientDto[];
  k8?: CoefficientDto[];
  k9?: CoefficientDto[];
  k10?: CoefficientDto[];
}

export interface ConfigurationInsuranceProductDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
}

export interface ConfigurationInsuranceProgramDto {
  /** @format int64 */
  id?: number;
  code?: string;
  codeNumeric?: string;
  /** @format int64 */
  contractTypeId?: number;
  name?: string;
  active?: boolean;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  /** @format date */
  updatedDate?: string;
  updatedBy?: string;
  businessExpensesPercent?: number;
  contractNumber?: string;
  policies?: InsurancePolicyDto[];
  risks?: ConfigurationProgramRiskDto[];
  /** @format int64 */
  signingAgentId?: number;
  /** @format int64 */
  claimManagerId?: number;
  /** @format date */
  createdDate?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceProduct?: ConfigurationInsuranceProductDto;
  objectConfiguration?: object;
  subAgreementSmsEnabled?: boolean;
  programInstallmentPayments?: ProgramInstallmentPaymentDto[];
  documents?: DocumentDto[];
  objectType?: InsuranceObjectType;
  prolongationConfiguration?: ProlongationConfigurationDto;
  autoGenerationNumberEnabled?: boolean;
  medAppEnabled?: boolean;
  generalContractEnabled?: boolean;
  terminationInitiators?: TerminationInitiatorConfigurationDto[];
  terminationDocumentConfigurations?: TerminationDocumentConfigurationDto[];
  deferredPaymentEnabled?: boolean;
  requiredContractVerify?: boolean;
  calculatorType?: ContractCalculatorType;
  claimsConfiguration?: ClaimsConfigurationDto;
  fixedStartDateEnabled?: boolean;
  coefficientsContainer?: CoefficientsContainerDto;
  contractStartPeriod?: Duration;
  roundingType?: RoundingType;
  contractDraftTtl?: ContractDraftStoragePeriodType;
  assistanceContacts?: AssistanceContactDto[];
  /** @format int64 */
  assistanceCompanyId?: number;
  widgetType?: WidgetType;
}

export interface ConfigurationProgramRiskDto {
  /** @format int64 */
  id?: number;
  risk?: ConfigurationRiskDto;
  liabilityLimit?: number;
  franchiseValueType?: FranchiseValueType;
  franchise?: number;
  riskFranchises?: RiskFranchiseDto[];
  sharePercent?: number;
  expressPaymentAvailable?: boolean;
  customConfiguration?: Record<string, string>;
  currency?: Currency;
}

export interface ConfigurationUserInsuranceProgramDto {
  /** @format int64 */
  id?: number;
  active?: boolean;
  insuranceProgram?: ConfigurationInsuranceProgramDto;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format int64 */
  userId?: number;
}

export enum ContactType {
  TELEGRAM = 'TELEGRAM',
  MOBILE_PHONE = 'MOBILE_PHONE',
  WORK_PHONE = 'WORK_PHONE',
  VIBER = 'VIBER',
  EMAIL = 'EMAIL',
  WEB_SITE = 'WEB_SITE',
}

export interface DocumentDto {
  typeCode?: InsuranceProgramDocumentType;
  /** @format date-time */
  createdDate?: string;
}

export enum InsuranceProgramDocumentType {
  OFFER = 'OFFER',
  CONTRACT_TEMPLATE = 'CONTRACT_TEMPLATE',
  CLAIM_TEMPLATE = 'CLAIM_TEMPLATE',
  ACT_TEMPLATE = 'ACT_TEMPLATE',
  TERMINATION_TEMPLATE = 'TERMINATION_TEMPLATE',
  CLIENT_CARD = 'CLIENT_CARD',
}

export interface ResolutionTypeConfigurationDto {
  riskResolutionType?: RiskResolutionTypeDto;
  risk?: RiskDto;
}

export interface RiskDto {
  code?: string;
  name?: string;
}

export interface PartnerProgramAgentDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  agentId?: number;
  addedOnProgram?: boolean;
  name?: string;
  feePercent?: number;
  insuranceAdjustmentRate?: number;
  depreciationAdjustmentRate?: number;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
}

export interface LiabilityLimitRequestDto {
  objectSubtypes?: number[];
  programRisks?: number[];
}

export enum ContractTypeGroupType {
  PAPER_DOCUMENT = 'PAPER_DOCUMENT',
  ELECTRONIC_DOCUMENT = 'ELECTRONIC_DOCUMENT',
}

export interface OsagoContractCalculationRequestDto {
  isUsedAsTaxi?: boolean;
  isEuroNumber?: boolean;
  isRequiredOtk?: boolean;
  franchise?: number;
  /** @format int32 */
  duration?: number;
  durationUnit?: ChronoUnitSimplifiedDuplicate;
  vehicleType?: VehicleTypeGroupType;
  registrationZone?: RegistrationZoneGroupType;
  holderType?: CounterpartyType;
  notUsageMonth?: number[];
  contractType?: ContractTypeGroupType;
  preferential?: PreferentialGroupType;
}

export enum PreferentialGroupType {
  WAR_VETERAN = 'WAR_VETERAN',
  DISABILITYGROUP2 = 'DISABILITY_GROUP_2',
  CHERNOBYLCATEGORY12 = 'CHERNOBYL_CATEGORY_1_2',
  PENSIONER = 'PENSIONER',
  NO_PRIVILEGE = 'NO_PRIVILEGE',
}

export enum VehicleTypeGroupType {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  C1 = 'C1',
  C2 = 'C2',
  D1 = 'D1',
  D2 = 'D2',
  E = 'E',
  F = 'F',
}

export interface ContractMtsbuCoefficientsDto {
  vehicleTypeCoefficient?: number;
  registrationZoneCoefficient?: number;
  vehicleUsageScopeCoefficient?: number;
  holderTypeCoefficient?: number;
  usagePeriodCoefficient?: number;
  insureActivityCoefficient?: number;
  contractPeriodCoefficient?: number;
  contractTypeCoefficient?: number;
  bonusMalusCoefficient?: number;
  preferentialCoefficient?: number;
}

export interface IntegrationConfigurationDto {
  /** @format int64 */
  id?: number;
  type?: IntegrationType;
  processType?: ProcessType;
  /** @format int64 */
  partnerId?: number;
  insurancePrograms?: ConfigurationInsuranceProgramDto[];
}

export enum IntegrationType {
  IMPORT_VISIT_UKRAINE = 'IMPORT_VISIT_UKRAINE',
  IMPORT_CHI = 'IMPORT_CHI',
  EXPORTCONTRACT1C = 'EXPORT_CONTRACT_1C',
  EXPORTINTERPOLISCONTRACT1C = 'EXPORT_INTER_POLIS_CONTRACT_1C',
  EXPORTCLAIM1C = 'EXPORT_CLAIM_1C',
  IMPORT_EDAK = 'IMPORT_EDAK',
  EXPORT_EDAK = 'EXPORT_EDAK',
  VERIFY_CONTRACT = 'VERIFY_CONTRACT',
}

export enum ProcessType {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
}

export interface ConfigurationInsuranceRateDto {
  /** @format int64 */
  id?: number;
  duration?: Duration;
  rate?: number;
  currency?: Currency;
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format int64 */
  partnerProgramId?: number;
  contractNumberEnding?: string;
  applicablePeriod?: DateRange;
  insuranceSums?: Record<string, InsuranceRateSumDto>;
}

export interface DateRange {
  /** @format date */
  from?: string;
  /** @format date */
  to?: string;
}

export interface InsuranceRateSumDto {
  /** @format int64 */
  id?: number;
  insuranceSum?: number;
  paymentAmount?: number;
  objectConfiguration?: object;
}

export interface DepreciationRateRequestDto {
  operatingTime?: Duration;
  /** @format int64 */
  partnerId?: number;
  /** @format date */
  contractSignDate?: string;
}

export interface TenantDto {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
}

export interface EwaInsuranceProgramDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  ewaId?: number;
  name?: string;
  /** @format int64 */
  contractTypeId?: number;
  /** @format date */
  createdDate?: string;
  franchise?: number;
}

export interface DiscountRequestDto {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  insuranceProductId?: number;
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format int64 */
  departmentPartnerId?: number;
  agentContractIds?: number[];
  /** @format int32 */
  duration?: number;
  durationUnit?: ChronoUnitSimplifiedDuplicate;
  franchise?: number;
  holderType?: CounterpartyType;
  registrationZone?: RegistrationZoneGroupType;
  vehicleType?: VehicleTypeGroupType;
  contractNumberEnding?: string;
  /** @format date */
  contractDate?: string;
}

export interface DiscountCalculationDto {
  insuranceCompanyCoefficient?: number;
  partnerCommissionCoefficient?: number;
  partnerCoefficient?: number;
  promo?: number;
}

export interface ConfigurationDepartmentProgramDto {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  externalId?: string;
  /** @format date-time */
  externalIdCreated?: string;
  active?: boolean;
  partnerProgram?: PartnerProgramDto;
  /** @format date-time */
  activationDate?: string;
  /** @format date-time */
  deactivationDate?: string;
  /** @format int64 */
  departmentId?: number;
}

export interface ClaimDocumentConfigurationDto {
  /** @format int64 */
  id?: number;
  documentType?: DocumentTypeDto;
  visible?: boolean;
  obligatory?: boolean;
  informationObligatory?: boolean;
  /** @format int64 */
  documentTypeId: number;
  /** @format int64 */
  riskId?: number;
  allowMultipleFiles?: boolean;
  calculable?: boolean;
  calculationParticipation?: boolean;
}

export interface ResolutionTypeDocumentConfigurationDto {
  /** @format int64 */
  id?: number;
  documentConfigurations?: ClaimDocumentConfigurationDto[];
}

export interface CreateAggregatorInsuranceProgramDto {
  /** @format int64 */
  partnerId?: number;
  partnerProgramsId?: number[];
}

export interface AggregatorInsuranceProgramDto {
  /** @format int64 */
  aggregatorPartnerId?: number;
  partnerPrograms?: PartnerProgramDto[];
}

export interface TerminationInitiatorDto {
  /** @format int64 */
  id?: number;
  code?: TerminationInitiatorCode;
  name?: string;
}

export interface TerminationInitiatorCodePair {
  key?: TerminationInitiatorCode;
  value?: string;
}

export enum TerminationCauseCode {
  DO_NOT_USE_DEVICE_INSURER = 'DO_NOT_USE_DEVICE_INSURER',
  DO_NOT_USE_DEVICE_CUSTOMER = 'DO_NOT_USE_DEVICE_CUSTOMER',
  VIOLATION_NON_FULFILLMENT_TERMS_CONTRACT_BY_INSURED = 'VIOLATION_NON_FULFILLMENT_TERMS_CONTRACT_BY_INSURED',
  DO_NOT_USE_VEHICLE_INSURER = 'DO_NOT_USE_VEHICLE_INSURER',
  DO_NOT_USE_VEHICLE_CUSTOMER = 'DO_NOT_USE_VEHICLE_CUSTOMER',
  CHANGE_TERMS = 'CHANGE_TERMS',
  WANT_TO_CHANGE_TERMS = 'WANT_TO_CHANGE_TERMS',
  ALREADY_HAVE_CONTRACT = 'ALREADY_HAVE_CONTRACT',
  NO_INSURANCE_REQUIRED = 'NO_INSURANCE_REQUIRED',
  RETURN_INSURED_DEVICE_TO_SELLER = 'RETURN_INSURED_DEVICE_TO_SELLER',
  EXCHANGE_OF_DEVICE = 'EXCHANGE_OF_DEVICE',
  EXHAUSTION_AGREEMENT_LIABILITY_LIMIT = 'EXHAUSTION_AGREEMENT_LIABILITY_LIMIT',
  NON_PAYMENT_BY_INSURED_NEXT_INSURANCE_PAYMENT = 'NON_PAYMENT_BY_INSURED_NEXT_INSURANCE_PAYMENT',
  OTHER_CAUSE = 'OTHER_CAUSE',
  DISMISSAL = 'DISMISSAL',
  NOT_PAYMENT = 'NOT_PAYMENT',
  INVALID_CONTRACT = 'INVALID_CONTRACT',
}

export interface TerminationCauseDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: TerminationCauseCode;
}

export interface AccountTypeNamePair {
  key?: AccountTypeCode;
  value?: string;
}

export interface ConfigurationRiskResolutionTypeDto {
  /** @format int64 */
  id?: number;
  risk?: ConfigurationRiskDto;
  resolutionType?: ResolutionType;
  active?: boolean;
}

export interface ResultListDtoRiskView {
  resultList?: RiskView[];
  /** @format int64 */
  count?: number;
  total?: RiskView;
}

export interface RiskView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  /** @format date */
  creationDate?: string;
  isObligatory?: boolean;
  /** @format int64 */
  insuranceRuleId?: number;
  /** @format int64 */
  insuranceTypeId?: number;
}

export interface PromoCodeCalculationDto {
  amount?: number;
  valid?: boolean;
  discountMeasurementType?: DiscountMeasurementType;
}

export interface PromoCodeView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  status?: DiscountStatus;
  insuranceCompanyName?: string;
  partnerName?: string;
  /** @format int64 */
  insuranceProductId?: number;
  insuranceProductName?: string;
  initiator?: InitiatorType;
  type?: PromoCodeType;
  promo?: string;
  /** @format int32 */
  maxNumberOfUses?: number;
  /** @format int32 */
  actualUsedCount?: number;
  actualPromoCodeSum?: number;
  /** @format date-time */
  lastUsedDate?: string;
  insuranceProgramIds?: string;
  amount?: number;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  updatedBy?: string;
  /** @format date-time */
  modifiedAt?: string;
  /** @format date-time */
  createDate?: string;
  discountMeasurementType?: DiscountMeasurementType;
  /** @format int64 */
  clientId?: number;
  updatable?: boolean;
}

export interface ResultListDtoPromoCodeView {
  resultList?: PromoCodeView[];
  /** @format int64 */
  count?: number;
  total?: PromoCodeView;
}

export interface ConfigurationPaymentSystemCredentialsDto {
  merchantId?: string;
  apiKey?: string;
}

export interface RoundingTypePair {
  key?: RoundingType;
  value?: string;
}

export interface ContractDraftStoragePeriodTypePair {
  key?: ContractDraftStoragePeriodType;
  value?: string;
}

export interface PairContractNumberFormatString {
  key?: ContractNumberFormat;
  value?: string;
}

export enum CalculationStrategy {
  RISK_SUM = 'RISK_SUM',
  SINGLE_RISK = 'SINGLE_RISK',
}

export interface ConfigurationProgramObjectSubtypeDto {
  /** @format int64 */
  id?: number;
  paymentAmount?: number;
  franchise?: number;
  calculationStrategy?: CalculationStrategy;
  risks?: ConfigurationProgramObjectSubtypeRiskDto[];
}

export interface ConfigurationProgramObjectSubtypeRiskDto {
  /** @format int64 */
  id?: number;
  programRisk?: ConfigurationProgramRiskDto;
  liabilityLimit?: number;
}

export interface ConfigurationObjectSubtypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  objectType?: InsuranceObjectType;
  subtypeCode?: ObjectSubtypeCode;
}

export enum ObjectSubtypeCode {
  STRUCTURAL_ELEMENTS = 'STRUCTURAL_ELEMENTS',
  INTERIOR_OBJECT = 'INTERIOR_OBJECT',
  CIVIL_RESPONSIBILITY = 'CIVIL_RESPONSIBILITY',
  MOVABLE = 'MOVABLE',
}

export interface ResultListDtoVehicleView {
  resultList?: VehicleView[];
  /** @format int64 */
  count?: number;
  total?: VehicleView;
}

export interface VehicleView {
  /** @format int64 */
  id?: number;
  markName?: string;
  modelName?: string;
  fullName?: string;
}

export enum ContractPeriodGroupType {
  DURATION1 = 'DURATION_1',
  DURATION2 = 'DURATION_2',
  DURATION3 = 'DURATION_3',
  DURATION4 = 'DURATION_4',
  DURATION5 = 'DURATION_5',
  DURATION6 = 'DURATION_6',
  DURATION7A = 'DURATION_7_A',
  DURATION7B = 'DURATION_7_B',
  DURATION8 = 'DURATION_8',
  DURATION9 = 'DURATION_9',
  DURATION10 = 'DURATION_10',
  DURATION11 = 'DURATION_11',
  DURATION12 = 'DURATION_12',
  DURATION13 = 'DURATION_13',
}

export interface MtsbuConfigurationDto {
  applicableVehicleTypes?: VehicleTypeGroupType[];
  zones?: RegistrationZoneGroupType[];
  holderType?: CounterpartyType[];
  contractPeriods?: ContractPeriodGroupType[];
  contractType?: ContractTypeGroupType[];
  preferential?: PreferentialGroupType[];
}

export interface InternetSalesView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  partnerProgramId?: number;
  insuranceProgramName?: string;
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format uuid */
  token?: string;
  website?: string;
  referralLink?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  modifiedAt?: string;
  modifiedUserName?: string;
  /** @format date-time */
  deactivatedAt?: string;
}

export interface ResultListDtoInternetSalesView {
  resultList?: InternetSalesView[];
  /** @format int64 */
  count?: number;
  total?: InternetSalesView;
}

export interface InsuranceTypeView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  /** @format date */
  creationDate?: string;
  isObligatory?: boolean;
  licenseSeries?: string;
  /** @format int64 */
  licenseNumber?: number;
  /** @format date */
  licenseIssueDate?: string;
  legislativeDocument?: string;
  /** @format int64 */
  insuranceRuleId?: number;
  insuranceRuleName?: string;
}

export interface ResultListDtoInsuranceTypeView {
  resultList?: InsuranceTypeView[];
  /** @format int64 */
  count?: number;
  total?: InsuranceTypeView;
}

export interface InsuranceProgramSmsConfigurationDto {
  contractOtpDisabled?: boolean;
  claimOtpDisabled?: boolean;
  terminationOtpDisabled?: boolean;
  contractSendSmsDisabled?: boolean;
  claimSendSmsDisabled?: boolean;
  terminationSendSmsDisabled?: boolean;
}

export interface ConfigurationInsurancePolicyDto {
  /** @format int64 */
  id?: number;
  insuranceProgram?: ConfigurationInsuranceProgramDto;
  partnerProgram?: PartnerProgramDto;
  applicablePeriod?: DateRange;
  insuranceRates?: ConfigurationInsuranceRateDto[];
  policyType?: PolicyType;
  name?: string;
  offerUrl?: string;
  contractTemplateUrl?: string;
}

export interface ConfigurationInitiatorCauseDto {
  /** @format int64 */
  id?: number;
  initiator?: ConfigurationTerminationInitiatorDto;
  /** @format int64 */
  causeId?: number;
  name?: string;
  code?: TerminationCauseCode;
  /** @format int64 */
  insuranceProgramId?: number;
}

export interface ConfigurationTerminationInitiatorDto {
  /** @format int64 */
  id?: number;
  name?: TerminationInitiatorCode;
}

export interface ClaimInfoFieldConfigurationDto {
  /** @format int64 */
  id?: number;
  fieldName?: string;
  required?: boolean;
  /** @format int64 */
  insuranceProgramId?: number;
}

export interface InsuranceRuleView {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface ResultListDtoInsuranceRuleView {
  resultList?: InsuranceRuleView[];
  /** @format int64 */
  count?: number;
  total?: InsuranceRuleView;
}

export interface InstallmentPaymentPeriodTypeDto {
  installmentPaymentPeriodType?: InstallmentPaymentPeriodType;
  description?: string;
  minimalInsuranceSum?: number;
}

export interface InsuranceProgramDetailsDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  installmentPaymentSubscription?: boolean;
  clientSignContractEnabled?: boolean;
  fixedStartDateEnabled?: boolean;
  autoGenerationNumberEnabled?: boolean;
  policies?: InsurancePolicyDto[];
  installmentPayments?: InstallmentPaymentPeriodTypeDto[];
  insuranceProgramSmsConfiguration?: InsuranceProgramSmsConfigurationDto;
  franchises?: number[];
  contractStartPeriod?: Duration;
}

export enum InsuranceProgramStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
  DRAFT = 'DRAFT',
}

export interface InsuranceProgramView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  code?: string;
  name?: string;
  /** @format date */
  createdDate?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  status?: InsuranceProgramStatus;
  active?: boolean;
  insuranceRules?: string;
  insuranceTypeName?: string;
  /** @format int64 */
  insuranceProductId?: number;
  insuranceProductName?: string;
  insuranceProductCode?: string;
  /** @format int64 */
  partnerProgramId?: number;
  /** @format date */
  partnerProgramActivationDate?: string;
  /** @format date */
  partnerProgramDeactivationDate?: string;
  /** @format date */
  applicablePeriodFrom?: string;
  /** @format date */
  applicablePeriodTo?: string;
  visible?: boolean;
  installmentPaymentEnabled?: boolean;
  clientSignContractEnabled?: boolean;
  /** @format int64 */
  claimManagerId?: number;
  businessExpensesPercent?: number;
  /** @format int64 */
  contractTypeId?: number;
  /** @format int64 */
  signingAgentId?: number;
  subAgreementSmsEnabled?: boolean;
  /** @format int64 */
  prolongateFromId?: number;
  /** @format int64 */
  individualTermsTemplateId?: number;
  individualTermsTemplateGeneratorCode?: string;
  /** @format int64 */
  claimTemplateId?: number;
  claimTemplateGeneratorCode?: string;
  /** @format int64 */
  insuranceActTemplateId?: number;
  insuranceActTemplateGeneratorCode?: string;
  /** @format int64 */
  terminationTemplateId?: number;
  terminationTemplateGeneratorCode?: string;
  contractOtpDisabled?: boolean;
  claimOtpDisabled?: boolean;
  terminationOtpDisabled?: boolean;
  contractSendSmsDisabled?: boolean;
  claimSendSmsDisabled?: boolean;
  terminationSendSmsDisabled?: boolean;
  autoGenerationNumberEnabled?: boolean;
  requiredContractVerify?: boolean;
  fixedStartDateEnabled?: boolean;
  contractStartPeriod?: Duration;
  contractDraftTtl?: ContractDraftStoragePeriodType;
  partnerProgramContractDraftTtl?: ContractDraftStoragePeriodType;
  departmentActive?: boolean;
}

export interface ResultListDtoInsuranceProgramView {
  resultList?: InsuranceProgramView[];
  /** @format int64 */
  count?: number;
  total?: InsuranceProgramView;
}

export enum InsuranceProductCode {
  CV = 'CV',
  MK = 'MK',
  MZ = 'MZ',
  IN = 'IN',
  MED = 'MED',
  MM = 'MM',
  VZR = 'VZR',
  CRG = 'CRG',
  OSG = 'OSG',
}

export interface InsuranceProductView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  visible?: boolean;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
  contractConclusionFlow?: ContractConclusionFlow;
  widgetType?: WidgetType;
}

export interface ResultListDtoInsuranceProductView {
  resultList?: InsuranceProductView[];
  /** @format int64 */
  count?: number;
  total?: InsuranceProductView;
}

export interface ConfigurationFilePathDto {
  type?: FileStorageEntityType;
  fileStorageKey?: string;
  storageFileKey?: string;
  originalFileName?: string;
}

export enum FileStorageEntityType {
  CONTRACT_CLAIM_DOCUMENT = 'CONTRACT_CLAIM_DOCUMENT',
  CONTRACT_TERMINATION_DOCUMENT = 'CONTRACT_TERMINATION_DOCUMENT',
  ML_EXPORT_DOCUMENT = 'ML_EXPORT_DOCUMENT',
  SALE_REGISTER_DOCUMENT = 'SALE_REGISTER_DOCUMENT',
  INSURANCE_COMPANY_LOGO = 'INSURANCE_COMPANY_LOGO',
  INSURANCE_PROGRAM_DOCUMENT = 'INSURANCE_PROGRAM_DOCUMENT',
  TEMPLATE_FILE = 'TEMPLATE_FILE',
}

export interface SaleRegisterDocumentConfigurationDto {
  /** @format int64 */
  id?: number;
  documentType?: DocumentTypeDto;
  visible?: boolean;
  obligatory?: boolean;
  informationObligatory?: boolean;
  allowMultipleFiles?: boolean;
}

export interface ContractDocumentConfigurationDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  partnerProgramId?: number;
  documentType?: DocumentTypeDto;
  allowMultipleFiles?: boolean;
}

export enum GeneratorCode {
  COVID_CONTRACT_REPORT_GENERATOR = 'COVID_CONTRACT_REPORT_GENERATOR',
  COVID_CONTRACT_CLAIM_REPORT_GENERATOR = 'COVID_CONTRACT_CLAIM_REPORT_GENERATOR',
  ELECTRONIC_DEVICE_CONTRACT_REPORT_GENERATOR = 'ELECTRONIC_DEVICE_CONTRACT_REPORT_GENERATOR',
  ELECTRONIC_DEVICE_CONTRACT_CLAIM_REPORT_GENERATOR = 'ELECTRONIC_DEVICE_CONTRACT_CLAIM_REPORT_GENERATOR',
  ELECTRONIC_DEVICE_ACT_CLAIM_REPORT_GENERATOR = 'ELECTRONIC_DEVICE_ACT_CLAIM_REPORT_GENERATOR',
  ESTATE_CONTRACT_CLAIM_REPORT_GENERATOR = 'ESTATE_CONTRACT_CLAIM_REPORT_GENERATOR',
  VZR_ACT_CLAIM_REPORT_GENERATOR = 'VZR_ACT_CLAIM_REPORT_GENERATOR',
  VISIT_UKRAINE_CONTRACT_REPORT_GENERATOR = 'VISIT_UKRAINE_CONTRACT_REPORT_GENERATOR',
  VZR_CONTRACT_REPORT_GENERATOR = 'VZR_CONTRACT_REPORT_GENERATOR',
  VZR_UKRAINE_CONTRACT_REPORT_GENERATOR = 'VZR_UKRAINE_CONTRACT_REPORT_GENERATOR',
  VZR_FOREIGNERS_CONTRACT_REPORT_GENERATOR = 'VZR_FOREIGNERS_CONTRACT_REPORT_GENERATOR',
  VZR_VIZIT_CONTRACT_REPORT_GENERATOR = 'VZR_VIZIT_CONTRACT_REPORT_GENERATOR',
  MEDICAL_EXPENSES_CONTRACT_REPORT_GENERATOR = 'MEDICAL_EXPENSES_CONTRACT_REPORT_GENERATOR',
  MINI_KASKO_CONTRACT_REPORT_GENERATOR = 'MINI_KASKO_CONTRACT_REPORT_GENERATOR',
  MINI_KASKO_CONTRACT_CLAIM_REPORT_GENERATOR = 'MINI_KASKO_CONTRACT_CLAIM_REPORT_GENERATOR',
  KASKO_DARNICA_INSURANCE_ACT_REPORT_GENERATOR = 'KASKO_DARNICA_INSURANCE_ACT_REPORT_GENERATOR',
  KASKO_VF_INSURANCE_ACT_REPORT_GENERATOR = 'KASKO_VF_INSURANCE_ACT_REPORT_GENERATOR',
  MINI_KASKO_INSURANCE_ACT_REPORT_GENERATOR = 'MINI_KASKO_INSURANCE_ACT_REPORT_GENERATOR',
  ESTATE_MINI_CONTRACT_REPORT_GENERATOR = 'ESTATE_MINI_CONTRACT_REPORT_GENERATOR',
  ESTATE_APEX_CONTRACT_REPORT_GENERATOR = 'ESTATE_APEX_CONTRACT_REPORT_GENERATOR',
  TERMINATION_REPORT_GENERATOR = 'TERMINATION_REPORT_GENERATOR',
  VZR_TERMINATION_REPORT_GENERATOR = 'VZR_TERMINATION_REPORT_GENERATOR',
  OSAGO_CONTRACT_REPORT_GENERATOR = 'OSAGO_CONTRACT_REPORT_GENERATOR',
  OSAGO_CONTRACT_CLAIM_REPORT_GENERATOR = 'OSAGO_CONTRACT_CLAIM_REPORT_GENERATOR',
  KASKO_DARNICA_CONTRACT_CLAIM_REPORT_GENERATOR = 'KASKO_DARNICA_CONTRACT_CLAIM_REPORT_GENERATOR',
}

export interface TemplateFileDto {
  generatorCode?: GeneratorCode;
  templatePath?: string;
}

export interface GeneratorDto {
  /** @format int64 */
  id?: number;
  name?: GeneratorCode;
  description?: string;
  templateFiles?: OptionDto[];
}

export interface GeneratorWrapperDto {
  documentType?: InsuranceProgramDocumentType;
  generators?: GeneratorDto[];
}

export interface DiscountConfigurationDto {
  discountTypes?: DiscountType[];
  applicableVehicleTypes?: VehicleTypeGroupType[];
  zones?: RegistrationZoneGroupType[];
  holderType?: CounterpartyType[];
}

export interface DiscountView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  status?: DiscountStatus;
  insuranceCompanyName?: string;
  partnerName?: string;
  insuranceProductId?: string;
  insuranceProductName?: string;
  autoApplicable?: boolean;
  initiator?: InitiatorType;
  holderType?: CounterpartyType;
  type?: DiscountType;
  name?: string;
  insuranceProgramName?: string;
  amount?: number;
  duration?: Duration;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  agentContractId?: string;
  updatedBy?: string;
  /** @format date */
  updatedDate?: string;
}

export interface ResultListDtoDiscountView {
  resultList?: DiscountView[];
  /** @format int64 */
  count?: number;
  total?: DiscountView;
}

export interface DiaCredentialDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
  acquirerToken?: string;
  authAcquirerToken?: string;
  /** @format uuid */
  vaultId?: string;
  branchId?: string;
}

export interface DepartmentProgramView {
  /** @format int64 */
  id?: number;
  externalId?: string;
  /** @format date-time */
  externalIdCreated?: string;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  insuranceProgramId?: number;
  name?: string;
  code?: string;
  /** @format int64 */
  departmentId?: number;
}

export interface ResultListDtoDepartmentProgramView {
  resultList?: DepartmentProgramView[];
  /** @format int64 */
  count?: number;
  total?: DepartmentProgramView;
}

export interface ResolutionTypePair {
  key?: ResolutionType;
  value?: string;
}

export interface DetailedAggregatorInsuranceProgramDto {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  insuranceCompanyName?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerProgramId?: number;
  insuranceProgram?: ConfigurationInsuranceProgramDto;
}

export interface UpdateDocumentConfigurationsParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface CreateTerminationDocumentConfigurationsParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetInsuranceProgramParams {
  /** @default "ua" */
  lang?: string;
  /** @format int64 */
  id: number;
}

export interface GetVehicleUsageScopes1Params {
  isTaxi: boolean;
  holderType: CounterpartyType;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface CreateVehicleUsageScopesParams {
  isTaxi: boolean;
  holderType: CounterpartyType;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface UpdateInsureActivityCoefficientsParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetTerminationInitiatorParams {
  initiatorCode: TerminationInitiatorCode;
  /** @format int64 */
  id: number;
}

export interface List1Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [DiscountStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [InitiatorType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  initiator?: string;
  /** Filter value. View field type is [PromoCodeType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  promo?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  maxNumberOfUses?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actualUsedCount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actualPromoCodeSum?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  lastUsedDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramIds?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  amount?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  updatedBy?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  modifiedAt?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createDate?: string;
  /** Filter value. View field type is [DiscountMeasurementType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  discountMeasurementType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface CreateInsuranceProgramRiskParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface FindAllPaymentSystemConfigurationsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
}

export interface FindAllByInsuranceProgramIdParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface FindByPartnerProgramIdAndTypeParams {
  /** @format int64 */
  partnerProgramId: number;
  templateType: EmailTemplateType;
}

export interface SaveCoefficientsParams {
  /** @format int64 */
  insuranceProgramId: number;
  coefficientGroup: CoefficientGroup;
}

export interface List2Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [UUID] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  token?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  website?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  referralLink?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdAt?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  modifiedAt?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  modifiedUserName?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  deactivatedAt?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface FindAllIntegrationConfigurationsParams {
  /** @format int64 */
  partnerId: number;
  processType: ProcessType;
  integrationType?: IntegrationType;
}

export interface FindRateForProlongationParams {
  /** @format int64 */
  prolongatedFromInsuranceRateId: number;
  /** @format int64 */
  id: number;
}

export interface FindOrdinalInsuranceRateByDurationAndInsurancePolicyIdParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  partnerId: number;
  /** @format date */
  contractDate: string;
}

export interface ListDurationsByInsuranceProgramParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface List4Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [InsuranceProgramStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  active?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceRules?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceTypeName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramActivationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramDeactivationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  applicablePeriodFrom?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  applicablePeriodTo?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  visible?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  installmentPaymentEnabled?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientSignContractEnabled?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimManagerId?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  businessExpensesPercent?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractTypeId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signingAgentId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  subAgreementSmsEnabled?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  prolongateFromId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  individualTermsTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  individualTermsTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  claimTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceActTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceActTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  terminationTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  terminationTemplateGeneratorCode?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  contractOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  claimOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminationOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  contractSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  claimSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminationSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  autoGenerationNumberEnabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  requiredContractVerify?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  fixedStartDateEnabled?: string;
  /** Filter value. View field type is [Duration] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractStartPeriod?: string;
  /** Filter value. View field type is [ContractDraftStoragePeriodType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractDraftTtl?: string;
  /** Filter value. View field type is [ContractDraftStoragePeriodType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramContractDraftTtl?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  departmentActive?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetProlongationPoliciesParams {
  /** @format int64 */
  insuranceProgramId: number;
  policyType: PolicyType;
}

export interface CreateInsurancePolicyParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface CreateOrUpdatePartnerProgramsParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  agentId: number;
}

export interface SaveTemplateFileParams {
  /** @format int64 */
  generatorCodeId: number;
  lang: string;
}

export interface DownloadInsuranceProgramFileParams {
  documentTypeCode: InsuranceProgramDocumentType;
  /** @default "ua" */
  lang?: string;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface SaveInsuranceProgramFileParams {
  documentTypeCode: InsuranceProgramDocumentType;
  /** @default "ua" */
  lang?: string;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface List5Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [DiscountStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  autoApplicable?: string;
  /** Filter value. View field type is [InitiatorType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  initiator?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  holderType?: string;
  /** Filter value. View field type is [DiscountType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramName?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  amount?: string;
  /** Filter value. View field type is [Duration] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  duration?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  agentContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  updatedBy?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  updatedDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface CreateExternalIdParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  departmentId: number;
}

export interface DeleteExternalIdParams {
  /** @format int64 */
  departmentProgramId: number;
}

export interface ListDepartmentProgramsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  externalId?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  externalIdCreated?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  departmentId?: string;
}

export interface FindProgramPaymentRejectionCauseParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface CreatePaymentRejectionCauseParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetTerminationDocumentsParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetTerminationCausesParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [TerminationCauseCode] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  code?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  terminationInitiatorId?: string;
}

export interface GetAccountTypesParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  terminationInitiatorId: number;
}

export interface FindAllRiskResolutionTypesParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  id: number;
}

export interface PrintXlsxReportParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  creationDate?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  isObligatory?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceRuleId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceTypeId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  creationDate?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  isObligatory?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceRuleId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceTypeId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface IsPromoCodeValidParams {
  /** @format int64 */
  insuranceCompanyId: number;
  promoCode: string;
}

export interface PrintXlsxReport1Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [DiscountStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [InitiatorType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  initiator?: string;
  /** Filter value. View field type is [PromoCodeType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  promo?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  maxNumberOfUses?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actualUsedCount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actualPromoCodeSum?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  lastUsedDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramIds?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  amount?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  updatedBy?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  modifiedAt?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createDate?: string;
  /** Filter value. View field type is [DiscountMeasurementType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  discountMeasurementType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface FindPromoCodeCalculationParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  promoCode?: string;
}

export interface FindPromoCodeInfoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  promoCode?: string;
}

export interface GetPaymentSystemCredentialsDtoParams {
  /** @format int64 */
  insuranceCompanyId: number;
  /** @format int64 */
  partnerId: number;
  paymentSystemType: PaymentSystemType;
  paymentMerchantType: PaymentMerchantType;
}

export interface GetPaymentSystemConfigurationParams {
  /** @format int64 */
  insuranceCompanyId: number;
  paymentMerchantType: PaymentMerchantType;
  /** @format int64 */
  partnerId: number;
}

export interface GetMaxAgentContractCommissionParams {
  agentIds?: number[];
  /** @format int64 */
  partnerId: number;
}

export interface FindRoundingTypesParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  id: number;
}

export interface FindContractDraftStoragePeriodTypesParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  id: number;
}

export interface FindContractNumberFormatParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetPartnerDiscountUnitsParams {
  partnerDiscountType: PartnerDiscountType;
}

export interface FindProgramObjectSubtypeParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  id: number;
}

export interface FindAllObjectSubtypeByInsuranceObjectTypeParams {
  /** @format int64 */
  insuranceProgramId?: number;
  objectType?: InsuranceObjectType;
}

export interface ListVehiclesParams {
  params: Record<string, string>;
}

export interface GetZoneByCityParams {
  city: string;
}

export interface GetVehicleUsageScopesParams {
  isTaxi: boolean;
  holderType: CounterpartyType;
}

export interface GetTypesByGroupParams {
  group: CoefficientGroup;
}

export interface GetLimitsByGroupAndTypeParams {
  group: CoefficientGroup;
  type: string;
}

export interface PrintXlsxReport2Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  creationDate?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  isObligatory?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  licenseSeries?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  licenseNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  licenseIssueDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  legislativeDocument?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceRuleId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceRuleName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface List3Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  creationDate?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  isObligatory?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  licenseSeries?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  licenseNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  licenseIssueDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  legislativeDocument?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceRuleId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceRuleName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListDurationsByPartnerProgramIdParams {
  /** @format int64 */
  partnerProgramId: number;
  /** @format int64 */
  id: number;
}

export interface FindAllPoliciesParams {
  /** @format int64 */
  partnerId: number;
  /** @format date */
  applicablePeriodFrom: string;
  /** @format int64 */
  id: number;
}

export interface FindInsurancePolicyParams {
  /** @format int64 */
  partnerId: number;
  /** @format date */
  contractSignDate: string;
  /** @format int64 */
  id: number;
}

export interface ListDurationsParams {
  /** @format int64 */
  insurancePolicyId: number;
  /** @format int64 */
  id: number;
}

export interface ListRulesParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
}

export interface GetInsuranceProgramsByPartnerCompanyAndProductParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  insuranceCompanyId: number;
  productCode: string;
}

export interface FindInsuranceProgramIdsByCodeInParams {
  codes: string[];
}

export interface ListDetailsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [InsuranceProgramStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  active?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceRules?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceTypeName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramActivationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramDeactivationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  applicablePeriodFrom?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  applicablePeriodTo?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  visible?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  installmentPaymentEnabled?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientSignContractEnabled?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimManagerId?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  businessExpensesPercent?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractTypeId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signingAgentId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  subAgreementSmsEnabled?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  prolongateFromId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  individualTermsTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  individualTermsTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  claimTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceActTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceActTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  terminationTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  terminationTemplateGeneratorCode?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  contractOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  claimOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminationOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  contractSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  claimSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminationSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  autoGenerationNumberEnabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  requiredContractVerify?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  fixedStartDateEnabled?: string;
  /** Filter value. View field type is [Duration] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractStartPeriod?: string;
  /** Filter value. View field type is [ContractDraftStoragePeriodType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractDraftTtl?: string;
  /** Filter value. View field type is [ContractDraftStoragePeriodType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramContractDraftTtl?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  departmentActive?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetDetailsForProlongationParams {
  /** @format int64 */
  prolongatedFromInsuranceRateId: number;
}

export interface GetInsuranceProgramByCodeParams {
  /** @default "ua" */
  lang?: string;
  code: string;
}

export interface ListProgramsByProductCodeParams {
  insuranceProductCode: string;
}

export interface ListProductsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50). To disable pagination use '-1' value */
  page_size?: number;
  /**
   * Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter
   * @example "sorting=-id,name"
   */
  sorting?: string;
  /**
   * Fields for selection (if not specified, all fields). Use ',' as delimiter
   * @example "attributes=id,name,code"
   */
  attributes?: string;
  /**
   * Fields for total row. Use ',' as delimiter
   * @example "totalAttributes=id,name,code"
   */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  visible?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  activationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  deactivationDate?: string;
  /** Filter value. View field type is [ContractConclusionFlow] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractConclusionFlow?: string;
  /** Filter value. View field type is [WidgetType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  widgetType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface FindFileIdParams {
  type: InsuranceProgramDocumentType;
  /** @default "ua" */
  lang?: string;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetTerminationDocumentConfigurationsParams {
  initiator: TerminationInitiatorCode;
}

export interface GetAllDocumentTypesParams {
  name: string;
}

export interface GetContractDocumentConfigurationByPartnerIdAndInsuranceProgramIdAndDocumentTypeIdParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  id: number;
}

export interface GetContractDocumentConfigurationsParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetClaimDocumentConfigurationByRiskIdAndDocumentTypeParams {
  /** @format int64 */
  riskId: number;
  /** @format int64 */
  id: number;
}

export interface GetClaimDocumentConfigurationsParams {
  /** @format int64 */
  riskId: number;
}

export interface FindTemplateFilePathParams {
  /** @default "ua" */
  lang?: string;
  /** @format int64 */
  documentTemplateId: number;
}

export interface DownloadOfferFileParams {
  documentTypeCode: InsuranceProgramDocumentType;
  /** @format int64 */
  policyId: number;
}

export interface FindDocumentTemplatesParams {
  objectType: InsuranceObjectType;
}

export interface FindResolutionTypesParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface FindRisksByResolutionTypeParams {
  /** @format int64 */
  insuranceProgramId: number;
  resolutionType: ResolutionType;
}

export interface GetResolutionTypeConfigurationsParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface FindDocumentConfigurationParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  riskId: number;
  resolutionType: ResolutionType;
}

export interface DownloadOfferFileByInternetSalesTokenParams {
  /** @default "ua" */
  lang?: string;
  /** @format uuid */
  token: string;
}

export interface DownloadOfferFile1Params {
  /** @default "ua" */
  lang?: string;
  /** @format uuid */
  externalId: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios-next';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://test.eks.inrisk.com.ua:443',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title INRISK Configuration Service
 * @version 0.0.1
 * @baseUrl http://test.eks.inrisk.com.ua:443
 *
 * Configuration Service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  terminationConfigurationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name FindTerminationInitiator
     * @summary find termination initiator
     * @request GET:/termination-configurations/termination-initiators/{id}
     * @secure
     */
    findTerminationInitiator: (id: number, params: RequestParams = {}) =>
      this.request<TerminationInitiatorConfigurationDto, any>({
        path: `/termination-configurations/termination-initiators/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name UpdateTerminationInitiator
     * @summary update termination initiator
     * @request PUT:/termination-configurations/termination-initiators/{id}
     * @secure
     */
    updateTerminationInitiator: (id: number, data: TerminationInitiatorConfigurationDto, params: RequestParams = {}) =>
      this.request<TerminationInitiatorConfigurationDto, any>({
        path: `/termination-configurations/termination-initiators/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name DeleteTerminationInitiator
     * @summary delete termination initiator
     * @request DELETE:/termination-configurations/termination-initiators/{id}
     * @secure
     */
    deleteTerminationInitiator: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/termination-configurations/termination-initiators/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name UpdateDocumentConfigurations
     * @summary update document configurations
     * @request PUT:/termination-configurations/document-configurations
     * @secure
     */
    updateDocumentConfigurations: (
      query: UpdateDocumentConfigurationsParams,
      data: TerminationDocumentConfigurationDto[],
      params: RequestParams = {},
    ) =>
      this.request<TerminationDocumentConfigurationDto[], any>({
        path: `/termination-configurations/document-configurations`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name CreateTerminationDocumentConfigurations
     * @summary create termination document configurations
     * @request POST:/termination-configurations/document-configurations
     * @secure
     */
    createTerminationDocumentConfigurations: (
      query: CreateTerminationDocumentConfigurationsParams,
      data: TerminationDocumentConfigurationDto[],
      params: RequestParams = {},
    ) =>
      this.request<TerminationDocumentConfigurationDto[], any>({
        path: `/termination-configurations/document-configurations`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name UpdateDocumentConfiguration
     * @summary update document configuration
     * @request PUT:/termination-configurations/document-configurations/{id}
     * @secure
     */
    updateDocumentConfiguration: (id: number, data: TerminationDocumentConfigurationDto, params: RequestParams = {}) =>
      this.request<TerminationDocumentConfigurationDto, any>({
        path: `/termination-configurations/document-configurations/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name DeleteDocumentConfiguration
     * @summary delete document configuration
     * @request DELETE:/termination-configurations/document-configurations/{id}
     * @secure
     */
    deleteDocumentConfiguration: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/termination-configurations/document-configurations/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name GetTerminationInitiator
     * @summary get termination initiator
     * @request GET:/termination-configurations/termination-initiators/insurance-programs/{id}
     * @secure
     */
    getTerminationInitiator: ({ id, ...query }: GetTerminationInitiatorParams, params: RequestParams = {}) =>
      this.request<TerminationInitiatorDto, any>({
        path: `/termination-configurations/termination-initiators/insurance-programs/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name CreateTerminationInitiator
     * @summary create termination initiator
     * @request POST:/termination-configurations/termination-initiators/insurance-programs/{id}
     * @secure
     */
    createTerminationInitiator: (id: number, data: TerminationInitiatorConfigurationDto, params: RequestParams = {}) =>
      this.request<TerminationInitiatorConfigurationDto, any>({
        path: `/termination-configurations/termination-initiators/insurance-programs/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags termination-configuration-controller
     * @name GetTerminationInitiators
     * @summary get termination initiators
     * @request GET:/termination-configurations/termination-initiators
     * @secure
     */
    getTerminationInitiators: (params: RequestParams = {}) =>
      this.request<TerminationInitiatorCodePair[], any>({
        path: `/termination-configurations/termination-initiators`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name GetTerminationDocuments
     * @summary get termination documents
     * @request GET:/termination-configurations/termination-documents
     * @secure
     */
    getTerminationDocuments: (query: GetTerminationDocumentsParams, params: RequestParams = {}) =>
      this.request<TerminationDocumentConfigurationDto[], any>({
        path: `/termination-configurations/termination-documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query TerminationCauseView using dynamic search of TableViewRepository
     *
     * @tags termination-configuration-controller
     * @name GetTerminationCauses
     * @summary get termination causes
     * @request GET:/termination-configurations/termination-causes
     * @secure
     */
    getTerminationCauses: (query: GetTerminationCausesParams, params: RequestParams = {}) =>
      this.request<TerminationCauseDto[], any>({
        path: `/termination-configurations/termination-causes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags termination-configuration-controller
     * @name GetTerminationCause
     * @summary get termination cause
     * @request GET:/termination-configurations/termination-cause/{id}
     * @secure
     */
    getTerminationCause: (id: number, params: RequestParams = {}) =>
      this.request<TerminationCauseDto, any>({
        path: `/termination-configurations/termination-cause/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags termination-configuration-controller
     * @name GetAllowedAccountTypes
     * @summary get allowed account types
     * @request GET:/termination-configurations/allowed-account-types
     * @secure
     */
    getAllowedAccountTypes: (params: RequestParams = {}) =>
      this.request<AccountTypeNamePair[], any>({
        path: `/termination-configurations/allowed-account-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM','ROLE_CLIENT', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags termination-configuration-controller
     * @name GetAccountTypes
     * @summary get account types
     * @request GET:/termination-configurations/account-types
     * @secure
     */
    getAccountTypes: (query: GetAccountTypesParams, params: RequestParams = {}) =>
      this.request<AccountTypeCode[], any>({
        path: `/termination-configurations/account-types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  riskController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags risk-controller
     * @name FindById
     * @summary find by id
     * @request GET:/risks/{id}
     * @secure
     */
    findById: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationRiskDto, any>({
        path: `/risks/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags risk-controller
     * @name UpdateRisk
     * @summary update risk
     * @request PUT:/risks/{id}
     * @secure
     */
    updateRisk: (id: number, data: ConfigurationRiskDto, params: RequestParams = {}) =>
      this.request<ConfigurationRiskDto, any>({
        path: `/risks/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags risk-controller
     * @name DeleteRisk
     * @summary delete risk
     * @request DELETE:/risks/{id}
     * @secure
     */
    deleteRisk: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/risks/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags risk-controller
     * @name FindAllRisks
     * @summary find all risks
     * @request GET:/risks
     * @secure
     */
    findAllRisks: (params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/risks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags risk-controller
     * @name CreateRisk
     * @summary create risk
     * @request POST:/risks
     * @secure
     */
    createRisk: (data: ConfigurationRiskDto, params: RequestParams = {}) =>
      this.request<ConfigurationRiskDto, any>({
        path: `/risks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags risk-controller
     * @name FindAllRiskResolutionTypes
     * @summary find all risk resolution types
     * @request GET:/risks/{id}/resolution-types
     * @secure
     */
    findAllRiskResolutionTypes: ({ id, ...query }: FindAllRiskResolutionTypesParams, params: RequestParams = {}) =>
      this.request<ConfigurationRiskResolutionTypeDto[], any>({
        path: `/risks/${id}/resolution-types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query RiskView using dynamic search of TableViewRepository
     *
     * @tags risk-controller
     * @name PrintXlsxReport
     * @summary print xlsx report
     * @request GET:/risks/print/xlsx
     * @secure
     */
    printXlsxReport: (query: PrintXlsxReportParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/risks/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags risk-controller
     * @name FindRiskByCodeAndInsuranceCompanyId
     * @summary find risk by code and insurance company id
     * @request GET:/risks/code/{code}/insurance-company/{insuranceCompanyId}
     * @secure
     */
    findRiskByCodeAndInsuranceCompanyId: (code: string, insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<ConfigurationRiskDto, any>({
        path: `/risks/code/${code}/insurance-company/${insuranceCompanyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags risk-controller
     * @name FindByCode
     * @summary find by code
     * @request GET:/risks/code/{code}
     * @secure
     */
    findByCode: (code: string, params: RequestParams = {}) =>
      this.request<ConfigurationRiskDto, any>({
        path: `/risks/code/${code}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query RiskView using dynamic search of TableViewRepository
     *
     * @tags risk-controller
     * @name List
     * @summary list
     * @request GET:/risks/all
     * @secure
     */
    list: (query: ListParams, params: RequestParams = {}) =>
      this.request<ResultListDtoRiskView, any>({
        path: `/risks/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  promoCodeController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name Get
     * @summary get
     * @request GET:/promo-codes/{id}
     * @secure
     */
    get: (id: number, params: RequestParams = {}) =>
      this.request<PromoCodeDto, any>({
        path: `/promo-codes/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name Update
     * @summary update
     * @request PUT:/promo-codes/{id}
     * @secure
     */
    update: (id: number, data: PromoCodeDto, params: RequestParams = {}) =>
      this.request<PromoCodeDto, any>({
        path: `/promo-codes/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name Delete
     * @summary delete
     * @request DELETE:/promo-codes/{id}
     * @secure
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/promo-codes/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Query PromoCodeView using dynamic search of TableViewRepository
     *
     * @tags promo-code-controller
     * @name List1
     * @summary list
     * @request GET:/promo-codes
     * @secure
     */
    list1: (query: List1Params, params: RequestParams = {}) =>
      this.request<ResultListDtoPromoCodeView, any>({
        path: `/promo-codes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name Create
     * @summary create
     * @request POST:/promo-codes
     * @secure
     */
    create: (data: PromoCodeDto, params: RequestParams = {}) =>
      this.request<PromoCodeDto, any>({
        path: `/promo-codes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name IsPromoCodeValid
     * @summary is promo code valid
     * @request GET:/promo-codes/validate-promo-codes
     * @secure
     */
    isPromoCodeValid: (query: IsPromoCodeValidParams, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/promo-codes/validate-promo-codes`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Query PromoCodeView using dynamic search of TableViewRepository
     *
     * @tags promo-code-controller
     * @name PrintXlsxReport1
     * @summary print xlsx report
     * @request GET:/promo-codes/print/xlsx
     * @secure
     */
    printXlsxReport1: (query: PrintXlsxReport1Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/promo-codes/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name GeneratePromoCode
     * @summary generate promo code
     * @request GET:/promo-codes/generate-promo-codes
     * @secure
     */
    generatePromoCode: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/promo-codes/generate-promo-codes`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name FindPromoCodeCalculation
     * @summary find promo code calculation
     * @request GET:/promo-codes/calculations
     * @secure
     */
    findPromoCodeCalculation: (query: FindPromoCodeCalculationParams, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/promo-codes/calculations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags promo-code-controller
     * @name FindPromoCodeInfo
     * @summary find promo code info
     * @request GET:/promo-codes/calculations/info
     * @secure
     */
    findPromoCodeInfo: (query: FindPromoCodeInfoParams, params: RequestParams = {}) =>
      this.request<PromoCodeCalculationDto, any>({
        path: `/promo-codes/calculations/info`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  prolongationConfigurationController = {
    /**
     * No description
     *
     * @tags prolongation-configuration-controller
     * @name UpdateProlongationConfiguration
     * @summary update prolongation configuration
     * @request PUT:/prolongation-configurations/{prolongationConfigurationId}
     * @secure
     */
    updateProlongationConfiguration: (
      prolongationConfigurationId: number,
      data: ProlongationConfigurationDto,
      params: RequestParams = {},
    ) =>
      this.request<ProlongationConfigurationDto, any>({
        path: `/prolongation-configurations/${prolongationConfigurationId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prolongation-configuration-controller
     * @name CreateProlongationConfiguration
     * @summary create prolongation configuration
     * @request POST:/prolongation-configurations/insurance-programs/{insuranceProgramId}
     * @secure
     */
    createProlongationConfiguration: (
      insuranceProgramId: number,
      data: ProlongationConfigurationDto,
      params: RequestParams = {},
    ) =>
      this.request<ProlongationConfigurationDto, any>({
        path: `/prolongation-configurations/insurance-programs/${insuranceProgramId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  insuranceProgramRiskController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-risk-controller
     * @name GetInsuranceProgramRisk
     * @summary get insurance program risk
     * @request GET:/program-risks/{id}
     * @secure
     */
    getInsuranceProgramRisk: (id: number, params: RequestParams = {}) =>
      this.request<ProgramRiskDto, any>({
        path: `/program-risks/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-risk-controller
     * @name UpdateInsuranceProgramRisk
     * @summary update insurance program risk
     * @request PUT:/program-risks/{id}
     * @secure
     */
    updateInsuranceProgramRisk: (id: number, data: ProgramRiskDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/program-risks/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-risk-controller
     * @name DeleteInsuranceProgramRisk
     * @summary delete insurance program risk
     * @request DELETE:/program-risks/{id}
     * @secure
     */
    deleteInsuranceProgramRisk: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/program-risks/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-risk-controller
     * @name CreateInsuranceProgramRisk
     * @summary create insurance program risk
     * @request POST:/program-risks
     * @secure
     */
    createInsuranceProgramRisk: (
      query: CreateInsuranceProgramRiskParams,
      data: ProgramRiskDto,
      params: RequestParams = {},
    ) =>
      this.request<ProgramRiskDto, any>({
        path: `/program-risks`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  paymentSystemController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags payment-system-controller
     * @name Update1
     * @summary update
     * @request PUT:/payment-systems/{id}
     * @secure
     */
    update1: (id: number, data: PaymentSystemConfigurationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payment-systems/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags payment-system-controller
     * @name FindAllPaymentSystemConfigurations
     * @summary find all payment system configurations
     * @request GET:/payment-systems
     * @secure
     */
    findAllPaymentSystemConfigurations: (query: FindAllPaymentSystemConfigurationsParams, params: RequestParams = {}) =>
      this.request<PaymentSystemConfigurationDto[], any>({
        path: `/payment-systems`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags payment-system-controller
     * @name Create1
     * @summary create
     * @request POST:/payment-systems
     * @secure
     */
    create1: (data: PaymentSystemConfigurationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payment-systems`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags payment-system-controller
     * @name GetPaymentSystemCredentialsDto
     * @summary get payment system credentials dto
     * @request GET:/payment-systems/credentials
     * @secure
     */
    getPaymentSystemCredentialsDto: (query: GetPaymentSystemCredentialsDtoParams, params: RequestParams = {}) =>
      this.request<ConfigurationPaymentSystemCredentialsDto, any>({
        path: `/payment-systems/credentials`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags payment-system-controller
     * @name GetPaymentSystemConfiguration
     * @summary get payment system configuration
     * @request GET:/payment-systems/configurations
     * @secure
     */
    getPaymentSystemConfiguration: (query: GetPaymentSystemConfigurationParams, params: RequestParams = {}) =>
      this.request<PaymentSystemConfigurationDto, any>({
        path: `/payment-systems/configurations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  partnerProgramController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name Read
     * @summary read
     * @request GET:/partner-programs/{id}
     * @secure
     */
    read: (id: number, params: RequestParams = {}) =>
      this.request<PartnerProgramDto, any>({
        path: `/partner-programs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name Update2
     * @summary update
     * @request PUT:/partner-programs/{id}
     * @secure
     */
    update2: (id: number, data: SavePartnerProgramRequestDto, params: RequestParams = {}) =>
      this.request<PartnerProgramDto, any>({
        path: `/partner-programs/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name FindByPartnerId
     * @summary find by partner id
     * @request GET:/partner-programs/partners/{id}
     * @secure
     */
    findByPartnerId: (id: number, params: RequestParams = {}) =>
      this.request<PartnerProgramDto[], any>({
        path: `/partner-programs/partners/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name UpdatePartnerProgramsByPartnerId
     * @summary update partner programs by partner id
     * @request PUT:/partner-programs/partners/{id}
     * @secure
     */
    updatePartnerProgramsByPartnerId: (id: number, data: PartnerProgramDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partner-programs/partners/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name AvailableAgentList
     * @summary available agent list
     * @request POST:/partner-programs/agents
     * @secure
     */
    availableAgentList: (data: number[], params: RequestParams = {}) =>
      this.request<PartnerProgramAgentDto[], any>({
        path: `/partner-programs/agents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name FindAllByInsuranceProgramId
     * @summary find all by insurance program id
     * @request GET:/partner-programs
     * @secure
     */
    findAllByInsuranceProgramId: (query: FindAllByInsuranceProgramIdParams, params: RequestParams = {}) =>
      this.request<PartnerProgramDto[], any>({
        path: `/partner-programs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name SavePartnerProgramAndDepartmentPrograms
     * @summary save partner program and department programs
     * @request POST:/partner-programs
     * @secure
     */
    savePartnerProgramAndDepartmentPrograms: (data: SavePartnerProgramRequestDto, params: RequestParams = {}) =>
      this.request<PartnerProgramDto, any>({
        path: `/partner-programs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name FindByPartnerIdAndInsuranceProgramId
     * @summary find by partner id and insurance program id
     * @request GET:/partner-programs/partners/{partnerId}/insurance-programs/{insuranceProgramId}
     * @secure
     */
    findByPartnerIdAndInsuranceProgramId: (partnerId: number, insuranceProgramId: number, params: RequestParams = {}) =>
      this.request<PartnerProgramDto, any>({
        path: `/partner-programs/partners/${partnerId}/insurance-programs/${insuranceProgramId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name GetMaxAgentContractCommission
     * @summary get max agent contract commission
     * @request GET:/partner-programs/partners/{partnerId}/agents
     * @secure
     */
    getMaxAgentContractCommission: (
      { partnerId, ...query }: GetMaxAgentContractCommissionParams,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/partner-programs/partners/${partnerId}/agents`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name FindRoundingTypes
     * @summary find rounding types
     * @request GET:/partner-programs/partners/{id}/rounding-types
     * @secure
     */
    findRoundingTypes: ({ id, ...query }: FindRoundingTypesParams, params: RequestParams = {}) =>
      this.request<RoundingTypePair[], any>({
        path: `/partner-programs/partners/${id}/rounding-types`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name FindMaxPartnerProgramEndDate
     * @summary find max partner program end date
     * @request GET:/partner-programs/partners/{id}/end-dates
     * @secure
     */
    findMaxPartnerProgramEndDate: (id: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/partner-programs/partners/${id}/end-dates`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name FindContractDraftStoragePeriodTypes
     * @summary find contract draft storage period types
     * @request GET:/partner-programs/partners/{id}/contract-draft-storage-period-types
     * @secure
     */
    findContractDraftStoragePeriodTypes: (
      { id, ...query }: FindContractDraftStoragePeriodTypesParams,
      params: RequestParams = {},
    ) =>
      this.request<ContractDraftStoragePeriodTypePair[], any>({
        path: `/partner-programs/partners/${id}/contract-draft-storage-period-types`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name FindContractFormatNumbers
     * @summary find contract format numbers
     * @request GET:/partner-programs/partners/contract-number-formats
     * @secure
     */
    findContractFormatNumbers: (params: RequestParams = {}) =>
      this.request<PairContractNumberFormatString[], any>({
        path: `/partner-programs/partners/contract-number-formats`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_SELLER')
     *
     * @tags partner-program-controller
     * @name FindContractNumberFormat
     * @summary find contract number format
     * @request GET:/partner-programs/contract-number-formats
     * @secure
     */
    findContractNumberFormat: (query: FindContractNumberFormatParams, params: RequestParams = {}) =>
      this.request<ContractNumberFormat, any>({
        path: `/partner-programs/contract-number-formats`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-program-controller
     * @name PartnerProgramAgentExistsByAgentId
     * @summary partner program agent exists by agent id
     * @request GET:/partner-programs/agents/{id}
     * @secure
     */
    partnerProgramAgentExistsByAgentId: (id: number, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/partner-programs/agents/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  partnerDiscountController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-discount-controller
     * @name GetPartnerDiscounts
     * @summary get partner discounts
     * @request GET:/partner-discounts/partners/{id}
     * @secure
     */
    getPartnerDiscounts: (id: number, params: RequestParams = {}) =>
      this.request<PartnerDiscountDto[], any>({
        path: `/partner-discounts/partners/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-discount-controller
     * @name UpdatePartnerDiscount
     * @summary update partner discount
     * @request PUT:/partner-discounts/partners/{id}
     * @secure
     */
    updatePartnerDiscount: (id: number, data: PartnerDiscountDto[], params: RequestParams = {}) =>
      this.request<PartnerDiscountDto[], any>({
        path: `/partner-discounts/partners/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-discount-controller
     * @name CreatePartnerDiscount
     * @summary create partner discount
     * @request POST:/partner-discounts/partners/{id}
     * @secure
     */
    createPartnerDiscount: (id: number, data: PartnerDiscountDto[], params: RequestParams = {}) =>
      this.request<PartnerDiscountDto[], any>({
        path: `/partner-discounts/partners/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-discount-controller
     * @name GetPartnerDiscountUnits
     * @summary get partner discount units
     * @request GET:/partner-discounts/units
     * @secure
     */
    getPartnerDiscountUnits: (query: GetPartnerDiscountUnitsParams, params: RequestParams = {}) =>
      this.request<DiscountUnit[], any>({
        path: `/partner-discounts/units`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  partnerDepartmentsController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-departments-controller
     * @name UpdatePartnerDepartmentConfiguration
     * @summary update partner department configuration
     * @request PUT:/partner-departments/configurations/{id}
     * @secure
     */
    updatePartnerDepartmentConfiguration: (
      id: number,
      data: PartnerDepartmentConfigurationDto,
      params: RequestParams = {},
    ) =>
      this.request<PartnerDepartmentConfigurationDto, any>({
        path: `/partner-departments/configurations/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-departments-controller
     * @name CreatePartnerDepartmentConfiguration
     * @summary create partner department configuration
     * @request POST:/partner-departments/configurations
     * @secure
     */
    createPartnerDepartmentConfiguration: (data: PartnerDepartmentConfigurationDto, params: RequestParams = {}) =>
      this.request<PartnerDepartmentConfigurationDto, any>({
        path: `/partner-departments/configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags partner-departments-controller
     * @name GetPartnerDepartmentConfiguration
     * @summary get partner department configuration
     * @request GET:/partner-departments/{id}/configurations
     * @secure
     */
    getPartnerDepartmentConfiguration: (id: number, params: RequestParams = {}) =>
      this.request<PartnerDepartmentConfigurationDto, any>({
        path: `/partner-departments/${id}/configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  notificationsConfigurationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags notifications-configuration-controller
     * @name Update3
     * @summary update
     * @request PUT:/notifications-configuration/{id}
     * @secure
     */
    update3: (
      id: number,
      data: {
        /** @format binary */
        file: File;
        configurationDto: EmailNotificationConfigurationDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmailNotificationConfigurationDto, any>({
        path: `/notifications-configuration/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags notifications-configuration-controller
     * @name Delete1
     * @summary delete
     * @request DELETE:/notifications-configuration/{id}
     * @secure
     */
    delete1: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications-configuration/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags notifications-configuration-controller
     * @name FindByPartnerProgramIdAndType
     * @summary find by partner program id and type
     * @request GET:/notifications-configuration
     * @secure
     */
    findByPartnerProgramIdAndType: (query: FindByPartnerProgramIdAndTypeParams, params: RequestParams = {}) =>
      this.request<EmailNotificationConfigurationDto, any>({
        path: `/notifications-configuration`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags notifications-configuration-controller
     * @name Create2
     * @summary create
     * @request POST:/notifications-configuration
     * @secure
     */
    create2: (
      data: {
        /** @format binary */
        file: File;
        configurationDto: EmailNotificationConfigurationDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<EmailNotificationConfigurationDto, any>({
        path: `/notifications-configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  mtsbuConfigurationController = {
    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name UpdateMtsbuConfiguration
     * @summary update mtsbu configuration
     * @request PUT:/mtsbu-configuration/{id}
     * @secure
     */
    updateMtsbuConfiguration: (id: number, data: MtsbuCoefficientsRegistryDto, params: RequestParams = {}) =>
      this.request<MtsbuCoefficientsRegistryDto, any>({
        path: `/mtsbu-configuration/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags mtsbu-configuration-controller
     * @name UpdateCityAndZone
     * @summary update city and zone
     * @request PUT:/mtsbu-configuration/city-zone/{id}
     * @secure
     */
    updateCityAndZone: (id: number, data: CityZoneDto, params: RequestParams = {}) =>
      this.request<CityZoneDto, any>({
        path: `/mtsbu-configuration/city-zone/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name GetConfiguration
     * @summary get configuration
     * @request GET:/mtsbu-configuration
     * @secure
     */
    getConfiguration: (params: RequestParams = {}) =>
      this.request<MtsbuConfigurationDto, any>({
        path: `/mtsbu-configuration`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name CreateMtsbuConfiguration
     * @summary create mtsbu configuration
     * @request POST:/mtsbu-configuration
     * @secure
     */
    createMtsbuConfiguration: (data: MtsbuCoefficientsRegistryDto, params: RequestParams = {}) =>
      this.request<MtsbuCoefficientsRegistryDto, any>({
        path: `/mtsbu-configuration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name GetLimitsByInsuranceProgramAndTypes
     * @summary get limits by insurance program and types
     * @request POST:/mtsbu-configuration/limits/{id}
     * @secure
     */
    getLimitsByInsuranceProgramAndTypes: (
      id: number,
      data: OsagoContractCalculationRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<ContractMtsbuCoefficientsDto, any>({
        path: `/mtsbu-configuration/limits/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags mtsbu-configuration-controller
     * @name CreateCityAndZone
     * @summary create city and zone
     * @request POST:/mtsbu-configuration/city-zone
     * @secure
     */
    createCityAndZone: (data: CityZoneDto, params: RequestParams = {}) =>
      this.request<CityZoneDto, any>({
        path: `/mtsbu-configuration/city-zone`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name GetVehicleUsageScopes
     * @summary get vehicle usage scopes
     * @request GET:/mtsbu-configuration/vehicle-usage-scope
     * @secure
     */
    getVehicleUsageScopes: (query: GetVehicleUsageScopesParams, params: RequestParams = {}) =>
      this.request<MtsbuCoefficientsRegistryDto[], any>({
        path: `/mtsbu-configuration/vehicle-usage-scope`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name GetTypesByGroup
     * @summary get types by group
     * @request GET:/mtsbu-configuration/types
     * @secure
     */
    getTypesByGroup: (query: GetTypesByGroupParams, params: RequestParams = {}) =>
      this.request<MtsbuCoefficientsRegistryDto[], any>({
        path: `/mtsbu-configuration/types`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name GetLimitsByGroupAndType
     * @summary get limits by group and type
     * @request GET:/mtsbu-configuration/limits
     * @secure
     */
    getLimitsByGroupAndType: (query: GetLimitsByGroupAndTypeParams, params: RequestParams = {}) =>
      this.request<MtsbuCoefficientsRegistryDto, any>({
        path: `/mtsbu-configuration/limits`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-configuration-controller
     * @name GetAllRegistrationCitiesAndZones
     * @summary get all registration cities and zones
     * @request GET:/mtsbu-configuration/city-zone/all
     * @secure
     */
    getAllRegistrationCitiesAndZones: (params: RequestParams = {}) =>
      this.request<CityZoneDto[], any>({
        path: `/mtsbu-configuration/city-zone/all`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  internetSalesController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags internet-sales-controller
     * @name Read1
     * @summary read
     * @request GET:/internet-sales/{id}
     * @secure
     */
    read1: (id: number, params: RequestParams = {}) =>
      this.request<InternetSalesDto, any>({
        path: `/internet-sales/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags internet-sales-controller
     * @name Update4
     * @summary update
     * @request PUT:/internet-sales/{id}
     * @secure
     */
    update4: (id: number, data: CreateUpdateInternetSalesDto, params: RequestParams = {}) =>
      this.request<InternetSalesDto, any>({
        path: `/internet-sales/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags internet-sales-controller
     * @name Delete2
     * @summary delete
     * @request DELETE:/internet-sales/{id}
     * @secure
     */
    delete2: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/internet-sales/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Query InternetSalesView using dynamic search of TableViewRepository
     *
     * @tags internet-sales-controller
     * @name List2
     * @summary list
     * @request GET:/internet-sales
     * @secure
     */
    list2: (query: List2Params, params: RequestParams = {}) =>
      this.request<ResultListDtoInternetSalesView, any>({
        path: `/internet-sales`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags internet-sales-controller
     * @name Create3
     * @summary create
     * @request POST:/internet-sales
     * @secure
     */
    create3: (data: CreateUpdateInternetSalesDto, params: RequestParams = {}) =>
      this.request<InternetSalesDto, any>({
        path: `/internet-sales`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags internet-sales-controller
     * @name FindByToken
     * @summary find by token
     * @request GET:/internet-sales/token/{token}
     * @secure
     */
    findByToken: (token: string, params: RequestParams = {}) =>
      this.request<InternetSalesDto, any>({
        path: `/internet-sales/token/${token}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  insuranceTypeController = {
    /**
     * @description Security requirements: hasPermission('KIND_OF_INSURANCE_AND_RISK_REGISTRY', 'update')
     *
     * @tags insurance-type-controller
     * @name UpdateInsuranceType
     * @summary update insurance type
     * @request PUT:/insurance-types/{id}
     * @secure
     */
    updateInsuranceType: (id: number, data: ConfigurationInsuranceTypeDto, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceTypeDto, any>({
        path: `/insurance-types/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('KIND_OF_INSURANCE_AND_RISK_REGISTRY', 'delete')
     *
     * @tags insurance-type-controller
     * @name DeleteInsuranceType
     * @summary delete insurance type
     * @request DELETE:/insurance-types/{id}
     * @secure
     */
    deleteInsuranceType: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-types/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasRole('ROLE_PARTNER_ASSISTANCE') or hasPermission('KIND_OF_INSURANCE_AND_RISK_REGISTRY', 'read')
     *
     * @tags insurance-type-controller
     * @name ListInsuranceTypes
     * @summary list insurance types
     * @request GET:/insurance-types
     * @secure
     */
    listInsuranceTypes: (params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceTypeDto[], any>({
        path: `/insurance-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('KIND_OF_INSURANCE_AND_RISK_REGISTRY', 'create')
     *
     * @tags insurance-type-controller
     * @name CreateInsuranceType
     * @summary create insurance type
     * @request POST:/insurance-types
     * @secure
     */
    createInsuranceType: (data: ConfigurationInsuranceTypeDto, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceTypeDto, any>({
        path: `/insurance-types`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query InsuranceTypeView using dynamic search of TableViewRepository
     *
     * @tags insurance-type-controller
     * @name PrintXlsxReport2
     * @summary print xlsx report
     * @request GET:/insurance-types/print/xlsx
     * @secure
     */
    printXlsxReport2: (query: PrintXlsxReport2Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/insurance-types/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Query InsuranceTypeView using dynamic search of TableViewRepository
     *
     * @tags insurance-type-controller
     * @name List3
     * @summary list
     * @request GET:/insurance-types/all
     * @secure
     */
    list3: (query: List3Params, params: RequestParams = {}) =>
      this.request<ResultListDtoInsuranceTypeView, any>({
        path: `/insurance-types/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  insuranceRateController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rate-controller
     * @name FindInsuranceRate
     * @summary find insurance rate
     * @request GET:/insurance-rates/{id}
     * @secure
     */
    findInsuranceRate: (id: number, params: RequestParams = {}) =>
      this.request<InsuranceRateDto, any>({
        path: `/insurance-rates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rate-controller
     * @name UpdateInsuranceRate
     * @summary update insurance rate
     * @request PUT:/insurance-rates/{id}
     * @secure
     */
    updateInsuranceRate: (id: number, data: InsuranceRateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-rates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rate-controller
     * @name DeleteInsuranceRate
     * @summary delete insurance rate
     * @request DELETE:/insurance-rates/{id}
     * @secure
     */
    deleteInsuranceRate: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-rates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rate-controller
     * @name CreateInsuranceRate
     * @summary create insurance rate
     * @request POST:/insurance-rates/insurance-policies/{insurancePolicyId}
     * @secure
     */
    createInsuranceRate: (insurancePolicyId: number, data: InsuranceRateDto, params: RequestParams = {}) =>
      this.request<InsuranceRateDto, any>({
        path: `/insurance-rates/insurance-policies/${insurancePolicyId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rate-controller
     * @name FindInsuranceRateByDurationAndPolicyId
     * @summary find insurance rate by duration and policy id
     * @request GET:/insurance-rates/{duration}/{policyId}
     * @secure
     */
    findInsuranceRateByDurationAndPolicyId: (duration: number, policyId: number, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceRateDto, any>({
        path: `/insurance-rates/${duration}/${policyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rate-controller
     * @name FindInsuranceRateByInsuranceSumId
     * @summary find insurance rate by insurance sum id
     * @request GET:/insurance-rates/insurance-sums/{insuranceSumId}
     * @secure
     */
    findInsuranceRateByInsuranceSumId: (insuranceSumId: number, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceRateDto, any>({
        path: `/insurance-rates/insurance-sums/${insuranceSumId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_SELLER')
     *
     * @tags insurance-rate-controller
     * @name FindInsuranceRateCurrencies
     * @summary find insurance rate currencies
     * @request GET:/insurance-rates/currencies/insurance-policies/{insurancePolicyId}
     * @secure
     */
    findInsuranceRateCurrencies: (insurancePolicyId: number, params: RequestParams = {}) =>
      this.request<Currency[], any>({
        path: `/insurance-rates/currencies/insurance-policies/${insurancePolicyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rate-controller
     * @name DeleteInsuranceRateSumOrInsuranceRate
     * @summary delete insurance rate sum or insurance rate
     * @request DELETE:/insurance-rates/insurance-rate-sums/{id}
     * @secure
     */
    deleteInsuranceRateSumOrInsuranceRate: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-rates/insurance-rate-sums/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  insuranceProgramController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name GetInsuranceProgram
     * @summary get insurance program
     * @request GET:/insurance-programs/{id}
     * @secure
     */
    getInsuranceProgram: ({ id, ...query }: GetInsuranceProgramParams, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto, any>({
        path: `/insurance-programs/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name UpdateInsuranceProgram
     * @summary update insurance program
     * @request PUT:/insurance-programs/{id}
     * @secure
     */
    updateInsuranceProgram: (id: number, data: InsuranceProgramDto, params: RequestParams = {}) =>
      this.request<InsuranceProgramDto, any>({
        path: `/insurance-programs/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindRateForProlongation
     * @summary find rate for prolongation
     * @request POST:/insurance-programs/{id}/insurance-rates/prolongation
     * @secure
     */
    findRateForProlongation: (
      { id, ...query }: FindRateForProlongationParams,
      data: Duration,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationInsuranceRateDto, any>({
        path: `/insurance-programs/${id}/insurance-rates/prolongation`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindDepreciationRate
     * @summary find depreciation rate
     * @request POST:/insurance-programs/{id}/depreciation-rates
     * @secure
     */
    findDepreciationRate: (id: number, data: DepreciationRateRequestDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/insurance-programs/${id}/depreciation-rates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name GetInsuranceProgramsByTenant
     * @summary get insurance programs by tenant
     * @request POST:/insurance-programs/tenant
     * @secure
     */
    getInsuranceProgramsByTenant: (data: TenantDto, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto[], any>({
        path: `/insurance-programs/tenant`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindInsuranceRateByDurationAndInsurancePolicyId
     * @summary find insurance rate by duration and insurance policy id
     * @request POST:/insurance-programs/insurance-policies/{insurancePolicyId}/rates
     * @secure
     */
    findInsuranceRateByDurationAndInsurancePolicyId: (
      insurancePolicyId: number,
      data: Duration,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationInsuranceRateDto, any>({
        path: `/insurance-programs/insurance-policies/${insurancePolicyId}/rates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindOrdinalInsuranceRateByDurationAndInsurancePolicyId
     * @summary find ordinal insurance rate by duration and insurance policy id
     * @request POST:/insurance-programs/insurance-policies/ordinal/rates
     * @secure
     */
    findOrdinalInsuranceRateByDurationAndInsurancePolicyId: (
      query: FindOrdinalInsuranceRateByDurationAndInsurancePolicyIdParams,
      data: Duration,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationInsuranceRateDto, any>({
        path: `/insurance-programs/insurance-policies/ordinal/rates`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindFranchises
     * @summary find franchises
     * @request POST:/insurance-programs/franchises
     * @secure
     */
    findFranchises: (data: number[], params: RequestParams = {}) =>
      this.request<number[], any>({
        path: `/insurance-programs/franchises`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name ListDurationsByInsuranceProgram
     * @summary list durations by insurance program
     * @request GET:/insurance-programs/durations
     * @secure
     */
    listDurationsByInsuranceProgram: (query: ListDurationsByInsuranceProgramParams, params: RequestParams = {}) =>
      this.request<Duration[], any>({
        path: `/insurance-programs/durations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name ListActivePolicyDurationsByInsurancePrograms
     * @summary list active policy durations by insurance programs
     * @request POST:/insurance-programs/durations
     * @secure
     */
    listActivePolicyDurationsByInsurancePrograms: (data: number[], params: RequestParams = {}) =>
      this.request<Duration[], any>({
        path: `/insurance-programs/durations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Query InsuranceProgramView using dynamic search of TableViewRepository
     *
     * @tags insurance-program-controller
     * @name List4
     * @summary list
     * @request GET:/insurance-programs
     * @secure
     */
    list4: (query: List4Params, params: RequestParams = {}) =>
      this.request<ResultListDtoInsuranceProgramView, any>({
        path: `/insurance-programs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name CreateInsuranceProgram
     * @summary create insurance program
     * @request POST:/insurance-programs
     * @secure
     */
    createInsuranceProgram: (data: InsuranceProgramDto, params: RequestParams = {}) =>
      this.request<InsuranceProgramDto, any>({
        path: `/insurance-programs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindTerminationInitiators
     * @summary find termination initiators
     * @request GET:/insurance-programs/{id}/termination-initiators
     * @secure
     */
    findTerminationInitiators: (id: number, params: RequestParams = {}) =>
      this.request<TerminationInitiatorDto[], any>({
        path: `/insurance-programs/${id}/termination-initiators`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindInsuranceProgramSmsConfigurationByInsuranceProgramId
     * @summary find insurance program sms configuration by insurance program id
     * @request GET:/insurance-programs/{id}/sms-configuration
     * @secure
     */
    findInsuranceProgramSmsConfigurationByInsuranceProgramId: (id: number, params: RequestParams = {}) =>
      this.request<InsuranceProgramSmsConfigurationDto, any>({
        path: `/insurance-programs/${id}/sms-configuration`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindRoundTypes
     * @summary find round types
     * @request GET:/insurance-programs/{id}/rounding-types
     * @secure
     */
    findRoundTypes: (id: number, params: RequestParams = {}) =>
      this.request<RoundingTypePair[], any>({
        path: `/insurance-programs/${id}/rounding-types`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_CLIENT','ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name ListRisks
     * @summary list risks
     * @request GET:/insurance-programs/{id}/risks
     * @secure
     */
    listRisks: (id: number, params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/insurance-programs/${id}/risks`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name ListDurationsByPartnerProgramId
     * @summary list durations by partner program id
     * @request GET:/insurance-programs/{id}/online-contract-durations
     * @secure
     */
    listDurationsByPartnerProgramId: (
      { id, ...query }: ListDurationsByPartnerProgramIdParams,
      params: RequestParams = {},
    ) =>
      this.request<Duration[], any>({
        path: `/insurance-programs/${id}/online-contract-durations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindAllPolicies
     * @summary find all policies
     * @request GET:/insurance-programs/{id}/insurance-policies/rates
     * @secure
     */
    findAllPolicies: ({ id, ...query }: FindAllPoliciesParams, params: RequestParams = {}) =>
      this.request<ConfigurationInsurancePolicyDto[], any>({
        path: `/insurance-programs/${id}/insurance-policies/rates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindInsurancePolicy
     * @summary find insurance policy
     * @request GET:/insurance-programs/{id}/insurance-policies
     * @secure
     */
    findInsurancePolicy: ({ id, ...query }: FindInsurancePolicyParams, params: RequestParams = {}) =>
      this.request<ConfigurationInsurancePolicyDto, any>({
        path: `/insurance-programs/${id}/insurance-policies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindProgramInstallmentPaymentInstallmentPaymentPeriodType
     * @summary find program installment payment installment payment period type
     * @request GET:/insurance-programs/{id}/installment-payment-types/{installmentPaymentPeriodType}
     * @secure
     */
    findProgramInstallmentPaymentInstallmentPaymentPeriodType: (
      id: number,
      installmentPaymentPeriodType: InstallmentPaymentPeriodType,
      params: RequestParams = {},
    ) =>
      this.request<ProgramInstallmentPaymentDto, any>({
        path: `/insurance-programs/${id}/installment-payment-types/${installmentPaymentPeriodType}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindAllInitiatorCausesByInsuranceProgramId
     * @summary find all initiator causes by insurance program id
     * @request GET:/insurance-programs/{id}/initiator-causes/{terminationInitiatorCode}
     * @secure
     */
    findAllInitiatorCausesByInsuranceProgramId: (
      id: number,
      terminationInitiatorCode: TerminationInitiatorCode,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationInitiatorCauseDto[], any>({
        path: `/insurance-programs/${id}/initiator-causes/${terminationInitiatorCode}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindAllInitiatorCausesByInsuranceProgramId1
     * @summary find all initiator causes by insurance program id
     * @request GET:/insurance-programs/{id}/initiator-causes
     * @secure
     */
    findAllInitiatorCausesByInsuranceProgramId1: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationInitiatorCauseDto[], any>({
        path: `/insurance-programs/${id}/initiator-causes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindInsuranceProgramFranchises
     * @summary find insurance program franchises
     * @request GET:/insurance-programs/{id}/franchises
     * @secure
     */
    findInsuranceProgramFranchises: (id: number, params: RequestParams = {}) =>
      this.request<number[], any>({
        path: `/insurance-programs/${id}/franchises`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name ListDurations
     * @summary list durations
     * @request GET:/insurance-programs/{id}/durations
     * @secure
     */
    listDurations: ({ id, ...query }: ListDurationsParams, params: RequestParams = {}) =>
      this.request<Duration[], any>({
        path: `/insurance-programs/${id}/durations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindContractDraftStoragePeriodTypes1
     * @summary find contract draft storage period types
     * @request GET:/insurance-programs/{id}/contract-draft-storage-period-types
     * @secure
     */
    findContractDraftStoragePeriodTypes1: (id: number, params: RequestParams = {}) =>
      this.request<ContractDraftStoragePeriodTypePair[], any>({
        path: `/insurance-programs/${id}/contract-draft-storage-period-types`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindAllClaimInfoFieldConfigurations
     * @summary find all claim info field configurations
     * @request GET:/insurance-programs/{id}/claim-field-configurations
     * @secure
     */
    findAllClaimInfoFieldConfigurations: (id: number, params: RequestParams = {}) =>
      this.request<ClaimInfoFieldConfigurationDto[], any>({
        path: `/insurance-programs/${id}/claim-field-configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query InsuranceRuleView using dynamic search of TableViewRepository
     *
     * @tags insurance-program-controller
     * @name ListRules
     * @summary list rules
     * @request GET:/insurance-programs/rules
     * @secure
     */
    listRules: (query: ListRulesParams, params: RequestParams = {}) =>
      this.request<ResultListDtoInsuranceRuleView, any>({
        path: `/insurance-programs/rules`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name GetInsuranceProgramsByPartnerCompanyAndProduct
     * @summary get insurance programs by partner company and product
     * @request GET:/insurance-programs/partner/insurance-company/product-code
     * @secure
     */
    getInsuranceProgramsByPartnerCompanyAndProduct: (
      query: GetInsuranceProgramsByPartnerCompanyAndProductParams,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationInsuranceProgramDto[], any>({
        path: `/insurance-programs/partner/insurance-company/product-code`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindInsurancePolicyByInternetSalesToken
     * @summary find insurance policy by internet sales token
     * @request GET:/insurance-programs/insurance-policies/{internet-sales-token}
     * @secure
     */
    findInsurancePolicyByInternetSalesToken: (internetSalesToken: string, params: RequestParams = {}) =>
      this.request<ConfigurationInsurancePolicyDto, any>({
        path: `/insurance-programs/insurance-policies/${internetSalesToken}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name GetInsuranceProgramsByInsuranceCompanyId
     * @summary get insurance programs by insurance company id
     * @request GET:/insurance-programs/insurance-company/{id}
     * @secure
     */
    getInsuranceProgramsByInsuranceCompanyId: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto[], any>({
        path: `/insurance-programs/insurance-company/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name FindInsuranceProgramIdsByCodeIn
     * @summary find insurance program ids by code in
     * @request GET:/insurance-programs/ids
     * @secure
     */
    findInsuranceProgramIdsByCodeIn: (query: FindInsuranceProgramIdsByCodeInParams, params: RequestParams = {}) =>
      this.request<number[], any>({
        path: `/insurance-programs/ids`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name GetInsuranceProgramByEwaId
     * @summary get insurance program by ewa id
     * @request GET:/insurance-programs/ewa/{ewaId}
     * @secure
     */
    getInsuranceProgramByEwaId: (ewaId: number, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto, any>({
        path: `/insurance-programs/ewa/${ewaId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query InsuranceProgramView using dynamic search of TableViewRepository
     *
     * @tags insurance-program-controller
     * @name ListDetails
     * @summary list details
     * @request GET:/insurance-programs/details
     * @secure
     */
    listDetails: (query: ListDetailsParams, params: RequestParams = {}) =>
      this.request<InsuranceProgramDetailsDto[], any>({
        path: `/insurance-programs/details`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name GetDetailsForProlongation
     * @summary get details for prolongation
     * @request GET:/insurance-programs/details/prolongation
     * @secure
     */
    getDetailsForProlongation: (query: GetDetailsForProlongationParams, params: RequestParams = {}) =>
      this.request<InsuranceProgramDetailsDto[], any>({
        path: `/insurance-programs/details/prolongation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name Copy
     * @summary copy
     * @request GET:/insurance-programs/copy/{insuranceProgramId}
     * @secure
     */
    copy: (insuranceProgramId: number, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto, any>({
        path: `/insurance-programs/copy/${insuranceProgramId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-program-controller
     * @name GetInsuranceProgramByCode
     * @summary get insurance program by code
     * @request GET:/insurance-programs/by-code/{code}
     * @secure
     */
    getInsuranceProgramByCode: ({ code, ...query }: GetInsuranceProgramByCodeParams, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto, any>({
        path: `/insurance-programs/by-code/${code}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  programMtsbuConfigurationController = {
    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name GetVehicleUsageScopes1
     * @summary get vehicle usage scopes
     * @request GET:/insurance-program/configuration/mtsbu/vehicle-usage-scope/{insuranceProgramId}
     * @secure
     */
    getVehicleUsageScopes1: (
      { insuranceProgramId, ...query }: GetVehicleUsageScopes1Params,
      params: RequestParams = {},
    ) =>
      this.request<CoefficientDto[], any>({
        path: `/insurance-program/configuration/mtsbu/vehicle-usage-scope/${insuranceProgramId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name UpdateVehicleUsageScopes
     * @summary update vehicle usage scopes
     * @request PUT:/insurance-program/configuration/mtsbu/vehicle-usage-scope/{insuranceProgramId}
     * @secure
     */
    updateVehicleUsageScopes: (insuranceProgramId: number, data: CoefficientDto[], params: RequestParams = {}) =>
      this.request<CoefficientDto[], any>({
        path: `/insurance-program/configuration/mtsbu/vehicle-usage-scope/${insuranceProgramId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name CreateVehicleUsageScopes
     * @summary create vehicle usage scopes
     * @request POST:/insurance-program/configuration/mtsbu/vehicle-usage-scope/{insuranceProgramId}
     * @secure
     */
    createVehicleUsageScopes: (
      { insuranceProgramId, ...query }: CreateVehicleUsageScopesParams,
      data: CoefficientDto[],
      params: RequestParams = {},
    ) =>
      this.request<CoefficientDto[], any>({
        path: `/insurance-program/configuration/mtsbu/vehicle-usage-scope/${insuranceProgramId}`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name UpdateInsureActivityCoefficients
     * @summary update insure activity coefficients
     * @request PUT:/insurance-program/configuration/mtsbu/insure-activity
     * @secure
     */
    updateInsureActivityCoefficients: (
      query: UpdateInsureActivityCoefficientsParams,
      data: CoefficientDto[],
      params: RequestParams = {},
    ) =>
      this.request<CoefficientDto[], any>({
        path: `/insurance-program/configuration/mtsbu/insure-activity`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name GetInsureActivityCoefficients
     * @summary get insure activity coefficients
     * @request GET:/insurance-program/configuration/mtsbu/insure-activity/{insuranceProgramId}
     * @secure
     */
    getInsureActivityCoefficients: (insuranceProgramId: number, params: RequestParams = {}) =>
      this.request<CoefficientDto[], any>({
        path: `/insurance-program/configuration/mtsbu/insure-activity/${insuranceProgramId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name UpdateInsureActivityCoefficient
     * @summary update insure activity coefficient
     * @request PUT:/insurance-program/configuration/mtsbu/insure-activity/{insuranceProgramId}
     * @secure
     */
    updateInsureActivityCoefficient: (insuranceProgramId: number, data: CoefficientDto, params: RequestParams = {}) =>
      this.request<CoefficientDto, any>({
        path: `/insurance-program/configuration/mtsbu/insure-activity/${insuranceProgramId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name CreateInsureActivityCoefficient
     * @summary create insure activity coefficient
     * @request POST:/insurance-program/configuration/mtsbu/insure-activity/{insuranceProgramId}
     * @secure
     */
    createInsureActivityCoefficient: (insuranceProgramId: number, data: CoefficientDto, params: RequestParams = {}) =>
      this.request<CoefficientDto, any>({
        path: `/insurance-program/configuration/mtsbu/insure-activity/${insuranceProgramId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags program-mtsbu-configuration-controller
     * @name DeleteInsureActivityCoefficient
     * @summary delete insure activity coefficient
     * @request DELETE:/insurance-program/configuration/mtsbu/insure-activity/{insuranceProgramId}/{insureActivityCoefficientId}
     * @secure
     */
    deleteInsureActivityCoefficient: (
      insuranceProgramId: number,
      insureActivityCoefficientId: number,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/insurance-program/configuration/mtsbu/insure-activity/${insuranceProgramId}/${insureActivityCoefficientId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  insurancePolicyController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-policy-controller
     * @name GetInsurancePolicy
     * @summary get insurance policy
     * @request GET:/insurance-policies/{id}
     * @secure
     */
    getInsurancePolicy: (id: number, params: RequestParams = {}) =>
      this.request<InsurancePolicyDto, any>({
        path: `/insurance-policies/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-policy-controller
     * @name UpdateInsurancePolicy
     * @summary update insurance policy
     * @request PUT:/insurance-policies/{id}
     * @secure
     */
    updateInsurancePolicy: (id: number, data: InsurancePolicyDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-policies/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-policy-controller
     * @name DeleteInsurancePolicy
     * @summary delete insurance policy
     * @request DELETE:/insurance-policies/{id}
     * @secure
     */
    deleteInsurancePolicy: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-policies/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_SELLER')
     *
     * @tags insurance-policy-controller
     * @name GetProlongationPolicies
     * @summary get prolongation policies
     * @request GET:/insurance-policies
     * @secure
     */
    getProlongationPolicies: (query: GetProlongationPoliciesParams, params: RequestParams = {}) =>
      this.request<InsurancePolicyDto[], any>({
        path: `/insurance-policies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-policy-controller
     * @name CreateInsurancePolicy
     * @summary create insurance policy
     * @request POST:/insurance-policies
     * @secure
     */
    createInsurancePolicy: (query: CreateInsurancePolicyParams, data: InsurancePolicyDto, params: RequestParams = {}) =>
      this.request<InsurancePolicyDto, any>({
        path: `/insurance-policies`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  installmentPaymentController = {
    /**
     * No description
     *
     * @tags installment-payment-controller
     * @name UpdateInstallment
     * @summary update installment
     * @request PUT:/installment-payments/insurance-programs/{insuranceProgramId}
     * @secure
     */
    updateInstallment: (insuranceProgramId: number, data: ProgramInstallmentPaymentDto[], params: RequestParams = {}) =>
      this.request<ProgramInstallmentPaymentDto[], any>({
        path: `/installment-payments/insurance-programs/${insuranceProgramId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags installment-payment-controller
     * @name CreateInstallment
     * @summary create installment
     * @request POST:/installment-payments/insurance-programs/{insuranceProgramId}
     * @secure
     */
    createInstallment: (insuranceProgramId: number, data: ProgramInstallmentPaymentDto[], params: RequestParams = {}) =>
      this.request<ProgramInstallmentPaymentDto[], any>({
        path: `/installment-payments/insurance-programs/${insuranceProgramId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  discountController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags discount-controller
     * @name GetDiscount
     * @summary get discount
     * @request GET:/discounts/{id}
     * @secure
     */
    getDiscount: (id: number, params: RequestParams = {}) =>
      this.request<DiscountDto, any>({
        path: `/discounts/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags discount-controller
     * @name UpdateDiscount
     * @summary update discount
     * @request PUT:/discounts/{id}
     * @secure
     */
    updateDiscount: (id: number, data: DiscountDto, params: RequestParams = {}) =>
      this.request<DiscountDto, any>({
        path: `/discounts/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags discount-controller
     * @name DeleteDiscount
     * @summary delete discount
     * @request DELETE:/discounts/{id}
     * @secure
     */
    deleteDiscount: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/discounts/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags discount-controller
     * @name FindDiscountCalculation
     * @summary find discount calculation
     * @request POST:/discounts/calculations
     * @secure
     */
    findDiscountCalculation: (data: DiscountRequestDto, params: RequestParams = {}) =>
      this.request<DiscountCalculationDto, any>({
        path: `/discounts/calculations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Query DiscountView using dynamic search of TableViewRepository
     *
     * @tags discount-controller
     * @name List5
     * @summary list
     * @request GET:/discounts
     * @secure
     */
    list5: (query: List5Params, params: RequestParams = {}) =>
      this.request<ResultListDtoDiscountView, any>({
        path: `/discounts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags discount-controller
     * @name CreateDiscount
     * @summary create discount
     * @request POST:/discounts
     * @secure
     */
    createDiscount: (data: DiscountDto, params: RequestParams = {}) =>
      this.request<DiscountDto, any>({
        path: `/discounts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ADMIN')
     *
     * @tags discount-controller
     * @name DiscountTypes
     * @summary discount types
     * @request GET:/discounts/configurations
     * @secure
     */
    discountTypes: (params: RequestParams = {}) =>
      this.request<DiscountConfigurationDto, any>({
        path: `/discounts/configurations`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  depreciationRateController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags depreciation-rate-controller
     * @name FindDepreciationRate1
     * @summary find depreciation rate
     * @request GET:/depreciation-rates/{id}
     * @secure
     */
    findDepreciationRate1: (id: number, params: RequestParams = {}) =>
      this.request<DepreciationRateDto, any>({
        path: `/depreciation-rates/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags depreciation-rate-controller
     * @name UpdateDepreciationRate
     * @summary update depreciation rate
     * @request PUT:/depreciation-rates/{id}
     * @secure
     */
    updateDepreciationRate: (id: number, data: DepreciationRateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/depreciation-rates/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags depreciation-rate-controller
     * @name DeleteDepreciationRate
     * @summary delete depreciation rate
     * @request DELETE:/depreciation-rates/{id}
     * @secure
     */
    deleteDepreciationRate: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/depreciation-rates/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags depreciation-rate-controller
     * @name CreateDepreciationRate
     * @summary create depreciation rate
     * @request POST:/depreciation-rates/insurance-policies/{insurancePolicyId}
     * @secure
     */
    createDepreciationRate: (insurancePolicyId: number, data: DepreciationRateDto, params: RequestParams = {}) =>
      this.request<DepreciationRateDto, any>({
        path: `/depreciation-rates/insurance-policies/${insurancePolicyId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  claimsConfigurationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name FindPaymentRejectionCause
     * @summary find payment rejection cause
     * @request GET:/claims-configurations/payment-rejection-causes/{id}
     * @secure
     */
    findPaymentRejectionCause: (id: number, params: RequestParams = {}) =>
      this.request<PaymentRejectionCauseDto, any>({
        path: `/claims-configurations/payment-rejection-causes/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name UpdatePaymentRejectionCause
     * @summary update payment rejection cause
     * @request PUT:/claims-configurations/payment-rejection-causes/{id}
     * @secure
     */
    updatePaymentRejectionCause: (id: number, data: PaymentRejectionCauseDto, params: RequestParams = {}) =>
      this.request<PaymentRejectionCauseDto, any>({
        path: `/claims-configurations/payment-rejection-causes/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name DeletePaymentRejectionCause
     * @summary delete payment rejection cause
     * @request DELETE:/claims-configurations/payment-rejection-causes/{id}
     * @secure
     */
    deletePaymentRejectionCause: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims-configurations/payment-rejection-causes/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name GetDefaultRejectionCauses
     * @summary get default rejection causes
     * @request POST:/claims-configurations/{id}/default-rejection-causes
     * @secure
     */
    getDefaultRejectionCauses: (id: number, params: RequestParams = {}) =>
      this.request<PaymentRejectionCauseDto[], any>({
        path: `/claims-configurations/${id}/default-rejection-causes`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name DuplicateResolutionTypes
     * @summary duplicate resolution types
     * @request POST:/claims-configurations/resolution-types/{id}/duplicate
     * @secure
     */
    duplicateResolutionTypes: (id: number, data: number[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims-configurations/resolution-types/${id}/duplicate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name CreateResolutionTypeDocumentConfiguration
     * @summary create resolution type document configuration
     * @request POST:/claims-configurations/resolution-types/{id}/documents
     * @secure
     */
    createResolutionTypeDocumentConfiguration: (
      id: number,
      data: ResolutionTypeDocumentConfigurationDto,
      params: RequestParams = {},
    ) =>
      this.request<ClaimDocumentConfigurationDto[], any>({
        path: `/claims-configurations/resolution-types/${id}/documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags claims-configuration-controller
     * @name FindProgramPaymentRejectionCause
     * @summary find program payment rejection cause
     * @request GET:/claims-configurations/payment-rejection-causes
     * @secure
     */
    findProgramPaymentRejectionCause: (query: FindProgramPaymentRejectionCauseParams, params: RequestParams = {}) =>
      this.request<PaymentRejectionCauseDto[], any>({
        path: `/claims-configurations/payment-rejection-causes`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name CreatePaymentRejectionCause
     * @summary create payment rejection cause
     * @request POST:/claims-configurations/payment-rejection-causes
     * @secure
     */
    createPaymentRejectionCause: (
      query: CreatePaymentRejectionCauseParams,
      data: PaymentRejectionCauseDto,
      params: RequestParams = {},
    ) =>
      this.request<PaymentRejectionCauseDto, any>({
        path: `/claims-configurations/payment-rejection-causes`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name FindResolutionTypes
     * @summary find resolution types
     * @request GET:/claims-configurations/resolution-types
     * @secure
     */
    findResolutionTypes: (query: FindResolutionTypesParams, params: RequestParams = {}) =>
      this.request<ResolutionTypePair[], any>({
        path: `/claims-configurations/resolution-types`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name GetResolutionTypeDocumentConfiguration
     * @summary get resolution type document configuration
     * @request GET:/claims-configurations/resolution-types/{id}/document-configurations
     * @secure
     */
    getResolutionTypeDocumentConfiguration: (id: number, params: RequestParams = {}) =>
      this.request<ResolutionTypeDocumentConfigurationDto, any>({
        path: `/claims-configurations/resolution-types/${id}/document-configurations`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name DeleteResolutionTypeDocumentConfiguration
     * @summary delete resolution type document configuration
     * @request DELETE:/claims-configurations/resolution-types/{id}/document-configurations
     * @secure
     */
    deleteResolutionTypeDocumentConfiguration: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims-configurations/resolution-types/${id}/document-configurations`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name FindRisksByResolutionType
     * @summary find risks by resolution type
     * @request GET:/claims-configurations/resolution-types/risks
     * @secure
     */
    findRisksByResolutionType: (query: FindRisksByResolutionTypeParams, params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/claims-configurations/resolution-types/risks`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name GetResolutionTypeConfigurations
     * @summary get resolution type configurations
     * @request GET:/claims-configurations/resolution-type-configurations
     * @secure
     */
    getResolutionTypeConfigurations: (query: GetResolutionTypeConfigurationsParams, params: RequestParams = {}) =>
      this.request<ResolutionTypeConfigurationDto[], any>({
        path: `/claims-configurations/resolution-type-configurations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags claims-configuration-controller
     * @name FindDocumentConfiguration
     * @summary find document configuration
     * @request GET:/claims-configurations/document-configurations
     * @secure
     */
    findDocumentConfiguration: (query: FindDocumentConfigurationParams, params: RequestParams = {}) =>
      this.request<ClaimDocumentConfigurationDto[], any>({
        path: `/claims-configurations/document-configurations`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  userInsuranceProgramController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags user-insurance-program-controller
     * @name SaveAll
     * @summary save all
     * @request POST:/user-insurance-programs
     * @secure
     */
    saveAll: (data: ConfigurationUserInsuranceProgramDto[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user-insurance-programs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags user-insurance-program-controller
     * @name FindAllByUserId
     * @summary find all by user id
     * @request GET:/user-insurance-programs/users/{id}
     * @secure
     */
    findAllByUserId: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationUserInsuranceProgramDto[], any>({
        path: `/user-insurance-programs/users/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags user-insurance-program-controller
     * @name DeleteAllByUserId
     * @summary delete all by user id
     * @request DELETE:/user-insurance-programs/users/{id}
     * @secure
     */
    deleteAllByUserId: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user-insurance-programs/users/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  objectSubtypeController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags object-subtype-controller
     * @name FindLiabilityLimit
     * @summary find liability limit
     * @request POST:/object-subtypes/liability-limits
     * @secure
     */
    findLiabilityLimit: (data: LiabilityLimitRequestDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/object-subtypes/liability-limits`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags object-subtype-controller
     * @name FindProgramObjectSubtype
     * @summary find program object subtype
     * @request GET:/object-subtypes/{id}/program-object-subtypes
     * @secure
     */
    findProgramObjectSubtype: ({ id, ...query }: FindProgramObjectSubtypeParams, params: RequestParams = {}) =>
      this.request<ConfigurationProgramObjectSubtypeDto, any>({
        path: `/object-subtypes/${id}/program-object-subtypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags object-subtype-controller
     * @name FindById1
     * @summary find by id
     * @request GET:/object-subtypes/{id}
     * @secure
     */
    findById1: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationObjectSubtypeDto, any>({
        path: `/object-subtypes/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags object-subtype-controller
     * @name FindObjectSubtypeByObjectTypeAndName
     * @summary find object subtype by object type and name
     * @request GET:/object-subtypes/types/{insuranceObjectType}/{objectSubtypeName}
     * @secure
     */
    findObjectSubtypeByObjectTypeAndName: (
      insuranceObjectType: InsuranceObjectType,
      objectSubtypeName: string,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationObjectSubtypeDto, any>({
        path: `/object-subtypes/types/${insuranceObjectType}/${objectSubtypeName}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags object-subtype-controller
     * @name FindAllObjectSubtypeByInsuranceObjectType
     * @summary find all object subtype by insurance object type
     * @request GET:/object-subtypes/types
     * @secure
     */
    findAllObjectSubtypeByInsuranceObjectType: (
      query: FindAllObjectSubtypeByInsuranceObjectTypeParams,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationObjectSubtypeDto[], any>({
        path: `/object-subtypes/types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags object-subtype-controller
     * @name FindAllObjectSubtypesByInsuranceProgramId
     * @summary find all object subtypes by insurance program id
     * @request GET:/object-subtypes/insurance-programs/{id}
     * @secure
     */
    findAllObjectSubtypesByInsuranceProgramId: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationObjectSubtypeDto[], any>({
        path: `/object-subtypes/insurance-programs/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags object-subtype-controller
     * @name FindAll
     * @summary find all
     * @request GET:/object-subtypes
     * @secure
     */
    findAll: (params: RequestParams = {}) =>
      this.request<ConfigurationObjectSubtypeDto[], any>({
        path: `/object-subtypes`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  mtsbuRatesController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags mtsbu-rates-controller
     * @name SaveCoefficients
     * @summary save coefficients
     * @request POST:/mtsbu-rates/save
     * @secure
     */
    saveCoefficients: (query: SaveCoefficientsParams, data: CoefficientDto[], params: RequestParams = {}) =>
      this.request<CoefficientDto[], any>({
        path: `/mtsbu-rates/save`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  integrationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags integration-controller
     * @name FindAllIntegrationConfigurations
     * @summary find all integration configurations
     * @request GET:/integrations
     * @secure
     */
    findAllIntegrationConfigurations: (query: FindAllIntegrationConfigurationsParams, params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/integrations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags integration-controller
     * @name CreateIntegrationConfiguration
     * @summary create integration configuration
     * @request POST:/integrations
     * @secure
     */
    createIntegrationConfiguration: (data: IntegrationConfigurationDto, params: RequestParams = {}) =>
      this.request<IntegrationConfigurationDto, any>({
        path: `/integrations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags integration-controller
     * @name FindById2
     * @summary find by id
     * @request GET:/integrations/{id}
     * @secure
     */
    findById2: (id: number, params: RequestParams = {}) =>
      this.request<IntegrationConfigurationDto, any>({
        path: `/integrations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  ewaCopyController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags ewa-copy-controller
     * @name CreateOrUpdatePartnerPrograms
     * @summary create or update partner programs
     * @request POST:/ewa/copy/partner-programs
     * @secure
     */
    createOrUpdatePartnerPrograms: (
      query: CreateOrUpdatePartnerProgramsParams,
      data: Record<string, number>,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/ewa/copy/partner-programs`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags ewa-copy-controller
     * @name CopyEwaInsurancePrograms
     * @summary copy ewa insurance programs
     * @request POST:/ewa/copy/insurance-programs
     * @secure
     */
    copyEwaInsurancePrograms: (data: EwaInsuranceProgramDto[], params: RequestParams = {}) =>
      this.request<EwaInsuranceProgramDto[], any>({
        path: `/ewa/copy/insurance-programs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  documentConfigurationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetDocumentTypesByCodes
     * @summary get document types by codes
     * @request POST:/documents/codes
     * @secure
     */
    getDocumentTypesByCodes: (data: DocumentTypeCode[], params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/documents/codes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetDocumentTypeById
     * @summary get document type by id
     * @request GET:/documents/{id}
     * @secure
     */
    getDocumentTypeById: (id: number, params: RequestParams = {}) =>
      this.request<DocumentTypeDto, any>({
        path: `/documents/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetTerminationDocumentConfigurations
     * @summary get termination document configurations
     * @request GET:/documents/terminations
     * @secure
     */
    getTerminationDocumentConfigurations: (
      query: GetTerminationDocumentConfigurationsParams,
      params: RequestParams = {},
    ) =>
      this.request<TerminationDocumentConfigurationDto[], any>({
        path: `/documents/terminations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags document-configuration-controller
     * @name GetSaleRegisterDocumentConfigurations
     * @summary get sale register document configurations
     * @request GET:/documents/sale-register
     * @secure
     */
    getSaleRegisterDocumentConfigurations: (params: RequestParams = {}) =>
      this.request<SaleRegisterDocumentConfigurationDto[], any>({
        path: `/documents/sale-register`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetAllDocumentTypes
     * @summary get all document types
     * @request GET:/documents/document-types
     * @secure
     */
    getAllDocumentTypes: (query: GetAllDocumentTypesParams, params: RequestParams = {}) =>
      this.request<DocumentTypeDto[], any>({
        path: `/documents/document-types`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetContractDocumentConfigurationByPartnerIdAndInsuranceProgramIdAndDocumentTypeId
     * @summary get contract document configuration by partner id and insurance program id and document type id
     * @request GET:/documents/contract/document-types/{id}
     * @secure
     */
    getContractDocumentConfigurationByPartnerIdAndInsuranceProgramIdAndDocumentTypeId: (
      { id, ...query }: GetContractDocumentConfigurationByPartnerIdAndInsuranceProgramIdAndDocumentTypeIdParams,
      params: RequestParams = {},
    ) =>
      this.request<ContractDocumentConfigurationDto, any>({
        path: `/documents/contract/document-types/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetContractDocumentConfigurations
     * @summary get contract document configurations
     * @request GET:/documents/contract
     * @secure
     */
    getContractDocumentConfigurations: (query: GetContractDocumentConfigurationsParams, params: RequestParams = {}) =>
      this.request<ContractDocumentConfigurationDto[], any>({
        path: `/documents/contract`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetDocumentTypeById1
     * @summary get document type by id
     * @request GET:/documents/codes/{documentTypeCode}
     * @secure
     */
    getDocumentTypeById1: (documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<DocumentTypeDto, any>({
        path: `/documents/codes/${documentTypeCode}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetClaimDocumentConfigurationByRiskIdAndDocumentType
     * @summary get claim document configuration by risk id and document type
     * @request GET:/documents/claims/document-types/{id}
     * @secure
     */
    getClaimDocumentConfigurationByRiskIdAndDocumentType: (
      { id, ...query }: GetClaimDocumentConfigurationByRiskIdAndDocumentTypeParams,
      params: RequestParams = {},
    ) =>
      this.request<ClaimDocumentConfigurationDto, any>({
        path: `/documents/claims/document-types/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetClaimDocumentConfigurations
     * @summary get claim document configurations
     * @request GET:/documents/claims
     * @secure
     */
    getClaimDocumentConfigurations: (query: GetClaimDocumentConfigurationsParams, params: RequestParams = {}) =>
      this.request<ClaimDocumentConfigurationDto[], any>({
        path: `/documents/claims`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags document-configuration-controller
     * @name GetAllDocumentTypes1
     * @summary get all document types
     * @request GET:/documents
     * @secure
     */
    getAllDocumentTypes1: (params: RequestParams = {}) =>
      this.request<DocumentTypeDto[], any>({
        path: `/documents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  insuranceDocumentController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-document-controller
     * @name SaveTemplateFile
     * @summary save template file
     * @request POST:/document-templates/save
     * @secure
     */
    saveTemplateFile: (
      query: SaveTemplateFileParams,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/document-templates/save`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER','ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-document-controller
     * @name DownloadInsuranceProgramFile
     * @summary download insurance program file
     * @request GET:/document-templates/insurance-programs/{insuranceProgramId}/files
     * @secure
     */
    downloadInsuranceProgramFile: (
      { insuranceProgramId, ...query }: DownloadInsuranceProgramFileParams,
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/document-templates/insurance-programs/${insuranceProgramId}/files`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-document-controller
     * @name SaveInsuranceProgramFile
     * @summary save insurance program file
     * @request POST:/document-templates/insurance-programs/{insuranceProgramId}/files
     * @secure
     */
    saveInsuranceProgramFile: (
      { insuranceProgramId, ...query }: SaveInsuranceProgramFileParams,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/document-templates/insurance-programs/${insuranceProgramId}/files`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-document-controller
     * @name FindTemplateFilePath
     * @summary find template file path
     * @request GET:/document-templates/{documentTemplateId}/template-files/path
     * @secure
     */
    findTemplateFilePath: ({ documentTemplateId, ...query }: FindTemplateFilePathParams, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/document-templates/${documentTemplateId}/template-files/path`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-document-controller
     * @name FindDocumentFile
     * @summary find document file
     * @request GET:/document-templates/template-files/{templateFileId}
     * @secure
     */
    findDocumentFile: (templateFileId: number, params: RequestParams = {}) =>
      this.request<TemplateFileDto, any>({
        path: `/document-templates/template-files/${templateFileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-document-controller
     * @name DownloadOfferFile
     * @summary download offer file
     * @request GET:/document-templates/insurance-policies/{policyId}/files
     * @secure
     */
    downloadOfferFile: ({ policyId, ...query }: DownloadOfferFileParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/document-templates/insurance-policies/${policyId}/files`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-document-controller
     * @name FindDocumentTemplates
     * @summary find document templates
     * @request GET:/document-templates
     * @secure
     */
    findDocumentTemplates: (query: FindDocumentTemplatesParams, params: RequestParams = {}) =>
      this.request<GeneratorWrapperDto[], any>({
        path: `/document-templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  departmentProgramController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags department-program-controller
     * @name CreateExternalId
     * @summary create external id
     * @request POST:/department-programs/external-id
     * @secure
     */
    createExternalId: (query: CreateExternalIdParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/department-programs/external-id`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags department-program-controller
     * @name DeleteExternalId
     * @summary delete external id
     * @request DELETE:/department-programs/external-id
     * @secure
     */
    deleteExternalId: (query: DeleteExternalIdParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/department-programs/external-id`,
        method: 'DELETE',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Query DepartmentProgramView using dynamic search of TableViewRepository
     *
     * @tags department-program-controller
     * @name ListDepartmentPrograms
     * @summary list department programs
     * @request GET:/department-programs
     * @secure
     */
    listDepartmentPrograms: (query: ListDepartmentProgramsParams, params: RequestParams = {}) =>
      this.request<ResultListDtoDepartmentProgramView, any>({
        path: `/department-programs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags department-program-controller
     * @name SaveAll1
     * @summary save all
     * @request POST:/department-programs
     * @secure
     */
    saveAll1: (data: ConfigurationDepartmentProgramDto[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/department-programs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags department-program-controller
     * @name FindAllByPartnerIdAndInsuranceProgramIdId
     * @summary find all by partner id and insurance program id id
     * @request GET:/department-programs/partner/{partnerId}/insurance-program/{insuranceProgramId}
     * @secure
     */
    findAllByPartnerIdAndInsuranceProgramIdId: (
      partnerId: number,
      insuranceProgramId: number,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationDepartmentProgramDto[], any>({
        path: `/department-programs/partner/${partnerId}/insurance-program/${insuranceProgramId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags department-program-controller
     * @name FindAllByDepartmentId
     * @summary find all by department id
     * @request GET:/department-programs/departments/{id}
     * @secure
     */
    findAllByDepartmentId: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationDepartmentProgramDto[], any>({
        path: `/department-programs/departments/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags department-program-controller
     * @name DeleteAllByDepartmentId
     * @summary delete all by department id
     * @request DELETE:/department-programs/departments/{id}
     * @secure
     */
    deleteAllByDepartmentId: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/department-programs/departments/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags department-program-controller
     * @name FindByExternalId
     * @summary find by external id
     * @request GET:/department-programs/department/{externalId}
     * @secure
     */
    findByExternalId: (externalId: string, params: RequestParams = {}) =>
      this.request<ConfigurationDepartmentProgramDto, any>({
        path: `/department-programs/department/${externalId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  aggregatorInsuranceProgramController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags aggregator-insurance-program-controller
     * @name Create4
     * @summary create
     * @request POST:/aggregator-insurance-programs
     * @secure
     */
    create4: (data: CreateAggregatorInsuranceProgramDto, params: RequestParams = {}) =>
      this.request<AggregatorInsuranceProgramDto, any>({
        path: `/aggregator-insurance-programs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_AGGREGATOR')
     *
     * @tags aggregator-insurance-program-controller
     * @name ListAllAggregatorProgramsByProductCode
     * @summary list all aggregator programs by product code
     * @request GET:/aggregator-insurance-programs/product-code/{code}
     * @secure
     */
    listAllAggregatorProgramsByProductCode: (code: InsuranceProductCode, params: RequestParams = {}) =>
      this.request<DetailedAggregatorInsuranceProgramDto[], any>({
        path: `/aggregator-insurance-programs/product-code/${code}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags aggregator-insurance-program-controller
     * @name ListInsuranceProgramByAggregatorPartnerId
     * @summary list insurance program by aggregator partner id
     * @request GET:/aggregator-insurance-programs/aggregator-partner/{aggregatorPartnerId}
     * @secure
     */
    listInsuranceProgramByAggregatorPartnerId: (aggregatorPartnerId: number, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto[], any>({
        path: `/aggregator-insurance-programs/aggregator-partner/${aggregatorPartnerId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags aggregator-insurance-program-controller
     * @name ListProgramsByPartnerIdAndInsuranceCompanyId
     * @summary list programs by partner id and insurance company id
     * @request GET:/aggregator-insurance-programs/aggregator-partner/{aggregatorPartnerId}/insurance-company/{insuranceCompanyId}
     * @secure
     */
    listProgramsByPartnerIdAndInsuranceCompanyId: (
      aggregatorPartnerId: number,
      insuranceCompanyId: number,
      params: RequestParams = {},
    ) =>
      this.request<ConfigurationInsuranceProgramDto[], any>({
        path: `/aggregator-insurance-programs/aggregator-partner/${aggregatorPartnerId}/insurance-company/${insuranceCompanyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  mtsbuDictionaryController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_INSURANCE_MANAGER', 'ROLE_SYSTEM','ROLE_INSURANCE_ADMIN', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER')
     *
     * @tags mtsbu-dictionary-controller
     * @name ListVehicles
     * @summary list vehicles
     * @request GET:/mtsbu-dictionary/vehicles
     * @secure
     */
    listVehicles: (query: ListVehiclesParams, params: RequestParams = {}) =>
      this.request<ResultListDtoVehicleView, any>({
        path: `/mtsbu-dictionary/vehicles`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_INSURANCE_MANAGER', 'ROLE_SYSTEM','ROLE_INSURANCE_ADMIN', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER')
     *
     * @tags mtsbu-dictionary-controller
     * @name GetZoneByCity
     * @summary get zone by city
     * @request GET:/mtsbu-dictionary/city-zone
     * @secure
     */
    getZoneByCity: (query: GetZoneByCityParams, params: RequestParams = {}) =>
      this.request<CityZoneDto[], any>({
        path: `/mtsbu-dictionary/city-zone`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  mtsbuDictionaryImportController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags mtsbu-dictionary-import-controller
     * @name ImportMtsbuVehicleModels
     * @summary import mtsbu vehicle models
     * @request GET:/mtsbu-dictionaries/import/models
     * @secure
     */
    importMtsbuVehicleModels: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mtsbu-dictionaries/import/models`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags mtsbu-dictionary-import-controller
     * @name ImportMtsbuVehicleMarks
     * @summary import mtsbu vehicle marks
     * @request GET:/mtsbu-dictionaries/import/marks
     * @secure
     */
    importMtsbuVehicleMarks: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mtsbu-dictionaries/import/marks`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags mtsbu-dictionary-import-controller
     * @name ImportMtsbuCities
     * @summary import mtsbu cities
     * @request GET:/mtsbu-dictionaries/import/cities
     * @secure
     */
    importMtsbuCities: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mtsbu-dictionaries/import/cities`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  insuranceRuleController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rule-controller
     * @name GetInsuranceRulesByInsuranceCompany
     * @summary get insurance rules by insurance company
     * @request GET:/insurance-rules/insurance-companies/{id}
     * @secure
     */
    getInsuranceRulesByInsuranceCompany: (id: number, params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/insurance-rules/insurance-companies/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-rule-controller
     * @name GetInsuranceRules
     * @summary get insurance rules
     * @request GET:/insurance-rules
     * @secure
     */
    getInsuranceRules: (params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/insurance-rules`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  insuranceProductController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-product-controller
     * @name ListPrograms
     * @summary list programs
     * @request GET:/insurance-products/{id}/programs
     * @secure
     */
    listPrograms: (id: number, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto[], any>({
        path: `/insurance-products/${id}/programs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-product-controller
     * @name ListProgramsByProductCode
     * @summary list programs by product code
     * @request GET:/insurance-products/programs
     * @secure
     */
    listProgramsByProductCode: (query: ListProgramsByProductCodeParams, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProgramDto[], any>({
        path: `/insurance-products/programs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-product-controller
     * @name GetInsuranceProductByCode
     * @summary get insurance product by code
     * @request GET:/insurance-products/code/{code}
     * @secure
     */
    getInsuranceProductByCode: (code: InsuranceProductCode, params: RequestParams = {}) =>
      this.request<ConfigurationInsuranceProductDto, any>({
        path: `/insurance-products/code/${code}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query InsuranceProductView using dynamic search of TableViewRepository
     *
     * @tags insurance-product-controller
     * @name ListProducts
     * @summary list products
     * @request GET:/insurance-products
     * @secure
     */
    listProducts: (query: ListProductsParams, params: RequestParams = {}) =>
      this.request<ResultListDtoInsuranceProductView, any>({
        path: `/insurance-products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  filesDocumentController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags files-document-controller
     * @name FindConfigurationFilePath
     * @summary find configuration file path
     * @request GET:/files/{fileId}
     * @secure
     */
    findConfigurationFilePath: (fileId: string, params: RequestParams = {}) =>
      this.request<ConfigurationFilePathDto, any>({
        path: `/files/${fileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags files-document-controller
     * @name FindFileId
     * @summary find file id
     * @request GET:/files/insurance-programs/{insuranceProgramId}/fileId
     * @secure
     */
    findFileId: ({ insuranceProgramId, ...query }: FindFileIdParams, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/files/insurance-programs/${insuranceProgramId}/fileId`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  diaCredentialController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags dia-credential-controller
     * @name GetDiaCredentialByInsuranceCompanyId
     * @summary get dia credential by insurance company id
     * @request GET:/dia/credentials/{insuranceCompanyId}
     * @secure
     */
    getDiaCredentialByInsuranceCompanyId: (insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<DiaCredentialDto, any>({
        path: `/dia/credentials/${insuranceCompanyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  externalInsuranceDocumentController = {
    /**
     * No description
     *
     * @tags external-insurance-document-controller
     * @name DownloadOfferFileByInternetSalesToken
     * @summary download offer file by internet sales token
     * @request GET:/api/v1/document-templates/internet-sales/{token}/offer
     * @secure
     */
    downloadOfferFileByInternetSalesToken: (
      { token, ...query }: DownloadOfferFileByInternetSalesTokenParams,
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/api/v1/document-templates/internet-sales/${token}/offer`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags external-insurance-document-controller
     * @name DownloadOfferFile1
     * @summary download offer file
     * @request GET:/api/v1/document-templates/department-program/{externalId}/offer
     * @secure
     */
    downloadOfferFile1: ({ externalId, ...query }: DownloadOfferFile1Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/v1/document-templates/department-program/${externalId}/offer`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
}
