import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';
import { AnyAction, combineReducers } from 'redux';

import me, { InferActionTypes as InferActionTypesMe } from './me/me';
import insuredEventList, {
  InferActionTypes as InferActionTypesInsuredEvents,
} from './insuredEventsList/insuredEventList';
import contractList from './contractList';
import contractListNext, { InferActionTypes as InferActionTypesContracts } from './contractList/contractList';
import acts, { InferActionTypes as InferActionTypesActs } from './acts/acts';
import act, { InferActionTypes as InferActionTypesAct } from './act/act';
import contractDetails from './contractDetails';
import contractDetailsNext, { InferActionTypes as InferActionTypesContract } from './contractDetails/contractDetails';
import deviceSearch from './deviceSearch';
import insuredEvent from './insuredEvent';
import insurance from './insurance';
import userLegacy from './user';
import { InferActionTypes as InferActionTypesUser, user } from './user';
import expressPayment from './expressPayment';
import terminationList, { InferActionTypes as InferActionTypesTerminations } from './terminationList/terminationList';
import termination, { InferActionTypes as InferActionTypesTermination } from './termination/termination';
import adminList from './adminList';
import insuranceProducts from './insuranceProducts';
import privateOffice from './privateOffice';
import usersActivities from './usersActivities';
import departments, { InferActionTypes as InferActionTypesDepartments } from './departments/departments';
import departmentDetails from './departmentDetails';
import integrations, { InferActionTypes as InferActionTypesIntegrations } from './integrations/integrations';
import integration, { InferActionTypes as InferActionTypesIntegration } from './integrations/integration';
import insuranceProgramsList from './insuranceProgramsList';
import insuranceProgramDetails from './insuranceProgramDetails';
import insuranceConfiguration from './insuranceConfiguration';
import partnerList, { InferActionTypes as InferActionTypesPartners } from './partnersList/partnerList';
import partnerCreate from './partner';
import insuranceProgramCreate from './insuranceProgramCreate';
import brands, { InferActionTypes as InferActionTypesBrands } from './brands/brands';
import registrationDeviceList, {
  InferActionTypes as InferActionTypesRegistrationDevices,
} from './registrationDevices/registrationDeviceList';
import sublimits from './sublimits';
import subevents from './subevents';
import ui from './ui';
import registerTypes from './registerTypes';
import discounts from './discounts';
import medApp from './medApp';
import institutions from './institutions';
import claims from './claims';
import appointment from './appointment';
import permissions, { InferActionTypes as InferActionTypesPermissions } from './permissions/permissions';
import insuranceCompanyList, {
  InferActionTypes as InferActionTypesInsuranceCompanies,
} from './insuranceCompanyList/insuranceCompanyList';
import users, { InferActionTypes as InferActionTypesUsers } from './users/users';
import partnerCreateNext, { InferActionTypes as InferActionTypesPartnerCreate } from './partner/partner';
import insuranceProgram, {
  InferActionTypes as InferActionTypesInsuranceProgram,
} from './insuranceProgramCreate/insuranceProgram';
import userActivities, { InferActionTypes as InferActionTypesUserActivities } from './usersActivities/userActivities';
import insurancePrograms, {
  InferActionTypes as InferActionTypesInsurancePrograms,
} from './insuranceProgramsList/insurancePrograms';
import dashboardSettlement, {
  InferActionTypes as InferActionTypesDashboardSettlement,
} from './dashboardSettlement/dashboardSettlement';
import dashboardExtend, {
  InferActionTypes as InferActionTypesDashboardExtend,
} from './dashboardExtend/dashboardExtend';
import clientDashboard, {
  InferActionTypes as InferActionTypesClientDashboard,
} from './clientDashboard/clientDashboard';
import clientAuth, { InferActionTypes as InferActionTypesClientAuth } from './clientAuth/clientAuth';
import profile, { InferActionTypes as InferActionTypesProfile } from './profile/profile';
import clientPolicies, { InferActionTypes as InferActionTypesClientPolicies } from './clientPolicies/clientPolicies';
import partnerAgent, { InferActionTypes as InferActionTypesPartnerAgent } from './partnerAgent/partnerAgent';
import discountsNext, { InferActionTypes as InferActionTypesDiscounts } from './discounts/discounts';
import insuranceProductsNext, {
  InferActionTypes as InferActionTypesProducts,
} from './insuranceProducts/insuranceProducts';
import clientNotifications, {
  InferActionTypes as InferActionTypesClientNotifications,
} from './clientNotifications/clientNotifications';
import { StoreType } from 'types/store';
import clientBonuses, { InferActionTypes as InferActionTypesClientBonuses } from './clientBonuses/clientBonuses';

const rootReducer = combineReducers<StoreType>({
  me,
  usersActivities,
  insuredEventList,
  contractList,
  registrationDeviceList,
  acts,
  act,
  contractDetails,
  contractDetailsNext,
  insuredEvent,
  insurance,
  deviceSearch,
  userLegacy,
  user,
  departments,
  departmentDetails,
  insuranceProgramsList,
  insurancePrograms,
  insuranceProgramDetails,
  expressPayment,
  terminationList,
  termination,
  adminList,
  privateOffice,
  insuranceConfiguration,
  brands,
  insuranceProducts,
  partnerList,
  partnerCreate,
  integrations,
  integration,
  insuranceProgramCreate,
  sublimits,
  subevents,
  ui,
  registerTypes,
  discounts,
  medApp,
  appointment,
  institutions,
  claims,
  permissions,
  contractListNext,
  insuranceCompanyList,
  users,
  partnerCreateNext,
  insuranceProgram,
  userActivities,
  dashboardSettlement,
  dashboardExtend,
  clientDashboard,
  clientAuth,
  profile,
  clientPolicies,
  partnerAgent,
  discountsNext,
  insuranceProductsNext,
  clientNotifications,
  clientBonuses,
});

export type AppAction =
  | InferActionTypesMe
  | InferActionTypesContracts
  | InferActionTypesContract
  | InferActionTypesUsers
  | InferActionTypesTerminations
  | InferActionTypesTermination
  | InferActionTypesRegistrationDevices
  | InferActionTypesBrands
  | InferActionTypesInsuranceCompanies
  | InferActionTypesPartners
  | InferActionTypesInsuredEvents
  | InferActionTypesIntegrations
  | InferActionTypesIntegration
  | InferActionTypesDepartments
  | InferActionTypesPermissions
  | InferActionTypesPartnerCreate
  | InferActionTypesInsuranceProgram
  | InferActionTypesUserActivities
  | InferActionTypesInsurancePrograms
  | InferActionTypesDashboardSettlement
  | InferActionTypesDashboardExtend
  | InferActionTypesClientDashboard
  | InferActionTypesClientAuth
  | InferActionTypesProfile
  | InferActionTypesClientPolicies
  | InferActionTypesPartnerAgent
  | InferActionTypesDiscounts
  | InferActionTypesUser
  | InferActionTypesProducts
  | InferActionTypesClientNotifications
  | InferActionTypesActs
  | InferActionTypesAct
  | InferActionTypesClientBonuses;

const appReducer = (state: ReturnType<typeof rootReducer>, action: AnyAction) =>
  action.type === 'RESET_STORE' ? rootReducer(rootReducer(undefined, action), action) : rootReducer(state, action);

export type AppState = ReturnType<typeof appReducer>;

export type AppDispatch = ThunkDispatch<StoreType, void, AppAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default appReducer;
