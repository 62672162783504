/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface AddressDto {
  locality: string;
  street: string;
}

export interface OptionDto {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface UserDto {
  /** @format int64 */
  id?: number;
  active?: boolean;
  status?: UserStatus;
  lang?: string;
  lastName: string;
  firstName: string;
  middleName?: string;
  phoneNumber: string;
  email: string;
  insuranceCompany?: OptionDto;
  partner?: OptionDto;
  partnerDepartment?: OptionDto;
  partnerDepartmentRegion?: OptionDto;
  address: AddressDto;
  role?: UserRole;
  client?: boolean;
  /** @format date-time */
  signupDate?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  insurancePrograms?: UserInsuranceProgramDto[];
}

export interface UserInsuranceProgramDto {
  active?: boolean;
  insuranceProgram?: OptionDto;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  newActive?: boolean;
}

export enum UserRole {
  ROLE_PARTNER_SELLER = 'ROLE_PARTNER_SELLER',
  ROLE_HR_MANAGER = 'ROLE_HR_MANAGER',
  ROLE_AGENT_SELLER = 'ROLE_AGENT_SELLER',
  ROLE_PARTNER_MANAGER = 'ROLE_PARTNER_MANAGER',
  ROLE_INSURANCE_MANAGER = 'ROLE_INSURANCE_MANAGER',
  ROLE_INSURANCE_CLAIM_MANAGER = 'ROLE_INSURANCE_CLAIM_MANAGER',
  ROLE_SYSTEM_PARTNER = 'ROLE_SYSTEM_PARTNER',
  ROLE_PARTNER_ASSISTANCE = 'ROLE_PARTNER_ASSISTANCE',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_SYSTEM = 'ROLE_SYSTEM',
  ROLE_AGGREGATOR = 'ROLE_AGGREGATOR',
  ROLE_PARTNER_ADMIN = 'ROLE_PARTNER_ADMIN',
  ROLE_INSURANCE_ADMIN = 'ROLE_INSURANCE_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export enum UserStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum StatusType {
  INSURANCE_CASE = 'INSURANCE_CASE',
  ON_WAY_TO_REPAIR = 'ON_WAY_TO_REPAIR',
  ON_WAY_TO_INSURANCE_COMPANY = 'ON_WAY_TO_INSURANCE_COMPANY',
  ON_WAY_TO_CLIENT = 'ON_WAY_TO_CLIENT',
  REPAIR = 'REPAIR',
  SELLING = 'SELLING',
  STEALING = 'STEALING',
  SOLD = 'SOLD',
}

export enum DeliveryType {
  SELF_PIKUP = 'SELF_PIKUP',
  NOVA_POSHTA = 'NOVA_POSHTA',
  OLX_DELIVERY = 'OLX_DELIVERY',
  UKRPOSHTA = 'UKRPOSHTA',
  COURIER_SC = 'COURIER_SC',
  COURIER_DELIVERY = 'COURIER_DELIVERY',
}

export interface RepairEquipmentDto {
  /** @format int64 */
  id?: number;
  serviceCenter?: string;
  /** @format date-time */
  serviceCenterDate?: string;
  damage?: string;
  repairStatusType: RepairStatusType;
  repairCost?: number;
  /** @format date-time */
  dispatchFromRepairDate?: string;
  deliveryType?: DeliveryType;
  deliveryAddress?: string;
  invoiceNumber?: string;
  /** @format date-time */
  receiptDate?: string;
  amountRepairCost?: number;
  otherExpenses?: number;
}

export enum RepairStatusType {
  ON_WAY_TO_REPAIR = 'ON_WAY_TO_REPAIR',
  UNDER_REPAIR = 'UNDER_REPAIR',
  REPAIR_POSTPONED = 'REPAIR_POSTPONED',
  REPAIR_NOT_POSSIBLE = 'REPAIR_NOT_POSSIBLE',
  REPAIR_COMPLETED = 'REPAIR_COMPLETED',
  REPAIR_INEXPEDIENT = 'REPAIR_INEXPEDIENT',
}

export enum ClaimStatus {
  REPORTED = 'REPORTED',
  DOCUMENTS_COLLECTION = 'DOCUMENTS_COLLECTION',
  PAYMENT_CALCULATION = 'PAYMENT_CALCULATION',
  PAYMENT_AWAITING = 'PAYMENT_AWAITING',
  PAYMENT = 'PAYMENT',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  ANNUL = 'ANNUL',
}

export interface DepartmentAddressDto {
  locality: string;
  street?: string;
}

export interface DepartmentDto {
  /** @format int64 */
  id?: number;
  name: string;
  active?: boolean;
  /**
   * @minLength 0
   * @maxLength 10
   */
  departmentNumber: string;
  type: DepartmentType;
  partnerType: PartnerType;
  partner: OptionDto;
  directorFullName: string;
  phoneNumber: string;
  email: string;
  /** @format date */
  createdDate?: string;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
  address?: DepartmentAddressDto;
  region: OptionDto;
  departmentPrograms?: DepartmentProgramDto[];
  insuranceCompany: OptionDto;
  /** @format int64 */
  departmentConfigurationId?: number;
  workDayStart: string;
  workDayEnd: string;
}

export interface DepartmentProgramDto {
  /** @format int64 */
  id?: number;
  /** @format uuid */
  externalId?: string;
  /** @format date-time */
  externalIdCreated?: string;
  active?: boolean;
  insuranceProgram: OptionDto;
  partnerDepartment?: OptionDto;
  /** @format date-time */
  activationDate?: string;
  /** @format date-time */
  deactivationDate?: string;
}

export enum DepartmentType {
  SALE_POINT = 'SALE_POINT',
  INTERNET_SHOP = 'INTERNET_SHOP',
  TEST_SALE_POINT = 'TEST_SALE_POINT',
}

export interface DispatchAfterSaleDto {
  /** @format int64 */
  id?: number;
  invoiceNumber?: string;
  /** @format date-time */
  dispatchDate?: string;
  deliveryType?: DeliveryType;
  /** @format date-time */
  transferEquipmentToBuyerDate?: string;
}

export enum DocumentTypeCode {
  PASSPORT = 'PASSPORT',
  PASSPORTPAGE2 = 'PASSPORT_PAGE_2',
  PASSPORTPAGE3 = 'PASSPORT_PAGE_3',
  PASSPORT_RESIDENCE_PLACE = 'PASSPORT_RESIDENCE_PLACE',
  IDENTIFICATION_NUMBER = 'IDENTIFICATION_NUMBER',
  AUTHORITIES_INFORMATION = 'AUTHORITIES_INFORMATION',
  SERVICE_CENTER_CONCLUSION = 'SERVICE_CENTER_CONCLUSION',
  INVOICE = 'INVOICE',
  AGENT_ACT = 'AGENT_ACT',
  FISCAL_CHECK = 'FISCAL_CHECK',
  EQUIPMENT_WARRANTY_CARD = 'EQUIPMENT_WARRANTY_CARD',
  DAMAGED_EQUIPMENT_PHOTO = 'DAMAGED_EQUIPMENT_PHOTO',
  RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS = 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS',
  EXTRACT_FROM_UNIFIED_REGISTER_PRE_TRIAL_INVESTIGATIONS = 'EXTRACT_FROM_UNIFIED_REGISTER_PRE_TRIAL_INVESTIGATIONS',
  RESOLUTION_ON_CLOSING_CRIMINAL_PROCEEDINGS_WITH_INDICATION_GUILTY_PERSONSSIGNS_ILLEGAL_ACTIONS = 'RESOLUTION_ON_CLOSING_CRIMINAL_PROCEEDINGS_WITH_INDICATION_GUILTY_PERSONSSIGNS_ILLEGAL_ACTIONS',
  COURT_DECISION_TO_ESTABLISH_GUILT_OF_PERSON_WHO_CAUSED_DAMAGE = 'COURT_DECISION_TO_ESTABLISH_GUILT_OF_PERSON_WHO_CAUSED_DAMAGE',
  DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID = 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID',
  AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION = 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION',
  FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT = 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT',
  DOCUMENTS_WITH_IMEI = 'DOCUMENTS_WITH_IMEI',
  INSURANCE_FISCAL_CHECK = 'INSURANCE_FISCAL_CHECK',
  PACKAGING = 'PACKAGING',
  DAMAGED_EQUIPMENT = 'DAMAGED_EQUIPMENT',
  SERVICE_CENTER_ACCOUNT = 'SERVICE_CENTER_ACCOUNT',
  CHARGER_AND_OTHER_COMPONENTS = 'CHARGER_AND_OTHER_COMPONENTS',
  OTHER_DOCUMENTS = 'OTHER_DOCUMENTS',
  FISCAL_CHECK_OTHER_RISKS = 'FISCAL_CHECK_OTHER_RISKS',
  INSURANCE_FISCAL_CHECK_OTHER_RISKS = 'INSURANCE_FISCAL_CHECK_OTHER_RISKS',
  PASSPORT_OTHER_RISKS = 'PASSPORT_OTHER_RISKS',
  PASSPORTPAGE2OTHERRISKS = 'PASSPORT_PAGE_2_OTHER_RISKS',
  PASSPORT_RESIDENCE_PLACE_OTHER_RISKS = 'PASSPORT_RESIDENCE_PLACE_OTHER_RISKS',
  IDENTIFICATION_NUMBER_OTHER_RISKS = 'IDENTIFICATION_NUMBER_OTHER_RISKS',
  DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS = 'DAMAGED_EQUIPMENT_PHOTO_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGED_EQUIPMENT_OTHER_RISKS = 'DAMAGED_EQUIPMENT_OTHER_RISKS',
  EQUIPMENT_WARRANTY_CARD_OTHER_RISKS = 'EQUIPMENT_WARRANTY_CARD_OTHER_RISKS',
  CHARGER_AND_OTHER_COMPONENTS_OTHER_RISKS = 'CHARGER_AND_OTHER_COMPONENTS_OTHER_RISKS',
  PACKAGING_OTHER_RISKS = 'PACKAGING_OTHER_RISKS',
  AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION_OTHER_RISKS = 'AUTHORIZED_SERVICE_CENTER_ACT_ON_TECHNICAL_CONDITION_OTHER_RISKS',
  INVOICE_OTHER_RISKS = 'INVOICE_OTHER_RISKS',
  AGENT_ACT_OTHER_RISKS = 'AGENT_ACT_OTHER_RISKS',
  REPAIRED_DEVICE_PHOTO = 'REPAIRED_DEVICE_PHOTO',
  REPAIRED_DEVICE_PHOTO_OTHER_RISKS = 'REPAIRED_DEVICE_PHOTO_OTHER_RISKS',
  FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT_OTHER_RISKS = 'FISCAL_CHECK_FOR_REPAIR_WORK_PAYMENT_OTHER_RISKS',
  RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS_OTHER_RISKS = 'RELEVANT_COMPETENT_AUTHORITIES_DOCUMENTS_OTHER_RISKS',
  AUTHORITIES_INFORMATION_OTHER_RISKS = 'AUTHORITIES_INFORMATION_OTHER_RISKS',
  OTHER_DOCUMENTS_OTHER_RISKS = 'OTHER_DOCUMENTS_OTHER_RISKS',
  TERMINATION_FISCAL_CHECK = 'TERMINATION_FISCAL_CHECK',
  DAMAGEDEQUIPMENTPHOTO2WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_2_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO3WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_3_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO4WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_4_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO5WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_5_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  DAMAGEDEQUIPMENTPHOTO6WITHDAMAGEFIXATIONANDIMEIDEVICEIDOTHERRISKS = 'DAMAGED_EQUIPMENT_PHOTO_6_WITH_DAMAGE_FIXATION_AND_IMEI_DEVICE_ID_OTHER_RISKS',
  COVID_TEST = 'COVID_TEST',
  HOSPITAL_STAY_CERTIFICATE = 'HOSPITAL_STAY_CERTIFICATE',
  DOCTORS_APPOINTMENT = 'DOCTORS_APPOINTMENT',
  EXPENSES_DOCUMENTS = 'EXPENSES_DOCUMENTS',
  PASSPORT_OR_BIRTH_CERTIFICATE = 'PASSPORT_OR_BIRTH_CERTIFICATE',
  PAYMENT_RECIPIENTS_PASSPORT = 'PAYMENT_RECIPIENTS_PASSPORT',
  PAYMENT_RECIPIENTS_CODE = 'PAYMENT_RECIPIENTS_CODE',
  MEDICAL_PRACTICE_LICENSE_COPY = 'MEDICAL_PRACTICE_LICENSE_COPY',
  DEATH_CERTIFICATE = 'DEATH_CERTIFICATE',
  CAUSE_OF_DEATH_CERTIFICATE = 'CAUSE_OF_DEATH_CERTIFICATE',
  INHERITANCE_DOCUMENT = 'INHERITANCE_DOCUMENT',
  CONTRACT_CLAIM_REPORT = 'CONTRACT_CLAIM_REPORT',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  PASSPORT_INSURANCE = 'PASSPORT_INSURANCE',
  PASSPORTINSURANCEPAGE2 = 'PASSPORT_INSURANCE_PAGE_2',
  PASSPORTINSURANCEPAGE3 = 'PASSPORT_INSURANCE_PAGE_3',
  IDENTIFICATION_NUMBER_INSURANCE = 'IDENTIFICATION_NUMBER_INSURANCE',
  PASSPORT_DRIVER = 'PASSPORT_DRIVER',
  PASSPORTDRIVERPAGE2 = 'PASSPORT_DRIVER_PAGE_2',
  PASSPORTDRIVERPAGE3 = 'PASSPORT_DRIVER_PAGE_3',
  IDENTIFICATION_NUMBER_DRIVER = 'IDENTIFICATION_NUMBER_DRIVER',
  VEHICLE_REGISTRATION_CERTIFICATE = 'VEHICLE_REGISTRATION_CERTIFICATE',
  AUTHORITY_RELEVANT_CERTIFICATE = 'AUTHORITY_RELEVANT_CERTIFICATE',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  CALCULATION_SERVICE_STATION = 'CALCULATION_SERVICE_STATION',
  OWNERSHIP_DOCUMENT = 'OWNERSHIP_DOCUMENT',
  SERVICE_DEPARTMENT_DOCUMENTS = 'SERVICE_DEPARTMENT_DOCUMENTS',
  INVESTIGATION_DOCUMENT = 'INVESTIGATION_DOCUMENT',
  LIST_OF_DAMAGED_PROPERTY = 'LIST_OF_DAMAGED_PROPERTY',
  AMOUNT_OF_DAMAGES_CONFIRMATION_DOCUMENT = 'AMOUNT_OF_DAMAGES_CONFIRMATION_DOCUMENT',
  INSURANCE_CONTRACT = 'INSURANCE_CONTRACT',
  INSURED_VEHICLE_REGISTRATION_CERTIFICATE = 'INSURED_VEHICLE_REGISTRATION_CERTIFICATE',
  INSURED_DRIVER_LICENSE = 'INSURED_DRIVER_LICENSE',
  INSURED_PASSPORT_DRIVER = 'INSURED_PASSPORT_DRIVER',
  INSURED_IDENTIFICATION_NUMBER_DRIVER = 'INSURED_IDENTIFICATION_NUMBER_DRIVER',
  RECRUITMENT_ORDER = 'RECRUITMENT_ORDER',
  CASUALTY_VEHICLE_REGISTRATION_CERTIFICATE = 'CASUALTY_VEHICLE_REGISTRATION_CERTIFICATE',
  CASUALTY_DRIVER_LICENSE = 'CASUALTY_DRIVER_LICENSE',
  CASUALTY_PASSPORT_DRIVER = 'CASUALTY_PASSPORT_DRIVER',
  CASUALTY_IDENTIFICATION_NUMBER_DRIVER = 'CASUALTY_IDENTIFICATION_NUMBER_DRIVER',
  REFERENCE_FROM_THE_COMPETENT_AUTHORITIES_OF_THE_MINISTRY_OF_INTERNAL_AFFAIRS_MINISTRY_OF_EMERGENCIES_EUROPEAN_PROTOCOL_ETC = 'REFERENCE_FROM_THE_COMPETENT_AUTHORITIES_OF_THE_MINISTRY_OF_INTERNAL_AFFAIRS_MINISTRY_OF_EMERGENCIES_EUROPEAN_PROTOCOL_ETC',
  DECISION_OF_THE_ADMINISTRATIVE_COURT = 'DECISION_OF_THE_ADMINISTRATIVE_COURT',
  DOCUMENT_FROM_THE_SERVICE_STATION_EXAMINATION_CALCULATION_OF_THE_INSURER = 'DOCUMENT_FROM_THE_SERVICE_STATION_EXAMINATION_CALCULATION_OF_THE_INSURER',
  CERTIFICATE_OF_TEMPORARY_INCAPACITY_FOR_WORK_SICK_LEAVES_MEDICAL_REPORTS_AND_BILLS = 'CERTIFICATE_OF_TEMPORARY_INCAPACITY_FOR_WORK_SICK_LEAVES_MEDICAL_REPORTS_AND_BILLS',
  MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP = 'MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP',
  ORIGINAL_INHERITANCE_DOCUMENT = 'ORIGINAL_INHERITANCE_DOCUMENT',
  APPLICATION_FOR_PAYMENT_OF_THE_INSURED_CASUALTY = 'APPLICATION_FOR_PAYMENT_OF_THE_INSURED_CASUALTY',
  BENEFICIARY_LETTER = 'BENEFICIARY_LETTER',
  ACT_OF_INSPECTION = 'ACT_OF_INSPECTION',
  PHOTO_OF_A_DAMAGED_CAR = 'PHOTO_OF_A_DAMAGED_CAR',
  RESOLUTION_OF_THE_ADMINISTRATIVE_COURT_OR_RESOLUTION_ON_THE_CLOSURE_OF_CRIMINAL_PROCEEDINGS = 'RESOLUTION_OF_THE_ADMINISTRATIVE_COURT_OR_RESOLUTION_ON_THE_CLOSURE_OF_CRIMINAL_PROCEEDINGS',
  SALE_REGISTER_ANALOGUE_PHOTO = 'SALE_REGISTER_ANALOGUE_PHOTO',
  SALEREGISTERPHOTO1 = 'SALE_REGISTER_PHOTO_1',
  SALEREGISTERPHOTO2 = 'SALE_REGISTER_PHOTO_2',
  SALEREGISTERPHOTO3 = 'SALE_REGISTER_PHOTO_3',
  SALEREGISTERPHOTO4 = 'SALE_REGISTER_PHOTO_4',
  SALEREGISTERPHOTO5 = 'SALE_REGISTER_PHOTO_5',
  INSURANCE_COMPANY_LOGO = 'INSURANCE_COMPANY_LOGO',
  SERVICE_CENTER_EXPERTISE = 'SERVICE_CENTER_EXPERTISE',
  PASSPORT_DMS_INSURANCE_PROGRAM = 'PASSPORT_DMS_INSURANCE_PROGRAM',
  PASSPORT_OF_PAYER_OR_OTHER_DOCUMENT = 'PASSPORT_OF_PAYER_OR_OTHER_DOCUMENT',
  REFERRALS_FOR_CONSULTATIONS = 'REFERRALS_FOR_CONSULTATIONS',
  COPIES_OF_SURVEY_OR_RESEARCH_RESULTS = 'COPIES_OF_SURVEY_OR_RESEARCH_RESULTS',
  PRESCRIPTIONS_OR_OTHER_MEDICAL_DOCUMENTS_INDICATING_THE_NAME_OF_THE_INSURED_PERSON = 'PRESCRIPTIONS_OR_OTHER_MEDICAL_DOCUMENTS_INDICATING_THE_NAME_OF_THE_INSURED_PERSON',
  EXTRACT_FROM_OUTPATIENT_CARD = 'EXTRACT_FROM_OUTPATIENT_CARD',
  EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT = 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT',
  ORIGINAL_PAYMENT_DOCUMENT = 'ORIGINAL_PAYMENT_DOCUMENT',
  ACT_OF_PERFORMED_WORKS_RENDERED_SERVICE_THAT_PROVIDED_SERVICE = 'ACT_OF_PERFORMED_WORKS_RENDERED_SERVICE_THAT_PROVIDED_SERVICE',
  LICENSE_FOR_THE_IMPLEMENTATION_OF_RELEVANT_TYPE_OF_MEDICAL_PRACTICE_EXCEPT_PUBLIC_MEDICAL_INSTITUTIONS = 'LICENSE_FOR_THE_IMPLEMENTATION_OF_RELEVANT_TYPE_OF_MEDICAL_PRACTICE_EXCEPT_PUBLIC_MEDICAL_INSTITUTIONS',
  COPY_CONTRACT = 'COPY_CONTRACT',
  ORGANIZATION_ACCOUNT = 'ORGANIZATION_ACCOUNT',
  COPY_DOCUMENT_ACCIDENT_FACT = 'COPY_DOCUMENT_ACCIDENT_FACT',
  DISABLED_CHILD = 'DISABLED_CHILD',
  PASSPORT_RUS = 'PASSPORT_RUS',
  COPY_MEDICAL_CERTIFICATE_RUS = 'COPY_MEDICAL_CERTIFICATE_RUS',
  ORGANIZATION_ACCOUNT_RUS = 'ORGANIZATION_ACCOUNT_RUS',
  INHERITANCE_DOCUMENT_RUS = 'INHERITANCE_DOCUMENT_RUS',
  EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT_RUS = 'EXTRACT_FROM_HISTORY_OF_DISEASE_IN_CASE_OF_INPATIENT_TREATMENT_RUS',
  COPY_DOCUMENT_ACCIDENT_FACT_RUS = 'COPY_DOCUMENT_ACCIDENT_FACT_RUS',
  COPY_MEDICAL_CERTIFICATE_OF_MEDICAL_SOCIAL_COMMISSION_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS = 'COPY_MEDICAL_CERTIFICATE_OF_MEDICAL_SOCIAL_COMMISSION_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS',
  COPY_MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS = 'COPY_MEDICAL_CERTIFICATE_ON_THE_ASSIGNMENT_OF_A_DISABILITY_GROUP_RUS',
  OTHER_DOCUMENTS_RUS = 'OTHER_DOCUMENTS_RUS',
  CHECK_PURCHASE_MEDICINES = 'CHECK_PURCHASE_MEDICINES',
  ORIGINALS_RECIPES = 'ORIGINALS_RECIPES',
  PHOTO_DEVICE = 'PHOTO_DEVICE',
  PARTNER_LOGO = 'PARTNER_LOGO',
  PARTNER_LOGO_MINI = 'PARTNER_LOGO_MINI',
}

export interface DocumentTypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code: DocumentTypeCode;
}

export type ElectronicDeviceInsuranceObjectDto = UtilRequiredKeys<InsuranceObjectDto, 'id' | 'type'>;

export type EstateInsuranceObjectDto = UtilRequiredKeys<InsuranceObjectDto, 'id' | 'type'>;

export interface InsuranceObjectDto {
  /** @format int64 */
  id: number;
  type: InsuranceObjectType;
  objectSubtypeName?: string;
  name?: string;
  pid?: string;
}

export enum InsuranceObjectType {
  ELECTRONIC_DEVICE = 'ELECTRONIC_DEVICE',
  PERSON = 'PERSON',
  VEHICLE = 'VEHICLE',
  ESTATE = 'ESTATE',
}

export enum OwnerEquipmentType {
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  INSURER = 'INSURER',
  BENEFICIARY = 'BENEFICIARY',
}

export enum PartnerType {
  SERVICE_COMPANY = 'SERVICE_COMPANY',
  INSURANCE_AGENT = 'INSURANCE_AGENT',
  TEST_PARTNER = 'TEST_PARTNER',
}

export type PersonInsuranceObjectDto = UtilRequiredKeys<InsuranceObjectDto, 'id' | 'type'> & {
  /** @format date */
  birthday?: string;
  client?: boolean;
};

export enum ResolutionType {
  EXPRESS_PAYMENT = 'EXPRESS_PAYMENT',
  SHOP_SERVICE_REPAIR = 'SHOP_SERVICE_REPAIR',
  SELF_SERVICE_REPAIR = 'SELF_SERVICE_REPAIR',
  PAYMENT = 'PAYMENT',
  SERVICE_STATION_PAYMENT = 'SERVICE_STATION_PAYMENT',
  MEDICAL_ASSISTANCE = 'MEDICAL_ASSISTANCE',
  MEDICAL_INSTITUTION = 'MEDICAL_INSTITUTION',
  INSURED = 'INSURED',
}

export interface SaleRegisterDocumentDto {
  /** @format int64 */
  saleRegisterId?: number;
  obligatory?: boolean;
  equipmentSalePlatform?: string;
  equipmentSalePlatformUrl?: string;
  analogueCost?: number;
  cost?: number;
  documentType: DocumentTypeDto;
  /** @format date-time */
  receiptDate?: string;
  editable?: boolean;
}

export interface SaleRegisterDto {
  /** @format int64 */
  id?: number;
  statusType?: StatusType;
  ownerEquipmentType?: OwnerEquipmentType;
  insuranceObject?:
    | ElectronicDeviceInsuranceObjectDto
    | EstateInsuranceObjectDto
    | PersonInsuranceObjectDto
    | VehicleInsuranceObjectDto;
  contractNumber?: string;
  insuranceSum?: number;
  saleRevenue?: number;
  resolutionType?: ResolutionType;
  claimStatus?: ClaimStatus;
  /** @format date-time */
  incidentDate?: string;
  partnerDepartmentPayout?: DepartmentDto;
  paymentSum?: number;
  deliveryType?: DeliveryType;
  invoiceNumber?: string;
  repairEquipment?: RepairEquipmentDto;
  equipmentSalePlatform?: string;
  documents?: SaleRegisterDocumentDto[];
  /** @format date-time */
  saleDate?: string;
  salePrice?: number;
  dispatchAfterSale?: DispatchAfterSaleDto;
}

export type VehicleInsuranceObjectDto = UtilRequiredKeys<InsuranceObjectDto, 'id' | 'type'> & {
  brand?: string;
  model?: string;
  vinNumber?: string;
  /** @format date */
  manufactureDate?: string;
};

export interface PartnerRegionDto {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  creatorName?: string;
  /** @format date */
  registrationDate: string;
  /** @format date */
  activationDate: string;
  /** @format date */
  deactivationDate: string;
}

export enum DiscountUnit {
  PERCENT = 'PERCENT',
  COMMISSION = 'COMMISSION',
  MONEY_SUM = 'MONEY_SUM',
}

export interface PartnerDiscountDto {
  /** @format int64 */
  id?: number;
  type?: PartnerDiscountType;
  allowed?: boolean;
  agentContracts?: number[];
  minAmount?: number;
  maxAmount?: number;
  currency?: string;
  unit?: DiscountUnit;
}

export enum PartnerDiscountType {
  PROGRAM_DISCOUNT = 'PROGRAM_DISCOUNT',
  CONTRACT_DISCOUNT = 'CONTRACT_DISCOUNT',
}

export interface PartnerProgramParametersDto {
  /** @format int32 */
  expressAutoPaymentTimeoutInMin?: number;
  /** @format int32 */
  expressPaymentWorkingDays?: number;
  clientSignContractEnabled?: boolean;
  /** @format int64 */
  partnerId?: number;
  partnerDiscounts?: PartnerDiscountDto[];
}

export enum CounterpartyType {
  PERSON = 'PERSON',
  ENTREPRENEUR = 'ENTREPRENEUR',
  COMPANY = 'COMPANY',
}

export interface PartnerDto {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  shortName: string;
  /**
   * @minLength 1
   * @maxLength 3
   */
  code: string;
  type: PartnerType;
  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceCompanyName?: string;
  /**
   * @minLength 8
   * @maxLength 8
   */
  enterpriseCode?: string;
  /**
   * @minLength 10
   * @maxLength 10
   */
  inn?: string;
  /** @format date */
  createdDate: string;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
  phoneNumber: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  directorFullName: string;
  active?: boolean;
  /**
   * @minLength 0
   * @maxLength 100
   */
  acquirerBank?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  acquirerBankUrl?: string;
  address?: AddressDto;
  whiteLabeling?: object;
  partnerType: CounterpartyType;
  insuranceCompanies?: OptionDto[];
}

export enum ContractConclusionFlowDto {
  REGULAR = 'REGULAR',
  WIDGET = 'WIDGET',
  ANY = 'ANY',
}

export enum ContractDraftStoragePeriodType {
  TWO_MONTH = 'TWO_MONTH',
  MONTH = 'MONTH',
  THREE_MONTH = 'THREE_MONTH',
  SIX_MONTH = 'SIX_MONTH',
  ONE_YEAR = 'ONE_YEAR',
}

export enum ContractNumberFormat {
  GENERAL_TEN_DIGITS = 'GENERAL_TEN_DIGITS',
  CUSTOM_FIVE_DIGITS = 'CUSTOM_FIVE_DIGITS',
  WITHOUT_TEMPLATE = 'WITHOUT_TEMPLATE',
  SPECIFIC_ALPHA_NUMERIC = 'SPECIFIC_ALPHA_NUMERIC',
  CUSTOM_NINE_DIGITS = 'CUSTOM_NINE_DIGITS',
}

export enum ContractVerificationTypeDto {
  MANUAL = 'MANUAL',
  AUTO_AT_SIGNING = 'AUTO_AT_SIGNING',
  AUTO_AFTER_PAYMENT = 'AUTO_AFTER_PAYMENT',
}

export interface PartnerProgramAgentDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  agentId?: number;
  addedOnProgram?: boolean;
  name?: string;
  feePercent?: number;
  insuranceAdjustmentRate?: number;
  depreciationAdjustmentRate?: number;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
}

export interface PartnerProgramDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
  /** @format int32 */
  expressAutoPaymentTimeoutInMin?: number;
  /** @format int32 */
  expressPaymentWorkingDays?: number;
  partnerProgramAgents?: PartnerProgramAgentDto[];
  aggregationEnabled?: boolean;
  /** @format int64 */
  aggregatorRootPartnerId?: number;
  deferredPaymentEnabled?: boolean;
  /** @format int32 */
  countPayments?: number;
  autoGenerationNumberEnabled?: boolean;
  roundingType?: RoundingType;
  programRoundingType?: RoundingType;
  contractDraftTtl?: ContractDraftStoragePeriodType;
  programContractDraftTtl?: ContractDraftStoragePeriodType;
  contractNumberFormat?: ContractNumberFormat;
  contractConclusionFlow?: ContractConclusionFlowDto;
  contractVerificationType?: ContractVerificationTypeDto;
}

export enum RoundingType {
  HUNDREDTHS = 'HUNDREDTHS',
  TENTHS = 'TENTHS',
  UNITS = 'UNITS',
  TENS = 'TENS',
  HUNDREDS = 'HUNDREDS',
  THOUSANDS = 'THOUSANDS',
}

export interface BrandDto {
  /** @format int64 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  brand: string;
  type: InsuranceObjectType;
  /**
   * @minLength 0
   * @maxLength 50
   */
  subtype: string;
}

export type CompanyCounterpartyDto = CounterpartyDto & {
  fullName?: string;
  enterpriseCode?: string;
};

export interface CounterpartyDto {
  /** @format int64 */
  id?: number;
  name?: string;
  type?: CounterpartyType;
  phoneNumber?: string;
  email?: string;
  locality?: string;
  street?: string;
}

export interface CurrencyDto {
  code?: string;
  name?: string;
  symbol?: string;
}

export enum CurrencyProvider {
  UKRAINE = 'UKRAINE',
  TAJIKISTAN = 'TAJIKISTAN',
  PRIVATE_BANK = 'PRIVATE_BANK',
}

export type EntrepreneurCounterpartyDto = CounterpartyDto & {
  lastName?: string;
  firstName?: string;
  middleName?: string;
};

export interface FileStorageDto {
  type?: FileStorageEntityTypeDto;
  storageFiles?: StorageFileDto[];
  documentTypeCode?: DocumentTypeCode;
  /** @format int64 */
  storageFileTotalPage?: number;
}

export enum FileStorageEntityTypeDto {
  CONTRACT_CLAIM_DOCUMENT = 'CONTRACT_CLAIM_DOCUMENT',
  CONTRACT_TERMINATION_DOCUMENT = 'CONTRACT_TERMINATION_DOCUMENT',
  ML_EXPORT_DOCUMENT = 'ML_EXPORT_DOCUMENT',
  SALE_REGISTER_DOCUMENT = 'SALE_REGISTER_DOCUMENT',
  INSURANCE_COMPANY_LOGO = 'INSURANCE_COMPANY_LOGO',
  INSURANCE_PROGRAM_DOCUMENT = 'INSURANCE_PROGRAM_DOCUMENT',
  CONTRACT_DOCUMENT = 'CONTRACT_DOCUMENT',
  CONTRACT_REPORT = 'CONTRACT_REPORT',
  PARTNER_LOGO = 'PARTNER_LOGO',
}

export interface FileWrapperDto {
  contentType?: string;
  filename?: string;
  data?: string[];
}

export interface InsuranceCompanyDto {
  /** @format int64 */
  id?: number;
  code?: string;
  /**
   * @minLength 1
   * @maxLength 5
   */
  abbreviation: string;
  /**
   * @minLength 1
   * @maxLength 11
   */
  enterpriseCode: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  name: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  shortName: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  fullName: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  phoneNumber: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  contactPersonFullName?: string;
  /**
   * @minLength 1
   * @maxLength 510
   */
  postAddress: string;
  email?: string;
  siteUrl?: string;
  /**
   * @minLength 1
   * @maxLength 34
   */
  ibanAccountNumber: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  bankName?: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  bankPostAddress?: string;
  /**
   * @minLength 1
   * @maxLength 8
   */
  bankEnterpriseCode?: string;
  directorPosition?: string;
  directorFullName?: string;
  customerSupportPhoneNumber?: string;
  customerSupportEmail?: string;
  customerSupportUrl?: string;
  partnerSupportPhoneNumber?: string;
  telegramChannel?: string;
  contactPerson?: CounterpartyDto | CompanyCounterpartyDto | EntrepreneurCounterpartyDto | PersonCounterpartyDto;
  insuranceManager?: CounterpartyDto | CompanyCounterpartyDto | EntrepreneurCounterpartyDto | PersonCounterpartyDto;
  claimManager?: CounterpartyDto | CompanyCounterpartyDto | EntrepreneurCounterpartyDto | PersonCounterpartyDto;
  /** @format date-time */
  createdDate?: string;
  readOnlyFields?: string[];
  smsSenderName?: string;
  fileStorage?: FileStorageDto;
  currency?: CurrencyDto;
  lang?: string;
  nationalBankUrl?: string;
  country: string;
  whiteLabeling?: object;
  currencyProvider?: CurrencyProvider;
}

export type PersonCounterpartyDto = CounterpartyDto & {
  lastName?: string;
  firstName?: string;
  middleName?: string;
  /** @format date */
  birthday?: string;
};

export interface StorageFileDto {
  key?: string;
  fileName?: string;
  /** @format date-time */
  createdDate?: string;
  fileWrapper?: FileWrapperDto;
}

export interface DeductionDto {
  /** @format int64 */
  id?: number;
  name?: string;
  value?: number;
}

export type ChornobylCertificateDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 1
   * @maxLength 4
   */
  serial: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export enum ChronoUnitSimplifiedDuplicate {
  NANOS = 'NANOS',
  MICROS = 'MICROS',
  MILLIS = 'MILLIS',
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  HALF_DAYS = 'HALF_DAYS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
  DECADES = 'DECADES',
  CENTURIES = 'CENTURIES',
  MILLENNIA = 'MILLENNIA',
  ERAS = 'ERAS',
  FOREVER = 'FOREVER',
}

export interface ContractBeneficiaryDto {
  type: CounterpartyType;
  /**
   * @minLength 5
   * @maxLength 30
   */
  phoneNumber: string;
  email?: string;
  /**
   * @minLength 0
   * @maxLength 0
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  middleName?: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  companyName: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  locality: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  street: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  citizenship?: string;
  /**
   * @minLength 10
   * @maxLength 10
   */
  innCode?: string;
  /** @format date */
  dateBirthday: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  investContract?: string;
  /** @format date */
  investContractFrom: string;
  identifyDocumentType: IdentifyDocumentType;
  /**
   * @minLength 0
   * @maxLength 3
   */
  passportSeries: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  passportNumber: string;
  /**
   * @minLength 0
   * @maxLength 200
   */
  passportCreator: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  idCardRecord: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  idCardNumber: string;
  /** @format date */
  idCardDateCreate: string;
  insuranceProgramCode: string;
  enterpriseCode?: string;
  counterpartyType?: CounterpartyType;
}

export interface ContractDiscountDto {
  /** @format int64 */
  id?: number;
  discount?: number;
  discountSum?: number;
  insuranceCompanyCoefficient?: number;
  partnerCommissionCoefficient?: number;
  partnerCoefficient?: number;
  promoCodeCoefficient?: number;
  promoCode?: string;
  discountMeasurementType?: DiscountMeasurementType;
  isPromoCodeValid?: boolean;
}

export interface ContractDocumentDto {
  /** @format int64 */
  contractId?: number;
  documentType: DocumentTypeDto;
  /** @format date-time */
  receiptDate?: string;
  attachment?: boolean;
  allowMultipleFiles?: boolean;
}

export interface ContractDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  terminationId?: number;
  contractNumberPrefix?: string;
  contractNumberMainPart: string;
  contractNumberEnding: string;
  contractNumber: string;
  originalContractNumber?: string;
  /** @format int64 */
  originalContractId?: number;
  originalContractPaymentAmount?: number;
  originalContractInsuranceRate?: number;
  current?: boolean;
  /** @format int32 */
  subAgreementNumber?: number;
  lastSubAgreement?: boolean;
  status?: ContractStatus;
  /** @format date-time */
  conclusionDate?: string;
  /** @format date-time */
  signDate?: string;
  /** @format date-time */
  verifiedAt?: string;
  /** @format int32 */
  duration?: number;
  durationUnit: ChronoUnitSimplifiedDuplicate;
  /** @format int64 */
  clientId?: number;
  signatory: SignatoryDto;
  /** @format int64 */
  beneficiaryId?: number;
  contractBeneficiary: ContractBeneficiaryDto;
  contractObjects: (ElectronicDeviceObjectDto | EstateObjectDto | PersonObjectDto | VehicleObjectDto)[];
  /** @format int32 */
  contractObjectsCount?: number;
  sellerPhoneNumber: string;
  sellerName?: string;
  insuranceProgram?: OptionDto;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  subAgreementConclusionDate?: string;
  /** @format date-time */
  subAgreementStartDate?: string;
  /** @format date-time */
  subAgreementEndDate?: string;
  /** @format date-time */
  prolongationConclusionDate?: string;
  /** @format date-time */
  prolongationStartDate?: string;
  /** @format date-time */
  prolongationEndDate?: string;
  insuranceSum?: number;
  insuranceSumCurrency?: string;
  exchangedInsuranceSum?: number;
  exchangedInsuranceSumCurrency?: string;
  insuranceRate?: number;
  paymentAmount?: number;
  paymentAmountCurrency?: string;
  calculatedInsuranceRate: number;
  calculatedPaymentAmount: number;
  paymentStatus?: PaymentStatus;
  installmentPayment?: boolean;
  contractInstallmentPayment?: ContractInstallmentPaymentDto;
  hasDraftSubAgreement?: boolean;
  /** @format int64 */
  partnerId?: number;
  insuranceProductCode?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  prolongation?: boolean;
  hasClaims?: boolean;
  prolongated?: boolean;
  needClientSign?: boolean;
  clientSigned?: boolean;
  russianCitizen?: boolean;
  /** @format date-time */
  sentForSigningDate?: string;
  /** @format date-time */
  originalContractSignDate?: string;
  insurancePolicy?: OptionDto;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format uuid */
  externalId?: string;
  programChanging?: boolean;
  /** @format int64 */
  prolongateFromId?: number;
  /** @format int64 */
  insuranceSumId?: number;
  contractInfo?: object;
  documents?: ContractDocumentDto[];
  prolongationPaymentAmount?: number;
  notes?: string;
  parentContract?: RelatedContractDto;
  childContract?: RelatedContractDto;
  relationType?: RelationType;
  /** @format int64 */
  parentContractId?: number;
  paymentCurrency?: Currency;
  sumCurrency?: Currency;
  lang?: string;
  contractDiscount?: ContractDiscountDto;
  lastSendingType?: SendingType;
  paymentEmailNotificationCanceled?: boolean;
  contractNumberFormat?: ContractNumberFormat;
  canProlongation?: boolean;
}

export interface ContractInstallmentPaymentDto {
  installmentPaymentPeriodType?: InstallmentPaymentPeriodType;
}

export interface ContractObjectDto {
  /** @format int64 */
  id?: number;
  type: InsuranceObjectType;
  risks?: ContractObjectRiskDto[];
  pid?: string;
}

export interface ContractObjectRiskDto {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  riskNumber?: number;
  name?: string;
  insuranceAmount?: number;
  paymentAmount?: number;
  calculatedPaymentAmount?: number;
  franchiseValueType?: FranchiseValueType;
  franchise?: number;
  exchangedInsuranceAmount?: number;
  exchangedCurrency?: string;
  currency?: string;
  paymentAmountCurrencyNotAccounted?: number;
}

export enum ContractStatus {
  DRAFT = 'DRAFT',
  DECLAIMED = 'DECLAIMED',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
}

export enum ContractTypeGroupType {
  PAPER_DOCUMENT = 'PAPER_DOCUMENT',
  ELECTRONIC_DOCUMENT = 'ELECTRONIC_DOCUMENT',
}

export interface Currency {
  code?: string;
  symbol?: string;
  name?: string;
}

export type DisabilityCertificateDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 1
   * @maxLength 4
   */
  serial: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export enum DiscountMeasurementType {
  ABSOLUTE_VALUE = 'ABSOLUTE_VALUE',
  PERCENT = 'PERCENT',
}

export type DriverLicenseDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 3
   * @maxLength 3
   */
  serial: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export type ElectronicDeviceObjectDto = UtilRequiredKeys<ContractObjectDto, 'type' | 'pid'> & {
  /** @format int64 */
  subtypeId?: number;
  subtypeName?: string;
  /**
   * @minLength 2
   * @maxLength 36
   */
  pid: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  brand: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  model: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  checkNumber: string;
  /** @format date-time */
  saleDate: string;
  saleSum: number;
};

export type ElectronicPensionerCertificateDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export type EstateObjectDto = UtilRequiredKeys<ContractObjectDto, 'type'> & {
  /** @format int64 */
  subtypeId: number;
  subtypeName: string;
  subtypeCode?: ObjectSubtypeCode;
  locality: string;
  street: string;
  estateObjectType: EstateObjectType;
  insuranceSum?: number;
  insuranceRate?: number;
  paymentAmount?: number;
  calculatedPaymentAmount?: number;
  franchise?: number;
  franchiseValueType?: FranchiseValueType;
};

export enum EstateObjectType {
  APARTMENT = 'APARTMENT',
  PRIVATE_HOUSE = 'PRIVATE_HOUSE',
  COTTAGE = 'COTTAGE',
  TOWN_HOUSE = 'TOWN_HOUSE',
}

export type ForeignerPassportDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 4
   * @maxLength 4
   */
  serial: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
};

export enum FranchiseValueType {
  PERCENT = 'PERCENT',
  FIXED_SUM = 'FIXED_SUM',
  NO_VALUE = 'NO_VALUE',
}

export type IDInternalPassportDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 0
   * @maxLength 14
   */
  recordNumber?: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
};

export enum IdentifyDocumentType {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
}

export interface IdentityDocumentDto {
  /** @format int64 */
  id?: number;
  number: string;
  type: IdentityDocumentType;
  /** @format int64 */
  counterpartyId?: number;
}

export enum IdentityDocumentType {
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  ID_PASSPORT = 'ID_PASSPORT',
  INTERNAL_PASSPORT = 'INTERNAL_PASSPORT',
  INTERNATIONAL_PASSPORT = 'INTERNATIONAL_PASSPORT',
  TAXPAYER_CARD = 'TAXPAYER_CARD',
  CHORNOBYL_CERTIFICATE = 'CHORNOBYL_CERTIFICATE',
  DISABILITY_CERTIFICATE = 'DISABILITY_CERTIFICATE',
  ELECTRONIC_PENSIONER_CERTIFICATE = 'ELECTRONIC_PENSIONER_CERTIFICATE',
  PENSIONER_CERTIFICATE = 'PENSIONER_CERTIFICATE',
  ID_RESIDENCE_PERMIT = 'ID_RESIDENCE_PERMIT',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  VETERAN_CERTIFICATE = 'VETERAN_CERTIFICATE',
  FOREIGNER_PASSPORT = 'FOREIGNER_PASSPORT',
}

export enum InstallmentPaymentPeriodType {
  ONE_PAYMENT = 'ONE_PAYMENT',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export type InternalPassportDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 2
   * @maxLength 2
   */
  serial: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
};

export type InternationalPassportDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 2
   * @maxLength 2
   */
  serial: string;
  recordNumber?: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export enum ObjectSubtypeCode {
  STRUCTURAL_ELEMENTS = 'STRUCTURAL_ELEMENTS',
  INTERIOR_OBJECT = 'INTERIOR_OBJECT',
  CIVIL_RESPONSIBILITY = 'CIVIL_RESPONSIBILITY',
  MOVABLE = 'MOVABLE',
}

export enum PaymentStatus {
  UNPAID = 'UNPAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
}

export type PensionerCertificateDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  serial?: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export type PersonObjectDto = UtilRequiredKeys<ContractObjectDto, 'type'> & {
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  middleName?: string;
  /** @format date */
  birthday: string;
  client: boolean;
  objectSum?: number;
  paymentAmount?: number;
  calculatedPaymentAmount: number;
  email?: string;
  /**
   * @minLength 1
   * @maxLength 25
   */
  passport?: string;
  /**
   * @minLength 0
   * @maxLength 14
   */
  pinfl?: string;
  paymentAmountCurrencyNotAccounted?: number;
  paymentAmountForObject?: number;
};

export enum PreferentialGroupType {
  WAR_VETERAN = 'WAR_VETERAN',
  DISABILITYGROUP2 = 'DISABILITY_GROUP_2',
  CHERNOBYLCATEGORY12 = 'CHERNOBYL_CATEGORY_1_2',
  PENSIONER = 'PENSIONER',
  NO_PRIVILEGE = 'NO_PRIVILEGE',
}

export enum RegistrationZoneGroupType {
  ZONE1 = 'ZONE_1',
  ZONE2 = 'ZONE_2',
  ZONE3 = 'ZONE_3',
  ZONE4 = 'ZONE_4',
  ZONE5 = 'ZONE_5',
  ZONE6 = 'ZONE_6',
}

export interface RelatedContractDto {
  /** @format int64 */
  contractId?: number;
  contractNumber?: string;
  /** @format date-time */
  contractSignDate?: string;
  relationType?: RelationType;
}

export enum RelationType {
  COPY = 'COPY',
  PROLONGATION = 'PROLONGATION',
  RE_REGISTRATION = 'RE_REGISTRATION',
  SUB_AGREEMENT = 'SUB_AGREEMENT',
  NONE = 'NONE',
}

export type ResidenceIDPermitDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 0
   * @maxLength 14
   */
  recordNumber?: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export type ResidencePermitDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 2
   * @maxLength 2
   */
  serial: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export enum SendingType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export interface SignatoryDto {
  type: CounterpartyType;
  /**
   * @minLength 5
   * @maxLength 30
   */
  phoneNumber: string;
  email?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  middleName?: string;
  /** @format date */
  birthday?: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  companyName: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  locality: string;
  /**
   * @minLength 1
   * @maxLength 500
   */
  street: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  citizenship?: string;
  /**
   * @minLength 1
   * @maxLength 25
   */
  passport?: string;
  /**
   * @minLength 0
   * @maxLength 14
   */
  pinfl?: string;
  /**
   * @minLength 0
   * @maxLength 10
   */
  inn: string;
  insuranceProgramCode: string;
  enterpriseCode?: string;
  identityDocument?:
    | ChornobylCertificateDto
    | DisabilityCertificateDto
    | DriverLicenseDto
    | ElectronicPensionerCertificateDto
    | ForeignerPassportDto
    | IDInternalPassportDto
    | InternalPassportDto
    | InternationalPassportDto
    | PensionerCertificateDto
    | ResidenceIDPermitDto
    | ResidencePermitDto
    | TaxpayerCardDto
    | VeteranCertificateDto;
  counterpartyType?: CounterpartyType;
}

export type TaxpayerCardDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /** @format date */
  issuedAt?: string;
};

export type VehicleObjectDto = UtilRequiredKeys<ContractObjectDto, 'type' | 'pid'> & {
  /** @format int64 */
  subtypeId?: number;
  /**
   * @minLength 17
   * @maxLength 17
   */
  pid: string;
  brand: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  model: string;
  /**
   * @minLength 8
   * @maxLength 8
   */
  vinNumber: string;
  /** @format date */
  manufactureDate: string;
  isEuroNumber?: boolean;
  isRequiredOtk?: boolean;
  isUsedAsTaxi?: boolean;
  /** @format date */
  dateOfOtk?: string;
  registrationCity?: string;
  franchise?: number;
  brandId?: string;
  modelId?: string;
  registrationCityId?: string;
  vehicleType?: VehicleTypeGroupType;
  registrationZone?: RegistrationZoneGroupType;
  holderType?: CounterpartyType;
  contractType?: ContractTypeGroupType;
  preferential?: PreferentialGroupType;
};

export enum VehicleTypeGroupType {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  C1 = 'C1',
  C2 = 'C2',
  D1 = 'D1',
  D2 = 'D2',
  E = 'E',
  F = 'F',
}

export type VeteranCertificateDto = UtilRequiredKeys<IdentityDocumentDto, 'number' | 'type'> & {
  /**
   * @minLength 1
   * @maxLength 4
   */
  serial: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
};

export enum AccountTypeCode {
  CARD = 'CARD',
  IBAN = 'IBAN',
  CASH_DESK = 'CASH_DESK',
}

export interface ContractTerminationDocumentDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  terminationId?: number;
  obligatory?: boolean;
  documentType: DocumentTypeDto;
  /** @format date-time */
  receiptDate?: string;
  /** @format date-time */
  signDate?: string;
  information?: string;
  attachment?: boolean;
  refundSum?: number;
  informationObligatory?: boolean;
  sharingFromDia?: boolean;
  sharableFromDia?: boolean;
}

export interface ContractTerminationDto {
  /** @format int64 */
  id?: number;
  contract?: TerminationContractDto;
  terminationNumber?: string;
  /** @format date */
  terminationDate?: string;
  /** @format date */
  signDate?: string;
  /** @format date */
  estimatedRealEndDate?: string;
  /** @format date */
  realEndDate?: string;
  /** @format int32 */
  realTerm?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
  cause?: TerminationCauseDto;
  refundSum?: number;
  currency?: string;
  recipient: PaymentRecipientDto;
  documents: ContractTerminationDocumentDto[];
  initiator: TerminationInitiatorDto;
  contractStatus?: ContractStatus;
  otp?: string;
  terminationStatus?: ContractTerminationStatus;
  signatoryEmail?: string;
}

export enum ContractTerminationStatus {
  DRAFT = 'DRAFT',
  SIGNED = 'SIGNED',
  PAYMENT_AWAITING = 'PAYMENT_AWAITING',
  PAID = 'PAID',
}

export interface PaymentRecipientDto {
  /** @format int64 */
  id?: number;
  type?: PaymentRecipientType;
  counterpartyType?: CounterpartyType;
  phoneNumber?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  companyName?: string;
  locality?: string;
  street?: string;
  code: string;
  accountType: AccountTypeCode;
  accountNumber?: string;
  /**
   * @minLength 0
   * @maxLength 150
   */
  bankName?: string;
}

export enum PaymentRecipientType {
  CLIENT = 'CLIENT',
  SERVICE_CENTER = 'SERVICE_CENTER',
  CRS = 'CRS',
}

export enum TerminationCauseCode {
  DO_NOT_USE_DEVICE_INSURER = 'DO_NOT_USE_DEVICE_INSURER',
  DO_NOT_USE_DEVICE_CUSTOMER = 'DO_NOT_USE_DEVICE_CUSTOMER',
  VIOLATION_NON_FULFILLMENT_TERMS_CONTRACT_BY_INSURED = 'VIOLATION_NON_FULFILLMENT_TERMS_CONTRACT_BY_INSURED',
  DO_NOT_USE_VEHICLE_INSURER = 'DO_NOT_USE_VEHICLE_INSURER',
  DO_NOT_USE_VEHICLE_CUSTOMER = 'DO_NOT_USE_VEHICLE_CUSTOMER',
  CHANGE_TERMS = 'CHANGE_TERMS',
  WANT_TO_CHANGE_TERMS = 'WANT_TO_CHANGE_TERMS',
  ALREADY_HAVE_CONTRACT = 'ALREADY_HAVE_CONTRACT',
  NO_INSURANCE_REQUIRED = 'NO_INSURANCE_REQUIRED',
  RETURN_INSURED_DEVICE_TO_SELLER = 'RETURN_INSURED_DEVICE_TO_SELLER',
  EXCHANGE_OF_DEVICE = 'EXCHANGE_OF_DEVICE',
  EXHAUSTION_AGREEMENT_LIABILITY_LIMIT = 'EXHAUSTION_AGREEMENT_LIABILITY_LIMIT',
  NON_PAYMENT_BY_INSURED_NEXT_INSURANCE_PAYMENT = 'NON_PAYMENT_BY_INSURED_NEXT_INSURANCE_PAYMENT',
  OTHER_CAUSE = 'OTHER_CAUSE',
  DISMISSAL = 'DISMISSAL',
  NOT_PAYMENT = 'NOT_PAYMENT',
  INVALID_CONTRACT = 'INVALID_CONTRACT',
}

export interface TerminationCauseDto {
  /** @format int64 */
  id?: number;
  description?: string;
  code?: TerminationCauseCode;
  name?: string;
}

export interface TerminationContractDto {
  /** @format int64 */
  id?: number;
  contractNumber?: string;
  /** @format date */
  conclusionDate?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  insuranceProgram?: string;
  client?: string;
  insuranceSum?: number;
  insuranceSumCurrency?: string;
  paymentAmount?: number;
  insuranceProgramCode?: string;
  /** @format int64 */
  insuranceProgramId?: number;
  contractObjects?: (ElectronicDeviceObjectDto | EstateObjectDto | PersonObjectDto | VehicleObjectDto)[];
}

export enum TerminationInitiatorCode {
  INSURER = 'INSURER',
  POLICY_HOLDER = 'POLICY_HOLDER',
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  INSURANCE_COMPANY_MAINTENANCE = 'INSURANCE_COMPANY_MAINTENANCE',
  INSURANCE_COMPANY_NO_MONEY_BACK = 'INSURANCE_COMPANY_NO_MONEY_BACK',
}

export interface TerminationInitiatorDto {
  /** @format int64 */
  id?: number;
  code?: TerminationInitiatorCode;
  name?: string;
}

export interface ClaimApplicationDto {
  /** @format int64 */
  id?: number;
  role?: UserRole;
  applicantName?: string;
  /** @format date-time */
  applicationDate?: string;
  details?: string;
  description?: string;
}

export interface ClaimContractDto {
  /** @format int64 */
  id?: number;
  contractNumber?: string;
  /** @format date-time */
  conclusionDate?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  prolongationStartDate?: string;
  currency?: string;
  status?: ContractStatus;
  client?: SignatoryDto;
  beneficiary?: string;
  paymentStatus?: PaymentStatus;
  insuranceProgramCode?: string;
  /** @format int64 */
  insuranceProgramId?: number;
}

export interface ClaimStatusHistoryDto {
  claimStatus?: ClaimStatus;
  /** @format date-time */
  acceptDate?: string;
}

export interface ContractClaimDocumentDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  claimId?: number;
  obligatory?: boolean;
  documentType: DocumentTypeDto;
  information?: string;
  informationObligatory?: boolean;
  /** @format date-time */
  receiptDate?: string;
  claimSum?: number;
  /** @format date-time */
  signDate?: string;
  attachment?: boolean;
  calculable?: boolean;
  calculationParticipation?: boolean;
  visible?: boolean;
  paymentSum?: number;
  allowMultipleFiles?: boolean;
}

export interface ContractClaimDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
  claimNumber?: string;
  claimStatus?: ClaimStatus;
  contract?: ClaimContractDto;
  originalContract?: ClaimContractDto;
  pidVerified?: boolean;
  insuranceObject?:
    | ElectronicDeviceInsuranceObjectDto
    | EstateInsuranceObjectDto
    | PersonInsuranceObjectDto
    | VehicleInsuranceObjectDto;
  /** @format int64 */
  riskId?: number;
  /** @format date-time */
  claimDate?: string;
  /** @format date-time */
  signDate?: string;
  /** @format date-time */
  incidentDate: string;
  claimInfo: Record<string, string>;
  claimSum?: number;
  expertSum?: number;
  documents?: ContractClaimDocumentDto[];
  paymentRecipient?: PaymentRecipientDto;
  objectRecipient?: ObjectRecipientDto;
  contactPhone?: string;
  contactEmail?: string;
  resolutionType?: ResolutionType;
  paymentType?: PaymentType;
  partnerManagerPhoneNumber?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  partnerManagerName?: string;
  claimStatusHistoryDtoList?: ClaimStatusHistoryDto[];
  serviceCenter?: ServiceCenterDto;
  applications?: ClaimApplicationDto[];
  paymentRejectionCause?: OptionDto;
  politicallySignificant?: boolean;
  payoutType?: PayoutType;
  signatoryEmail?: string;
}

export interface ObjectRecipientDto {
  /** @format int64 */
  id?: number;
  type?: PaymentRecipientType;
  counterpartyType?: CounterpartyType;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  companyName?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  destination: string;
  code: string;
  phoneNumber: string;
}

export enum PaymentType {
  EXPRESS = 'EXPRESS',
  ORDINARY = 'ORDINARY',
}

export enum PayoutType {
  ACCOUNTING = 'ACCOUNTING',
  PAYMENT_SYSTEM = 'PAYMENT_SYSTEM',
}

export interface ServiceCenterDto {
  name?: string;
  address?: string;
  phoneNumber?: string;
}

export enum AdditionalFeeUnitType {
  PERCENT = 'PERCENT',
  SUM = 'SUM',
}

export enum AgentActCode {
  ACT_ONE_CODE = 'ACT_ONE_CODE',
  ACT_TWO_CODE = 'ACT_TWO_CODE',
  ACT_THREE_CODE = 'ACT_THREE_CODE',
}

export interface AgentDto {
  /** @format int64 */
  id?: number;
  name?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  middleName?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName?: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  companyName?: string;
  counterpartyType?: CounterpartyType;
  address?: AddressDto;
  /**
   * @minLength 8
   * @maxLength 8
   */
  enterpriseCode?: string;
  /**
   * @minLength 10
   * @maxLength 10
   */
  inn?: string;
  /**
   * @minLength 13
   * @maxLength 13
   */
  phoneNumber: string;
  email: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  agentContractNumber: string;
  /** @format date-time */
  agentContractDate: string;
  /** @format int64 */
  partnerId: number;
  agentActCode?: AgentActCode;
  partnerSignatoryFullName?: string;
  partnerSignatoryPosition?: string;
  foundingDocument?: string;
  additionalFee?: number;
  additionalFeeUnit?: AdditionalFeeUnitType;
  currency?: CurrencyType;
  /**
   * @minLength 29
   * @maxLength 29
   */
  ibanAccountNumber?: string;
  /**
   * @minLength 1
   * @maxLength 250
   */
  bankName?: string;
  /**
   * @minLength 5
   * @maxLength 10
   */
  bankEnterpriseCode?: string;
  /**
   * @minLength 9
   * @maxLength 12
   */
  taxpayerCertificateNumber?: string;
  visible?: boolean;
}

export enum CurrencyType {
  UAH = 'UAH',
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
}

export interface UpdateAgentActDto {
  contractsToDelete?: number[];
  contractsToAdd?: number[];
  /** @format date-time */
  actDate?: string;
  additionalFee?: number;
  additionalFeeUnit?: AdditionalFeeUnitType;
}

export interface ResetPasswordRequestDto {
  login?: string;
}

export interface ResetPasswordTokenValidationDto {
  login?: string;
  token?: string;
}

export interface ResetPasswordDto {
  token?: string;
  newPassword?: string;
  confirmNewPassword?: string;
}

export interface DeactivateUsersRequestDto {
  userIds?: number[];
}

export interface ProductRiskStatisticsDto {
  /** @format int64 */
  productId?: number;
  productCode?: string;
  productName?: string;
  /** @uniqueItems true */
  riskStatisticsDtoList?: RiskStatisticsDto[];
}

export interface RiskStatisticsDto {
  /** @format int64 */
  riskId?: number;
  riskName?: string;
  riskCode?: string;
  /** @format int32 */
  countContracts?: number;
  contractRiskPaymentSum?: number;
  /** @format int32 */
  countClaims?: number;
  claimRiskPaymentSum?: number;
}

export interface PartnerStatisticsDto {
  partnerName?: string;
  /** @format int64 */
  countContracts?: number;
  contractPaymentSum?: number;
  /** @format int64 */
  countClaims?: number;
  claimPaymentSum?: number;
}

export interface PartnerDepartmentStatisticsDto {
  /** @format int64 */
  partnerDepartmentId?: number;
  partnerDepartmentNumber?: string;
  /** @format int64 */
  countContracts?: number;
  contractPaymentSum?: number;
  /** @format int64 */
  countClaims?: number;
  claimPaymentSum?: number;
}

export interface InsuranceProgramStatisticsDto {
  /** @format int64 */
  programId?: number;
  programName?: string;
  programCode?: string;
  contractPaymentSum?: number;
  /** @format int64 */
  countContracts?: number;
}

export interface DaysStatisticsDto {
  /** @format date */
  signDate?: string;
  /** @format int64 */
  countContracts?: number;
  contractPaymentSum?: number;
  /** @format int64 */
  countClaims?: number;
  claimPaymentSum?: number;
}

export interface ParsedInsuredPersonDto {
  /**
   * @minLength 1
   * @maxLength 100
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 100
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  middleName?: string;
  /** @format date */
  birthday: string;
  /**
   * @minLength 0
   * @maxLength 20
   */
  passport?: string;
}

export enum SenderEmailType {
  DEFAULT = 'DEFAULT',
  TRAVEL_INSURER = 'TRAVEL_INSURER',
}

export interface TemplatedEmailMessageDto {
  recipient?: string;
  subject?: string;
  templatePath?: string;
  params?: object;
  senderEmailType?: SenderEmailType;
  senderName?: string;
  senderShortName?: string;
}

export interface SignupAccountBaseInfoDto {
  partnerCode?: string;
  email?: string;
  phoneNumber?: string;
  token?: string;
}

export type SignupAccountDto = SignupAccountBaseInfoDto & {
  lastName?: string;
  firstName?: string;
  middleName?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  partner?: OptionDto;
  /** @format int64 */
  partnerDepartmentId?: number;
  password?: string;
  confirmPassword?: string;
};

export interface ImportContractDto {
  /**
   * @minLength 6
   * @maxLength 7
   */
  contractNumber: string;
  /** @format date */
  conclusionDate: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string;
  /**
   * @minLength 1
   * @maxLength 30
   */
  phoneNumber: string;
  /** @format date */
  birthday: string;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  citizenship: string;
  /**
   * @minLength 0
   * @maxLength 150
   */
  insuredPerson: string;
  /**
   * @minLength 5
   * @maxLength 50
   */
  email: string;
  paymentAmount: number;
  commission?: number;
  /**
   * @minLength 1
   * @maxLength 50
   */
  agent: string;
  /** @format int32 */
  objectCount: number;
  insuranceSum: number;
  checkNumber: string;
  paymentSystem: string;
  insuranceProgram: string;
  persons: ImportPersonDto[];
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  insuranceProgramId?: number;
}

export interface ImportPersonDto {
  /**
   * @minLength 7
   * @maxLength 7
   */
  contractNumber: string;
  /** @format int32 */
  orderNumber: number;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  phoneNumber: string;
  /** @format date */
  birthday: string;
  /**
   * @minLength 5
   * @maxLength 50
   */
  email: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  passport: string;
  insuranceSum: number;
  /** @format date */
  startDate: string;
  /** @format date */
  endDate: string;
}

export interface IntegrationRequestDto {
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  insuranceProductId?: number;
  /** @format int64 */
  contractTransferConfigurationId?: number;
  /** @format date-time */
  startSignDate?: string;
  /** @format date-time */
  endSignDate?: string;
  contracts?: ImportContractDto[];
  persons?: ImportPersonDto[];
}

export interface IntegrationPackageInformationDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  countToTransfer?: number;
  /** @format int64 */
  transferred?: number;
  status?: IntegrationStatusDto;
}

export enum IntegrationStatusDto {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  INTERRUPT = 'INTERRUPT',
}

export interface IntegrationItemCountDto {
  /** @format int32 */
  count?: number;
}

export interface DiaDocumentReceivingResponseDto {
  success?: boolean;
}

export interface CommonOfferDto {
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format int64 */
  contractId: number;
  sendingType: SendingType;
  language?: string;
  phoneNumber: string;
}

export interface UniqueNumberValidationRequestDto {
  uniqueNumber?: string;
  /** @format int64 */
  contractId?: number;
  /** @format int64 */
  originalContractId?: number;
}

export enum EmailTemplateType {
  CONTRACT_DRAFT_PAYMENT_NOTIFICATION = 'CONTRACT_DRAFT_PAYMENT_NOTIFICATION',
  CONTRACT_SIGN_OTP = 'CONTRACT_SIGN_OTP',
  DOCUMENTS_UPLOAD = 'DOCUMENTS_UPLOAD',
  CONTRACT_OFFER = 'CONTRACT_OFFER',
  CLAIM_SIGN_INFO = 'CLAIM_SIGN_INFO',
  USER_SIGNUP = 'USER_SIGNUP',
  PARTNERSHIP = 'PARTNERSHIP',
  CONTRACT_PROMO_CODE_NOTIFICATION = 'CONTRACT_PROMO_CODE_NOTIFICATION',
}

export type AutoCertificateRecognitionDto = RecognitionDto & {
  vin?: string;
  address?: string;
  brand?: string;
  category?: string;
  color?: string;
  dataExpire?: string;
  dataIssue?: string;
  engNames?: string;
  engineVolume?: string;
  model?: string;
  property?: string;
  registrationNumber?: string;
  type?: string;
  ukrNames?: string;
  yearIssue?: string;
};

export enum DoctypeDto {
  DrivingLicense = 'driving_license',
  AutoCertificate = 'auto_certificate',
  ForeignPassport = 'foreign_passport',
  IdentificationNumber = 'identification_number',
  FiscalCheck = 'fiscal_check',
  IdCardOldPassport = 'id_card_old_passport',
  Other = 'other',
}

export type DriverLicenceRecognitionDto = RecognitionDto & {
  dateOfBirth?: string;
  dateOfExpire?: string;
  ukrFullName?: string;
  englishFullName?: string;
  registrationPlace?: string;
  serialNumber?: string;
};

export type FiscalRecognitionDto = RecognitionDto & {
  fiscalNumber?: string;
};

export type IdentificationNumberRecognitionDto = RecognitionDto & {
  identificationNumber?: string;
  fullName?: string;
};

export type NewPassportFormatRecognitionDto = RecognitionDto & {
  documentNumber?: string;
  dates?: string[];
  ukrNameData?: string[];
  engNameData?: string[];
};

export type OldPassportFormatRecognitionDto = RecognitionDto & {
  documentNumber?: string;
  dateOfBirth?: string;
  dateOfExpire?: string;
  ukrNameData?: string[];
  engNameData?: string[];
};

export type OtherRecognitionDto = RecognitionDto & {
  error?: string;
};

export interface RecognitionDto {
  doctype?: DoctypeDto;
}

export interface ConfigurationRequestDto {
  /** @format int32 */
  duration?: number;
  durationUnit?: ChronoUnitSimplifiedDuplicate;
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format int64 */
  insurancePolicyId?: number;
  /** @format int64 */
  contractId?: number;
}

export interface ContractConfigurationDto {
  contractNumberEnding?: string;
}

export interface ContractCalculationRequestDto {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int32 */
  duration?: number;
  durationUnit?: ChronoUnitSimplifiedDuplicate;
  saleSum?: number;
  /** @format int64 */
  insuranceSumId?: number;
  /** @format int32 */
  objectsQuantity?: number;
  contractObjects?: (ElectronicDeviceObjectDto | EstateObjectDto | PersonObjectDto | VehicleObjectDto)[];
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format int64 */
  insuranceProductId?: number;
  /** @format int64 */
  insurancePolicyId?: number;
  /** @format int64 */
  contractId?: number;
  prolongation?: boolean;
  /** @format date */
  contractDate?: string;
  /** @format int64 */
  prolongatedFromInsuranceRateId?: number;
  installmentPaymentPeriodType?: InstallmentPaymentPeriodType;
  contractInfo?: object;
  agentContractIds?: number[];
  promoCode?: string;
}

export interface CalculatedInsuranceObjectDto {
  /** @format int64 */
  subtypeId?: number;
  insuranceSumForObject?: number;
  paymentAmountForObject?: number;
  paymentAmountCurrencyNotAccounted?: number;
  insuranceRate?: number;
  franchise?: number;
  franchiseValueType?: FranchiseValueType;
  risks?: ContractCalculatedRiskDto[];
}

export interface ContractCalculatedRiskDto {
  /** @format int64 */
  id?: number;
  insuranceAmount?: number;
  paymentAmount?: number;
  paymentAmountCurrencyNotAccounted?: number;
  franchiseValueType?: FranchiseValueType;
  franchise?: number;
  riskName?: string;
  exchangedInsuranceAmount?: number;
  exchangedCurrency?: string;
  currency?: string;
}

export interface ContractCalculationResultDto {
  insuranceSum?: number;
  insuranceSumCurrency?: string;
  exchangedInsuranceSum?: number;
  exchangedInsuranceSumCurrency?: string;
  insuranceRate?: number;
  calculatedInsuranceRate?: number;
  paymentAmount?: number;
  paymentAmountCurrencyNotAccounted?: number;
  calculatedPaymentAmountCurrencyNotAccounted?: number;
  currencyExchangeRate?: number;
  currencyNbuExchangeRate?: number;
  calculatedPaymentAmount?: number;
  prolongationSum?: number;
  objects?: CalculatedInsuranceObjectDto[];
  mtsbuCoefficients?: ContractMtsbuCoefficientsDto;
  insuranceCompanyCoefficient?: number;
  partnerCommissionCoefficient?: number;
  partnerCoefficient?: number;
  promoCodeCoefficient?: number;
  discountSum?: number;
  discount?: number;
  discountMeasurementType?: DiscountMeasurementType;
  isPromoCodeValid?: boolean;
}

export interface ContractMtsbuCoefficientsDto {
  vehicleTypeCoefficient?: number;
  registrationZoneCoefficient?: number;
  vehicleUsageScopeCoefficient?: number;
  holderTypeCoefficient?: number;
  usagePeriodCoefficient?: number;
  insureActivityCoefficient?: number;
  contractPeriodCoefficient?: number;
  contractTypeCoefficient?: number;
  bonusMalusCoefficient?: number;
  preferentialCoefficient?: number;
}

export enum PaymentSystemType {
  FONDY = 'FONDY',
  CONCORD = 'CONCORD',
}

export interface PayoutTerminationRecipientDto {
  accountNumber?: string;
  paymentSystemType: PaymentSystemType;
  /**
   * @minLength 0
   * @maxLength 150
   */
  bankName?: string;
}

export interface InitialPaymentResponse {
  currency?: string;
  /** @format int32 */
  amount?: number;
  signature?: string;
  rectoken?: string;
  payment_id?: string;
  response_status?: string;
  error_message?: string;
  /** @format int32 */
  error_code?: number;
  request_id?: string;
  card_type?: string;
  masked_card?: string;
  order_time?: string;
  response_signature_string?: string;
  order_id?: string;
  tran_type?: string;
  payment_system?: string;
  order_status?: string;
  response_description?: string;
  /** @format int32 */
  merchant_id?: number;
  /** @format int32 */
  card_bin?: number;
}

export interface ClientSignupAccountBaseInfoDto {
  phoneNumber?: string;
  token?: string;
}

export type ClientSignupAccountDto = ClientSignupAccountBaseInfoDto & {
  email?: string;
  password?: string;
  confirmPassword?: string;
};

export interface ClientRegistrationValidateDto {
  email?: string;
  token?: string;
  universalTokenEnabled?: boolean;
}

export interface ClientRegistrationAccountDto {
  email?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  /** @format date */
  birthDate?: string;
  locality?: string;
  street?: string;
  password?: string;
  confirmPassword?: string;
}

export interface Address {
  /** @format int64 */
  id?: number;
  locality?: string;
  street?: string;
}

export interface Counterparty {
  /** @format int64 */
  id?: number;
  name?: string;
  shortName?: string;
  phoneNumber?: string;
  email?: string;
  type?: CounterpartyType;
  address?: Address;
  /** @format int64 */
  userId?: number;
  identityDocuments?: IdentityDocument[];
  /** @format date-time */
  createdAt?: string;
  /** @format int64 */
  createdBy?: number;
  /** @format date-time */
  modifiedAt?: string;
  /** @format int64 */
  modifiedBy?: number;
}

export interface IdentityDocument {
  /** @format int64 */
  id?: number;
  number?: string;
  type?: IdentityDocumentType;
  counterparty?: Counterparty;
}

export interface PersonCounterparty {
  /** @format int64 */
  id?: number;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  /** @format date */
  birthday?: string;
  inn?: string;
  citizenship?: string;
  counterparty?: Counterparty;
  counterpartyType?: CounterpartyType;
}

export interface PaymentSystemTypeDto {
  paymentSystemType?: PaymentSystemType;
}

export interface ExpressPaymentDto {
  contractNumber?: string;
  /** @format date-time */
  contractDate?: string;
  claimNumber?: string;
  /** @format date-time */
  claimDate?: string;
  insuranceObject?: string;
  risk?: string;
  paymentRecipient?: PaymentRecipientDto;
  paymentSum?: number;
}

export interface ValidationErrorDto {
  field?: string;
  code?: string;
}

export interface EmailContractClaimDto {
  /** @format int64 */
  claimId: number;
  documentTypeCodes: DocumentTypeCode[];
}

export interface ClaimManualPaymentDto {
  /** @format int64 */
  contractClaimId: number;
  orderNumber: string;
  /** @format date-time */
  paymentDate: string;
  paymentSum: number;
}

export interface ClaimSumCalculationRequestDto {
  /** @format int64 */
  contractId?: number;
  /** @format int64 */
  claimId?: number;
  insuranceObjectPid?: string;
  /** @format int64 */
  riskId?: number;
  /** @format date-time */
  incidentDate?: string;
  resolutionType?: ResolutionType;
}

/** Valid username and password */
export interface UserCredentialsDto {
  username: string;
  password: string;
}

export interface TwoFactorAuthIdentityResponseDto {
  /** @format int64 */
  identity?: number;
  code?: string;
}

export interface UserCredentialsWithOTPDto {
  username: string;
  password: string;
  otp: string;
}

export interface ExternalImportSignatoryDto {
  phoneNumber: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  lastName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  firstName: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  middleName: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  locality: string;
  /**
   * @minLength 1
   * @maxLength 255
   */
  street: string;
  /**
   * @minLength 1
   * @maxLength 150
   */
  companyName: string;
  clientType: CounterpartyType;
  /** @format date */
  birthday?: string;
  counterpartyType?: CounterpartyType;
}

export interface MedAppImportContractDto {
  /**
   * @minLength 0
   * @maxLength 10
   */
  partnerDepartmentNumber: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  insuranceProgramCode: string;
  calculatedPaymentAmount: number;
  paymentAmount: number;
  /** @format int32 */
  duration: number;
  durationUnit: ChronoUnitSimplifiedDuplicate;
  sellerPhoneNumber?: string;
  /**
   * @minLength 0
   * @maxLength 150
   */
  sellerName?: string;
  signatory: ExternalImportSignatoryDto;
  contractObject: (ElectronicDeviceObjectDto | EstateObjectDto | PersonObjectDto | VehicleObjectDto)[];
  contractPayments: MedAppImportContractPaymentDto[];
  generalContractNumber: string;
  contractNumber: string;
  contractId: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format date-time */
  conclusionDate: string;
}

export interface MedAppImportContractPaymentDto {
  /** @format date-time */
  payBeforeDate: string;
  expectedPaymentAmount: number;
  /** @format date-time */
  periodStartDate: string;
  /** @format date-time */
  periodEndDate: string;
  paymentAmount?: number;
  /** @format date-time */
  paymentDate?: string;
  checkNumber?: string;
}

export interface UpdateResultDto {
  /** @format int32 */
  importedCount?: number;
  /** @format int32 */
  failedCount?: number;
  errorsReport?: Record<string, string>;
  /** @format int32 */
  updateCount?: number;
  /** @format int32 */
  notUpdateCount?: number;
}

export interface PartnerToSendOfferDto {
  /**
   * @minLength 1
   * @maxLength 20
   */
  insuranceProgramCode: string;
  phoneNumber: string;
}

export interface ImportResultDto {
  /** @format int32 */
  importedCount?: number;
  /** @format int32 */
  failedCount?: number;
  errorsReport?: Record<string, string>;
}

export interface CitrusImportContractDto {
  /**
   * @minLength 0
   * @maxLength 10
   */
  partnerDepartmentNumber: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  insuranceProgramCode: string;
  calculatedPaymentAmount: number;
  /** @format int32 */
  duration: number;
  durationUnit: ChronoUnitSimplifiedDuplicate;
  sellerPhoneNumber?: string;
  /**
   * @minLength 0
   * @maxLength 150
   */
  sellerName?: string;
  signatory: ExternalImportSignatoryDto;
  contractObject: ExternalImportElectronicObjectDto;
  contractPayments: ExternalImportContractPaymentDto[];
}

export interface ExternalImportContractPaymentDto {
  /** @format date-time */
  payBeforeDate: string;
  expectedPaymentAmount: number;
  /** @format date-time */
  periodStartDate: string;
  /** @format date-time */
  periodEndDate: string;
  paymentAmount: number;
  /** @format date-time */
  paymentDate: string;
  /**
   * @minLength 1
   * @maxLength 20
   */
  checkNumber: string;
}

export interface ExternalImportElectronicObjectDto {
  subtype: string;
  /**
   * @minLength 2
   * @maxLength 36
   */
  pid: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  brand: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  model: string;
  price: number;
  /** @format date-time */
  paymentDate: string;
  /**
   * @minLength 1
   * @maxLength 50
   */
  checkNumber: string;
}

export interface AuthenticationResponseDto {
  access_token?: string;
  refresh_token?: string;
}

export enum ContractStateType {
  DELETED = 'DELETED',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
  DRAFT = 'DRAFT',
  SIGNED = 'SIGNED',
  REQUEST = 'REQUEST',
  EMITTED = 'EMITTED',
  ABORTED = 'ABORTED',
  RENEW = 'RENEW',
  DUPLICATE = 'DUPLICATE',
}

export interface ExternalContractInfoDto {
  externalId?: string;
  tariff?: string;
  source?: OsagoFlowType;
  status?: ContractStateType;
  /** @format date-time */
  conclusionDate?: string;
  /** @format date-time */
  signDate?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  lastModifiedDate?: string;
  externalContractReportPdfUrl?: string;
  contractNumber?: string;
}

export enum OsagoFlowType {
  INSK = 'INSK',
  EXTERNAL = 'EXTERNAL',
}

export interface AutoMakerDto {
  /** @format int32 */
  id?: number;
  name?: string;
  /** @format int32 */
  cdbMtibuCode?: number;
  lastModified?: string;
}

export interface AutoModelDto {
  /** @format int32 */
  id?: number;
  autoMaker?: AutoMakerDto;
  kind?: VehicleClass;
  name?: string;
  lastModified?: string;
}

export interface BlackListCoincidenceDto {
  /** @format int32 */
  id?: number;
  blackList?: BlackListDto;
}

export interface BlackListDto {
  /** @format int32 */
  id?: number;
  notes?: string;
}

export enum CalculationType {
  NONE = 'NONE',
  TARIFF_MULTIPLIER = 'TARIFF_MULTIPLIER',
  TARIFF_ADDITION = 'TARIFF_ADDITION',
  PAYMENT_MULTIPLIER = 'PAYMENT_MULTIPLIER',
  PAYMENT_ADDITION = 'PAYMENT_ADDITION',
}

export enum CommissionSourceType {
  RKO = 'RKO',
  CARD = 'CARD',
}

export enum CommissionType {
  IMMEDIATE = 'IMMEDIATE',
  LATER = 'LATER',
  NONE = 'NONE',
}

export interface CompanyAccountDto {
  /** @format int32 */
  id?: number;
  mfo?: string;
  account?: string;
  name?: string;
}

export interface CompanySimpleDto {
  /** @format int64 */
  id?: number;
  type?: string;
  code?: string;
  name?: string;
  namePrint?: string;
  country?: CountryCode;
}

export interface ContractAgentDto {
  /** @format int32 */
  id?: number;
  agent?: string;
  paymentOption?: string;
  calculationKind?: string;
  commission?: number;
}

export interface ContractDateToLimitDto {
  /** @format int32 */
  value?: number;
  datePeriodType?: DurationUnit;
}

export interface ContractPaymentDto {
  /** @format int32 */
  id?: number;
  date?: string;
  created?: string;
  stateChanged?: string;
  payment?: number;
  commission?: number;
  commissionType?: CommissionType;
  commissionSource?: CommissionSourceType;
  number?: string;
  purpose?: string;
  state?: PaymentState;
  recipientName?: string;
  recipientCode?: string;
  recipientBankMfo?: string;
  recipientAccountNumber?: string;
  senderName?: string;
  senderCode?: string;
  senderBankMfo?: string;
  senderAccountNumber?: string;
  senderCardNumber?: string;
}

export interface ContractRiskDto {
  /** @format int32 */
  id?: number;
  risk?: RiskDto;
  baseTariff?: number;
  insuranceAmount?: number;
  amountCurrency?: CurrencyType;
  currencyExchangeRate?: CurrencyExchangeRateDto;
  payment?: number;
  customFields?: CustomFieldValueDto[];
}

export interface ContractTypeDto {
  /** @format int32 */
  id?: number;
  name?: string;
}

export interface ContractUnderwritingDto {
  /** @format int32 */
  id?: number;
  user?: UserDto;
  customFieldCode?: string;
  fieldType?: UnderwritingFieldType;
  notes?: string;
  underwritingLevel?: UnderwritingLevelType;
  baseCoeff?: number;
  requestedCoeff?: number;
  approvedCoeff?: number;
}

export enum CountryCode {
  UA = 'UA',
  RU = 'RU',
  KZ = 'KZ',
}

export interface CurrencyExchangeRateDto {
  /** @format int32 */
  id?: number;
  from?: CurrencyType;
  to?: CurrencyType;
  dateFrom?: string;
  dateTo?: string;
  /** @format int32 */
  quantity?: number;
  rate?: number;
}

export interface CustomFieldValueDto {
  /** @format int32 */
  id?: number;
  code?: string;
  value?: string;
  calculationKind?: CalculationType;
  coeff?: number;
}

export enum CustomerOtpState {
  NONE = 'NONE',
  SENT = 'SENT',
  CONFIRMED = 'CONFIRMED',
  CONFIRMED_BY_SELLER = 'CONFIRMED_BY_SELLER',
  CONFIRMED_BY_LINK = 'CONFIRMED_BY_LINK',
}

export enum DurationUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export interface EncryptedPersonDto {
  code?: string;
  dontHaveCode?: boolean;
  name?: string;
  nameLast?: string;
  nameFirst?: string;
  nameMiddle?: string;
  address?: string;
  locality?: string;
  street?: string;
  phone?: string;
  email?: string;
  birthDate?: string;
  document?: PersonDocumentDto;
  legal?: boolean;
  blackListCustomer?: boolean;
  foreignOperator?: boolean;
  phoneEncrypted?: string[];
  emailEncrypted?: string[];
}

export enum EuaVehicleType {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
  C1 = 'C1',
  C2 = 'C2',
  D1 = 'D1',
  D2 = 'D2',
  E = 'E',
  F = 'F',
}

export interface ExternalContractDto {
  /** @format int32 */
  id?: number;
  source?: OsagoFlowType;
  type?: string;
  code?: string;
  tariff?: TariffSimpleDto;
  number?: string;
  generalNumber?: string;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
  period?: ContractDateToLimitDto;
  customer?: EncryptedPersonDto;
  beneficiary?: PersonDto;
  insuranceObject?: InsuranceObjectDto | InsuranceObjectAutoDto;
  insuranceObjects?: (InsuranceObjectDto | InsuranceObjectAutoDto)[];
  baseTariff?: number;
  discount?: number;
  urgencyCoefficient?: number;
  payment?: number;
  brokerDiscountedPayment?: number;
  commission?: number;
  commissionCoeff?: number;
  insurerAccount?: CompanyAccountDto;
  paymentNumber?: string;
  paymentDate?: string;
  paymentPurpose?: string;
  creditDocument?: ContractDocumentDto;
  pledgeDocument?: ContractDocumentDto;
  state?: ContractStateType;
  notes?: string;
  multiObject?: boolean;
  externalId?: string;
  agents?: ContractAgentDto[];
  risks?: ContractRiskDto[];
  customFields?: CustomFieldValueDto[];
  payments?: ContractPaymentDto[];
  salePoint?: SalePointSimpleDto;
  user?: UserSimpleDto;
  created?: string;
  stateChanged?: string;
  stateChangedBy?: UserSimpleDto;
  paymentBreakdown?: PaymentBreakdownType;
  dateClose?: string;
  unusedPayment?: number;
  oldContract?: ExternalContractDto;
  customerOtp?: string;
  customerOtpState?: CustomerOtpState;
  sourceContract?: ExternalContractDto;
  customerExternalId?: string;
  skipNotify?: boolean;
  reasonChange?: string;
  costsOfDeal?: number;
  underwriting?: ContractUnderwritingDto[];
  blackListCoincidences?: BlackListCoincidenceDto[];
  promocode?: string;
  warning?: string[];
  registrationCityName?: string;
}

/** Valid contract for saving in 'Draft' status */
export interface ExternalOsagoContractDto {
  /** @format int32 */
  id?: number;
  source?: OsagoFlowType;
  type?: string;
  code?: string;
  tariff?: TariffSimpleDto;
  number?: string;
  generalNumber?: string;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
  period?: ContractDateToLimitDto;
  customer?: EncryptedPersonDto;
  beneficiary?: PersonDto;
  insuranceObject?: InsuranceObjectDto | InsuranceObjectAutoDto;
  insuranceObjects?: (InsuranceObjectDto | InsuranceObjectAutoDto)[];
  baseTariff?: number;
  discount?: number;
  urgencyCoefficient?: number;
  payment?: number;
  brokerDiscountedPayment?: number;
  commission?: number;
  commissionCoeff?: number;
  insurerAccount?: CompanyAccountDto;
  paymentNumber?: string;
  paymentDate?: string;
  paymentPurpose?: string;
  creditDocument?: ContractDocumentDto;
  pledgeDocument?: ContractDocumentDto;
  state?: ContractStateType;
  notes?: string;
  multiObject?: boolean;
  externalId?: string;
  agents?: ContractAgentDto[];
  risks?: ContractRiskDto[];
  customFields?: CustomFieldValueDto[];
  payments?: ContractPaymentDto[];
  salePoint?: SalePointSimpleDto;
  user?: UserSimpleDto;
  created?: string;
  stateChanged?: string;
  stateChangedBy?: UserSimpleDto;
  paymentBreakdown?: PaymentBreakdownType;
  dateClose?: string;
  unusedPayment?: number;
  oldContract?: ExternalContractDto | ExternalOsagoContractDto;
  customerOtp?: string;
  customerOtpState?: CustomerOtpState;
  sourceContract?: ExternalContractDto | ExternalOsagoContractDto;
  customerExternalId?: string;
  skipNotify?: boolean;
  reasonChange?: string;
  costsOfDeal?: number;
  underwriting?: ContractUnderwritingDto[];
  blackListCoincidences?: BlackListCoincidenceDto[];
  promocode?: string;
  warning?: string[];
  registrationCityName?: string;
  /** @format int32 */
  usageMonths?: number;
  franchise?: number;
  k1?: number;
  k2?: number;
  k3?: number;
  k4?: number;
  k5?: number;
  k6?: number;
  k8?: number;
  bonusMalus?: number;
  privilege?: number;
  privilegeType?: PrivilegeType;
  drivingExpLessThreeYears?: boolean;
  taxi?: boolean;
  stickerNumber?: string;
  linkMtibu?: string;
  counterpartyType?: CounterpartyType;
}

export type InsuranceObjectAutoDto = InsuranceObjectDto & {
  category?: EuaVehicleType;
  model?: AutoModelDto;
  modelText?: string;
  complectation?: string;
  bodyNumber?: string;
  dontHaveBodyNumber?: boolean;
  stateNumber?: string;
  dontHaveStateNumber?: boolean;
  registrationPlace?: PlaceDto;
  outsideUkraine?: boolean;
  registrationType?: VehicleRegistrationType;
  otkDate?: string;
  otkTwiceYear?: boolean;
  /** @format int32 */
  year?: number;
  /** @format int32 */
  engineVolume?: number;
  /** @format int32 */
  mileage?: number;
  autoColor?: string;
  autoOwner?: string;
  autoRegistrationDocument?: string;
};

export enum ObjectsCalculationType {
  BY_CONTRACT_WITHOUT_OBJECT_COUNT = 'BY_CONTRACT_WITHOUT_OBJECT_COUNT',
  BY_CONTRACT_MULTIPLIED_ON_OBJECT_COUNT = 'BY_CONTRACT_MULTIPLIED_ON_OBJECT_COUNT',
  BY_OBJECT = 'BY_OBJECT',
}

export enum PaymentBreakdownType {
  ONE_PAYMENT = 'ONE_PAYMENT',
  TWOPAYMENTS50X50BYHALFYEAR = 'TWO_PAYMENTS_50x50_BY_HALF_YEAR',
  TWOPAYMENTS50X50FIRST2MONTHS = 'TWO_PAYMENTS_50x50_FIRST_2_MONTHS',
  TWOPAYMENTS70X30BYHALFYEAR = 'TWO_PAYMENTS_70x30_BY_HALF_YEAR',
  THREEPAYMENTS50X25X25FIRST3MONTHS = 'THREE_PAYMENTS_50x25x25_FIRST_3_MONTHS',
  THREEPAYMENTS34X33X33FIRST3MONTHS = 'THREE_PAYMENTS_34x33x33_FIRST_3_MONTHS',
  THREEPAYMENTS34X33X33EACHFOURMONTHS = 'THREE_PAYMENTS_34x33x33_EACH_FOUR_MONTHS',
  FOURPAYMENTS25X25X25X25EACHTHREEMONTHS = 'FOUR_PAYMENTS_25x25x25x25_EACH_THREE_MONTHS',
  FOURPAYMENTS40X20X20X20EACHTHREEMONTHS = 'FOUR_PAYMENTS_40x20x20x20_EACH_THREE_MONTHS',
  TWELWE_PAYMENTS_EACH_MONTH = 'TWELWE_PAYMENTS_EACH_MONTH',
  TWOPAYMENTS50X50FIRST3MONTHS = 'TWO_PAYMENTS_50x50_FIRST_3_MONTHS',
  FOURPAYMENTS25X25X25X25FIRST4MONTHS = 'FOUR_PAYMENTS_25x25x25x25_FIRST_4_MONTHS',
}

export enum PaymentState {
  PLAN = 'PLAN',
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}

export interface PersonDocumentDto {
  type?: PersonDocumentType;
  series?: string;
  number?: string;
  date?: string;
  issuedBy?: string;
  dateEnd?: string;
  issuer?: string;
  /** @format date */
  issuedAt?: string;
  /** @format date */
  expirationDate?: string;
  record?: string;
}

export enum PersonDocumentType {
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  INTERNAL_PASSPORT = 'INTERNAL_PASSPORT',
  INTERNATIONAL_PASSPORT = 'INTERNATIONAL_PASSPORT',
  TAXPAYER_CARD = 'TAXPAYER_CARD',
  CHORNOBYL_CERTIFICATE = 'CHORNOBYL_CERTIFICATE',
  ELECTRONIC_PENSIONER_CERTIFICATE = 'ELECTRONIC_PENSIONER_CERTIFICATE',
  PENSIONER_CERTIFICATE = 'PENSIONER_CERTIFICATE',
  FOREIGNER_PASSPORT = 'FOREIGNER_PASSPORT',
  PASSPORT = 'PASSPORT',
  ID_PASSPORT = 'ID_PASSPORT',
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  PENSION_CERTIFICATE = 'PENSION_CERTIFICATE',
  E_PENSION_CERTIFICATE = 'E_PENSION_CERTIFICATE',
  CHERNOBYL_CERTIFICATE = 'CHERNOBYL_CERTIFICATE',
  EXTERNAL_PASSPORT = 'EXTERNAL_PASSPORT',
  FOREIGN_PASSPORT = 'FOREIGN_PASSPORT',
  REGISTRATION_CARDT = 'REGISTRATION_CARDT',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  ID_RESIDENCE_PERMIT = 'ID_RESIDENCE_PERMIT',
  DISABILITY_CERTIFICATE = 'DISABILITY_CERTIFICATE',
  VETERAN_CERTIFICATE = 'VETERAN_CERTIFICATE',
}

export interface PersonDto {
  code?: string;
  dontHaveCode?: boolean;
  name?: string;
  nameLast?: string;
  nameFirst?: string;
  nameMiddle?: string;
  address?: string;
  locality?: string;
  street?: string;
  phone?: string;
  email?: string;
  birthDate?: string;
  document?: PersonDocumentDto;
  legal?: boolean;
  blackListCustomer?: boolean;
}

export interface PlaceDto {
  /** @format int32 */
  id?: number;
  country?: CountryCode;
  placeCode?: string;
  /** @format int32 */
  zone?: number;
  name?: string;
  nameRus?: string;
  nameFull?: string;
  nameFullRus?: string;
  cdbMtibu?: boolean;
  /** @format int32 */
  cdbMtibuCode?: number;
  lastModified?: string;
}

export enum PrivilegeType {
  VETERAN = 'VETERAN',
  DISABLED = 'DISABLED',
  CHERNOBYLETS = 'CHERNOBYLETS',
  PENSIONER = 'PENSIONER',
}

export interface RiskDto {
  /** @format int32 */
  id?: number;
  shortName?: string;
  name?: string;
  externalId?: string;
}

export interface SalePointSimpleDto {
  /** @format int32 */
  id?: number;
  company?: CompanySimpleDto;
  code?: string;
  name?: string;
  namePrint?: string;
  /** @format int32 */
  lft?: number;
  /** @format int32 */
  rgt?: number;
  path?: string;
  paymentsAvailable?: boolean;
  findDocumentsAvailable?: boolean;
  exportersAvailable?: boolean;
  mtibuExporterGCAvailable?: boolean;
  reportSalePoint?: SalePointSimpleDto;
}

export interface TariffSimpleDto {
  /** @format int64 */
  id?: number;
  type?: string;
  name?: string;
  company?: CompanySimpleDto;
  insurer?: CompanySimpleDto;
  /** @format date */
  dateFrom?: string;
  dateTo?: string;
  objectsCalcType?: ObjectsCalculationType;
  risksSupported?: boolean;
  risksOnContract?: boolean;
  signerOtpRequired?: boolean;
  customerOtpRequired?: boolean;
  externalId?: string;
  contractType?: ContractTypeDto;
}

export enum UnderwritingFieldType {
  KASKO_BASE_TARIFF = 'KASKO_BASE_TARIFF',
  CUSTOM_FIELD_MEMBER = 'CUSTOM_FIELD_MEMBER',
}

export enum UnderwritingLevelType {
  LEVEL1 = 'LEVEL1',
  LEVEL2 = 'LEVEL2',
  LEVEL3 = 'LEVEL3',
  LEVEL4 = 'LEVEL4',
  LEVEL5 = 'LEVEL5',
}

export interface UserSimpleDto {
  /** @format int32 */
  id?: number;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  email?: string;
  code?: string;
  externalId?: string;
  externalCode?: string;
}

export enum VehicleClass {
  MOTO = 'MOTO',
  CAR = 'CAR',
  PASSENGER = 'PASSENGER',
  FREIGHT = 'FREIGHT',
  TRAILER = 'TRAILER',
  AGRICULTURAL = 'AGRICULTURAL',
  SPECIAL = 'SPECIAL',
}

export enum VehicleRegistrationType {
  PERMANENT_WITHOUT_OTK = 'PERMANENT_WITHOUT_OTK',
  PERMANENT_WITH_OTK = 'PERMANENT_WITH_OTK',
  NOT_REGISTERED = 'NOT_REGISTERED',
  TEMPORARY = 'TEMPORARY',
  TEMPORARY_ENTRANCE = 'TEMPORARY_ENTRANCE',
}

export interface AssistantAppointmentDto {
  subCaseId?: string;
  /** @format int64 */
  claimApplicationId?: number;
  contractNumber: string;
  caseId?: string;
  /** @format int64 */
  claimId?: number;
  contractorId?: string;
  contractorPhoneNumber?: string;
  /** @format int32 */
  condition: number;
  approve?: boolean;
  online?: boolean;
  clinic?: AssistantInstitutionDto;
  doctor?: AssistantDoctorDto;
  typeOfService?: string;
  service?: string;
  claims?: string;
  /** @format int64 */
  riskId?: number;
  risk?: string;
  /** @format date-time */
  slot?: string;
  planCost?: number;
  factCost?: number;
  diagnosis?: string;
  /** @format date-time */
  dateOfCase?: string;
  comments?: string;
  subCaseStatus?: SubCaseStatus;
  link?: string;
  /** @format int64 */
  userId?: number;
  userFirstName?: string;
  userMiddleName?: string;
  userLastName?: string;
  /** @format int64 */
  contractId?: number;
}

export interface AssistantDoctorDto {
  name?: string;
  specialization?: string;
}

export interface AssistantInstitutionDto {
  edrpou?: string;
  region?: string;
  city?: string;
  street?: string;
  building?: string;
  name?: string;
  address?: string;
  corpus?: string;
  cabinet?: string;
  med_id?: string;
}

export enum SubCaseStatus {
  PLANNED = 'PLANNED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  WAITING_FOR_CANCELLATION = 'WAITING_FOR_CANCELLATION',
  CANCELLATION_IS_UNAVAILABLE = 'CANCELLATION_IS_UNAVAILABLE',
}

export interface CreateUpdateAggregatorDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  userId?: number;
  aggregatorName?: string;
  partners?: number[];
}

export interface AggregatorDto {
  /** @format int64 */
  id?: number;
  aggregatorName?: string;
  /** @format int64 */
  authUserId?: number;
  partners?: PartnerDto[];
}

export interface CreateActDto {
  filters?: Record<string, string>;
  /** @format date-time */
  actDateTo?: string;
  /** @format date-time */
  actDate?: string;
  /** @format int64 */
  agentContractId?: number;
  excludeContracts?: ExcludeContractDto[];
}

export interface ExcludeContractDto {
  /** @format int64 */
  id?: number;
  terminated?: boolean;
}

export interface AgentActContractDto {
  /** @format int64 */
  id?: number;
  contractNumber?: string;
  clientName?: string;
  /** @format date */
  conclusionDate?: string;
  paymentAmount?: number;
  paymentNumber?: string;
  feeSum?: number;
  feePercent?: number;
  baseFeeSum?: number;
  toPay?: number;
  terminated?: boolean;
  calculatedPaymentAmount?: number;
  returnedPaymentSum?: number;
  returnedToPartnerSum?: number;
  actualPaymentAmount?: number;
}

export interface NewAgentActContractsReportDto {
  contracts?: AgentActContractDto[];
  /** @format int64 */
  count?: number;
  totalInsurancePayments?: number;
  amountToPay?: number;
  totalFeeSum?: number;
  totalBaseFeeSum?: number;
  /** @format int64 */
  totalCount?: number;
}

export interface AuthenticationDetailsDto {
  login?: string;
  /** @format int64 */
  userId?: number;
  tenant?: TenantDto;
  lang?: string;
  isClient?: boolean;
  accesses?: UserAccessesDto;
  permissions?: Record<string, Record<string, boolean>>;
  authorities?: string[];
  enumAuthorities?: UserRole[];
}

export interface TenantDto {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
}

export interface UserAccessesDto {
  allowedInsurancePrograms?: number[];
}

export interface CurrentUserDto {
  /** @format int64 */
  id?: number;
  login?: string;
  role?: UserRole;
  partnerName?: string;
  partnerCode?: string;
  partnerDepartmentName?: string;
  checkNumberPrefix?: string;
  name?: string;
  phone?: string;
  managerPhoneNumber?: string;
  locality?: string;
  street?: string;
  insuranceCompanyName?: string;
  insuranceCompanyCurrency?: string;
  lang?: string;
  telegramChatLink?: string;
}

export enum ActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  SIGN_WITHDRAWAL = 'SIGN_WITHDRAWAL',
  VERIFY_WITHDRAWAL = 'VERIFY_WITHDRAWAL',
  SIGN_CONTRACT = 'SIGN_CONTRACT',
  VERIFY_CONTRACT = 'VERIFY_CONTRACT',
  AUTO_VERIFY_CONTRACT = 'AUTO_VERIFY_CONTRACT',
  SIGN_EXPRESS_PAYMENT = 'SIGN_EXPRESS_PAYMENT',
  ORDINARY_PAYMENT = 'ORDINARY_PAYMENT',
  REJECT_EXPRESS_PAYMENT = 'REJECT_EXPRESS_PAYMENT',
  AUTO_PAYMENT = 'AUTO_PAYMENT',
  CREATE_NEW_ACT = 'CREATE_NEW_ACT',
  PAYMENT_CALCULATION = 'PAYMENT_CALCULATION',
  PAYMENT_AWAITING = 'PAYMENT_AWAITING',
  PAYMENT = 'PAYMENT',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED',
  ANNUL_CONTRACT_CLAIM = 'ANNUL_CONTRACT_CLAIM',
  RESTORE_CONTRACT_CLAIM = 'RESTORE_CONTRACT_CLAIM',
  EDIT_USER_CARD = 'EDIT_USER_CARD',
  SAVE_CONTRACT = 'SAVE_CONTRACT',
  SAVE_CLAIM = 'SAVE_CLAIM',
  SAVE_TERMINATION = 'SAVE_TERMINATION',
  DEFER_EXPRESS_PAYMENT = 'DEFER_EXPRESS_PAYMENT',
  CREATE_SUB_AGREEMENT_ON_VERIFY_CONTRACT = 'CREATE_SUB_AGREEMENT_ON_VERIFY_CONTRACT',
  IMPORT_VISIT_UKRAINE = 'IMPORT_VISIT_UKRAINE',
  IMPORT_CHI = 'IMPORT_CHI',
  SAVE_DRAFT_PROLONGATION = 'SAVE_DRAFT_PROLONGATION',
  SAVE_SUB_AGREEMENT = 'SAVE_SUB_AGREEMENT',
  SIGN_SUB_AGREEMENT = 'SIGN_SUB_AGREEMENT',
  SIGN_TERMINATION = 'SIGN_TERMINATION',
  REMOVE_TERMINATION_SIGNATURE = 'REMOVE_TERMINATION_SIGNATURE',
  SIGN_CLAIM = 'SIGN_CLAIM',
  EXPORT_CONTRACT = 'EXPORT_CONTRACT',
  EXPORT_CONTRACT_CLAIM = 'EXPORT_CONTRACT_CLAIM',
  IMPORT_EDAK_CONTRACT_CLAIM = 'IMPORT_EDAK_CONTRACT_CLAIM',
  REJECT_ORDINARY_PAYMENT = 'REJECT_ORDINARY_PAYMENT',
  SIGN_ORDINARY_PAYMENT = 'SIGN_ORDINARY_PAYMENT',
  UPDATE_SALE_REGISTER = 'UPDATE_SALE_REGISTER',
  IMPORT_PARTNER_REGIONS = 'IMPORT_PARTNER_REGIONS',
  NOTIFY_CLAIM_MANAGER = 'NOTIFY_CLAIM_MANAGER',
  REPORTED = 'REPORTED',
  SEND_OFFER = 'SEND_OFFER',
  GET_SIGNED_CONTRACT = 'GET_SIGNED_CONTRACT',
  CONTRACT_PAYMENT_FIRST_PAY_BY_PAYMENT_SUBSCRIPTION = 'CONTRACT_PAYMENT_FIRST_PAY_BY_PAYMENT_SUBSCRIPTION',
  CONTRACT_PAYMENT_REJECT_FIRST_PAY_BY_PAYMENT_SUBSCRIPTION = 'CONTRACT_PAYMENT_REJECT_FIRST_PAY_BY_PAYMENT_SUBSCRIPTION',
  CONTRACT_PAYMENT_NEXT_PAY_BY_PAYMENT_SUBSCRIPTION = 'CONTRACT_PAYMENT_NEXT_PAY_BY_PAYMENT_SUBSCRIPTION',
  CONTRACT_PAYMENT_REJECT_NEXT_PAY_BY_PAYMENT_SUBSCRIPTION = 'CONTRACT_PAYMENT_REJECT_NEXT_PAY_BY_PAYMENT_SUBSCRIPTION',
  CONTRACT_PAYMENT_ANNUL_CONTRACT_BY_PAYMENT_SUBSCRIPTION = 'CONTRACT_PAYMENT_ANNUL_CONTRACT_BY_PAYMENT_SUBSCRIPTION',
  CONTRACT_PAYMENT_STOP_SUBSCRIPTION = 'CONTRACT_PAYMENT_STOP_SUBSCRIPTION',
  CREATE_PARTNER_EXTERNAL_ID = 'CREATE_PARTNER_EXTERNAL_ID',
  REMOVE_PARTNER_EXTERNAL_ID = 'REMOVE_PARTNER_EXTERNAL_ID',
  SEND_CLIENT_SIGN_CONTRACT = 'SEND_CLIENT_SIGN_CONTRACT',
  USER_PASSWORD_CHANGE = 'USER_PASSWORD_CHANGE',
  TERMINATION_APPLICATION_SENT_FOR_PAYMENT = 'TERMINATION_APPLICATION_SENT_FOR_PAYMENT',
  TERMINATION_APPLICATION_SUCCESSFULLY_PAID = 'TERMINATION_APPLICATION_SUCCESSFULLY_PAID',
  CANCEL_PAYMENT_NOTIFICATION_EMAIL_SENDING = 'CANCEL_PAYMENT_NOTIFICATION_EMAIL_SENDING',
}

export interface ContractUserActivityLogView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  contractId?: number;
  /** @format int64 */
  userActivityLogId?: number;
  /** @format date-time */
  activityDate?: string;
  shortName?: string;
  login?: string;
  role?: UserRole;
  action?: ActionType;
  details?: string;
  /** @format int64 */
  entityId?: number;
  entityType?: EntityType;
  partnerName?: string;
  partnerDepartmentName?: string;
}

export enum EntityType {
  USER = 'USER',
  CONTRACT = 'CONTRACT',
  CONTRACT_CLAIM = 'CONTRACT_CLAIM',
  AGENT_ACT = 'AGENT_ACT',
  CONTRACT_TERMINATION = 'CONTRACT_TERMINATION',
  IMPORT_HISTORY = 'IMPORT_HISTORY',
  EXPORT_CONTRACT = 'EXPORT_CONTRACT',
  EXPORT_CLAIM = 'EXPORT_CLAIM',
  IMPORT_CLAIM = 'IMPORT_CLAIM',
  AUTO_VERIFY_CONTRACT = 'AUTO_VERIFY_CONTRACT',
  SALE_REGISTER = 'SALE_REGISTER',
  PARTNER_REGION = 'PARTNER_REGION',
  PARTNER_EXTERNAL_ID = 'PARTNER_EXTERNAL_ID',
}

export interface ResultListDtoContractUserActivityLogView {
  resultList?: ContractUserActivityLogView[];
  /** @format int64 */
  count?: number;
  total?: ContractUserActivityLogView;
}

export interface ResultListDtoUserActivityLogView {
  resultList?: UserActivityLogView[];
  /** @format int64 */
  count?: number;
  total?: UserActivityLogView;
}

export interface UserActivityLogView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  /** @format date-time */
  activityDate?: string;
  shortName?: string;
  login?: string;
  role?: UserRole;
  action?: ActionType;
  details?: string;
  /** @format int64 */
  entityId?: number;
  entityType?: EntityType;
  /** @format int64 */
  userCounterpartyId?: number;
  /** @format int64 */
  userId?: number;
  partnerName?: string;
  partnerDepartmentName?: string;
  /** @format int64 */
  logContractId?: number;
}

export interface AuthUserView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  status?: UserStatus;
  username?: string;
  insuranceCompanyName?: string;
  partnerName?: string;
  partnerDepartmentName?: string;
  role?: UserRole;
  client?: string;
  phoneNumber?: string;
  email?: string;
  /** @format date-time */
  signupDate?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format date-time */
  lastLoginDate?: string;
}

export interface ResultListDtoAuthUserView {
  resultList?: AuthUserView[];
  /** @format int64 */
  count?: number;
  total?: AuthUserView;
}

export interface DashboardInfoDto {
  topDashboardInfoDto?: TopDashboardInfoDto | TopDashboardLimitsInfoDto;
  daysStatistics?: DaysStatisticsDto[];
  programStatistics?: InsuranceProgramStatisticsDto[];
  departmentStatistics?: PartnerDepartmentStatisticsDto[];
  risksStatistics?: ProductRiskStatisticsDto[];
  partnerStatistics?: PartnerStatisticsDto[];
  programClaimStatistics?: InsuranceProgramClaimStatisticsDto[];
}

export interface InsuranceProgramClaimStatisticsDto {
  /** @format int64 */
  programId?: number;
  programName?: string;
  programCode?: string;
  contractClaimPaymentSum?: number;
  /** @format int32 */
  countContractClaims?: number;
}

export interface TopDashboardInfoDto {
  contractsPaymentsSum?: number;
  /** @format int64 */
  countContracts?: number;
  claimsPaymentsSum?: number;
  /** @format int64 */
  countClaims?: number;
  payedPaymentsSum?: number;
  /** @format int64 */
  countPayed?: number;
  terminationsPaymentsSum?: number;
  /** @format int32 */
  countTerminations?: number;
  agentsPaymentsSum?: number;
  currencyCode?: string;
}

export type TopDashboardLimitsInfoDto = TopDashboardInfoDto & {
  generalLimit?: number;
  currentLimit?: number;
};

export interface SalesStatisticsDtoBigDecimal {
  name?: string;
  sales?: StatisticsDtoBigDecimal;
}

export interface StatisticsDtoBigDecimal {
  currentValue?: number;
  previousValue?: number;
}

export interface MonthInfoDto {
  insurancePayment?: StatisticsDtoBigDecimal;
  calculatedRefundSum?: StatisticsDtoBigDecimal;
  newClientCount?: StatisticsDtoInteger;
  returnedClientCount?: StatisticsDtoInteger;
  terminatedContract?: StatisticsDtoInteger;
  /** @format date */
  previousStartDate?: string;
}

export interface StatisticsDtoInteger {
  /** @format int32 */
  currentValue?: number;
  /** @format int32 */
  previousValue?: number;
}

export interface ClaimPaymentsStatisticDto {
  name?: string;
  sumPayments?: StatisticsDtoBigDecimal;
  countPayments?: StatisticsDtoLong;
}

export interface StatisticsDtoLong {
  /** @format int64 */
  currentValue?: number;
  /** @format int64 */
  previousValue?: number;
}

export interface SalesStatisticsDtoLong {
  name?: string;
  sales?: StatisticsDtoLong;
}

export interface SaleRegisterDocumentConfigurationDto {
  obligatory?: boolean;
  documentType?: DocumentTypeDto;
  informationObligatory?: boolean;
  visible?: boolean;
  allowMultipleFiles?: boolean;
}

export interface ResultListDtoSaleRegisterView {
  resultList?: SaleRegisterView[];
  /** @format int64 */
  count?: number;
  total?: SaleRegisterView;
}

export interface SaleRegisterView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  saleRegisterStatus?: StatusType;
  ownerEquipment?: OwnerEquipmentType;
  insuranceObjectName?: string;
  insuranceObjectPid?: string;
  insuranceObjectType?: string;
  insuranceObjectSubtype?: string;
  contractNumber?: string;
  insuranceSum?: number;
  saleRevenue?: number;
  resolution?: ResolutionType;
  claimStatus?: ClaimStatus;
  /** @format date */
  incidentDate?: string;
  partnerDepartmentPayout?: string;
  paymentSum?: number;
  delivery?: DeliveryType;
  invoiceNumber?: string;
  serviceCenter?: string;
  /** @format date */
  serviceCenterDate?: string;
  damage?: string;
  repairStatus?: RepairStatusType;
  repairCost?: number;
  /** @format date */
  dispatchFromRepairDate?: string;
  repairEquipmentDelivery?: DeliveryType;
  repairEquipmentDeliveryAddress?: string;
  repairEquipmentInvoiceNumber?: string;
  /** @format date */
  receiptDate?: string;
  amountRepairCost?: number;
  otherExpenses?: number;
  /** @format date */
  saleDate?: string;
  salePrice?: number;
  dispatchAfterSaleInvoiceNumber?: string;
  /** @format date */
  dispatchDate?: string;
  dispatchAfterSaleDelivery?: DeliveryType;
  /** @format date */
  transferEquipmentToBuyerDate?: string;
}

export interface WhiteLabelDto {
  whiteLabeling?: object;
  logoUrl?: string;
  logoMiniUrl?: string;
}

export interface PartnerInsuranceCompanyView {
  /** @format int64 */
  id?: number;
  insuranceCompanyName?: string;
  enterpriseCode?: string;
  directorFullName?: string;
  postAddress?: string;
  phoneNumber?: string;
  email?: string;
  createdBy?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  deactivatedAt?: string;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
}

export interface ResultListDtoPartnerInsuranceCompanyView {
  resultList?: PartnerInsuranceCompanyView[];
  /** @format int64 */
  count?: number;
  total?: PartnerInsuranceCompanyView;
}

export interface PartnerView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  identifier?: string;
  type?: PartnerType;
  shortName?: string;
  insuranceCompanyName?: string;
  telegramChatId?: string;
  telegramChatLink?: string;
  telegramManagerSupportChatId?: string;
  telegramManagerSupportChatLink?: string;
}

export interface ResultListDtoPartnerView {
  resultList?: PartnerView[];
  /** @format int64 */
  count?: number;
  total?: PartnerView;
}

export interface MobizonResponseDto {
  /** @format int32 */
  code?: number;
  message?: string;
  data?: Record<string, string>;
}

export interface IntegrationHistoryItemView {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  historyId?: number;
  /** @format int64 */
  packageNumber?: number;
  /** @format date-time */
  operationDate?: string;
  success?: boolean;
  details?: string;
  message?: string;
  type?: IntegrationType;
  processType?: ProcessType;
}

export enum IntegrationType {
  IMPORT_VISIT_UKRAINE = 'IMPORT_VISIT_UKRAINE',
  IMPORT_CHI = 'IMPORT_CHI',
  EXPORTCONTRACT1C = 'EXPORT_CONTRACT_1C',
  EXPORTINTERPOLISCONTRACT1C = 'EXPORT_INTER_POLIS_CONTRACT_1C',
  EXPORTCLAIM1C = 'EXPORT_CLAIM_1C',
  IMPORT_EDAK = 'IMPORT_EDAK',
  EXPORT_EDAK = 'EXPORT_EDAK',
  VERIFY_CONTRACT = 'VERIFY_CONTRACT',
}

export enum ProcessType {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
}

export interface ResultListDtoIntegrationHistoryItemView {
  resultList?: IntegrationHistoryItemView[];
  /** @format int64 */
  count?: number;
  total?: IntegrationHistoryItemView;
}

export enum IntegrationHistoryType {
  CONTRACTS = 'CONTRACTS',
  CLAIMS = 'CLAIMS',
}

export interface IntegrationItemView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  packageNumber?: number;
  partnerName?: string;
  /** @format int64 */
  insuranceProductId?: number;
  insuranceProductName?: string;
  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;
  transferType?: IntegrationHistoryType;
  /** @format date */
  operationDate?: string;
  /** @format int32 */
  entitiesToTransferCount?: number;
  /** @format int32 */
  successCount?: number;
  /** @format int32 */
  failedCount?: number;
  initiator?: string;
  type?: IntegrationType;
}

export interface ResultListDtoIntegrationItemView {
  resultList?: IntegrationItemView[];
  /** @format int64 */
  count?: number;
  total?: IntegrationItemView;
}

export interface DiaDeepLinkResponseDto {
  deeplink?: string;
  /** @format uuid */
  requestId?: string;
}

export interface DiaDocumentsMetadataDto {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  inn?: string;
  /** @format date */
  birthDate?: string;
  idPassportNumber?: string;
  idPassportRecordNumber?: string;
  residenceAddress?: string;
}

export interface InsuranceProgramDto {
  /** @format int64 */
  id?: number;
  active?: boolean;
  contractType?: string;
  code?: string;
  name?: string;
  insuranceCompany?: string;
  claimManagerName?: string;
  claimManagerEmail?: string;
  claimManagerPhoneNumber?: string;
  agentName?: string;
  agentEmail?: string;
  agentPhoneNumber?: string;
  /** @format date */
  createdDate?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  businessExpensesPercent?: number;
  programRisks?: ProgramRiskDto[];
  installmentPaymentPeriodTypes?: InstallmentPaymentPeriodType[];
  objectConfiguration?: object;
  widgetType?: WidgetTypeDto;
}

export interface ProgramRiskDto {
  rule?: OptionDto;
  risk?: OptionDto;
  sharePercent?: number;
  liabilityLimit?: number;
  franchise?: number;
  expressPaymentAvailable?: boolean;
}

export enum WidgetTypeDto {
  OSAGO = 'OSAGO',
  VZR = 'VZR',
  NONE = 'NONE',
}

export interface ObjectSubtypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  objectType?: InsuranceObjectType;
  subtypeCode?: ObjectSubtypeCode;
}

export interface ClaimPaymentDto {
  number?: string;
  /** @format date-time */
  paymentDate?: string;
  paymentSum?: number;
  status?: ContractPaymentStatus;
  paymentType?: PaymentType;
}

export enum ContractPaymentStatus {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  PENDING_BALANCE = 'PENDING_BALANCE',
  PAID_OUT = 'PAID_OUT',
}

export interface DeviceContractClaimDto {
  /** @format int64 */
  id?: number;
  claimNumber?: string;
  /** @format date-time */
  incidentDate?: string;
  claimStatus?: string;
  paymentType?: PaymentType;
  /** @format date-time */
  claimDate?: string;
  riskName?: string;
  expressPaymentPossible?: boolean;
  claimPayment?: ClaimPaymentDto;
  claimStatusHistories?: ClaimStatusHistoryDto[];
}

export interface InsuranceObjectDetailsDto {
  name?: string;
  contractNumber?: string;
  /** @format int64 */
  contractId?: number;
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format int64 */
  originalContractId?: number;
  insuranceProductCode?: string;
  contractStatus?: ContractStatus;
  /** @format date-time */
  startDate?: string;
  contractClaims?: DeviceContractClaimDto[];
  type?: InsuranceObjectType;
  /** @format date */
  verifiedAt?: string;
  /** @format date */
  signDate?: string;
  /** @format date-time */
  endDate?: string;
  insuranceSumCurrency?: string;
  insuranceSum?: number;
  calculatedInsuranceRate?: number;
  contractBeneficiary?: ContractBeneficiaryDto;
  /** @format date-time */
  conclusionDate?: string;
  paymentAmount?: number;
  symbol?: string;
}

export interface InsuranceObjectResponseDto {
  insuranceObjects?: InsuranceObjectDetailsDto[];
  /** @format int32 */
  totalCount?: number;
}

export interface BrandView {
  /** @format int64 */
  id?: number;
  brand?: string;
  type?: InsuranceObjectType;
  subtype?: string;
}

export interface ResultListDtoBrandView {
  resultList?: BrandView[];
  /** @format int64 */
  count?: number;
  total?: BrandView;
}

export interface InsuranceCompanySettingsDto {
  /** @format int64 */
  id?: number;
  insuranceCompanyName?: string;
  insuranceCompanyCurrency?: string;
  currencyProvider?: CurrencyProvider;
}

export interface InsuranceCompanyBasicInformationDto {
  /** @format int64 */
  id?: number;
  name?: string;
  siteUrl?: string;
}

export interface InsuranceCompanyView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  abbreviation?: string;
  enterpriseCode?: string;
  name?: string;
  phoneNumber?: string;
  contactPersonFullName?: string;
  /** @format date */
  createdDate?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  currencyCode?: string;
}

export interface ResultListDtoInsuranceCompanyView {
  resultList?: InsuranceCompanyView[];
  /** @format int64 */
  count?: number;
  total?: InsuranceCompanyView;
}

export interface Account {
  id?: string;
  currency?: string;
  /** @format double */
  balance?: number;
  /** @format double */
  balance_db?: number;
  /** @format double */
  balance_cr?: number;
  /** @format double */
  balance_hold?: number;
  /** @format double */
  balance_income?: number;
}

export interface MerchantBalancesResponse {
  accounts?: Record<string, Account[]>;
}

export interface FinMonitoringReportDto {
  dateResponse?: string;
  search?: string;
  responsetype?: string;
  result?: Record<string, object>;
  idinternal?: string;
  idsubjectFM?: string;
  iduserPC?: string;
  idrequest?: string;
  listdata?: string;
  textformat?: string;
}

export enum DeviceDetailsAutocompleteStatus {
  SUCCESS = 'SUCCESS',
  AUTOCOMPLETE_DISABLED = 'AUTOCOMPLETE_DISABLED',
  IMEI_NOT_FOUND = 'IMEI_NOT_FOUND',
  INVALID_IMEI = 'INVALID_IMEI',
  RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
}

export interface DeviceDetailsDto {
  brand?: string;
  model?: string;
}

export interface DeviceDetailsWrapperDto {
  status?: DeviceDetailsAutocompleteStatus;
  deviceDetails?: DeviceDetailsDto;
}

export interface ImeiDBBalanceDto {
  balance?: number;
  currency?: string;
}

export interface DepartmentView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  regionId?: number;
  region?: string;
  departmentNumber?: string;
  partnerName?: string;
  type?: DepartmentType;
  name?: string;
  phoneNumber?: string;
  locality?: string;
  street?: string;
  active?: boolean;
  director?: string;
  email?: string;
  /** @format date */
  createdDate?: string;
  /** @format date */
  activationDate?: string;
  /** @format date */
  deactivationDate?: string;
}

export interface ResultListDtoDepartmentView {
  resultList?: DepartmentView[];
  /** @format int64 */
  count?: number;
  total?: DepartmentView;
}

export interface CounterpartyDetailsView {
  /** @format int64 */
  id?: number;
  shortName?: string;
  fullName?: string;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  enterpriseCode?: string;
  inn?: string;
  /** @format date */
  birthday?: string;
  phoneNumber?: string;
  email?: string;
  locality?: string;
  street?: string;
  type?: CounterpartyType;
}

export interface ResultListDtoCounterpartyDetailsView {
  resultList?: CounterpartyDetailsView[];
  /** @format int64 */
  count?: number;
  total?: CounterpartyDetailsView;
}

export interface WithdrawVerifyWarningsDto {
  warnings?: string[];
}

export interface ContractTerminationDetailsDto {
  /** @format int64 */
  id?: number;
  terminationNumber?: string;
  /** @format date-time */
  terminationDate?: string;
  /** @format date-time */
  signDate?: string;
  /** @format date-time */
  realEndDate?: string;
  cause?: string;
  /** @format int32 */
  realTerm?: number;
  calculatedRefundSum?: number;
  terminationStatus?: ContractTerminationStatus;
  /** @format date-time */
  payoutDate?: string;
  terminationPaymentStatus?: ContractPaymentStatus;
  terminationPaymentOrderStatus?: OrderStatus;
  paymentSystemType?: PaymentSystemType;
  contractPaymentOrderId?: string;
}

export enum OrderStatus {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED',
  REVERSED = 'REVERSED',
}

export interface ContractIntegrationHistoryDto {
  /** @format int64 */
  packageNumber?: number;
  /** @format date-time */
  operationDate?: string;
  success?: boolean;
  message?: string;
  integrationType?: IntegrationType;
}

export interface ContractClaimDetailsDto {
  /** @format int64 */
  claimId?: number;
  claimNumber?: string;
  /** @format date-time */
  claimDate?: string;
  insuranceObject?: string;
  risk?: string;
  expectedLoss?: number;
  franchise?: number;
  franchiseType?: string;
  claimSum?: number;
  paymentSum?: number;
  /** @format date-time */
  paymentDate?: string;
  claimStatus?: ClaimStatus;
  resolutionType?: ResolutionType;
  resolutionTypeName?: string;
  rejectionCauseName?: string;
  rejectionCauseCode?: string;
}

export interface ContractView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  number?: string;
  originalContractNumber?: string;
  /** @format int64 */
  originalContractId?: number;
  current?: boolean;
  /** @format int32 */
  subAgreementNumber?: number;
  clientName?: string;
  clientType?: CounterpartyType;
  phoneNumber?: string;
  email?: string;
  clientLocality?: string;
  clientStreet?: string;
  beneficiaryPhoneNumber?: string;
  beneficiaryType?: CounterpartyType;
  beneficiaryName?: string;
  beneficiaryLocality?: string;
  beneficiaryStreet?: string;
  /** @format date */
  conclusionDate?: string;
  /** @format date */
  subAgreementConclusionDate?: string;
  /** @format date */
  subAgreementStartDate?: string;
  /** @format date */
  subAgreementEndDate?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  status?: ContractStatus;
  insuranceAmount?: number;
  currencyCode?: string;
  paymentAmount?: number;
  calculatedPaymentAmount?: number;
  /** @format int32 */
  numberPayments?: number;
  insuranceObjectPid?: string;
  insuranceObjectName?: string;
  insuranceObjectType?: InsuranceObjectType;
  insuranceObjectSubtype?: string;
  check_number?: string;
  /** @format date */
  saleDate?: string;
  saleSum?: number;
  /** @format int64 */
  partnerCompanyId?: number;
  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;
  /** @format int64 */
  contractTerminationId?: number;
  partnerDepartmentRegion?: string;
  sellerName?: string;
  sellerPhoneNumber?: string;
  /** @format date */
  verifiedAt?: string;
  participateInAgentAct?: boolean;
  test?: boolean;
  insuranceProductCode?: string;
  /** @format date-time */
  signDate?: string;
  partnerName?: string;
  /** @format int64 */
  generalContractId?: number;
  partnerDepartmentsName?: string;
  subAgreementType?: SubAgreementType;
  /** @format int64 */
  clientId?: number;
  orderId?: string;
}

export interface ResultListDtoContractView {
  resultList?: ContractView[];
  /** @format int64 */
  count?: number;
  total?: ContractView;
}

export enum SubAgreementType {
  PROLONGATION = 'PROLONGATION',
  PROGRAM_CHANGE = 'PROGRAM_CHANGE',
  DATA_CHANGE = 'DATA_CHANGE',
  DATA_CHANGE_WHEN_VERIFY = 'DATA_CHANGE_WHEN_VERIFY',
}

export interface MajorContractDto {
  /** @format int64 */
  generalContractId?: number;
  generalContractNumber?: string;
  /** @format date-time */
  contractStartDate?: string;
}

export interface ContractClaimImeiDuplicationDto {
  documentCollectionClaimStatistic?: OptionInfoDto;
  paymentCalculationClaimStatistic?: OptionInfoDto;
  paymentAwaitingClaimStatistic?: OptionInfoDto;
  paymentClaimStatistic?: OptionInfoDto;
}

export interface OptionInfoDto {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  count?: number;
  sum?: number;
  name?: string;
  /** @format date */
  date?: string;
}

export interface ContractImeiDuplicationsView {
  /** @format int64 */
  id?: number;
  contractNumber?: string;
  imei?: string;
  status?: ContractStatus;
  signatoryPhoneNumber?: string;
  signatoryName?: string;
  objectName?: string;
  objectSubtypeName?: string;
  /** @format date-time */
  conclusionDate?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  insuranceSum?: number;
  paymentAmount?: number;
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  partnerId?: number;
  partnerDepartmentName?: string;
  code?: string;
  symbol?: string;
}

export interface ResultListDtoContractImeiDuplicationsView {
  resultList?: ContractImeiDuplicationsView[];
  /** @format int64 */
  count?: number;
  total?: ContractImeiDuplicationsView;
}

export interface ConfigurationDocumentTypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: DocumentTypeCode;
}

export interface ContractDocumentConfigurationDto {
  /** @format int64 */
  id?: number;
  documentType?: ConfigurationDocumentTypeDto;
  /** @format int64 */
  partnerProgramId?: number;
  allowMultipleFiles?: boolean;
}

export interface ContractCurrentLiabilityDto {
  currentContractLiability?: CurrentLiabilityDto;
  currentObjectLiability?: CurrentLiabilityDto;
  currentRiskLiability?: CurrentLiabilityDto;
}

export interface CurrentLiabilityDto {
  /** @format int64 */
  id?: number;
  insuranceSum?: number;
  totalPaymentsAmount?: number;
}

export interface ReceivedDocumentDto {
  documentTypeCode?: DocumentTypeCode;
  received?: boolean;
}

export interface TerminationDocumentConfigurationDto {
  obligatory?: boolean;
  documentType?: DocumentTypeDto;
  informationObligatory?: boolean;
  sharableFromDia?: boolean;
}

export interface TerminationDocumentConfigurationWithSumDto {
  refundSum?: number;
  configuration?: TerminationDocumentConfigurationDto[];
}

export interface ContractTerminationView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  terminationId?: number;
  /** @format int64 */
  contractId?: number;
  terminationNumber?: string;
  /** @format date */
  terminationDate?: string;
  /** @format date */
  signDate?: string;
  /** @format int32 */
  duration?: number;
  initiator?: string;
  refundSum?: number;
  currency?: string;
  status?: ContractStatus;
  contractNumber?: string;
  /** @format date */
  contractDateFrom?: string;
  /** @format date */
  contractDateTo?: string;
  claimPaymentSum?: number;
  signed?: boolean;
  /** @format date */
  realEndDate?: string;
  /** @format int64 */
  realDuration?: number;
  /** @format int64 */
  insuranceProgramId?: number;
  /** @format int64 */
  clientId?: number;
  /** @format date-time */
  payoutDate?: string;
  /** @format int64 */
  terminationCauseId?: number;
}

export interface ResultListDtoContractTerminationView {
  resultList?: ContractTerminationView[];
  /** @format int64 */
  count?: number;
  total?: ContractTerminationView;
}

export interface ContractPaymentView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  /** @format date */
  periodStartDate?: string;
  /** @format date */
  periodEndDate?: string;
  expectedPaymentAmount?: number;
  /** @format date */
  expectedPaymentDate?: string;
  paymentAmount?: number;
  /** @format date */
  paymentDate?: string;
  checkNumber?: string;
  leftToPay?: number;
  /** @format int64 */
  contractId?: number;
}

export interface ResultListDtoContractPaymentView {
  resultList?: ContractPaymentView[];
  /** @format int64 */
  count?: number;
  total?: ContractPaymentView;
}

export interface PaymentResultDto {
  contractPaymentStatus?: ContractPaymentStatus;
  error?: string;
}

export interface CheckoutUrlDto {
  checkoutUrl?: string;
  paymentId?: string;
  responseStatus?: string;
}

export interface PaymentButtonRequestDto {
  contractPayments?: ContractPaymentDto[];
  checkoutUrl?: CheckoutUrlDto;
}

export interface CompanyView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  name?: string;
  shortName?: string;
}

export interface PaymentCalculationsDto {
  currentLiabilitySum?: number;
  insuranceSum?: number;
  depreciation?: number;
  depreciationRate?: number;
  paymentsSum?: number;
  expertSum?: number;
  documents?: ContractClaimDocumentDto[];
  additionalDeductions?: DeductionDto[];
  franchise?: number;
  franchisePercent?: number;
  expectedPaymentSum?: number;
  paymentSum?: number;
  unpaidSumInstallment?: number;
}

export interface ClaimDocumentConfigurationDto {
  documentType?: DocumentTypeDto;
  visible?: boolean;
  obligatory?: boolean;
  informationObligatory?: boolean;
  /** @format int64 */
  riskId?: number;
  allowMultipleFiles?: boolean;
  calculable?: boolean;
  calculationParticipation?: boolean;
}

export interface ContractClaimDocumentView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  claimId?: number;
  claimStatus?: ClaimStatus;
  claimInfo?: Record<string, string>;
  claimNumber?: string;
  /** @format date */
  receiptDate?: string;
  /** @format date-time */
  claimDate?: string;
  /** @format date */
  incidentDate?: string;
  contractNumber?: string;
  signatory?: string;
  insuranceObject?: string;
  phone?: string;
  insuranceProgram?: string;
  risk?: string;
  /** @format int64 */
  loadedDocumentsAmount?: number;
  /** @format int64 */
  totalDocumentsAmount?: number;
  expectedLossSum?: number;
  paymentAmount?: number;
  paid?: number;
}

export interface ResultListDtoContractClaimDocumentView {
  resultList?: ContractClaimDocumentView[];
  /** @format int64 */
  count?: number;
  total?: ContractClaimDocumentView;
}

export enum ClaimSource {
  INSURANCE_MANAGER = 'INSURANCE_MANAGER',
}

export interface ContractClaimView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  claimId?: number;
  claimStatus?: ClaimStatus;
  claimNumber?: string;
  /** @format date-time */
  claimDate?: string;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  insuranceSum?: number;
  currencyCode?: string;
  contractNumber?: string;
  /** @format int64 */
  contractId?: number;
  /** @format int64 */
  generalContractId?: number;
  signatory?: string;
  insuranceObject?: string;
  pid?: string;
  phone?: string;
  risk?: string;
  franchise?: number;
  paymentSum?: number;
  paymentSystemType?: PaymentSystemType;
  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgram?: string;
  expertSum?: number;
  /** @format date */
  paymentDate?: string;
  /** @format date */
  incidentDate?: string;
  /** @format date */
  signDate?: string;
  claimSource?: ClaimSource;
  expectedLossSum?: number;
  paymentAmount?: number;
  paid?: number;
  claimSum?: number;
  insuranceAmount?: number;
  resolutionType?: ResolutionType;
  type?: PaymentRecipientType;
  clientName?: string;
  code?: string;
  bankName?: string;
  accountNumber?: string;
  recipientPhoneNumber?: string;
  destination?: string;
  /** @format date-time */
  updatedDate?: string;
  updatedBy?: string;
  partnerDepartmentPayout?: string;
  claimInfo?: Record<string, string>;
  partnerName?: string;
  current?: boolean;
  test?: boolean;
  /** @format int64 */
  riskId?: number;
  productCode?: string;
  /** @format int64 */
  clientId?: number;
}

export interface ResultListDtoContractClaimView {
  resultList?: ContractClaimView[];
  /** @format int64 */
  count?: number;
  total?: ContractClaimView;
}

export interface AssistanceContactDto {
  /** @format int64 */
  id?: number;
  contactType?: ContactType;
  contactData?: string;
  label?: string;
}

export interface AssistanceProgramContactsDto {
  /** @format int64 */
  assistanceCompanyId?: number;
  assistanceCompanyName?: string;
  /** @format int64 */
  insuranceProgramId?: number;
  assistanceContacts?: AssistanceContactDto[];
}

export enum ContactType {
  TELEGRAM = 'TELEGRAM',
  MOBILE_PHONE = 'MOBILE_PHONE',
  WORK_PHONE = 'WORK_PHONE',
  VIBER = 'VIBER',
  EMAIL = 'EMAIL',
  WEB_SITE = 'WEB_SITE',
}

export interface ExternalSavedSignedContractDto {
  contractNumber?: string;
  /** @format date-time */
  signDate?: string;
}

export interface VehicleSearchDetailsDto {
  pid?: string;
  brand?: string;
  brandId?: string;
  model?: string;
  modelId?: string;
  vinNumber?: string;
  vehicleType?: VehicleTypeGroupType;
}

export interface ResultListDtoVehicleView {
  resultList?: VehicleView[];
  /** @format int64 */
  count?: number;
  total?: VehicleView;
}

export interface VehicleView {
  markName?: string;
  modelName?: string;
  fullName?: string;
}

export interface CityZoneDto {
  cityName?: string;
  /** @format int64 */
  mtsbuId?: number;
  zone?: RegistrationZoneGroupType;
}

export interface CalculationRequestDefaultParamsDto {
  customerCategory?: CustomerCategoryType[];
  counterpartyType?: CounterpartyType[];
  privilegeType?: PrivilegeType[];
  franchise?: number;
  franchiseTo?: number;
  bonusMalus?: number;
  discount?: number;
  taxi?: boolean;
  autoCategory?: EuaVehicleType[];
  registrationCityName?: string;
  outsideUkraine?: boolean;
  registrationType?: VehicleRegistrationType[];
  otkDate?: string;
  dateFrom?: string;
  dateTo?: string;
  /** @format int32 */
  usageMonths?: number;
  crossSell?: boolean;
  customerBirthDate?: string;
}

export enum CustomerCategoryType {
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
  PRIVILEGED = 'PRIVILEGED',
}

export interface CalculationRequestDto {
  customerCategory?: CustomerCategoryType;
  counterpartyType?: CounterpartyType;
  privilegeType?: PrivilegeType;
  franchise?: number;
  franchiseTo?: number;
  bonusMalus?: number;
  discount?: number;
  taxi?: boolean;
  autoCategory?: EuaVehicleType;
  registrationCityName?: string;
  outsideUkraine?: boolean;
  registrationType?: VehicleRegistrationType;
  otkDate?: string;
  dateFrom?: string;
  dateTo?: string;
  /** @format int32 */
  usageMonths?: number;
  crossSell?: boolean;
  customerBirthDate?: string;
  internetSalesToken?: string;
}

export interface CustomerFieldSettingsDto {
  code?: FieldVisibilityType;
  name?: FieldVisibilityType;
  nameFirst?: FieldVisibilityType;
  nameMiddle?: FieldVisibilityType;
  address?: FieldVisibilityType;
  phone?: FieldVisibilityType;
  mail?: FieldVisibilityType;
  birthDate?: FieldVisibilityType;
  documentTypes?: PersonDocumentType[];
  documentSeries?: FieldVisibilityType;
  documentNumber?: FieldVisibilityType;
  documentDate?: FieldVisibilityType;
  documentIssuedBy?: FieldVisibilityType;
  documentDateEnd?: FieldVisibilityType;
}

export interface ExternalOsagoCalculationResultDto {
  insuranceCompanyName?: string;
  insuranceCompanyLogoUrl?: string;
  tariffId?: string;
  paymentSum?: number;
  calculationType?: OsagoFlowType;
  insuranceCompanyCode?: string;
  offer?: string;
  offerFranchise?: number;
  offerPrice?: number;
  type?: string;
  commission?: number;
  /** @format int32 */
  dateFromMax?: number;
  customerSettings?: CustomerFieldSettingsDto;
  minBonusMalus?: number;
}

export enum FieldVisibilityType {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
  REQUIRED = 'REQUIRED',
  HIDDEN_WITH_DEFAULT = 'HIDDEN_WITH_DEFAULT',
}

export interface AggregatorPartnerProgramsDto {
  insuranceCompanyName?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  insurancePrograms?: ConfigurationInsuranceProgramDto[];
}

export interface ConfigurationInsuranceProductDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
}

export interface ConfigurationInsuranceProgramDto {
  /** @format int64 */
  id?: number;
  code?: string;
  /** @format int64 */
  contractTypeId?: number;
  name?: string;
  active?: boolean;
  /** @format date */
  startDate?: string;
  /** @format date */
  endDate?: string;
  businessExpensesPercent?: number;
  contractNumber?: string;
  risks?: ConfigurationProgramRiskDto[];
  policies?: InsurancePolicyDto[];
  /** @format int64 */
  signingAgentId?: number;
  /** @format int64 */
  claimManagerId?: number;
  /** @format date */
  createdDate?: string;
  /** @format int64 */
  insuranceCompanyId?: number;
  insuranceProduct?: ConfigurationInsuranceProductDto;
  objectConfiguration?: object;
  subAgreementSmsEnabled?: boolean;
  programInstallmentPayments?: ConfigurationProgramInstallmentPaymentDto[];
  deferredPaymentEnabled?: boolean;
  fixedStartDateEnabled?: boolean;
  autoGenerationNumberEnabled?: boolean;
  prolongationConfiguration?: ProlongationConfigurationDto;
  assistanceContacts?: AssistanceContactDto[];
  /** @format int64 */
  assistanceCompanyId?: number;
  widgetType?: WidgetTypeDto;
}

export interface ConfigurationInsuranceTypeDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
}

export interface ConfigurationProgramInstallmentPaymentDto {
  /** @format int64 */
  id?: number;
  installmentPaymentPeriodType?: InstallmentPaymentPeriodType;
  /** @format int32 */
  countPeriodFirstPayment?: number;
  minimalInsuranceSumInstallmentPayment?: number;
}

export interface ConfigurationProgramRiskDto {
  /** @format int64 */
  id?: number;
  risk?: ConfigurationRiskDto;
  liabilityLimit?: number;
  franchiseValueType?: FranchiseValueType;
  franchise?: number;
  riskFranchises?: RiskFranchiseDto[];
  sharePercent?: number;
  expressPaymentAvailable?: boolean;
  customConfiguration?: Record<string, string>;
  currency?: Currency;
}

export interface ConfigurationRiskDto {
  /** @format int64 */
  id?: number;
  name?: string;
  code?: string;
  insuranceRule?: OptionDto;
  insuranceType?: ConfigurationInsuranceTypeDto;
}

export interface Duration {
  /** @format int32 */
  duration?: number;
  durationUnit?: ChronoUnitSimplifiedDuplicate;
}

export interface InsurancePolicyDto {
  /** @format int64 */
  id?: number;
  name?: string;
  policyType?: PolicyType;
  rates?: InsuranceRateDto[];
}

export interface InsuranceRateDto {
  /** @format int64 */
  id?: number;
  duration?: Duration;
  currency?: Currency;
  contractNumberEnding?: string;
  insuranceSums?: InsuranceSumDto[];
}

export interface InsuranceSumDto {
  /** @format int64 */
  id?: number;
  insuranceSum?: number;
  paymentAmount?: number;
}

export enum PolicyType {
  ORDINAL = 'ORDINAL',
  PARTNER_PROMOTION = 'PARTNER_PROMOTION',
  INSURANCE_COMPANY_PROMOTION = 'INSURANCE_COMPANY_PROMOTION',
  PROLONGATION = 'PROLONGATION',
}

export interface ProlongationConfigurationDto {
  /** @format int64 */
  id?: number;
  /** @format int32 */
  beforeContractEnding?: number;
  beforeContractEndingUnit?: ChronoUnitSimplifiedDuplicate;
  /** @format int32 */
  afterContractEnding?: number;
  afterContractEndingUnit?: ChronoUnitSimplifiedDuplicate;
}

export interface RiskFranchiseDto {
  /** @format int64 */
  id?: number;
  franchise?: number;
  /** @format int64 */
  programRiskId?: number;
}

export interface AgentActInfoDto {
  /** @format int64 */
  insuranceProgramId?: number;
  insuranceProgramName?: string;
  partnerName?: string;
  partnerShortName?: string;
  partnerPostAddress?: string;
  partnerEnterpriseCode?: string;
  partnerDirectorFullName?: string;
  partnerAcquirerBank?: string;
  insuranceCompanyFullName?: string;
  insuranceCompanyName?: string;
  insuranceCompanyAbbreviation?: string;
  insuranceCompanyDirectorPosition?: string;
  insuranceCompanyDirectorFullName?: string;
  insuranceCompanyEnterpriseCode?: string;
  insuranceCompanyPostAddress?: string;
  insuranceCompanyIbanAccountNumber?: string;
  insuranceCompanyBankName?: string;
  insuranceCompanyBankEnterpriseCode?: string;
  insuranceCompanyPhoneNumber?: string;
  agentContractNumber?: string;
  /** @format date */
  actDate?: string;
  /** @format date */
  dateFrom?: string;
  /** @format date */
  dateTo?: string;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  agentContractId?: number;
  agentActNumber?: string;
  /** @format date-time */
  actSignDate?: string;
  /** @format date-time */
  agentContractDate?: string;
  /** @format int64 */
  contractsCount?: number;
  /** @format int64 */
  terminatedContractsCount?: number;
  contractCalculatedPaymentAmount?: number;
  contractPaymentAmount?: number;
  feeSum?: number;
  baseFeeSum?: number;
  actualFeePercent?: number;
  returnedPaymentSum?: number;
  returnedToPartnerSum?: number;
  periodFeeSum?: number;
  /** @format int64 */
  agentId?: number;
  agentActCode?: AgentActCode;
  actAdditionalFee?: number;
  actAdditionalFeeUnit?: AdditionalFeeUnitType;
  actAdditionalFeeSum?: number;
  currency?: CurrencyType;
  insuranceCompanyEmail?: string;
  agentFullName?: string;
  agentAddress?: string;
  agentPhoneNumber?: string;
  partnerSignatoryFullName?: string;
  partnerSignatoryPosition?: string;
  foundingDocument?: string;
  ibanAccountNumber?: string;
  bankName?: string;
  bankEnterpriseCode?: string;
  taxpayerCertificateNumber?: string;
}

export interface AgentActView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  /** @format date */
  conclusionDate?: string;
  /** @format int64 */
  agentContractId?: number;
  agentContractNumber?: string;
  contractNumber?: string;
  clientName?: string;
  currencyCode?: string;
  calculatedPaymentAmount?: number;
  paymentAmount?: number;
  actualPaymentAmount?: number;
  paymentNumber?: string;
  feeSum?: number;
  baseFeeSum?: number;
  toPay?: number;
  feePercent?: number;
  /** @format int64 */
  agentActId?: number;
  /** @format date */
  endDate?: string;
  status?: ContractStatus;
  /** @format date */
  filterDate?: string;
  /** @format int64 */
  insuranceProgramId?: number;
  terminated?: boolean;
  returnedPayment?: number;
  returnedToPartner?: number;
}

export interface ResultListDtoAgentActView {
  resultList?: AgentActView[];
  /** @format int64 */
  count?: number;
  total?: AgentActView;
}

export interface AgentActDto {
  /** @format int64 */
  id?: number;
  actNumber?: string;
  /** @format date-time */
  actDate?: string;
  /** @format date-time */
  dateFrom?: string;
  /** @format date-time */
  dateTo?: string;
  /** @format int64 */
  countContracts?: number;
  sumInsurancePayments?: number;
  sumFee?: number;
  sumToPay?: number;
  /** @format date-time */
  confirmationDate?: string;
  sumPaid?: number;
  /** @format date-time */
  paymentDate?: string;
  documentPaymentNumber?: string;
  /** @format int64 */
  countPaidContracts?: number;
}

export interface AgentActStatisticsDto {
  /** @format int64 */
  reportCountContracts?: number;
  /** @format int32 */
  reportRecords?: number;
  reportSumInsurancePayments?: number;
  reportSumFee?: number;
  reportBaseFeeSum?: number;
  reportSumToPay?: number;
  reportSumPaid?: number;
  /** @format int64 */
  reportCountPaidContracts?: number;
  /** @format int32 */
  totalRecords?: number;
  totalSumInsurancePayments?: number;
  totalSumFee?: number;
  totalBaseFeeSum?: number;
  totalSumToPay?: number;
}

export interface AgentActsDto {
  acts?: AgentActsView[];
  statistic?: AgentActStatisticsDto;
  /** @format int64 */
  totalRecords?: number;
}

export interface AgentActsView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  actNumber?: string;
  /** @format int64 */
  agentId?: number;
  /** @format int64 */
  agentContractId?: number;
  /** @format date */
  actDate?: string;
  /** @format date */
  dateFrom?: string;
  /** @format date */
  dateTo?: string;
  /** @format int64 */
  countContracts?: number;
  sumInsurancePayments?: number;
  feeSum?: number;
  baseFeeSum?: number;
  actSum?: number;
  /** @format date */
  confirmationDate?: string;
  paid?: number;
  /** @format date */
  paymentDate?: string;
  paymentDocumentNumber?: string;
  /** @format int64 */
  countPaidContracts?: number;
  /** @format int64 */
  insuranceProgramId?: number;
}

export interface AgentContractView {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  id?: number;
  /** @format int64 */
  agentId?: number;
  contractNumber?: string;
  /** @format date-time */
  contractDate?: string;
}

export interface ResultListDtoAgentContractView {
  resultList?: AgentContractView[];
  /** @format int64 */
  count?: number;
  total?: AgentContractView;
}

export interface AgentContractDto {
  /** @format int64 */
  id?: number;
  contractNumber?: string;
  /** @format date-time */
  contractDate?: string;
}

export interface RenameFileParams {
  /**
   * @format int32
   * @default 0
   */
  pageNumber?: number;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /** @format int64 */
  contractId: number;
  documentTypeCode: DocumentTypeCode;
  fileKey: string;
  fileName: string;
}

export interface UpdateClaimStatusParams {
  claimStatus: ClaimStatus;
  /** @format int64 */
  rejectionId?: number;
  /** @format int64 */
  id: number;
}

export interface RenameFile1Params {
  /**
   * @format int32
   * @default 0
   */
  pageNumber?: number;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /** @format int64 */
  claimId: number;
  documentTypeCode: DocumentTypeCode;
  fileKey: string;
  fileName: string;
}

export interface Read10Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** @format int64 */
  id: number;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  agentContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  agentContractNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currencyCode?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  calculatedPaymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actualPaymentAmount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  paymentNumber?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  feeSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  baseFeeSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  toPay?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  feePercent?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  agentActId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  filterDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminated?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  returnedPayment?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  returnedToPartner?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [UserStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  username?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentName?: string;
  /** Filter value. View field type is [UserRole] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  role?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  client?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signupDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  lastLoginDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface CreateExternalIdParams {
  /** @format int64 */
  insuranceProgramId: number;
  /** @format int64 */
  departmentId: number;
  /** @format int64 */
  id: number;
}

export interface List2Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  identifier?: string;
  /** Filter value. View field type is [PartnerType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  shortName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  telegramChatId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  telegramChatLink?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  telegramManagerSupportChatId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  telegramManagerSupportChatLink?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface DownloadInsuredPersonListParams {
  /** @format int32 */
  existingInsuredPerson: number;
}

export interface ReceiveDocumentsParams {
  encodeData: string;
}

export interface ListBrandsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  brand?: string;
  /** Filter value. View field type is [InsuranceObjectType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  subtype?: string;
}

export interface List4Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  abbreviation?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  enterpriseCode?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contactPersonFullName?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currencyCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface List5Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  regionId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  region?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  departmentNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [DepartmentType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  locality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  street?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  active?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  director?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  activationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  deactivationDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface Create4Params {
  /** @format int64 */
  claimId: number;
}

export interface SignContractParams {
  token?: string;
  russianCitizen?: boolean;
  /** @format int64 */
  id: number;
}

export interface SendSignVerificationParams {
  sendingType?: SendingType;
  /** @format int64 */
  id: number;
}

export interface SendContractReportParams {
  isSendSms: boolean;
  /** @default "ua" */
  language?: string;
  /** @format int64 */
  id: number;
}

export interface SendClientOnSignParams {
  russianCitizen?: boolean;
  /** @format int64 */
  id: number;
}

export interface UploadFileParams {
  file: File[];
  /** @format int64 */
  contractId: number;
  documentTypeCode: DocumentTypeCode;
}

export interface UpdateStatusDeleteParams {
  isDeleted: boolean;
}

export interface ClientSignParams {
  token: string;
  /** @default true */
  needClientSign?: boolean;
  russianCitizen?: boolean;
  /** @format uuid */
  externalId: string;
}

export interface SendClientSignVerificationParams {
  /** @default true */
  needClientSign?: boolean;
  /** @format uuid */
  externalId: string;
}

export interface List7Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  number?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  originalContractNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  originalContractId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  current?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientName?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientStreet?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryPhoneNumber?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  beneficiaryType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryStreet?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementConclusionDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementStartDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementEndDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceAmount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currencyCode?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  calculatedPaymentAmount?: string;
  /** Filter value. View field type is [short] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  numberPayments?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectPid?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectName?: string;
  /** Filter value. View field type is [InsuranceObjectType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceObjectType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectSubtype?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  check_number?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleSum?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractTerminationId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentRegion?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  sellerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  sellerPhoneNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  verifiedAt?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  participateInAgentAct?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  test?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  generalContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentsName?: string;
  /** Filter value. View field type is [SubAgreementType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  orderId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface SignContractTerminationParams {
  token?: string;
  /** @format int64 */
  id: number;
}

export interface SendSignVerification1Params {
  sendingType?: SendingType;
  /** @format int64 */
  id: number;
}

export interface List8Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  terminationId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  terminationNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  terminationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signDate?: string;
  /** Filter value. View field type is [Integer] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  duration?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  initiator?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  refundSum?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currency?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractDateFrom?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractDateTo?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimPaymentSum?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signed?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  realEndDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  realDuration?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  payoutDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  terminationCauseId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface SignContractClaimParams {
  token?: string;
  /** @format int64 */
  id: number;
}

export interface SendSignVerification2Params {
  sendingType?: SendingType;
  /** @format int64 */
  id: number;
}

export interface ConfirmPaymentParams {
  payment_system_type: PaymentSystemType;
  /** @format int64 */
  id: number;
}

export interface UploadFile1Params {
  file: File[];
  /** @format int64 */
  claimId: number;
  documentTypeCode: DocumentTypeCode;
}

export interface ReserveContractInMtsbuParams {
  otp: string;
  /** Contract id */
  id: string;
}

export interface Create8Params {
  /** Valid contract for saving in 'Draft' status */
  tariffId: string;
}

export interface AgentActsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  actNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  agentId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  agentContractId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dateFrom?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dateTo?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  countContracts?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  sumInsurancePayments?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  feeSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  baseFeeSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actSum?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  confirmationDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paid?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  paymentDocumentNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  countPaidContracts?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetAgentsParams {
  /** @format int64 */
  partnerId: number;
}

export interface PrintPdfReportParams {
  timeZone: string;
  /** @format int64 */
  id: number;
}

export interface ListRolesParams {
  user: AuthenticationDetailsDto;
}

export interface PrintXlsxReportParams {
  timeZone: string;
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [UserStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  username?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentName?: string;
  /** Filter value. View field type is [UserRole] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  role?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  client?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signupDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  lastLoginDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListContractActivitiesParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  userActivityLogId?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  activityDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  shortName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  login?: string;
  /** Filter value. View field type is [UserRole] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  role?: string;
  /** Filter value. View field type is [ActionType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  action?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  details?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  entityId?: string;
  /** Filter value. View field type is [EntityType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  entityType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListActivitiesParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  activityDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  shortName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  login?: string;
  /** Filter value. View field type is [UserRole] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  role?: string;
  /** Filter value. View field type is [ActionType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  action?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  details?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  entityId?: string;
  /** Filter value. View field type is [EntityType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  entityType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  userCounterpartyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  userId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  logContractId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface PrintXlsxActivityLogsReportParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  activityDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  shortName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  login?: string;
  /** Filter value. View field type is [UserRole] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  role?: string;
  /** Filter value. View field type is [ActionType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  action?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  details?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  entityId?: string;
  /** Filter value. View field type is [EntityType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  entityType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  userCounterpartyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  userId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  logContractId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetProductRiskStatisticsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface RiskPaymentInfoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  generalContractId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface GetInsuranceProgramStatisticsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface ProgramPaymentInfoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  generalContractId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface ListPaymentStatisticsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
}

export interface GetPartnerStatisticsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface GetMonthInfoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
}

export interface PaymentInfoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  generalContractId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface DashboardGeneralInfoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  generalContractId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface GetPartnerDepartmentStatisticsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface GetDaysStatisticsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  generalContractId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface GetDashboardInfoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface ListPayStatisticsParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
}

export interface ListBrandSalesParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format int64 */
  partnerDepartmentId?: number;
  /** @format int64 */
  deviceTypeId: number;
  insuranceObjectType: InsuranceObjectType;
}

export interface PrintXlsxSaleRegisterListParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [StatusType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleRegisterStatus?: string;
  /** Filter value. View field type is [OwnerEquipmentType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  ownerEquipment?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectPid?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectSubtype?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleRevenue?: string;
  /** Filter value. View field type is [ResolutionType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  resolution?: string;
  /** Filter value. View field type is [ClaimStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimStatus?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  incidentDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentPayout?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentSum?: string;
  /** Filter value. View field type is [DeliveryType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  delivery?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  invoiceNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  serviceCenter?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  serviceCenterDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  damage?: string;
  /** Filter value. View field type is [RepairStatusType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  repairStatus?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  repairCost?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dispatchFromRepairDate?: string;
  /** Filter value. View field type is [DeliveryType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  repairEquipmentDelivery?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  repairEquipmentDeliveryAddress?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  repairEquipmentInvoiceNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  receiptDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  amountRepairCost?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  otherExpenses?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  salePrice?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  dispatchAfterSaleInvoiceNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dispatchDate?: string;
  /** Filter value. View field type is [DeliveryType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dispatchAfterSaleDelivery?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  transferEquipmentToBuyerDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface List1Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [StatusType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleRegisterStatus?: string;
  /** Filter value. View field type is [OwnerEquipmentType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  ownerEquipment?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectPid?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectSubtype?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleRevenue?: string;
  /** Filter value. View field type is [ResolutionType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  resolution?: string;
  /** Filter value. View field type is [ClaimStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimStatus?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  incidentDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentPayout?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentSum?: string;
  /** Filter value. View field type is [DeliveryType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  delivery?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  invoiceNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  serviceCenter?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  serviceCenterDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  damage?: string;
  /** Filter value. View field type is [RepairStatusType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  repairStatus?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  repairCost?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dispatchFromRepairDate?: string;
  /** Filter value. View field type is [DeliveryType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  repairEquipmentDelivery?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  repairEquipmentDeliveryAddress?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  repairEquipmentInvoiceNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  receiptDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  amountRepairCost?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  otherExpenses?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  salePrice?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  dispatchAfterSaleInvoiceNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dispatchDate?: string;
  /** Filter value. View field type is [DeliveryType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  dispatchAfterSaleDelivery?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  transferEquipmentToBuyerDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetInsuranceCompaniesParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  enterpriseCode?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  directorFullName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  postAddress?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  createdBy?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdAt?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  deactivatedAt?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
}

export interface ListContractTransferHistoryParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  historyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  packageNumber?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  operationDate?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  success?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  details?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  message?: string;
  /** Filter value. View field type is [IntegrationType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [ProcessType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  processType?: string;
}

export interface List3Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  packageNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramName?: string;
  /** Filter value. View field type is [IntegrationHistoryType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  transferType?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  operationDate?: string;
  /** Filter value. View field type is [Integer] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  entitiesToTransferCount?: string;
  /** Filter value. View field type is [Integer] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  successCount?: string;
  /** Filter value. View field type is [Integer] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  failedCount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  initiator?: string;
  /** Filter value. View field type is [IntegrationType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetDeepLinkParams {
  phoneNumber?: string;
}

export interface PrintXlsxReport1Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  active?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceRules?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceTypeName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramActivationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramDeactivationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  applicablePeriodFrom?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  applicablePeriodTo?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  visible?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  installmentPaymentEnabled?: string;
  /** Filter value. View field type is [Boolean] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientSignContractEnabled?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimManagerId?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  businessExpensesPercent?: string;
  /** Filter value. View field type is [short] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  expressPaymentWorkingDays?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractTypeId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signingAgentId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  subAgreementSmsEnabled?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  individualTermsTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  individualTermsTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  claimTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceActTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceActTemplateGeneratorCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  terminationTemplateId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  terminationTemplateGeneratorCode?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  contractOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  claimOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminationOtpDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  contractSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  claimSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminationSendSmsDisabled?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  requiredContractVerify?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  fixedStartDateEnabled?: string;
  /** Filter value. View field type is [ContractDraftStoragePeriodType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractDraftTtl?: string;
  /** Filter value. View field type is [ContractDraftStoragePeriodType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerProgramContractDraftTtl?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListAvailableInsuranceProgramsForPartnerParams {
  /** @format int64 */
  partnerId: number;
}

export interface ListAvailableInsuranceProgramsForExternalIdByPartnerParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  departmentId?: number;
}

export interface ListTypesSubtypeParams {
  /** @format int64 */
  insuranceProgramId?: number;
  objectType?: InsuranceObjectType;
}

export interface ListSubtypesParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface FindInsuranceObjectResponseDtoParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  originalContractNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  originalContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  current?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementNumber?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractStatus?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  identifier?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  receiptNumber?: string;
  /** Filter value. View field type is [InsuranceObjectType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  verifiedAt?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceSumCurrencyCode?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  calculatedInsuranceRate?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractBeneficiaryType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryPhoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryLastName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryFirstName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryMiddleName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryStreet?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryCitizenship?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  symbol?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface FindInsuranceObjectsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  originalContractNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  originalContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  current?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementNumber?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractStatus?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  identifier?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  receiptNumber?: string;
  /** Filter value. View field type is [InsuranceObjectType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  verifiedAt?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceSumCurrencyCode?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  calculatedInsuranceRate?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractBeneficiaryType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryPhoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryLastName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryFirstName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryMiddleName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryStreet?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractBeneficiaryCitizenship?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  symbol?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListAllParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  abbreviation?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  enterpriseCode?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contactPersonFullName?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currencyCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetMerchantBalancesParams {
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetCheckListsFinApParams {
  /** @format int64 */
  contractId: number;
}

export interface PrintXlsxReport2Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [DiscountStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceCompanyName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductName?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  autoApplicable?: string;
  /** Filter value. View field type is [InitiatorType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  initiator?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  holderType?: string;
  /** Filter value. View field type is [DiscountType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramName?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  amount?: string;
  /** Filter value. View field type is [Duration] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  duration?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  agentContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  updatedBy?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  updatedDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface PrintXlsxContractListParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  regionId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  region?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  departmentNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [DepartmentType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  locality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  street?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  active?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  director?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createdDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  activationDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  deactivationDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface ListAvailableDepartmentsByPartnerParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  insuranceProgramId?: number;
}

export interface GetDeductionListParams {
  /** @format int64 */
  claimId: number;
}

export interface FindCounterpartyByEmailParams {
  email: string;
  type: CounterpartyType;
}

export interface List6Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  shortName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  fullName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  lastName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  firstName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  middleName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  enterpriseCode?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  inn?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  birthday?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  locality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  street?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
}

export interface PrintPdfReport1Params {
  /** @default "ua" */
  language?: string;
  timeZone: string;
  /** @format int64 */
  id: number;
}

export interface PrintInvoiceReportParams {
  timeZone: string;
  /** @format int64 */
  id: number;
}

export interface DownloadDocumentDescriptionFilesParams {
  /**
   * @format int32
   * @default 0
   */
  pageNumber?: number;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /** @default true */
  scale?: boolean;
  /** @format int64 */
  contractId: number;
  documentTypeCode: DocumentTypeCode;
}

export interface UpdateContractAgentsAndContractObjectRiskParams {
  contractNumber: string;
}

export interface ListSubAgreementsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  number?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  originalContractNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  originalContractId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  current?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientName?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientStreet?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryPhoneNumber?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  beneficiaryType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryStreet?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementConclusionDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementStartDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementEndDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceAmount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currencyCode?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  calculatedPaymentAmount?: string;
  /** Filter value. View field type is [short] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  numberPayments?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectPid?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectName?: string;
  /** Filter value. View field type is [InsuranceObjectType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceObjectType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectSubtype?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  check_number?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleSum?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractTerminationId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentRegion?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  sellerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  sellerPhoneNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  verifiedAt?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  participateInAgentAct?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  test?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  generalContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentsName?: string;
  /** Filter value. View field type is [SubAgreementType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  orderId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface PrintXlsxContractList1Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  number?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  originalContractNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  originalContractId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  current?: string;
  /** Filter value. View field type is [int] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientName?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  email?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientStreet?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryPhoneNumber?: string;
  /** Filter value. View field type is [CounterpartyType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  beneficiaryType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryLocality?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  beneficiaryStreet?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementConclusionDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementStartDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementEndDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceAmount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currencyCode?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  calculatedPaymentAmount?: string;
  /** Filter value. View field type is [short] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  numberPayments?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectPid?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectName?: string;
  /** Filter value. View field type is [InsuranceObjectType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceObjectType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObjectSubtype?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  check_number?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  saleSum?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgramName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractTerminationId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentRegion?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  sellerName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  sellerPhoneNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  verifiedAt?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  participateInAgentAct?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  test?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProductCode?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  generalContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentsName?: string;
  /** Filter value. View field type is [SubAgreementType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  subAgreementType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  orderId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GenerateContractNumberParams {
  insuranceProgramCode: string;
}

export interface PrintPdfReportExternalParams {
  timeZone: string;
  /** @default true */
  needClientSign?: boolean;
  /** @format uuid */
  externalId: string;
}

export interface GetOfferUrl1Params {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  insuranceCompanyId: number;
  insuranceProgramCode: string;
}

export interface ListOptionInfoDtoParams {
  /** @format int64 */
  insuranceCompanyId?: number;
  /** @format int64 */
  partnerId?: number;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
}

export interface ListContractImeiDuplicationsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  imei?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  signatoryPhoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  signatoryName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  objectName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  objectSubtypeName?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  symbol?: string;
}

export interface GetContractDocumentConfigurationsParams {
  /** @format int64 */
  partnerId: number;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface GetCurrentLiabilityParams {
  /** @format int64 */
  contractObjectId?: number;
  /** @format int64 */
  riskId?: number;
  /** @format int64 */
  contractId: number;
}

export interface GetContactTerminationPdfParams {
  timeZone: string;
  /** @format int64 */
  id: number;
}

export interface GetTemplateParams {
  /** @format int64 */
  contractId: number;
}

export interface PrintXlsxContractTerminationListParams {
  params: Record<string, string>;
}

export interface GetInitiatorsParams {
  /** @format int64 */
  contractId: number;
}

export interface GetTerminationDocumentConfigurationsParams {
  initiator: TerminationInitiatorCode;
  /** @format int64 */
  contractId: number;
}

export interface GetTerminationCausesParams {
  terminationInitiator: TerminationInitiatorCode;
  /** @format int64 */
  insuranceProgramId: number;
}

export interface PaymentListParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  periodStartDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  periodEndDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  expectedPaymentAmount?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  expectedPaymentDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  checkNumber?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  leftToPay?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface List9Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  shortName?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetInsuranceClaimPdfParams {
  timeZone: string;
  documentType: string;
  /** @format int64 */
  id: number;
}

export interface GetInsuranceActPdfParams {
  timeZone: string;
  documentType: string;
  /** @format int64 */
  id: number;
}

export interface RejectExpressPaymentParams {
  /** @format int64 */
  rejectId: number;
  /** @format int64 */
  id: number;
}

export interface DownloadDocumentDescriptionFiles1Params {
  /**
   * @format int32
   * @default 0
   */
  pageNumber?: number;
  /**
   * @format int32
   * @default 10
   */
  pageSize?: number;
  /** @default true */
  scale?: boolean;
  /** @format int64 */
  claimId: number;
  documentTypeCode: DocumentTypeCode;
}

export interface GetTemplate1Params {
  /** @format int64 */
  contractId: number;
}

export interface PrintXlsxNotificationParams {
  params: Record<string, string>;
}

export interface FindClaimDocumentConfigurationsParams {
  /** @format int64 */
  contractId: number;
  /** @format int64 */
  riskId: number;
  resolutionType: ResolutionType;
}

export interface GetDocumentConfirmationsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimId?: string;
  /** Filter value. View field type is [ClaimStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimStatus?: string;
  /** Filter value. View field type is [Map] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimInfo?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  claimNumber?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  receiptDate?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  incidentDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  signatory?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObject?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phone?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgram?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  risk?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  loadedDocumentsAmount?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  totalDocumentsAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  expectedLossSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paid?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface List10Params {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimId?: string;
  /** Filter value. View field type is [ClaimStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimStatus?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  claimNumber?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  startDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceSum?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  currencyCode?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  generalContractId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  signatory?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceObject?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  pid?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  phone?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  risk?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  franchise?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentSum?: string;
  /** Filter value. View field type is [PaymentSystemType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentSystemType?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  insuranceProgram?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  expertSum?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  incidentDate?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  signDate?: string;
  /** Filter value. View field type is [ClaimSource] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimSource?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  expectedLossSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paid?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceAmount?: string;
  /** Filter value. View field type is [ResolutionType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  resolutionType?: string;
  /** Filter value. View field type is [PaymentRecipientType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  type?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  code?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  bankName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  accountNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  recipientPhoneNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  destination?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  updatedDate?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  updatedBy?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerDepartmentPayout?: string;
  /** Filter value. View field type is [Map] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  claimInfo?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  partnerName?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  current?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  test?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  riskId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  productCode?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  clientId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface GetSignedContractParams {
  partnerDepartmentNumber: string;
  checkNumber: string;
  pid?: string;
}

export interface ListVehiclesParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  markName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  modelName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  fullName?: string;
}

export interface GetMarksAndModelsParams {
  /** Search query */
  query: string;
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  markName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  modelName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  fullName?: string;
}

export interface GetZoneByCityParams {
  /** Part of city name to search for */
  city: string;
}

export interface GetCalculationsParams {
  params: CalculationRequestDto;
}

export interface ListProgramsByProductIdParams {
  productCode: string;
}

export interface GetAgentsByTypeParams {
  type: string;
}

export interface PrintXlsxReport3Params {
  params: Record<string, string>;
  id: string;
}

export interface PrintContractsXlsxReportParams {
  params: Record<string, string>;
  id: string;
}

export interface PrintXlsxAgentActsParams {
  params: Record<string, string>;
}

export interface InitNewAgentActParams {
  /** @format int64 */
  agentContractId: number;
}

export interface AllContractsAvailableForAgentActParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  originalContractId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  conclusionDate?: string;
  /** @format int64 */
  agentContractId: number;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  clientName?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentAmount?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  paymentNumber?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  feeSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  baseFeeSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  toPay?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  feePercent?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  agentActId?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  endDate?: string;
  /** Filter value. View field type is [ContractStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  status?: string;
  /** Filter value. View field type is [PaymentStatus] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  paymentStatus?: string;
  /** Filter value. View field type is [LocalDate] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  filterDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceProgramId?: string;
  /** Filter value. View field type is [boolean] .Recommended next filters: value attribute should be equals, '<>' not equals to value */
  terminated?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  calculatedPaymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  returnedPaymentSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  returnedToPartnerSum?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  actualPaymentAmount?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface AvailableAgentContractsParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  agentId?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractDate?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  insuranceCompanyId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  partnerDepartmentId?: string;
}

export interface AgentContractNumbersParams {
  /** @format int64 */
  partnerId: number;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios-next';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://test.eks.inrisk.com.ua' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title INRISK Contracts Service
 * @version 0.0.1
 * @baseUrl https://test.eks.inrisk.com.ua
 *
 * Contracts Service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  userController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_SYSTEM') or @userAclService.hasAccess(#id)
     *
     * @tags user-controller
     * @name Read
     * @summary read
     * @request GET:/users/{id}
     * @secure
     */
    read: (id: number, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/users/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER') or @userAclService.hasAccess(#id)
     *
     * @tags user-controller
     * @name Update
     * @summary update
     * @request PUT:/users/{id}
     * @secure
     */
    update: (id: number, data: UserDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/users/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_CLIENT')
     *
     * @tags user-controller
     * @name DeactivateCurrentUser
     * @summary deactivate current user
     * @request PUT:/users/current/deactivate
     * @secure
     */
    deactivateCurrentUser: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/current/deactivate`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name SendPasswordResetSms
     * @summary send password reset sms
     * @request POST:/users/password/send-reset-sms
     * @secure
     */
    sendPasswordResetSms: (data: ResetPasswordRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/password/send-reset-sms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ValidateToken
     * @summary validate token
     * @request POST:/users/password/reset/token
     * @secure
     */
    validateToken: (data: ResetPasswordTokenValidationDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/password/reset/token`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name ResetPassword
     * @summary reset password
     * @request POST:/users/password/reset
     * @secure
     */
    resetPassword: (data: ResetPasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/password/reset`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('PERSONAL_SETTINGS', 'update')
     *
     * @tags user-controller
     * @name DeactivateUsers
     * @summary deactivate users
     * @request POST:/users/deactivate
     * @secure
     */
    deactivateUsers: (data: DeactivateUsersRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/deactivate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name SendPasswordResetSmsToClient
     * @summary send password reset sms to client
     * @request POST:/users/clients/password/send-reset-sms
     * @secure
     */
    sendPasswordResetSmsToClient: (data: ResetPasswordRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/clients/password/send-reset-sms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name SendPasswordResetEmailToClient
     * @summary send password reset email to client
     * @request POST:/users/clients/password/send-reset-email
     * @secure
     */
    sendPasswordResetEmailToClient: (data: ResetPasswordRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/clients/password/send-reset-email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Query AuthUserView using dynamic search of TableViewRepository
     *
     * @tags user-controller
     * @name List
     * @summary list
     * @request GET:/users
     * @secure
     */
    list: (query: ListParams, params: RequestParams = {}) =>
      this.request<ResultListDtoAuthUserView, any>({
        path: `/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('USER_SYSTEM', 'create')
     *
     * @tags user-controller
     * @name Create
     * @summary create
     * @request POST:/users
     * @secure
     */
    create: (data: UserDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/users`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('PERSONAL_SETTINGS', 'export')
     *
     * @tags user-controller
     * @name PrintPdfReport
     * @summary print pdf report
     * @request GET:/users/{id}/pdf-report
     * @secure
     */
    printPdfReport: ({ id, ...query }: PrintPdfReportParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/users/${id}/pdf-report`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('PERSONAL_SETTINGS', 'read')
     *
     * @tags user-controller
     * @name ListRoles
     * @summary list roles
     * @request GET:/users/roles
     * @secure
     */
    listRoles: (query: ListRolesParams, params: RequestParams = {}) =>
      this.request<UserRole[], any>({
        path: `/users/roles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query AuthUserView using dynamic search of TableViewRepository
     *
     * @tags user-controller
     * @name PrintXlsxReport
     * @summary print xlsx report
     * @request GET:/users/print/xlsx
     * @secure
     */
    printXlsxReport: (query: PrintXlsxReportParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/users/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user-controller
     * @name GetCurrentUser
     * @summary get current user
     * @request GET:/users/current
     * @secure
     */
    getCurrentUser: (params: RequestParams = {}) =>
      this.request<CurrentUserDto, any>({
        path: `/users/current`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query ContractUserActivityLogView using dynamic search of TableViewRepository
     *
     * @tags user-controller
     * @name ListContractActivities
     * @summary list contract activities
     * @request GET:/users/contract-activity-logs
     * @secure
     */
    listContractActivities: (query: ListContractActivitiesParams, params: RequestParams = {}) =>
      this.request<ResultListDtoContractUserActivityLogView, any>({
        path: `/users/contract-activity-logs`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Query UserActivityLogView using dynamic search of TableViewRepository
     *
     * @tags user-controller
     * @name ListActivities
     * @summary list activities
     * @request GET:/users/activity-logs
     * @secure
     */
    listActivities: (query: ListActivitiesParams, params: RequestParams = {}) =>
      this.request<ResultListDtoUserActivityLogView, any>({
        path: `/users/activity-logs`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Query UserActivityLogView using dynamic search of TableViewRepository
     *
     * @tags user-controller
     * @name PrintXlsxActivityLogsReport
     * @summary print xlsx activity logs report
     * @request GET:/users/activity-logs/print/xlsx
     * @secure
     */
    printXlsxActivityLogsReport: (query: PrintXlsxActivityLogsReportParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/users/activity-logs/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: @userAclService.hasAccessToActivityLogs(#params)
     *
     * @tags user-controller
     * @name MapActionTypes
     * @summary map action types
     * @request GET:/users/activity-logs/action-types
     * @secure
     */
    mapActionTypes: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/users/activity-logs/action-types`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  saleRegisterController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name UpdateRepairEquipment
     * @summary update repair equipment
     * @request PUT:/sale-registers/{id}/status/{status}/repair-equipment
     * @secure
     */
    updateRepairEquipment: (id: number, status: StatusType, data: RepairEquipmentDto, params: RequestParams = {}) =>
      this.request<RepairEquipmentDto, any>({
        path: `/sale-registers/${id}/status/${status}/repair-equipment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name Read1
     * @summary read
     * @request GET:/sale-registers/{id}
     * @secure
     */
    read1: (id: number, params: RequestParams = {}) =>
      this.request<SaleRegisterDto, any>({
        path: `/sale-registers/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name Update1
     * @summary update
     * @request PUT:/sale-registers/{id}
     * @secure
     */
    update1: (id: number, data: SaleRegisterDto, params: RequestParams = {}) =>
      this.request<SaleRegisterDto, any>({
        path: `/sale-registers/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name DownloadFile2
     * @summary download file
     * @request GET:/sale-registers/{saleRegisterId}/documents/{documentTypeCode}/files
     * @secure
     */
    downloadFile2: (saleRegisterId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/sale-registers/${saleRegisterId}/documents/${documentTypeCode}/files`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name SaveFile
     * @summary save file
     * @request POST:/sale-registers/{saleRegisterId}/documents/{documentTypeCode}/files
     * @secure
     */
    saveFile: (
      saleRegisterId: number,
      documentTypeCode: DocumentTypeCode,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/sale-registers/${saleRegisterId}/documents/${documentTypeCode}/files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name RemoveFile4
     * @summary remove file
     * @request DELETE:/sale-registers/{saleRegisterId}/documents/{documentTypeCode}/files
     * @secure
     */
    removeFile4: (saleRegisterId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/sale-registers/${saleRegisterId}/documents/${documentTypeCode}/files`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name SetDispatchAfterSale
     * @summary set dispatch after sale
     * @request POST:/sale-registers/{id}/status/{status}/dispatch-after-sale
     * @secure
     */
    setDispatchAfterSale: (id: number, status: StatusType, data: DispatchAfterSaleDto, params: RequestParams = {}) =>
      this.request<SaleRegisterDto, any>({
        path: `/sale-registers/${id}/status/${status}/dispatch-after-sale`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name MapRepairStatuses
     * @summary map repair statuses
     * @request GET:/sale-registers/repair-statuses
     * @secure
     */
    mapRepairStatuses: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/sale-registers/repair-statuses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query SaleRegisterView using dynamic search of TableViewRepository
     *
     * @tags sale-register-controller
     * @name PrintXlsxSaleRegisterList
     * @summary print xlsx sale register list
     * @request GET:/sale-registers/print/xlsx
     * @secure
     */
    printXlsxSaleRegisterList: (query: PrintXlsxSaleRegisterListParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/sale-registers/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name MapOwnerEquipmentTypes
     * @summary map owner equipment types
     * @request GET:/sale-registers/owner-equipments
     * @secure
     */
    mapOwnerEquipmentTypes: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/sale-registers/owner-equipments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name MapEquipmentSales
     * @summary map equipment sales
     * @request GET:/sale-registers/equipment-sale-statuses
     * @secure
     */
    mapEquipmentSales: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/sale-registers/equipment-sale-statuses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name GetSaleRegisterDocumentConfigurations
     * @summary get sale register document configurations
     * @request GET:/sale-registers/documents/configurations
     * @secure
     */
    getSaleRegisterDocumentConfigurations: (params: RequestParams = {}) =>
      this.request<SaleRegisterDocumentConfigurationDto[], any>({
        path: `/sale-registers/documents/configurations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SALE_REGISTER_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags sale-register-controller
     * @name MapDeliveryTypes
     * @summary map delivery types
     * @request GET:/sale-registers/delivery-types
     * @secure
     */
    mapDeliveryTypes: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/sale-registers/delivery-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query SaleRegisterView using dynamic search of TableViewRepository
     *
     * @tags sale-register-controller
     * @name List1
     * @summary list
     * @request GET:/sale-registers
     * @secure
     */
    list1: (query: List1Params, params: RequestParams = {}) =>
      this.request<ResultListDtoSaleRegisterView, any>({
        path: `/sale-registers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  partnerController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name UpdateRegion
     * @summary update region
     * @request PUT:/partners/{id}/regions/{regionId}
     * @secure
     */
    updateRegion: (id: number, regionId: number, data: PartnerRegionDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${id}/regions/${regionId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name GetProgramParameters
     * @summary get program parameters
     * @request GET:/partners/{id}/program-parameters
     * @secure
     */
    getProgramParameters: (id: number, params: RequestParams = {}) =>
      this.request<PartnerProgramParametersDto, any>({
        path: `/partners/${id}/program-parameters`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name UpdatePartnerPrograms
     * @summary update partner programs
     * @request PUT:/partners/{id}/program-parameters
     * @secure
     */
    updatePartnerPrograms: (id: number, data: PartnerProgramParametersDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${id}/program-parameters`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name AddInsuranceCompany
     * @summary add insurance company
     * @request PUT:/partners/{id}/insurance-company/add/{insuranceCompanyId}
     * @secure
     */
    addInsuranceCompany: (id: number, insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<PartnerDto, any>({
        path: `/partners/${id}/insurance-company/add/${insuranceCompanyId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name Read2
     * @summary read
     * @request GET:/partners/{id}
     * @secure
     */
    read2: (id: number, params: RequestParams = {}) =>
      this.request<PartnerDto, any>({
        path: `/partners/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name Update2
     * @summary update
     * @request PUT:/partners/{id}
     * @secure
     */
    update2: (id: number, data: PartnerDto, params: RequestParams = {}) =>
      this.request<PartnerDto, any>({
        path: `/partners/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags partner-controller
     * @name DownloadFile3
     * @summary download file
     * @request GET:/partners/{partnerId}/{documentTypeCode}/file
     * @secure
     */
    downloadFile3: (partnerId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/partners/${partnerId}/${documentTypeCode}/file`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags partner-controller
     * @name SaveFile1
     * @summary save file
     * @request POST:/partners/{partnerId}/{documentTypeCode}/file
     * @secure
     */
    saveFile1: (
      partnerId: number,
      documentTypeCode: DocumentTypeCode,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/partners/${partnerId}/${documentTypeCode}/file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags partner-controller
     * @name RemoveFile5
     * @summary remove file
     * @request DELETE:/partners/{partnerId}/{documentTypeCode}/file
     * @secure
     */
    removeFile5: (partnerId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${partnerId}/${documentTypeCode}/file`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name GetRegions
     * @summary get regions
     * @request GET:/partners/{id}/regions
     * @secure
     */
    getRegions: (id: number, params: RequestParams = {}) =>
      this.request<PartnerRegionDto[], any>({
        path: `/partners/${id}/regions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name SaveRegions
     * @summary save regions
     * @request POST:/partners/{id}/regions
     * @secure
     */
    saveRegions: (id: number, data: PartnerRegionDto[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${id}/regions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name CreateExternalId
     * @summary create external id
     * @request POST:/partners/{id}/external-id
     * @secure
     */
    createExternalId: ({ id, ...query }: CreateExternalIdParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${id}/external-id`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Query PartnerView using dynamic search of TableViewRepository
     *
     * @tags partner-controller
     * @name List2
     * @summary list
     * @request GET:/partners
     * @secure
     */
    list2: (query: List2Params, params: RequestParams = {}) =>
      this.request<ResultListDtoPartnerView, any>({
        path: `/partners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name Create1
     * @summary create
     * @request POST:/partners
     * @secure
     */
    create1: (data: PartnerDto, params: RequestParams = {}) =>
      this.request<PartnerDto, any>({
        path: `/partners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags partner-controller
     * @name ViewFile
     * @summary view file
     * @request GET:/partners/{partnerId}/{documentTypeCode}/view/file
     * @secure
     */
    viewFile: (partnerId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/partners/${partnerId}/${documentTypeCode}/view/file`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name GetDepartmentPrograms
     * @summary get department programs
     * @request GET:/partners/{id}/department-programs
     * @secure
     */
    getDepartmentPrograms: (id: number, params: RequestParams = {}) =>
      this.request<DepartmentProgramDto[], any>({
        path: `/partners/${id}/department-programs`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags partner-controller
     * @name GetWhiteLabel
     * @summary get white label
     * @request GET:/partners/white-label/{partnerCode}
     * @secure
     */
    getWhiteLabel: (partnerCode: string, params: RequestParams = {}) =>
      this.request<WhiteLabelDto, any>({
        path: `/partners/white-label/${partnerCode}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name GetPartnerTypes
     * @summary get partner types
     * @request GET:/partners/partner-types
     * @secure
     */
    getPartnerTypes: (params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/partners/partner-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name GetPartnerEnumTypes
     * @summary get partner enum types
     * @request GET:/partners/partner-enum-types
     * @secure
     */
    getPartnerEnumTypes: (params: RequestParams = {}) =>
      this.request<PartnerType[], any>({
        path: `/partners/partner-enum-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query PartnerInsuranceCompanyView using dynamic search of TableViewRepository
     *
     * @tags partner-controller
     * @name GetInsuranceCompanies
     * @summary get insurance companies
     * @request GET:/partners/insurance-companies
     * @secure
     */
    getInsuranceCompanies: (query: GetInsuranceCompaniesParams, params: RequestParams = {}) =>
      this.request<ResultListDtoPartnerInsuranceCompanyView, any>({
        path: `/partners/insurance-companies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name DeleteExternalId
     * @summary delete external id
     * @request DELETE:/partners/{id}/department/{departmentProgramId}/external-id
     * @secure
     */
    deleteExternalId: (id: number, departmentProgramId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/${id}/department/${departmentProgramId}/external-id`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-controller
     * @name DeleteRegion
     * @summary delete region
     * @request DELETE:/partners/regions/{regionId}
     * @secure
     */
    deleteRegion: (regionId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/partners/regions/${regionId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  partnerProgramController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-program-controller
     * @name Read3
     * @summary read
     * @request GET:/partner-programs/{id}
     * @secure
     */
    read3: (id: number, params: RequestParams = {}) =>
      this.request<PartnerProgramDto, any>({
        path: `/partner-programs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-program-controller
     * @name Update3
     * @summary update
     * @request PUT:/partner-programs/{id}
     * @secure
     */
    update3: (id: number, data: PartnerProgramDto, params: RequestParams = {}) =>
      this.request<PartnerProgramDto, any>({
        path: `/partner-programs/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags partner-program-controller
     * @name Save
     * @summary save
     * @request POST:/partner-programs
     * @secure
     */
    save: (data: PartnerProgramDto, params: RequestParams = {}) =>
      this.request<PartnerProgramDto, any>({
        path: `/partner-programs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  integrationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags integration-controller
     * @name MigrateInsuranceProgramIntegrationHistories
     * @summary migrate insurance program integration histories
     * @request PUT:/integrations/migrate-integration-histories
     * @secure
     */
    migrateInsuranceProgramIntegrationHistories: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/integrations/migrate-integration-histories`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags integration-controller
     * @name Interrupt
     * @summary interrupt
     * @request PUT:/integrations/interrupt/{transferPackageId}
     * @secure
     */
    interrupt: (transferPackageId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/integrations/interrupt/${transferPackageId}`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags integration-controller
     * @name ImportItems
     * @summary import items
     * @request POST:/integrations/import
     * @secure
     */
    importItems: (data: IntegrationRequestDto, params: RequestParams = {}) =>
      this.request<IntegrationPackageInformationDto, any>({
        path: `/integrations/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags integration-controller
     * @name ExportItems
     * @summary export items
     * @request POST:/integrations/export
     * @secure
     */
    exportItems: (data: IntegrationRequestDto, params: RequestParams = {}) =>
      this.request<IntegrationPackageInformationDto, any>({
        path: `/integrations/export`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags integration-controller
     * @name GetCountTransfer
     * @summary get count transfer
     * @request POST:/integrations/count
     * @secure
     */
    getCountTransfer: (data: IntegrationRequestDto, params: RequestParams = {}) =>
      this.request<IntegrationItemCountDto, any>({
        path: `/integrations/count`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags integration-controller
     * @name VerifyNewContractsAutomatically
     * @summary verify new contracts automatically
     * @request POST:/integrations/auto-verification/{configurationId}
     * @secure
     */
    verifyNewContractsAutomatically: (
      configurationId: number,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<IntegrationPackageInformationDto, any>({
        path: `/integrations/auto-verification/${configurationId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags integration-controller
     * @name GetTransferStatus
     * @summary get transfer status
     * @request GET:/integrations/status/{transferPackageId}
     * @secure
     */
    getTransferStatus: (transferPackageId: number, params: RequestParams = {}) =>
      this.request<IntegrationPackageInformationDto, any>({
        path: `/integrations/status/${transferPackageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query IntegrationHistoryItemView using dynamic search of TableViewRepository
     *
     * @tags integration-controller
     * @name ListContractTransferHistory
     * @summary list contract transfer history
     * @request GET:/integrations/histories
     * @secure
     */
    listContractTransferHistory: (query: ListContractTransferHistoryParams, params: RequestParams = {}) =>
      this.request<ResultListDtoIntegrationHistoryItemView, any>({
        path: `/integrations/histories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query IntegrationItemView using dynamic search of TableViewRepository
     *
     * @tags integration-controller
     * @name List3
     * @summary list
     * @request GET:/integrations
     * @secure
     */
    list3: (query: List3Params, params: RequestParams = {}) =>
      this.request<ResultListDtoIntegrationItemView, any>({
        path: `/integrations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  insuranceObjectController = {
    /**
     * @description Security requirements: hasPermission('BRAND_CATALOG', 'update')
     *
     * @tags insurance-object-controller
     * @name UpdateBrand
     * @summary update brand
     * @request PUT:/insurance-objects/brands/{id}
     * @secure
     */
    updateBrand: (id: number, data: BrandDto, params: RequestParams = {}) =>
      this.request<BrandDto, any>({
        path: `/insurance-objects/brands/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('BRAND_CATALOG', 'delete')
     *
     * @tags insurance-object-controller
     * @name DeleteBrand
     * @summary delete brand
     * @request DELETE:/insurance-objects/brands/{id}
     * @secure
     */
    deleteBrand: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-objects/brands/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-object-controller
     * @name ValidateVehicleVinNumber
     * @summary validate vehicle vin number
     * @request POST:/insurance-objects/vehicles/vin-number/validation
     * @secure
     */
    validateVehicleVinNumber: (data: UniqueNumberValidationRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-objects/vehicles/vin-number/validation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-object-controller
     * @name ValidateVehicleRegistrationNumber
     * @summary validate vehicle registration number
     * @request POST:/insurance-objects/vehicles/registration-number/validation
     * @secure
     */
    validateVehicleRegistrationNumber: (data: UniqueNumberValidationRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-objects/vehicles/registration-number/validation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-object-controller
     * @name ValidateDeviceImei
     * @summary validate device imei
     * @request POST:/insurance-objects/electronic-devices/imei/validation
     * @secure
     */
    validateDeviceImei: (data: UniqueNumberValidationRequestDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-objects/electronic-devices/imei/validation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Query BrandView using dynamic search of TableViewRepository
     *
     * @tags insurance-object-controller
     * @name ListBrands
     * @summary list brands
     * @request GET:/insurance-objects/brands
     * @secure
     */
    listBrands: (query: ListBrandsParams, params: RequestParams = {}) =>
      this.request<ResultListDtoBrandView, any>({
        path: `/insurance-objects/brands`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('BRAND_CATALOG', 'create')
     *
     * @tags insurance-object-controller
     * @name CreateBrand
     * @summary create brand
     * @request POST:/insurance-objects/brands
     * @secure
     */
    createBrand: (data: BrandDto, params: RequestParams = {}) =>
      this.request<BrandDto, any>({
        path: `/insurance-objects/brands`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-object-controller
     * @name ListTypesSubtype
     * @summary list types subtype
     * @request GET:/insurance-objects/types-subtypes
     * @secure
     */
    listTypesSubtype: (query: ListTypesSubtypeParams, params: RequestParams = {}) =>
      this.request<ObjectSubtypeDto[], any>({
        path: `/insurance-objects/types-subtypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-object-controller
     * @name ListSubtypes
     * @summary list subtypes
     * @request GET:/insurance-objects/subtypes
     * @secure
     */
    listSubtypes: (query: ListSubtypesParams, params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/insurance-objects/subtypes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query InsuranceObjectView using dynamic search of TableViewRepository
     *
     * @tags insurance-object-controller
     * @name FindInsuranceObjectResponseDto
     * @summary find insurance object response dto
     * @request GET:/insurance-objects/personal-page
     * @secure
     */
    findInsuranceObjectResponseDto: (query: FindInsuranceObjectResponseDtoParams, params: RequestParams = {}) =>
      this.request<InsuranceObjectResponseDto, any>({
        path: `/insurance-objects/personal-page`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-object-controller
     * @name ListEstateObjectType
     * @summary list estate object type
     * @request GET:/insurance-objects/estate-types
     * @secure
     */
    listEstateObjectType: (params: RequestParams = {}) =>
      this.request<EstateObjectType[], any>({
        path: `/insurance-objects/estate-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query InsuranceObjectView using dynamic search of TableViewRepository
     *
     * @tags insurance-object-controller
     * @name FindInsuranceObjects
     * @summary find insurance objects
     * @request GET:/insurance-objects
     * @secure
     */
    findInsuranceObjects: (query: FindInsuranceObjectsParams, params: RequestParams = {}) =>
      this.request<InsuranceObjectDetailsDto[], any>({
        path: `/insurance-objects`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  insuranceCompanyController = {
    /**
     * @description Security requirements: hasPermission('INSURANCE_COMPANIES', 'read')
     *
     * @tags insurance-company-controller
     * @name Read5
     * @summary read
     * @request GET:/insurance-companies/{id}
     * @secure
     */
    read5: (id: number, params: RequestParams = {}) =>
      this.request<InsuranceCompanyDto, any>({
        path: `/insurance-companies/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('INSURANCE_COMPANIES', 'update')
     *
     * @tags insurance-company-controller
     * @name Update4
     * @summary update
     * @request PUT:/insurance-companies/{id}
     * @secure
     */
    update4: (id: number, data: InsuranceCompanyDto, params: RequestParams = {}) =>
      this.request<InsuranceCompanyDto, any>({
        path: `/insurance-companies/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags insurance-company-controller
     * @name DownloadFile4
     * @summary download file
     * @request GET:/insurance-companies/{insuranceCompanyId}/file
     * @secure
     */
    downloadFile4: (insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/insurance-companies/${insuranceCompanyId}/file`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('INSURANCE_COMPANIES', 'create')
     *
     * @tags insurance-company-controller
     * @name SaveFile2
     * @summary save file
     * @request POST:/insurance-companies/{insuranceCompanyId}/file
     * @secure
     */
    saveFile2: (
      insuranceCompanyId: number,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/insurance-companies/${insuranceCompanyId}/file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('INSURANCE_COMPANIES', 'delete')
     *
     * @tags insurance-company-controller
     * @name RemoveFile6
     * @summary remove file
     * @request DELETE:/insurance-companies/{insuranceCompanyId}/file
     * @secure
     */
    removeFile6: (insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-companies/${insuranceCompanyId}/file`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-company-controller
     * @name ValidateInsuranceCompanyCode
     * @summary validate insurance company code
     * @request POST:/insurance-companies/code/{code}/validation
     * @secure
     */
    validateInsuranceCompanyCode: (code: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-companies/code/${code}/validation`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Query InsuranceCompanyView using dynamic search of TableViewRepository
     *
     * @tags insurance-company-controller
     * @name List4
     * @summary list
     * @request GET:/insurance-companies
     * @secure
     */
    list4: (query: List4Params, params: RequestParams = {}) =>
      this.request<ResultListDtoInsuranceCompanyView, any>({
        path: `/insurance-companies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('INSURANCE_COMPANIES', 'create')
     *
     * @tags insurance-company-controller
     * @name Create2
     * @summary create
     * @request POST:/insurance-companies
     * @secure
     */
    create2: (data: InsuranceCompanyDto, params: RequestParams = {}) =>
      this.request<InsuranceCompanyDto, any>({
        path: `/insurance-companies`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags insurance-company-controller
     * @name ViewFile1
     * @summary view file
     * @request GET:/insurance-companies/{insuranceCompanyId}/view/file
     * @secure
     */
    viewFile1: (insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/insurance-companies/${insuranceCompanyId}/view/file`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags insurance-company-controller
     * @name PaymentSystemList
     * @summary payment system list
     * @request GET:/insurance-companies/{id}/payment-systems
     * @secure
     */
    paymentSystemList: (id: number, params: RequestParams = {}) =>
      this.request<PaymentSystemType[], any>({
        path: `/insurance-companies/${id}/payment-systems`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags insurance-company-controller
     * @name GetWhiteLabel1
     * @summary get white label
     * @request GET:/insurance-companies/white-label/{insuranceCompanyCode}
     * @secure
     */
    getWhiteLabel1: (insuranceCompanyCode: string, params: RequestParams = {}) =>
      this.request<WhiteLabelDto, any>({
        path: `/insurance-companies/white-label/${insuranceCompanyCode}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags insurance-company-controller
     * @name FindInsuranceCompanySettings
     * @summary find insurance company settings
     * @request GET:/insurance-companies/settings/{insuranceCompanyId}
     * @secure
     */
    findInsuranceCompanySettings: (insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<InsuranceCompanySettingsDto, any>({
        path: `/insurance-companies/settings/${insuranceCompanyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance-company-controller
     * @name GetBasicInformation
     * @summary get basic information
     * @request GET:/insurance-companies/basic-info/{id}
     * @secure
     */
    getBasicInformation: (id: number, params: RequestParams = {}) =>
      this.request<InsuranceCompanyBasicInformationDto, any>({
        path: `/insurance-companies/basic-info/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Query InsuranceCompanyView using dynamic search of TableViewRepository
     *
     * @tags insurance-company-controller
     * @name ListAll
     * @summary list all
     * @request GET:/insurance-companies/all
     * @secure
     */
    listAll: (query: ListAllParams, params: RequestParams = {}) =>
      this.request<ResultListDtoInsuranceCompanyView, any>({
        path: `/insurance-companies/all`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  departmentController = {
    /**
     * @description Security requirements: hasPermission('DEPARTMENTS', 'read')
     *
     * @tags department-controller
     * @name Read6
     * @summary read
     * @request GET:/departments/{id}
     * @secure
     */
    read6: (id: number, params: RequestParams = {}) =>
      this.request<DepartmentDto, any>({
        path: `/departments/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('DEPARTMENTS', 'update')
     *
     * @tags department-controller
     * @name Update5
     * @summary update
     * @request PUT:/departments/{id}
     * @secure
     */
    update5: (id: number, data: DepartmentDto, params: RequestParams = {}) =>
      this.request<DepartmentDto, any>({
        path: `/departments/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags department-controller
     * @name SaveAll
     * @summary save all
     * @request POST:/departments/import
     * @secure
     */
    saveAll: (data: DepartmentDto[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/departments/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Query DepartmentView using dynamic search of TableViewRepository
     *
     * @tags department-controller
     * @name List5
     * @summary list
     * @request GET:/departments
     * @secure
     */
    list5: (query: List5Params, params: RequestParams = {}) =>
      this.request<ResultListDtoDepartmentView, any>({
        path: `/departments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasPermission('DEPARTMENTS', 'create')
     *
     * @tags department-controller
     * @name Create3
     * @summary create
     * @request POST:/departments
     * @secure
     */
    create3: (data: DepartmentDto, params: RequestParams = {}) =>
      this.request<DepartmentDto, any>({
        path: `/departments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query DepartmentView using dynamic search of TableViewRepository
     *
     * @tags department-controller
     * @name PrintXlsxContractList
     * @summary print xlsx contract list
     * @request GET:/departments/print/xlsx
     * @secure
     */
    printXlsxContractList: (query: PrintXlsxContractListParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/departments/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags department-controller
     * @name ListAvailableDepartmentsByPartner
     * @summary list available departments by partner
     * @request GET:/departments/available/internet-sale-creation
     * @secure
     */
    listAvailableDepartmentsByPartner: (query: ListAvailableDepartmentsByPartnerParams, params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/departments/available/internet-sale-creation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  deductionController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags deduction-controller
     * @name Read7
     * @summary read
     * @request GET:/deductions/{id}
     * @secure
     */
    read7: (id: number, params: RequestParams = {}) =>
      this.request<DeductionDto, any>({
        path: `/deductions/${id}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags deduction-controller
     * @name Update6
     * @summary update
     * @request PUT:/deductions/{id}
     * @secure
     */
    update6: (id: number, data: DeductionDto, params: RequestParams = {}) =>
      this.request<DeductionDto, any>({
        path: `/deductions/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags deduction-controller
     * @name Delete
     * @summary delete
     * @request DELETE:/deductions/{id}
     * @secure
     */
    delete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/deductions/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags deduction-controller
     * @name Create4
     * @summary create
     * @request POST:/deductions/create
     * @secure
     */
    create4: (query: Create4Params, data: DeductionDto, params: RequestParams = {}) =>
      this.request<DeductionDto, any>({
        path: `/deductions/create`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags deduction-controller
     * @name GetDeductionList
     * @summary get deduction list
     * @request GET:/deductions
     * @secure
     */
    getDeductionList: (query: GetDeductionListParams, params: RequestParams = {}) =>
      this.request<DeductionDto[], any>({
        path: `/deductions`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  contractController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER','ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER', 'ROLE_PARTNER_ASSISTANCE') and @contractAclService.hasAccess(#id)
     *
     * @tags contract-controller
     * @name GetDetails
     * @summary get details
     * @request GET:/contracts/{id}
     * @secure
     */
    getDetails: (id: number, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/contracts/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-controller
     * @name Update7
     * @summary update
     * @request PUT:/contracts/{id}
     * @secure
     */
    update7: (id: number, data: ContractDto, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/contracts/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name RenameFile
     * @summary rename file
     * @request PUT:/contracts/{contractId}/documents/{documentTypeCode}/files/{fileKey}/name/{fileName}
     * @secure
     */
    renameFile: (
      { contractId, documentTypeCode, fileKey, fileName, ...query }: RenameFileParams,
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/contracts/${contractId}/documents/${documentTypeCode}/files/${fileKey}/name/${fileName}`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name WithdrawVerify
     * @summary withdraw verify
     * @request POST:/contracts/{id}/withdraw-verify
     * @secure
     */
    withdrawVerify: (id: number, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/contracts/${id}/withdraw-verify`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name WithdrawSign
     * @summary withdraw sign
     * @request POST:/contracts/{id}/withdraw-sign
     * @secure
     */
    withdrawSign: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/${id}/withdraw-sign`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-controller
     * @name VerifyContract
     * @summary verify contract
     * @request POST:/contracts/{id}/verify
     * @secure
     */
    verifyContract: (id: number, data: ContractDto, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/contracts/${id}/verify`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-controller
     * @name SignContract
     * @summary sign contract
     * @request POST:/contracts/{id}/sign
     * @secure
     */
    signContract: ({ id, ...query }: SignContractParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/${id}/sign`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-controller
     * @name SendSignVerification
     * @summary send sign verification
     * @request POST:/contracts/{id}/sign-verification
     * @secure
     */
    sendSignVerification: ({ id, ...query }: SendSignVerificationParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/${id}/sign-verification`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-controller
     * @name SendContractReport
     * @summary send contract report
     * @request POST:/contracts/{id}/send-report
     * @secure
     */
    sendContractReport: ({ id, ...query }: SendContractReportParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/${id}/send-report`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name SendOffer
     * @summary send offer
     * @request POST:/contracts/{id}/send-offer
     * @secure
     */
    sendOffer: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/${id}/send-offer`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN')
     *
     * @tags contract-controller
     * @name SendClientOnSign
     * @summary send client on sign
     * @request POST:/contracts/{id}/send-client-on-sign
     * @secure
     */
    sendClientOnSign: ({ id, ...query }: SendClientOnSignParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/${id}/send-client-on-sign`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT')
     *
     * @tags contract-controller
     * @name UploadFile
     * @summary upload file
     * @request POST:/contracts/{contractId}/documents/{documentTypeCode}/files
     * @secure
     */
    uploadFile: ({ contractId, documentTypeCode, ...query }: UploadFileParams, params: RequestParams = {}) =>
      this.request<FileStorageDto, any>({
        path: `/contracts/${contractId}/documents/${documentTypeCode}/files`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name RemoveFile
     * @summary remove file
     * @request DELETE:/contracts/{contractId}/documents/{documentTypeCode}/files
     * @secure
     */
    removeFile: (contractId: number, documentTypeCode: DocumentTypeCode, fileKey: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/${contractId}/documents/${documentTypeCode}/files`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name UpdateStatusDelete
     * @summary update status delete
     * @request POST:/contracts/update-status-delete
     * @secure
     */
    updateStatusDelete: (query: UpdateStatusDeleteParams, data: number[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/update-status-delete`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name ClientSign
     * @summary client sign
     * @request POST:/contracts/external/{externalId}/client-sign
     * @secure
     */
    clientSign: ({ externalId, ...query }: ClientSignParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contracts/external/${externalId}/client-sign`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name SendClientSignVerification
     * @summary send client sign verification
     * @request POST:/contracts/external/{externalId}/client-sign-verification
     * @secure
     */
    sendClientSignVerification: (
      { externalId, ...query }: SendClientSignVerificationParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/contracts/external/${externalId}/client-sign-verification`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_INSURANCE_MANAGER')
     *
     * @tags contract-controller
     * @name GetContractNumberEnding
     * @summary get contract number ending
     * @request POST:/contracts/configurations
     * @secure
     */
    getContractNumberEnding: (data: ConfigurationRequestDto, params: RequestParams = {}) =>
      this.request<ContractConfigurationDto, any>({
        path: `/contracts/configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-controller
     * @name ContractCalculations
     * @summary contract calculations
     * @request POST:/contracts/calculations
     * @secure
     */
    contractCalculations: (data: ContractCalculationRequestDto, params: RequestParams = {}) =>
      this.request<ContractCalculationResultDto, any>({
        path: `/contracts/calculations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query ContractView using dynamic search of TableViewRepository
     *
     * @tags contract-controller
     * @name List7
     * @summary list
     * @request GET:/contracts
     * @secure
     */
    list7: (query: List7Params, params: RequestParams = {}) =>
      this.request<ResultListDtoContractView, any>({
        path: `/contracts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_INSURANCE_MANAGER')
     *
     * @tags contract-controller
     * @name Create5
     * @summary create
     * @request POST:/contracts
     * @secure
     */
    create5: (data: ContractDto, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/contracts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name GetWithdrawVerifyWarnings
     * @summary get withdraw verify warnings
     * @request GET:/contracts/{id}/withdraw-verify/warnings
     * @secure
     */
    getWithdrawVerifyWarnings: (id: number, params: RequestParams = {}) =>
      this.request<WithdrawVerifyWarningsDto, any>({
        path: `/contracts/${id}/withdraw-verify/warnings`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT') and @contractAclService.hasAccess(#id)
     *
     * @tags contract-controller
     * @name GetTerminationDetails
     * @summary get termination details
     * @request GET:/contracts/{id}/termination-details
     * @secure
     */
    getTerminationDetails: (id: number, params: RequestParams = {}) =>
      this.request<ContractTerminationDetailsDto, any>({
        path: `/contracts/${id}/termination-details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-controller
     * @name GetRiskResolutionTypes
     * @summary get risk resolution types
     * @request GET:/contracts/{id}/risks/{riskId}/resolution-types
     * @secure
     */
    getRiskResolutionTypes: (id: number, riskId: number, params: RequestParams = {}) =>
      this.request<Record<string, string>, any>({
        path: `/contracts/${id}/risks/${riskId}/resolution-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER', 'ROLE_PARTNER_ASSISTANCE') and @contractAclService.hasAccess(#id)
     *
     * @tags contract-controller
     * @name GetContractRisks
     * @summary get contract risks
     * @request GET:/contracts/{id}/risks
     * @secure
     */
    getContractRisks: (id: number, params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/contracts/${id}/risks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE') and @contractAclService.hasAccess(#id)
     *
     * @tags contract-controller
     * @name PrintPdfReport1
     * @summary print pdf report
     * @request GET:/contracts/{id}/pdf-report
     * @secure
     */
    printPdfReport1: ({ id, ...query }: PrintPdfReport1Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contracts/${id}/pdf-report`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT') and @contractAclService.hasAccess(#id)
     *
     * @tags contract-controller
     * @name PrintInvoiceReport
     * @summary print invoice report
     * @request GET:/contracts/{id}/invoice-report
     * @secure
     */
    printInvoiceReport: ({ id, ...query }: PrintInvoiceReportParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contracts/${id}/invoice-report`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT') and @contractAclService.hasAccess(#id)
     *
     * @tags contract-controller
     * @name GetIntegrationHistory
     * @summary get integration history
     * @request GET:/contracts/{id}/integration-histories
     * @secure
     */
    getIntegrationHistory: (id: number, params: RequestParams = {}) =>
      this.request<ContractIntegrationHistoryDto[], any>({
        path: `/contracts/${id}/integration-histories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-controller
     * @name GetInsuranceObjects
     * @summary get insurance objects
     * @request GET:/contracts/{id}/insurance-objects
     * @secure
     */
    getInsuranceObjects: (id: number, params: RequestParams = {}) =>
      this.request<
        (
          | ElectronicDeviceInsuranceObjectDto
          | EstateInsuranceObjectDto
          | PersonInsuranceObjectDto
          | VehicleInsuranceObjectDto
        )[],
        any
      >({
        path: `/contracts/${id}/insurance-objects`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER','ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE') and @contractAclService.hasAccess(#id)
     *
     * @tags contract-controller
     * @name GetClaimDetails
     * @summary get claim details
     * @request GET:/contracts/{id}/claim-details
     * @secure
     */
    getClaimDetails: (id: number, params: RequestParams = {}) =>
      this.request<ContractClaimDetailsDto[], any>({
        path: `/contracts/${id}/claim-details`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE','ROLE_CLIENT')
     *
     * @tags contract-controller
     * @name GetProgramParameters1
     * @summary get program parameters
     * @request GET:/contracts/{contractId}/program-parameters
     * @secure
     */
    getProgramParameters1: (contractId: number, params: RequestParams = {}) =>
      this.request<PartnerProgramParametersDto, any>({
        path: `/contracts/${contractId}/program-parameters`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name GetOfferUrlByContractId
     * @summary get offer url by contract id
     * @request GET:/contracts/{contractId}/offer-url
     * @secure
     */
    getOfferUrlByContractId: (contractId: number, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/contracts/${contractId}/offer-url`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name DownloadDocumentFileZip2
     * @summary download document file zip
     * @request GET:/contracts/{contractId}/documents/{documentTypeCode}/zip
     * @secure
     */
    downloadDocumentFileZip2: (contractId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contracts/${contractId}/documents/${documentTypeCode}/zip`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name DownloadFile5
     * @summary download file
     * @request GET:/contracts/{contractId}/documents/{documentTypeCode}/files/{fileKey}
     * @secure
     */
    downloadFile5: (
      contractId: number,
      documentTypeCode: DocumentTypeCode,
      fileKey: string,
      params: RequestParams = {},
    ) =>
      this.request<string[], any>({
        path: `/contracts/${contractId}/documents/${documentTypeCode}/files/${fileKey}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name RemoveFile1
     * @summary remove file
     * @request DELETE:/contracts/{contractId}/documents/{documentTypeCode}/files/{fileKey}
     * @secure
     */
    removeFile1: (
      contractId: number,
      documentTypeCode: DocumentTypeCode,
      fileKey: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/contracts/${contractId}/documents/${documentTypeCode}/files/${fileKey}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_MANAGER')
     *
     * @tags contract-controller
     * @name DownloadDocumentDescriptionFiles
     * @summary download document description files
     * @request GET:/contracts/{contractId}/document-description/{documentTypeCode}/files
     * @secure
     */
    downloadDocumentDescriptionFiles: (
      { contractId, documentTypeCode, ...query }: DownloadDocumentDescriptionFilesParams,
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/contracts/${contractId}/document-description/${documentTypeCode}/files`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name UpdateContractAgentsAndContractObjectRisk
     * @summary update contract agents and contract object risk
     * @request GET:/contracts/update-agents-and-object-risks
     * @secure
     */
    updateContractAgentsAndContractObjectRisk: (
      query: UpdateContractAgentsAndContractObjectRiskParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/contracts/update-agents-and-object-risks`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Query ContractView using dynamic search of TableViewRepository
     *
     * @tags contract-controller
     * @name ListSubAgreements
     * @summary list sub agreements
     * @request GET:/contracts/sub-agreements
     * @secure
     */
    listSubAgreements: (query: ListSubAgreementsParams, params: RequestParams = {}) =>
      this.request<ResultListDtoContractView, any>({
        path: `/contracts/sub-agreements`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query ContractView using dynamic search of TableViewRepository
     *
     * @tags contract-controller
     * @name PrintXlsxContractList1
     * @summary print xlsx contract list
     * @request GET:/contracts/print/xlsx
     * @secure
     */
    printXlsxContractList1: (query: PrintXlsxContractList1Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contracts/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM')
     *
     * @tags contract-controller
     * @name GetByContractNumber
     * @summary get by contract number
     * @request GET:/contracts/online-contracts/{contractNumber}
     * @secure
     */
    getByContractNumber: (contractNumber: string, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/contracts/online-contracts/${contractNumber}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-controller
     * @name GetMajorContracts
     * @summary get major contracts
     * @request GET:/contracts/majorcontracts
     * @secure
     */
    getMajorContracts: (params: RequestParams = {}) =>
      this.request<MajorContractDto[], any>({
        path: `/contracts/majorcontracts`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_ADMIN','ROLE_INSURANCE_ADMIN', 'ROLE_INSURANCE_MANAGER')
     *
     * @tags contract-controller
     * @name GenerateContractNumber
     * @summary generate contract number
     * @request GET:/contracts/generate-contract-number
     * @secure
     */
    generateContractNumber: (query: GenerateContractNumberParams, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/contracts/generate-contract-number`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name PrintPdfReportExternal
     * @summary print pdf report external
     * @request GET:/contracts/external/{externalId}/pdf-report
     * @secure
     */
    printPdfReportExternal: ({ externalId, ...query }: PrintPdfReportExternalParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contracts/external/${externalId}/pdf-report`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name GetOfferUrl
     * @summary get offer url
     * @request GET:/contracts/external/{externalId}/offer-url
     * @secure
     */
    getOfferUrl: (externalId: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/contracts/external/${externalId}/offer-url`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name ExternalRead
     * @summary external read
     * @request GET:/contracts/external/{externalId}
     * @secure
     */
    externalRead: (externalId: string, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/contracts/external/${externalId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-controller
     * @name GetOfferUrl1
     * @summary get offer url
     * @request GET:/contracts/external/offer-url
     * @secure
     */
    getOfferUrl1: (query: GetOfferUrl1Params, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/contracts/external/offer-url`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER','ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-controller
     * @name ListOptionInfoDto
     * @summary list option info dto
     * @request GET:/contracts/duplications/imeiInfo
     * @secure
     */
    listOptionInfoDto: (query: ListOptionInfoDtoParams, params: RequestParams = {}) =>
      this.request<ContractClaimImeiDuplicationDto, any>({
        path: `/contracts/duplications/imeiInfo`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query ContractImeiDuplicationsView using dynamic search of TableViewRepository
     *
     * @tags contract-controller
     * @name ListContractImeiDuplications
     * @summary list contract imei duplications
     * @request GET:/contracts/duplications/imei
     * @secure
     */
    listContractImeiDuplications: (query: ListContractImeiDuplicationsParams, params: RequestParams = {}) =>
      this.request<ResultListDtoContractImeiDuplicationsView, any>({
        path: `/contracts/duplications/imei`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT')
     *
     * @tags contract-controller
     * @name GetContractDocumentConfigurations
     * @summary get contract document configurations
     * @request GET:/contracts/documents/configurations
     * @secure
     */
    getContractDocumentConfigurations: (query: GetContractDocumentConfigurationsParams, params: RequestParams = {}) =>
      this.request<ContractDocumentConfigurationDto[], any>({
        path: `/contracts/documents/configurations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER') and @contractAclService.hasAccess(#contractId)
     *
     * @tags contract-controller
     * @name GetCurrentLiability
     * @summary get current liability
     * @request GET:/contracts/current-liability/{contractId}
     * @secure
     */
    getCurrentLiability: ({ contractId, ...query }: GetCurrentLiabilityParams, params: RequestParams = {}) =>
      this.request<ContractCurrentLiabilityDto, any>({
        path: `/contracts/current-liability/${contractId}`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  contractTerminationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN','ROLE_CLIENT', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name Read8
     * @summary read
     * @request GET:/contract-terminations/{id}
     * @secure
     */
    read8: (id: number, params: RequestParams = {}) =>
      this.request<ContractTerminationDto, any>({
        path: `/contract-terminations/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name Update8
     * @summary update
     * @request PUT:/contract-terminations/{id}
     * @secure
     */
    update8: (id: number, data: ContractTerminationDto, params: RequestParams = {}) =>
      this.request<ContractTerminationDto, any>({
        path: `/contract-terminations/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name DownloadFile6
     * @summary download file
     * @request GET:/contract-terminations/{terminationId}/documents/{documentTypeCode}/files
     * @secure
     */
    downloadFile6: (terminationId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contract-terminations/${terminationId}/documents/${documentTypeCode}/files`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name SaveFile3
     * @summary save file
     * @request POST:/contract-terminations/{terminationId}/documents/{documentTypeCode}/files
     * @secure
     */
    saveFile3: (
      terminationId: number,
      documentTypeCode: DocumentTypeCode,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/contract-terminations/${terminationId}/documents/${documentTypeCode}/files`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name RemoveFile7
     * @summary remove file
     * @request DELETE:/contract-terminations/{terminationId}/documents/{documentTypeCode}/files
     * @secure
     */
    removeFile7: (terminationId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contract-terminations/${terminationId}/documents/${documentTypeCode}/files`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name SignContractTermination
     * @summary sign contract termination
     * @request POST:/contract-terminations/{id}/sign
     * @secure
     */
    signContractTermination: ({ id, ...query }: SignContractTerminationParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contract-terminations/${id}/sign`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name SendSignVerification1
     * @summary send sign verification
     * @request POST:/contract-terminations/{id}/sign-verification
     * @secure
     */
    sendSignVerification1: (
      { id, ...query }: SendSignVerification1Params,
      data: ContractTerminationDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/contract-terminations/${id}/sign-verification`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-termination-controller
     * @name RemoveSignature
     * @summary remove signature
     * @request POST:/contract-terminations/{id}/remove-signature
     * @secure
     */
    removeSignature: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contract-terminations/${id}/remove-signature`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name ConfirmPayout
     * @summary confirm payout
     * @request POST:/contract-terminations/confirm-payout/{terminationId}
     * @secure
     */
    confirmPayout: (terminationId: number, data: PayoutTerminationRecipientDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contract-terminations/confirm-payout/${terminationId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Query ContractTerminationView using dynamic search of TableViewRepository
     *
     * @tags contract-termination-controller
     * @name List8
     * @summary list
     * @request GET:/contract-terminations
     * @secure
     */
    list8: (query: List8Params, params: RequestParams = {}) =>
      this.request<ResultListDtoContractTerminationView, any>({
        path: `/contract-terminations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name Create6
     * @summary create
     * @request POST:/contract-terminations
     * @secure
     */
    create6: (data: ContractTerminationDto, params: RequestParams = {}) =>
      this.request<ContractTerminationDto, any>({
        path: `/contract-terminations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name GetContactTerminationPdf
     * @summary get contact termination pdf
     * @request GET:/contract-terminations/{id}/pdf-report
     * @secure
     */
    getContactTerminationPdf: ({ id, ...query }: GetContactTerminationPdfParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contract-terminations/${id}/pdf-report`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name GetTemplate
     * @summary get template
     * @request GET:/contract-terminations/templates
     * @secure
     */
    getTemplate: (query: GetTemplateParams, params: RequestParams = {}) =>
      this.request<ContractTerminationDto, any>({
        path: `/contract-terminations/templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name PrintXlsxContractTerminationList
     * @summary print xlsx contract termination list
     * @request GET:/contract-terminations/print/xlsx
     * @secure
     */
    printXlsxContractTerminationList: (query: PrintXlsxContractTerminationListParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/contract-terminations/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN','ROLE_CLIENT', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name GetInitiators
     * @summary get initiators
     * @request GET:/contract-terminations/initiators
     * @secure
     */
    getInitiators: (query: GetInitiatorsParams, params: RequestParams = {}) =>
      this.request<TerminationInitiatorDto[], any>({
        path: `/contract-terminations/initiators`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name IsDocumentsReceived
     * @summary is documents received
     * @request GET:/contract-terminations/documents/received/{terminationId}
     * @secure
     */
    isDocumentsReceived: (terminationId: number, params: RequestParams = {}) =>
      this.request<ReceivedDocumentDto[], any>({
        path: `/contract-terminations/documents/received/${terminationId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name GetTerminationDocumentConfigurations
     * @summary get termination document configurations
     * @request GET:/contract-terminations/documents/configurations
     * @secure
     */
    getTerminationDocumentConfigurations: (
      query: GetTerminationDocumentConfigurationsParams,
      params: RequestParams = {},
    ) =>
      this.request<TerminationDocumentConfigurationWithSumDto, any>({
        path: `/contract-terminations/documents/configurations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name SendDiaDeeplinkToShareDocuments
     * @summary send dia deeplink to share documents
     * @request GET:/contract-terminations/dia/share/{terminationId}
     * @secure
     */
    sendDiaDeeplinkToShareDocuments: (terminationId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contract-terminations/dia/share/${terminationId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER')
     *
     * @tags contract-termination-controller
     * @name GetTerminationCauses
     * @summary get termination causes
     * @request GET:/contract-terminations/causes
     * @secure
     */
    getTerminationCauses: (query: GetTerminationCausesParams, params: RequestParams = {}) =>
      this.request<TerminationCauseDto[], any>({
        path: `/contract-terminations/causes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-termination-controller
     * @name GetAllowedAccountTypes
     * @summary get allowed account types
     * @request GET:/contract-terminations/account-types/{terminationInitiatorCode}
     * @secure
     */
    getAllowedAccountTypes: (terminationInitiatorCode: TerminationInitiatorCode, params: RequestParams = {}) =>
      this.request<AccountTypeCode[], any>({
        path: `/contract-terminations/account-types/${terminationInitiatorCode}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  contractClaimController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name UpdateClaimStatus
     * @summary update claim status
     * @request PUT:/claims/{id}/update-status
     * @secure
     */
    updateClaimStatus: ({ id, ...query }: UpdateClaimStatusParams, params: RequestParams = {}) =>
      this.request<ContractClaimDto, any>({
        path: `/claims/${id}/update-status`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER', 'ROLE_PARTNER_ASSISTANCE') and @claimAclService.hasAccess(#id)
     *
     * @tags contract-claim-controller
     * @name Read9
     * @summary read
     * @request GET:/claims/{id}
     * @secure
     */
    read9: (id: number, params: RequestParams = {}) =>
      this.request<ContractClaimDto, any>({
        path: `/claims/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER') and @claimAclService.hasAccess(#id)
     *
     * @tags contract-claim-controller
     * @name Update9
     * @summary update
     * @request PUT:/claims/{id}
     * @secure
     */
    update9: (id: number, data: ContractClaimDto, params: RequestParams = {}) =>
      this.request<ContractClaimDto, any>({
        path: `/claims/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name RenameFile1
     * @summary rename file
     * @request PUT:/claims/{claimId}/documents/{documentTypeCode}/files/{fileKey}/name/{fileName}
     * @secure
     */
    renameFile1: (
      { claimId, documentTypeCode, fileKey, fileName, ...query }: RenameFile1Params,
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/claims/${claimId}/documents/${documentTypeCode}/files/${fileKey}/name/${fileName}`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT') and @claimAclService.hasAccess(#id)
     *
     * @tags contract-claim-controller
     * @name SignContractClaim
     * @summary sign contract claim
     * @request POST:/claims/{id}/sign
     * @secure
     */
    signContractClaim: ({ id, ...query }: SignContractClaimParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/sign`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT') and @claimAclService.hasAccess(#id)
     *
     * @tags contract-claim-controller
     * @name SendSignVerification2
     * @summary send sign verification
     * @request POST:/claims/{id}/sign-verification
     * @secure
     */
    sendSignVerification2: ({ id, ...query }: SendSignVerification2Params, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/sign-verification`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name RestoreContractClaim
     * @summary restore contract claim
     * @request POST:/claims/{id}/restore
     * @secure
     */
    restoreContractClaim: (id: number, params: RequestParams = {}) =>
      this.request<ContractClaimDto, any>({
        path: `/claims/${id}/restore`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name PayClaim
     * @summary pay claim
     * @request POST:/claims/{id}/pay
     * @deprecated
     * @secure
     */
    payClaim: (id: number, data: PaymentSystemTypeDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/pay`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name SaveExpressPayment
     * @summary save express payment
     * @request POST:/claims/{id}/express-payments
     * @secure
     */
    saveExpressPayment: (id: number, data: ExpressPaymentDto, params: RequestParams = {}) =>
      this.request<ValidationErrorDto[], any>({
        path: `/claims/${id}/express-payments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name DeferExpressPayment
     * @summary defer express payment
     * @request POST:/claims/{id}/express-payments/defer
     * @secure
     */
    deferExpressPayment: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/express-payments/defer`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name NotifyClientAboutDocuments
     * @summary notify client about documents
     * @request POST:/claims/{id}/documents/notify-client
     * @secure
     */
    notifyClientAboutDocuments: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/documents/notify-client`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name UpdateDocumentList
     * @summary update document list
     * @request POST:/claims/{id}/documents
     * @secure
     */
    updateDocumentList: (id: number, data: ContractClaimDocumentDto[], params: RequestParams = {}) =>
      this.request<ContractClaimDocumentDto[], any>({
        path: `/claims/${id}/documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name ConfirmPayment
     * @summary confirm payment
     * @request POST:/claims/{id}/confirm-payment
     * @secure
     */
    confirmPayment: ({ id, ...query }: ConfirmPaymentParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/confirm-payment`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_SYSTEM_PARTNER')
     *
     * @tags contract-claim-controller
     * @name AnnulContractClaim
     * @summary annul contract claim
     * @request POST:/claims/{id}/annul
     * @secure
     */
    annulContractClaim: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/annul`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name DownloadFile
     * @summary download file
     * @request GET:/claims/{claimId}/documents/{documentTypeCode}/files
     * @secure
     */
    downloadFile: (claimId: number, documentTypeCode: DocumentTypeCode, fileKey: string, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/claims/${claimId}/documents/${documentTypeCode}/files`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT') and @claimAclService.hasAccess(#claimId)
     *
     * @tags contract-claim-controller
     * @name UploadFile1
     * @summary upload file
     * @request POST:/claims/{claimId}/documents/{documentTypeCode}/files
     * @secure
     */
    uploadFile1: ({ claimId, documentTypeCode, ...query }: UploadFile1Params, params: RequestParams = {}) =>
      this.request<FileStorageDto, any>({
        path: `/claims/${claimId}/documents/${documentTypeCode}/files`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name RemoveFile2
     * @summary remove file
     * @request DELETE:/claims/{claimId}/documents/{documentTypeCode}/files
     * @secure
     */
    removeFile2: (claimId: number, documentTypeCode: DocumentTypeCode, fileKey: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${claimId}/documents/${documentTypeCode}/files`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT') and @claimAclService.hasAccess(#claimId)
     *
     * @tags contract-claim-controller
     * @name FinishUploadFiles
     * @summary finish upload files
     * @request POST:/claims/{claimId}/documents/finish-upload-files
     * @secure
     */
    finishUploadFiles: (claimId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${claimId}/documents/finish-upload-files`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT')
     *
     * @tags contract-claim-controller
     * @name SendEmailAttachDocuments
     * @summary send email attach documents
     * @request POST:/claims/email-notification/documents
     * @secure
     */
    sendEmailAttachDocuments: (data: EmailContractClaimDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/email-notification/documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name ConfirmClaimPayment
     * @summary confirm claim payment
     * @request POST:/claims/confirm-payment
     * @secure
     */
    confirmClaimPayment: (data: ClaimManualPaymentDto, params: RequestParams = {}) =>
      this.request<ClaimStatus, any>({
        path: `/claims/confirm-payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name CalculateClaimSum
     * @summary calculate claim sum
     * @request POST:/claims/claim-sum
     * @secure
     */
    calculateClaimSum: (data: ClaimSumCalculationRequestDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/claims/claim-sum`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER')
     *
     * @tags contract-claim-controller
     * @name Create7
     * @summary create
     * @request POST:/claims/
     * @secure
     */
    create7: (data: ContractClaimDto, params: RequestParams = {}) =>
      this.request<ContractClaimDto, any>({
        path: `/claims/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name DownloadDocumentFileZip
     * @summary download document file zip
     * @request GET:/claims/{claimId}/documents/{documentTypeCode}/zip
     * @secure
     */
    downloadDocumentFileZip: (claimId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/claims/${claimId}/documents/${documentTypeCode}/zip`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name DownloadDocumentFileZip1
     * @summary download document file zip
     * @request GET:/claims/{claimId}/documents/zip
     * @secure
     */
    downloadDocumentFileZip1: (claimId: number, documentTypeCode: DocumentTypeCode, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/claims/${claimId}/documents/zip`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name DownloadFile1
     * @summary download file
     * @request GET:/claims/{claimId}/documents/{documentTypeCode}/files/{fileKey}
     * @secure
     */
    downloadFile1: (claimId: number, documentTypeCode: DocumentTypeCode, fileKey: string, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/claims/${claimId}/documents/${documentTypeCode}/files/${fileKey}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name RemoveFile3
     * @summary remove file
     * @request DELETE:/claims/{claimId}/documents/{documentTypeCode}/files/{fileKey}
     * @secure
     */
    removeFile3: (claimId: number, documentTypeCode: DocumentTypeCode, fileKey: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${claimId}/documents/${documentTypeCode}/files/${fileKey}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE', 'ROLE_PARTNER_ADMIN') and @claimAclService.hasAccess(#id)
     *
     * @tags contract-claim-controller
     * @name GetClaimStatus
     * @summary get claim status
     * @request GET:/claims/{id}/status
     * @secure
     */
    getClaimStatus: (id: number, params: RequestParams = {}) =>
      this.request<ClaimStatus, any>({
        path: `/claims/${id}/status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE') and @claimAclService.hasAccess(#id)
     *
     * @tags contract-claim-controller
     * @name GetInsuranceClaimPdf
     * @summary get insurance claim pdf
     * @request GET:/claims/{id}/pdf-report/insurance-claim
     * @secure
     */
    getInsuranceClaimPdf: ({ id, ...query }: GetInsuranceClaimPdfParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/claims/${id}/pdf-report/insurance-claim`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name GetInsuranceActPdf
     * @summary get insurance act pdf
     * @request GET:/claims/{id}/pdf-report/insurance-act
     * @secure
     */
    getInsuranceActPdf: ({ id, ...query }: GetInsuranceActPdfParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/claims/${id}/pdf-report/insurance-act`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE','ROLE_CLIENT', 'ROLE_PARTNER_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN')
     *
     * @tags contract-claim-controller
     * @name GetPaymentCalculations
     * @summary get payment calculations
     * @request GET:/claims/{id}/payment-calculations
     * @secure
     */
    getPaymentCalculations: (id: number, params: RequestParams = {}) =>
      this.request<PaymentCalculationsDto, any>({
        path: `/claims/${id}/payment-calculations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name GetExpressPaymentTemplate
     * @summary get express payment template
     * @request GET:/claims/{id}/express-payments/templates
     * @secure
     */
    getExpressPaymentTemplate: (id: number, params: RequestParams = {}) =>
      this.request<ExpressPaymentDto, any>({
        path: `/claims/${id}/express-payments/templates`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name RejectExpressPayment
     * @summary reject express payment
     * @request GET:/claims/{id}/express-payments/reject
     * @secure
     */
    rejectExpressPayment: ({ id, ...query }: RejectExpressPaymentParams, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/claims/${id}/express-payments/reject`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name GetCountdownTimer
     * @summary get countdown timer
     * @request GET:/claims/{claimId}/express-payments/countdown-timer
     * @secure
     */
    getCountdownTimer: (claimId: number, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/claims/${claimId}/express-payments/countdown-timer`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE', 'ROLE_CLIENT')
     *
     * @tags contract-claim-controller
     * @name DownloadDocumentDescriptionFiles1
     * @summary download document description files
     * @request GET:/claims/{claimId}/document-description/{documentTypeCode}/files
     * @secure
     */
    downloadDocumentDescriptionFiles1: (
      { claimId, documentTypeCode, ...query }: DownloadDocumentDescriptionFiles1Params,
      params: RequestParams = {},
    ) =>
      this.request<FileStorageDto, any>({
        path: `/claims/${claimId}/document-description/${documentTypeCode}/files`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER', 'ROLE_PARTNER_ASSISTANCE') and @contractAclService.hasAccess(#contractId)
     *
     * @tags contract-claim-controller
     * @name GetTemplate1
     * @summary get template
     * @request GET:/claims/templates
     * @secure
     */
    getTemplate1: (query: GetTemplate1Params, params: RequestParams = {}) =>
      this.request<ContractClaimDto, any>({
        path: `/claims/templates`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ADMIN', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name PrintXlsxNotification
     * @summary print xlsx notification
     * @request GET:/claims/print/xlsx
     * @secure
     */
    printXlsxNotification: (query: PrintXlsxNotificationParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/claims/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_SYSTEM_PARTNER')
     *
     * @tags contract-claim-controller
     * @name FindClaimDocumentConfigurations
     * @summary find claim document configurations
     * @request GET:/claims/documents/configurations
     * @secure
     */
    findClaimDocumentConfigurations: (query: FindClaimDocumentConfigurationsParams, params: RequestParams = {}) =>
      this.request<ClaimDocumentConfigurationDto[], any>({
        path: `/claims/documents/configurations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name DocumentList
     * @summary document list
     * @request GET:/claims/documents
     * @secure
     */
    documentList: (params: RequestParams = {}) =>
      this.request<ClaimDocumentConfigurationDto[], any>({
        path: `/claims/documents`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query ContractClaimDocumentView using dynamic search of TableViewRepository
     *
     * @tags contract-claim-controller
     * @name GetDocumentConfirmations
     * @summary get document confirmations
     * @request GET:/claims/document-confirmations
     * @secure
     */
    getDocumentConfirmations: (query: GetDocumentConfirmationsParams, params: RequestParams = {}) =>
      this.request<ResultListDtoContractClaimDocumentView, any>({
        path: `/claims/document-confirmations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags contract-claim-controller
     * @name GetApplicationTemplate
     * @summary get application template
     * @request GET:/claims/applications/templates
     * @secure
     */
    getApplicationTemplate: (params: RequestParams = {}) =>
      this.request<ClaimApplicationDto, any>({
        path: `/claims/applications/templates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_CLIENT', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-claim-controller
     * @name GetAccountTypes
     * @summary get account types
     * @request GET:/claims/account-types
     * @secure
     */
    getAccountTypes: (params: RequestParams = {}) =>
      this.request<AccountTypeCode[], any>({
        path: `/claims/account-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query ContractClaimView using dynamic search of TableViewRepository
     *
     * @tags contract-claim-controller
     * @name List10
     * @summary list
     * @request GET:/claims
     * @secure
     */
    list10: (query: List10Params, params: RequestParams = {}) =>
      this.request<ResultListDtoContractClaimView, any>({
        path: `/claims`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  agentController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags agent-controller
     * @name GetAgent
     * @summary get agent
     * @request GET:/agents/{id}
     * @secure
     */
    getAgent: (id: number, params: RequestParams = {}) =>
      this.request<AgentDto, any>({
        path: `/agents/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-controller
     * @name Update10
     * @summary update
     * @request PUT:/agents/{id}
     * @secure
     */
    update10: (id: number, data: AgentDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agents/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-controller
     * @name Delete1
     * @summary delete
     * @request DELETE:/agents/{id}
     * @secure
     */
    delete1: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agents/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-controller
     * @name GetAgents
     * @summary get agents
     * @request GET:/agents
     * @secure
     */
    getAgents: (query: GetAgentsParams, params: RequestParams = {}) =>
      this.request<AgentDto[], any>({
        path: `/agents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-controller
     * @name Save1
     * @summary save
     * @request POST:/agents
     * @secure
     */
    save1: (data: AgentDto, params: RequestParams = {}) =>
      this.request<AgentDto, any>({
        path: `/agents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags agent-controller
     * @name GetAgentsByType
     * @summary get agents by type
     * @request GET:/agents/search
     * @secure
     */
    getAgentsByType: (query: GetAgentsByTypeParams, params: RequestParams = {}) =>
      this.request<AgentDto[], any>({
        path: `/agents/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  agentActController = {
    /**
     * @description Query AgentActView using dynamic search of TableViewRepository
     *
     * @tags agent-act-controller
     * @name Read10
     * @summary read
     * @request GET:/agents/acts/{id}
     * @secure
     */
    read10: ({ id, ...query }: Read10Params, params: RequestParams = {}) =>
      this.request<ResultListDtoAgentActView, any>({
        path: `/agents/acts/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name Update11
     * @summary update
     * @request PUT:/agents/acts/{id}
     * @secure
     */
    update11: (id: number, data: UpdateAgentActDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agents/acts/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name Delete2
     * @summary delete
     * @request DELETE:/agents/acts/{id}
     * @secure
     */
    delete2: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agents/acts/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name WithdrawConfirmation
     * @summary withdraw confirmation
     * @request POST:/agents/acts/{id}/withdraw-confirmation
     * @secure
     */
    withdrawConfirmation: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agents/acts/${id}/withdraw-confirmation`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name Confirm
     * @summary confirm
     * @request POST:/agents/acts/{id}/confirm
     * @secure
     */
    confirm: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/agents/acts/${id}/confirm`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Query AgentActsView using dynamic search of TableViewRepository
     *
     * @tags agent-act-controller
     * @name AgentActs
     * @summary agent acts
     * @request GET:/agents/acts
     * @secure
     */
    agentActs: (query: AgentActsParams, params: RequestParams = {}) =>
      this.request<AgentActsDto, any>({
        path: `/agents/acts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name CreateNewAct
     * @summary create new act
     * @request POST:/agents/acts
     * @secure
     */
    createNewAct: (data: CreateActDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/agents/acts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name PrintXlsxReport3
     * @summary print xlsx report
     * @request GET:/agents/acts/{id}/xlsx-report
     * @secure
     */
    printXlsxReport3: ({ id, ...query }: PrintXlsxReport3Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/agents/acts/${id}/xlsx-report`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name PrintPdfReport2
     * @summary print pdf report
     * @request GET:/agents/acts/{id}/pdf-report
     * @secure
     */
    printPdfReport2: (id: number, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/agents/acts/${id}/pdf-report`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name ReadInfo
     * @summary read info
     * @request GET:/agents/acts/{id}/info
     * @secure
     */
    readInfo: (id: number, params: RequestParams = {}) =>
      this.request<AgentActInfoDto, any>({
        path: `/agents/acts/${id}/info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name PrintContractsXlsxReport
     * @summary print contracts xlsx report
     * @request GET:/agents/acts/{id}/contracts/xlsx-report
     * @secure
     */
    printContractsXlsxReport: ({ id, ...query }: PrintContractsXlsxReportParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/agents/acts/${id}/contracts/xlsx-report`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name PrintXlsxAgentActs
     * @summary print xlsx agent acts
     * @request GET:/agents/acts/print/xlsx
     * @secure
     */
    printXlsxAgentActs: (query: PrintXlsxAgentActsParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/agents/acts/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-act-controller
     * @name InitNewAgentAct
     * @summary init new agent act
     * @request GET:/agents/acts/init-creation
     * @secure
     */
    initNewAgentAct: (query: InitNewAgentActParams, params: RequestParams = {}) =>
      this.request<AgentActDto, any>({
        path: `/agents/acts/init-creation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  statisticsController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name PrintXlsxRiskStatistics
     * @summary print xlsx risk statistics
     * @request POST:/statistics/risk/print/xlsx
     * @secure
     */
    printXlsxRiskStatistics: (data: ProductRiskStatisticsDto[], params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/statistics/risk/print/xlsx`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name PrintXlsxPartnerStatistics
     * @summary print xlsx partner statistics
     * @request POST:/statistics/partner/print/xlsx
     * @secure
     */
    printXlsxPartnerStatistics: (data: PartnerStatisticsDto[], params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/statistics/partner/print/xlsx`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name PrintXlsxPartnerDepartmentStatistics
     * @summary print xlsx partner department statistics
     * @request POST:/statistics/partner-department/print/xlsx
     * @secure
     */
    printXlsxPartnerDepartmentStatistics: (data: PartnerDepartmentStatisticsDto[], params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/statistics/partner-department/print/xlsx`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name PrintXlsxInsuranceProgramStatistics
     * @summary print xlsx insurance program statistics
     * @request POST:/statistics/insurance-program/print/xlsx
     * @secure
     */
    printXlsxInsuranceProgramStatistics: (data: InsuranceProgramStatisticsDto[], params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/statistics/insurance-program/print/xlsx`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name PrintXlsxDaysStatistics
     * @summary print xlsx days statistics
     * @request POST:/statistics/days/print/xlsx
     * @secure
     */
    printXlsxDaysStatistics: (data: DaysStatisticsDto[], params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/statistics/days/print/xlsx`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name GetProductRiskStatistics
     * @summary get product risk statistics
     * @request GET:/statistics/risks
     * @secure
     */
    getProductRiskStatistics: (query: GetProductRiskStatisticsParams, params: RequestParams = {}) =>
      this.request<ProductRiskStatisticsDto[], any>({
        path: `/statistics/risks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name RiskPaymentInfo
     * @summary risk payment info
     * @request GET:/statistics/riskPaymentInfo
     * @secure
     */
    riskPaymentInfo: (query: RiskPaymentInfoParams, params: RequestParams = {}) =>
      this.request<ProductRiskStatisticsDto[], any>({
        path: `/statistics/riskPaymentInfo`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name GetInsuranceProgramStatistics
     * @summary get insurance program statistics
     * @request GET:/statistics/programs
     * @secure
     */
    getInsuranceProgramStatistics: (query: GetInsuranceProgramStatisticsParams, params: RequestParams = {}) =>
      this.request<InsuranceProgramStatisticsDto[], any>({
        path: `/statistics/programs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name ProgramPaymentInfo
     * @summary program payment info
     * @request GET:/statistics/programPaymentInfo
     * @secure
     */
    programPaymentInfo: (query: ProgramPaymentInfoParams, params: RequestParams = {}) =>
      this.request<DashboardInfoDto, any>({
        path: `/statistics/programPaymentInfo`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name ListPaymentStatistics
     * @summary list payment statistics
     * @request GET:/statistics/payments
     * @secure
     */
    listPaymentStatistics: (query: ListPaymentStatisticsParams, params: RequestParams = {}) =>
      this.request<SalesStatisticsDtoBigDecimal[], any>({
        path: `/statistics/payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name GetPartnerStatistics
     * @summary get partner statistics
     * @request GET:/statistics/partners
     * @secure
     */
    getPartnerStatistics: (query: GetPartnerStatisticsParams, params: RequestParams = {}) =>
      this.request<PartnerStatisticsDto[], any>({
        path: `/statistics/partners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name GetMonthInfo
     * @summary get month info
     * @request GET:/statistics/month-info
     * @secure
     */
    getMonthInfo: (query: GetMonthInfoParams, params: RequestParams = {}) =>
      this.request<MonthInfoDto, any>({
        path: `/statistics/month-info`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name PaymentInfo
     * @summary payment info
     * @request GET:/statistics/generalPaymentInfo
     * @secure
     */
    paymentInfo: (query: PaymentInfoParams, params: RequestParams = {}) =>
      this.request<TopDashboardInfoDto | TopDashboardLimitsInfoDto, any>({
        path: `/statistics/generalPaymentInfo`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name DashboardGeneralInfo
     * @summary dashboard general info
     * @request GET:/statistics/generalInfo
     * @secure
     */
    dashboardGeneralInfo: (query: DashboardGeneralInfoParams, params: RequestParams = {}) =>
      this.request<TopDashboardLimitsInfoDto, any>({
        path: `/statistics/generalInfo`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name GetPartnerDepartmentStatistics
     * @summary get partner department statistics
     * @request GET:/statistics/departments
     * @secure
     */
    getPartnerDepartmentStatistics: (query: GetPartnerDepartmentStatisticsParams, params: RequestParams = {}) =>
      this.request<PartnerDepartmentStatisticsDto[], any>({
        path: `/statistics/departments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name GetDaysStatistics
     * @summary get days statistics
     * @request GET:/statistics/days
     * @secure
     */
    getDaysStatistics: (query: GetDaysStatisticsParams, params: RequestParams = {}) =>
      this.request<DaysStatisticsDto[], any>({
        path: `/statistics/days`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name GetDashboardInfo
     * @summary get dashboard info
     * @request GET:/statistics/dashboard-info
     * @secure
     */
    getDashboardInfo: (query: GetDashboardInfoParams, params: RequestParams = {}) =>
      this.request<TopDashboardInfoDto | TopDashboardLimitsInfoDto, any>({
        path: `/statistics/dashboard-info`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name ListPayStatistics
     * @summary list pay statistics
     * @request GET:/statistics/claim-payments
     * @secure
     */
    listPayStatistics: (query: ListPayStatisticsParams, params: RequestParams = {}) =>
      this.request<ClaimPaymentsStatisticDto[], any>({
        path: `/statistics/claim-payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags statistics-controller
     * @name ListBrandSales
     * @summary list brand sales
     * @request GET:/statistics/brand-sales
     * @secure
     */
    listBrandSales: (query: ListBrandSalesParams, params: RequestParams = {}) =>
      this.request<SalesStatisticsDtoLong[], any>({
        path: `/statistics/brand-sales`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  parseFileController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN')
     *
     * @tags parse-file-controller
     * @name DownloadInsuredPersonList
     * @summary download insured person list
     * @request POST:/parse-file/insured-person
     * @secure
     */
    downloadInsuredPersonList: (
      query: DownloadInsuredPersonListParams,
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<ParsedInsuredPersonDto[], any>({
        path: `/parse-file/insured-person`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  };
  notificationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags notification-controller
     * @name SendPaymentEmail
     * @summary send payment email
     * @request POST:/notifications/send-payment-email/{id}
     * @secure
     */
    sendPaymentEmail: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/send-payment-email/${id}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM', 'ROLE_ADMIN')
     *
     * @tags notification-controller
     * @name SendOtpByEmail
     * @summary send otp by email
     * @request POST:/notifications/email/by-template
     * @secure
     */
    sendOtpByEmail: (data: TemplatedEmailMessageDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/email/by-template`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags notification-controller
     * @name CancelPaymentEmailSending
     * @summary cancel payment email sending
     * @request DELETE:/notifications/send-payment-email/{id}/cancel
     * @secure
     */
    cancelPaymentEmailSending: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/notifications/send-payment-email/${id}/cancel`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
  mtsbuIntegrationController = {
    /**
     * No description
     *
     * @tags mtsbu-integration-controller
     * @name NewDigitalReserve
     * @summary new digital reserve
     * @request POST:/mtsbu/contract/digital/reserve/{contractId}
     * @secure
     */
    newDigitalReserve: (contractId: number, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/mtsbu/contract/digital/reserve/${contractId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-integration-controller
     * @name ResetContractReservation
     * @summary reset contract reservation
     * @request POST:/mtsbu/contract/digital/reserve/reset/{contractId}
     * @secure
     */
    resetContractReservation: (contractId: number, params: RequestParams = {}) =>
      this.request<ContractDto, any>({
        path: `/mtsbu/contract/digital/reserve/reset/${contractId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-integration-controller
     * @name CheckMtsbuContract
     * @summary check mtsbu contract
     * @request POST:/mtsbu/check/contract
     * @secure
     */
    checkMtsbuContract: (data: ContractDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mtsbu/check/contract`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags mtsbu-integration-controller
     * @name GetVehicleDetails
     * @summary get vehicle details
     * @request GET:/mtsbu/vehicle-details/{registrationNumber}
     * @secure
     */
    getVehicleDetails: (registrationNumber: string, params: RequestParams = {}) =>
      this.request<VehicleObjectDto, any>({
        path: `/mtsbu/vehicle-details/${registrationNumber}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  managerSignupController = {
    /**
     * No description
     *
     * @tags manager-signup-controller
     * @name ValidateAccount
     * @summary validate account
     * @request POST:/manager-signup/validate-account
     * @secure
     */
    validateAccount: (data: SignupAccountBaseInfoDto | SignupAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/manager-signup/validate-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags manager-signup-controller
     * @name ValidateSmsVerification
     * @summary validate sms verification
     * @request POST:/manager-signup/sms-verification/check
     * @secure
     */
    validateSmsVerification: (data: SignupAccountBaseInfoDto | SignupAccountDto, params: RequestParams = {}) =>
      this.request<SignupAccountDto, any>({
        path: `/manager-signup/sms-verification/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags manager-signup-controller
     * @name SendSmsVerification
     * @summary send sms verification
     * @request POST:/manager-signup/sms-verification
     * @secure
     */
    sendSmsVerification: (data: SignupAccountBaseInfoDto | SignupAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/manager-signup/sms-verification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags manager-signup-controller
     * @name Signup
     * @summary signup
     * @request POST:/manager-signup
     * @secure
     */
    signup: (data: SignupAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/manager-signup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  diaIntegrationController = {
    /**
     * No description
     *
     * @tags dia-integration-controller
     * @name ReceiveDocuments
     * @summary receive documents
     * @request POST:/integration/dia/documents/receive
     * @secure
     */
    receiveDocuments: (query: ReceiveDocumentsParams, params: RequestParams = {}) =>
      this.request<DiaDocumentReceivingResponseDto, any>({
        path: `/integration/dia/documents/receive`,
        method: 'POST',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dia-integration-controller
     * @name GetDeepLink
     * @summary get deep link
     * @request GET:/integration/dia/receive/metadata
     * @secure
     */
    getDeepLink: (query: GetDeepLinkParams, params: RequestParams = {}) =>
      this.request<DiaDeepLinkResponseDto, any>({
        path: `/integration/dia/receive/metadata`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dia-integration-controller
     * @name GetDocumentsMetadata
     * @summary get documents metadata
     * @request GET:/integration/dia/receive/metadata/{requestId}
     * @secure
     */
    getDocumentsMetadata: (requestId: string, params: RequestParams = {}) =>
      this.request<DiaDocumentsMetadataDto, any>({
        path: `/integration/dia/receive/metadata/${requestId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  insuranceProgramController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name SendCommonOffer
     * @summary send common offer
     * @request POST:/insurance-programs/send-common-offer
     * @secure
     */
    sendCommonOffer: (data: CommonOfferDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/insurance-programs/send-common-offer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name ListPartnerAgents
     * @summary list partner agents
     * @request GET:/insurance-programs/{id}/partner-agents
     * @secure
     */
    listPartnerAgents: (id: number, params: RequestParams = {}) =>
      this.request<PartnerProgramAgentDto[], any>({
        path: `/insurance-programs/${id}/partner-agents`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name Read4
     * @summary read
     * @request GET:/insurance-programs/{id}
     * @secure
     */
    read4: (id: number, params: RequestParams = {}) =>
      this.request<InsuranceProgramDto, any>({
        path: `/insurance-programs/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query InsuranceProgramView using dynamic search of TableViewRepository
     *
     * @tags insurance-program-controller
     * @name PrintXlsxReport1
     * @summary print xlsx report
     * @request GET:/insurance-programs/print/xlsx
     * @secure
     */
    printXlsxReport1: (query: PrintXlsxReport1Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/insurance-programs/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name ListAvailableInsuranceProgramsForPartner
     * @summary list available insurance programs for partner
     * @request GET:/insurance-programs/available/partner-program-creation
     * @secure
     */
    listAvailableInsuranceProgramsForPartner: (
      query: ListAvailableInsuranceProgramsForPartnerParams,
      params: RequestParams = {},
    ) =>
      this.request<InsuranceProgramDto[], any>({
        path: `/insurance-programs/available/partner-program-creation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags insurance-program-controller
     * @name ListAvailableInsuranceProgramsForExternalIdByPartner
     * @summary list available insurance programs for external id by partner
     * @request GET:/insurance-programs/available/internet-sale-creation
     * @secure
     */
    listAvailableInsuranceProgramsForExternalIdByPartner: (
      query: ListAvailableInsuranceProgramsForExternalIdByPartnerParams,
      params: RequestParams = {},
    ) =>
      this.request<OptionDto[], any>({
        path: `/insurance-programs/available/internet-sale-creation`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  emailNotificationController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags email-notification-controller
     * @name SendEmails
     * @summary send emails
     * @request POST:/email-notification/send/template-type/{templateType}/partner-program/{partnerProgramId}
     * @secure
     */
    sendEmails: (
      templateType: EmailTemplateType,
      partnerProgramId: number,
      data: string[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/email-notification/send/template-type/${templateType}/partner-program/${partnerProgramId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  documentRecognitionController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags document-recognition-controller
     * @name Recognize
     * @summary recognize
     * @request POST:/document-recognition/recognize
     * @secure
     */
    recognize: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        | AutoCertificateRecognitionDto
        | DriverLicenceRecognitionDto
        | FiscalRecognitionDto
        | IdentificationNumberRecognitionDto
        | NewPassportFormatRecognitionDto
        | OldPassportFormatRecognitionDto
        | OtherRecognitionDto,
        any
      >({
        path: `/document-recognition/recognize`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),
  };
  contractPaymentController = {
    /**
     * No description
     *
     * @tags contract-payment-controller
     * @name DoFirstClientContractPayment
     * @summary do first client contract payment
     * @request POST:/contract-payments/external/{externalId}/pay
     * @secure
     */
    doFirstClientContractPayment: (externalId: string, data: InitialPaymentResponse, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contract-payments/external/${externalId}/pay`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Query ContractPaymentView using dynamic search of TableViewRepository
     *
     * @tags contract-payment-controller
     * @name PaymentList
     * @summary payment list
     * @request GET:/contract-payments/payments
     * @secure
     */
    paymentList: (query: PaymentListParams, params: RequestParams = {}) =>
      this.request<ResultListDtoContractPaymentView, any>({
        path: `/contract-payments/payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-payment-controller
     * @name GetPaymentResult
     * @summary get payment result
     * @request GET:/contract-payments/payment-result/{orderId}
     * @secure
     */
    getPaymentResult: (orderId: string, params: RequestParams = {}) =>
      this.request<PaymentResultDto, any>({
        path: `/contract-payments/payment-result/${orderId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags contract-payment-controller
     * @name ExternalGeneratePayment
     * @summary external generate payment
     * @request GET:/contract-payments/external/generate-payment/{externalId}
     * @secure
     */
    externalGeneratePayment: (externalId: string, params: RequestParams = {}) =>
      this.request<PaymentButtonRequestDto, any>({
        path: `/contract-payments/external/generate-payment/${externalId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  clientSignupController = {
    /**
     * No description
     *
     * @tags client-signup-controller
     * @name ValidateAccount1
     * @summary validate account
     * @request POST:/client-signup/validate-account
     * @secure
     */
    validateAccount1: (data: ClientSignupAccountBaseInfoDto | ClientSignupAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/client-signup/validate-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags client-signup-controller
     * @name ValidateSmsVerification1
     * @summary validate sms verification
     * @request POST:/client-signup/sms-verification/check
     * @secure
     */
    validateSmsVerification1: (
      data: ClientSignupAccountBaseInfoDto | ClientSignupAccountDto,
      params: RequestParams = {},
    ) =>
      this.request<ClientSignupAccountDto, any>({
        path: `/client-signup/sms-verification/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client-signup-controller
     * @name SendSmsVerification1
     * @summary send sms verification
     * @request POST:/client-signup/sms-verification
     * @secure
     */
    sendSmsVerification1: (data: ClientSignupAccountBaseInfoDto | ClientSignupAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/client-signup/sms-verification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags client-signup-controller
     * @name Signup1
     * @summary signup
     * @request POST:/client-signup
     * @secure
     */
    signup1: (data: ClientSignupAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/client-signup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  clientRegistrationController = {
    /**
     * @description Security requirements: permitAll()
     *
     * @tags client-registration-controller
     * @name ValidateEmailOtpCode
     * @summary validate email otp code
     * @request POST:/client-registration/validate-email/check
     * @secure
     */
    validateEmailOtpCode: (data: ClientRegistrationValidateDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/client-registration/validate-email/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags client-registration-controller
     * @name ValidateEmailAndSendEmailVerification
     * @summary validate email and send email verification
     * @request POST:/client-registration/validate-email
     * @secure
     */
    validateEmailAndSendEmailVerification: (data: ClientRegistrationAccountDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/client-registration/validate-email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: permitAll()
     *
     * @tags client-registration-controller
     * @name Registration
     * @summary registration
     * @request POST:/client-registration
     * @secure
     */
    registration: (data: ClientRegistrationAccountDto, params: RequestParams = {}) =>
      this.request<PersonCounterparty, any>({
        path: `/client-registration`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  authenticationController = {
    /**
     * No description
     *
     * @tags authentication-controller
     * @name TwoFactorLogin
     * @summary two factor login
     * @request POST:/auth/2fa/login
     * @secure
     */
    twoFactorLogin: (data: UserCredentialsDto, params: RequestParams = {}) =>
      this.request<TwoFactorAuthIdentityResponseDto, any>({
        path: `/auth/2fa/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags authentication-controller
     * @name TwoFactorCheck
     * @summary two factor check
     * @request POST:/auth/2fa/check/{id}
     * @secure
     */
    twoFactorCheck: (id: number, data: UserCredentialsWithOTPDto, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/auth/2fa/check/${id}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  externalContractsImportController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags external-contracts-import-controller
     * @name UpdateContractsMedApp
     * @summary update contracts med app
     * @request POST:/api/transfer/contracts/update
     * @secure
     */
    updateContractsMedApp: (data: MedAppImportContractDto[], params: RequestParams = {}) =>
      this.request<UpdateResultDto, any>({
        path: `/api/transfer/contracts/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags external-contracts-import-controller
     * @name SendOffer1
     * @summary send offer
     * @request POST:/api/transfer/contracts/send-offer
     * @secure
     */
    sendOffer1: (data: PartnerToSendOfferDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/transfer/contracts/send-offer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER') Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags external-contracts-import-controller
     * @name ImportContractsMedApp1
     * @summary import contracts citrus
     * @request POST:/api/transfer/contracts/import
     * @secure
     */
    importContractsMedApp1: (data: CitrusImportContractDto[], params: RequestParams = {}) =>
      this.request<ImportResultDto | UpdateResultDto, any>({
        path: `/api/transfer/contracts/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags external-contracts-import-controller
     * @name GetSignedContract
     * @summary get signed contract
     * @request GET:/api/transfer/contracts/signed-contract
     * @secure
     */
    getSignedContract: (query: GetSignedContractParams, params: RequestParams = {}) =>
      this.request<ExternalSavedSignedContractDto, any>({
        path: `/api/transfer/contracts/signed-contract`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  osagoExternalController = {
    /**
     * @description Entry point for user to start working with external osago endpoints
     *
     * @tags osago-external-controller
     * @name PartnerLogin
     * @summary partner login
     * @request POST:/api/external/login
     * @secure
     */
    partnerLogin: (data: UserCredentialsDto, params: RequestParams = {}) =>
      this.request<AuthenticationResponseDto, any>({
        path: `/api/external/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Request OTP to sign specified contract. OTP is sent in SMS to client phone number Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name SendSignVerification3
     * @summary send sign verification
     * @request POST:/api/external/contract/{id}/sign-verification
     * @secure
     */
    sendSignVerification3: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/external/contract/${id}/sign-verification`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * @description Checks if OTP, which was sent in SMS to client phone number, is valid Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name SignContract1
     * @summary sign contract
     * @request POST:/api/external/contract/{id}/sign
     * @secure
     */
    signContract1: (id: string, params: RequestParams = {}) =>
      this.request<ExternalContractInfoDto, any>({
        path: `/api/external/contract/${id}/sign`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Reserves contract in MTSBU. Contract must be in "DRAFT" state. Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name ReserveContractInMtsbu
     * @summary reserve contract in mtsbu
     * @request POST:/api/external/contract/{id}/mtsbu-reserve
     * @secure
     */
    reserveContractInMtsbu: ({ id, ...query }: ReserveContractInMtsbuParams, params: RequestParams = {}) =>
      this.request<ExternalContractInfoDto, any>({
        path: `/api/external/contract/${id}/mtsbu-reserve`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Save contract draft Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name Create8
     * @summary create
     * @request POST:/api/external/contract
     * @secure
     */
    create8: (query: Create8Params, data: ExternalOsagoContractDto, params: RequestParams = {}) =>
      this.request<ExternalContractInfoDto, any>({
        path: `/api/external/contract`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Find vehicle details by registration plate number Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name GetVehicleDetails1
     * @summary get vehicle details
     * @request GET:/api/external/vehicle-details/{registrationNumber}
     * @secure
     */
    getVehicleDetails1: (registrationNumber: string, params: RequestParams = {}) =>
      this.request<VehicleSearchDetailsDto, any>({
        path: `/api/external/vehicle-details/${registrationNumber}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query VehicleView using dynamic search of TableViewRepository
     *
     * @tags osago-external-controller
     * @name ListVehicles
     * @summary list vehicles
     * @request GET:/api/external/dictionary/vehicles
     * @secure
     */
    listVehicles: (query: ListVehiclesParams, params: RequestParams = {}) =>
      this.request<ResultListDtoVehicleView, any>({
        path: `/api/external/dictionary/vehicles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query VehicleView using dynamic search of TableViewRepository
     *
     * @tags osago-external-controller
     * @name GetMarksAndModels
     * @summary get marks and models
     * @request GET:/api/external/dictionary/mark-and-model
     * @secure
     */
    getMarksAndModels: (query: GetMarksAndModelsParams, params: RequestParams = {}) =>
      this.request<AutoModelDto[], any>({
        path: `/api/external/dictionary/mark-and-model`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Find city and it's zone by whole name, or part of it. Returns entry list with matching in the beginning, middle, or end of stored city names Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name GetZoneByCity
     * @summary get zone by city
     * @request GET:/api/external/dictionary/city-zone
     * @secure
     */
    getZoneByCity: (query: GetZoneByCityParams, params: RequestParams = {}) =>
      this.request<CityZoneDto[], any>({
        path: `/api/external/dictionary/city-zone`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name GetCalculationsDefaultParams
     * @summary get calculations default params
     * @request GET:/api/external/calculations/default-params
     * @secure
     */
    getCalculationsDefaultParams: (params: RequestParams = {}) =>
      this.request<CalculationRequestDefaultParamsDto, any>({
        path: `/api/external/calculations/default-params`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Returns calculations for specified parameters Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags osago-external-controller
     * @name GetCalculations
     * @summary get calculations
     * @request GET:/api/external/calculations
     * @secure
     */
    getCalculations: (query: GetCalculationsParams, params: RequestParams = {}) =>
      this.request<ExternalOsagoCalculationResultDto[], any>({
        path: `/api/external/calculations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  assistantContractClaimController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_SYSTEM_PARTNER')
     *
     * @tags assistant-contract-claim-controller
     * @name CreateContractClaim
     * @summary create contract claim
     * @request POST:/api/assistans/contract-claim/import
     * @secure
     */
    createContractClaim: (data: AssistantAppointmentDto, params: RequestParams = {}) =>
      this.request<AssistantAppointmentDto, any>({
        path: `/api/assistans/contract-claim/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  aggregatorController = {
    /**
     * @description Security requirements: hasRole('ROLE_ADMIN')
     *
     * @tags aggregator-controller
     * @name CreateAggregator
     * @summary create aggregator
     * @request POST:/aggregator
     * @secure
     */
    createAggregator: (data: CreateUpdateAggregatorDto, params: RequestParams = {}) =>
      this.request<AggregatorDto, any>({
        path: `/aggregator`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Security requirements: hasRole('ROLE_AGGREGATOR')
     *
     * @tags aggregator-controller
     * @name ListProgramsByProductId
     * @summary list programs by product id
     * @request GET:/aggregator/insurance-programs
     * @secure
     */
    listProgramsByProductId: (query: ListProgramsByProductIdParams, params: RequestParams = {}) =>
      this.request<AggregatorPartnerProgramsDto[], any>({
        path: `/aggregator/insurance-programs`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  agentContractController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-contract-controller
     * @name CalculatingExcludeContracts
     * @summary calculating exclude contracts
     * @request POST:/agent-contracts/sum-calculation
     * @secure
     */
    calculatingExcludeContracts: (data: CreateActDto, params: RequestParams = {}) =>
      this.request<NewAgentActContractsReportDto, any>({
        path: `/agent-contracts/sum-calculation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query ContractsAvailableForActsView using dynamic search of TableViewRepository
     *
     * @tags agent-contract-controller
     * @name AllContractsAvailableForAgentAct
     * @summary all contracts available for agent act
     * @request GET:/agent-contracts/{agentContractId}/available/contracts
     * @secure
     */
    allContractsAvailableForAgentAct: (
      { agentContractId, ...query }: AllContractsAvailableForAgentActParams,
      params: RequestParams = {},
    ) =>
      this.request<NewAgentActContractsReportDto, any>({
        path: `/agent-contracts/${agentContractId}/available/contracts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Query AgentContractView using dynamic search of TableViewRepository
     *
     * @tags agent-contract-controller
     * @name AvailableAgentContracts
     * @summary available agent contracts
     * @request GET:/agent-contracts/available/list
     * @secure
     */
    availableAgentContracts: (query: AvailableAgentContractsParams, params: RequestParams = {}) =>
      this.request<ResultListDtoAgentContractView, any>({
        path: `/agent-contracts/available/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags agent-contract-controller
     * @name AgentContractNumbers
     * @summary agent contract numbers
     * @request GET:/agent-contracts
     * @secure
     */
    agentContractNumbers: (query: AgentContractNumbersParams, params: RequestParams = {}) =>
      this.request<AgentContractDto[], any>({
        path: `/agent-contracts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  adminController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN')
     *
     * @tags admin-controller
     * @name ExportDocuments
     * @summary export documents
     * @request POST:/admin-actions/export-documents
     * @secure
     */
    exportDocuments: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin-actions/export-documents`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  mobizonController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags mobizon-controller
     * @name GetMobizonBalanceInfo
     * @summary get mobizon balance info
     * @request GET:/mobizon/checkMobizonBalance
     * @secure
     */
    getMobizonBalanceInfo: (params: RequestParams = {}) =>
      this.request<MobizonResponseDto, any>({
        path: `/mobizon/checkMobizonBalance`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  fondyController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags fondy-controller
     * @name GetMerchantBalances
     * @summary get merchant balances
     * @request GET:/fondy/get-merchant-balances
     * @secure
     */
    getMerchantBalances: (query: GetMerchantBalancesParams, params: RequestParams = {}) =>
      this.request<MerchantBalancesResponse, any>({
        path: `/fondy/get-merchant-balances`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  checkListsFinApController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_PARTNER_SELLER', 'ROLE_INSURANCE_ADMIN', 'ROLE_HR_MANAGER', 'ROLE_INSURANCE_MANAGER')
     *
     * @tags check-lists-fin-ap-controller
     * @name GetCheckListsFinAp
     * @summary get check lists fin ap
     * @request GET:/finap/check
     * @secure
     */
    getCheckListsFinAp: (query: GetCheckListsFinApParams, params: RequestParams = {}) =>
      this.request<FinMonitoringReportDto, any>({
        path: `/finap/check`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  clientFileController = {
    /**
     * No description
     *
     * @tags client-file-controller
     * @name GetClientFile
     * @summary get client file
     * @request GET:/files/{urlFileId}
     * @secure
     */
    getClientFile: (urlFileId: string, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/files/${urlFileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags client-file-controller
     * @name GetOfferFile
     * @summary get offer file
     * @request GET:/files/offer/{urlFileId}
     * @secure
     */
    getOfferFile: (urlFileId: string, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/files/offer/${urlFileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  documentTemplateController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_SYSTEM')
     *
     * @tags document-template-controller
     * @name DownloadTemplateFile
     * @summary download template file
     * @request GET:/document-templates/template-files/{templateFileId}
     * @secure
     */
    downloadTemplateFile: (templateFileId: number, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/document-templates/template-files/${templateFileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  discountController = {
    /**
     * @description Query DiscountView using dynamic search of TableViewRepository
     *
     * @tags discount-controller
     * @name PrintXlsxReport2
     * @summary print xlsx report
     * @request GET:/discounts/print/xlsx
     * @secure
     */
    printXlsxReport2: (query: PrintXlsxReport2Params, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/discounts/print/xlsx`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  deviceDetailsController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags device-details-controller
     * @name GetDeviceDetailsByImei
     * @summary get device details by imei
     * @request GET:/device-details/imei/{imei}
     * @secure
     */
    getDeviceDetailsByImei: (imei: string, params: RequestParams = {}) =>
      this.request<DeviceDetailsWrapperDto, any>({
        path: `/device-details/imei/${imei}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags device-details-controller
     * @name GetImeiDbBalanceInfo
     * @summary get imei db balance info
     * @request GET:/device-details/checkImeiDBBalance
     * @secure
     */
    getImeiDbBalanceInfo: (params: RequestParams = {}) =>
      this.request<ImeiDBBalanceDto, any>({
        path: `/device-details/checkImeiDBBalance`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  currencyController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN')
     *
     * @tags currency-controller
     * @name GetCurrencies
     * @summary get currencies
     * @request GET:/currencies
     * @secure
     */
    getCurrencies: (params: RequestParams = {}) =>
      this.request<CurrencyDto[], any>({
        path: `/currencies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  counterpartyController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags counterparty-controller
     * @name GetClaimManagersByInsuranceCompany
     * @summary get claim managers by insurance company
     * @request GET:/counterparties/insurance-company/{id}
     * @secure
     */
    getClaimManagersByInsuranceCompany: (id: number, params: RequestParams = {}) =>
      this.request<
        (CounterpartyDto | CompanyCounterpartyDto | EntrepreneurCounterpartyDto | PersonCounterpartyDto)[],
        any
      >({
        path: `/counterparties/insurance-company/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags counterparty-controller
     * @name FindCounterpartyByEmail
     * @summary find counterparty by email
     * @request GET:/counterparties/by-email-and-type
     * @secure
     */
    findCounterpartyByEmail: (query: FindCounterpartyByEmailParams, params: RequestParams = {}) =>
      this.request<CounterpartyDto | CompanyCounterpartyDto | EntrepreneurCounterpartyDto | PersonCounterpartyDto, any>(
        {
          path: `/counterparties/by-email-and-type`,
          method: 'GET',
          query: query,
          secure: true,
          format: 'json',
          ...params,
        },
      ),

    /**
     * @description Query CounterpartyDetailsView using dynamic search of TableViewRepository
     *
     * @tags counterparty-controller
     * @name List6
     * @summary list
     * @request GET:/counterparties
     * @secure
     */
    list6: (query: List6Params, params: RequestParams = {}) =>
      this.request<ResultListDtoCounterpartyDetailsView, any>({
        path: `/counterparties`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  contractTypeController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_ADMIN', 'ROLE_INSURANCE_ADMIN', 'ROLE_PARTNER_ASSISTANCE')
     *
     * @tags contract-type-controller
     * @name GetContractTypes
     * @summary get contract types
     * @request GET:/contract-types
     * @secure
     */
    getContractTypes: (params: RequestParams = {}) =>
      this.request<OptionDto[], any>({
        path: `/contract-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  companyController = {
    /**
     * @description Query CompanyView using dynamic search of TableViewRepository
     *
     * @tags company-controller
     * @name List9
     * @summary list
     * @request GET:/companies
     * @secure
     */
    list9: (query: List9Params, params: RequestParams = {}) =>
      this.request<CompanyView[], any>({
        path: `/companies`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  assistanceCompanyController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_INSURANCE_ADMIN', 'ROLE_ADMIN', 'ROLE_SYSTEM', 'ROLE_CLIENT')
     *
     * @tags assistance-company-controller
     * @name GetAssistingCompanyContacts
     * @summary get assisting company contacts
     * @request GET:/assistance/program-contacts/{insuranceProgramId}
     * @secure
     */
    getAssistingCompanyContacts: (insuranceProgramId: number, params: RequestParams = {}) =>
      this.request<AssistanceProgramContactsDto, any>({
        path: `/assistance/program-contacts/${insuranceProgramId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  externalCompanyController = {
    /**
     * No description
     *
     * @tags external-company-controller
     * @name DownloadCompanyLogo
     * @summary download company logo
     * @request GET:/api/v1/insurance-companies/logo/download/{insuranceCompanyId}
     * @secure
     */
    downloadCompanyLogo: (insuranceCompanyId: number, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/v1/insurance-companies/logo/download/${insuranceCompanyId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  externalEwaContractController = {
    /**
     * No description
     *
     * @tags external-ewa-contract-controller
     * @name DownloadContractReport
     * @summary download contract report
     * @request GET:/api/v1/contracts/eva/report-pdf/{externalId}
     * @secure
     */
    downloadContractReport: (externalId: string, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/v1/contracts/eva/report-pdf/${externalId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  listsController = {
    /**
     * @description Security requirements: hasAnyRole('ROLE_PARTNER_SELLER', 'ROLE_HR_MANAGER', 'ROLE_AGENT_SELLER', 'ROLE_PARTNER_MANAGER', 'ROLE_PARTNER_ADMIN', 'ROLE_INSURANCE_MANAGER', 'ROLE_INSURANCE_CLAIM_MANAGER', 'ROLE_ADMIN')
     *
     * @tags lists-controller
     * @name GetAccountTypes1
     * @summary get account types
     * @request GET:/account-types
     * @secure
     */
    getAccountTypes1: (params: RequestParams = {}) =>
      this.request<AccountTypeCode[], any>({
        path: `/account-types`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
