import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { ReactGoogleAutocompleteProps, usePlacesWidget } from 'react-google-autocomplete';
import { FieldErrors } from 'react-hook-form';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

interface Props extends InputProps, Omit<ReactGoogleAutocompleteProps, 'apiKey'> {
  label?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
}

export const AutoCompleteGoogle = ({
  name,
  errors,
  requiredFields,
  colorBase = 'grey',
  errorPosition,
  label,
  options,
  libraries,
  language,
  inputAutocompleteValue,
  onPlaceSelected,
  isRequired,
  hasError,
  ...props
}: Props) => {
  const { ref } = usePlacesWidget({
    onPlaceSelected,
    options,
    libraries,
    language,
    inputAutocompleteValue,
  });

  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <Input
        ref={(inputRef) => {
          //@ts-ignore
          ref.current = inputRef?.input;
        }}
        data-test={name}
        name={name}
        bordered={false}
        {...props}
      />
    </InputContainer>
  );
};
