//libs
import { createTypes } from 'redux-compose-reducer';
import { notification } from 'antd';
//api
import { getInsuranceCompany, getResolution } from 'api/insuranceCompanies';
import { AllActions, ThunkResult } from './actionTypes';
import { CurrencyResponse, getCurrencyData } from 'api/restcountries';
import { InsuranceCompanyDto } from 'types/dto/contracts-service';

export const TYPES = createTypes('insuranceConfiguration', [
  'setData',
  'setLoading',
  'setDates',
  'setLogo',
  'setResolutions',
  'setCurrency',
]);

// action creators
export const setData = (data: InsuranceCompanyDto): AllActions<InsuranceCompanyDto> => ({
  type: TYPES.setData,
  payload: data,
});

export const clearData = (data: {}): AllActions<{}> => ({
  type: TYPES.setData,
  payload: data,
});

export const setResolutions = (data) => ({
  type: TYPES.setResolutions,
  payload: data,
});

export const setCurrency = (data: CurrencyResponse[]) => ({
  type: TYPES.setCurrency,
  payload: data,
});

export const setLoading = (loading: boolean): AllActions<boolean> => ({ type: TYPES.setLoading, payload: loading });

export const setDates = (field: string, date: string): AllActions<{ [key: string]: string }> => ({
  type: TYPES.setDates,
  payload: {
    [field]: date,
  },
});

export const setLogo = (field: string, value: string): AllActions<{ [key: string]: string }> => ({
  type: TYPES.setLogo,
  payload: {
    [field]: value,
  },
});

//thunks
export const fetch =
  (id: number, t?: any): ThunkResult =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const { data } = await getInsuranceCompany(id);

      dispatch(setData(data));
    } catch (err) {
      notification.error({
        message: t('popup.upload_error'),
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getResolutionAction =
  (contractId: string | number, riskId: string | number, t): ThunkResult =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const data = await getResolution(contractId, riskId);
      dispatch(setResolutions(data));
    } catch (err) {
      notification.error({
        message: t('popup.error'),
      });
    }
  };

export const getCurrency =
  (t): ThunkResult =>
  async (dispatch) => {
    try {
      const { data } = await getCurrencyData();
      dispatch(setCurrency(data));
    } catch (err) {
      notification.error({
        message: t('popup.error'),
      });
    }
  };
