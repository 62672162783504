import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';
import i18n from 'i18next';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { List1Params, ResultListDtoSaleRegisterView, StatusType } from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/RegistrationDevices/Filters/Filters.schema';
import config from 'config';
import { apiContracts } from 'api/contracts';
import onDownload from 'callbacks/onDownload';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoSaleRegisterView | null;
}
type Filters = Values & { initialization: boolean };
interface EquipmentSales {
  loading: boolean;
  data: Record<StatusType, string>;
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'REGISTRATION_DEVICES/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'REGISTRATION_DEVICES/SET_FILTERS', payload } as const),
  setEquipmentSales: (payload: Partial<EquipmentSales>) =>
    ({ type: 'REGISTRATION_DEVICES/SET_EQUIPMENT_SALES', payload } as const),
};

export const downloadReport = (): ThunkType<void> => async (dispatch, getState) => {
  const registrationDevices = getState().registrationDeviceList;
  const sorting = getSortBy(registrationDevices.data.sorting);

  await onDownload(() =>
    apiContracts.saleRegisterController
      .printXlsxSaleRegisterList(
        {
          // TODO back
          // @ts-ignore
          timeZone: dayjs.tz.guess(),
          insuranceObjectPid: registrationDevices.filters?.insuranceObjectPid ?? undefined,
          saleRegisterStatus: registrationDevices.filters?.saleRegisterStatus ?? undefined,
          incidentDate: getDateRange(registrationDevices.filters?.dateFrom, registrationDevices.filters?.dateTo) ?? '',
          sorting: sorting ? `${sorting},-id` : '-id',
          ...getPrivateFilters(registrationDevices.data.privateFilters),
        },
        { format: 'blob' },
      )
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const requestData =
  (params: List1Params = {}): ThunkType<Promise<ResultListDtoSaleRegisterView>> =>
  async (dispatch, getState) => {
    const registrationDevices = getState().registrationDeviceList;
    const sorting = getSortBy(registrationDevices.data.sorting);

    return apiContracts.saleRegisterController
      .list1({
        distinct: true,
        count: true,
        attributes:
          'id,claimStatus,partnerDepartmentPayout,repairEquipmentDeliveryAddress,insuranceObjectSubtype,saleRegisterStatus,ownerEquipment,insuranceObjectName,insuranceObjectPid,insuranceObjectType,contractNumber,insuranceSum,saleRevenue,resolution,incidentDate,paymentSum,delivery,invoiceNumber,serviceCenter,serviceCenterDate,damage,repairStatus,repairCost,dispatchFromRepairDate,repairEquipmentDelivery,repairEquipmentInvoiceNumber,receiptDate,amountRepairCost,otherExpenses,saleDate,salePrice,dispatchAfterSaleInvoiceNumber,dispatchDate,dispatchAfterSaleDelivery,transferEquipmentToBuyerDate',
        insuranceObjectPid: registrationDevices.filters?.insuranceObjectPid ?? undefined,
        saleRegisterStatus: registrationDevices.filters?.saleRegisterStatus ?? undefined,
        incidentDate: getDateRange(registrationDevices.filters?.dateFrom, registrationDevices.filters?.dateTo) ?? '',
        sorting: sorting ? `${sorting},-id` : '-id',
        ...getPrivateFilters(registrationDevices.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const registrationDevices = getState().registrationDeviceList;

  dispatch(actions.setData({ loading: true }));

  dispatch(requestData({ ...getPageBy(registrationDevices.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export const loadEquipmentSales = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setEquipmentSales({ loading: true }));

  apiContracts.saleRegisterController
    .mapEquipmentSales()
    .then((res) => {
      dispatch(actions.setEquipmentSales({ loading: false, data: res.data }));
    })
    .catch(() => {
      dispatch(actions.setEquipmentSales({ loading: false }));
    });
};

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadEquipmentSales());
};

export interface StoreRegistrationDevices {
  data: Data;
  filters: Filters;
  equipmentSales: EquipmentSales;
}
export const initialState: StoreRegistrationDevices = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'incidentDate', order: 'descend' },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  equipmentSales: { loading: false, data: {} as Record<StatusType, string> },
};

const reducer = (state = initialState, action: InferActionTypes): StoreRegistrationDevices => {
  switch (action.type) {
    case 'REGISTRATION_DEVICES/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'REGISTRATION_DEVICES/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'REGISTRATION_DEVICES/SET_EQUIPMENT_SALES':
      return { ...state, equipmentSales: { ...state.equipmentSales, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
