import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import { object, Infer, optional, number, date, string, nullable, refiner } from 'utils/struct';

export const schema = object({
  insuranceCompanyId: optional(nullable(number())),
  id: optional(nullable(number())),
  insuranceRules: optional(nullable(string())),
  insuranceTypeName: optional(nullable(string())),
  dateFrom: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  insuranceCompanyId: null,
  id: null,
  insuranceRules: null,
  insuranceTypeName: null,
  dateFrom: dayjs().startOf('month'),
  dateTo: dayjs(),
};
