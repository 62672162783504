import { withStyles } from '@material-ui/core';
import BaseMenuItem from '@material-ui/core/MenuItem';
import theme from '../../../styles/theme';

const MenuItem = withStyles({
  root: {
    fontFamily: theme.typography.fontFamily,
    borderTop: '1px solid #EAECED',
    padding: 16,
    fontSize: 15,
    lineHeight: '18px',
    color: '#363636',
    '&:hover': {
      background: '#EBF5EB',
    },
  },
  selected: {
    fontWeight: 500,
    background: '#EBF5EB !important',
  },
})(BaseMenuItem);

export default MenuItem;
