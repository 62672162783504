import React, { useState } from 'react';
import { Layout } from 'antd';
import { css } from '@emotion/css';

import pallete from 'styles/pallete';
import Sider from './Sider';

import CreateModal from 'components/modals/CreateModal/CreateModal';

const cssLayout = css`
  display: flex;
  min-height: 100vh;
`;

const cssLayoutInner = css`
  background: ${pallete.blueGray.lighter};
  margin-left: 60px;
  width: calc(100% - 60px);
`;

const cssContent = css`
  /* display: inline-block; */
  padding: 30px;
  overflow: initial;
`;

interface Props {
  children: React.ReactNode;
}

export const LayoutComponent = ({ children }: Props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout className={cssLayout}>
      <Sider openModal={openModal} />
      <Layout className={cssLayoutInner}>
        <Layout.Content className={cssContent}>{children}</Layout.Content>
      </Layout>
      <CreateModal isOpen={isModalOpen} handleClose={closeModal} />
    </Layout>
  );
};
