import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';
import { filtersDefaultValue, TABS } from 'screens/RegisterOfTypes/utils';

import { RegisterTypes } from 'types/registerTypes';
import { TYPES } from './actions';
import config from 'config';

const initialState: RegisterTypes = {
  insuranceCompanies: [],
  filtersInfo: {
    companyId: filtersDefaultValue,
    ruleId: filtersDefaultValue,
    name: filtersDefaultValue,
    isObligatory: filtersDefaultValue,
    insuranceTypeId: filtersDefaultValue,
  },
  insuranceRules: [],
  insuranceTypes: {
    insuranceTypesTableData: [],
    insuranceTypesPending: false,
    insuranceTypesTotalCount: 0,
    insuranceTypesPage: 1,
    insuranceTypesPageSize: config.ui.pagination.size,
    insuranceTypesSorting: {
      direction: 'DESC',
    },
  },
  insuranceRisks: {
    insuranceRisksTableData: [],
    insuranceRisksTotalCount: 0,
    insuranceRisksPending: false,
    insuranceRisksPage: 1,
    insuranceRisksPageSize: config.ui.pagination.size,
    insuranceRisksSorting: {
      direction: 'DESC',
    },
  },
  activeTab: TABS.insuranceType,
  insTypesList: [],
};

const setInsuranceCompanies = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceCompanies: payload.data,
  };
};

const setInsuranceRules = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceRules: payload.data,
  };
};

const setInsuranceTypesData = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceTypes: {
      ...state.insuranceTypes,
      insuranceTypesTableData: payload.data,
      insuranceTypesPending: payload.pending,
      insuranceTypesTotalCount: payload.count,
    },
  };
};

const setInsuranceRisksData = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceRisks: {
      ...state.insuranceRisks,
      insuranceRisksTableData: payload.data,
      insuranceRisksPending: payload.pending,
      insuranceRisksTotalCount: payload.count,
    },
  };
};

const setActiveTab = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    activeTab: payload.activeTab,
  };
};

const setInsTypesList = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insTypesList: payload.insTypesList,
  };
};

const setInsuranceTypesPage = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceTypes: {
      ...state.insuranceTypes,
      insuranceTypesPage: payload.page,
      insuranceTypesPageSize: payload.pageSize,
    },
  };
};

const setInsuranceRisksPage = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceRisks: {
      ...state.insuranceRisks,
      insuranceRisksPage: payload.page,
      insuranceRisksPageSize: payload.pageSize,
    },
  };
};

const setInsuranceTypesSorting = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceTypes: {
      ...state.insuranceTypes,
      insuranceTypesSorting: payload.sorting,
    },
  };
};

const setInsuranceRisksSorting = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    insuranceRisks: {
      ...state.insuranceRisks,
      insuranceRisksSorting: payload.sorting,
    },
  };
};

const setFiltersInfo = (state: RegisterTypes, { payload }: AnyAction): RegisterTypes => {
  return {
    ...state,
    filtersInfo: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setInsuranceCompanies,
    setInsuranceRules,
    setInsuranceTypesData,
    setInsuranceRisksData,
    setActiveTab,
    setInsTypesList,
    setInsuranceTypesPage,
    setInsuranceRisksPage,
    setInsuranceTypesSorting,
    setInsuranceRisksSorting,
    setFiltersInfo,
  },
});
