import React from 'react';
import { Table as TableAntd } from 'antd';
import { TableProps } from 'antd/lib/table';
import { css, cx } from '@emotion/css';

import pallete from 'styles/pallete';

const cssRoot = css`
  /* .ant-table-tbody {
    .ant-table-row {
      :nth-child(even) {
        background: ${pallete.gray.lighter};
      }
    }
  } */

  .ant-table-footer {
    padding: 0;
  }
`;

interface Props<T> extends TableProps<T> {}

export function Table<T extends object>({ className, ...props }: Props<T>) {
  return <TableAntd className={cx(cssRoot, className)} bordered pagination={false} {...props} />;
}
