import React from 'react';

import { Layout } from 'antd';
import { css } from '@emotion/css';
import pallete from 'styles/pallete';
import { ClientHeader } from './ClientHeader/ClientHeader';
import { ClientFooter } from './ClientFooter/ClientFooter';
import SideMenu from './SideMenu/SideMenu';

const { Content, Header, Footer } = Layout;

interface Props {
  children: React.ReactNode;
}

const cssLayout = css`
  display: flex;
  min-height: 100vh;
`;

const cssMenuBackground = css`
  background: ${pallete.blueGray.light};
  margin-left: 80px;
  width: calc(100% - 80px);
`;

const cssContent = css`
  display: inline-block;
  padding: 0 30px;
  overflow-y: hidden;
`;

const cssHeader = css`
  height: 76px;
  padding: 14px 24px;
  background-color: inherit;
  position: sticky;
  top: 0;
  z-index: 1;
  width: '100%';
`;
const cssFooter = css`
  padding: 8px 40px;
  background-color: inherit;
  position: sticky;
  bottom: 0;
  z-index: 5;
  width: '100%';
`;

export const ClientLayout = (props: Props) => {
  return (
    <Layout className={cssLayout}>
      <SideMenu />
      <Layout className={cssMenuBackground}>
        <Header className={cssHeader}>
          <ClientHeader />
        </Header>
        <Content className={cssContent}>{props.children}</Content>
        <Footer className={cssFooter}>
          <ClientFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};
