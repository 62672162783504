export default [
  '#AC89BF',
  '#DF92BE',
  '#F488A8',
  '#FB8068',
  '#FEC777',
  '#FAF08E',
  '#C2DB8A',
  '#A8D594',
  '#ADDAC6',
  '#95CFF3',
];
