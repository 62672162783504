export const changeDocumentDelete = (arr, id) =>
  arr.map((document) => {
    if (document.documentType.id === id) {
      return {
        ...document,
        attachment: false,
        receiptDate: null,
      };
    }
    return document;
  });
