import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { IHistory } from 'types/appointments';
import { Hospital, CallDefault } from 'components/Icons';
import { StatusKey } from './enum';

import {
  Wrapper,
  TimeWrapper,
  Time,
  EventBody,
  ServiceWrapper,
  StatusWrapper,
  CanceledStatus,
  CompletedStatus,
  PendingStatus,
  PlannedStatus,
  ServiceName,
  InstitutionWrapper,
  IconWrapper,
  InstitutionText,
} from './Event.styled';

interface IEventProps {
  event: IHistory;
  onSubeventClick: (event: IHistory) => void;
}

const Event: React.FC<IEventProps> = ({ event, onSubeventClick }) => {
  const { t } = useTranslation();

  const renderStatus = () => {
    switch (event.status) {
      case StatusKey.CANCELED:
        return <CanceledStatus>{t('history.status_canceled')}</CanceledStatus>;
      case StatusKey.WAITING_FOR_CANCELLATION:
        return <CanceledStatus>{t('history.status_waiting_for_canceled')}</CanceledStatus>;
      case StatusKey.COMPLETED:
        return <CompletedStatus>{t('history.status_completed')}</CompletedStatus>;
      case StatusKey.PENDING:
        return <PendingStatus>{t('history.status_pending')}</PendingStatus>;
      case StatusKey.PLANNED:
        return <PlannedStatus>{t('history.status_planned')}</PlannedStatus>;
      default:
        break;
    }
  };

  const handleEventClick = (ev) => {
    onSubeventClick(ev);
  };

  return (
    <Wrapper>
      <TimeWrapper>
        <Time>{moment(event.meetingTime).format('HH:mm')}</Time>
        {event.duration && (
          <Time>
            {moment(event.meetingTime)
              .add(Number(event.duration) / 60000, 'minutes')
              .format('HH:mm')}
          </Time>
        )}
      </TimeWrapper>
      <EventBody onClick={() => handleEventClick(event)}>
        <ServiceWrapper>
          <StatusWrapper>
            {renderStatus()}
            {event.isOnline && <CallDefault />}
          </StatusWrapper>
          <ServiceName>{event.serviceName}</ServiceName>
        </ServiceWrapper>
        <InstitutionWrapper>
          <IconWrapper>
            <Hospital className="hospital_icon" />
          </IconWrapper>
          <InstitutionText>{event.institutionName}</InstitutionText>
        </InstitutionWrapper>
      </EventBody>
    </Wrapper>
  );
};

export default Event;
