import { ThunkAction } from 'redux-thunk';
import { DeepPartial } from 'utility-types';
import { mergePartially } from 'merge-partially';
import dayjs from 'dayjs';
import { notification } from 'antd';
import { t } from 'i18next';
import { generatePath } from 'react-router';

import history from 'routes/history';
import { AppState } from 'store/reducer';
import { ActionTypes, ErrorsDto, List, Language, ProgramCode } from 'types';
import {
  CalculatedInsuranceObjectDto,
  ContractCalculationRequestDto,
  ContractCalculationResultDto,
  ContractClaimDetailsDto,
  ContractDto,
  ContractIntegrationHistoryDto,
  ContractTerminationDetailsDto,
  CounterpartyDetailsView,
  InsuranceCompanyDto,
  List6Params,
  ListActivitiesParams,
  PaymentListParams,
  ResultListDtoContractPaymentView,
  ResultListDtoContractView,
  ResultListDtoUserActivityLogView,
  SendContractReportParams,
  VehicleObjectDto,
  DiaDeepLinkResponseDto,
  DiaDocumentsMetadataDto,
  SendingType,
  ParsedInsuredPersonDto,
  DownloadInsuredPersonListParams,
} from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import {
  CityZoneDto,
  InsuranceProgramDetailsDto,
  InsuranceRateDto,
  InsuranceSumDto,
  VehicleView,
  InsurancePolicyDto,
  Currency,
  FindContractNumberFormatParams,
  ContractNumberFormat,
} from 'types/dto/configuration-service';
import { identifierMap } from 'screens/ContractDetailsNext/ContractDetails.utils';
import config from 'config';
import { getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { apiConfiguration } from 'api/configuration';
import { poll } from 'utils/helpers';
import { ROUTES } from 'constants/routes';
import onDownload from 'callbacks/onDownload';
import { dateFormat } from 'utils/formatters';
import { apiLocations } from 'api/locations';
import { LocalityView, CountryView } from 'types/dto/location-service';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data {
  loading: boolean;
  data: ContractDto | null;
  loadingContractNumber: boolean;
  loadingSave: boolean;
  loadingVerify: boolean;
  loadingVerifyWidraw: boolean;
  loadingSignWidraw: boolean;
  loadingVehicleDetails: boolean;
  loadingSendEmailPayment: boolean;
  loadingCancelSendingEmail: boolean;
  loadingContactObjects: boolean;
}
interface Calculation {
  loading: boolean;
  data: CalculatedInsuranceObjectDto[];
}
interface Payments extends List {
  loading: boolean;
  data: ResultListDtoContractPaymentView | null;
}
interface Repayments {
  loading: boolean;
  data: ContractClaimDetailsDto[];
}
interface Terminations {
  loading: boolean;
  data: ContractTerminationDetailsDto[];
}
interface SubAgreements {
  loading: boolean;
  data: ResultListDtoContractView | null;
}
interface ExportImport {
  loading: boolean;
  data: ContractIntegrationHistoryDto[];
}
interface UsersActivity extends List {
  loading: boolean;
  data: ResultListDtoUserActivityLogView | null;
}
interface InsurancePrograms {
  loading: boolean;
  data: InsuranceProgramDetailsDto[];
  program: InsuranceProgramDetailsDto | null;
  policy: InsurancePolicyDto | null;
  rate: InsuranceRateDto | null;
  currencies: Currency[];
}
interface InsuranceCompanies {
  loading: boolean;
  data: InsuranceCompanyDto[];
}
interface Models {
  loading: boolean;
  data: VehicleView[];
}
interface Countries {
  loading: boolean;
  data: CountryView[];
}
interface Counterparties {
  loading: boolean;
  data: CounterpartyDetailsView[];
}
interface Locations {
  loading: boolean;
  data: LocalityView[];
}
interface CityZones {
  loading: boolean;
  data: CityZoneDto[];
}

interface SetDataOptions {
  fullMerge?: boolean;
}

interface DiaDeepLink {
  loading: boolean;
  data: DiaDeepLinkResponseDto | null;
}

interface DiaDocumentsMetadata {
  loading: boolean;
  data: DiaDocumentsMetadataDto | null;
}

export const actions = {
  reset: () => ({ type: 'CONTRACT/RESET' } as const),
  setData: (payload: DeepPartial<Data>, options?: SetDataOptions) =>
    ({ type: 'CONTRACT/SET_DATA', payload, options } as const),
  setCalculation: (payload: Partial<Calculation>) => ({ type: 'CONTRACT/SET_CALCULATION', payload } as const),
  setPayments: (payload: Partial<Payments>) => ({ type: 'CONTRACT/SET_PAYMENTS', payload } as const),
  setRepayments: (payload: Partial<Repayments>) => ({ type: 'CONTRACT/SET_REPAYMENTS', payload } as const),
  setTerminations: (payload: Partial<Terminations>) => ({ type: 'CONTRACT/SET_TERMINATIONS', payload } as const),
  setSubAgreements: (payload: Partial<SubAgreements>) => ({ type: 'CONTRACT/SET_SUB_AGREEMENTS', payload } as const),
  setExportImport: (payload: Partial<ExportImport>) => ({ type: 'CONTRACT/SET_EXPORT_IMPORT', payload } as const),
  setUsersActivity: (payload: Partial<UsersActivity>) => ({ type: 'CONTRACT/SET_USERS_ACTIVITY', payload } as const),
  setInsurancePrograms: (payload: Partial<InsurancePrograms>) =>
    ({ type: 'CONTRACT/SET_INSURANCE_PROGRAMS', payload } as const),
  setInsuranceCompanies: (payload: Partial<InsuranceCompanies>) =>
    ({ type: 'CONTRACT/SET_INSURANCE_COMPANIES', payload } as const),
  setModels: (payload: Partial<Models>) => ({ type: 'CONTRACT/SET_MODELS', payload } as const),
  setCountries: (payload: Partial<Countries>) => ({ type: 'CONTRACT/SET_COUNTRIES', payload } as const),
  setCounterparties: (payload: Partial<Counterparties>) => ({ type: 'CONTRACT/SET_COUNTERPARTIES', payload } as const),
  setLocations: (payload: Partial<Locations>) => ({ type: 'CONTRACT/SET_LOCATIONS', payload } as const),
  setCityZones: (payload: Partial<CityZones>) => ({ type: 'CONTRACT/SET_CITY_ZONES', payload } as const),
  setDeepLink: (payload: Partial<DiaDeepLink>) => ({ type: 'CONTRACT/SET_DEEP_LINK', payload } as const),
  setDocumentsMetadata: (payload: Partial<DiaDocumentsMetadata>) =>
    ({ type: 'CONTRACT/SET_DOCUMENTS_METADATA', payload } as const),
};

export const getData =
  (id: number): ThunkType<void> =>
  async (dispatch) => {
    try {
      dispatch(actions.setData({ loading: true }));

      const { data } = await apiContracts.contractController.getDetails(id);

      dispatch(actions.setData({ loading: false, data }, { fullMerge: true }));
    } catch (error) {
      dispatch(actions.setData({ loading: false }));
    }
  };

export const requestPayments =
  (params: PaymentListParams = {}): ThunkType<Promise<ResultListDtoContractPaymentView>> =>
  async (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;
    const payments = getState().contractDetailsNext.payments;
    const sorting = getSortBy(payments.sorting);

    return apiContracts.contractPaymentController
      .paymentList({
        count: true,
        total: true,
        totalAttributes: 'expectedPaymentAmount,paymentAmount,leftToPay',
        attributes:
          'id,periodStartDate,periodEndDate,expectedPaymentAmount,expectedPaymentDate,paymentAmount,paymentDate,checkNumber,leftToPay,contractId,insuranceCompanyId,partnerId,partnerDepartmentId',
        contractId: (contract?.prolongation
          ? contract?.id ?? 0
          : contract?.originalContractId ?? contract?.id ?? 0
        ).toString(),
        sorting: sorting ? `${sorting},id` : 'id',
        ...getPrivateFilters(payments.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const getPayments = (): ThunkType<void> => async (dispatch, getState) => {
  try {
    dispatch(actions.setPayments({ loading: true }));

    const res = await dispatch(requestPayments());

    dispatch(actions.setPayments({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setPayments({ loading: false }));
  }
};

export const getRepayments = (): ThunkType<void> => async (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  try {
    dispatch(actions.setRepayments({ loading: true }));

    const res = await apiContracts.contractController.getClaimDetails(contract?.id ?? 0);

    dispatch(actions.setRepayments({ loading: false, data: res.data }));
  } catch (error) {
    dispatch(actions.setRepayments({ loading: false }));
  }
};

export const getTerminations = (): ThunkType<void> => async (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  try {
    dispatch(actions.setTerminations({ loading: true }));

    const res = await apiContracts.contractController.getTerminationDetails(contract?.id ?? 0);

    dispatch(actions.setTerminations({ loading: false, data: res.data ? [res.data] : [] }));
  } catch (error) {
    dispatch(actions.setTerminations({ loading: false }));
  }
};

export const getSubAgreements = (): ThunkType<void> => async (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  try {
    dispatch(actions.setSubAgreements({ loading: true }));

    const res = await apiContracts.contractController.listSubAgreements({
      distinct: true,
      sorting: '-startDate,-id',
      originalContractId: (contract?.originalContractId ?? contract?.id ?? 0).toString(),
      id: `<>${contract?.originalContractId ?? contract?.id}`,
    });

    dispatch(actions.setSubAgreements({ loading: false, data: res.data }));
  } catch (error) {
    dispatch(actions.setSubAgreements({ loading: false }));
  }
};

export const getExportImport = (): ThunkType<void> => async (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  try {
    dispatch(actions.setExportImport({ loading: true }));

    const res = await apiContracts.contractController.getIntegrationHistory(
      contract?.originalContractId ?? contract?.id ?? 0,
    );

    dispatch(actions.setExportImport({ loading: false, data: res.data }));
  } catch (error) {
    dispatch(actions.setExportImport({ loading: false }));
  }
};

export const requestUsersActivity =
  (params: ListActivitiesParams = {}): ThunkType<Promise<ResultListDtoUserActivityLogView>> =>
  async (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;
    const usersActivity = getState().contractDetailsNext.usersActivity;
    const sorting = getSortBy(usersActivity.sorting);

    return apiContracts.userController
      .listContractActivities({
        count: true,
        attributes:
          'shortName,activityDate,login,partnerDepartmentName,partnerName,role,action,entityId,partnerId,partnerDepartmentId,details,activityDate',
        contractId: (contract?.originalContractId ?? contract?.id ?? 0).toString(),
        sorting,
        ...getPrivateFilters(usersActivity.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const getUsersActivity = (): ThunkType<void> => async (dispatch, getState) => {
  const usersActivity = getState().contractDetailsNext.usersActivity;

  try {
    dispatch(actions.setUsersActivity({ loading: true }));

    const res = await dispatch(requestUsersActivity({ ...getPageBy(usersActivity.pagination) }));

    dispatch(actions.setUsersActivity({ loading: false, data: res }));
  } catch (error) {
    dispatch(actions.setUsersActivity({ loading: false }));
  }
};

export const getInsurancePrograms =
  (): ThunkType<Promise<InsuranceProgramDetailsDto[]>> => async (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;
    const tenantParsed = getState().me.data?.decoded?.tenantParsed;

    try {
      dispatch(actions.setInsurancePrograms({ loading: true }));

      const period = dayjs().format('YYYY-MM-DD');

      const res = await apiConfiguration.insuranceProgramController.listDetails({
        distinct: true,
        visible: String(true),
        insuranceProductCode: contract?.insuranceProductCode ?? '',
        partnerId: contract?.partnerId?.toString(),
        partnerProgramActivationDate: `<=${period}`,
        partnerProgramDeactivationDate: `>=${period}`,
        applicablePeriodFrom: `<=${period}`,
        applicablePeriodTo: `>${period}`,
        prolongateFromId: 'null',
        insuranceCompanyId: tenantParsed?.insuranceCompanyId?.toString(),
        page_size: -1,
      });

      dispatch(actions.setInsurancePrograms({ loading: false, data: res.data }));

      return res.data;
    } catch (error) {
      dispatch(actions.setInsurancePrograms({ loading: false }));

      return [];
    }
  };

interface ContractNumber {
  contractNumberMainPart?: string;
  year?: string;
  identifier?: string;
  contractNumberPrefix?: string;
  contractNumberEnding?: string;
}

export const getContractNumber =
  (contractNumberFormat?: ContractNumberFormat, options?: ContractNumber): ThunkType<void> =>
  (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;

    const year = options?.year ?? dayjs().format('YY');
    const identifier =
      options?.identifier ??
      identifierMap[contract?.insuranceProductCode as ProgramCode] ??
      identifierMap.default ??
      '';
    const contractNumberPrefix = options?.contractNumberPrefix ?? contract?.contractNumberPrefix ?? '';
    const contractNumberMainPart = options?.contractNumberMainPart ?? contract?.contractNumberMainPart ?? '';
    const contractNumberEnding = options?.contractNumberEnding ?? contract?.contractNumberEnding ?? '';

    const contractNumberMap: Record<ContractNumberFormat, string> = {
      GENERAL_TEN_DIGITS: `${year}.${identifier}.${contractNumberPrefix}${contractNumberMainPart}${contractNumberEnding}`,
      CUSTOM_FIVE_DIGITS: `unknown.${contractNumberEnding}.${year}.${identifier}.${contractNumberMainPart}.unknown`,
      WITHOUT_TEMPLATE: `${contractNumberMainPart}`,
      SPECIFIC_ALPHA_NUMERIC: `${contractNumberMainPart}`,
      CUSTOM_NINE_DIGITS: `${year}.${identifier}.${contractNumberPrefix}${contractNumberMainPart}${contractNumberEnding}`,
    };

    const contractNumber = contractNumberMap[contractNumberFormat ?? ContractNumberFormat.GENERAL_TEN_DIGITS];

    dispatch(actions.setData({ data: { contractNumber } }));
  };

/**
 * @param insuranceProgramCode ProgramCode enum
 */
export const generateContractNumberMainPart =
  (insuranceProgramCode: string): ThunkType<Promise<string>> =>
  (dispatch, getState) => {
    dispatch(actions.setData({ loadingContractNumber: true }));

    return apiContracts.contractController
      .generateContractNumber({ insuranceProgramCode })
      .then((res) => res.data)
      .catch((error) => {
        dispatch(actions.setData({ loadingContractNumber: false }));

        if (error !== undefined) {
          notification.error({ message: error });
        }

        return Promise.reject(undefined);
      })
      .finally(() => {
        dispatch(actions.setData({ loadingContractNumber: false }));
      });
  };

export const getContractNumberFormat =
  (query: FindContractNumberFormatParams): ThunkType<void> =>
  (dispatch, getState) => {
    apiConfiguration.partnerProgramController
      .findContractNumberFormat(query)
      .then((res) => {
        dispatch(actions.setData({ data: { contractNumberFormat: res.data } }));
      })
      .catch((error) => {
        if (error !== undefined) {
          notification.error({ message: error });
        }

        return Promise.reject();
      });
  };

export const getInsuranceCompanies = (): ThunkType<void> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  dispatch(actions.setInsuranceCompanies({ loading: true }));

  apiContracts.insuranceCompanyController
    .read5(contract?.insuranceCompanyId ?? 0)
    .then((res) => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: [res.data] }));
    })
    .catch((error) => {
      dispatch(actions.setInsuranceCompanies({ loading: false }));
    });
};

export const getModels =
  (query: { fullName?: string; markName?: string; modelName?: string }): ThunkType<Promise<VehicleView[]>> =>
  (dispatch) => {
    dispatch(actions.setModels({ loading: true }));

    return (
      apiConfiguration.mtsbuDictionaryController
        // TODO back
        .listVehicles(query as any)
        .then((res) => {
          dispatch(actions.setModels({ loading: false, data: res.data.resultList }));

          return res.data.resultList ?? [];
        })
        .catch(() => {
          dispatch(actions.setModels({ loading: false }));

          return [];
        })
    );
  };

export const getCountries = (): ThunkType<void> => (dispatch) => {
  dispatch(actions.setCountries({ loading: true }));
  const params = { page_size: -1 };

  return apiLocations.countriesController
    .list(params)
    .then((res) => {
      dispatch(actions.setCountries({ loading: false, data: res.data.resultList }));
    })
    .catch(() => {
      dispatch(actions.setCountries({ loading: false }));
    });
};

export const getCounterparties =
  (query: List6Params): ThunkType<Promise<CounterpartyDetailsView[] | void>> =>
  (dispatch) => {
    dispatch(actions.setCounterparties({ loading: true }));

    return apiContracts.counterpartyController
      .list6(query)
      .then(({ data }) => {
        dispatch(actions.setCounterparties({ loading: false, data: data.resultList }));
        return data.resultList;
      })
      .catch((error) => {
        dispatch(actions.setCounterparties({ loading: false, data: [] }));
      });
  };

export const calculationContract =
  (data: ContractCalculationRequestDto): ThunkType<Promise<ContractCalculationResultDto>> =>
  (dispatch, getState) => {
    dispatch(actions.setCalculation({ loading: true }));

    return apiContracts.contractController
      .contractCalculations(data)
      .then(({ data }) => {
        dispatch(actions.setCalculation({ loading: false, data: data.objects ?? [] }));

        return data;
      })
      .catch((error) => {
        dispatch(actions.setCalculation({ loading: false }));

        if (error === undefined) {
          return Promise.reject(undefined);
        }

        notification.error({ message: error.message });

        return Promise.reject(undefined);
      });
  };

export const revokeContractFromMTSBU = (): ThunkType<void> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  apiContracts.mtsbuIntegrationController
    .resetContractReservation(contract?.id ?? 0)
    .then(({ data }) => {
      notification.success({ message: 'revoked succesfully' });
      dispatch(actions.setData({ data }, { fullMerge: true }));
    })
    .catch((error) => {
      if (error === undefined) {
        return;
      }

      notification.error({ message: error.message });
    });
};

export const declareContractToMTSBU = (): ThunkType<Promise<ContractDto>> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  return apiContracts.mtsbuIntegrationController
    .newDigitalReserve(contract?.id ?? 0)
    .then(({ data }) => {
      notification.success({ message: 'Sent successfully' });
      dispatch(actions.setData({ data }, { fullMerge: true }));

      return data;
    })
    .catch((error) => {
      if (error === undefined) {
        return Promise.reject(undefined);
      }

      notification.error({ message: error.message });

      return Promise.reject(undefined);
    });
};

export const createContract =
  (seed: ContractDto): ThunkType<Promise<ContractDto>> =>
  (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;

    dispatch(actions.setData({ loadingSave: true }));

    return apiContracts.contractController
      .create5(seed)
      .then(({ data }) => {
        notification.success({ message: t('popup.created') });
        history.replace(generatePath(ROUTES.CONTRACTS_LIST.DETAILS, { id: data.id }));

        dispatch(actions.setData({ data, loadingSave: false }, { fullMerge: true }));

        return data;
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingSave: false }));

        if (error === undefined) {
          return Promise.reject(undefined);
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            if (err.code === 'INVALID_USER_ROLE_FOR_RE_REGISTRATION') {
              notification.error({ message: t('popup.INVALID_USER_ROLE_FOR_RE_REGISTRATION') });
              return;
            }

            if (err.code === 'PHONE_NUMBER_IS_NOT_ALLOWED') {
              notification.error({ message: t('popup.PHONE_NUMBER_IS_NOT_ALLOWED') });
              return;
            }

            if (err.code === 'NOT_UNIQUE_COUNTERPARTY_TYPE_AND_PHONE_NUMBER') {
              notification.error({ message: t('popup.NOT_UNIQUE_COUNTERPARTY_TYPE_AND_PHONE_NUMBER') });
              return;
            }

            if (err.code === 'NOT_UNIQUE_COUNTERPARTY_TYPE_AND_EMAIL') {
              notification.error({ message: t('popup.NOT_UNIQUE_COUNTERPARTY_TYPE_AND_EMAIL') });
              return;
            }

            if (err.code === 'NOT_UNIQUE') {
              notification.error({ message: t('popup.NOT_UNIQUE') });
              return;
            }

            notification.error({ message: err.code });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const updateContract =
  (seed: ContractDto): ThunkType<Promise<ContractDto>> =>
  (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;

    dispatch(actions.setData({ loadingSave: true }));

    return apiContracts.contractController
      .update7(contract?.id ?? 0, seed)
      .then(({ data }) => {
        notification.success({ message: t('popup.updated') });

        dispatch(actions.setData({ data, loadingSave: false }, { fullMerge: true }));

        return data;
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingSave: false }));

        if (error === undefined) {
          return Promise.reject(undefined);
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            if (err.code === 'INVALID_USER_ROLE_FOR_RE_REGISTRATION') {
              notification.error({ message: t('popup.INVALID_USER_ROLE_FOR_RE_REGISTRATION') });
              return;
            }

            if (err.code === 'PHONE_NUMBER_IS_NOT_ALLOWED') {
              notification.error({ message: t('popup.PHONE_NUMBER_IS_NOT_ALLOWED') });
              return;
            }

            if (err.code === 'NOT_UNIQUE_COUNTERPARTY_TYPE_AND_PHONE_NUMBER') {
              notification.error({ message: t('popup.NOT_UNIQUE_COUNTERPARTY_TYPE_AND_PHONE_NUMBER') });
              return;
            }

            if (err.code === 'NOT_UNIQUE_COUNTERPARTY_TYPE_AND_EMAIL') {
              notification.error({ message: t('popup.NOT_UNIQUE_COUNTERPARTY_TYPE_AND_EMAIL') });
              return;
            }

            if (err.code === 'CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT') {
              notification.error({ message: t('popup.CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT') });
              return;
            }

            if (err.code === 'NOT_UNIQUE') {
              notification.error({ message: t('popup.NOT_UNIQUE') });
              return;
            }

            notification.error({ message: err.code });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const verifyContract =
  (contract: ContractDto): ThunkType<Promise<ContractDto>> =>
  (dispatch, getState) => {
    dispatch(actions.setData({ loadingVerify: true }));

    return apiContracts.contractController
      .verifyContract(contract.id ?? 0, contract)
      .then(({ data }) => {
        notification.success({ message: t('popup.contract_verified_successfully') });

        dispatch(actions.setData({ data, loadingVerify: false }, { fullMerge: true }));

        history.replace(generatePath(ROUTES.CONTRACTS_LIST.DETAILS, { id: data.id }));

        return data;
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingVerify: false }));

        if (error === undefined) {
          return Promise.reject(undefined);
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: t(`error_code.${err.code}`) });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const withdrawVerifyContract = (): ThunkType<Promise<ContractDto>> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  dispatch(actions.setData({ loadingVerifyWidraw: true }));

  return apiContracts.contractController
    .withdrawVerify(contract?.originalContractId ?? contract?.id ?? 0)
    .then(({ data }) => {
      notification.success({ message: t('popup.animal_removed') });

      dispatch(actions.setData({ data, loadingVerifyWidraw: false }, { fullMerge: true }));

      return data;
    })
    .catch((error) => {
      dispatch(actions.setData({ loadingVerifyWidraw: false }));

      if (error === undefined) {
        return Promise.reject(undefined);
      }

      if (Array.isArray(error?.response?.data.errors)) {
        (error?.response?.data as ErrorsDto).errors.forEach((err) => {
          notification.error({ message: t(`error_code.${err.code}`) });
        });
      } else {
        notification.error({ message: error.message });
      }

      return Promise.reject(undefined);
    });
};

export const withdrawSignContract = (): ThunkType<Promise<void>> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  dispatch(actions.setData({ loadingSignWidraw: true }));

  return apiContracts.contractController
    .withdrawSign(contract?.id ?? 0)
    .then(() => {
      notification.success({ message: t('popup.Signature_removed') });

      dispatch(actions.setData({ loadingSignWidraw: false }));
    })
    .catch((error) => {
      dispatch(actions.setData({ loadingSignWidraw: false }));

      if (error === undefined) {
        return;
      }

      if (Array.isArray(error?.response?.data.errors)) {
        (error?.response?.data as ErrorsDto).errors.forEach((err) => {
          if (err.code === 'SIGN_WITHDRAWAL_IMPOSSIBLE') {
            notification.error({ message: t('popup.SIGN_WITHDRAWAL_IMPOSSIBLE') });

            return;
          }

          notification.error({ message: err.code });
        });
      } else {
        notification.error({ message: error.message });
      }
    });
};

export const getPdfReport =
  (language?: string): ThunkType<void> =>
  (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;

    onDownload(() =>
      apiContracts.contractController
        .printPdfReport1({ id: contract?.id ?? 0, timeZone: dayjs.tz.guess(), language }, { format: 'blob' })
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

export const sendContractReport =
  (query: SendContractReportParams): ThunkType<void> =>
  (dispatch, getState) => {
    apiContracts.contractController
      .sendContractReport(query)
      .then(() => {
        notification.success({ message: t('contract_details.offer_submitted_successfully') });
      })
      .catch((err) => {
        notification.error({ message: t('contract_details.error_sending_offer') });
      });
  };

export const sendOffer = (): ThunkType<void> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  apiContracts.contractController
    .sendOffer(contract?.id ?? 0)
    .then(() => {
      notification.success({ message: t('contract_details.offer_submitted_successfully') });
    })
    .catch((err) => {
      notification.error({ message: t('contract_details.error_sending_offer') });
    });
};

export const sendCommonOffer =
  ({
    phoneNumber,
    sendingType,
    language,
  }: {
    phoneNumber: string;
    sendingType: SendingType;
    language?: string;
  }): ThunkType<void> =>
  (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;

    apiContracts.insuranceProgramController
      .sendCommonOffer({
        insuranceProgramId: contract?.insuranceProgram?.id ?? 0,
        contractId: contract?.id ?? 0,
        phoneNumber,
        sendingType,
        language,
      })
      .then(() => {
        notification.success({ message: t('contract_details.offer_submitted_successfully') });
      })
      .catch((err) => {
        notification.error({ message: t('contract_details.error_sending_offer') });
      });
  };

export const createCaseStatement = (): ThunkType<void> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  apiContracts.contractClaimController
    .getTemplate1({ contractId: contract?.id ?? 0 })
    .then(() => {
      history.push(ROUTES.INSURED_EVENTS.CREATE.ROOT.replace(':contractId', (contract?.id ?? 0).toString()));
    })
    .catch((error) => {
      (error?.response?.data as ErrorsDto | undefined)?.errors.forEach((err) => {
        if (err.code === 'CONTRACT_CLAIM_CREATION_IMPOSSIBLE') {
          notification.error({
            message: '',
            description: `${t('contract_details.occurrence_insured_event_possible_earlier')} ${dateFormat(
              dayjs(contract?.conclusionDate).add(15, 'days'),
            )}`,
          });
        }
      });
    });
};

export const openTermination = (): ThunkType<void> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  const isBeforeStartDate = dayjs().isBefore(dayjs(contract?.startDate));
  const canCreate = Boolean(contract?.verifiedAt) || isBeforeStartDate;

  if (canCreate) {
    contract?.terminationId
      ? history.push(
          generatePath(ROUTES.TERMINATIONS.READ, { id: contract?.id, terminationId: contract.terminationId }),
        )
      : history.push(generatePath(ROUTES.TERMINATIONS.ROOT, { id: contract?.id }));
  } else {
    notification.error({
      message: t('contract_details.unavailable_operation'),
      description: t('contract_details.contact_manager_insurance_company'),
    });
  }
};

export const sendSmsVerification =
  ({ sendingType }: { sendingType: SendingType }): ThunkType<Promise<void>> =>
  (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;

    return apiContracts.contractController
      .sendSignVerification({ id: contract?.id ?? 0, sendingType })
      .then(() => Promise.resolve(undefined))
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject(undefined);
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: t(`error_code.${err.code}`) });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const signContract =
  ({ token, russianCitizen }: { token: string; russianCitizen?: boolean }): ThunkType<Promise<void>> =>
  (dispatch, getState) => {
    const contract = getState().contractDetailsNext.data.data;

    return apiContracts.contractController
      .signContract({ id: contract?.id ?? 0, token, russianCitizen })
      .then(() => {
        notification.success({ message: t('insured_events.signed_successfully') });
      })
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject(undefined);
        }

        notification.error({ message: t('popup.your_code_invalid') });

        return Promise.reject(undefined);
      });
  };

export const sendClientOnSign = (): ThunkType<Promise<void>> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  return apiContracts.contractController
    .sendClientOnSign({ id: contract?.id ?? 0 })
    .then((res) => {
      notification.success({ message: t('insured_events.signed_successfully') });

      return Promise.resolve(undefined);
    })
    .catch((error) => {
      if (error === undefined) {
        return Promise.reject(undefined);
      }

      notification.error({ message: error.message });

      return Promise.reject(undefined);
    });
};

export const getInvoiceReport = (): ThunkType<void> => (dispatch, getState) => {
  const contract = getState().contractDetailsNext.data.data;

  onDownload(() =>
    apiContracts.contractController
      .printInvoiceReport({ id: contract?.id ?? 0, timeZone: dayjs.tz.guess() }, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const getLocations =
  (address: string): ThunkType<Promise<LocalityView[]>> =>
  (dispatch, getState) => {
    dispatch(actions.setLocations({ loading: true }));

    return apiLocations.locationController
      .locations({ address })
      .then((res) => {
        dispatch(actions.setLocations({ loading: false, data: res.data }));

        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setLocations({ loading: false }));

        if (error === undefined) {
          return Promise.reject([]);
        }

        notification.error({ message: error.message });

        return Promise.reject([]);
      });
  };

export const getZoneByCity =
  (city: string): ThunkType<Promise<CityZoneDto[]>> =>
  (dispatch, getState) => {
    dispatch(actions.setCityZones({ loading: true }));

    return apiConfiguration.mtsbuDictionaryController
      .getZoneByCity({ city })
      .then((res) => {
        dispatch(actions.setCityZones({ loading: false, data: res.data }));

        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setCityZones({ loading: false, data: [] }));

        if (error === undefined) {
          return Promise.reject(undefined);
        }

        notification.error({ message: error.message });

        return Promise.reject(undefined);
      });
  };

export const getVehicleDetails =
  (registrationNumber: string): ThunkType<Promise<VehicleObjectDto>> =>
  (dispatch) => {
    dispatch(actions.setData({ loadingVehicleDetails: true }));

    return apiContracts.mtsbuIntegrationController
      .getVehicleDetails(registrationNumber)
      .then((res) => {
        dispatch(actions.setData({ loadingVehicleDetails: false }));

        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingVehicleDetails: false }));

        return Promise.reject(error);
      });
  };

export const getDiaDeepLink =
  (phoneNumber?: string): ThunkType<Promise<DiaDeepLinkResponseDto>> =>
  (dispatch, getState) => {
    dispatch(actions.setDeepLink({ loading: true }));

    return apiContracts.diaIntegrationController
      .getDeepLink({ phoneNumber })
      .then((res) => {
        dispatch(actions.setDeepLink({ loading: false, data: res.data }));
        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setDeepLink({ loading: false, data: null }));

        if (error === undefined) {
          return Promise.reject(undefined);
        }

        return Promise.reject(undefined);
      });
  };

export const poolingDIAMetadata = (): ThunkType<Promise<DiaDocumentsMetadataDto>> => (dispatch, getState) => {
  const requestId = getState().contractDetailsNext.diaDeepLink.data?.requestId;
  dispatch(actions.setDocumentsMetadata({ loading: true }));

  if (requestId) {
    return poll<DiaDocumentsMetadataDto>({
      cb: () =>
        apiContracts.diaIntegrationController.getDocumentsMetadata(requestId).then((res) => {
          dispatch(actions.setDocumentsMetadata({ data: res.data }));
          return res.data;
        }),
      predicate: (res) => !!Object.keys(res).length,
      interval: 5 * 1000,
      timeout: config.DIA_TIMEOUT,
    }).finally(() => {
      dispatch(actions.setDocumentsMetadata({ loading: false }));
    });
  }
  return Promise.reject(undefined);
};

export const getContractObjects =
  (query: DownloadInsuredPersonListParams, file: File): ThunkType<Promise<ParsedInsuredPersonDto[]>> =>
  (dispatch, getState) => {
    dispatch(actions.setData({ loadingContactObjects: true }));

    return apiContracts.parseFileController
      .downloadInsuredPersonList(query, { file })
      .then((res) => {
        dispatch(actions.setData({ loadingContactObjects: false }));

        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingContactObjects: false }));

        return Promise.reject(error);
      });
  };

export const sendPaymentEmail =
  (id: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setData({ loadingSendEmailPayment: true }));

    return apiContracts.notificationController
      .sendPaymentEmail(id)
      .then(() => {
        dispatch(actions.setData({ loadingSendEmailPayment: false }));
        notification.success({ message: t('contract_details.send_payment_email') });
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingSendEmailPayment: false }));
        notification.success({ message: t('validation.something_went_wrong') });
      });
  };

export const cancelPaymentEmailSending =
  (id: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setData({ loadingCancelSendingEmail: true }));

    return apiContracts.notificationController
      .cancelPaymentEmailSending(id)
      .then(() => {
        dispatch(actions.setData({ loadingCancelSendingEmail: false }));
        notification.success({ message: t('contract_details.cancel_sending_email') });
        return Promise.resolve(undefined);
      })
      .catch((error) => {
        dispatch(actions.setData({ loadingCancelSendingEmail: false }));
        notification.success({ message: t('validation.something_went_wrong') });
        return Promise.reject(error);
      });
  };

export interface StoreContract {
  data: Data;
  calculation: Calculation;
  payments: Payments;
  repayments: Repayments;
  terminations: Terminations;
  subAgreements: SubAgreements;
  exportImport: ExportImport;
  usersActivity: UsersActivity;
  programs: InsurancePrograms;
  insuranceCompanies: InsuranceCompanies;
  models: Models;
  countries: Countries;
  counterparties: Counterparties;
  locations: Locations;
  cityZones: CityZones;
  diaDeepLink: DiaDeepLink;
  diaDocumentsMetadata: DiaDocumentsMetadata;
}
export const initialState: StoreContract = {
  data: {
    loading: true,
    data: null,
    loadingContractNumber: false,
    loadingSave: false,
    loadingVerify: false,
    loadingVerifyWidraw: false,
    loadingSignWidraw: false,
    loadingVehicleDetails: false,
    loadingSendEmailPayment: false,
    loadingCancelSendingEmail: false,
    loadingContactObjects: false,
  },
  calculation: { loading: false, data: [] },
  payments: { loading: false, data: null },
  repayments: { loading: false, data: [] },
  terminations: { loading: false, data: [] },
  subAgreements: { loading: false, data: null },
  exportImport: { loading: false, data: [] },
  usersActivity: { loading: false, data: null, pagination: { page: 1, pageSize: config.ui.pagination.size } },
  programs: {
    loading: false,
    data: [],
    program: null,
    policy: null,
    rate: null,
    currencies: [],
  },
  insuranceCompanies: { loading: false, data: [] },
  models: { loading: false, data: [] },
  countries: { loading: false, data: [] },
  counterparties: { loading: false, data: [] },
  locations: { loading: false, data: [] },
  cityZones: { loading: false, data: [] },
  diaDeepLink: { loading: false, data: null },
  diaDocumentsMetadata: { loading: false, data: null },
};

const reducer = (state = initialState, action: InferActionTypes): StoreContract => {
  switch (action.type) {
    case 'CONTRACT/RESET':
      return initialState;
    case 'CONTRACT/SET_DATA':
      return {
        ...state,
        data: action.options?.fullMerge
          ? { ...state.data, ...(action.payload as Data) }
          : mergePartially.shallow(state.data, action.payload as Data),
      };
    case 'CONTRACT/SET_CALCULATION':
      return { ...state, calculation: { ...state.calculation, ...action.payload } };
    case 'CONTRACT/SET_PAYMENTS':
      return { ...state, payments: { ...state.payments, ...action.payload } };
    case 'CONTRACT/SET_REPAYMENTS':
      return { ...state, repayments: { ...state.repayments, ...action.payload } };
    case 'CONTRACT/SET_TERMINATIONS':
      return { ...state, terminations: { ...state.terminations, ...action.payload } };
    case 'CONTRACT/SET_SUB_AGREEMENTS':
      return { ...state, subAgreements: { ...state.subAgreements, ...action.payload } };
    case 'CONTRACT/SET_EXPORT_IMPORT':
      return { ...state, exportImport: { ...state.exportImport, ...action.payload } };
    case 'CONTRACT/SET_USERS_ACTIVITY':
      return { ...state, usersActivity: { ...state.usersActivity, ...action.payload } };
    case 'CONTRACT/SET_INSURANCE_PROGRAMS':
      return { ...state, programs: { ...state.programs, ...action.payload } };
    case 'CONTRACT/SET_INSURANCE_COMPANIES':
      return { ...state, insuranceCompanies: { ...state.insuranceCompanies, ...action.payload } };
    case 'CONTRACT/SET_MODELS':
      return { ...state, models: { ...state.models, ...action.payload } };
    case 'CONTRACT/SET_COUNTRIES':
      return { ...state, countries: { ...state.countries, ...action.payload } };
    case 'CONTRACT/SET_COUNTERPARTIES':
      return { ...state, counterparties: { ...state.counterparties, ...action.payload } };
    case 'CONTRACT/SET_LOCATIONS':
      return { ...state, locations: { ...state.locations, ...action.payload } };
    case 'CONTRACT/SET_CITY_ZONES':
      return { ...state, cityZones: { ...state.cityZones, ...action.payload } };
    case 'CONTRACT/SET_DEEP_LINK':
      return { ...state, diaDeepLink: { ...state.diaDeepLink, ...action.payload } };
    case 'CONTRACT/SET_DOCUMENTS_METADATA':
      return { ...state, diaDocumentsMetadata: { ...state.diaDocumentsMetadata, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
