import React, { useState } from 'react';
import ReactCalendar from 'react-calendar';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/Icons';
import Modal from '../Modal';
import 'react-calendar/dist/Calendar.css';
import './override.less';
import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash/fp';

interface CalendarProps {
  date: Date;
  dates?: string[];
  onChange: (date: Date) => void;
}

export const Calendar: React.FC<CalendarProps> = ({ date, dates, onChange }) => {
  const [value, setValue] = useState(cloneDeep(date));

  return (
    <>
      <div className="calendar-wrapper">
        <ReactCalendar
          minDate={new Date()}
          value={value}
          onChange={setValue}
          showFixedNumberOfWeeks={true}
          view="month"
          prevLabel={<ChevronLeftIcon />}
          nextLabel={<ChevronRightIcon />}
          formatMonthYear={(_: string, date: Date) => moment(date).format('MMMM YYYY')}
          formatShortWeekday={(_: string, date: Date) => moment(date).format('dd')}
          tileClassName={({ date }) => {
            if (dates && dates.find((d) => d === moment(date).format('DD-MM-YYYY'))) {
              return 'highlight';
            } else {
              return '';
            }
          }}
          prev2Label={null}
          next2Label={null}
        />
      </div>
      <Modal.Confirm onClick={() => onChange(value)} />
    </>
  );
};
