export enum TABS {
  RISKS = 'RISKS',
  TARIFFS = 'TARIFFS',
  INSURANCE_POLICIES = 'INSURANCE_POLICIES',
  COMPENSATION = 'COMPENSATION',
  DOCUMENTS = 'DOCUMENTS',
  PARAMETERS = 'PARAMETERS',
  TERMINATION = 'TERMINATION',
  SUBLIMITS = 'SUBLIMITS',
  MPI = 'MPI',
  PAYMENTS = 'PAYMENTS',
  COEFFICIENTS = 'COEFFICIENTS',
}

export enum MODALTABS {
  STANDARD = 'STANDARD',
  PERSONAL = 'PERSONAL',
}
export enum MODALSTATUS {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum PAYMENTS_TABS {
  SETTLEMENT_TYPES = 'SETTLEMENT_TYPES',
  REFUSAL_REASONS = 'REFUSAL_REASONS',
  PAYMENT_RECIPIENT = 'PAYMENT_RECIPIENT',
}

export enum TERMINATION_TABS {
  RUPTURE_INITIATOR = 'RUPTURE_INITIATOR',
  TERMINATION_DOCUMENTS = 'TERMINATION_DOCUMENTS',
}
export enum COEFFICIENTS_TABS {
  K1 = 'K1',
  K2 = 'K2',
  K3 = 'K3',
  K4 = 'K4',
  K5 = 'K5',
  K6 = 'K6',
  K7 = 'K7',
  K8 = 'K8',
  K9 = 'K9',
  K10 = 'K10',
}
