import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result } from 'antd';

import { UseHistory } from 'types';
import Button from 'components/next/Button/Button';

const Page403 = () => {
  const history = useHistory<UseHistory>();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => history.goBack()}>
          Back
        </Button>
      }
    />
  );
};

export default Page403;
