import { t } from 'i18next';
import { LabelInValue } from 'types';

import { string, refiner, object, Infer, customObject, number, nullable } from 'utils/struct';

export const schema = object({
  lastName: refiner(string(), (value) => {
    if (!value) {
      return t('schema.required');
    }

    return true;
  }),
  firstName: refiner(string(), (value) => {
    if (!value) {
      return t('schema.required');
    }

    return true;
  }),
  middleName: string(),
  company: refiner(nullable(customObject<LabelInValue<number>>()), (value) => {
    if (!value) {
      return t('schema.required');
    }

    return true;
  }),
  partnerDepartment: refiner(nullable(number()), (value) => {
    if (!value) {
      return t('schema.required');
    }

    return true;
  }),
  password: refiner(string(), (value) => {
    if (!value) {
      return t('schema.required');
    }

    if (!/^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{8,})*$/.test(value)) {
      return t('passwordRecovery.password_must_contain_at_least_8_characters').toString();
    }

    return true;
  }),
  confirmPassword: refiner(string(), (value, ctx) => {
    const { password } = ctx.branch[0] as Values;

    if (value !== password) {
      return t('passwordRecovery.passwordMatch').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  firstName: '',
  lastName: '',
  middleName: '',
  company: null,
  partnerDepartment: null,
  password: '',
  confirmPassword: '',
};
