export const getActionType = (t) => {
  return {
    IMPORT_VISIT_UKRAINE: t('contract_details.import_visit_ukraine_import_export'),
    EXPORT_1C: t('contract_details.export_1c_import_export'),
    EXPORT_CONTRACT_1C: t('contract_details.export_1c_import_export'),
    EXPORT_CLAIM_1C: t('contract_details.export_claim_1c'),
    VERIFY_CONTRACT: t('contract_details.verify_contract'),
    IMPORT_EDAK: t('contract_details.import_edak_import_export'),
    EXPORT_EDAK: t('contract_details.export_edak_import_export'),
  };
};
