import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash/fp';

import Modal from '../../components/Modal';
import Previewer from '../../components/Previewer';
import ItemsList, { builders, selectors } from '../../components/List';
import { useModal } from 'screens/Appointments/hooks/useModal';
import { IDrEleksService } from 'types/institutions';
import { Picker, WithVariants, WithErrors } from 'types/appointments';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';
import { defaultValues } from 'screens/Appointments/hooks/useForm';

export const ServiceDrEleksPicker: React.FC<Picker<IDrEleksService> & WithErrors & WithVariants<IDrEleksService>> = ({
  variants,
  error,
  defaultValue,
  onClose,
  disabled,
  clearError,
  fetchDrEleksDoctors,
  institutionId,
}) => {
  const { t } = useTranslation();

  const { isOpen, open, close, confirm, picked, onPick } = useModal(onClose, defaultValue);

  const handleServiceClick = (service) => {
    onPick(picked?.serviceID === service.serviceID ? defaultValues.serviceDrEleks : service);
  };

  const onSubmit = () => {
    clearError('service');
    clearError('serviceDrEleks');
    if (picked && institutionId) {
      fetchDrEleksDoctors && fetchDrEleksDoctors({ institutionId: institutionId, serviceId: picked.serviceID });
    }
    confirm();
  };

  return (
    <>
      <Previewer
        displayValue={defaultValue.serviceName}
        onClick={open}
        title={defaultValue.serviceName || error ? t('appointments.service') : t('appointments.selector_service')}
        disabled={disabled}
        errorMessage={error ? t('appointments.selector_service') : ''}
      />
      {isOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              onPick(defaultValue);
              close();
            }}
          >
            <Modal.Header
              title={t('appointments.services_list')}
              onClose={() => {
                onPick(defaultValue);
                close();
              }}
            />
            <Modal.Body>
              <Scrollbars autoHeight autoHeightMax={MAX_SCROLLBAR_HEIGHT}>
                <ItemsList
                  items={variants}
                  itemBuilder={builders.byServiceName}
                  selector={selectors.byServiceID(picked?.serviceID)}
                  onItemClick={handleServiceClick}
                />
              </Scrollbars>
            </Modal.Body>
            <Modal.Confirm disabled={isEqual(defaultValue, picked)} onClick={onSubmit} />
          </OutsideClickHandler>
        </Modal>
      )}
    </>
  );
};
