import { ColumnProps } from 'antd/lib/table';
import { Table } from 'components/next/Table/Table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Data } from '../DashboardSettlement';
import { useSelector } from 'react-redux';
import { AppState } from 'store/reducer';
import { css } from '@emotion/css';

interface Props {
  onChangeImei: (imei: string) => void;
  emeiWitchDublicateUnique: Data[];
  selectedImei: string;
}

const cssPagination = css`
  &.ant-table-pagination.ant-pagination {
    margin: 16px 16px;
  }
`;

const cssSelectedImei = css`
  &.ant-table-row {
    background: #ced9cc !important;
  }
`;

export const ImeiWhichDublicateTable = ({ onChangeImei, emeiWitchDublicateUnique, selectedImei }: Props) => {
  const { t } = useTranslation();

  const contractsWithDublicateImei = useSelector(
    (store: AppState) => store.dashboardSettlement.contractsWithDublicateImei,
  );

  const emeiColumns: ColumnProps<Data>[] = [
    {
      title: '№',
      key: 'index',
      width: 60,
      align: 'center',
      render: (_, r, i) => i + 1,
    },
    {
      title: 'IMEI',
      key: 'imei',
      render: (_, record) => record.imei,
    },
    {
      title: t('dashboard_settlement.quantity'),
      key: 'countContracts',
      render: (_, record) => record.contracts.length,
    },
  ];

  return (
    <Table
      pagination={
        emeiWitchDublicateUnique.length > 10
          ? {
              total: emeiWitchDublicateUnique.length,
              className: cssPagination,
            }
          : false
      }
      columns={emeiColumns}
      scroll={{ y: emeiWitchDublicateUnique.length > 5 ? 280 : 0 }}
      bordered
      rowKey={(record) => (record.imei ?? 0).toString()}
      onRow={(record) => ({
        onClick: () => {
          onChangeImei(record.imei);
        },
      })}
      rowClassName={(record) => (record.imei === selectedImei ? cssSelectedImei : '')}
      dataSource={emeiWitchDublicateUnique}
      loading={contractsWithDublicateImei.loading}
    />
  );
};
