import React from 'react';
import dayjs from 'dayjs';
import { ColumnProps } from 'antd/lib/table';
import { Table } from 'components/next/Table/Table';
import { useTranslation } from 'react-i18next';
import { ContractImeiDuplicationsView, ContractStatus } from 'types/dto/contracts-service';
import Status from '../Status/Status';
import { dateFormat } from 'utils/formatters';
import { css } from '@emotion/css';
import Menu from '../Menu/Menu';
import { generatePath, useHistory } from 'react-router-dom';
import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import { checkPermissions } from 'roles/userCan';

const cssPagination = css`
  &.ant-table-pagination.ant-pagination {
    margin: 16px 16px;
  }
`;

interface Props {
  allImeiDublicate: ContractImeiDuplicationsView[];
}

export const ContractsWithTheSameImeiTable = ({ allImeiDublicate }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();

  const contractsColumns: ColumnProps<ContractImeiDuplicationsView>[] = [
    {
      title: t('dashboard_settlement.table_status'),
      key: 'status',
      width: 80,
      align: 'center',
      render: (_, record) => <Status status={record?.status} />,
    },
    {
      title: 'IMEI',
      key: 'imei',
      width: 160,
      render: (_, record) => record.imei,
    },
    {
      title: t('dashboard_settlement.contract_number'),
      key: 'contractNumber',
      width: 200,
      render: (_, record) => record.contractNumber,
    },
    {
      title: t('dashboard_settlement.phone_number'),
      key: 'phoneNumber',
      width: 140,
      render: (_, record) => record.signatoryPhoneNumber,
    },
    {
      title: t('dashboard_settlement.insurer'),
      key: 'insurer',
      width: 120,
      render: (_, record) => record.signatoryName,
    },
    {
      title: t('dashboard_settlement.name'),
      key: 'name',
      width: 120,
      render: (_, record) => record.objectSubtypeName,
    },
    {
      title: t('dashboard_settlement.brand_and_model'),
      key: 'brandAndModel',
      width: 120,
      render: (_, record) => record.objectName,
    },
    {
      title: t('dashboard_settlement.concluded'),
      key: 'concluded',
      width: 100,
      render: (_, record) => dateFormat(dayjs(record.conclusionDate)),
    },
    {
      title: t('dashboard_settlement.insured_sum'),
      key: 'insuredSum',
      width: 100,
      render: (_, record) => `${record.insuranceSum} ${record.symbol}`,
    },
    {
      title: t('dashboard_settlement.insurance_payment'),
      key: 'insurancePayment',
      width: 100,
      render: (_, record) => `${record.paymentAmount} ${record.symbol}`,
    },
    {
      title: t('dashboard_settlement.subdivision'),
      key: 'subdivision',
      width: 200,
      render: (_, record) => record.partnerDepartmentName,
    },
    {
      key: 'actions',
      width: 50,
      align: 'center',
      render: (_, record) => {
        return <Menu record={record} />;
      },
    },
  ];

  return (
    <Table
      pagination={{
        total: allImeiDublicate ? allImeiDublicate.length : 0,
        className: cssPagination,
      }}
      columns={contractsColumns}
      scroll={{ y: 280, x: 1280 }}
      bordered
      onRow={(record) => ({
        onDoubleClick: () => {
          if (!checkPermissions('CONTRACT', 'READ')) {
            return;
          }

          history.push(generatePath(ROUTES.CONTRACTS_LIST.DETAILS, { id: record.id }));
        },
      })}
      rowKey={(record) => (record.id ?? 0).toString()}
      dataSource={allImeiDublicate}
    />
  );
};
