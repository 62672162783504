import React from 'react';
import { Pie } from '@ant-design/plots';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

import config from 'config/config';
import { InsuranceProgramStatisticsDto } from 'types/dto/contracts-service';

export interface Data {
  angleField: number;
  colorField: string;
  raw: InsuranceProgramStatisticsDto;
}

interface Props {
  data: Data[];
  currency: string;
  total: number;
}

const ByProgramChart = ({ data, currency, total }: Props) => {
  const { t } = useTranslation();

  return (
    <Pie
      data={data}
      angleField="angleField"
      colorField="colorField"
      height={350}
      appendPadding={10}
      radius={1}
      innerRadius={0.65}
      label={false}
      legend={{
        layout: 'vertical',
        position: 'left-top',
      }}
      tooltip={{
        formatter: (datum: Pick<Data, 'angleField' | 'colorField'>) => {
          const percent = ((datum.angleField / total) * 100).toFixed(0);

          return {
            name: datum.colorField,
            value: `${numeral(datum.angleField).format(config.format.financial)} ${currency} (${percent}%)`,
          };
        },
      }}
      statistic={{
        title: {
          content: t('dashboard_extend.byProgram.total'),
        },
        content: {
          formatter: (_, data: Data[]) => {
            const total = data?.reduce((r, d) => r + d.angleField, 0);

            return `${numeral(total).format('0.0a')}`;
          },
        },
      }}
      color={[
        '#ac89bf',
        '#df92be',
        '#f488a8',
        '#fb8068',
        '#fec777',
        '#faf08e',
        '#c2db8a',
        '#a8d594',
        '#addac6',
        '#95cff3',
        '#8899d1',
      ]}
      interactions={[
        {
          type: 'element-active',
        },
      ]}
      state={{
        active: {
          style: {
            stroke: '#555555',
          },
        },
      }}
    />
  );
};

const MemoizedByProgramChart = React.memo(ByProgramChart);

export default MemoizedByProgramChart;
