import React from 'react';
import { css, cx } from '@emotion/css';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

const cssRoot = ({ height }: { height: string }) => css`
  padding: 4px 11px;
  height: ${height};
`;
const cssChildren = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const cssContainer = css`
  height: 100%;
`;

export interface Props {
  children: React.ReactNode | string;
  className?: string;
  label?: string;
  name?: string;
  errors?: AnyObject;
  requiredFields?: AnyObject;
  colorBase?: ColorBase;
  errorPosition?: Position;
  classNameContainer?: string;
  /**
   * show multiple rows
   */
  multiple?: boolean;
  /**
   * responsive by height
   */
  responsive?: boolean;
}

export const TextField = ({
  children,
  name,
  errors,
  requiredFields,
  label,
  colorBase = 'none',
  className,
  errorPosition,
  classNameContainer,
  multiple,
  responsive,
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      colorBase={colorBase}
      errorPosition={errorPosition}
      className={cx({ [cssContainer]: responsive }, classNameContainer)}
    >
      <div data-test={name} className={cx(cssRoot({ height: multiple ? '' : '30px' }), className)}>
        {multiple ? children : <div className={cssChildren}>{children}</div>}
      </div>
    </InputContainer>
  );
};
