import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import { object, Infer, optional, number, date, string, nullable, refiner } from 'utils/struct';

export const schema = object({
  insuranceCompanyId: optional(nullable(number())),
  partnerId: optional(nullable(number())),
  insuranceProductId: optional(nullable(number())),
  insuranceProgramIds: optional(nullable(number())),
  startDate: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  endDate: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  insuranceCompanyId: null,
  partnerId: null,
  insuranceProductId: null,
  insuranceProgramIds: null,
  startDate: dayjs().startOf('month'),
  endDate: dayjs(),
};
