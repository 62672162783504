import { notification } from 'antd';
import { apiConfiguration } from 'api/configuration';

import { Action, Dispatch } from 'redux';
import { createTypes } from 'redux-compose-reducer';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/reducer';
import { DiscountDto, PromoCodeDto } from 'types/dto/configuration-service';
import onDownload from 'callbacks/onDownload';
import { StoreType } from 'types/store';
import { apiContracts } from 'api/contracts';
import { DiscountsTypes, ITableFilters } from 'types/discountsTypes';
import { t } from 'i18next';
import { IFilterObj } from 'screens/Discounts/components/DiscountFilter/types';
import { getFilteredBody } from 'screens/Discounts/utils';

export const TYPES = createTypes('registerTypes', [
  'setInsuranceCompanies',
  'setInsuranceRules',
  'setInsuranceTypesData',
  'setInsuranceRisksData',
  'setActiveTab',
  'setInsTypesList',
  'setInsuranceTypesPage',
  'setInsuranceRisksPage',
  'setInsuranceTypesSorting',
  'setInsuranceRisksSorting',
  'setFiltersInfo',
  'setDiscounts',
  'setAllDiscounts',
  'setModalStatus',
  'setDiscountsPage',
  'setDiscountsInfoData',
  'setDiscountTableFilters',
  'setEndDate',
  'setChoosenDiscount',
  'setChoosenPromoCodeDiscount',
  'setPending',
  'setRowIndex',
  'setPromo',
  'setDiscountActiveTab',
  'setPartnerDiscounts',
  'setPromoCodeDiscounts',
  'setAllPromoDiscounts',
  'setFilterAction',
  'setFilterCoefficient',
  'setFilterPromo',
  'setFilterActionSaved',
  'setFilterCoefficientSaved',
  'setFilterPromoSaved',
  'setPromoChoosenFilter',
  'setClearPromoFilter',
  'setPromoFilter',
  'setPromoFilterClear',
  'setPromoSorter',
]);
type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const setActiveTab =
  (activeTab: string): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: TYPES.setActiveTab, payload: { activeTab } });
    } catch (e) {
      console.error(e);
    }
  };

export const setInsuranceTypesPage =
  (page: number, pageSize: number, pageTotal: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    _setPage(TYPES.setInsuranceTypesPage, { page, pageSize, pageTotal }, dispatch);
  };

const _setPage = (type: string, payload: { page: number; pageSize: number; pageTotal: number }, dispatch: Dispatch) => {
  try {
    dispatch({ type, payload });
  } catch (e) {
    console.error(e);
  }
};

export const getDiscounts =
  (params?): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    try {
      const {
        discounts: { discountTypes },
      }: AppState = getState();
      const type = params.type ? params.type : undefined;
      const requestParams = {
        ...params,
        page: discountTypes.discountsDataPage,
        page_size: discountTypes.discountsDataPageSize,
        sorting: params.sorting ? `${params.sorting},-id` : '-id',
        count: true,
        type,
      };

      dispatch({ type: TYPES.setPending, payload: true });
      const { data } = await apiConfiguration.discountController.list5(requestParams);
      dispatch({ type: TYPES.setDiscounts, payload: { data: [], count: 0 } });
      dispatch({ type: TYPES.setDiscounts, payload: { data: data?.resultList, count: data.count } });
      type && data.resultList && dispatch({ type: TYPES.setPartnerDiscounts, payload: { data: [], count: 0 } });
      type && dispatch({ type: TYPES.setPartnerDiscounts, payload: { data: data?.resultList, count: data.count } });
      data.resultList && dispatch({ type: TYPES.setPending, payload: false });
    } catch (e) {
      dispatch({ type: TYPES.setPending, payload: false });
      console.error(e);
    }
  };
export const getAllDiscounts =
  (params?): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    const type = params.type ? params.type : undefined;
    const requestParams = {
      ...params,
      sorting: params.sorting ? `${params.sorting},-id` : '-id',
      count: true,
      page_size: '-1',
      type,
    };
    try {
      dispatch({ type: TYPES.setPending, payload: true });
      const { data } = await apiConfiguration.discountController.list5(requestParams);
      dispatch({ type: TYPES.setAllDiscounts, payload: { data: data?.resultList, count: data.count } });
    } catch (e) {
      dispatch({ type: TYPES.setPending, payload: false });
      console.error(e);
    }
  };
export const getDiscountsById =
  (id: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { data } = await apiConfiguration.discountController.getDiscount(id);
      dispatch({ type: TYPES.setChoosenDiscount, payload: { data } });
    } catch (e) {
      console.error(e);
    }
  };
export const changeModalStatus =
  (status: string): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setModalStatus, payload: status });
  };
export const setDiscountTypesPage =
  (page: number, pageSize: number, pageTotal: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    _setPage(TYPES.setDiscountsPage, { page, pageSize, pageTotal }, dispatch);
  };

export const setDiscountsInfoData =
  (data: any, name: string): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setDiscountsInfoData, payload: { data, name } });
  };

export const setFilterActionSaved =
  (data: IFilterObj): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setFilterActionSaved, payload: { data } });
  };
export const setFilterCoefficientSaved =
  (data: IFilterObj): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setFilterCoefficientSaved, payload: { data } });
  };

export const setFilterPromoSaved =
  (data: IFilterObj): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setFilterPromoSaved, payload: { data } });
  };

export const setDiscountTableFilters =
  (data: ITableFilters): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setDiscountTableFilters, payload: { data } });
  };

export const getEndDate =
  (date: string): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setEndDate, payload: { data: date } });
  };

export const getRowIndex =
  (index: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: TYPES.setRowIndex, payload: { index } });
  };

export const createDiscount =
  (data: DiscountDto): ThunkResult =>
  async (): Promise<void> => {
    try {
      const createdDiscount = await apiConfiguration.discountController.createDiscount(data);
      createdDiscount.status === 200 &&
        notification.success({
          message: 'Created',
        });
    } catch (e) {
      notification.error({
        message: 'Fail saving',
      });
      console.error(e);
    }
  };

export const getPromoCodeDiscountsList =
  (params?): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    try {
      const {
        discounts: { discountTypes },
      }: AppState = getState();
      const requestParams = {
        page: discountTypes.discountsDataPage,
        page_size: discountTypes.discountsDataPageSize,
        count: true,
        ...params,
      };

      dispatch({ type: TYPES.setPending, payload: true });
      const promoTable = await apiConfiguration.promoCodeController.list1(requestParams);
      dispatch({ type: TYPES.setPromoCodeDiscounts, payload: { data: [], count: 0 } });
      dispatch({
        type: TYPES.setPromoCodeDiscounts,
        payload: { data: promoTable.data?.resultList, count: promoTable.data.count },
      });
      dispatch({ type: TYPES.setPending, payload: false });

      promoTable.data.resultList && dispatch({ type: TYPES.setPending, payload: false });
    } catch (e) {
      dispatch({ type: TYPES.setPending, payload: false });
      console.error(e);
    }
  };

export const getAllPromoList =
  (): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    try {
      dispatch({ type: TYPES.setPending, payload: true });
      const promoTable = await apiConfiguration.promoCodeController.list1({});
      dispatch({
        type: TYPES.setAllPromoDiscounts,
        payload: { data: promoTable.data?.resultList, count: promoTable.data.count },
      });
      dispatch({ type: TYPES.setPending, payload: false });

      promoTable.data.resultList && dispatch({ type: TYPES.setPending, payload: false });
    } catch (e) {
      dispatch({ type: TYPES.setPending, payload: false });
      console.error(e);
    }
  };

export const setPromoTableFilter =
  (filteredObj: { [x: string]: any }): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    dispatch({ type: TYPES.setPromoFilter, payload: { data: filteredObj } });
  };

export const setPromoSorter =
  (sorter: string): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    dispatch({ type: TYPES.setPromoSorter, payload: { data: sorter } });
  };
export const setClearPromoFilter =
  (): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    dispatch({ type: TYPES.setPromoFilterClear, payload: { data: [] } });
  };
export const getPromoCode =
  (): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const promo = await apiConfiguration.promoCodeController.generatePromoCode();
      dispatch({ type: TYPES.setPromo, payload: { data: promo.data } });
      promo.status === 200 &&
        notification.success({
          message: t('promo_code.notification.Created'),
        });
    } catch (e) {
      notification.error({
        message: t('promo_code.notification.Failed'),
      });
      console.error(e);
    }
  };
export const createPromoCodeDiscount =
  (data: PromoCodeDto): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const promo = await apiConfiguration.promoCodeController.create(data);
      dispatch({ type: TYPES.setPromo, payload: { data: promo.data } });
      promo.status === 200 &&
        notification.success({
          message: t('promo_code.notification.Created'),
        });
    } catch (e) {
      if (e?.response?.data?.errors[0].code === 'PROMO_CODE_ALREADY_EXISTS') {
        notification.error({
          message: t('popup.promo_already_exists'),
        });
      }

      console.error(e);
    }
  };

export const UpdatePromoCodeDiscount =
  (id: number, data: PromoCodeDto): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const promo = await apiConfiguration.promoCodeController.update(id, data);
      dispatch({ type: TYPES.setPromo, payload: { data: promo.data } });
      promo.status === 200 &&
        notification.success({
          message: t('promo_code.notification.Updated'),
        });
    } catch (e) {
      notification.error({
        message: t('promo_code.notification.Failed'),
      });
      console.error(e);
    }
  };

export const deletePromoCodeDiscount =
  (id: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const discount = await apiConfiguration.promoCodeController.delete(id);
      dispatch({ type: TYPES.setPromo, payload: { data: discount.data } });
      discount.status === 200 &&
        notification.success({
          message: t('promo_code.notification.Deleted'),
        });
    } catch (e) {
      notification.error({
        message: t('promo_code.notification.Failed'),
      });
      console.error(e);
    }
  };

export const getPromoCodeDiscountsById =
  (id: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { data } = await apiConfiguration.promoCodeController.get(id);
      dispatch({ type: TYPES.setChoosenPromoCodeDiscount, payload: { data } });
    } catch (e) {
      console.error(e);
    }
  };
export const updateDiscount =
  (id: number, data: DiscountDto): ThunkResult =>
  async (): Promise<void> => {
    try {
      const createdDiscount = await apiConfiguration.discountController.updateDiscount(id, data);
      createdDiscount.status === 200 &&
        notification.success({
          message: 'Updated',
        });
    } catch (e) {
      notification.error({
        message: 'Fail updating',
      });
      console.error(e);
    }
  };

export const deleteById =
  (id: number): ThunkResult =>
  async (): Promise<void> => {
    try {
      await apiConfiguration.discountController.deleteDiscount(id);
    } catch (e) {
      console.error(e);
    }
  };

export const getActiveTab = (tabName: string) => async (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setDiscountActiveTab, payload: { tabName } });
};

export const downloadDiscounts = () => async (dispatch, getState) => {
  const filters: DiscountsTypes = getState().discounts;
  const activeTab: string = getState().discounts.activeTabeName.tabName;
  const body = getFilteredBody(filters.filterAction, filters.filterCoefficient, filters.filterPromo, activeTab);
  await onDownload(() =>
    apiContracts.discountController
      .printXlsxReport2(body, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const downloadPromoToExel = () => async (dispatch, getState) => {
  const filters: DiscountsTypes = getState().discounts;
  const activeTab: string = getState().discounts.activeTabeName.tabName;
  const body = getFilteredBody(filters.filterAction, filters.filterCoefficient, filters.filterPromo, activeTab);
  await onDownload(() =>
    apiConfiguration.promoCodeController
      .printXlsxReport1({ ...body, page_size: -1 }, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const setChoosenFilters = (filterColumn) => async (dispatch, getState) => {
  dispatch({
    type: TYPES.setPromoChoosenFilter,
    payload: { data: { name: filterColumn.name, value: filterColumn.value } },
  });
};

export const setClearFilter = () => async (dispatch, getState) => {
  dispatch({
    type: TYPES.setClearPromoFilter,
  });
};
