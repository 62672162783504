import React, { Suspense, lazy } from 'react';
import PageLoading from 'components/PageLoading';
import { ClientLayout } from 'components/nextRedesign/ClientLayout/ClientLayout';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import PrivateRoute from './PrivateRoute';
import { ROUTES } from 'constants/routes';
import Dashboard from 'screens/Dashboard/Dashboard';
import UI from 'screens/UI/UI';
import Page404 from 'components/Page404';

const ClientProfile = lazy(() => import('screens/ClientProfile/ClientProfile'));
const ClientPolicies = lazy(() => import('screens/ClientPolicies/ClientPolicies'));
const ClientNotification = lazy(() => import('screens/ClientNotification/ClientNotification'));
const Settings = lazy(() => import('screens/Settings/Settings'));
const ClientContractDetails = lazy(() => import('screens/ClientContractDetails/ClientContractDetails'));
const ClientBonuses = lazy(() => import('screens/ClientBonuses/ClientBonuses'));

export const ClientLayoutRouting = () => {
  return (
    <ClientLayout>
      <Suspense fallback={<PageLoading />}>
        <Router history={history}>
          <Switch>
            <PrivateRoute role={['DASHBOARD', 'READ']} path={ROUTES.DASHBOARD.ROOT} component={Dashboard} />
            <PrivateRoute exact role={['DASHBOARD', 'READ']} path={ROUTES.CLIENT.POLICIES} component={ClientPolicies} />
            <PrivateRoute
              role={['DASHBOARD', 'READ']}
              path={ROUTES.CLIENT.NOTIFICATION}
              component={ClientNotification}
            />
            <PrivateRoute role={['PROFILE', 'READ']} path={ROUTES.CLIENT.PROFILE} component={ClientProfile} />
            <PrivateRoute
              role={['DASHBOARD', 'READ']}
              path={ROUTES.CLIENT.CONTRACT_CREATE}
              component={ClientContractDetails}
            />
            <PrivateRoute role={['BONUSES', 'READ']} path={ROUTES.CLIENT_BONUSES.ROOT} component={ClientBonuses} />

            {process.env.NODE_ENV === 'development' && <Route exact path={ROUTES.UI.ROOT} component={UI} />}
            <Route exact path={ROUTES.SETTINGS.ROOT} component={Settings} />
            <Route component={Page404} />
          </Switch>
        </Router>
      </Suspense>
    </ClientLayout>
  );
};
