import { useState, useEffect } from 'react';
import moment from 'moment';

import { getUserContracts } from 'api/contracts';
import {
  getInstitutions,
  getServiceTypeServices,
  getAllServices,
  getDoctorEleksServices,
  getDoctorEleksDoctors,
  getDoctorEleksSlots,
} from 'api/institution';
import { useAsync } from 'hooks/useAsync';
import { APPOINTMENT_TYPES } from 'constants/appointments';
import { IClaim } from 'types/appointments';

const dayCount = 14;

export const useData = () => {
  const { value: contracts, doFetch: fetchContracts } = useAsync(getUserContracts);
  const { value: serviceTypes, doFetch: fetchServiceTypes } = useAsync(getServiceTypeServices);
  const { value: services, doFetch: fetchServices } = useAsync(() =>
    getAllServices({ pagination: { current: 1, perPage: 300 }, sorting: null, filters: null }),
  );
  const { value: clinics, doFetch: fetchClinics } = useAsync((filters) =>
    getInstitutions({ current: 1, perPage: 50 }, filters),
  );
  const [contractClaims, setContractClaims] = useState<Array<IClaim>>([]);

  const { value: drEleksServices, doFetch: fetchDrEleksServices } = useAsync((institutionId) =>
    getDoctorEleksServices(institutionId),
  );
  const { value: drEleksDoctors, doFetch: fetchDrEleksDoctors } = useAsync((data) =>
    getDoctorEleksDoctors(data.institutionId, data.serviceId),
  );
  const { value: drEleksSlots, doFetch: fetchDrEleksSlots } = useAsync((data) =>
    getDoctorEleksSlots(data.institutionId, data.serviceId, data.doctorLogin, dayCount),
  );

  const defaultContract = localStorage.getItem('defaultContract');

  useEffect(() => {
    fetchContracts();
    fetchClinics();
    fetchServiceTypes();
    fetchServices();
  }, []);

  useEffect(() => {
    const contractId = defaultContract ? JSON.parse(defaultContract).contractId : 0;
    if (contracts) {
      sortingClaims(contractId);
    }
  }, [defaultContract, contracts]);

  const sortingClaims = (id) => {
    const contrClaims = contracts.find((contr) => contr.contractId === id)?.contractClaims;
    if (contrClaims && contrClaims.length > 0) {
      setContractClaims(contrClaims.sort((a, b) => moment(b.claimDate).valueOf() - moment(a.claimDate).valueOf()));
    } else {
      setContractClaims([]);
    }
  };

  const formatTypes = () => {
    return APPOINTMENT_TYPES.map((el) => {
      if (el.id === 2) {
        return {
          ...el,
          parentId: el.id,
          children: contractClaims.length
            ? contractClaims.map((item) => {
                return {
                  ...item,
                  parentId: el.id,
                  id: item.id,
                };
              })
            : [],
        };
      }
      return { ...el, parentId: el.id, children: [] };
    });
  };

  const serviceData =
    serviceTypes && services
      ? serviceTypes?.resultList.map(({ id, name, hasChildren, couldBeOnline }) => {
          const children = hasChildren
            ? services?.resultList.filter(({ parent }) => parent === id).map((el) => ({ ...el, parentId: el.parent }))
            : [];

          return {
            id,
            name,
            couldBeOnline,
            children,
            parentId: id,
          };
        })
      : [];

  return {
    contracts: contracts ?? [],
    services: serviceData ?? [],
    clinics: clinics?.data?.resultList ?? [],
    types: formatTypes(),
    drEleksServices: drEleksServices?.serviceDetails ?? [],
    drEleksDoctors: drEleksDoctors?.result ?? [],
    drEleksSlots: drEleksSlots?.freeSeanceDetails ?? [],
    sortingClaims,
    fetchClinics,
    fetchDrEleksServices,
    fetchDrEleksDoctors,
    fetchDrEleksSlots,
  };
};
