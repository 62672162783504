import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { List4Params, ResultListDtoInsuranceCompanyView } from 'types/dto/contracts-service';
import { getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { store } from 'store';
import config from 'config';
import { apiContracts } from 'api/contracts';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoInsuranceCompanyView | null;
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'INSURANCE_COMPANIES/SET_DATA', payload } as const),
};

export const requestData =
  (params: List4Params = {}): ThunkType<Promise<ResultListDtoInsuranceCompanyView>> =>
  async (dispatch, getState) => {
    const insuranceCompanies = getState().insuranceCompanyList;
    const sorting = getSortBy(insuranceCompanies.data.sorting);

    return apiContracts.insuranceCompanyController
      .list4({
        count: true,
        distinct: true,
        attributes: 'id,abbreviation,enterpriseCode,name,phoneNumber,contactPersonFullName,createdDate',
        sorting,
        ...getPrivateFilters(insuranceCompanies.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const insuranceCompanies = getState().insuranceCompanyList;
  dispatch(actions.setData({ loading: true }));

  return dispatch(requestData({ ...getPageBy(insuranceCompanies.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export interface StoreInsuranceCompanies {
  data: Data;
}
export const initialState: StoreInsuranceCompanies = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'createdDate', order: 'descend' },
    privateFilters: {},
  },
};

const reducer = (state = initialState, action: InferActionTypes): StoreInsuranceCompanies => {
  switch (action.type) {
    case 'INSURANCE_COMPANIES/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
