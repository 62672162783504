import isEmail from 'is-email';
import * as structs from 'superstruct';
import { Dayjs } from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { t } from 'i18next';

export * from 'superstruct';

export function string(message?: () => string): structs.Struct<string, null> {
  return structs.define('string', (value) => {
    return typeof value === 'string' || (message?.() ?? t('schema.required') ?? 'required');
  });
}

export function number(message?: () => string): structs.Struct<number, null> {
  return structs.define('number', (value) => {
    return (typeof value === 'number' && !isNaN(value)) || (message?.() ?? t('schema.required') ?? 'required');
  });
}

export function date(): structs.Struct<Dayjs> {
  return customObject<Dayjs>();
}

export function customObject<T>(): structs.Struct<T> {
  return record(string(), structs.any()) as structs.Struct<T>;
}

function isObject(x: unknown): x is object {
  return typeof x === 'object' && x != null;
}

export function record<K extends string, V>(
  Key: structs.Struct<K>,
  Value: structs.Struct<V>,
): structs.Struct<Record<K, V>, null> {
  return new structs.Struct({
    type: 'record',
    schema: null,
    *entries(value) {
      if (isObject(value)) {
        for (const k in value) {
          const v = value[k];
          yield [k, k, Key];
          yield [k, v, Value];
        }
      }
    },
    validator(value) {
      return isObject(value) || (t('schema.required') ?? 'required');
    },
  });
}

export function enums<T extends number>(values: readonly T[]): structs.Struct<T, { [K in T[][number]]: K }>;
export function enums<T extends string>(values: readonly T[]): structs.Struct<T, { [K in T[][number]]: K }>;
export function enums<T extends number | string>(values: readonly T[]): any {
  const schema: any = {};

  for (const key of values) {
    schema[key] = key;
  }

  return new structs.Struct({
    type: 'enums',
    schema,
    validator(value) {
      return values.includes(value as any) || (t('schema.required') ?? 'required');
    },
  });
}

/**
 * @deprecated please use custom definition
 */
export function size<T extends string | number | Date | any[] | Map<any, any> | Set<any>, S extends any>(
  struct: structs.Struct<T, S>,
  min: number,
  max: number,
): structs.Struct<T, S> {
  return structs.refine(struct, 'size', (value) => {
    const message =
      min === max
        ? t('schema.size', { count: min }) || `${min} characters`
        : t('schema.sizeRange', { min, max }) || `${min}-${max} characters`;

    if (typeof value === 'number' || value instanceof Date) {
      return (min <= value && value <= max) || message;
    } else if (value instanceof Map || value instanceof Set) {
      const { size } = value;
      return (min <= size && size <= max) || message;
    } else {
      const { length } = value as string | any[];
      return (min <= length && length <= max) || message;
    }
  });
}

/**
 * @deprecated please use custom definition
 */
export const nonempty = <T extends string | object | unknown[], S extends unknown>(struct: structs.Struct<T, S>) =>
  structs.refine<T, S>(struct, 'nonempty', (value) =>
    (Array.isArray(value) && value.length === 0) || (typeof value === 'object' && isEmpty(value)) || value === ''
      ? t('schema.required') ?? 'required'
      : true,
  );

export const refiner = <T, S>(struct: structs.Struct<T, S>, refiner: structs.Refiner<T>) =>
  structs.refine(struct, 'refiner', refiner);

export const email = <T extends string, S extends unknown>(struct: structs.Struct<T, S>) =>
  structs.refine<T, S>(struct, 'email', (value) => {
    return isEmail(value) || (t('schema.email') ?? 'email');
  });

export const phoneNumber = <T extends string, S extends unknown>(struct: structs.Struct<T, S>) =>
  structs.refine<T, S>(struct, 'phoneNumber', (value) => {
    return (parsePhoneNumber(value).valid || t('schema.phoneNumber')) ?? 'invalid phoneNumber';
  });

export const integer = <T extends number, S extends unknown>(struct: structs.Struct<T, S>) =>
  structs.refine<T, S>(
    struct,
    'integer',
    (value) => Number.isInteger(value) || (t('schema.numberInteger') ?? 'integer'),
  );

export const positive = <T extends number, S extends unknown>(struct: structs.Struct<T, S>) =>
  structs.refine<T, S>(struct, 'positive', (value) => value >= 0 || (t('schema.numberPositive') ?? 'positive'));
