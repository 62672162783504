import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/css';
import { useDispatch } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import jwt from 'jsonwebtoken';

import { UseHistory } from 'types';
import ClientAuthContainer from 'components/nextRedesign/ClientAuthContainer/ClientAuthContainer';
import { getInsuranceCompanyWhiteLabel, getPartnerWhiteLabel, actions, defaultWhiteLabel } from 'store/me/me';
import { breakpoints } from 'styles/breakpoints';

import Login from './Login/Login';
// import Verification from './Verification/Verification';

const cssHeader = css`
  &.ant-typography {
    font-size: 44px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: ${breakpoints.small}) {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
`;

export type Step = 'login' | 'verification';
export interface State {
  step: Step;
  token: number;
  username: string;
  password: string;
  userId: number;
}

const ClientLogin = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(history.location.search);
  const jwtToken = queryParams.toString().slice(0, -1);
  const decodedToken = jwtToken ? jwt.decode(jwtToken) : null;

  const insuranceCompanyCode = typeof decodedToken === 'object' ? decodedToken?.insuranceCompanyCode : null;
  const partnerCode = typeof decodedToken === 'object' ? decodedToken?.partnerCode : null;

  // const [state, setState] = useReducer(
  //   (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
  //   {
  //     step: 'login',
  //     token: 0,
  //     username: '',
  //     password: '',
  //     userId: 0,
  //   },
  // );

  useAsyncEffect(async () => {
    localStorage.setItem('clientAuthParams', history.location.search);
    if (partnerCode) {
      dispatch(getPartnerWhiteLabel(partnerCode));
    } else if (insuranceCompanyCode && !partnerCode) {
      dispatch(getInsuranceCompanyWhiteLabel(insuranceCompanyCode));
    } else {
      dispatch(actions.setWhiteLabel({ data: defaultWhiteLabel }));
    }
  }, [insuranceCompanyCode, partnerCode]);

  return (
    <ClientAuthContainer>
      <Typography.Title className={cssHeader}>{t('log_in.client_title')}</Typography.Title>

      <Login />
      {/* <Modal
        footer={null}
        maskClosable={false}
        destroyOnClose
        open={state.step === 'verification'}
        onCancel={() => setState({ step: 'login' })}
        width={650}
        centered
      >
        <Verification state={state} setState={setState} />
      </Modal> */}
    </ClientAuthContainer>
  );
};

export default ClientLogin;
