import { createTypes } from 'redux-compose-reducer';
import * as subeventsAPI from 'api/subevents';
// import { AppState } from 'store/reducer';
// import history from 'routes/history';
// import { ROUTES } from 'constants/routes';

export const TYPES = createTypes('subevents', ['setPending', 'setError', 'setSubevents', 'sendConsultationRating']);

const setPending = (pending: boolean) => ({
  type: TYPES.setPending,
  payload: { pending },
});

const setError = (error) => ({
  type: TYPES.setError,
  payload: { error },
});

export const getSubevents = () => async (dispatch) => {
  try {
    dispatch(setPending(true));
    const response = await subeventsAPI.getSubevents();
    dispatch({ type: TYPES.setSubevents, payload: { subevents: response } });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};

export const sendConsultationRating = (data) => async (dispatch) => {
  try {
    dispatch(setPending(true));
    await subeventsAPI.sendConsultationRating(data);
    dispatch({ type: TYPES.setSublimits });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch(setPending(false));
  }
};
