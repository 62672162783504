import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import {
  IntegrationItemView,
  List3Params,
  ListContractTransferHistoryParams,
  PartnerView,
  ResultListDtoIntegrationHistoryItemView,
  ResultListDtoIntegrationItemView,
} from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/Integrations/Filters/Filters.schema';
import config from 'config';
import {
  InsuranceProductView,
  InsuranceProgramView,
  ListProductsParams,
  List4Params,
} from 'types/dto/configuration-service';
import { apiContracts } from 'api/contracts';
import { apiConfiguration } from 'api/configuration';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoIntegrationItemView | null;
}
type Filters = Values & { initialization: boolean };
interface InsuranceProducts {
  loading: boolean;
  data: InsuranceProductView[];
}
interface InsurancePrograms {
  loading: boolean;
  data: InsuranceProgramView[];
}
interface Partners {
  loading: boolean;
  data: PartnerView[];
}
interface Details extends List {
  loading: boolean;
  transferHistory: ResultListDtoIntegrationHistoryItemView | null;
  extra: IntegrationItemView | null;
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'INTEGRATIONS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'INTEGRATIONS/SET_FILTERS', payload } as const),
  setInsuranceProducts: (payload: Partial<InsuranceProducts>) =>
    ({ type: 'INTEGRATIONS/SET_INSURANCE_PRODUCTS', payload } as const),
  setInsurancePrograms: (payload: Partial<InsurancePrograms>) =>
    ({ type: 'INTEGRATIONS/SET_INSURANCE_PROGRAMS', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'INTEGRATIONS/SET_PARTNERS', payload } as const),
  setDetails: (payload: Partial<Details>) => ({ type: 'INTEGRATIONS/SET_DETAILS', payload } as const),
  clearDetails: () => ({ type: 'INTEGRATIONS/CLEAR_DETAILS' } as const),
};

export const requestData =
  (params: List3Params = {}): ThunkType<Promise<ResultListDtoIntegrationItemView>> =>
  async (dispatch, getState) => {
    const integrations = getState().integrations;
    const sorting = getSortBy(integrations.data.sorting);

    return apiContracts.integrationController
      .list3({
        count: true,
        distinct: true,
        attributes:
          'id,transferType,insuranceProductId,insuranceProductName,partnerId,partnerName,insuranceProductId,insuranceProductName,insuranceProgramId,insuranceProgramName,packageNumber,type,operationDate,entitiesToTransferCount,successCount,failedCount,initiator',
        type: integrations.filters?.type ?? undefined,
        insuranceProductId: integrations.filters?.insuranceProductId?.toString(),
        insuranceProgramId: integrations.filters?.insuranceProgramId?.toString(),
        partnerId: integrations.filters?.partnerId?.toString(),
        operationDate: getDateRange(integrations.filters?.dateFrom, integrations.filters?.dateTo) ?? '',
        sorting: sorting ? `${sorting},-id` : '-id',
        ...getPrivateFilters(integrations.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const integrations = getState().integrations;

  dispatch(actions.setData({ loading: true, data: null }));

  dispatch(requestData({ ...getPageBy(integrations.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false, data: null }));
    });
};

export const loadInsuranceProducts =
  (query: ListProductsParams = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setInsuranceProducts({ loading: true, data: [] }));

    apiConfiguration.insuranceProductController
      .listProducts({
        distinct: true,
        attributes: 'id,name,code',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setInsuranceProducts({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setInsuranceProducts({ loading: false, data: [] }));
      });
  };

export const loadInsurancePrograms =
  (query: List4Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setInsurancePrograms({ loading: true, data: [] }));

    apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        page_size: -1,
        attributes: 'id,name',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: [] }));
      });
  };

export const loadPartners = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setPartners({ loading: true, data: [] }));

  apiContracts.partnerController
    .list2({ page_size: -1, attributes: 'id,name,shortName' })
    .then((res) => {
      dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch(() => {
      dispatch(actions.setPartners({ loading: false, data: [] }));
    });
};

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadInsuranceProducts());
  dispatch(loadInsurancePrograms());
  dispatch(loadPartners());
};

export const requestTransferHistory =
  (params: ListContractTransferHistoryParams = {}): ThunkType<Promise<ResultListDtoIntegrationHistoryItemView>> =>
  async (dispatch, getState) => {
    const integrations = getState().integrations;
    const sorting = getSortBy(integrations.data.sorting);

    return apiContracts.integrationController
      .listContractTransferHistory({
        count: true,
        historyId: integrations.details?.extra?.id?.toString(),
        sorting: sorting ? `${sorting},id` : undefined,
        ...getPrivateFilters(integrations.details?.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadTransferHistory = (): ThunkType<void> => async (dispatch, getState) => {
  const integrations = getState().integrations;

  dispatch(actions.setDetails({ loading: true }));

  dispatch(requestTransferHistory({ ...getPageBy(integrations.details?.pagination) }))
    .then((res) => {
      dispatch(actions.setDetails({ loading: false, transferHistory: res }));
    })
    .catch(() => {
      dispatch(actions.setDetails({ loading: false }));
    });
};

export interface StoreIntegrations {
  data: Data;
  filters: Filters;
  insuranceProducts: InsuranceProducts;
  insurancePrograms: InsurancePrograms;
  partners: Partners;
  details: Partial<Details> | null;
}
export const initialState: StoreIntegrations = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'operationDate', order: 'descend' },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  insuranceProducts: { loading: false, data: [] },
  insurancePrograms: { loading: false, data: [] },
  partners: { loading: false, data: [] },
  details: null,
};

const reducer = (state = initialState, action: InferActionTypes): StoreIntegrations => {
  switch (action.type) {
    case 'INTEGRATIONS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'INTEGRATIONS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'INTEGRATIONS/SET_INSURANCE_PRODUCTS':
      return { ...state, insuranceProducts: { ...state.insuranceProducts, ...action.payload } };
    case 'INTEGRATIONS/SET_INSURANCE_PROGRAMS':
      return { ...state, insurancePrograms: { ...state.insurancePrograms, ...action.payload } };
    case 'INTEGRATIONS/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'INTEGRATIONS/SET_DETAILS':
      return { ...state, details: { ...state.details, ...action.payload } };
    case 'INTEGRATIONS/CLEAR_DETAILS':
      return { ...state, details: null };

    default:
      return state;
  }
};

export default reducer;
