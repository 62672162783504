const config = {
  ui: {
    pagination: {
      size: 50,
      options: [50, 100],
    },
    search: {
      delay: 300,
    },
  },
  // TODO front part of formats should belong to locale
  format: {
    dateBack: 'YYYY-MM-DD',
    date: 'DD.MM.YYYY',
    monthYear: 'MMMM YYYY',
    year: 'YYYY',
    time: 'HH:mm',
    financial: '0,0.00',
  },
  storage: {
    ACCESS_TOKEN: 'accessToken',
  },
  DIA_TIMEOUT: 3 * 60 * 1000,
  NOTIFICATION_TIMEOUT: 10 * 60 * 1000,
  AUTH_OTP_TIMEOUT: 2 * 60 * 1000 - 1 * 1000,
  RESET_PASSWORD_TIMEOUT: 5 * 60 * 1000 - 1 * 1000,
  SIGN_UP_TIMEOUT: 5 * 60 * 1000 - 1 * 1000,
};

export default config;
