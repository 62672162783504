import { ThunkAction } from 'redux-thunk';
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';
import { notification } from 'antd';

import { AppState } from 'store/reducer';
import { ActionTypes } from 'types';
import { CurrentUserDto, InsuranceCompanyDto, UserDto } from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import { TokenDto, Tenant } from 'types/token';
import defaultLogo from 'assets/img/inriskLogo.png';
import defaultLogoSmall from 'components/nextRedesign/Icon/logo-small.svg';
import palleteRedesign from 'styles/palleteRedesign';
import { NotificationLogDto, ResultListDtoNotificationLog } from 'types/dto/notification-service';
import { apiNotification } from 'api/notification';
import { WhiteLabeling } from 'types/whiteLabel';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

export const defaultWhiteLabel = {
  logoUrl: defaultLogo,
  logoMiniUrl: defaultLogoSmall,
  whiteLabeling: { color: palleteRedesign.primary },
};

interface Data {
  loading?: boolean;
  decoded?: TokenDto;
  /**
   * @deprecated use details if it is possible, almost full duplicate of details
   * @readonly don't modify it directly
   */
  current?: CurrentUserDto;
  details?: UserDto;
  insuranceCompany?: InsuranceCompanyDto | null;
}
interface WhiteLabel {
  loading?: boolean;
  data?: {
    logoUrl?: string;
    logoMiniUrl?: string;
    whiteLabeling?: WhiteLabeling;
  };
}

interface Notification {
  loading?: boolean;
  isOpenAlertPanel?: boolean;
  data?: ResultListDtoNotificationLog | null;
}

export const actions = {
  setData: (payload: Data | null) => ({ type: 'me/SET_DATA', payload } as const),
  setWhiteLabel: (payload: WhiteLabel | null) => ({ type: 'me/SET_WHITE_LABEL', payload } as const),
  setNotification: (payload: Notification | null) => ({ type: 'me/SET_NOTIFICATION', payload } as const),
};

// TODO front split to independ requests, is it need?
export const getMe = (): ThunkType<void> => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error("token isn't exist");
    }

    const decoded = jwt.decode(accessToken) as TokenDto;
    const tenant = (decoded.tenant ?? '').split('/');
    const tenantParsed: Tenant = {
      insuranceCompanyId: tenant?.[0] ? Number(tenant[0]) : undefined,
      partnerId: tenant?.[1] ? Number(tenant[1]) : undefined,
      partnerDepartmentId: tenant?.[2] ? Number(tenant[2]) : undefined,
    };

    // TODO front need this check?
    if (decoded.expirationDate < dayjs().valueOf()) {
      throw new Error('token is expired');
    }

    dispatch(actions.setData({ loading: true }));

    const current = await (await apiContracts.userController.getCurrentUser()).data;
    const details = await (await apiContracts.userController.read(current.id ?? 0)).data;
    let insuranceCompany: InsuranceCompanyDto | null = null;

    if (details.insuranceCompany?.id) {
      insuranceCompany = await (await apiContracts.insuranceCompanyController.read5(details.insuranceCompany?.id)).data;
    }

    dispatch(
      actions.setData({
        loading: false,
        decoded: { ...decoded, tenantParsed },
        current,
        details,
        insuranceCompany,
      }),
    );
  } catch (error) {
    throw new Error(error);
  }
};

export const getInsuranceCompanyWhiteLabel =
  (insuranceCompanyCode: string): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setWhiteLabel({ loading: true }));

    apiContracts.insuranceCompanyController
      .getWhiteLabel1(insuranceCompanyCode)
      .then((res) => {
        // TODO back dont return empty string
        dispatch(actions.setWhiteLabel({ loading: false, data: Boolean(res.data) ? res.data : defaultWhiteLabel }));
      })
      .catch((error) => {
        dispatch(actions.setWhiteLabel({ loading: false, data: defaultWhiteLabel }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getPartnerWhiteLabel =
  (partnerCode: string): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setWhiteLabel({ loading: true }));

    return apiContracts.partnerController
      .getWhiteLabel(partnerCode)
      .then((res) => {
        // TODO back dont return empty string
        dispatch(actions.setWhiteLabel({ loading: false, data: Boolean(res.data) ? res.data : defaultWhiteLabel }));
      })
      .catch((error) => {
        dispatch(actions.setWhiteLabel({ loading: false, data: defaultWhiteLabel }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getNotification = (): ThunkType<Promise<ResultListDtoNotificationLog>> => async (dispatch, getState) => {
  dispatch(actions.setNotification({ loading: true }));
  return apiNotification.notificationController
    .list({
      count: true,
      sorting: '-createdAt',
      page_size: 10,
      visible: 'true',
    })
    .then((res) => {
      dispatch(actions.setNotification({ loading: false, data: res.data }));
      return res.data;
    })
    .catch((error) => {
      dispatch(actions.setNotification({ loading: false, data: null }));
      return Promise.reject(undefined);
    });
};

export const updateNotification =
  (id: number, data: NotificationLogDto): ThunkType<Promise<NotificationLogDto>> =>
  (dispatch) => {
    return apiNotification.notificationController
      .updateNotificationLog(id, data)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  };
export interface StoreMe {
  data: Data | null;
  whiteLabel: WhiteLabel | null;
  notification: Notification | null;
}
const initialState: StoreMe = {
  data: null,
  whiteLabel: null,
  notification: {
    loading: false,
    isOpenAlertPanel: false,
    data: null,
  },
};

const reducer = (state = initialState, action: InferActionTypes): StoreMe => {
  switch (action.type) {
    case 'me/SET_DATA':
      return { ...state, data: action.payload === null ? null : { ...state.data, ...action.payload } };
    case 'me/SET_WHITE_LABEL':
      return {
        ...state,
        whiteLabel:
          action.payload === null
            ? null
            : {
                loading: action.payload.loading,
                data: {
                  ...state.whiteLabel?.data,
                  ...action.payload.data,
                },
              },
      };
    case 'me/SET_NOTIFICATION':
      return { ...state, notification: action.payload === null ? null : { ...state.notification, ...action.payload } };
    default:
      return state;
  }
};

export default reducer;
