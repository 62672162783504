import React from 'react';
import { MenuItem } from '@material-ui/core';
import UiSelect from 'components/FiltersBar/components/Select';
import { Control, Controller } from 'react-hook-form';
import styles from './Select.module.less';

interface SelectProps<T> {
  name: string;
  control: Control<T>;
  label: string;
  options?: string[];
}

export const Select = <T,>({ name, control, label, options = [] }: SelectProps<T>) => {
  const optionsWithNotChosen = ['Не обрано'].concat(options ?? []);

  return (
    <Controller
      // @ts-ignore
      name={name}
      control={control}
      render={({ field: { name, onChange, value } }) => (
        <UiSelect className={styles.select} label={label} value={value} onChange={(e) => onChange(e.target.value)}>
          {optionsWithNotChosen.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </UiSelect>
      )}
    />
  );
};
