import { object, Infer, optional, string } from 'utils/struct';

export const schema = object({
  brand: optional(string()),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  brand: '',
};
