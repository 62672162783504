import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uk';
import 'moment/locale/eu';
import RU from 'antd/lib/locale/ru_RU';
import EN from 'antd/lib/locale/en_US';
import UA from 'antd/lib/locale/uk_UA';
import dayjs, { ManipulateType } from 'dayjs';
import merge from 'lodash/merge';
import map from 'lodash/map';
import find from 'lodash/find';

import { RISK_TYPE } from '../components/DetailsCard/types/Jutlo.interfaces';
import { IMapAutoComplate, Locale } from 'types';
import { ChronoUnitSimplifiedDuplicate } from 'types/dto/contracts-service';
import { IContract, IDetailProgram } from 'types/contractDetails';
import { Currency, InsuranceProductCode } from 'types/dto/configuration-service';
import { store } from 'store/store';

export const dateFormatter = (date: string) => {
  return moment(date).format('DD.MM.YYYY');
};

export const moneyFormatter = (money: number | null = 0, fixedCount?: number, zeroWithMinus?: boolean) => {
  if (money === null) {
    return '0.00';
  }
  if (zeroWithMinus && money === 0) {
    return '-0.00';
  }

  const n = String(reverseMoneyToNumber(String(money)).toFixed(fixedCount ?? 2)),
    p = n.indexOf('.');

  const formated = n.replace(/\d(?=(?:\d{3})+(?:\.|$))/g, (m, i) => (p < 0 || i < p ? `${m} ` : m));
  const commaPattern = /(\d+)(\d{3})(\.\d*)*$/;
  const callback = (match, p1, p2, p3) => p1.replace(commaPattern, callback) + ' ' + p2 + (p3 || '');
  return formated.replace(commaPattern, callback);
};

export const percentFormatter = (value: number | null = 0) => Number(value) * 100;

export const reverseMoneyToNumber = (str: string) => {
  const splinted = String(str)?.split(' ');
  return Number(splinted?.join(''));
};

export const makerRequestParams = (array: []) => {
  let str = '';

  Array.isArray(array)
    ? array.forEach((item, inx) => {
        if (inx === 0) {
          str = str + item;
        } else {
          str = `${str}|${item}`;
        }
      })
    : (str = array);

  return str;
};

export const phoneNumberMask = (value: string, code?: string) => {
  let result = '';

  if (value[0]) {
    // result += value[0] + (value[1] || '') + (value[2] || '') + (value[3] || '');
    result += code ?? '+380';
  }
  if (value[4]) {
    result += ' (' + value[4] + (value[5] || '');
  }
  if (value[6]) {
    result += ')-' + value[6] + (value[7] || '') + (value[8] || '');
  }
  if (value[9]) {
    result += '-' + value[9] + (value[10] || '');
  }
  if (value[11]) {
    result += '-' + value[11] + (value[12] || '');
  }
  return result;
};

export const getIsTJS = (insuranceProgram: string) =>
  insuranceProgram.includes('ACCIDENT') || insuranceProgram.includes('ВЗР');

export const formatDocumentsData = (dataArr: any) =>
  dataArr.map((data: any) => ({
    obligatory: data.obligatory,
    information: '',
    signDate: '',
    receiptDate: '',
    documentType: data.documentType,
    informationObligatory: data.informationObligatory,
    visible: data.visible,
    allowMultipleFiles: data.allowMultipleFiles,
    calculable: data.calculable,
    calculationParticipation: data.calculationParticipation,
    id: data.id,
  }));

export const splitString = (str: string) => {
  const rx = /\b(?:type|degree)\s+M{0,4}(?:C[MD]|D?C{0,3})(?:X[CL]|L?X{0,3})(?:I[XV]|V?I{0,3})\b|\S+/g;
  return str.match(rx) || [];
};

export const maxTickValue = (data: any[]) => {
  if (data.length) {
    let max = data.reduce((accum, el) => Math.max(accum, el.value), data[0].value);
    return max >= 12 ? 12 : max;
  } else {
    return 10;
  }
};

export const checkOnlineStatus = (errorMessage: string) => {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    return errorMessage;
  } else {
    return 'Перевірте підключення до інтернету або зверніться до технічної підтримки';
  }
};

export const getJutloType = (type) => {
  switch (type) {
    case RISK_TYPE.APARTMENT:
      return 'Квартира';
    case RISK_TYPE.PRIVATE_HOUSE:
      return 'Приватний будинок';
    case RISK_TYPE.COTTAGE:
      return 'Котедж';
    case RISK_TYPE.TOWN_HOUSE:
      return 'Таунхаус';
    case 'Приватний будинок':
      return 'Жилой дом';
    case 'Котедж':
      return 'Коттедж';
    default:
      return type;
  }
};

// TODO Delte after deploy
export const getMyHomeType = (type) => {
  switch (type) {
    case RISK_TYPE.APARTMENT:
      return 'Квартира';
    case RISK_TYPE.PRIVATE_HOUSE:
      return 'Жилой дом';
    case RISK_TYPE.COTTAGE:
      return 'Коттедж';
    case RISK_TYPE.TOWN_HOUSE:
      return 'Таунхаус';
    default:
      return '';
  }
};

export const languages = [
  { title: 'Українська', key: 'Українська', value: Locale.ua },
  { title: 'Русский', key: 'Русский', value: Locale.ru },
  { title: 'Английский', key: 'Английский', value: Locale.en },
];

export const getDatePickerLng = (language: string | undefined) => {
  if (!language) {
    return EN;
  }

  const datePickerLng = {
    ru: RU,
    en: EN,
    ua: UA,
  };
  return datePickerLng[language];
};

export const sortCustomArray = (arr) => {
  let len = arr.length;
  for (let i = 0; i < len; i++) {
    if (arr[i].code === InsuranceProductCode.MED && len !== 1) {
      for (let j = 0; j < len; j++) {
        if (arr[j].code === InsuranceProductCode.MED) {
          let tmp = arr[j];
          arr[j] = arr[1];
          arr[1] = tmp;
        }
        if (arr[j].code === InsuranceProductCode.CV) {
          let tmp = arr[j];
          arr[j] = arr[2];
          arr[2] = tmp;
        }
      }
    }
  }
  return arr;
};

export const restrictNumber = (name) => {
  if (name) {
    return name.replace(new RegExp(/[^\d]/, 'ig'), '');
  }
};

export const getUniqueListBy = <T>(arr: T[], key: keyof T) => {
  return Array.from(new Map(arr.map((item) => [item[key], item])).values());
};

/**
 * @deprecated, please use getUniqueListBy
 */
export const deleteDuplicatesFromList = (list: { [key: string]: any }[]) => {
  const namesList = list.map((item) => item.name);
  return list.filter((item, idx) => namesList.indexOf(item.name) === idx);
};

export const currentCurrency = () => {
  const currentCurrency = sessionStorage.getItem('currentCurrency');
  if (currentCurrency) {
    return currentCurrency;
  } else {
    return '₴';
  }
};

export const isMobiRetatl = (code: string): boolean => {
  if (!code) return false;

  return code.includes('MR');
};

export const initAutocomplete = ({ form, fieldId, fieldName, filterType }: IMapAutoComplate) => {
  return () => {
    const address = document.querySelector(`#${fieldId}`) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(address, {
      types: [filterType],
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.formatted_address) {
        form.setFieldsValue({
          [fieldName]: place.formatted_address,
        });
      }
    });
  };
};

export const debouncePromise = (fn, ms = 0) => {
  let timeoutId;
  const pending = [];
  return (...args) =>
    new Promise((res, rej) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const currentPending = [...pending];
        pending.length = 0;
        Promise.resolve(fn.apply(this, args)).then(
          (data) => {
            // @ts-ignore
            currentPending.forEach(({ resolve }) => resolve(data));
          },
          (error) => {
            // @ts-ignore
            currentPending.forEach(({ reject }) => reject(error));
          },
        );
      }, ms);
      // @ts-ignore
      pending.push({ resolve: res, reject: rej });
    });
};

export type Period = `${ChronoUnitSimplifiedDuplicate}_${number}`;

export const toDuration = (duration?: number, durationUnit?: ChronoUnitSimplifiedDuplicate) => {
  if (duration === undefined || !durationUnit) {
    return undefined;
  }

  return `${durationUnit}_${duration}` as Period;
};

export const fromDuration = (period: Period) => {
  const [durationUnit, duration] = period.split('_');

  return { duration: Number(duration), durationUnit: durationUnit as ChronoUnitSimplifiedDuplicate };
};

export const durationUnitDayjsMap: { [key in ChronoUnitSimplifiedDuplicate]?: ManipulateType } = {
  MONTHS: 'months',
  DAYS: 'days',
};

interface MergeArraysOptions<T, S> {
  /**
   * trim source to target length
   */
  allowTrimSource?: boolean;
  /**
   * by default key is 'id'
   */
  targetKey?: keyof T;
  /**
   * by default key is 'id'
   */
  sourceKey?: keyof S;
  findByIndex?: boolean;
}

// TODO front very complex and almost doesn't solve anything
/**
 * @deprecated
 */
export const mergeArrays = <T, S>(target: T[], source: S[], options?: MergeArraysOptions<T, S>) => {
  const sourceLength = source.length;
  const targetLength = target.length;

  if (sourceLength === 0) {
    return target;
  }

  if (options?.allowTrimSource && sourceLength > targetLength) {
    const base = source.slice(0, targetLength - 2);

    return map(base, function (item, index) {
      return merge(
        item,
        options.findByIndex
          ? target[index]
          : find(target, { [options.targetKey ?? 'id']: item[(options.sourceKey as string) ?? 'id'] }),
      );
    });
  }

  if (targetLength > sourceLength) {
    const tail = target.slice(sourceLength - 2);

    const base = map(source, function (item, index) {
      return merge(
        item,
        options?.findByIndex
          ? target[index]
          : find(target, { [options?.targetKey ?? 'id']: item[(options?.sourceKey as string) ?? 'id'] }),
      );
    });

    return [...base, ...tail];
  }

  return map(source, function (item, index) {
    return merge(
      item,
      options?.findByIndex
        ? target[index]
        : find(target, { [options?.targetKey ?? 'id']: item[(options?.sourceKey as string) ?? 'id'] }),
    );
  });
};

export const zeroPad = (value: number | string, length: number = 2) => {
  return value.toString().padStart(length, '0');
};

export const delay = (ms: number) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms);
  });
};

interface PollProps<T> {
  cb: () => Promise<T>;
  predicate: (res: T) => boolean;
  interval: number;
  timeout: number;
}

//TODO front clear interval after unmount
//TODO front retry counter
// from https://gist.github.com/ivan-marquez/e141b863bc77c1e6e9a4f0c30d72ee0a
export const poll = <T>({ cb, predicate, timeout, interval }: PollProps<T>) => {
  let start = Date.now();

  const run = (): Promise<T> => {
    return cb().then((res) => {
      if (predicate(res)) {
        // we know we're done here, return from here whatever you
        // want the final resolved value of the promise to be
        return res;
      } else {
        if (timeout !== 0 && Date.now() - start > timeout) {
          throw new Error('TIMEOUT');
        } else {
          // run again with a short delay
          return delay(interval).then(run);
        }
      }
    });
  };

  return run();
};

export const parseInn = (inn: string) => {
  const birthdayHash = Number(inn.slice(0, 5));
  const birthday = dayjs('1899-12-31').add(birthdayHash, 'days');
  const sex = Number(inn.charAt(8)) % 2 === 0 ? 'female' : 'male';

  return { birthday, sex };
};

export const getDMSProgram = (detatailsProgram: IDetailProgram[], contract: IContract) => {
  const codeId = contract.insuranceProgram.id;
  const programs: IDetailProgram[] = detatailsProgram.filter((program: IDetailProgram) =>
    program.code.includes('MED_DMS'),
  );
  const codeArray = detatailsProgram.filter((program: IDetailProgram) => program.id === codeId);
  const contractCode = codeArray[0]?.code;
  const isDMS: boolean =
    programs.length > 0 || contract.programCode === 'MED_DMS' || contractCode === 'MED_DMS' ? true : false;
  return isDMS;
};

export const getFilteredValue = (inputValue: string) => {
  const arrTimes = [
    '08:00-18:00',
    '08:30-18:30',
    '09:00-19:00',
    '09:30-19:30',
    '10:00-20:00',
    '10:30-20:30',
    '11:00-21:00',
    '11:30-21:30',
    '12:00-22:00',
  ];
  return arrTimes?.filter((item) => item.includes(inputValue));
};

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const getCurrencySymbol = (value: Currency): string => {
  const currencyMap: Record<Currency, string> = {
    [Currency.USD]: '$',
    [Currency.EUR]: '€',
    [Currency.UAH]: '₴',
  };
  return currencyMap[value];
};

export const hexToRgb = (hex: string) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `
    ${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}
  `
    : '';
};

export const insuranceCurrency = () => store.getState().me.data?.insuranceCompany?.currency;
// from https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript
export const getExtension = (path: string) => {
  // extract file name from full path ...
  // (supports `\\` and `/` separators)
  var basename = path.split(/[\\/]/).pop() ?? '',
    // get last position of `.`
    pos = basename.lastIndexOf('.');

  if (basename === '' || pos < 1)
    // if file name is empty or ...
    //  `.` not found (-1) or comes first (0)
    return '';

  // extract extension ignoring `.`
  return basename.slice(pos + 1);
};
