import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: #eeeff4;
  border-radius: 14px;
  padding: 10px 16px;
`;

const Label = styled.label`
  font-size: 13px;
  line-height: 20px;
  color: #a9b5bd;
  display: block;
`;

const TextArea = styled.textarea`
  display: block;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 22px;
  color: #363636;
  resize: none;
  width: 100%;
  height: 228px;
`;

const Input = ({ label, value, ...props }: any) => (
  <Wrapper>
    <Label>{label}</Label>
    <TextArea value={value} {...props} />
  </Wrapper>
);

export default Input;
