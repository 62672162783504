import colors from '../Theme/colors';
import { SalesStatisticsDtoBigDecimal, ClaimPaymentsStatisticDto } from 'types/dto/contracts-service';

const normalizeChartData = (data: SalesStatisticsDtoBigDecimal[], t?: any) => {
  const sortData = data
    .sort((a, b) => (b.sales?.currentValue ?? 0) - (a.sales?.currentValue ?? 0))
    .map((d, index) => {
      const label = d.name?.toLowerCase() === 'other' ? 'Інше' : d.name ?? '';
      return {
        id: d.name ?? '',
        label: d.name ? label : '',
        value: d.sales?.currentValue ?? 0,
        previousValue: d.sales?.previousValue ?? 0,
        color: colors[index % colors.length],
      };
    })
    .filter((item) => item.value);
  const fullValue = sortData.reduce((acc, value) => acc + (value.value ?? 0), 0);
  const chartData = sortData.map((item) => ({ ...item, fullValue }));
  return chartData;
};

export const normalizeClaimPaymentsChartData = (data: ClaimPaymentsStatisticDto[], t?: any) => {
  const sortData = data
    .sort((a, b) => (b.countPayments?.currentValue ?? 0) - (a.countPayments?.currentValue ?? 0))
    .map((d, index) => ({
      id: d.name ?? '',
      label: d.name?.toLowerCase() === 'other' ? 'Інше' : d.name ?? '',
      value: d.countPayments?.currentValue ?? 0,
      previousValue: d.countPayments?.previousValue ?? 0,
      color: colors[index % colors.length],
      sum: d.sumPayments?.currentValue,
    }))
    .filter((item) => item.value);
  const fullValue = sortData.reduce((acc, value) => acc + (value.value ?? 0), 0);
  const chartData = sortData.map((item) => ({ ...item, fullValue }));
  return chartData;
};

export default normalizeChartData;
