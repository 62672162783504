import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { UseHistory } from 'types';
import Icon from 'components/nextRedesign/Icon/Icon';
import { Button } from 'components/nextRedesign/Button/Button';
import { Badge } from 'antd';
import palleteRedesign from 'styles/palleteRedesign';
import { actions, getNotification } from 'store/me/me';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store/reducer';
import { AlertPanel } from '../AlertPanel/AlertPanel';
import { IconBell } from 'components/next/Icon/Icon';
import { useTimer } from 'react-timer-hook';
import config from 'config';

const cssBadge = css`
  .ant-badge-count {
    min-width: 16px;
    width: 16px;
    height: 16px;
    color: ${palleteRedesign.gray.lighter};
    line-height: 18px;
    padding: 0;
    font-size: 8px;
    box-shadow: 0 0 0 1px ${palleteRedesign.gray.lighter};
    cursor: pointer;
  }
`;

const cssHeader = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const cssButton = css`
  &.ant-btn {
    margin-right: 16px;
  }
`;

export const ClientHeader = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const dispatch = useDispatch<AppDispatch>();
  const notification = useSelector((state: AppState) => state.me.notification);

  const timer = useTimer({ autoStart: false, expiryTimestamp: new Date() });

  const runPoolingNotifications = () => {
    dispatch(getNotification()).finally(() => {
      timer.restart(new Date(Date.now() + config.NOTIFICATION_TIMEOUT));
    });
  };

  useEffect(() => {
    runPoolingNotifications();
  }, []);

  useEffect(() => {
    if (timer.isRunning) {
      return;
    }

    runPoolingNotifications();
  }, [timer.isRunning]);

  return (
    <>
      <div className={cssHeader}>
        {/* TODO Return when BA has business logic for this */}
        {/* <Button size="small" className={cssButton} type="primary">
          <Icon size="10px" type="circle" color="white" style={{ padding: '0 8px' }} /> {t('dashboard_client.call_us')}
        </Button>
        <Button size="small" className={cssButton} type="primary">
          <Icon size="14px" type="telegram" color="white" style={{ padding: '0 8px' }} />
          {t('dashboard_client.contact_with_us')}
        </Button> */}
        <div
          onClick={() => dispatch(actions.setNotification({ isOpenAlertPanel: true }))}
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Badge count={notification?.data?.count} offset={[-14, 15]} className={cssBadge}>
            <IconBell color="primary" style={{ fontSize: '24px', padding: '8px' }} />
          </Badge>
        </div>

        <Icon type="user" size="24px" color="primary" onClick={() => history.push(ROUTES.CLIENT.PROFILE)} />
      </div>

      <AlertPanel />
    </>
  );
};
