import dayjs, { Dayjs } from 'dayjs';
import { object, Infer, optional, number, date, string, refiner } from 'utils/struct';

export const schema = object({
  brand: optional(string()),
  region: optional(string()),
  mis: optional(string()),
  clinicType: optional(string()),
  clinicClass: optional(string()),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  brand: '',
  region: '',
  mis: '',
  clinicType: '',
  clinicClass: '',
};
