import { StatusError, StatusPending, StatusSuccess } from '../../../Icons';
import React from 'react';

const DocumentStatus = ({ status }: { status: string }) => {
  switch (status) {
    case 'FINISHED': {
      return <StatusError />;
    }
    case 'ACTIVE': {
      return <StatusSuccess />;
    }
    case 'DRAFT': {
      return <StatusPending color="#F2C94C" className="draft" />;
    }
    default: {
      return null;
    }
  }
};

const columns = (t) => {
  return [
    {
      title: '',
      value: 'status',
      key: 'status',
      width: 50,
      dataIndex: 'status',
      render: (status: string) => <DocumentStatus status={status} />,
    },
    {
      title: t('dash_board.agreement_table'),
      value: 'number',
      key: 'number',
      dataIndex: 'number',
      width: 235,
    },
    {
      title: t('dash_board.phone_table'),
      value: 'phoneNumber',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
      width: 145,
    },
    {
      title: t('dash_board.client_table'),
      value: 'clientName',
      key: 'clientName',
      dataIndex: 'clientName',
      width: 260,
    },
    {
      title: t('dash_board.serial_number_table'),
      value: 'insuranceObjectPid',
      key: 'insuranceObjectPid',
      dataIndex: 'insuranceObjectPid',
      width: 190,
    },
    {
      title: t('dash_board.name_table'),
      value: 'insuranceObjectName',
      key: 'insuranceObjectName',
      dataIndex: 'insuranceObjectName',
      width: 220,
    },
    {
      title: t('dash_board.concluded_table'),
      value: 'conclusionDate',
      key: 'conclusionDate',
      dataIndex: 'conclusionDate',
      width: 160,
    },
    {
      title: t('dash_board.sum_insured_table'),
      value: 'insuranceAmount',
      key: 'insuranceAmount',
      dataIndex: 'insuranceAmount',
      width: 160,
    },
    {
      title: t('dash_board.insurance_payment_table'),
      value: 'paymentAmount',
      key: 'paymentAmount',
      dataIndex: 'paymentAmount',
      width: 160,
    },
  ];
};

export default columns;
