import React, { useEffect, useReducer } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Col, Row, Typography, Grid } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import { css } from '@emotion/css';
import { DefaultOptionType } from 'antd/lib/select';

import { Password } from 'components/next/Password/Password';
import { useRequired } from 'hooks/useRequired';
import { Button } from 'components/next/Button/Button';

import { schema, Values } from './PersonalInfo.schema';
import { State } from '../SignUp';
import { InputField } from 'components/next/InputField/InputField';
import { apiContracts } from 'api/contracts';
import { Select } from 'components/next/Select/Select';
import { CompanyView, SignupAccountDto } from 'types/dto/contracts-service';
import { zeroPad } from 'utils/helpers';
import config from 'config';
import Step from '../Step/Step';
import Back from '../Back/Back';
import AuthContainer from 'components/next/AuthContainer/AuthContainer';

const cssSubmit = css`
  width: 150px;
`;

const getDefaultValues = (signupAccount: SignupAccountDto | null): Values => {
  return {
    firstName: signupAccount?.firstName ?? '',
    lastName: signupAccount?.lastName ?? '',
    middleName: signupAccount?.middleName ?? '',
    company: signupAccount?.partner?.id
      ? { key: signupAccount?.partner.id, label: signupAccount?.partner.name ?? '' }
      : null,
    partnerDepartment: null,
    password: '',
    confirmPassword: '',
  };
};

interface _State {
  submitting: boolean;
  loadingPartnerDepartments: boolean;
  partnerDepartments: CompanyView[];
}
type Props = {
  steps: number;
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const PersonalInfo = ({ steps, state, setState }: Props) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const timer = useTimer({
    expiryTimestamp: new Date(Date.now() + config.SIGN_UP_TIMEOUT),
    onExpire: () => setState({ step: 0 }),
  });

  const [_state, _setState] = useReducer(
    (prevState: _State, nextState: Partial<_State>): _State => ({ ...prevState, ...nextState }),
    {
      submitting: false,
      loadingPartnerDepartments: false,
      partnerDepartments: [],
    },
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues: getDefaultValues(state.signupAccount),
    mode: 'onChange',
  });
  const { requiredFields } = useRequired(schema, watch());

  useEffect(() => {
    _setState({ loadingPartnerDepartments: true });

    apiContracts.companyController
      .list9({ partnerDepartmentId: '<>null', partnerId: state.signupAccount?.partner?.id?.toString() })
      .then((res) => {
        _setState({ loadingPartnerDepartments: false, partnerDepartments: res.data });
      })
      .catch(() => {
        _setState({ loadingPartnerDepartments: false, partnerDepartments: [] });
      });
  }, []);

  const onSubmit = (values: Values) => {
    _setState({ submitting: true });

    apiContracts.managerSignupController
      .signup({
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        email: state.signupAccount?.email,
        phoneNumber: state.signupAccount?.phoneNumber,
        partnerCode: state.signupAccount?.partnerCode,
        insuranceCompanyId: values.company?.key,
        partnerDepartmentId: values.partnerDepartment ?? 0,
        partner: state.signupAccount?.partner,
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: state.signupAccount?.token,
      })
      .then(() => {
        _setState({ submitting: false });
        setState({
          step: state.step + 1,
          signupAccount: {
            ...state.signupAccount,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
          },
        });
      })
      .catch(() => {
        _setState({ submitting: false });
      });
  };

  const partnerDepartmentOptions: DefaultOptionType[] = _state.partnerDepartments.map((el) => ({
    value: el.partnerDepartmentId,
    label: el.name,
  }));

  const timerUI = `${zeroPad(timer.minutes)}:${zeroPad(timer.seconds)}`;

  return (
    <AuthContainer maxWidth="700px">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>{<Step step={state.step} steps={steps} />}</Col>

            <Col>
              <Typography.Text strong type="warning">
                {timerUI}
              </Typography.Text>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Typography.Title level={screens.lg ? 1 : 3}>{t('signup.info')}</Typography.Title>
        </Col>

        <Col span={24}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} lg={8}>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <InputField
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.last_name')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col xs={24} sm={24} lg={8}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <InputField
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.first_name')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col xs={24} sm={24} lg={8}>
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <InputField
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.middle_name')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col xs={24} sm={24} lg={12}>
                <Controller
                  name="company"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.company')}
                      colorBase="grey"
                      disabled
                      labelInValue
                    />
                  )}
                />
              </Col>

              <Col xs={24} sm={24} lg={12}>
                <Controller
                  name="partnerDepartment"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.units')}
                      colorBase="grey"
                      options={partnerDepartmentOptions}
                      loading={_state.loadingPartnerDepartments}
                      showSearch
                      allowClear
                    />
                  )}
                />
              </Col>

              <Col xs={24} sm={24} lg={12}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Password
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.enter_password')}
                      colorBase="grey"
                      autoComplete="new-password"
                    />
                  )}
                />
              </Col>

              <Col xs={24} sm={24} lg={12}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Password
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.confirm_password')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col span={24}>
                <Button type="primary" size="large" htmlType="submit" loading={_state.submitting} className={cssSubmit}>
                  {t('signup.next')}
                </Button>
              </Col>

              <Col span={24}>
                <Back handleBack={() => setState({ step: 0 })} />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </AuthContainer>
  );
};

export default PersonalInfo;
