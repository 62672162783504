import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { List2Params, ResultListDtoPartnerView } from 'types/dto/contracts-service';
import { getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import config from 'config';
import { apiContracts } from 'api/contracts';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoPartnerView | null;
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'PARTNERS/SET_DATA', payload } as const),
};

export const requestData =
  (params: List2Params = {}): ThunkType<Promise<ResultListDtoPartnerView>> =>
  async (dispatch, getState) => {
    const partners = getState().partnerList;
    const sorting = getSortBy(partners.data.sorting);

    return apiContracts.partnerController
      .list2({
        count: true,
        sorting,
        ...getPrivateFilters(partners.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const partners = getState().partnerList;

  dispatch(actions.setData({ loading: true }));

  dispatch(requestData({ ...getPageBy(partners.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export interface StorePartners {
  data: Data;
}
export const initialState: StorePartners = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
  },
};

const reducer = (state = initialState, action: InferActionTypes): StorePartners => {
  switch (action.type) {
    case 'PARTNERS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
