import React, { useEffect, useReducer } from 'react';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
// import { SelectProps } from 'antd/lib/select';
import { useDispatch, useSelector } from 'react-redux';

import { useRequired } from 'hooks/useRequired';
import Button from 'components/next/Button/Button';
import { Select } from 'components/next/Select/Select';
import { InputField } from 'components/next/InputField/InputField';
import { Group } from 'components/next/Group/Group';
import { getInstitutionsBrands, getInstitutionsClasses } from 'api/institution';
// import { DepartmentView, PartnerView } from 'types/dto/contracts-service';
// import { InsuranceProgramView } from 'types/dto/configuration-service';
// import { actions, loadData } from 'store/contractListNext/contractList';
import { AppState } from 'store/reducer';

import { schema, Values, initialValues } from './Filters.schema';
import { DefaultOptionType } from 'antd/lib/select';
// import config from 'config';

// TODO: delete
const clinicTypes = [
  'Вузькопрофільна клініка',
  'Стоматологія',
  'Амбулаторно-поліклінічна допомога',
  'Виклик лікаря додому/офісу',
  'Невідкладна допомога',
  'Лабораторія',
  'facilityType',
  'Стаціонар',
  'Багатопрофільна',
];

const cssInput = css`
  min-width: 175px;
`;
const cssForm = css`
  overflow-x: auto;
`;

interface State {
  // partnerId: number | null;
  // loadingPartners: boolean;
  // partners: PartnerView[];
  // loadingDepartments: boolean;
  // departments: DepartmentView[];
  // loadingInsurancePrograms: boolean;
  // insurancePrograms: InsuranceProgramView[];
  loadingClasses: boolean;
  classes: string[];
  loadingBrands: boolean;
  brands: string[];
  loadingTypes: boolean;
  types: string[];
}

const ClinicsFilters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((store: AppState) => ({
    loading: store.contractListNext.data.loading,
  }));
  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      // partnerId: null,
      // loadingPartners: false,
      // partners: [],
      // loadingDepartments: false,
      // departments: [],
      // loadingInsurancePrograms: false,
      // insurancePrograms: [],
      loadingClasses: false,
      classes: [],
      loadingBrands: false,
      brands: [],
      loadingTypes: false,
      types: clinicTypes,
    },
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues: initialValues,
  });
  const { requiredFields } = useRequired(schema, watch());

  // useEffect(() => {
  //   dispatch(actions.setFilters(initialValues));
  // }, []);

  useEffect(() => {
    setState({ loadingBrands: true, loadingClasses: true });

    getInstitutionsBrands()
      .then((res) => {
        setState({ loadingBrands: false, brands: res ?? [] });
      })
      .catch(() => {
        setState({ loadingBrands: false });
      });

    getInstitutionsClasses()
      .then((res) => {
        setState({ loadingClasses: false, classes: res ?? [] });
      })
      .catch(() => {
        setState({ loadingClasses: false });
      });
  }, []);

  const onSubmit = (values: Values) => {
    //TODO add api
    console.log(values);
  };

  const brandsOptions: DefaultOptionType[] = state.brands.map((el, i) => ({
    value: el,
    label: el,
  }));

  const classesOptions: DefaultOptionType[] = state.classes.map((el, i) => ({
    value: el,
    label: el,
  }));

  const typesOptions: DefaultOptionType[] = state.types.map((el, i) => ({
    value: el,
    label: el,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-test="filters" className={cssForm}>
      <Group>
        <Controller
          name="brand"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              errors={errors}
              requiredFields={requiredFields}
              onChange={(v) => onChange(v)}
              value={value}
              label={t('clinics.brand')}
              options={brandsOptions}
              showSearch
              allowClear
              loading={state.loadingBrands}
              classNameContainer={cssInput}
              colorBase="white"
            />
          )}
        />

        <Controller
          name="region"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <InputField
              name={name}
              errors={errors}
              requiredFields={requiredFields}
              onChange={(v) => onChange(v)}
              value={value}
              label={t('clinics.region')}
              allowClear
              classNameContainer={cssInput}
              colorBase="white"
            />
          )}
        />

        <Controller
          name="mis"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <InputField
              name={name}
              errors={errors}
              requiredFields={requiredFields}
              onChange={(v) => onChange(v)}
              value={value}
              label={t('clinics.mis')}
              allowClear
              classNameContainer={cssInput}
              colorBase="white"
            />
          )}
        />

        <Controller
          name="clinicType"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              errors={errors}
              requiredFields={requiredFields}
              onChange={(v) => onChange(v)}
              value={value}
              label={t('clinics.clinic_type')}
              options={typesOptions}
              showSearch
              allowClear
              loading={state.loadingTypes}
              classNameContainer={cssInput}
              colorBase="white"
            />
          )}
        />

        <Controller
          name="clinicClass"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Select
              name={name}
              errors={errors}
              requiredFields={requiredFields}
              onChange={(v) => onChange(v)}
              value={value}
              label={t('clinics.clinic_class')}
              options={classesOptions}
              showSearch
              allowClear
              loading={state.loadingClasses}
              classNameContainer={cssInput}
              colorBase="white"
            />
          )}
        />

        <Button htmlType="submit" type="primary" loading={loading}>
          {t('clinics.find_button')}
        </Button>
      </Group>
    </form>
  );
};

export default ClinicsFilters;
