import React from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAsync } from '../../../hooks/useAsync';
import { getOfferFile } from '../../../api/auth';
import history from '../../../routes/history';
import { ROUTES } from '../../../constants/routes';

import styles from './styles.module.less';
import successHouse from 'assets/img/layout/successHouse.svg';
import { LogoIcon } from '../../Icons';

interface IProps {
  contractNumber: number;
  externalId: string;
  startDate: string;
  phoneNumber: string;
}

export const SuccessPaymentPage: React.FC<IProps> = ({ contractNumber, startDate, phoneNumber, externalId }) => {
  const { doFetch: getOffer, value } = useAsync(getOfferFile);
  const { t } = useTranslation();

  const downLoadOffer = () => {
    const data = getOffer(externalId);
    data
      .then((item) => {
        if (value) {
          const link = document.createElement('a');
          // @ts-ignore
          link.href = value.data;
          link.target = '_blank';
          link.click();
        }
      })
      .catch((er) => {
        notification.error({
          message: t('popup.something_went_wrong'),
        });
        console.log('er: ', er);
      });
  };

  const goBack = () => {
    history.push(ROUTES.CLIENT_SIGN.replace(':id', externalId));
  };

  return (
    <div className={styles.SuccessPage__Container}>
      <div className={styles.SuccessPage__Header}>
        <span>Оплата пройшла успішно!</span>
        <span>Ви успішно склали договір страхування з ПРАТ «Інноваційний страховий капітал» </span>
      </div>
      <div className={styles.SuccessPage__Line} />
      <div className={styles.SuccessPage__Body}>
        <div className={styles.SuccessPage__BodyText}>
          <span>Шановний кліент</span>
          <span>
            Ви застраховані за договором страхування № {contractNumber} від {startDate}
          </span>
          <span>
            На Ваш номер телефону {phoneNumber} відправлена СМС з посиланням на електронний договір страхування.
          </span>
          <span>Також, Ви можете ознайомитися зі своїми договорами страхування в особистому кабінеті.</span>
          <span>
            Оферту з правилами страхування Ви можете переглянути -
            <span className={styles.SuccessPage__Link} onClick={downLoadOffer}>
              тут
            </span>
            .
          </span>
          <div className={styles.SuccessPage__InskInfo}>
            <div>
              <LogoIcon width={50} height={50} />
            </div>
            <div>
              <span>З повагою до Вас, команда ПРАТ «Інноваційний страховий капітал»</span>
            </div>
          </div>
        </div>
        <div className={styles.SuccessPage__BodyImg}>
          <img src={successHouse} alt={'house'} />
        </div>
      </div>
      <div className={styles.SuccessPage__Line} />
      <div className={styles.SuccessPage__ButtonContainer}>
        <button className={styles.SuccessPage__Button} onClick={goBack}>
          Зрозуміло
        </button>
      </div>
    </div>
  );
};
