import { composeReducer } from 'redux-compose-reducer';
import { AnyAction } from 'redux';

import { TYPES } from './actions';
import { DiscountsTypes } from 'types/discountsTypes';
import { PAGES, TABS } from 'screens/Discounts/utils';

const initialState = {
  discountsData: [],
  allDiscountsData: [],
  count: 0,
  partnerDiscountsData: [],
  partnerDiscountsCount: 0,
  promoCodeDiscountsData: [],
  promoCodeDiscountsCount: 0,
  allPromoCodeDiscountsData: [],

  discountTypes: {
    discountsDataPage: PAGES.PAGE,
    discountsDataPageSize: PAGES.PAGESIZE,
    discountsDataPageTotal: PAGES.TOTAL,
  },
  discountsInfo: {
    companies: [],
    configs: {},
    agentContracts: [],
    brands: [],
    partners: [],
    products: [],
    salesPoints: [],
    programs: [],
  },
  filterAction: {},
  filterCoefficient: {},
  filterPromo: {},
  discountTableFilters: {
    insuranceCompanyName: undefined,
    insuranceCompanyId: undefined,
    partnerName: undefined,
    partnerId: undefined,
    insuranceProductId: undefined,
    insuranceProgramIds: undefined,
    agentContractId: '',
    type: '',
    startDate: '',
    endDate: '',
  },
  promoChoosenFilters: {},
  promoTableFilter: [],
  promoSorter: null,

  filtersInfo: {
    companyId: '',
    ruleId: '',
    name: '',
    isObligatory: '',
  },
  choosenDiscount: [],
  choosenPromoCodeDiscount: {},

  modalStatus: '',
  pending: false,
  discountRowIndex: null,
  activeTabeName: { tabName: TABS.Action },
  promoCode: '',
};

const setDiscounts = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    discountsData: payload.data,
    count: payload.count,
  };
};
const setAllDiscounts = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    allDiscountsData: payload.data,
    count: payload.count,
  };
};

const setPartnerDiscounts = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    partnerDiscountsData: payload.data,
    partnerDiscountsCount: payload.count,
  };
};

const setPromoCodeDiscounts = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    promoCodeDiscountsData: payload.data,
    promoCodeDiscountsCount: payload.count,
  };
};
const setAllPromoDiscounts = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    allPromoCodeDiscountsData: payload.data,
  };
};

const setPending = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    pending: payload,
  };
};

const setFiltersInfo = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    filtersInfo: payload,
  };
};

const setPromoChoosenFilter = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    promoChoosenFilters: {
      ...state.promoChoosenFilters,
      [payload.data.name]: payload.data.value,
    },
  };
};
const setClearPromoFilter = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    promoChoosenFilters: {},
  };
};

const setDiscountsPage = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    discountTypes: {
      discountsDataPage: payload.page,
      discountsDataPageSize: payload.pageSize,
      discountsDataPageTotal: payload.pageTotal,
    },
  };
};
const setModalStatus = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    modalStatus: payload,
  };
};
const setRowIndex = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    discountRowIndex: payload.index,
  };
};

const setPromoFilter = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    promoTableFilter: [payload.data, ...state.promoTableFilter],
  };
};
const setPromoFilterClear = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    promoTableFilter: [],
  };
};

const setPromoSorter = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    promoSorter: payload.data,
  };
};

const setPromo = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    promoCode: payload.data,
  };
};

const setDiscountsInfoData = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    discountsInfo: {
      ...state.discountsInfo,
      [payload.name]: payload.data,
    },
  };
};
const setFilterAction = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    filterAction: {
      ...state.filterAction,
      [payload.name]: payload.data,
    },
  };
};

const setFilterActionSaved = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    filterAction: payload.data,
  };
};

const setFilterCoefficient = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    filterCoefficient: {
      ...state.filterCoefficient,
      [payload.name]: payload.data,
    },
  };
};

const setFilterCoefficientSaved = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    filterCoefficient: payload.data,
  };
};
const setFilterPromo = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    filterPromo: {
      ...state.filterPromo,
      [payload.name]: payload.data,
    },
  };
};

const setFilterPromoSaved = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    filterPromo: payload.data,
  };
};
const setDiscountTableFilters = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    discountTableFilters: payload.data,
  };
};
const setEndDate = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    endDate: payload.data,
  };
};
const setChoosenDiscount = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    choosenDiscount: payload.data,
  };
};
const setChoosenPromoCodeDiscount = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    choosenPromoCodeDiscount: payload.data,
  };
};
const setDiscountActiveTab = (state: DiscountsTypes, { payload }: AnyAction): DiscountsTypes => {
  return {
    ...state,
    activeTabeName: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setFiltersInfo,
    setDiscounts,
    setAllDiscounts,
    setModalStatus,
    setDiscountsPage,
    setDiscountsInfoData,
    setDiscountTableFilters,
    setEndDate,
    setChoosenDiscount,
    setChoosenPromoCodeDiscount,
    setPending,
    setRowIndex,
    setPromoFilter,
    setClearPromoFilter,
    setPromo,
    setDiscountActiveTab,
    setPartnerDiscounts,
    setPromoCodeDiscounts,
    setAllPromoDiscounts,
    setFilterAction,
    setFilterActionSaved,
    setFilterCoefficientSaved,
    setFilterPromoSaved,
    setFilterCoefficient,
    setFilterPromo,
    setPromoChoosenFilter,
    setPromoFilterClear,
    setPromoSorter,
  },
});
