import React from 'react';
import { css, cx } from '@emotion/css';
import { FieldErrors } from 'react-hook-form';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';
import palleteRedesign from 'styles/palleteRedesign';

const inputStyleInvalid = {
  border: '2px solid red',
};

interface Props extends ReactCodeInputProps {
  label?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  colorBase?: ColorBase;
  errorPosition?: Position;
  classNameContainer?: string;
  errorClassName?: string;
  isRequired?: boolean;
  hasError?: boolean;
}

export const CodeInput = ({
  name,
  errors,
  requiredFields,
  label,
  colorBase = 'none',
  className,
  errorPosition,
  classNameContainer,
  errorClassName,
  isRequired,
  hasError,
  ...props
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      isRequired={isRequired}
      hasError={hasError}
      label={label}
      colorBase={colorBase}
      errorPosition={errorPosition}
      className={classNameContainer}
      errorClassName={errorClassName}
    >
      <ReactCodeInput
        name={name}
        data-test={name}
        className={cx(
          css`
            input {
              width: 43px;
              height: 54px;
              border-radius: 8px;
              font-size: 32px;
              margin-right: 8px;
              text-align: center;
              color: ${palleteRedesign.basic.black};
              border: 2px solid #a9b5bd;

              &:focus-visible {
                outline: none;
              }
            }
          `,
          className,
        )}
        inputStyleInvalid={inputStyleInvalid}
        {...props}
      />
    </InputContainer>
  );
};
