import { CheckboxValueType } from 'antd/lib/checkbox/Group';

import { t } from 'i18next';
import { DiscountType, InitiatorType, PromoCodeType } from 'types/dto/configuration-service';
import { ContractStatus } from 'types/dto/contracts-service';
import { filtersDefaultValue, getInitialValues } from './components/DiscountFilter/utils';
import dayjs from 'dayjs';
import { IFilterObj } from './components/DiscountFilter/types';
import { IRegisterTypesOption } from 'types/registerTypes';
import { IDiscountFilters } from './components/DiscountFilter/types';

export enum TABS {
  Action = 'Action',
  Coefficient = 'Coefficient',
  Promo = 'Promo_Code',
}
export enum PAGES {
  PAGE = 1,
  PAGESIZE = 50,
  TOTAL = 0,
}

export const getCorrectList = (
  checkedList: CheckboxValueType[],
  dataIndex: string,
  isDiscount: boolean,
  isPromo?: boolean,
) => {
  if (isDiscount) {
    if (dataIndex === 'status') {
      const listChecked = checkedList.map((status) =>
        status === t(`discounts.Table_Row_status_DRAFT`)
          ? ContractStatus.DRAFT
          : status === t(`discounts.Table_Row_status_ACTIVE`)
          ? ContractStatus.ACTIVE
          : status === t(`discounts.Table_Row_status_FINISHED`)
          ? ContractStatus.FINISHED
          : status,
      );
      return listChecked;
    }
    if (dataIndex === 'type') {
      if (isPromo) {
        const listChecked = checkedList.map((type) =>
          type === t(`enums.PromoCodeType.ONE_OFF`)
            ? PromoCodeType.ONE_OFF
            : type === t(`enums.PromoCodeType.REUSABLE`)
            ? PromoCodeType.REUSABLE
            : '',
        );
        return listChecked;
      }
      const listChecked = checkedList.map((type) =>
        type === t(`settings.modal_discounts_PROMOTION`)
          ? DiscountType.PROMOTION
          : type === t(`settings.modal_discounts_PROMO_CODE`)
          ? DiscountType.PROMO_CODE
          : type === t(`settings.modal_discounts_PARTNER_COEFFICIENT`)
          ? DiscountType.PARTNER_COEFFICIENT
          : type,
      );
      return listChecked;
    }
    if (dataIndex === 'initiator') {
      const listChecked = checkedList.map((initiator) =>
        initiator === t(`discounts.Table_Initiator_PARTNER_BY_COMMISSION`)
          ? InitiatorType.PARTNER_BY_COMMISSION
          : initiator === t(`discounts.Table_Initiator_INSURANCE_COMPANY`)
          ? InitiatorType.INSURANCE_COMPANY
          : initiator,
      );
      return listChecked;
    }
  }
  return checkedList;
};

export const getOptions = (dataIndex: string, filteredItems: string[], isDiscount: boolean, isPromo?: boolean) => {
  if (isDiscount) {
    if (dataIndex === 'status') {
      const statusArray = Object.keys(ContractStatus);
      const filteredArr = filteredItems.map((status) =>
        statusArray.includes(status) ? t(`discounts.Table_Row_status_${status}`) : status,
      );
      return filteredArr;
    }
    if (dataIndex === 'amount') {
      const filteredArr = filteredItems.map((amount) => `${amount}%`);
      return filteredArr;
    }
    if (dataIndex === 'type') {
      if (isPromo) {
        const typeArray = Object.keys(PromoCodeType);
        const filteredArr = filteredItems.map((type) =>
          typeArray.includes(type) ? t(`enums.PromoCodeType.${type}`) : type,
        );
        return filteredArr;
      }
      const typeArray = Object.keys(DiscountType);
      const filteredArr = filteredItems.map((type) =>
        typeArray.includes(type) ? t(`settings.modal_discounts_${type}`) : type,
      );
      return filteredArr;
    }
    if (dataIndex === 'initiator') {
      const initiatorArray = Object.keys(InitiatorType);
      const filteredArr = filteredItems.map((initiator) =>
        initiatorArray.includes(initiator) ? t(`discounts.Table_Initiator_${initiator}`) : initiator,
      );
      return filteredArr;
    }
    if (dataIndex === 'startDate' || dataIndex === 'endDate' || dataIndex === 'updatedDate') {
      return filteredItems.map((item) => dayjs(item).format('DD.MM.YYYY'));
    }
  }

  return filteredItems;
};
const removeUndefinedProperties = (filters: IDiscountFilters) => {
  return Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== undefined));
};

export const getFilteredBody = (
  filterAction: IFilterObj,
  filterCoefficient: IFilterObj,
  filterPromo: IFilterObj,
  activeTab: string,
) => {
  const company: IRegisterTypesOption = getInitialValues(
    filterAction,
    filterCoefficient,
    filterPromo,
    activeTab,
    'insuranceCompany',
  );
  const partner: IRegisterTypesOption = getInitialValues(
    filterAction,
    filterCoefficient,
    filterPromo,
    activeTab,
    'partner',
  );
  const product: IRegisterTypesOption = getInitialValues(
    filterAction,
    filterCoefficient,
    filterPromo,
    activeTab,
    'insuranceProduct',
  );
  const program: IRegisterTypesOption = getInitialValues(
    filterAction,
    filterCoefficient,
    filterPromo,
    activeTab,
    'insuranceProgram',
  );
  const type: IRegisterTypesOption = getInitialValues(filterAction, filterCoefficient, filterPromo, activeTab, 'type');
  const agentContract: IRegisterTypesOption = getInitialValues(
    filterAction,
    filterCoefficient,
    filterPromo,
    activeTab,
    'agentContract',
  );
  const startDate: string = getInitialValues(filterAction, filterCoefficient, filterPromo, activeTab, 'startDate');
  const endDate: string = getInitialValues(filterAction, filterCoefficient, filterPromo, activeTab, 'endDate');
  const dateFrom =
    typeof startDate === 'object'
      ? dayjs().startOf('month').format('YYYY-MM-DD')
      : dayjs(startDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
  const dateTo =
    typeof endDate === 'object' ? dayjs().format('YYYY-MM-DD') : dayjs(endDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
  const start = `>=${dateFrom}`;
  const end = `<=${dateTo}`;

  const filters: IDiscountFilters = {
    insuranceCompanyId: company.id !== filtersDefaultValue ? company.id : undefined,
    partnerId: partner.id !== filtersDefaultValue ? `${partner.id}` : undefined,
    insuranceProductId: product.id !== filtersDefaultValue ? `${product.id}` : undefined,
    insuranceProgramIds: program.id !== filtersDefaultValue ? `${program.id}` : undefined,
    type: type.id !== filtersDefaultValue ? `${type.id}` : undefined,
    agentContractId: agentContract.id !== filtersDefaultValue ? `${agentContract.id}` : undefined,
    startDate: start,
    endDate: end,
  };
  const filteredCody = removeUndefinedProperties(filters);
  return filteredCody;
};
