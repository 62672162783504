//translations - fondy.applicationError file
export const getErrors = (code) =>
  ({
    1000: 'Причина помилки невідома, чи необхідний детальний розбір у службі підтримки',
    1002: 'Причина помилки невідома, чи необхідний детальний розбір у службі підтримки',
    1003: 'Неправильний CVV2 код картки. У деяких випадках код може повертатися, якщо неправильний термін дії картки',
    1004: 'Карта заблокована, встановлений статус або закрита для оплати в інтернет',
    1005: 'Неправильний формат даних. Ймовірна причина – надто маленька сума платежу',
    1006: 'Налаштування Мерчант на стороні FONDY.com не коректні',
    1009: "Один або кілька обов'язкових параметрів порожні",
    1010: 'Надісланий запит порожній',
    1011: "Один або кілька обов'язкових параметрів не передано",
    1012: 'Продавець використовує валюту, не дозволену або не налаштовану на стороні FONDY',
    1013: 'Переданий параметр order_id не є унікальним для цього мерчанта',
    1014: 'Згенерований мерчантом підпис у запиті не вірний',
    1015: 'Невірний номер картки',
    1016: 'Мерчант не зареєстрований',
    1017: 'Мерчанту не дозволено жодного з методів оплати, які були передані у запиті',
    1019: 'Зроблено спробу оплатити замовлення, термін дії якого закінчився',
    1023: 'Невірна сума',
    1024: 'Помилка перевірки пароля 3DSecure',
    1025: 'Невірний термін дії картки',
    1040: 'Перевищено інтернет ліміт за картою',
    1041: 'Карта у чорному списку',
    1042: 'Картка вкрадена',
    1045: 'Карта заблокована банком',
    1047: 'Перевищено ліміт на кількість оплат',
    1048: 'Перевищено ліміт на суму оплат',
    1049: 'Невідома помилка',
    1052: 'Минув термін дії платежу',
    1092: 'Телефон, вказаний платником, неправильний',
    1096: 'Електронна пошта, вказана платником, неправильна',
    1111: 'Карта цієї платіжної системи не підтримується',
  }[code]);
