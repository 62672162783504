import React from 'react';
import { AutoComplete as AutoCompleteAntd } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { css, cx } from '@emotion/css';
import { FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

const cssRoot = css`
  width: 100%;
  height: 30px;
`;

interface Props<T> extends AutoCompleteProps<T> {
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  label?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
}

export const AutoComplete = <T,>({
  name,
  errors,
  requiredFields,
  label,
  colorBase = 'grey',
  errorPosition,
  className,
  isRequired,
  hasError,
  ...props
}: Props<T>) => {
  const { t } = useTranslation();

  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <AutoCompleteAntd<T>
        data-test={name}
        className={cx(cssRoot, className)}
        placeholder={t('common.search')}
        bordered={false}
        {...props}
      />
    </InputContainer>
  );
};
