import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import {
  DepartmentView,
  List5Params,
  List7Params,
  PartnerView,
  ResultListDtoContractView,
} from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/ContractList/Filters/Filters.schema';
import config from 'config';
import { InsuranceProgramView, List4Params } from 'types/dto/configuration-service';
import { apiContracts } from 'api/contracts';
import { apiConfiguration } from 'api/configuration';
import onDownload from 'callbacks/onDownload';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoContractView | null;
}
type Filters = Values & { initialization: boolean };
interface Partners {
  loading: boolean;
  data: PartnerView[];
}
interface PartnerDepartments {
  loading: boolean;
  data: DepartmentView[];
}
interface InsurancePrograms {
  loading: boolean;
  data: InsuranceProgramView[];
}
export enum Action {
  ALL = 'ALL',
  CURRENT = 'CURRENT',
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'CONTRACTS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'CONTRACTS/SET_FILTERS', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'CONTRACTS/SET_PARTNERS', payload } as const),
  setPartnerDepartments: (payload: Partial<PartnerDepartments>) =>
    ({ type: 'CONTRACTS/SET_PARTNER_DEPARTMENTS', payload } as const),
  setInsurancePrograms: (payload: Partial<InsurancePrograms>) =>
    ({ type: 'CONTRACTS/SET_INSURANCE_PROGRAMS', payload } as const),
};

export const downloadReport =
  (key: Action): ThunkType<void> =>
  async (dispatch, getState) => {
    const contracts = getState().contractListNext;
    const sorting = getSortBy(contracts.data.sorting);

    await onDownload(() =>
      apiContracts.contractController
        .printXlsxContractList1(
          {
            distinct: true,
            status: '<>DRAFT',
            // TODO back
            // @ts-ignore
            timeZone: dayjs.tz.guess(),
            current: key === Action.CURRENT ? String(true) : undefined,
            partnerId: contracts.filters?.partnerId?.toString(),
            partnerDepartmentId: [-1, -2].includes(contracts.filters?.partnerDepartmentId ?? 0)
              ? undefined
              : contracts.filters?.partnerDepartmentId?.toString(),
            insuranceProgramId: contracts.filters?.insuranceProgramId?.toString(),
            phoneNumber: contracts.filters?.phoneNumber ?? undefined,
            conclusionDate: getDateRange(contracts.filters?.dateFrom, contracts.filters?.dateTo) ?? '',
            sorting: sorting ? `${sorting},-id` : undefined,
            test: [-1, -2].includes(contracts.filters?.partnerDepartmentId ?? 0)
              ? contracts.filters?.partnerDepartmentId === -2
                ? String(true)
                : String(false)
              : undefined,
            ...getPrivateFilters(contracts.data.privateFilters),
          },
          { format: 'blob' },
        )
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

export const requestData =
  (params: List7Params = {}): ThunkType<Promise<ResultListDtoContractView>> =>
  async (dispatch, getState) => {
    const contracts = getState().contractListNext;
    const sorting = getSortBy(contracts.data.sorting);

    return apiContracts.contractController
      .list7({
        count: true,
        current: String(true),
        total: true,
        distinct: true,
        attributes:
          'currencyCode,insuranceProgramName,originalContractNumber,status,number,phoneNumber,insuranceProductCode,clientName,insuranceObjectPid,insuranceObjectName,conclusionDate,startDate,endDate,insuranceAmount,paymentAmount,numberPayments,id,calculatedPaymentAmount,partnerDepartmentRegion,sellerName,sellerPhoneNumber,insuranceObjectType,verifiedAt,subAgreementNumber,subAgreementConclusionDate,participateInAgentAct,test,check_number,partnerDepartmentsName,subAgreementType,email,orderId',
        totalAttributes: 'paymentAmount,calculatedPaymentAmount',
        partnerId: contracts.filters?.partnerId?.toString(),
        partnerDepartmentId: [-1, -2].includes(contracts.filters?.partnerDepartmentId ?? 0)
          ? undefined
          : contracts.filters?.partnerDepartmentId?.toString(),
        insuranceProgramId: contracts.filters?.insuranceProgramId?.toString(),
        phoneNumber: contracts.filters?.phoneNumber ?? undefined,
        conclusionDate: getDateRange(contracts.filters?.dateFrom, contracts.filters?.dateTo) ?? '',
        sorting: sorting ? `${sorting},-id` : '-id',
        test: [-1, -2].includes(contracts.filters?.partnerDepartmentId ?? 0)
          ? contracts.filters?.partnerDepartmentId === -2
            ? String(true)
            : String(false)
          : undefined,
        ...getPrivateFilters(contracts.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const contracts = getState().contractListNext;

  dispatch(actions.setData({ loading: true, data: null }));

  dispatch(requestData({ ...getPageBy(contracts.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false, data: null }));
    });
};

export const loadPartners = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setPartners({ loading: true, data: [] }));

  apiContracts.partnerController
    .list2({ distinct: true, page_size: 300 })
    .then((res) => {
      dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch(() => {
      dispatch(actions.setPartners({ loading: false, data: [] }));
    });
};

export const loadPartnerDepartments =
  (query: List5Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartnerDepartments({ loading: true, data: [] }));

    apiContracts.departmentController
      .list5({
        distinct: true,
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: [] }));
      });
  };

export const loadInsurancePrograms =
  (query: List4Params = {}): ThunkType<void> =>
  async (dispatch, getState) => {
    const contracts = getState().contractListNext;

    dispatch(actions.setInsurancePrograms({ loading: true, data: [] }));

    const partner = contracts.partners.data.find((el) => el.id?.toString() === query.partnerId);

    apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        page_size: -1,
        insuranceCompanyId: partner?.insuranceCompanyId?.toString(),
        attributes: 'id,name',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setInsurancePrograms({ loading: false, data: [] }));
      });
  };

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  const partner = getState().me.data?.details?.partner;
  const partnerDepartment = getState().me.data?.details?.partnerDepartment;

  if (partner) {
    dispatch(actions.setFilters({ partnerId: partner.id }));
    dispatch(actions.setPartners({ data: [{ id: partner.id, shortName: partner.name }] }));
  }

  if (partnerDepartment) {
    dispatch(actions.setFilters({ partnerDepartmentId: partnerDepartment.id }));
    dispatch(actions.setPartnerDepartments({ data: [{ id: partnerDepartment.id, name: partnerDepartment.name }] }));
  }

  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadPartners());
  dispatch(loadPartnerDepartments({ partnerId: partner ? partner.id?.toString() : undefined }));
  dispatch(loadInsurancePrograms({ partnerId: partner ? partner.id?.toString() : undefined }));
};

export interface StoreContracts {
  data: Data;
  filters: Filters;
  partners: Partners;
  partnerDepartments: PartnerDepartments;
  insurancePrograms: InsurancePrograms;
}
export const initialState: StoreContracts = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'conclusionDate', order: 'descend' },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  partners: { loading: false, data: [] },
  partnerDepartments: { loading: false, data: [] },
  insurancePrograms: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreContracts => {
  switch (action.type) {
    case 'CONTRACTS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'CONTRACTS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'CONTRACTS/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'CONTRACTS/SET_PARTNER_DEPARTMENTS':
      return { ...state, partnerDepartments: { ...state.partnerDepartments, ...action.payload } };
    case 'CONTRACTS/SET_INSURANCE_PROGRAMS':
      return { ...state, insurancePrograms: { ...state.insurancePrograms, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
