import React from 'react';

import Icon from 'components/nextRedesign/Icon/Icon';
import { NotificationType } from 'types/dto/notification-service';

const map: Record<NotificationType, ({ iconBackground }: { iconBackground: boolean }) => JSX.Element> = {
  SUCCESS: ({ iconBackground }) => (
    <Icon type="check" color="success" background={iconBackground ? 'success' : 'none'} />
  ),
  WARNING: ({ iconBackground }) => (
    <Icon type="warning" color="warning" background={iconBackground ? 'warning' : 'none'} />
  ),
  ERROR: ({ iconBackground }) => <Icon type="warning" color="error" background={iconBackground ? 'error' : 'none'} />,
  INFO: ({ iconBackground }) => (
    <Icon type="warning" color="warning" background={iconBackground ? 'warning' : 'none'} />
  ),
};

interface Props {
  status: NotificationType | undefined;
  iconBackground?: boolean;
}

const Status = ({ status, iconBackground = false }: Props) => {
  if (!status) {
    return null;
  }

  const Component = map[status];

  return <Component iconBackground={iconBackground} />;
};

export default Status;
