import { createTypes } from 'redux-compose-reducer';
import { getUsersInfo, searchContracts } from '../../api/contracts';

export const TYPES = createTypes('privateOffice', ['setPrivateOffice']);

export const setPrivateOffice =
  () =>
  async (dispatch: any): Promise<void> => {
    try {
      const { data } = await searchContracts({ current: true });
      const { data: userInfo } = await getUsersInfo();
      dispatch({ type: TYPES.setPrivateOffice, payload: { userInfo, data } });
    } catch (e) {
      console.error(e);
    }
  };
