import React from 'react';
import { SvgIcon } from '@material-ui/core';

const Plus = (props: any) => (
  <SvgIcon fill="none" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 0H20V20H0V0Z" fill="transparent" />
    <path
      d="M17.375 9.375H10.625V2.625C10.625 2.27981 10.3452 2 10 2C9.65481 2 9.375 2.27981 9.375 2.625V9.375H2.625C2.27981 9.375 2 9.65481 2 10C2 10.3452 2.27981 10.625 2.625 10.625H9.375V17.375C9.375 17.7202 9.65481 18 10 18C10.3452 18 10.625 17.7202 10.625 17.375V10.625H17.375C17.7202 10.625 18 10.3452 18 10C18 9.65481 17.7202 9.375 17.375 9.375Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.3"
    />
  </SvgIcon>
);

export default Plus;
