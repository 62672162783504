interface IGetContractType {
  insurancePrograms: { code: string; id: number; name: string; policies?: any }[];
  programCode: string;
  programId: number;
  exspectedCode: string[];
}

export const getContractType = ({
  insurancePrograms,
  programCode,
  programId,
  exspectedCode,
}: IGetContractType): boolean => {
  const triggerProgram = insurancePrograms?.find((item) => item.id === programId);
  const fromInPrograms = exspectedCode.some((item) => triggerProgram?.code?.includes(item));
  const fromCode = exspectedCode.some((item) => programCode?.includes(item));

  return fromInPrograms || fromCode;
};
