import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { css, cx } from '@emotion/css';
import { FieldErrors } from 'react-hook-form';

import { Position, colorBaseMap, InputContainer, ColorBase } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

interface Props extends InputProps {
  label: string;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
  colorBase?: ColorBase;
}

export const Password = ({
  name,
  errors,
  requiredFields,
  label,
  classNameContainer,
  errorPosition,
  isRequired,
  hasError,
  colorBase = 'none',
  ...props
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      isRequired={isRequired}
      hasError={hasError}
      label={label}
      className={classNameContainer}
      errorPosition={errorPosition}
      colorBase={colorBase}
    >
      <Input.Password
        data-test={name}
        name={name}
        className={cx(
          css`
            &.ant-input-affix-wrapper {
              border-radius: 12px;
              border: none;
              background-color: ${colorBaseMap[colorBase]};
            }

            &.ant-input-affix-wrapper-focused {
              box-shadow: none;
            }

            input {
              background: ${colorBaseMap[colorBase]};
              border-radius: 8px;
              border: none;
              padding: 8px 5px !important;
              font-size: 16px !important;
              font-style: normal;
              font-weight: 400;
              line-height: 167%;
              height: auto;
              max-height: 51px;

              :focus {
                box-shadow: none;
              }

              .ant-input {
                border: none;
                background: ${colorBaseMap[colorBase]};

                :focus {
                  box-shadow: none;
                }
              }

              &.ant-input[disabled] {
                background: ${colorBaseMap[colorBase]};
              }
            }
            .anticon {
              transform: rotateY(180deg);
            }
          `,
        )}
        {...props}
      />
    </InputContainer>
  );
};
