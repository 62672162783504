import { ActionTypes } from 'types';
import { ThunkAction } from 'redux-thunk';
import { t } from 'i18next';

import { AppState } from 'store/reducer';
import {
  ClaimDocumentConfigurationDto,
  ConfigurationProgramRiskDto,
  CreateInsuranceProgramRiskParams,
  DocumentTypeDto,
  GetInsuranceProgramParams,
  InsuranceTypeView,
  List3Params,
  ListParams,
  ProgramRiskDto,
  ResolutionType,
  ResolutionTypeConfigurationDto,
  ResolutionTypeDocumentConfigurationDto,
  RiskView,
} from 'types/dto/configuration-service';
import { apiConfiguration } from 'api/configuration';
import { notification } from 'antd';
import i18n from 'translations/i18n';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

export interface SettlementTypeModal {
  isDublicate?: boolean;
  details?: SettlementTypeModalDetails;
}

export interface SettlementTypeModalDetails {
  riskResolutionTypeId?: number;
  resolutionType?: ResolutionType;
  riskName?: string;
  documents?: { data?: ClaimDocumentConfigurationDto[]; loading?: boolean };
  documentTypeList?: { data?: DocumentTypeDto[]; loading?: boolean };
  documentsWhichAreNotConfigured?: { data?: ResolutionTypeConfigurationDto[]; loading?: boolean };
}
interface Risk {
  isCreate?: boolean;
  data?: ConfigurationProgramRiskDto;
  saving?: boolean;
  insuranceTypes?: {
    loading?: boolean;
    data?: InsuranceTypeView[];
  };
  risks?: {
    loading?: boolean;
    data?: RiskView[];
  };
}

export const actions = {
  setSettlementTypeModal: (payload: SettlementTypeModal | null) =>
    ({ type: 'INSURANCE_PROGRAM/SET_SETTLEMENT_TYPE_MODAL', payload } as const),
  setSettlementTypeModalDetails: (payload: SettlementTypeModalDetails) =>
    ({ type: 'INSURANCE_PROGRAM/SET_SETTLEMENT_TYPE_MODAL_DETAILS', payload } as const),
  closeSettlementTypeModal: () => ({ type: 'INSURANCE_PROGRAM/CLOSE_SETTLEMENT_TYPE_MODAL' } as const),
  setRisk: (payload: Risk | null) => ({ type: 'INSURANCE_PROGRAM/SET_RISK', payload } as const),
};

export const getInsuranceProgram =
  (query: GetInsuranceProgramParams): ThunkType<void> =>
  (dispatch) => {
    // dispatch(actions.setRisk({ risks: { loading: true, data: [] } }));

    apiConfiguration.insuranceProgramController
      .getInsuranceProgram(query)
      .then((res) => {
        // dispatch(actions.setRisk({ risks: { loading: false, data: res.data.resultList ?? [] } }));
      })
      .catch((error) => {
        // dispatch(actions.setRisk({ risks: { loading: false, data: [] } }));

        return Promise.reject();
      });
  };

export const getDocumentConfiguration =
  (resolutionTypeId: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setSettlementTypeModalDetails({ documents: { loading: true } }));

    return apiConfiguration.claimsConfigurationController
      .getResolutionTypeDocumentConfiguration(resolutionTypeId)
      .then(({ data }) => {
        dispatch(
          actions.setSettlementTypeModalDetails({
            documents: { data: data.documentConfigurations, loading: false },
          }),
        );
      })
      .catch((error) => {
        dispatch(actions.setSettlementTypeModalDetails({ documents: { data: [], loading: false } }));
      });
  };

export const getDocumentsList = (): ThunkType<Promise<void>> => (dispatch) => {
  dispatch(actions.setSettlementTypeModalDetails({ documentTypeList: { loading: true } }));
  // name: '' - need for get all the documents
  return apiConfiguration.documentConfigurationController
    .getAllDocumentTypes({ name: '' })
    .then(({ data }) => {
      dispatch(actions.setSettlementTypeModalDetails({ documentTypeList: { loading: false, data } }));
    })
    .catch((error) => {
      dispatch(actions.setSettlementTypeModalDetails({ documentTypeList: { loading: false, data: [] } }));
    });
};

export const getDocumentsWhichAreNotConfigured =
  (insuranceProgramId: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setSettlementTypeModalDetails({ documentsWhichAreNotConfigured: { loading: true } }));

    return apiConfiguration.claimsConfigurationController
      .getResolutionTypeConfigurations({ insuranceProgramId })
      .then(({ data }) => {
        dispatch(actions.setSettlementTypeModalDetails({ documentsWhichAreNotConfigured: { loading: false, data } }));
      })
      .catch((error) => {
        dispatch(
          actions.setSettlementTypeModalDetails({ documentsWhichAreNotConfigured: { loading: false, data: [] } }),
        );
      });
  };

export const updateResolutionTypeDocument =
  (
    id: number,
    seed: ResolutionTypeDocumentConfigurationDto,
  ): ThunkType<Promise<ClaimDocumentConfigurationDto[] | undefined>> =>
  (dispatch) => {
    return apiConfiguration.claimsConfigurationController
      .createResolutionTypeDocumentConfiguration(id, seed)
      .then((res) => {
        notification.success({ message: i18n.t('insurance_program_details.saved') });
        return res.data;
      })
      .catch((error) => {
        notification.error({ message: error.message });
        return Promise.reject(undefined);
      });
  };

export const deleteResolutionTypeDocument =
  (id: number): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiConfiguration.claimsConfigurationController
      .deleteResolutionTypeDocumentConfiguration(id)
      .then(() => {
        notification.success({ message: i18n.t('insurance_program_details.document_was_deleted') });
        return undefined;
      })
      .catch((error) => {
        notification.error({ message: error.message });
        return Promise.reject(undefined);
      });
  };

export const duplicateResolutionTypes =
  (resolutionTypeId: number, seed: number[]): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiConfiguration.claimsConfigurationController
      .duplicateResolutionTypes(resolutionTypeId, seed)
      .then(() => {
        notification.success({ message: i18n.t('insurance_program_details.document_was_dublicate') });
        return undefined;
      })
      .catch((error) => {
        notification.error({ message: error.message });
        return Promise.reject(undefined);
      });
  };

export const getCurrentDocumentsConfigurations =
  (resolutionTypeId: number): ThunkType<Promise<ResolutionTypeDocumentConfigurationDto>> =>
  (dispatch) => {
    return apiConfiguration.claimsConfigurationController
      .getResolutionTypeDocumentConfiguration(resolutionTypeId)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return Promise.reject(undefined);
      });
  };

export const getInsuranceTypes =
  (query: List3Params): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setRisk({ insuranceTypes: { loading: true, data: [] } }));

    apiConfiguration.insuranceTypeController
      .list3({
        attributes: 'name,id,insuranceRuleId,legislativeDocument,insuranceRuleName',
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setRisk({ insuranceTypes: { loading: false, data: res.data.resultList ?? [] } }));
      })
      .catch((error) => {
        dispatch(actions.setRisk({ insuranceTypes: { loading: false, data: [] } }));

        return Promise.reject();
      });
  };

export const getRisks =
  (query: ListParams): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setRisk({ risks: { loading: true, data: [] } }));

    apiConfiguration.riskController
      .list({
        attributes: 'name,id',
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setRisk({ risks: { loading: false, data: res.data.resultList ?? [] } }));
      })
      .catch((error) => {
        dispatch(actions.setRisk({ risks: { loading: false, data: [] } }));

        return Promise.reject();
      });
  };

export const updateRisk =
  (id: number, data: ProgramRiskDto): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setRisk({ saving: true }));

    return apiConfiguration.insuranceProgramRiskController
      .updateInsuranceProgramRisk(id, data)
      .then((res) => {
        dispatch(actions.setRisk({ saving: false }));

        notification.success({ message: t('insurance_configuration.data_updated') });
      })
      .catch((error) => {
        dispatch(actions.setRisk({ saving: false }));

        error?.response?.data?.errors.forEach((error) => {
          if (error.code === 'Insurance rule for current risk is incorrect') {
            notification.error({ message: t('insurance_program_list.ins_rule_validation') });
          }
          if (error.code === 'PROGRAM_RISK_WITH_CURRENT_RISK_EXISTS') {
            notification.error({ message: t('insurance_program_list.risk_save_exist') });
          }
        });

        return Promise.reject();
      });
  };

export const createRisk =
  (query: CreateInsuranceProgramRiskParams, data: ProgramRiskDto): ThunkType<Promise<void>> =>
  (dispatch) => {
    dispatch(actions.setRisk({ saving: true }));

    return apiConfiguration.insuranceProgramRiskController
      .createInsuranceProgramRisk(query, data)
      .then((res) => {
        dispatch(actions.setRisk({ saving: false }));

        notification.success({ message: t('popup.save') });
      })
      .catch((error) => {
        dispatch(actions.setRisk({ saving: false }));

        error?.response?.data?.errors.forEach((error) => {
          if (error.code === 'Insurance rule for current risk is incorrect') {
            notification.error({ message: t('insurance_program_list.ins_rule_validation') });
          }
          if (error.code === 'PROGRAM_RISK_WITH_CURRENT_RISK_EXISTS') {
            notification.error({ message: t('insurance_program_list.risk_save_exist') });
          }
        });

        return Promise.reject();
      });
  };

export interface StoreInsuranceProgram {
  settlementTypeModal: SettlementTypeModal | null;
  risk: Risk | null;
}

const initialState: StoreInsuranceProgram = {
  settlementTypeModal: null,
  risk: null,
};

const reducer = (state = initialState, action: InferActionTypes): StoreInsuranceProgram => {
  switch (action.type) {
    case 'INSURANCE_PROGRAM/SET_SETTLEMENT_TYPE_MODAL':
      return { ...state, settlementTypeModal: { ...state.settlementTypeModal, ...action.payload } };
    case 'INSURANCE_PROGRAM/SET_SETTLEMENT_TYPE_MODAL_DETAILS':
      return {
        ...state,
        settlementTypeModal: {
          ...state.settlementTypeModal,
          details: { ...state.settlementTypeModal?.details, ...action.payload },
        },
      };
    case 'INSURANCE_PROGRAM/CLOSE_SETTLEMENT_TYPE_MODAL':
      return { ...state, settlementTypeModal: null };
    case 'INSURANCE_PROGRAM/SET_RISK':
      return { ...state, risk: action.payload === null ? null : { ...state.risk, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
