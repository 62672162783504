import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Tab } from 'rc-tabs/lib/interface';
import Tabs from 'components/nextRedesign/Tabs/Tabs';

import closeIcon from 'assets/img/layout/greyClose.svg';
import { AppState } from 'store/reducer';
import { InsuranceProgramView } from 'types/dto/configuration-service';
import { Product } from 'components/modals/CreateModal/products/Products';
import { WidgetProducts } from 'components/modals/CreateModal/products/WidgetProducts';
import { setInsuranceProductCode } from 'store/contractDetails/actions';
import { getAnyProducts, getRegularProducts, getWidgetProducts } from 'store/insuranceProducts/insuranceProducts';

import styles from './CreateModal.module.less';

interface ICreateModal {
  isOpen: boolean;
  handleClose: () => void;
}

export enum DetailsTabKey {
  PROGRAMS = 'PROGRAMS',
  WIDGETS = 'WIDGETS',
}

const CreateModal = ({ isOpen, handleClose }: ICreateModal) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const partnerId = useSelector((store: AppState) => store.me.data?.details?.partner?.id);
  const anyProducts = useSelector((store: AppState) => store.insuranceProductsNext.anyProducts.data);
  const regularProducts = useSelector((store: AppState) => store.insuranceProductsNext.regularProducts.data);
  const widgetProducts = useSelector((store: AppState) => store.insuranceProductsNext.widgetProducts.data);

  const [activeTab, setActiveTab] = useState<DetailsTabKey>(DetailsTabKey.PROGRAMS);

  const regular = anyProducts.concat(regularProducts).reduce((acc: InsuranceProgramView[], el) => {
    if (!acc.find((item) => item.id === el.id)) {
      acc.push(el);
    }
    return acc;
  }, []);
  const widget = anyProducts.concat(widgetProducts).reduce((acc: InsuranceProgramView[], el) => {
    if (!acc.find((item) => item.id === el.id)) {
      acc.push(el);
    }
    return acc;
  }, []);

  useEffect(() => {
    dispatch(getAnyProducts(t, { partnerId: partnerId?.toString(), distinct: true }));
    dispatch(getRegularProducts(t, { partnerId: partnerId?.toString(), distinct: true }));
    dispatch(getWidgetProducts(t, { partnerId: partnerId?.toString(), distinct: true }));
  }, []);

  useEffect(() => {
    if (widget.length && !regular.length) {
      setActiveTab(DetailsTabKey.WIDGETS);
    }
  }, [regular, widget]);

  const handleClick = (code) => {
    sessionStorage.setItem('insuranceProductCode', code);
    dispatch(setInsuranceProductCode(code));
  };

  const tabs: (Tab & { visible: boolean; tabBarExtraContent?: React.ReactNode })[] = [
    {
      key: DetailsTabKey.PROGRAMS,
      label: t('contract_list.programs'),
      children: (
        <div className={styles.content} onClick={handleClose}>
          {regular.map((item) => {
            return (
              <Product
                name={item.name ?? ''}
                code={item.code ?? ''}
                key={item.id}
                handleClose={handleClose}
                handleClick={handleClick}
              />
            );
          })}
        </div>
      ),
      visible: !!anyProducts.length || !!regularProducts.length,
    },
    {
      key: DetailsTabKey.WIDGETS,
      label: t('contract_list.widgets'),
      children: (
        <div className={styles.content} onClick={handleClose}>
          {widget.map((item) => {
            return (
              <WidgetProducts
                name={item.name ?? ''}
                code={item.code ?? ''}
                key={item.id}
                handleClose={handleClose}
                handleClick={handleClick}
              />
            );
          })}
        </div>
      ),
      visible: !!anyProducts.length || !!widgetProducts.length,
    },
  ];
  const visibleTabs = tabs.filter((el) => el.visible).map(({ visible, tabBarExtraContent, ...el }) => el as Tab);

  return (
    <Modal
      open={isOpen}
      centered
      className={styles.wrapper}
      maskClosable={false}
      onCancel={handleClose}
      footer={null}
      destroyOnClose={true}
      closable={false}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}> {t('contract_list.choose_an_insurance_program')} </div>
          <div className={styles.close} onClick={handleClose}>
            <div className={styles.closeIcon} data-id="Close-Icon">
              <img src={closeIcon} width="16" height="16" alt="close icon" />
            </div>
          </div>
        </div>
        <Tabs
          items={visibleTabs}
          activeKey={activeTab}
          onChange={(activeTab) => setActiveTab(activeTab as DetailsTabKey)}
        />
      </div>
    </Modal>
  );
};

export default CreateModal;
