import { createTypes } from 'redux-compose-reducer';
import * as InsuredEventsAPI from 'api/InsuredEvents';
import { ThunkAction } from 'redux-thunk';
import { StoreType } from 'types/store';
import { Action, AnyAction } from 'redux';
import { notification } from 'antd';
import { formatDocumentsData } from 'utils/helpers';
import { Dispatch } from 'react';

export const TYPES = createTypes('insuredEvent', [
  'setDocumentsData',
  'clearDocumentsData',
  'setDocumentInfo',
  'setDocumentDates',
  'setDocuments',
  'setInsuredDocumentDelete',
  'setPreviewList',
  'setUploadDocumentDates',
  'setSignatoryEmail',
]);

type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const getInsuredEventDocuments =
  ({ riskId, contractId, resolutionType, prevDocuments, t }): ThunkResult =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const response = await InsuredEventsAPI.getRiskDocuments(riskId, contractId, resolutionType);
      let payload = response.data ? formatDocumentsData(response.data) : [];
      if (prevDocuments.length > 0) {
        payload = payload.reduce((acc, item) => {
          let newItem = null;

          prevDocuments.forEach((document) => {
            if (document.documentType.id === item.documentType.id) {
              if (document.calculable !== item.calculable) {
                newItem = { ...document, calculable: item.calculable, obligatory: item.obligatory };
              } else {
                newItem = document;
              }
            }
          });

          if (newItem) {
            return [...acc, newItem];
          }

          return [...acc, item];
        }, []);
        dispatch({ type: TYPES.setDocumentsData, payload });
      } else {
        dispatch({ type: TYPES.setDocumentsData, payload });
      }
    } catch (err) {
      //@ts-ignore
      if (!window.navigator.connection.downlink) {
        notification.error({
          message: t('popup.no_connection'),
          description: t('popup.check_your_network_connection'),
        });
      } else {
        notification.error({
          message: t('popup.something_went_wrong'),
          description: t('popup.load_document_list'),
        });
      }
    }
  };

export const clearDocumentsData = () => (dispatch: any) => dispatch({ type: TYPES.clearDocumentsData });

export const setDocumentInfo = (value: string, code: string) => (dispatch: any) => {
  dispatch({ type: TYPES.setDocumentInfo, payload: { value, code } });
};

export const setDocumentDates =
  ({ id, createDate, approveDate }: { id: number; createDate?: string; approveDate?: string }) =>
  (dispatch: any) => {
    dispatch({ type: TYPES.setDocumentDates, payload: { id, createDate, approveDate } });
  };

export const setUploadDocumentDates =
  ({ id, createDate, approveDate }: { id: number; createDate?: string; approveDate?: string }) =>
  (dispatch: any) => {
    dispatch({ type: TYPES.setUploadDocumentDates, payload: { id, createDate, approveDate } });
  };

export const deleteDocument = (id: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setInsuredDocumentDelete, payload: { id } });
};

export const setDocuments = (documents: any) => ({ type: TYPES.setDocuments, payload: documents });

export const sendDocumentTypeCodes = (claimId: number, documentTypeCodes: string[]) => async () => {
  await InsuredEventsAPI.sendDocumentTypeCodes({ claimId, documentTypeCodes });
};

export const getSignatoryEmail = (mail: string) => (dispatch: any) => {
  dispatch({ type: TYPES.setSignatoryEmail, payload: mail });
};
