import { combineReducers } from 'redux';

import main, { InferActionTypes as InferActionTypesUserMain } from './main';
import activity, { InferActionTypes as InferActionTypesUserActivity } from './activity';

export { default } from './reducer';

export const user = combineReducers({ main, activity });

export type StoreUser = ReturnType<typeof user>;

export type InferActionTypes = InferActionTypesUserMain | InferActionTypesUserActivity;
