import { AppState } from 'store/reducer';
import { ThunkAction } from 'redux-thunk';
import { ActionTypes } from 'types';
import { apiContracts } from 'api/contracts';
import { notification } from 'antd';
import {
  ContractClaimImeiDuplicationDto,
  ContractImeiDuplicationsView,
  ListContractImeiDuplicationsParams,
  ListOptionInfoDtoParams,
  PartnerView,
  RequestParams,
} from 'types/dto/contracts-service';
import i18n from 'translations/i18n';
import groupBy from 'lodash/groupBy';
import { Dictionary } from 'lodash';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Partners {
  loading: boolean;
  data: PartnerView[];
}

interface ContractsWithDublicateImei {
  loading: boolean;
  data: ContractImeiDuplicationsView[];
  dataGroup: Dictionary<ContractImeiDuplicationsView[]> | null;
}

interface InsuranceCaseInfo {
  loading: boolean;
  data: ContractClaimImeiDuplicationDto | null;
}

export const actions = {
  setPartners: (payload: Partial<Partners>) => ({ type: 'DASHBOARD_SETTLEMENT/SET_PARTNERS', payload } as const),
  setContractsWithDublicateImei: (payload: Partial<ContractsWithDublicateImei>) =>
    ({ type: 'DASHBOARD_SETTLEMENT/SET_CONTRACTS_WITH_DUBLICATE', payload } as const),
  setInsuranceCaseInfo: (payload: Partial<InsuranceCaseInfo>) =>
    ({ type: 'DASHBOARD_SETTLEMENT/SET_INSURANCE_CASE_INFO', payload } as const),
};

export const getPartners = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setPartners({ loading: true, data: [] }));

  apiContracts.partnerController
    .list2({ distinct: true, page_size: -1 })
    .then((res) => {
      dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch((error) => {
      dispatch(actions.setPartners({ loading: false, data: [] }));

      if (error?.response?.data?.message) {
        notification.error({ message: error.response.data.message });
      }
    });
};

export const getContractsWithDublicate = (query: ListContractImeiDuplicationsParams) => (dispatch) => {
  dispatch(actions.setContractsWithDublicateImei({ loading: true, data: [], dataGroup: null }));

  apiContracts.contractController
    .listContractImeiDuplications(query)
    .then((res) => {
      dispatch(actions.setContractsWithDublicateImei({ loading: false, data: res.data.resultList ?? [] }));
      dispatch(
        actions.setContractsWithDublicateImei({ dataGroup: groupBy(res.data.resultList ?? [], (elem) => elem.imei) }),
      );
    })
    .catch((error) => {
      dispatch(actions.setContractsWithDublicateImei({ loading: false, data: [], dataGroup: null }));

      if (error?.response?.data?.message) {
        notification.error({ message: error.response.data.message });
      }
    });
};

export const getInsurenceCasesInfo = (query: ListOptionInfoDtoParams) => (dispatch) => {
  dispatch(actions.setInsuranceCaseInfo({ loading: true }));

  apiContracts.contractController
    .listOptionInfoDto(query)
    .then((res) => {
      dispatch(actions.setInsuranceCaseInfo({ loading: false, data: res.data }));
    })
    .catch((error) => {
      dispatch(actions.setInsuranceCaseInfo({ loading: false }));

      if (error?.response?.data?.message) {
        notification.error({ message: error.response.data.message });
      }
    });
};

export interface StoreDashboardSettlement {
  partners: Partners;
  contractsWithDublicateImei: ContractsWithDublicateImei;
  insuranceCaseInfo: InsuranceCaseInfo;
}

const initialState: StoreDashboardSettlement = {
  partners: { loading: false, data: [] },
  contractsWithDublicateImei: {
    loading: false,
    data: [],
    dataGroup: null,
  },
  insuranceCaseInfo: {
    loading: false,
    data: null,
  },
};

const reducer = (state = initialState, action: InferActionTypes) => {
  switch (action.type) {
    case 'DASHBOARD_SETTLEMENT/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'DASHBOARD_SETTLEMENT/SET_CONTRACTS_WITH_DUBLICATE': {
      return { ...state, contractsWithDublicateImei: { ...state.contractsWithDublicateImei, ...action.payload } };
    }
    case 'DASHBOARD_SETTLEMENT/SET_INSURANCE_CASE_INFO': {
      return { ...state, insuranceCaseInfo: { ...state.insuranceCaseInfo, ...action.payload } };
    }

    default:
      return state;
  }
};

export default reducer;
