import React from 'react';
import { Column } from '@ant-design/plots';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DaysStatisticsDto } from 'types/dto/contracts-service';
import { currencyFormat, dateFormat } from 'utils/formatters';

type SeriesField = 'seriesField_1' | 'seriesField_2';
export interface DataRaw extends DaysStatisticsDto {
  seriesField_1: number;
  seriesField_2: number;
}
export interface Data {
  xField: string;
  yField: number;
  seriesField: SeriesField;
  raw: DataRaw;
}
interface Props {
  data: Data[];
  currency: string;
}

const ByMonthChart = ({ data, currency }: Props) => {
  const { t } = useTranslation();

  return (
    <Column
      data={data}
      xField="xField"
      yField="yField"
      seriesField="seriesField"
      isGroup
      height={350}
      xAxis={{
        label: {
          formatter: (v) => {
            return dayjs(v).format('D');
          },
        },
      }}
      tooltip={{
        title: (v) => {
          return dateFormat(dayjs(v));
        },
        formatter: (datum: Pick<Data, 'xField' | 'yField' | 'seriesField'>) => {
          return {
            name:
              datum.seriesField === 'seriesField_1'
                ? t('dashboard_extend.byMonth.contractPaymentSum')
                : t('dashboard_extend.byMonth.claimPaymentSum'),
            value: currencyFormat(datum.yField, currency),
          };
        },
      }}
      legend={{
        itemName: {
          formatter: (v: SeriesField) => {
            return v === 'seriesField_1'
              ? t('dashboard_extend.byMonth.contractPaymentSum')
              : t('dashboard_extend.byMonth.claimPaymentSum');
          },
        },
      }}
      color={['#c2db8a', '#95cff4']}
      columnStyle={{
        radius: [20, 20, 0, 0],
      }}
      state={{
        active: {
          style: {
            lineWidth: 'none',
          },
        },
      }}
    />
  );
};

const MemoizedByMonthChart = React.memo(ByMonthChart);

export default MemoizedByMonthChart;
