import { notification } from 'antd';
import { t } from 'i18next';

import { downloadFile, parseHeaders } from 'utils/download';

export interface Callback {
  data: Blob;
  headers: any;
}
interface Options {
  open?: boolean;
}

const onDownload = async (cb: () => Promise<Callback>, options?: Options) => {
  notification.info({ message: t('popup.download_will_start') });

  try {
    const res = await cb();

    const { parsedContentDisposition, parsedContentType } = parseHeaders(res.headers);

    downloadFile({
      blob: new Blob([res.data], { type: parsedContentType.type }),
      name: parsedContentDisposition.parameters.filename,
      open: options?.open,
    });

    return res;
  } catch (err) {
    if (err === undefined) {
      return Promise.reject();
    }

    notification.error({ message: t('popup.upload_error_excel'), description: t('popup.try_again_later') });

    return Promise.reject();
  }
};

export default onDownload;
