import { notification } from 'antd';
import { createTypes } from 'redux-compose-reducer';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  createSubdivision,
  getAgentsData,
  getPartnerOptionsTabData,
  getSudivisionsList,
  updatePartnerOptionsTabData,
  getRegionsData,
  createRegion,
  updateRegion,
} from 'api/partnerCreate';
import { StoreType } from 'types/store';
import { IPartnerOptionsTab, IPartnerRegionsTab } from './types';

type ThunkResult = ThunkAction<void, StoreType, null, Action>;
type IAction<A> = (data: A, t) => ThunkResult;

export const TYPES = createTypes('partnersCreate', [
  'getInsuranceCompanies',
  'getPartnersTypes',
  'getPartnersData',
  'getPartnersOptionsTabData',
  'getPartnersRegionsTabData',
  'getPartnersInternetSalesTabData',
  'getAgentsData',
  'resetPartnerData',
  'getInsPrograms',
  'getInsProgramsConfiguration',
  'getProgram',
  'getInsProgramsModal',
  'resetProgram',
  'getRegions',
  'getSubDivisions',
  'setSorting',
  'setOptions',
  'setUnits',
  'setMax',
  'getCompaniesInternetSales',
  'getSubdivisionInternetSales',
  'setAgentModal',
  'closeAgentModal',
]);

const getValidationText = (data, t) => {
  if (data === 'name') {
    return t('popup.name');
  }
  if (data === 'departmentNumber') {
    return t('popup.prefix');
  }
};

export const setOptionsFieldValue = (name, value) => {
  return (dispatch) => {
    dispatch({
      type: TYPES.setOptions,
      payload: {
        name,
        value,
      },
    });
  };
};

export const sendOptionsTabData = (id: number, data: IPartnerOptionsTab, t) => async (): Promise<any> => {
  try {
    await updatePartnerOptionsTabData(id, data);
  } catch (e) {
    notification.error({
      message: t('popup.retrieving_data'),
      description: t('popup.try_again_products'),
    });
  }
};

export const sendRegionsData =
  (id: number, data: IPartnerRegionsTab[], t, type: string, regionId?: number) =>
  async (dispatch): Promise<any> => {
    try {
      type === 'CREATE' ? await createRegion(id, data) : await updateRegion(id, data[0], regionId);
      await dispatch(getRegionsTabData(id, t));
      notification.success({
        message: t('popup.save'),
      });
    } catch (e) {
      if (e.response.status === 400) {
        const { data } = e.response;
        if (data.errors) {
          notification.error({
            message: t('popup.region_already_exists'),
            description: t('popup.try_again_products'),
          });
          return;
        }
      }
      notification.error({
        message: t('popup.retrieving_data'),
        description: t('popup.try_again_products'),
      });
    }
  };

export const getAgents =
  (partnerId: number, t): ThunkResult =>
  async (dispatch) => {
    try {
      const { data } = await getAgentsData(partnerId);
      dispatch({ type: TYPES.getAgentsData, payload: { data } });
    } catch (e) {
      notification.error({
        message: t('popup.getting_agents'),
        description: t('popup.try_again_products'),
      });
    }
  };

export const getOptionsTabData =
  (partnerId, t): ThunkResult =>
  async (dispatch) => {
    try {
      const { data } = await getPartnerOptionsTabData(partnerId);

      dispatch({ type: TYPES.getPartnersOptionsTabData, payload: data });
    } catch (e) {
      notification.error({
        message: t('popup.retrieving_data'),
        description: t('popup.try_again_products'),
      });
    }
  };

export const getRegionsTabData =
  (regionsId, t): ThunkResult =>
  async (dispatch) => {
    try {
      const { data } = await getRegionsData(regionsId);

      dispatch({ type: TYPES.getPartnersRegionsTabData, payload: data });
    } catch (error) {
      notification.error({
        message: t('popup.retrieving_data'),
        description: t('popup.try_again_products'),
      });
    }
  };

export const getSubDivisions =
  (partnerId: number, params?: any, errors?: { mes: string; desc: string }) => async (dispatch) => {
    try {
      const { data } = await getSudivisionsList(partnerId, params);

      dispatch({ type: TYPES.getSubDivisions, payload: data });
    } catch (e) {
      notification.error({
        message: errors?.mes,
        description: errors?.desc,
      });
    }
  };

export const sendSubdivision: IAction<any> = (data, t) => async (dispatch, getState) => {
  const {
    partnerCreate: {
      data: { id },
    },
  } = getState();
  try {
    await createSubdivision(data);
    if (id) await dispatch(getSubDivisions(id));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors.forEach((item) => {
        if (item.code === 'NOT_UNIQUE') {
          notification.error({
            message: `${t('popup.subdivision_with_such')} ${getValidationText(item.field, t)} ${t(
              'popup.already_exists',
            )}`,
          });
        }
      });
    }
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};
