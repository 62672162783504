const columnsList = (t) => {
  return [
    {
      title: '№',
      value: 'id',
    },
    {
      title: t('contract_details.expected_payment_payments_table'),
      value: 'expectedPaymentAmount',
    },
    {
      title: t('contract_details.pay_to_payments_table'),
      value: 'expectedPaymentDate',
    },
    {
      title: t('contract_details.coating_with_payments_table'),
      value: 'periodStartDate',
    },
    {
      title: t('contract_details.coverage_by_payments_table'),
      value: 'periodEndDate',
    },
    {
      title: t('contract_details.payment_amount_payments_table'),
      value: 'paymentAmount',
    },
    {
      title: t('contract_details.left_pay_payments_table'),
      value: 'leftToPay',
    },
    {
      title: t('contract_details.payment_date_payments_table'),
      value: 'paymentDate',
    },
    {
      title: t('contract_details.document_number_payments_table'),
      value: 'checkNumber',
    },
  ];
};

export default columnsList;
