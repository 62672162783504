import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes } from 'types';
import {
  IntegrationItemCountDto,
  IntegrationPackageInformationDto,
  IntegrationRequestDto,
  OptionDto,
} from 'types/dto/contracts-service';
import {
  ListProductsParams,
  FindAllIntegrationConfigurationsParams,
  ResultListDtoInsuranceProductView,
} from 'types/dto/configuration-service';
import { apiContracts } from 'api/contracts';
import { apiConfiguration } from 'api/configuration';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface InsuranceProducts {
  loading: boolean;
  data: ResultListDtoInsuranceProductView | null;
}
interface IntegrationConfigurations {
  loading: boolean;
  data: OptionDto[];
}
interface Process {
  loading: boolean;
  data: IntegrationPackageInformationDto | null;
  processing: boolean;
}
interface CountTransfer {
  loading: boolean;
  data: IntegrationItemCountDto | null;
}
interface TransferStatus {
  loading: boolean;
  data: IntegrationPackageInformationDto | null;
  cancelling: boolean;
}

export const actions = {
  reset: () => ({ type: 'INTEGRATION/RESET' } as const),
  setInsuranceProducts: (payload: Partial<InsuranceProducts>) =>
    ({ type: 'INTEGRATION/SET_INSURANCE_PRODUCTS', payload } as const),
  setIntegrationConfigurations: (payload: Partial<IntegrationConfigurations>) =>
    ({ type: 'INTEGRATION/SET_INTEGRATION_CONFIGURATIONS', payload } as const),
  setProcess: (payload: Partial<Process>) => ({ type: 'INTEGRATION/SET_PROCESS', payload } as const),
  setCountTransfer: (payload: Partial<CountTransfer>) => ({ type: 'INTEGRATION/SET_COUNT_TRANSFER', payload } as const),
  setTransferStatus: (payload: Partial<TransferStatus>) =>
    ({ type: 'INTEGRATION/SET_TRANSFER_STATUS', payload } as const),
};

export const getCountTransfer =
  (query: IntegrationRequestDto): ThunkType<Promise<IntegrationItemCountDto>> =>
  async (dispatch, getState) => {
    dispatch(actions.setCountTransfer({ loading: true, data: null }));

    return apiContracts.integrationController
      .getCountTransfer(query)
      .then((res) => {
        dispatch(actions.setCountTransfer({ loading: false, data: res.data }));

        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setCountTransfer(initialState.countTransfer));

        return Promise.resolve({ count: 0 });
      });
  };

export const getTransferStatus =
  (): ThunkType<Promise<IntegrationPackageInformationDto>> => async (dispatch, getState) => {
    const process = getState().integration.process.data;

    if (!process?.id) {
      throw new Error('ID_EMPTY');
    }

    dispatch(actions.setTransferStatus({ loading: true }));

    return apiContracts.integrationController
      .getTransferStatus(process.id)
      .then((res) => {
        dispatch(actions.setTransferStatus({ data: res.data }));

        return res.data;
      })
      .catch(() => {
        dispatch(actions.setTransferStatus(initialState.transferStatus));

        return Promise.reject();
      });
  };

export const interruptTransferring = (): ThunkType<Promise<void>> => async (dispatch, getState) => {
  const process = getState().integration.process.data;

  if (!process?.id) {
    return Promise.reject();
  }

  dispatch(actions.setTransferStatus({ cancelling: true }));

  return apiContracts.integrationController
    .interrupt(process.id)
    .then((res) => {
      dispatch(actions.setTransferStatus({ cancelling: false }));

      return res.data;
    })
    .catch(() => {
      dispatch(actions.setTransferStatus({ cancelling: false }));

      return Promise.reject();
    });
};

export const getInsuranceProducts =
  (query: ListProductsParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setInsuranceProducts({ loading: true, data: null }));

    apiConfiguration.insuranceProductController
      .listProducts({
        distinct: true,
        attributes: 'id,name,code',
        ...query,
      })
      .then((res) => {
        dispatch(actions.setInsuranceProducts({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setInsuranceProducts(initialState.insuranceProducts));
      });
  };

export const getIntegrationConfigurations =
  (query: FindAllIntegrationConfigurationsParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setIntegrationConfigurations({ loading: true, data: [] }));

    apiConfiguration.integrationController
      .findAllIntegrationConfigurations(query)
      .then((res) => {
        dispatch(actions.setIntegrationConfigurations({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setIntegrationConfigurations(initialState.integrationConfigurations));
      });
  };

export const importData =
  (query: IntegrationRequestDto): ThunkType<Promise<IntegrationPackageInformationDto>> =>
  async (dispatch, getState) => {
    dispatch(actions.setProcess({ loading: true, data: null, processing: false }));

    return apiContracts.integrationController
      .importItems(query)
      .then((res) => {
        dispatch(actions.setProcess({ loading: false, data: res.data, processing: true }));

        return res.data;
      })
      .catch(() => {
        dispatch(actions.setProcess(initialState.process));

        return Promise.reject();
      });
  };

export const exportData =
  (query: IntegrationRequestDto): ThunkType<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(actions.setProcess({ loading: true, data: null, processing: false }));

    return apiContracts.integrationController
      .exportItems(query)
      .then((res) => {
        dispatch(actions.setProcess({ loading: false, data: res.data, processing: true }));
      })
      .catch(() => {
        dispatch(actions.setProcess(initialState.process));

        return Promise.reject();
      });
  };

export const autoVerification =
  (configurationId: number, file: File): ThunkType<Promise<IntegrationPackageInformationDto>> =>
  async (dispatch, getState) => {
    // dispatch(actions.setTransfer({ loading: true }));

    return apiContracts.integrationController
      .verifyNewContractsAutomatically(configurationId, { file })
      .then((res) => res.data)
      .catch(() => Promise.reject());
  };

export interface StoreIntegration {
  insuranceProducts: InsuranceProducts;
  integrationConfigurations: IntegrationConfigurations;
  process: Process;
  countTransfer: CountTransfer;
  transferStatus: TransferStatus;
}
export const initialState: StoreIntegration = {
  insuranceProducts: { loading: false, data: null },
  integrationConfigurations: { loading: false, data: [] },
  process: { loading: false, data: null, processing: false },
  countTransfer: { loading: false, data: null },
  transferStatus: { loading: false, data: null, cancelling: false },
};

const reducer = (state = initialState, action: InferActionTypes): StoreIntegration => {
  switch (action.type) {
    case 'INTEGRATION/RESET':
      return initialState;
    case 'INTEGRATION/SET_INSURANCE_PRODUCTS':
      return { ...state, insuranceProducts: { ...state.insuranceProducts, ...action.payload } };
    case 'INTEGRATION/SET_INTEGRATION_CONFIGURATIONS':
      return { ...state, integrationConfigurations: { ...state.integrationConfigurations, ...action.payload } };
    case 'INTEGRATION/SET_PROCESS':
      return { ...state, process: { ...state.process, ...action.payload } };
    case 'INTEGRATION/SET_COUNT_TRANSFER':
      return { ...state, countTransfer: { ...state.countTransfer, ...action.payload } };
    case 'INTEGRATION/SET_TRANSFER_STATUS':
      return { ...state, transferStatus: { ...state.transferStatus, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
