import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import {
  DepartmentView,
  InsuranceCompanyView,
  List2Params,
  List5Params,
  PartnerView,
  ResultListDtoDepartmentView,
} from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/Departments/Filters/Filters.schema';
import config from 'config';
import { apiContracts } from 'api/contracts';
import onDownload from 'callbacks/onDownload';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoDepartmentView | null;
}
type Filters = Values & { initialization: boolean };
interface Partners {
  loading: boolean;
  data: PartnerView[];
}
interface InsuranceCompanies {
  loading: boolean;
  data: InsuranceCompanyView[];
}
interface Regions {
  loading: boolean;
  data: DepartmentView[];
}
interface PartnerDepartments {
  loading: boolean;
  data: DepartmentView[];
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'DEPARTMENTS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'DEPARTMENTS/SET_FILTERS', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'DEPARTMENTS/SET_PARTNERS', payload } as const),
  setInsuranceCompanies: (payload: Partial<InsuranceCompanies>) =>
    ({ type: 'DEPARTMENTS/SET_INSURANCE_COMPANIES', payload } as const),
  setRegions: (payload: Partial<Regions>) => ({ type: 'DEPARTMENTS/SET_REGIONS', payload } as const),
  setPartnerDepartments: (payload: Partial<PartnerDepartments>) =>
    ({ type: 'DEPARTMENTS/SET_PARTNER_DEPARTMENTS', payload } as const),
};

export const downloadReport = (): ThunkType<void> => async (dispatch, getState) => {
  const departments = getState().departments;
  const sorting = getSortBy(departments.data.sorting);

  await onDownload(() =>
    apiContracts.departmentController
      .printXlsxContractList(
        {
          // TODO back
          // @ts-ignore
          timeZone: dayjs.tz.guess(),
          insuranceCompanyId: departments.filters.insuranceCompanyId?.toString(),
          partnerId: departments.filters.partnerId?.toString(),
          regionId: departments.filters.regionId?.toString(),
          partnerDepartmentId: departments.filters.partnerDepartmentId?.toString(),
          createdDate: getDateRange(departments.filters?.dateFrom, departments.filters?.dateTo) ?? '',
          sorting: sorting ? `${sorting},id` : undefined,
          ...getPrivateFilters(departments.data.privateFilters),
        },
        { format: 'blob' },
      )
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const requestData =
  (params: List5Params = {}): ThunkType<Promise<ResultListDtoDepartmentView>> =>
  async (dispatch, getState) => {
    const departments = getState().departments;
    const sorting = getSortBy(departments.data.sorting);

    return apiContracts.departmentController
      .list5({
        count: true,
        insuranceCompanyId: departments.filters.insuranceCompanyId?.toString(),
        partnerId: departments.filters.partnerId?.toString(),
        regionId: departments.filters.regionId?.toString(),
        partnerDepartmentId: departments.filters.partnerDepartmentId?.toString(),
        createdDate: getDateRange(departments.filters?.dateFrom, departments.filters?.dateTo) ?? '',
        sorting: sorting ? `${sorting},id` : undefined,
        ...getPrivateFilters(departments.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const departments = getState().departments;

  dispatch(actions.setData({ loading: true, data: null }));

  dispatch(requestData({ ...getPageBy(departments.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false, data: null }));
    });
};

export const loadPartners =
  (query: List2Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartners({ loading: true, data: [] }));

    apiContracts.partnerController
      .list2({
        distinct: true,
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPartners({ loading: false, data: [] }));
      });
  };

export const loadInsuranceCompanies = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setInsuranceCompanies({ loading: true, data: [] }));

  apiContracts.insuranceCompanyController
    .list4({
      distinct: true,
      page_size: -1,
      attributes: 'id,name',
    })
    .then((res) => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch(() => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: [] }));
    });
};

export const loadRegions =
  (region: string): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setRegions({ loading: true, data: [] }));

    apiContracts.departmentController
      .list5({
        distinct: true,
        attributes: 'region,regionId',
        region,
      })
      .then((res) => {
        dispatch(actions.setRegions({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setRegions({ loading: false, data: [] }));
      });
  };

export const loadPartnerDepartments =
  (name: string): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setPartnerDepartments({ loading: true, data: [] }));

    apiContracts.departmentController
      .list5({
        distinct: true,
        attributes: 'name,partnerDepartmentId',
        name,
      })
      .then((res) => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: [] }));
      });
  };

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  const insuranceCompany = getState().me.data?.details?.insuranceCompany;

  if (insuranceCompany) {
    dispatch(actions.setFilters({ insuranceCompanyId: insuranceCompany.id }));
    dispatch(actions.setInsuranceCompanies({ data: [{ id: insuranceCompany.id, name: insuranceCompany.name }] }));
  }

  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadPartners());
  dispatch(loadInsuranceCompanies());
};

export interface StoreDepartments {
  data: Data;
  filters: Filters;
  partners: Partners;
  insuranceCompanies: InsuranceCompanies;
  regions: Regions;
  partnerDepartments: PartnerDepartments;
}
export const initialState: StoreDepartments = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'createdDate', order: 'descend' },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  partners: { loading: false, data: [] },
  insuranceCompanies: { loading: false, data: [] },
  regions: { loading: false, data: [] },
  partnerDepartments: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreDepartments => {
  switch (action.type) {
    case 'DEPARTMENTS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'DEPARTMENTS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'DEPARTMENTS/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'DEPARTMENTS/SET_INSURANCE_COMPANIES':
      return { ...state, insuranceCompanies: { ...state.insuranceCompanies, ...action.payload } };
    case 'DEPARTMENTS/SET_REGIONS':
      return { ...state, regions: { ...state.regions, ...action.payload } };
    case 'DEPARTMENTS/SET_PARTNER_DEPARTMENTS':
      return { ...state, partnerDepartments: { ...state.partnerDepartments, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
