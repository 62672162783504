import React from 'react';
import UiCheckbox from 'components/Checkbox';
import { Controller, Control } from 'react-hook-form';
import styles from './Checkbox.module.less';
import { isEqual } from 'lodash';

interface Radio {
  id: string;
  label: string;
}

interface CheckboxProps<T> {
  name: string;
  label?: string;
  control: Control<T>;
  radios?: Radio[];
}

const Radio = ({ label, onChange, value, radioName }) => {
  return (
    <div
      className={styles.checkbox}
      onClick={() => onChange(radioName && !value ? radioName : !value)}
      role="presentation"
    >
      <UiCheckbox checked={value} />
      <span>{label}</span>
    </div>
  );
};

export const Checkbox = <T,>({ name, label, control, radios = [] }: CheckboxProps<T>) => {
  return (
    <Controller
      control={control}
      // @ts-ignore
      name={name}
      render={({ field: { name, onChange, value } }) => (
        <>
          {(radios.length > 0 ? radios : [{ id: name, label }]).map((radio) => (
            <Radio
              key={radio.id}
              onChange={onChange}
              value={radios.length > 0 ? isEqual(value, radio) : value}
              label={radio.label}
              radioName={radios.length > 0 && radio}
            />
          ))}
        </>
      )}
    />
  );
};
