import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography, Row, Col, notification, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useDispatch } from 'react-redux';
import { Grid } from 'antd';

import { ErrorsDto, UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import { InputField } from 'components/next/InputField/InputField';
import { Password } from 'components/next/Password/Password';
import Button from 'components/next/Button/Button';
import AuthContainer from 'components/next/AuthContainer/AuthContainer';
import { apiAuth } from 'api/auth';
import { InputContainer } from 'components/next/InputContainer/InputContainer';
import { useRequired } from 'hooks/useRequired';

import { schema, defaultValues, Values } from './Login.schema';
import Language from './Language/Language';
import { actions } from 'store/me/me';
import pallete from 'styles/pallete';
import { getMe } from 'store/me/me';
const cssSubmit = css`
  width: 150px;
`;
const cssFlex = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { requiredFields } = useRequired(schema, watch());

  useEffect(() => {
    dispatch(
      actions.setWhiteLabel({
        data: {
          whiteLabeling: {
            color: pallete.primary.main,
          },
        },
      }),
    );
  }, []);

  const onSubmit = async (values: Values) => {
    setLoading(true);

    try {
      const response = await apiAuth.authenticationEndpoints.login({
        username: values.username.trim(),
        password: values.password.trim(),
      });

      localStorage.setItem('accessToken', response.data.access_token ?? '');
      await dispatch(getMe());
      history.push(ROUTES.DASHBOARD.ROOT);
    } catch (error) {
      setLoading(false);

      reset();

      const errorMap = {
        default: t('log_in.check_the_data'),
        USER_INACTIVE: t('log_in.user_inactive'),
        USER_ONLY_CLIENT: t('log_in.user_only_client'),
        USER_NOT_CLIENT: t('log_in.user_not_client'),
      };

      (error?.response?.data as ErrorsDto | undefined)?.errors.forEach((err) => {
        const message = errorMap[err.code] ?? errorMap.default;

        notification.error({ message });
      });
    }
  };

  return (
    <AuthContainer>
      <Typography.Title level={screens.lg ? 1 : 3}>{t('log_in.title')}</Typography.Title>

      <Row gutter={[16, 16]}>
        <Col span={24} className={cssFlex}>
          {t('log_in.as_a_partner')}
          <Language />
        </Col>

        <Col span={24}>
          <form onSubmit={handleSubmit(onSubmit)} data-test="form">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Controller
                  name="username"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <InputField
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      onChange={(v) => onChange(v)}
                      value={value}
                      label={t('log_in.your_login')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col span={24}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Password
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      onChange={(v) => onChange(v)}
                      value={value}
                      label={t('log_in.password')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col span={24} className={cssFlex}>
                <Controller
                  name="remember"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <InputContainer name={name} errors={errors} requiredFields={requiredFields}>
                      <Checkbox onChange={({ target }) => onChange(target.checked)} checked={value} data-test={name}>
                        {t('log_in.remember_me')}
                      </Checkbox>
                    </InputContainer>
                  )}
                />

                <InputContainer colorBase="none">
                  <Typography.Link onClick={() => history.push(ROUTES.PARTNER_AUTH.PASSWORD_RECOVERY)}>
                    {t('log_in.forgot_password')}
                  </Typography.Link>
                </InputContainer>
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" size="large" loading={loading} className={cssSubmit}>
                  {t('log_in.log_in')}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>

        <Col span={24}>
          {t('log_in.no_account')}{' '}
          <Typography.Link onClick={() => history.push(ROUTES.AUTH.SIGN_UP)}>{t('log_in.register')}</Typography.Link>
        </Col>
      </Row>
    </AuthContainer>
  );
};

export default Login;
