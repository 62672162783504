import React from 'react';
import { StatusError, StatusPending, StatusSuccess } from '../components/Icons';

export const paaFormat = (t) => {
  return {
    false: t('status.not_included'),
    true: t('status.included'),
  };
};

export const paymentStatus = (status: string, disabledUnpaid?: boolean | null, t?) => {
  switch (status) {
    case 'UNPAID': {
      return typeof disabledUnpaid === 'boolean' ? '' : t('status.not_paid');
    }
    case 'PARTIALLY_PAID': {
      return t('status.paid_part');
    }
    case 'PAID': {
      return t('status.paid');
    }
  }
};

export const renderStatus = (status: string) => {
  switch (status) {
    case 'FINISHED': {
      return <StatusError />;
    }
    case 'PROLONGATED':
    case 'ACTIVE': {
      return <StatusSuccess />;
    }
    case 'DRAFT': {
      return <StatusPending color="#F2C94C" className="draft" />;
    }
    default: {
      return <></>;
    }
  }
};

export const statusLabelsData = (t) => {
  return [
    {
      status: 'REPORTED',
      title: t('status.reported'),
      label: 'warn',
    },
    {
      status: 'CLAIMED',
      title: t('status.signed'),
      label: 'success',
    },
    {
      status: 'DOCUMENTS_COLLECTION',
      title: t('status.submission_documents'),
      label: 'warn',
    },
    { status: 'PAYMENT_CALCULATION', title: t('status.expert_evaluation'), label: 'warn' },
    { status: 'PAYMENT_AWAITING', title: t('status.awaiting_payment'), label: 'warn' },
    { status: 'PAYMENT', title: t('status.payment'), label: 'success' },
    { status: 'PAYMENT_REJECTED', title: t('status.refusal_pay'), label: 'danger' },
    { status: 'ANNUL', title: t('status.annul'), label: 'danger' },
  ];
};
