import React from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { Switch as BaseSwitch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

interface Props extends SwitchProps {}

const Switch = (props: Props) => {
  return <BaseSwitch unCheckedChildren={<BarChartOutlined />} checkedChildren={<TableOutlined />} {...props} />;
};

export default Switch;
