import { ThunkAction } from 'redux-thunk';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes } from 'types';
import { apiConfiguration } from 'api/configuration';
import { InsuranceProgramView, ListProductsParams, ContractConclusionFlow } from 'types/dto/configuration-service';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface AnyProducts {
  loading: boolean;
  data: InsuranceProgramView[];
}
interface RegularProducts {
  loading: boolean;
  data: InsuranceProgramView[];
}
interface WidgetProducts {
  loading: boolean;
  data: InsuranceProgramView[];
}

export const actions = {
  setAnyProducts: (payload: Partial<AnyProducts>) =>
    ({ type: 'INSURANCE_PRODUCTS/SET_ANY_PRODUCTS', payload } as const),
  setRegularProducts: (payload: Partial<RegularProducts>) =>
    ({ type: 'INSURANCE_PRODUCTS/SET_REGULAR_PRODUCTS', payload } as const),
  setWidgetProducts: (payload: Partial<WidgetProducts>) =>
    ({ type: 'INSURANCE_PRODUCTS/SET_WIDGET_PRODUCTS', payload } as const),
};

export const getAnyProducts =
  (t: TFunction, query: ListProductsParams): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setAnyProducts({ loading: true }));

    apiConfiguration.insuranceProductController
      .listProducts({
        ...query,
        contractConclusionFlow: ContractConclusionFlow.ANY,
        deactivationDate: `>=${dayjs().format('YYYY-MM-DD')}`,
        attributes: 'id,name,code',
      })
      .then((res) => {
        dispatch(actions.setAnyProducts({ data: res.data.resultList ?? [] }));
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(actions.setAnyProducts({ loading: false }));
      });
  };

export const getRegularProducts =
  (t: TFunction, query: ListProductsParams): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setRegularProducts({ loading: true }));

    apiConfiguration.insuranceProductController
      .listProducts({
        ...query,
        contractConclusionFlow: ContractConclusionFlow.REGULAR,
        deactivationDate: `>=${dayjs().format('YYYY-MM-DD')}`,
        attributes: 'id,name,code',
      })
      .then((res) => {
        dispatch(actions.setRegularProducts({ data: res.data.resultList ?? [] }));
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(actions.setRegularProducts({ loading: false }));
      });
  };

export const getWidgetProducts =
  (t: TFunction, query: ListProductsParams): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setWidgetProducts({ loading: true }));

    apiConfiguration.insuranceProductController
      .listProducts({
        ...query,
        contractConclusionFlow: ContractConclusionFlow.WIDGET,
        deactivationDate: `>=${dayjs().format('YYYY-MM-DD')}`,
        attributes: 'id,name,code',
      })
      .then((res) => {
        dispatch(actions.setWidgetProducts({ data: res.data.resultList ?? [] }));
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(actions.setWidgetProducts({ loading: false }));
      });
  };

export interface StoreInsuranceProducts {
  anyProducts: AnyProducts;
  regularProducts: RegularProducts;
  widgetProducts: WidgetProducts;
}
export const initialState: StoreInsuranceProducts = {
  anyProducts: { loading: false, data: [] },
  regularProducts: { loading: false, data: [] },
  widgetProducts: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreInsuranceProducts => {
  switch (action.type) {
    case 'INSURANCE_PRODUCTS/SET_ANY_PRODUCTS':
      return { ...state, anyProducts: { ...state.anyProducts, ...action.payload } };
    case 'INSURANCE_PRODUCTS/SET_REGULAR_PRODUCTS':
      return { ...state, regularProducts: { ...state.regularProducts, ...action.payload } };
    case 'INSURANCE_PRODUCTS/SET_WIDGET_PRODUCTS':
      return { ...state, widgetProducts: { ...state.widgetProducts, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
