import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { AppState } from 'store/reducer';
import { Message as TMessage } from 'types/chats';
import ImageModal from '../ImageModal';

import styles from './Message.module.less';

type MessageProps = TMessage & {
  companion: string;
  uuid: string;
};

const Message: React.FC<MessageProps> = ({ companion, type, publisher, timetoken, uuid, ...body }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const userId = useSelector((store: AppState) =>
    store.me.data?.details?.id ? String(store.me.data?.details?.id) : null,
  );
  const id = userId || uuid;

  const bodyNode =
    type === 'plaintext' ? (
      body['body'].system ? (
        <span className={styles.imageFile} onClick={() => setIsModalOpen(true)}>
          <img alt="" height="140" src={JSON.parse(body['body'].system).fileUrl} />
        </span>
      ) : (
        body['body']
      )
    ) : (
      <span className={styles.imageFile} onClick={() => setIsModalOpen(true)}>
        <img alt="" height="140" src={body['url']} />
      </span>
    );

  return (
    <div className={styles.message}>
      <div className={styles.textOuter}>
        {/* // Delete avatar */}
        {/* {publisher !== userId && <Avatar title={companion} dialog />} */}
        <div
          className={classNames(styles.text, {
            [styles.myMessage]: id === publisher,
          })}
        >
          {bodyNode}
        </div>
      </div>
      <div
        className={classNames(styles.timeOuter, {
          [styles.publisherIsCompanion]: id !== publisher,
        })}
      >
        <span className={styles.time}>
          {moment(new Date(parseInt(String(timetoken).slice(0, -4)))).format('HH:mm')}
        </span>
      </div>
      <ImageModal
        visible={isModalOpen}
        closeEditModal={() => setIsModalOpen(false)}
        fileUrl={type === 'plaintext' && body['body'].system ? JSON.parse(body['body'].system).fileUrl : body['url']}
        fileName={
          type === 'plaintext' && body['body'].system ? JSON.parse(body['body'].system).fileName : body['title']
        }
      />
    </div>
  );
};

export default Message;
