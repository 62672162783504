import React from 'react';
import { Select } from 'antd';
import { ChevronIcon } from '../../../Icons';
import SoldChart from '../../Charts/SoldChart';
import styles from './SoldBrands.module.less';
import { IDeviceType } from '../../../../types/DeviceType';
import normalizeChartData from '../../utils/normalizeChartData';
import { useTranslation } from 'react-i18next';
import { ObjectSubtypeDto, SalesStatisticsDtoLong } from 'types/dto/contracts-service';

interface ISoldBrandsProps {
  deviceTypes: IDeviceType[];
  deviceType?: ObjectSubtypeDto;
  onDeviceTypeChange: (value: any) => any;
  data: SalesStatisticsDtoLong[];
}

const SoldBrands = ({ deviceTypes, deviceType, onDeviceTypeChange, data: rawData }: ISoldBrandsProps) => {
  const { t } = useTranslation();

  const chartData = normalizeChartData(rawData);

  return (
    <div className={styles.sold_chart}>
      <div className={styles.sold_chart_header}>
        <p className={styles.sold_chart_title}> {t('dash_board.sold')} </p>
        <Select
          className={styles.sold_chart_control}
          value={deviceType && deviceType.id}
          onChange={onDeviceTypeChange}
          getPopupContainer={(trigger: any) => trigger.parentNode}
          suffixIcon={<ChevronIcon />}
        >
          {deviceTypes.map((d) => (
            <Select.Option value={d.id} key={d.id}>
              {d.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <SoldChart className={styles.sold_chart_chart} data={chartData} />
    </div>
  );
};

export default SoldBrands;
