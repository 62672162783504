import { notification } from 'antd';
import { apiConfiguration } from 'api/configuration';
import { apiContracts } from 'api/contracts';
import config from 'config';
import { ThunkAction } from 'redux-thunk';
import { Values as PromoFilterValues, initialValues } from 'screens/Discounts/Filters/PromoFilter/PromoFilter.schema';
import { AppState } from 'store/reducer';
import i18n from 'translations/i18n';
import { ActionTypes, List } from 'types';
import {
  InsuranceProductView,
  InsuranceProgramView,
  List1Params,
  List4Params,
  ListProductsParams,
  PromoCodeDto,
  ResultListDtoInsuranceProductView,
  ResultListDtoPromoCodeView,
} from 'types/dto/configuration-service';
import {
  CompanyView,
  DepartmentView,
  InsuranceCompanyView,
  List5Params,
  PartnerView,
  ResultListDtoDepartmentView,
  ResultListDtoInsuranceCompanyView,
  ResultListDtoPartnerView,
} from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

type PromoFilters = PromoFilterValues & { initialization: boolean };

interface Companies {
  loading: boolean;
  data: InsuranceCompanyView[];
}

interface Partners {
  loading: boolean;
  data: PartnerView[];
}

interface Products {
  loading: boolean;
  data: InsuranceProductView[];
}

interface Programs {
  loading: boolean;
  data: InsuranceProductView[];
}

interface PromoData extends List {
  loading: boolean;
  data: ResultListDtoPromoCodeView | null;
}

interface PromoModal {
  loading?: boolean;
  isCreate?: boolean;
  isCopy?: boolean;
  data?: PromoCodeDto;
  insuranceCompanies?: InsuranceCompanyView[];
  insurancePrograms?: InsuranceProgramView[];
  partners?: PartnerView[];
  products?: InsuranceProductView[];
  departments?: DepartmentView[];
}

export const actions = {
  setPromoModal: (payload: PromoModal | null) => ({ type: 'DISCOUNTS/SET_PROMO_MODAL', payload } as const),
  setCompanies: (payload: Partial<Companies>) => ({ type: 'DISCOUNTS/SET_COMPANIES', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'DISCOUNTS/SET_PARTNERS', payload } as const),
  setProducts: (payload: Partial<Products>) => ({ type: 'DISCOUNTS/SET_PRODUCTS', payload } as const),
  setPrograms: (payload: Partial<Programs>) => ({ type: 'DISCOUNTS/SET_PROGRAMS', payload } as const),
  setPromoFilters: (payload: Partial<PromoFilters>) => ({ type: 'DISCOUNTS/SET_PROMO_FILTERS', payload } as const),
  setPromoData: (payload: Partial<PromoData>) => ({ type: 'DISCOUNTS/SET_PROMO_DATA', payload } as const),
};

export const requestPromo =
  (params: List1Params): ThunkType<Promise<ResultListDtoPromoCodeView>> =>
  (dispatch, getState) => {
    const discount = getState().discountsNext;
    const sorting = getSortBy(discount.promoData.sorting);

    return apiConfiguration.promoCodeController
      .list1({
        count: true,
        distinct: true,
        sorting: sorting ? `${sorting},-id` : '-id',
        insuranceCompanyId: discount.promoFilters.insuranceCompanyId?.toString(),
        partnerId: discount.promoFilters.partnerId?.toString(),
        insuranceProductId: discount.promoFilters.insuranceProductId?.toString(),
        insuranceProgramIds: discount.promoFilters.insuranceProgramIds?.toString(),
        startDate: getDateRange(discount.promoFilters?.startDate, discount.promoFilters?.endDate) ?? '',
        ...getPrivateFilters(discount.promoData.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const getPromo = (): ThunkType<void> => (dispatch, getState) => {
  const discount = getState().discountsNext;

  dispatch(actions.setPromoData({ loading: true, data: null }));

  dispatch(requestPromo({ ...getPageBy(discount.promoData.pagination) }))
    .then((res) => {
      dispatch(actions.setPromoData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setPromoData({ loading: false, data: null }));
    });
};

export const getPromoById =
  (id: number): ThunkType<Promise<PromoCodeDto>> =>
  (dispatch) => {
    return apiConfiguration.promoCodeController
      .get(id)
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  };

export const deletePromo =
  (id: number): ThunkType<void> =>
  (dispatch) => {
    apiConfiguration.promoCodeController
      .delete(id)
      .then((res) => {
        dispatch(getPromo());
        notification.success({
          message: i18n.t('promo_code.notification.Deleted'),
        });
      })
      .catch((error) => {
        notification.error({
          message: i18n.t('promo_code.notification.Failed'),
        });
        return Promise.reject(undefined);
      });
  };

export const createPromo =
  (seed: PromoCodeDto): ThunkType<Promise<PromoCodeDto>> =>
  (dispatch) => {
    return apiConfiguration.promoCodeController
      .create(seed)
      .then((res) => {
        notification.success({
          message: i18n.t('promo_code.notification.Created'),
        });
        return res.data;
      })
      .catch((error) => {
        if (error?.response?.data?.errors[0].code === 'PROMO_CODE_ALREADY_EXISTS') {
          notification.error({
            message: i18n.t('popup.promo_already_exists'),
          });
          return Promise.reject(undefined);
        }

        notification.error({
          message: i18n.t('promo_code.notification.Failed'),
        });
        return Promise.reject(undefined);
      });
  };

export const updatePromo =
  (promoId: number, seed: PromoCodeDto): ThunkType<Promise<PromoCodeDto>> =>
  (dispatch) => {
    return apiConfiguration.promoCodeController
      .update(promoId, seed)
      .then((res) => {
        notification.success({
          message: i18n.t('promo_code.notification.Updated'),
        });
        return res.data;
      })
      .catch((error) => {
        if (error?.response?.data?.errors[0].code === 'PROMO_CODE_ALREADY_EXISTS') {
          notification.error({
            message: i18n.t('popup.promo_already_exists'),
          });
          return Promise.reject(undefined);
        }
        notification.error({
          message: i18n.t('promo_code.notification.Failed'),
        });
        return Promise.reject(undefined);
      });
  };

export const getInsuranceCompaniesModal = (): ThunkType<void> => (dispatch) => {
  return apiContracts.insuranceCompanyController
    .list4({
      distinct: true,
      attributes: 'id,name',
      page_size: -1,
    })
    .then(({ data }) => {
      dispatch(actions.setPromoModal({ insuranceCompanies: data.resultList }));
    })
    .catch(() => {
      dispatch(actions.setPromoModal({ insuranceCompanies: [] }));
    });
};

export const getInsuranceCompaniesFilter = (): ThunkType<Promise<ResultListDtoInsuranceCompanyView>> => (dispatch) => {
  dispatch(actions.setCompanies({ loading: true, data: [] }));

  return apiContracts.insuranceCompanyController
    .list4({
      distinct: true,
      attributes: 'id,name',
      page_size: -1,
    })
    .then(({ data }) => {
      dispatch(actions.setCompanies({ loading: false, data: data.resultList }));
      return data;
    })
    .catch(() => {
      dispatch(actions.setCompanies({ loading: false, data: [] }));
      return Promise.reject(undefined);
    });
};

export const getPartnersModal =
  (insuranceCompanyId: number): ThunkType<Promise<ResultListDtoPartnerView>> =>
  (dispatch) => {
    return apiContracts.partnerController
      .list2({
        distinct: true,
        page_size: -1,
        insuranceCompanyId: insuranceCompanyId.toString(),
      })
      .then(({ data }) => {
        dispatch(actions.setPromoModal({ partners: data.resultList }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setPromoModal({ partners: [] }));
        return Promise.reject(undefined);
      });
  };

export const getPartnersFilter =
  (insuranceCompanyId: number): ThunkType<Promise<ResultListDtoPartnerView>> =>
  (dispatch) => {
    dispatch(actions.setPartners({ loading: false }));

    return apiContracts.partnerController
      .list2({
        distinct: true,
        page_size: -1,
        insuranceCompanyId: insuranceCompanyId.toString(),
      })
      .then(({ data }) => {
        dispatch(actions.setPartners({ loading: false, data: data.resultList }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setPromoModal({ loading: false, partners: [] }));
        return Promise.reject(undefined);
      });
  };

export const getProducts =
  (query: ListProductsParams): ThunkType<Promise<ResultListDtoInsuranceProductView>> =>
  (dispatch) => {
    return apiConfiguration.insuranceProductController
      .listProducts({
        distinct: true,
        attributes: 'id,name,code',
        ...query,
      })
      .then(({ data }) => {
        dispatch(actions.setPromoModal({ products: data.resultList }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setPromoModal({ products: [] }));
        return Promise.reject(undefined);
      });
  };

export const getInsurancePrograms =
  (query: List4Params): ThunkType<Promise<ResultListDtoInsuranceProductView>> =>
  (dispatch) => {
    return apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        attributes: 'id,name',
        ...query,
      })
      .then(({ data }) => {
        dispatch(actions.setPromoModal({ insurancePrograms: data.resultList }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setPromoModal({ insurancePrograms: [] }));
        return Promise.reject(undefined);
      });
  };

export const getDepartments =
  (query: List5Params): ThunkType<Promise<ResultListDtoDepartmentView>> =>
  (dispatch) => {
    return apiContracts.departmentController
      .list5({
        distinct: true,
        attributes: 'id,name,departmentNumber,partnerId',
        ...query,
      })
      .then(({ data }) => {
        dispatch(actions.setPromoModal({ departments: data.resultList }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setPromoModal({ departments: [] }));
        return Promise.reject(undefined);
      });
  };

export const generatePromo = (): ThunkType<Promise<string>> => (dispatch) => {
  return apiConfiguration.promoCodeController
    .generatePromoCode()
    .then((res) => {
      notification.success({
        message: i18n.t('promo_code.notification.Created'),
      });
      return res.data;
    })
    .catch((error) => {
      notification.error({
        message: i18n.t('promo_code.notification.Failed'),
      });
      return Promise.reject(undefined);
    });
};

export const getProductsFilter =
  (query: ListProductsParams): ThunkType<Promise<ResultListDtoInsuranceProductView>> =>
  (dispatch) => {
    dispatch(actions.setProducts({ loading: true }));
    return apiConfiguration.insuranceProductController
      .listProducts({
        distinct: true,
        attributes: 'id,name,code',
        ...query,
      })
      .then(({ data }) => {
        dispatch(actions.setProducts({ loading: false, data: data.resultList }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setProducts({ loading: false, data: [] }));
        return Promise.reject(undefined);
      });
  };

export const getInsuranceProgramsFilter =
  (query: List4Params): ThunkType<Promise<ResultListDtoInsuranceProductView>> =>
  (dispatch) => {
    dispatch(actions.setPrograms({ loading: true }));

    return apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        attributes: 'id,name',
        ...query,
      })
      .then(({ data }) => {
        dispatch(actions.setPrograms({ loading: false, data: data.resultList }));
        return data;
      })
      .catch(() => {
        dispatch(actions.setPrograms({ loading: false, data: [] }));
        return Promise.reject(undefined);
      });
  };

export const initializePromoFilters = (): ThunkType<void> => (dispatch, getState) => {
  const insuranceCompany = getState().me.data?.details?.insuranceCompany;
  const partner = getState().me.data?.details?.partner;

  if (insuranceCompany) {
    dispatch(actions.setPromoFilters({ insuranceCompanyId: insuranceCompany.id }));
    dispatch(
      actions.setCompanies({ data: [{ insuranceCompanyId: insuranceCompany.id, name: insuranceCompany.name }] }),
    );
  }

  if (partner) {
    dispatch(actions.setPromoFilters({ partnerId: partner.id }));
    dispatch(actions.setPartners({ data: [{ partnerId: partner.id, name: partner.name }] }));
  }

  dispatch(actions.setPromoFilters({ initialization: false }));

  dispatch(getInsuranceCompaniesFilter()).catch(() => {});

  if (insuranceCompany?.id) {
    dispatch(getPartnersFilter(insuranceCompany.id)).catch(() => {});
    dispatch(getProductsFilter({ insuranceCompanyId: insuranceCompany?.id?.toString() }));
  }

  if (partner?.id) {
    dispatch(getProductsFilter({ partnerId: partner?.id?.toString() }));
    dispatch(getInsuranceProgramsFilter({ partnerId: partner?.id?.toString() }));
  }
};

export interface StoreDiscounts {
  promoData: PromoData;
  promoFilters: PromoFilters;
  promoModal: PromoModal | null;
  companies: Companies;
  partners: Partners;
  products: Products;
  programs: Programs;
}

export const initialState: StoreDiscounts = {
  companies: { loading: false, data: [] },
  partners: { loading: false, data: [] },
  products: { loading: false, data: [] },
  programs: { loading: false, data: [] },
  promoModal: null,
  promoFilters: { ...initialValues, initialization: true },
  promoData: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'startDate', order: 'descend' },
    privateFilters: {},
  },
};

const reducer = (state = initialState, action: InferActionTypes) => {
  switch (action.type) {
    case 'DISCOUNTS/SET_PROMO_MODAL': {
      return {
        ...state,
        promoModal:
          action.payload === null
            ? null
            : {
                ...state.promoModal,
                ...action.payload,
              },
      };
    }

    case 'DISCOUNTS/SET_COMPANIES': {
      return { ...state, companies: { ...state.companies, ...action.payload } };
    }

    case 'DISCOUNTS/SET_PARTNERS': {
      return { ...state, partners: { ...state.partners, ...action.payload } };
    }

    case 'DISCOUNTS/SET_PROMO_FILTERS': {
      return { ...state, promoFilters: { ...state.promoFilters, ...action.payload } };
    }

    case 'DISCOUNTS/SET_PROMO_DATA': {
      return { ...state, promoData: { ...state.promoData, ...action.payload } };
    }

    case 'DISCOUNTS/SET_PRODUCTS': {
      return { ...state, products: { ...state.products, ...action.payload } };
    }

    case 'DISCOUNTS/SET_PROGRAMS': {
      return { ...state, programs: { ...state.programs, ...action.payload } };
    }
    default:
      return state;
  }
};

export default reducer;
