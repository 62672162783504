import React from 'react';
import DropCustom from './TableToolsHandler';
import TableTools from './TableTools';

const columns = (t) => {
  return [
    {
      title: `№`,
      value: 'id',
      key: 'id',
      dataIndex: 'id',
      width: 70,
    },
    {
      title: t('clinics.brand'),
      value: 'brandName',
      key: 'brandName',
      dataIndex: 'brandName',
      width: 170,
    },
    {
      title: t('clinics.city'),
      value: 'city',
      key: 'city',
      dataIndex: 'city',
      width: 170,
    },
    {
      title: t('clinics.address'),
      value: `street`,
      key: 'street',
      dataIndex: 'street',
      width: 170,
      render: (text, record) => (
        <span>
          {record.street} {record.houseNumber}
        </span>
      ),
    },
    // {
    //   title: `Номер будинку`,
    //   value: 'houseNumber',
    //   key: 'houseNumber',
    //   dataIndex: 'houseNumber',
    //   width: 170,
    // },
    {
      title: t('clinics.lpu_type'),
      value: 'clinicType',
      key: 'clinicType',
      dataIndex: 'clinicType',
      width: 170,
    },
    {
      title: t('clinics.online'),
      value: 'isOnline',
      key: 'isOnline',
      dataIndex: 'isOnline',
      width: 100,
      render: (text, record) => <span>{record.isOnline ? t('clinics.yes') : t('clinics.no')}</span>,
    },
    {
      title: t('clinics.region'),
      value: 'locality',
      key: 'locality',
      dataIndex: 'locality',
      width: 170,
    },

    // {
    //   title: `Бренд`,
    //   value: 'brand',
    //   key: 'brand',
    //   dataIndex: 'brand',
    //   width: 170,
    // },
    {
      title: '',
      key: 'id',
      value: 'id',
      width: 50,
      render: (id: any, record: any) => (
        <DropCustom Component={TableTools} data={record} record={record}>
          {' '}
        </DropCustom>
      ),
    },
  ];
};

export default columns;
