import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'constants/routes';
import { LayoutComponent } from 'components/next/Layout/Layout';
import PrivateRoute, { PrivateRouteNext } from './PrivateRoute';
import Dashboard from 'screens/Dashboard/Dashboard';
import InsuredEventsCreate from 'screens/InsuredEvents/create/lazy';
import InsuranceProgramsCreate from 'screens/InsuranceProgramCreate/lazy';
import Insurance from 'screens/Insurance/lazy';
import Search from 'screens/Search/lazy';
import Page404 from '../components/Page404';
import InsuranceProgramDetails from 'screens/InsuranceProgramDetails/lazy';
import history from './history';
import { AppState } from '../store/reducer';
import { roleApproved } from 'roles/userCan';
import InsuranceConfiguration from 'screens/InsuranceCompanies/InsuranceConfiguration/lazy';
import DepartmentDetails from 'screens/DepartmentDetails/lazy';
import PageLoading from '../components/PageLoading';
import Chats from 'screens/Chats';
import Consultations from 'screens/Consultations/Consultations';
import Appointments from 'screens/Appointments';
import History from 'screens/History';
import MedApp from 'screens/MedApp';
import Sublimits from 'screens/Sublimits';
import SublimitDetails from 'screens/SublimitDetails';
import MedAppDetails from 'screens/MedAppDetails';
import Services from 'screens/Services';
import ServiceDetails from 'screens/ServiceDetails';
import RegisterOfTypes from 'screens/RegisterOfTypes/lazy';
import Discounts from 'screens/Discounts/lazy';
import Subevent from 'screens/Subevent';
import { PermissionEnum } from 'types/dto/auth-service';

const Settings = lazy(() => import('screens/Settings/Settings'));
const ContractList = lazy(() => import('screens/ContractList/ContractList'));
const InsuranceCompanyList = lazy(() => import('screens/InsuranceCompanies/List/InsuranceCompanyList'));
const ContractDetailsТNext = lazy(() => import('screens/ContractDetailsNext/ContractDetails'));
const ContractWidgets = lazy(() => import('screens/ContractWidgets/ContractWidgets'));
const PartnersList = lazy(() => import('screens/PartnersList/PartnersList'));
const Terminations = lazy(() => import('screens/TerminationsList/TerminationsList'));
const Termination = lazy(() => import('screens/Termination/Termination'));
const InsuredEventsList = lazy(() => import('screens/InsuredEvents/list/InsuredEventsList'));
const RegistrationDeviceList = lazy(() => import('screens/RegistrationDevices/RegistrationDevices'));
const Brands = lazy(() => import('screens/Brands/Brands'));
const Permissions = lazy(() => import('screens/Permissions/Permissions'));
const Users = lazy(() => import('screens/Users/Users'));
const Integrations = lazy(() => import('screens/Integrations/Integrations'));
const Departments = lazy(() => import('screens/Departments/Departments'));
const Partner = lazy(() => import('screens/Partner/Partner'));
const UserActivities = lazy(() => import('screens/UserActivities/UsersActivities'));
const InsurancePrograms = lazy(() => import('screens/InsurancePrograms/InsurancePrograms'));
const PartnerAgent = lazy(() => import('screens/PartnerAgent/PartnerAgent'));
const User = lazy(() => import('screens/User/User'));
const UI = lazy(() => import('screens/UI/UI'));
const Acts = lazy(() => import('screens/Acts/Acts'));
const Act = lazy(() => import('screens/Act/Act'));

export const LayoutRouting = () => {
  const user = useSelector((store: AppState) => store.me.data?.decoded);

  return (
    <>
      <LayoutComponent>
        <Suspense fallback={<PageLoading />}>
          <Router history={history}>
            <Switch>
              <PrivateRoute role={['DASHBOARD', 'READ']} path={ROUTES.DASHBOARD.ROOT} component={Dashboard} />
              <PrivateRoute
                exact
                role={['CONTRACTS_LIST', 'READ']}
                path={ROUTES.CONTRACTS_LIST.ROOT}
                component={ContractList}
              />
              <PrivateRoute
                exact
                role={['CONTRACT', 'READ']}
                path={ROUTES.CONTRACTS_LIST.DETAILS}
                component={ContractDetailsТNext}
              />
              <PrivateRoute
                exact
                role={['CONTRACT', 'CREATE']}
                path={ROUTES.CONTRACTS_LIST.WIDGET}
                component={ContractWidgets}
              />
              <PrivateRoute role={['ACTS', 'READ']} exact path={ROUTES.ACTS_LIST.ROOT} component={Acts} />
              {/* <PrivateRoute role={['ACTS', 'READ']} exact path={ROUTES.ACTS_LIST.CREATE_NEXT} component={ActCreate} /> */}
              <PrivateRoute role={['ACTS', 'READ']} exact path={ROUTES.ACTS_LIST.DETAILS} component={Act} />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.ROOT} component={Consultations} />
              <PrivateRoute
                role={['CHATS', 'READ']}
                exact
                path={ROUTES.CONSULTATIONS.APPOINTMENTS}
                component={Appointments}
              />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.HISTORY} component={History} />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.SUBEVENT} component={Subevent} />
              <PrivateRoute role={['CHATS', 'READ']} exact path={ROUTES.CONSULTATIONS.CHATS} component={Chats} />
              <PrivateRoute
                exact
                role={['INSURANCE', 'CREATE']}
                path={ROUTES.INSURED_EVENTS.CREATE.ROOT}
                component={InsuredEventsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE', 'READ']}
                path={ROUTES.INSURED_EVENTS.READ.ROOT}
                component={InsuredEventsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_LIST', 'READ']}
                path={ROUTES.INSURED_EVENTS.LIST.ROOT}
                component={InsuredEventsList}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_DETAILS', 'READ']}
                path={ROUTES.INSURANCE.ROOT}
                component={Insurance}
              />
              <PrivateRoute role={['SEARCH', 'READ']} exact path={ROUTES.SEARCH.ROOT} component={Search} />
              <PrivateRoute
                exact
                role={['TERMINATION', 'CREATE']}
                path={[ROUTES.TERMINATIONS.ROOT]}
                component={Termination}
              />
              <PrivateRoute
                exact
                role={['TERMINATION', 'READ']}
                path={[ROUTES.TERMINATIONS.READ]}
                component={Termination}
              />
              <PrivateRouteNext
                exact
                path={ROUTES.TERMINATIONS_LIST.ROOT}
                action="read"
                item={PermissionEnum.TERMINATION_REGISTRY}
                component={Terminations}
              />
              <PrivateRouteNext
                exact
                path={ROUTES.USERS.ROOT}
                action="read"
                item={PermissionEnum.USER_SYSTEM}
                component={Users}
              />
              <PrivateRoute
                exact
                role={['REGISTER_DEVICES', 'READ']}
                path={ROUTES.REGISTRATION_DEVICES.ROOT}
                component={RegistrationDeviceList}
              />
              <PrivateRouteNext
                exact
                path={ROUTES.USERS.DETAILS}
                action="read"
                item={PermissionEnum.PERSONAL_SETTINGS}
                component={User}
              />
              <PrivateRoute
                exact
                role={['PROCESS_ANALYTICS', 'READ']}
                path={ROUTES.INTEGRATIONS.LIST}
                component={Integrations}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_COMPANIES', 'LIST']}
                path={ROUTES.INSURANCE_COMPANIES.LIST.ROOT}
                component={InsuranceCompanyList}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_COMPANIES', 'SETTINGS']}
                path={ROUTES.INSURANCE_COMPANIES.SETTINGS.ROOT}
                component={InsuranceConfiguration}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_COMPANIES', 'SETTINGS']}
                path={ROUTES.INSURANCE_COMPANIES.CREATE_SETTINGS.ROOT}
                component={InsuranceConfiguration}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user}
                path={ROUTES.INSURANCE_PROGRAMS.ROOT}
                component={InsurancePrograms}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user}
                path={ROUTES.INSURANCE_PROGRAMS.CREATE}
                component={InsuranceProgramsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user}
                path={ROUTES.INSURANCE_PROGRAMS.READ}
                component={InsuranceProgramsCreate}
              />
              <PrivateRoute
                exact
                role={['INSURANCE_PROGRAMS', 'READ']}
                user={user}
                path={ROUTES.INSURANCE_PROGRAMS.PROGRAM.ROOT}
                component={InsuranceProgramDetails}
              />
              <PrivateRouteNext
                exact
                path={ROUTES.BRAND_PAGE.READ.ROOT}
                action="read"
                item={PermissionEnum.BRAND_CATALOG}
                component={Brands}
              />
              <PrivateRoute
                exact
                role={['DEPARTMENTS', 'READ']}
                user={user}
                path={ROUTES.DEPARTMENTS.ROOT}
                component={Departments}
              />
              <PrivateRouteNext
                exact
                path={ROUTES.PERMISSIONS.ROOT}
                action="read"
                item={PermissionEnum.ROLES_AND_PERMISSIONS}
                component={Permissions}
              />
              {process.env.NODE_ENV === 'development' && <Route exact path={ROUTES.UI.ROOT} component={UI} />}
              <PrivateRoute
                exact
                role={['DEPARTMENTS', 'READ']}
                user={user}
                path={ROUTES.DEPARTMENTS.READ.ROOT}
                component={DepartmentDetails}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.PARTNERS_LIST.ROOT}
                component={PartnersList}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.PARTNERS_LIST.CREATE}
                component={Partner}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.PARTNERS_LIST.READ.ROOT}
                component={Partner}
              />
              <PrivateRoute
                exact
                role={['ADMIN_PANEL', 'READ']}
                path={ROUTES.PARTNERS_LIST.PARTNER_AGENT.ROOT}
                component={PartnerAgent}
              />
              <PrivateRoute exact role={['ADMIN_PANEL', 'READ']} path={ROUTES.DISCOUNT.ROOT} component={Discounts} />
              <Route exact path={ROUTES.MEDAPP.CLINICS.ROOT} component={MedApp} />
              <Route exact path={ROUTES.MEDAPP.CLINICS.DETAILS.ROOT} component={MedAppDetails} />
              <Route exact path={ROUTES.MEDAPP.SERVICES.ROOT} component={Services} />
              <Route exact path={ROUTES.MEDAPP.SERVICES.DETAILS.ROOT} component={ServiceDetails} />
              <Route exact path={ROUTES.MEDAPP.SUBLIMITS.ROOT} component={Sublimits} />
              <Route exact path={ROUTES.MEDAPP.SUBLIMITS.DETAILS.ROOT} component={SublimitDetails} />
              <Route exact path={ROUTES.REGISTER_TYPES.ROOT} component={RegisterOfTypes} />
              <Route exact path={ROUTES.USERS_ACTIVITIES.ROOT} component={UserActivities} />
              <Route exact path={ROUTES.SETTINGS.ROOT} component={Settings} />
              <Route exact path={ROUTES.NOT_FOUND.ROOT} component={Page404} />
              {roleApproved() ? <Route component={Page404} /> : <Redirect to={ROUTES.AUTH.LOGIN} />}
            </Switch>
          </Router>
        </Suspense>
      </LayoutComponent>
    </>
  );
};

export default LayoutRouting;
