import { ThunkAction } from 'redux-thunk';
import { t } from 'i18next';
import { notification } from 'antd';
import { generatePath } from 'react-router-dom';

import { AppState } from 'store/reducer';
import { ActionTypes, ErrorsDto, List } from 'types';
import {
  AgentActDto,
  AgentActInfoDto,
  AllContractsAvailableForAgentActParams,
  CreateActDto,
  InitNewAgentActParams,
  NewAgentActContractsReportDto,
  PrintXlsxReport3Params,
  Read10Params,
  ResultListDtoAgentActView,
  UpdateAgentActDto,
} from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/Act/Filters/Filters.schema';
import config from 'config';
import { apiContracts } from 'api/contracts';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import onDownload from 'callbacks/onDownload';
import dayjs from 'dayjs';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

//  is it need to split into different slices (create/update)?
interface Data extends List {
  loading: boolean;
  data: ResultListDtoAgentActView | null;
  dataCreate: NewAgentActContractsReportDto | null;
  selected: number[];
}
type Filters = Values & { initialization: boolean };
interface Info {
  loading: boolean;
  data: AgentActInfoDto | null;
}
interface AvailableContracts extends List {
  loading: boolean;
  data: NewAgentActContractsReportDto | null;
}

export const actions = {
  reset: () => ({ type: 'act/RESET' } as const),
  setData: (payload: Partial<Data>) => ({ type: 'act/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'act/SET_FILTERS', payload } as const),
  setInfo: (payload: Partial<Info>) => ({ type: 'act/SET_INFO', payload } as const),
  setAvailableContracts: (payload: Partial<AvailableContracts>) =>
    ({ type: 'act/SET_AVAILABLE_CONTRACTS', payload } as const),
};

export const downloadReportXls =
  ({ id, params }: PrintXlsxReport3Params): ThunkType<void> =>
  async (dispatch, getState) => {
    const act = getState().act;

    await onDownload(() =>
      apiContracts.agentActController
        .printXlsxReport3(
          {
            id,
            // TODO back
          } as any,
          {
            format: 'blob',
            // TODO back
            // @ts-ignore
            query: {
              actDate: getDateRange(dayjs(act.info.data?.dateFrom), dayjs(act.info.data?.dateTo)),
              timeZone: dayjs.tz.guess(),
              ...params,
            },
          },
        )
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

export const downloadReportXlsContracts =
  ({ id, params }: PrintXlsxReport3Params): ThunkType<void> =>
  async (dispatch, getState) => {
    const act = getState().act;

    await onDownload(() =>
      apiContracts.agentActController
        .printContractsXlsxReport(
          {
            id,
            // TODO back
          } as any,
          {
            format: 'blob',
            // TODO back
            // @ts-ignore
            query: {
              actDate: getDateRange(dayjs(act.info.data?.dateFrom), dayjs(act.info.data?.dateTo)),
              timeZone: dayjs.tz.guess(),
              ...params,
            },
          },
        )
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

export const downloadReportPdf =
  (id: number): ThunkType<void> =>
  async (dispatch, getState) => {
    await onDownload(() =>
      apiContracts.agentActController
        // TODO back
        // @ts-ignore
        .printPdfReport2(id, { format: 'blob', query: { timeZone: dayjs.tz.guess() } })
        .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
    );
  };

export const requestData =
  (params: Read10Params): ThunkType<Promise<ResultListDtoAgentActView>> =>
  async (dispatch, getState) => {
    const act = getState().act;
    const sorting = getSortBy(act.data.sorting);

    return apiContracts.agentActController
      .read10({
        count: true,
        total: true,
        totalAttributes: 'feeSum,paymentAmount,calculatedPaymentAmount,toPay',
        sorting: sorting ? `${sorting},-id` : '-id',
        ...getPrivateFilters(act.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const getData =
  (id: number): ThunkType<void> =>
  async (dispatch, getState) => {
    const act = getState().act;

    dispatch(actions.setData({ loading: true, data: null }));

    dispatch(requestData({ id, ...getPageBy(act.data.pagination) }))
      .then((res) => {
        dispatch(actions.setData({ loading: false, data: res }));
      })
      .catch(() => {
        dispatch(actions.setData({ loading: false, data: null }));
      });
  };

export const getContracts = (): ThunkType<void> => async (dispatch, getState) => {
  const act = getState().act;

  dispatch(actions.setData({ loading: true }));

  apiContracts.agentContractController
    .allContractsAvailableForAgentAct({
      count: true,
      partnerId: act.filters.partnerId?.key.toString(),
      filterDate: `<=${act.filters.dateTo?.format(config.format.dateBack)}`,
      agentContractId: act.filters.agentContractId?.key ?? 0,
      ...getPageBy(act.data.pagination),
    })
    .then((res) => {
      dispatch(actions.setData({ loading: false, dataCreate: res.data }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export const calculateExcludeContracts = (): ThunkType<void> => (dispatch, getState) => {
  const act = getState().act;

  dispatch(actions.setData({ loading: true }));

  apiContracts.agentContractController
    .calculatingExcludeContracts({
      actDate: act.filters.actDate?.startOf('day').utc(true).toISOString(),
      agentContractId: act.filters.agentContractId?.key,
      excludeContracts: act.data.selected.map((el) => ({ id: el, terminated: false })),
      // TODO back not typed code
      filters: {
        agentContractId: act.filters.agentContractId?.key.toString() ?? '',
        filterDate: `<=${act.filters.dateTo?.format(config.format.dateBack)}`,
        partnerId: act.filters.partnerId?.key.toString() ?? '',
      },
    })
    .then((res) => {
      dispatch(
        actions.setData({
          loading: false,
          dataCreate: { ...res.data, contracts: act.data.dataCreate?.contracts, count: act.data.dataCreate?.count },
        }),
      );
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export const getInfo =
  (id: number): ThunkType<void> =>
  async (dispatch, getState) => {
    const act = getState().act;

    dispatch(actions.setInfo({ loading: true }));

    apiContracts.agentActController
      .readInfo(id)
      .then((res) => {
        dispatch(actions.setInfo({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setInfo({ loading: false }));
      });
  };

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setFilters({ initialization: false }));
};

export const getAvailableContracts =
  (query: AllContractsAvailableForAgentActParams): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setAvailableContracts({ loading: true }));

    apiContracts.agentContractController
      .allContractsAvailableForAgentAct({
        count: true,
        page_size: -1,
        partnerId: (1).toString(),
        filterDate: '<=2023-10-20',
        insuranceProgramId: (27).toString(),
        ...query,
      })
      .then((res) => {
        dispatch(actions.setAvailableContracts({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setAvailableContracts({ loading: false }));
      });
  };

export const withdrawAct =
  (id: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    return apiContracts.agentActController
      .withdrawConfirmation(id)
      .then(() => {})
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject();
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            if (err.code === 'AGENT_ACT_DOES_NOT_CONFIRMED') {
              notification.error({ message: t('popup.error'), description: t('popup.not_confirmed') });
              return;
            }
            if (err.code === 'AGENT_ACT_IS_NOT_THE_LAST_ONE') {
              notification.error({ message: t('popup.AGENT_ACT_IS_NOT_THE_LAST_ONE') });
              return;
            }

            notification.error({ message: err.code });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject();
      });
  };

export const confirmAct =
  (id: number): ThunkType<Promise<void>> =>
  (dispatch) => {
    return apiContracts.agentActController
      .confirm(id)
      .then(() => {})
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject();
        }

        notification.error({ message: error.message });

        return Promise.reject();
      });
  };

export const deleteAct =
  (id: number): ThunkType<void> =>
  (dispatch) => {
    apiContracts.agentActController
      .delete2(id)
      .then(() => {
        history.push(ROUTES.ACTS_LIST.ROOT);
      })
      .catch((error) => {
        if (error === undefined) {
          return;
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            if (err.code === 'AGENT_ACT_ALREADY_CONFIRMED') {
              notification.error({ message: t('popup.AGENT_ACT_ALREADY_CONFIRMED') });
              return;
            }

            notification.error({ message: err.code });
          });
        } else {
          notification.error({ message: error.message });
        }
      });
  };

export const createAct = (): ThunkType<void> => (dispatch, getState) => {
  const act = getState().act;

  apiContracts.agentActController
    .createNewAct({
      actDate: act.filters.actDate?.startOf('day').utc(true).toISOString(),
      actDateTo: act.filters.dateTo?.startOf('day').utc(true).toISOString(),
      agentContractId: act.filters.agentContractId?.key,
      excludeContracts: act.data.selected.map((el) => ({ id: el, terminated: false })),
      // TODO back not typed code
      filters: {
        agentContractId: act.filters.agentContractId?.key.toString() ?? '',
        filterDate: `<=${act.filters.dateTo?.format(config.format.dateBack)}`,
        partnerId: act.filters.partnerId?.key.toString() ?? '',
      },
    })
    .then((res) => {
      history.push(generatePath(ROUTES.ACTS_LIST.DETAILS, { id: res.data }));
    })
    .catch((error) => {
      if (error === undefined) {
        return;
      }

      notification.error({ message: error.message });
    });
};

export const updateAct =
  (id: number, data: UpdateAgentActDto): ThunkType<Promise<void>> =>
  (dispatch) => {
    return apiContracts.agentActController
      .update11(id, data)
      .then(() => {})
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject();
        }

        notification.error({ message: error.message });

        return Promise.reject();
      });
  };

export const initAgentAct =
  (query: InitNewAgentActParams): ThunkType<Promise<AgentActDto>> =>
  (dispatch) => {
    dispatch(actions.setInfo({ loading: true }));

    return apiContracts.agentActController
      .initNewAgentAct(query)
      .then((res) => {
        dispatch(actions.setInfo({ loading: false }));

        return res.data;
      })
      .catch((error) => {
        if (error === undefined) {
          return Promise.reject();
        }

        dispatch(actions.setInfo({ loading: false }));

        notification.error({ message: error.message });

        return Promise.reject();
      });
  };

export interface StoreAct {
  data: Data;
  filters: Filters;
  info: Info;
  availableContracts: AvailableContracts;
}
export const initialState: StoreAct = {
  data: {
    loading: false,
    data: null,
    dataCreate: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'conclusionDate', order: 'descend' },
    selected: [],
  },
  filters: { ...initialValues, initialization: true },
  info: { loading: false, data: null },
  availableContracts: { loading: false, data: null, pagination: { page: 1, pageSize: config.ui.pagination.size } },
};

const reducer = (state = initialState, action: InferActionTypes): StoreAct => {
  switch (action.type) {
    case 'act/RESET':
      return initialState;
    case 'act/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'act/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'act/SET_INFO':
      return { ...state, info: { ...state.info, ...action.payload } };
    case 'act/SET_AVAILABLE_CONTRACTS':
      return { ...state, availableContracts: { ...state.availableContracts, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
