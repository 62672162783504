import React from 'react';
import history from 'routes/history';
import { useDispatch } from 'react-redux';

import styles from '../CreateModal.module.less';
import socketIcon from 'assets/img/layout/socket.svg';
import covidIcon from 'assets/img/layout/coronavirus.svg';
import car from 'assets/img/layout/Car.svg';
import jutlo from 'assets/img/layout/jutlo.svg';
import medicine from 'assets/img/layout/medicine.svg';
import plane from 'assets/img/layout/plane.svg';
import truck from 'assets/img/layout/truck.svg';
import { ROUTES } from 'constants/routes';
import { setBeneficiaryUpdated } from 'store/contractDetails/actions';
import { useTranslation } from 'react-i18next';
import { InsuranceProductCode } from 'types/dto/configuration-service';

interface IProduct {
  name: string;
  code: string;
  handleClose: () => void;
  handleClick?: (code: string) => void;
}

export const Product = ({ name, code, handleClose, handleClick }: IProduct) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const redirectOn = (redirectParam: string) => {
    history.push({ pathname: ROUTES.CONTRACTS_LIST.CREATE, state: redirectParam });
    handleClose();
    history.go(0);
  };

  const getImg = (code) => {
    switch (code) {
      case InsuranceProductCode.MZ:
      case InsuranceProductCode.IN:
        return socketIcon;
      case InsuranceProductCode.CV:
        return covidIcon;
      case InsuranceProductCode.MM:
        return jutlo;
      case InsuranceProductCode.MED:
        return medicine;
      case InsuranceProductCode.VZR:
        return plane;
      case InsuranceProductCode.CRG:
        return truck;
      default:
        return car;
    }
  };

  const getAlt = (code) => {
    switch (code) {
      case InsuranceProductCode.MZ:
      case InsuranceProductCode.IN:
        return 'socket icon';
      case InsuranceProductCode.CV:
        return 'covid icon';
      case InsuranceProductCode.MM:
        return 'mini icon';
      case InsuranceProductCode.MED:
        return 'medicine icon';
      case InsuranceProductCode.VZR:
        return 'vzr icon';
      case InsuranceProductCode.CRG:
        return 'cargo icon';
      default:
        return 'car icon';
    }
  };

  const getName = (code) => {
    switch (code) {
      case InsuranceProductCode.MZ:
        return t('modal.mobile_protection_products');
      case InsuranceProductCode.IN:
        return 'INNOTECH';
      case InsuranceProductCode.CV:
        return 'COVID-19';
      case InsuranceProductCode.MK:
        return t('modal.car_insurance_products');
      case InsuranceProductCode.MM:
        return t('modal.dwelling_products');
      case InsuranceProductCode.MED:
        return t('modal.medicine');
      case InsuranceProductCode.VZR:
        return 'ВЗР';
      case InsuranceProductCode.CRG:
        return 'CARGO';
      case InsuranceProductCode.OSG:
        return 'OSAGO';
      default:
        return '';
    }
  };

  const onClickProduct = () => {
    if (handleClick) {
      handleClick(code);
    }
    dispatch(setBeneficiaryUpdated(false));
    redirectOn.call(null, code);
  };

  return (
    <div className={styles.content_item} onClick={onClickProduct} data-id={`${name}-Product`}>
      <div className={styles.content_icon}>
        <img src={getImg(code)} alt={getAlt(code)} className={styles.icon} />
      </div>
      <div className={styles.content_text}>{getName(code)}</div>
    </div>
  );
};
