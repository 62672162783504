const columnsList = (t) => {
  return [
    {
      title: t('contract_details.claim_number_repayments'),
      value: 'claimNumber',
    },
    {
      title: t('contract_details.claim_date_repayments'),
      value: 'claimDate',
    },
    {
      title: t('contract_details.insurance_object_repayments'),
      value: 'insuranceObject',
    },
    {
      title: t('contract_details.risk_repayments'),
      value: 'risk',
    },
    {
      title: t('contract_details.expected_loss_repayments'),
      value: 'expectedLoss',
    },
    {
      title: t('contract_details.franchise_repayments'),
      value: 'franchise',
    },
    {
      title: t('contract_details.claim_sum_repayments'),
      value: 'claimSum',
    },
    {
      title: t('contract_details.payment_sum_repayments'),
      value: 'paymentSum',
    },
    {
      title: t('contract_details.payment_date_repayments'),
      value: 'paymentDate',
    },
    {
      title: t('contract_details.payment_type_repayments'),
      value: 'resolutionTypeName',
    },
    {
      title: t('contract_details.claim_status_repayments'),
      value: 'claimStatus',
    },
    {
      title: t('contract_details.claim_reason'),
      value: 'rejectionCauseName',
    },
  ];
};
export default columnsList;
