import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { AppState } from 'store/reducer';
import FilterInput from 'components/filters/components/Input/Input';
import { setFilters, getSubsublimits } from 'store/sublimits/actions';

import styles from './Search.module.less';

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sublimitName } = useSelector(({ sublimits: { filters } }: AppState) => ({
    sublimitName: filters.sublimitName,
  }));

  const [fieldsValues, setFieldsValues] = useState({
    query: '',
  });

  const [fieldsActiveState, setFieldsActiveState] = useState({
    query: false,
  });

  const handleFields = (eventName: string, fieldName: string, force?: boolean) => {
    if (!!fieldsValues[fieldName] && !force) {
      return;
    } else {
      if (eventName === 'focus') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: true });
      }
      if (eventName === 'blur') {
        setFieldsActiveState({ ...fieldsActiveState, [fieldName]: false });
      }
    }
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && fieldsValues.query) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const { query } = fieldsValues;

    if (query) {
      dispatch(setFilters(query, query, true));
      dispatch(getSubsublimits());
    }
  };

  return (
    <div className={styles.container}>
      <FilterInput
        label={t('sublimits.search_label')}
        labelActive={fieldsActiveState.query || fieldsValues.query}
        onChange={(e: any) => {
          const value = e.target.value;
          setFieldsValues({ ...fieldsValues, query: value.trim() });
          if (!value && value !== sublimitName) {
            dispatch(setFilters('', '', false));
            dispatch(getSubsublimits());
          }
        }}
        value={fieldsValues.query}
        closable
        onCloseHandler={() => {
          setFieldsValues({ ...fieldsValues, query: '' });
          handleFields('blur', 'query', true);
          if (!!fieldsValues.query) {
            dispatch(setFilters('', '', false));
            dispatch(getSubsublimits());
          }
        }}
        focusHandler={(e: any) => handleFields(e.type, 'query')}
        containerClassname={styles.inputWrapper}
        onKeyDown={(e) => handleOnKeyDown(e)}
      />
      <Button
        size="large"
        type="primary"
        htmlType="submit"
        className={classNames('login-form-button', styles.searchButton, {
          [styles.searchDisabled]: !fieldsValues.query,
        })}
        onClick={handleSubmit}
      >
        {t('search.search')}
      </Button>
    </div>
  );
};

export default Search;
