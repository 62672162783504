import { notification } from 'antd';
import { apiContracts } from 'api/contracts';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store/reducer';
import i18n from 'translations/i18n';
import { ActionTypes } from 'types';
import { AgentDto, List6Params, PartnerDto } from 'types/dto/contracts-service';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface PartnerAgent {
  isCreate: boolean;
  loading: boolean;
  details: AgentDto | null;
}

interface Partner {
  details: PartnerDto | null;
  loading: boolean;
}

export const actions = {
  resetPartnerAgent: () => ({ type: 'PARTNER_AGENT/RESET' } as const),
  setPartnerAgent: (payload: Partial<PartnerAgent>) => ({ type: 'PARTNER_AGENT/SET_PARTNER_AGENT', payload } as const),
  setPartner: (payload: Partial<Partner>) => ({ type: 'PARTNER_AGENT/SET_PARTNER', payload } as const),
};

export const getAgent =
  (agentId: number): ThunkType<Promise<AgentDto>> =>
  (dispatch) => {
    dispatch(actions.setPartnerAgent({ details: null, loading: true }));

    return apiContracts.agentController
      .getAgent(agentId)
      .then((res) => {
        dispatch(actions.setPartnerAgent({ details: res.data, loading: false }));
        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setPartnerAgent({ details: null, loading: false }));
        return Promise.reject(undefined);
      });
  };
export const updateAgent =
  (agentId: number, data: AgentDto): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiContracts.agentController
      .update10(agentId, data)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.saved') });
        return Promise.resolve(undefined);
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          error?.response?.data?.errors.forEach((item) => {
            if (item.code === 'NOT_UNIQUE_EMAIL') {
              notification.error({
                message: i18n.t('popup.agent_with_email_already_exists'),
              });
            }
            if (item.code === 'NOT_UNIQUE_PHONE_NUMBER') {
              notification.error({
                message: i18n.t('popup.agent_with_phone_already_exists'),
              });
            }
            if (item.code === 'NOT_UNIQUE_ENTERPRISE_CODE' || item.code === 'NOT_UNIQUE_INN') {
              notification.error({
                message: i18n.t('popup.agent_with_ent_code_already_exists'),
              });
            }
          });
        }
        notification.error({
          message: i18n.t('popup.error'),
          description: i18n.t('popup.try_again_products'),
        });
        return Promise.reject(undefined);
      });
  };

export const createAgent =
  (data: AgentDto): ThunkType<Promise<undefined>> =>
  (dispatch) => {
    return apiContracts.agentController
      .save1(data)
      .then(() => {
        notification.success({ message: i18n.t('partner_creation.agent_was_added') });
        return Promise.resolve(undefined);
      })
      .catch((error) => {
        if (error?.response?.data?.errors) {
          error?.response?.data?.errors.forEach((item) => {
            if (item.code === 'NOT_UNIQUE_EMAIL') {
              notification.error({
                message: i18n.t('popup.agent_with_email_already_exists'),
              });
            }
            if (item.code === 'NOT_UNIQUE_PHONE_NUMBER') {
              notification.error({
                message: i18n.t('popup.agent_with_phone_already_exists'),
              });
            }
            if (item.code === 'NOT_UNIQUE_ENTERPRISE_CODE') {
              notification.error({
                message: i18n.t('popup.agent_with_ent_code_already_exists'),
              });
            }
          });
        }
        notification.error({
          message: i18n.t('popup.error'),
          description: i18n.t('popup.try_again_products'),
        });

        return Promise.reject(undefined);
      });
  };

export const getCounterpartyData = async (params: List6Params) => {
  return apiContracts.counterpartyController.list6(params);
};

export const getPartner =
  (partnerId: number): ThunkType<Promise<PartnerDto>> =>
  (dispatch) => {
    dispatch(actions.setPartner({ loading: true }));

    return apiContracts.partnerController
      .read2(partnerId)
      .then(({ data }) => {
        dispatch(actions.setPartner({ details: data, loading: false }));
        return data;
      })
      .catch((error) => {
        dispatch(actions.setPartner({ loading: false }));
        return Promise.reject(undefined);
      });
  };

export interface StorePartnerAgent {
  data: PartnerAgent;
  partner: Partner;
}

export const initialState: StorePartnerAgent = {
  data: {
    isCreate: false,
    loading: false,
    details: null,
  },
  partner: {
    details: null,
    loading: false,
  },
};

const reducer = (state = initialState, action: InferActionTypes) => {
  switch (action.type) {
    case 'PARTNER_AGENT/RESET': {
      return initialState;
    }
    case 'PARTNER_AGENT/SET_PARTNER_AGENT':
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case 'PARTNER_AGENT/SET_PARTNER':
      return {
        ...state,
        partner: {
          ...state.partner,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
