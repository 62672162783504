import i18n from 'i18next';
import isEmail from 'is-email';

import { Authorization } from 'types/whiteLabel';
import { string, refiner, object, Infer } from 'utils/struct';

interface SchemaValues {
  authorization: Authorization;
}

export const getSchema = (values: SchemaValues) => {
  return object({
    login: refiner(string(), (value) => {
      if (values.authorization === Authorization.PHONE_AND_EMAIL) {
        if (!value) {
          return i18n.t('log_in.enter_email_or_phone').toString();
        }
        if (/^\+/.test(value.trim())) {
          if (!/^\+\d+$/.test(value.trim())) {
            return i18n.t('schema.phoneNumber').toString();
          }

          return true;
        }
        if (!isEmail(value.trim())) {
          return i18n.t('schema.email').toString();
        }
      }

      if (values.authorization === Authorization.PHONE) {
        if (!value) {
          return i18n.t('log_in.enter_phone_number_plus').toString();
        }
        if (/^\+/.test(value.trim())) {
          if (!/^\+\d+$/.test(value.trim())) {
            return i18n.t('schema.phoneNumber').toString();
          }

          return true;
        }
      }

      if (values.authorization === Authorization.EMAIL) {
        if (!value) {
          return i18n.t('log_in.enter_email').toString();
        }
        if (!isEmail(value.trim())) {
          return i18n.t('schema.email').toString();
        }
      }

      return true;
    }),
  });
};

const schema = getSchema({
  authorization: Authorization.PHONE_AND_EMAIL,
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  login: '',
};
