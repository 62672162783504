import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Card, Modal, Popover, Checkbox, notification } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/es/form';
import { useTranslation } from 'react-i18next';
import history from 'routes/history';
import { useAsync } from 'hooks/useAsync';

import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { FormRules } from './rules';
import Button from 'components/Button';
import FormInputText from 'components/FormInputText';
import FormSelect from 'components/FormSelect';
import { addInstitution, updateInstitution, getServiceTypeServices } from 'store/medApp/actions';
import { getLocalities, getCities } from 'api/institution';
import {
  PROPERTY_TYPES,
  FACILITY_TYPES,
  CLASSES,
  DEFAULT_HOURS_BEFORE_CANCELLATION,
  HOURS_BEFORE_CANCELLATION,
  PropertyTypes,
} from 'constants/institutions';
import { loadInsuranceCompanies } from 'store/departments/departments';
import { IClinic } from 'types/institutions';

import styles from './styles.module.less';

interface IProps extends FormComponentProps {
  institutionData: IClinic | null;
  isEdit: boolean;
}
interface IFacilityClasses {
  insuranceCompanyId: number | undefined;
  facilityClass: string | undefined;
  id: number | undefined;
  tempId?: number;
}

const MedAppDetailsForm: React.FC<IProps> = ({ form, institutionData, isEdit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { serviceTypeServices } = useSelector((store: AppState) => store.medApp);
  const { insuranceCompanies } = useSelector((store: AppState) => store.departments);

  const { getFieldDecorator, setFieldsValue } = form;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [mapCoordinates, setMapCoordinates] = useState<string>('');
  const [latitude, setLatitude] = useState<string>('0');
  const [longitude, setLongitude] = useState<string>('0');
  const [showMap, setShowMap] = useState<boolean>(false);
  const [region, setRegion] = useState<string>('');
  const [facilityClasses, setFacilityClasses] = useState<IFacilityClasses[]>([]);
  const [medicalServiceTypeIds, setMedicalServiceTypeIds] = useState<number[]>([]);
  const [refMap, setRefMap] = useState<Element | null>(null);
  const [refAutocomplete, setRefAutocomplete] = useState<HTMLInputElement | null>(null);
  const [refCard, setRefCard] = useState<Element | null>(null);
  const [infowindowContentRef, setInfowindowContentRef] = useState<Element | null>(null);
  const { value: cities, doFetch: fetchCities } = useAsync(getCities);
  const { value: localities, doFetch: fetchLocalities } = useAsync(getLocalities);

  const medicalServiceTypesOptions = serviceTypeServices?.map((el) => ({
    key: el.id ?? 0,
    value: el.id ?? 0,
    label: el.name,
  }));

  const insuranceCompanyIdOptions = insuranceCompanies?.data?.map((el) => ({
    key: el.id ?? 0,
    value: el.id ?? 0,
    title: el.name,
  }));

  const initMap = () => {
    //@ts-ignore
    window.initMap = initMap;

    const myLatlng = { lat: 50.27, lng: 30.31 };
    if (!refMap || !infowindowContentRef) {
      return;
    }
    const map = new google.maps.Map(refMap, {
      zoom: 5,
      center: myLatlng,
      mapTypeControl: false,
    });

    const card = refCard;

    const input = refAutocomplete;

    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
      types: ['establishment'],
    };

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(card!);

    const autocomplete = new google.maps.places.Autocomplete(input!, options);

    autocomplete.bindTo('bounds', map);

    let infoWindowMarker = new google.maps.InfoWindow({
      content: 'Click the map to get Lat/Lng!',
      position: myLatlng,
    });

    infoWindowMarker.open(map);

    const infowindow = new google.maps.InfoWindow();

    const marker = new google.maps.Marker({
      map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      marker.setVisible(false);
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      if (place.geometry.location) {
        const { location } = place.geometry;
        setLatitude(String(location.lat()));
        setLongitude(String(location.lng()));
        const coordinates = location.toJSON();
        setMapCoordinates(`${coordinates.lat}, ${coordinates.lng}`);
        setFieldsValue({ coordinates: `${coordinates.lat}, ${coordinates.lng}` });
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
        map.setZoom(5);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(5);
      }

      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      infoWindowMarker.close();
    });

    map.addListener('click', (mapsMouseEvent) => {
      // Close the current infoWindowMarker.
      infoWindowMarker.close();

      // Create a new infoWindowMarker.
      infoWindowMarker = new google.maps.InfoWindow({
        position: mapsMouseEvent.latLng,
      });
      setLatitude(String(mapsMouseEvent.latLng.lat()));
      setLongitude(String(mapsMouseEvent.latLng.lng()));
      const coordinates = mapsMouseEvent.latLng.toJSON();
      setMapCoordinates(`${coordinates.lat}, ${coordinates.lng}`);
      setFieldsValue({ coordinates: `${coordinates.lat}, ${coordinates.lng}` });
      infoWindowMarker.setContent(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2));
      infoWindowMarker.open(map);
    });
  };

  useEffect(() => {
    dispatch(getServiceTypeServices());
    fetchLocalities();
    dispatch(loadInsuranceCompanies());
  }, []);

  useEffect(() => {
    if (institutionData) {
      setLatitude(institutionData.coordinates?.latitude ?? '0');
      setLongitude(institutionData.coordinates?.longitude ?? '0');
      setMapCoordinates(`${institutionData.coordinates?.latitude}, ${institutionData.coordinates?.longitude}`);
      setFieldsValue({
        coordinates: `${institutionData.coordinates?.latitude}, ${institutionData.coordinates?.longitude}`,
      });
      setFacilityClasses(institutionData.facilityClasses ?? []);
      const medicalServiceTypeIds = institutionData.medicalServiceTypes?.map((el) => el.id) ?? [];
      setMedicalServiceTypeIds(medicalServiceTypeIds);
    } else {
      setFacilityClasses([
        { insuranceCompanyId: undefined, facilityClass: undefined, id: undefined, tempId: Math.random() * 100 },
      ]);
    }
  }, [institutionData]);

  useEffect(() => {
    fetchCities(region);
  }, [region]);

  useEffect(() => {
    if (refMap) {
      initMap();
    }
  }, [refMap]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onChangeLocalities = (value) => {
    setRegion(value);
  };

  const onOpenMap = (visible: boolean) => {
    setShowMap(visible);
  };

  const onChangeCoordinates = (e) => {
    const coord = e.target.value;
    const coordArr = coord.split(',');
    setLatitude(coordArr[0]);
    setLongitude(coordArr[1]);
    setMapCoordinates(coord);
    setFieldsValue({ coordinates: coord });
  };

  const onChangeMedicalService = (checkedValues) => {
    setMedicalServiceTypeIds(checkedValues);
  };

  const onAddClass = () => {
    const newFacilityClasses = [
      ...facilityClasses,
      { insuranceCompanyId: undefined, facilityClass: undefined, id: undefined, tempId: Math.random() * 100 },
    ];
    setFacilityClasses(newFacilityClasses);
  };

  const onChangeClass = (value, id, tempId) => {
    const newFacilityClasses = facilityClasses.map((el) => {
      if (id && el.id === id) {
        return { ...el, ...value };
      }
      if (tempId && el.tempId === tempId) {
        return { ...el, ...value };
      }
      return el;
    });
    setFacilityClasses(newFacilityClasses);
  };

  const onSuccess = () => {
    notification.success({
      message: isEdit ? t('clinics.edit_success') : t('clinics.add_success'),
    });
    history.push(ROUTES.MEDAPP.CLINICS.ROOT);
  };

  const onSubmit = () => {
    form.validateFields((err: any, fieldsValue: any) => {
      const {
        companyId,
        legalName,
        brandName,
        isOnline,
        locality,
        city,
        street,
        houseNumber,
        brand,
        facilityType,
        phone,
        propertyType,
        hoursBeforeCancellation,
      } = fieldsValue;

      if (!err) {
        if (facilityClasses.length < 1 || !facilityClasses[0].facilityClass || !facilityClasses[0].insuranceCompanyId) {
          notification.error({
            message: t('clinics.class_error'),
          });
          return;
        }

        let requestData: { [key: string]: any } = {
          companyId,
          legalName,
          brandName,
          isOnline: /true/i.test(isOnline),
          locality,
          city,
          street,
          houseNumber,
          latitude,
          longitude,
          brand,
          facilityType,
          phone,
          propertyType,
          hoursBeforeCancellation,
          medicalServiceTypeIds,
          facilityClasses,
          informationSystem: 'null',
        };
        isEdit
          ? dispatch(updateInstitution(requestData, institutionData?.id ?? 0, onSuccess))
          : dispatch(addInstitution(requestData, onSuccess));
      }
    });
  };

  const popoverContent = (
    <>
      <div id="pac-card" ref={setRefCard} className={styles['pac-card']}>
        <div>
          <div className={styles.header}>Search</div>
        </div>
        <div className={styles['pac-container']}>
          <input
            id="autocomplete"
            ref={setRefAutocomplete}
            className={styles['pac-input']}
            type="text"
            placeholder="Enter a location"
          />
        </div>
      </div>
      <div id="map" ref={(node) => setRefMap(node)} style={{ height: 350, width: 500 }}></div>
      <div id="infowindow-content" ref={setInfowindowContentRef} className={styles['infowindow-content']}>
        <span id="place-name" className="title"></span>
        <br />
        <span id="place-address"></span>
      </div>
    </>
  );

  return (
    <>
      <div className={styles.Form}>
        <Card style={{ borderRadius: '8px' }} bordered={false}>
          <Row gutter={[10, 5]}>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('companyId', {
                  rules: FormRules(t).USREOU_RULES,
                  initialValue: isEdit ? institutionData?.companyId : '',
                })(<FormInputText title={t('clinics.enterprise_code')} isRequired={false} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('locality', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.locality : '',
                })(
                  <FormSelect
                    title={t('clinics.region')}
                    options={localities ?? []}
                    isRequired={true}
                    onChange={onChangeLocalities}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('city', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.city : '',
                })(<FormSelect title={t('clinics.city')} options={cities ?? []} isRequired={true} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('street', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.street : '',
                })(<FormInputText title={t('clinics.address')} isRequired={true} className={styles.street_field} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('houseNumber', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.houseNumber : '',
                })(
                  <FormInputText
                    title={t('clinics.house_number')}
                    isRequired={true}
                    className={styles.houseNumber_field}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item>
                {getFieldDecorator('propertyType', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? PropertyTypes[institutionData?.propertyType ?? ''] : '',
                })(
                  <FormSelect
                    title={t('clinics.property_type')}
                    options={PROPERTY_TYPES}
                    isRequired={true}
                    className={styles.ownership_field}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('legalName', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.legalName : '',
                })(<FormInputText title={t('clinics.legal_name')} isRequired={true} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('brandName', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.brandName : '',
                })(<FormInputText title={t('clinics.brand_name')} isRequired={true} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('brand', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.brand : '',
                })(<FormInputText title={t('clinics.brand')} isRequired={true} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('coordinates', {
                  rules: FormRules(t).COORDINATE_RULES,
                })(
                  <Popover
                    content={popoverContent}
                    title={null}
                    trigger="click"
                    placement="bottomLeft"
                    onVisibleChange={(visible) => onOpenMap(visible)}
                    visible={showMap}
                  >
                    <FormInputText
                      title={t('clinics.coordinates')}
                      isRequired={true}
                      value={mapCoordinates}
                      onChange={onChangeCoordinates}
                    />
                  </Popover>,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('facilityType', {
                  rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.clinicType : '',
                })(<FormSelect title={t('clinics.lpu_type')} options={FACILITY_TYPES} isRequired={true} />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('phone', {
                  // rules: FormRules(t).NAME_RULES,
                  initialValue: isEdit ? institutionData?.phone : '',
                })(<FormInputText title={t('clinics.phone_number')} isRequired={false} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('hoursBeforeCancellation', {
                  initialValue: isEdit ? institutionData?.hoursBeforeCancellation : DEFAULT_HOURS_BEFORE_CANCELLATION,
                })(
                  <FormSelect
                    title={t('clinics.hours_before_cancellation')}
                    options={HOURS_BEFORE_CANCELLATION}
                    isRequired={true}
                  />,
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {getFieldDecorator('isOnline', {
                  rules: [
                    {
                      required: true,
                      message: t('validation.enter_data'),
                    },
                  ],
                  initialValue: isEdit ? String(institutionData?.isOnline) : '',
                })(
                  <FormSelect
                    title={t('clinics.online')}
                    options={[
                      { title: t('clinics.yes'), value: 'true', key: t('clinics.yes') },
                      { title: t('clinics.no'), value: 'false', key: t('clinics.no') },
                    ]}
                  />,
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {facilityClasses.map(({ insuranceCompanyId, facilityClass, id, tempId }) => (
                <Row gutter={[10, 24]} key={id || tempId}>
                  <Col span={12}>
                    <FormSelect
                      title={t('clinics.insurance_company')}
                      value={insuranceCompanyId}
                      options={insuranceCompanyIdOptions}
                      isRequired={true}
                      onChange={(value) => onChangeClass({ insuranceCompanyId: value }, id, tempId)}
                    />
                  </Col>
                  <Col span={12}>
                    <FormSelect
                      title={t('clinics.clinic_class')}
                      value={facilityClass}
                      options={CLASSES}
                      isRequired={true}
                      onChange={(value) => onChangeClass({ facilityClass: value }, id, tempId)}
                    />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ marginBottom: '24px' }}>
              <Button color={'primary'} onClick={onAddClass}>
                {t('clinics.add_class')}
              </Button>
            </Col>
          </Row>
          <Row gutter={[10, 5]}>
            <Col span={12}>
              <Form.Item>
                <div className={styles['check_box_wrapper']}>
                  <div className={styles['check_box_title']}>{t('clinics.service_type')}</div>
                  <div className={styles['check_box_container']}>
                    <Checkbox.Group
                      value={medicalServiceTypeIds}
                      options={medicalServiceTypesOptions}
                      onChange={onChangeMedicalService}
                    />
                  </div>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row gutter={[10, 5]}>
          <div className={styles.Form__Header}>
            <Col span={8} offset={16}>
              <div className={styles.Form__Actions}>
                <Button startIcon={undefined} onClick={() => showModal()}>
                  {t('clinics.cancel')}
                </Button>
                <Button color={'primary'} onClick={onSubmit}>
                  {t('clinics.save')}
                </Button>
              </div>
            </Col>
          </div>
        </Row>
      </div>
      {isModalVisible && (
        <Modal visible={isModalVisible} width={437} footer={null} onCancel={closeModal}>
          <div className={styles.Form__Modal_title}>{t('clinics.modal_header')}</div>
          <div className={styles.Form__Modal_content}>{t('clinics.modal_text')}</div>
          <div className={styles.Form__Actions}>
            <Button className={styles.Form__Actions_Cancel} onClick={() => closeModal()}>
              {t('clinics.cancel')}
            </Button>
            <Button color={'primary'} onClick={() => history.push(ROUTES.MEDAPP.CLINICS.ROOT)}>
              {t('clinics.yes_back')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Form.create<IProps>({ name: 'MedAppDetailsForm' })(MedAppDetailsForm);
