import { ThunkAction } from 'redux-thunk';
import { mergePartially } from 'merge-partially';
import i18n from 'i18next';
import { notification } from 'antd';

import { AppState } from 'store/reducer';
import { ActionTypes, ErrorsDto, List } from 'types';
import {
  BrandView,
  InsuranceObjectType,
  ListBrandsParams,
  ObjectSubtypeDto,
  ResultListDtoBrandView,
} from 'types/dto/contracts-service';
import { getPageBy } from 'utils/request';
import config from 'config';
import { Values, initialValues } from 'screens/Brands/Filters/Filters.schema';
import { apiContracts } from 'api/contracts';
import { Values as ValuesElectronicDevices } from 'screens/Brands/ElectronicDevices/Form/Form.schema';
import { Values as ValuesVehicles } from 'screens/Brands/Vehicles/Form/Form.schema';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

export enum TabKey {
  ELECTRONIC_DEVICES = 'ELECTRONIC_DEVICES',
  VEHICLES = 'VEHICLES',
}

interface Details {
  isCreate?: boolean;
  loading?: boolean;
}
interface DetailsElectronicDevices extends Details {
  data?: BrandView;
}
interface DetailsVehicles extends Details {
  data?: BrandView;
}
type Filters = Values & {
  initialization: boolean;
};
interface Data {
  activeTab: TabKey;
}
interface ElectronicDevices extends List {
  loading: boolean;
  data: ResultListDtoBrandView | null;
  subTypes: ObjectSubtypeDto[];
  details: DetailsElectronicDevices | null;
}
interface Vehicles extends List {
  loading: boolean;
  data: ResultListDtoBrandView | null;
  subTypes: ObjectSubtypeDto[];
  details: DetailsVehicles | null;
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'BRANDS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'BRANDS/SET_FILTERS', payload } as const),
  setElectronicDevices: (payload: Partial<ElectronicDevices>) =>
    ({ type: 'BRANDS/SET_ELECTRONIC_DEVICES', payload } as const),
  setVehicles: (payload: Partial<Vehicles>) => ({ type: 'BRANDS/SET_VEHICLES', payload } as const),
};

export const initializeFilters = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setFilters({ initialization: false }));
};

export const requestData =
  (params: ListBrandsParams = {}): ThunkType<Promise<ResultListDtoBrandView>> =>
  (dispatch, getState) => {
    const brands = getState().brands;

    return apiContracts.insuranceObjectController
      .listBrands({
        count: true,
        brand: brands.filters.brand ?? undefined,
        ...params,
      })
      .then((res) => res.data);
  };

export const requestElectronicDevices =
  (params: ListBrandsParams = {}): ThunkType<Promise<ResultListDtoBrandView>> =>
  async (dispatch, getState) => {
    return dispatch(requestData({ type: InsuranceObjectType.ELECTRONIC_DEVICE, ...params }));
  };

export const loadElectronicDevices = (): ThunkType<void> => async (dispatch, getState) => {
  const brands = getState().brands;

  try {
    dispatch(actions.setElectronicDevices({ loading: true }));

    const res = await Promise.allSettled([
      dispatch(requestElectronicDevices({ ...getPageBy(brands.electronicDevices.pagination) })),
      apiContracts.insuranceObjectController.listTypesSubtype({ objectType: InsuranceObjectType.ELECTRONIC_DEVICE }),
    ]).then((res) => {
      const electronicDevices = res[0].status === 'fulfilled' ? res[0].value : null;
      const subTypes = res[1].status === 'fulfilled' ? res[1].value.data : [];

      return { electronicDevices, subTypes };
    });

    dispatch(actions.setElectronicDevices({ loading: false, data: res.electronicDevices, subTypes: res.subTypes }));
  } catch (error) {
    dispatch(actions.setElectronicDevices({ loading: false, data: {}, subTypes: [] }));
  }
};

export const createElectronicDevices =
  (values: ValuesElectronicDevices): ThunkType<Promise<undefined>> =>
  (dispatch, getState) => {
    dispatch(actions.setElectronicDevices({ details: { loading: true } }));

    return apiContracts.insuranceObjectController
      .createBrand({
        type: InsuranceObjectType.ELECTRONIC_DEVICE,
        brand: values.brand,
        subtype: values.subtype,
      })
      .then(() => {
        notification.success({ message: i18n.t('brands_page.successCreate') });

        return Promise.resolve(undefined);
      })
      .catch((error) => {
        dispatch(actions.setElectronicDevices({ details: null }));

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: i18n.t(`error_code.${err.code}`) });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const updateElectronicDevices =
  (values: ValuesElectronicDevices): ThunkType<Promise<undefined>> =>
  (dispatch, getState) => {
    const electronicDevices = getState().brands.electronicDevices;

    dispatch(actions.setElectronicDevices({ details: { loading: true } }));

    return apiContracts.insuranceObjectController
      .updateBrand(electronicDevices.details?.data?.id ?? 0, {
        type: InsuranceObjectType.ELECTRONIC_DEVICE,
        brand: values.brand,
        subtype: values.subtype,
      })
      .then(() => {
        notification.success({ message: i18n.t('brands_page.successUpdate') });

        return Promise.resolve(undefined);
      })
      .catch((error) => {
        dispatch(actions.setElectronicDevices({ details: null }));

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: i18n.t(`error_code.${err.code}`) });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const openElectronicDevicesDetails =
  (record: BrandView): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setElectronicDevices({ details: { isCreate: false, data: record } }));
  };

export const requestVehicles =
  (params: ListBrandsParams = {}): ThunkType<Promise<ResultListDtoBrandView>> =>
  (dispatch, getState) => {
    return dispatch(requestData({ type: InsuranceObjectType.VEHICLE, ...params }));
  };

export const loadVehicles = (): ThunkType<void> => async (dispatch, getState) => {
  const brands = getState().brands;

  try {
    dispatch(actions.setVehicles({ loading: true }));

    const res = await Promise.allSettled([
      dispatch(requestVehicles({ ...getPageBy(brands.vehicles.pagination) })),
      apiContracts.insuranceObjectController.listTypesSubtype({ objectType: InsuranceObjectType.VEHICLE }),
    ]).then((res) => {
      const vehicles = res[0].status === 'fulfilled' ? res[0].value : null;
      const subTypes = res[1].status === 'fulfilled' ? res[1].value.data : [];

      return { vehicles, subTypes };
    });

    dispatch(actions.setVehicles({ loading: false, data: res.vehicles, subTypes: res.subTypes }));
  } catch (error) {
    dispatch(actions.setVehicles({ loading: false, data: {}, subTypes: [] }));
  }
};

export const createVehicles =
  (values: ValuesVehicles): ThunkType<Promise<undefined>> =>
  (dispatch, getState) => {
    dispatch(actions.setVehicles({ details: { loading: true } }));

    return apiContracts.insuranceObjectController
      .createBrand({
        type: InsuranceObjectType.VEHICLE,
        brand: values.brand,
        subtype: values.subtype,
      })
      .then(() => {
        notification.success({ message: i18n.t('brands_page.successCreate') });

        return Promise.resolve(undefined);
      })
      .catch((error) => {
        dispatch(actions.setVehicles({ details: null }));

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: i18n.t(`error_code.${err.code}`) });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const updateVehicles =
  (values: ValuesVehicles): ThunkType<Promise<undefined>> =>
  (dispatch, getState) => {
    const vehicles = getState().brands.vehicles;

    dispatch(actions.setVehicles({ details: { loading: true } }));

    return apiContracts.insuranceObjectController
      .updateBrand(vehicles.details?.data?.id ?? 0, {
        type: InsuranceObjectType.ELECTRONIC_DEVICE,
        brand: values.brand,
        subtype: values.subtype,
      })
      .then(() => {
        notification.success({ message: i18n.t('brands_page.successUpdate') });

        return Promise.resolve(undefined);
      })
      .catch((error) => {
        dispatch(actions.setVehicles({ details: null }));

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: i18n.t(`error_code.${err.code}`) });
          });
        } else {
          notification.error({ message: error.message });
        }

        return Promise.reject(undefined);
      });
  };

export const openVehiclesDetails =
  (record: BrandView): ThunkType<void> =>
  (dispatch) => {
    dispatch(actions.setVehicles({ details: { isCreate: false, data: record } }));
  };

export const deleteBrand =
  (id: number): ThunkType<Promise<undefined>> =>
  () => {
    return apiContracts.insuranceObjectController
      .deleteBrand(id)
      .then(() => {
        notification.success({ message: i18n.t('brands_page.successDelete') });

        return Promise.resolve(undefined);
      })
      .catch((error) => {
        notification.error({ message: error.message });

        return Promise.reject(undefined);
      });
  };

export interface StoreBrands {
  data: Data;
  filters: Filters;
  electronicDevices: ElectronicDevices;
  vehicles: Vehicles;
}
export const initialState: StoreBrands = {
  data: { activeTab: TabKey.ELECTRONIC_DEVICES },
  filters: { ...initialValues, initialization: true },
  electronicDevices: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
    subTypes: [],
    details: null,
  },
  vehicles: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    privateFilters: {},
    subTypes: [],
    details: null,
  },
};

const reducer = (state = initialState, action: InferActionTypes): StoreBrands => {
  switch (action.type) {
    case 'BRANDS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'BRANDS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'BRANDS/SET_ELECTRONIC_DEVICES':
      return {
        ...state,
        electronicDevices: mergePartially.shallow(state.electronicDevices, action.payload as ElectronicDevices),
      };
    case 'BRANDS/SET_VEHICLES':
      return { ...state, vehicles: mergePartially.shallow(state.vehicles, action.payload as Vehicles) };

    default:
      return state;
  }
};

export default reducer;
