import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WalletOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { AppDispatch, AppState } from 'store/reducer';
import Button from 'components/next/Button/Button';
import { actions } from 'store/dashboardExtend/dashboardExtend';
import { currencyFormat } from 'utils/formatters';

import pallete from 'styles/pallete';

const cssButton = css`
  line-height: 1;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
`;

const cssWrapper = css`
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 5px;
`;

interface Props {}

const BalanceButton = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const mobizonBalanceInfo = useSelector((store: AppState) => store.dashboardExtend.mobizonBalanceInfo);
  const balanceInfo = mobizonBalanceInfo.data?.data;

  const onClick = () => {
    dispatch(actions.setBalanceModal({ isOpen: true }));
  };

  return (
    <Button className={cssButton} loading={mobizonBalanceInfo.loading} onClick={onClick}>
      <WalletOutlined style={{ color: pallete.primary.main, fontSize: '30px', width: '30px', height: '30px' }} />
      <div className={cssWrapper}>
        <span>{t('dashboard_extend.balance_mobizon')}</span>
        {balanceInfo && (
          <span>
            {currencyFormat(balanceInfo.balance ? Number(balanceInfo.balance) : 0, balanceInfo.currency ?? undefined)}
          </span>
        )}
      </div>
      <DownOutlined />
    </Button>
  );
};

export default BalanceButton;
