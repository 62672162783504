import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { Action, IPageStore } from '../../types/store';
import { DepartmentDetails, IDepartmentDetails } from '../../types/IDepartment';
import { AnyAction } from 'redux';

export interface IDepartmentsState extends IPageStore<IDepartmentDetails> {}

const initialState: IDepartmentsState = {
  data: new DepartmentDetails(),
  loading: false,
};

export const setLoading = (state: IDepartmentsState, action: Action<boolean>) => {
  return { ...state, loading: action.payload };
};

export const setData = (state: IDepartmentsState, action: Action<IDepartmentDetails>) => {
  return { ...state, data: action.payload };
};

export const setSwitchValue = (state: IDepartmentsState, action: Action<boolean>) => {
  return {
    ...state,
    data: {
      ...state.data,
      active: action.payload,
    },
  };
};

export const toggleCurrentActiveProgram = (
  state: IDepartmentsState,
  action: Action<{ id: number; value: boolean }>,
) => {
  const { id, value } = action.payload;
  const departmentPrograms = state.data.departmentPrograms.map((program) =>
    program.insuranceProgram.id === id ? { ...program, active: value } : program,
  );

  return {
    ...state,
    data: {
      ...state.data,
      departmentPrograms,
    },
  };
};

export const setActivationDate = (state: IDepartmentsState, action: Action<{ id: number; date: string }>) => {
  const { id, date } = action.payload;
  const departmentPrograms = state.data.departmentPrograms.map((program) =>
    program.insuranceProgram.id === id ? { ...program, activationDate: date } : program,
  );

  return {
    ...state,
    data: {
      ...state.data,
      departmentPrograms,
    },
  };
};

export const setDeactivationDate = (state: IDepartmentsState, action: Action<{ id: number; date: string }>) => {
  const { id, date } = action.payload;
  const departmentPrograms = state.data.departmentPrograms.map((program) =>
    program.insuranceProgram.id === id ? { ...program, deactivationDate: date } : program,
  );

  return {
    ...state,
    data: {
      ...state.data,
      departmentPrograms,
    },
  };
};

export const populateDepartmentFields = (state: IDepartmentsState, action: Action<any>) => {
  return {
    ...state,
    data: {
      ...state.data,
      ...action.payload,
    },
  };
};

const setPrograms = (state: IDepartmentsState, { payload }: AnyAction) => ({
  ...state,
  data: {
    ...state.data,
    departmentPrograms: payload,
  },
});

export const clear = () => {
  return { ...initialState };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setData,
    setLoading,
    setSwitchValue,
    populateDepartmentFields,
    setPrograms,
    clear,
    toggleCurrentActiveProgram,
    setActivationDate,
    setDeactivationDate,
  },
});
