import React, { useReducer } from 'react';
import { Row, Col, Typography, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DefaultOptionType } from 'antd/lib/select';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { css } from '@emotion/css';

import { Select } from 'components/next/Select/Select';
import PageHeader from 'components/next/PageHeader/PageHeader';
import { DatePicker } from 'components/next/DatePicker/DatePicker';
import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { LocationState } from 'types';
import { checkPermissions } from 'roles/userCan';

import ByMonth from './ByMonth/ByMonth';
import ByProgram from './ByProgram/ByProgram';
import ByDepartment from './ByDepartment/ByDepartment';
import ByPartner from './ByPartner/ByPartner';
import ByRisk from './ByRisk/ByRisk';
import BalanceButton from './BalanceButton/BalanceButton';
import BalanceModal from './BalanceModal/BalanceModal';
import { currencyMap } from 'config/currency';
import { Group } from 'components/next/Group/Group';
import { TextField } from 'components/next/TextField/TextField';
import { currencyFormat } from 'utils/formatters';
import {
  actions,
  getDaysStatistics,
  getDashboardInfo,
  getInsuranceProgramStatistics,
  getProductRiskStatistics,
  getPartnerStatistics,
  getPartnerDepartmentStatistics,
  getPartners,
  getMobizonBalanceInfo,
} from 'store/dashboardExtend/dashboardExtend';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import config from 'config';

const cssItem = css`
  white-space: nowrap;
`;
const cssTotal = css`
  margin-right: 14px;
`;

interface Item {
  label: string;
  total?: number;
  paymentAmount: number;
}

interface State {
  companyId: number | null;
  period: Dayjs | null;
}

const DashboardExtend = () => {
  const { t } = useTranslation();
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const details = useSelector((store: AppState) => store.me.data?.details);
  const partners = useSelector((store: AppState) => store.dashboardExtend.partners);
  const info = useSelector((store: AppState) => store.dashboardExtend.info);
  const daysStatistics = useSelector((store: AppState) => store.dashboardExtend.daysStatistics);
  const insuranceProgramStatistics = useSelector((store: AppState) => store.dashboardExtend.insuranceProgramStatistics);
  const partnerStatistics = useSelector((store: AppState) => store.dashboardExtend.partnerStatistics);
  const partnerDepartmentStatistics = useSelector(
    (store: AppState) => store.dashboardExtend.partnerDepartmentStatistics,
  );
  const productRiskStatistics = useSelector((store: AppState) => store.dashboardExtend.productRiskStatistics);
  const isShowBalance = checkPermissions('DASHBOARD', 'SHOW_BALANCE');

  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      companyId: null,
      period: dayjs(),
    },
  );

  useAsyncEffect(async () => {
    await dispatch(getPartners());
    isShowBalance && dispatch(getMobizonBalanceInfo());
  }, []);

  useAsyncEffect(async () => {
    if (!state.period) {
      return;
    }

    try {
      dispatch(actions.setDashboardInfo({ loading: true }));
      dispatch(actions.setDaysStatistics({ loading: true }));
      dispatch(actions.setInsuranceProgramStatistics({ loading: true }));
      dispatch(actions.setPartnerStatistics({ loading: true }));
      dispatch(actions.setPartnerDepartmentStatistics({ loading: true }));
      dispatch(actions.setProductRiskStatistics({ loading: true }));

      const partner = partners.data.find((c) => c.id === state.companyId);
      const startDate = state.period.utc().startOf('month').format();
      const endDate = state.period.utc().endOf('month').format();
      const insuranceCompanyId = details?.insuranceCompany?.id ?? partner?.insuranceCompanyId;
      const partnerId = details?.partner?.id ?? partner?.partnerId;
      const partnerDepartmentId = details?.partnerDepartment?.id;

      await dispatch(getDashboardInfo({ startDate, endDate, insuranceCompanyId, partnerId, partnerDepartmentId }));

      dispatch(getDaysStatistics({ startDate, endDate, insuranceCompanyId, partnerId, partnerDepartmentId }));
      dispatch(
        getInsuranceProgramStatistics({ startDate, endDate, insuranceCompanyId, partnerId, partnerDepartmentId }),
      );

      if (state.companyId === null) {
        dispatch(getPartnerStatistics({ startDate, endDate, insuranceCompanyId, partnerId, partnerDepartmentId }));
      } else {
        dispatch(
          getPartnerDepartmentStatistics({ startDate, endDate, insuranceCompanyId, partnerId, partnerDepartmentId }),
        );
      }

      dispatch(getProductRiskStatistics({ startDate, endDate, insuranceCompanyId, partnerId, partnerDepartmentId }));
    } catch (error) {}
  }, [state.companyId, state.period]);

  const menu: (Route & { visible: boolean })[] = [
    {
      path: ROUTES.DASHBOARD.EXTEND,
      breadcrumbName: 'Dashboard-1',
      visible: checkPermissions('DASHBOARD', 'EXTEND'),
    },
    {
      path: ROUTES.DASHBOARD.MAIN,
      breadcrumbName: 'Dashboard-2',
      visible: checkPermissions('DASHBOARD', 'ANALYTICS'),
    },
    {
      path: ROUTES.DASHBOARD.MEDICINE,
      breadcrumbName: 'Dashboard - Medicine',
      visible: checkPermissions('DASHBOARD', 'MEDICINE'),
    },
    {
      path: ROUTES.DASHBOARD.SETTLEMENT,
      breadcrumbName: 'Dashboard - Settlement',
      visible: checkPermissions('DASHBOARD', 'SETTLEMENT'),
    },
  ];
  const menuVisible: Route[] = menu.filter((m) => m.visible).map(({ visible, ...m }) => m);

  const infoItems: Item[] = [
    {
      label: t('dashboard_extend.sold'),
      total: info.data?.countContracts,
      paymentAmount: info.data?.contractsPaymentsSum ?? 0,
    },
    {
      label: t('dashboard_extend.terminated'),
      total: info.data?.countTerminations,
      paymentAmount: info.data?.terminationsPaymentsSum ?? 0,
    },
    {
      label: t('dashboard_extend.commission_fee'),
      paymentAmount: info.data?.agentsPaymentsSum ?? 0,
    },
    {
      label: t('dashboard_extend.paid'),
      total: info.data?.countClaims,
      paymentAmount: info.data?.claimsPaymentsSum ?? 0,
    },
  ];

  const partnersOptions: DefaultOptionType[] = partners.data.map((el) => ({
    value: el.id ?? 0,
    label: el.name,
  }));

  const currency = info.data?.currencyCode ? currencyMap[info.data.currencyCode] ?? info.data?.currencyCode : '';

  return (
    <>
      <PageHeader
        title={t('dashboard.title')}
        breadcrumb={{
          routes: [
            { path: ROUTES.DASHBOARD.ROOT, breadcrumbName: t('dashboard.title') },
            {
              path: '',
              breadcrumbName: menuVisible.find((m) => m.path === location.pathname)?.breadcrumbName ?? 'unknown',
              children: menuVisible.filter((m) => m.path !== location.pathname),
            },
          ],
        }}
        extra={isShowBalance && <BalanceButton />}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row align="middle" gutter={[16, 16]}>
            <Col>
              <Typography.Text>{t('dashboard_extend.data_for_the_period')} </Typography.Text>
            </Col>

            <Col>
              <DatePicker
                value={state.period}
                onChange={(v) => setState({ period: v })}
                allowClear={false}
                disabledDate={(date) => !dayjs(date).isBetween('2018-01-01', dayjs().endOf('month'))}
                colorBase="white"
                picker="month"
                format={config.format.monthYear}
              />
            </Col>
          </Row>
        </Col>

        <Col span={6}>
          <Select
            value={partners.data.length === 1 ? partners.data[0]?.id : state.companyId}
            options={partnersOptions}
            label={t('dashboard_extend.company')}
            onChange={(v) => setState({ companyId: v })}
            disabled={partners.data.length === 1}
            loading={partners.loading}
            colorBase="white"
            showSearch
            allowClear
          />
        </Col>

        <Col span={18}>
          <Spin spinning={info.loading}>
            <Group>
              {infoItems.map((el, i) => {
                const total = el.total ? (
                  <span className={cssTotal}>{t('dashboard_extend.contract', { count: el.total })}</span>
                ) : null;

                return (
                  <TextField key={i} label={el.label} colorBase="white">
                    <Typography.Text strong className={cssItem}>
                      {total} {currencyFormat(el.paymentAmount, currency)}
                    </Typography.Text>
                  </TextField>
                );
              })}
            </Group>
          </Spin>
        </Col>

        <Col md={12} span={24}>
          <Spin spinning={daysStatistics.loading} size="large">
            <ByMonth data={daysStatistics.data} currency={currency} />
          </Spin>
        </Col>

        <Col md={12} span={24}>
          <Spin spinning={insuranceProgramStatistics.loading} size="large">
            <ByProgram data={insuranceProgramStatistics.data} currency={currency} />
          </Spin>
        </Col>

        <Col md={12} span={24}>
          {state.companyId === null ? (
            <Spin spinning={partnerStatistics.loading} size="large">
              <ByPartner data={partnerStatistics.data} currency={currency} />
            </Spin>
          ) : (
            <Spin spinning={partnerDepartmentStatistics.loading} size="large">
              <ByDepartment data={partnerDepartmentStatistics.data} currency={currency} />
            </Spin>
          )}
        </Col>

        <Col md={12} span={24}>
          <Spin spinning={productRiskStatistics.loading} size="large">
            <ByRisk data={productRiskStatistics.data} currency={currency} />
          </Spin>
        </Col>
      </Row>
      <BalanceModal />
    </>
  );
};

export default DashboardExtend;
