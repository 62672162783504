import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import { object, Infer, optional, number, date, string, nullable, refiner } from 'utils/struct';

export const schema = object({
  partnerId: optional(nullable(number())),
  partnerDepartmentId: optional(nullable(number())),
  shortName: optional(nullable(string())),
  login: optional(nullable(string())),
  dateFrom: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  partnerId: null,
  partnerDepartmentId: null,
  shortName: null,
  login: null,
  dateFrom: dayjs().startOf('month'),
  dateTo: dayjs(),
};
