import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification, Row, Col, Typography } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import { useHistory } from 'react-router-dom';

import config from 'config';
import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import { apiContracts } from 'api/contracts';
import { zeroPad } from 'utils/helpers';
import Button from 'components/next/Button/Button';
import { CodeInput } from 'components/nextRedesign/CodeInput/CodeInput';

import { schema, defaultValues, Values } from './Verification.schema';
import { State } from '../PasswordRecovery';

const cssSubmit = css`
  width: 150px;
`;
const cssCodeInput = css`
  display: flex;
  justify-content: center;
`;
const cssError = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

type Props = {
  setState: React.Dispatch<Partial<State>>;
  state: State;
};

const Verification = ({ setState, state }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const [loading, setLoading] = useState(false);
  const timer = useTimer({
    expiryTimestamp: new Date(Date.now() + config.RESET_PASSWORD_TIMEOUT),
    onExpire: () => history.push(ROUTES.PARTNER_AUTH.LOGIN),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });

  const onSubmit = (values: Values) => {
    setLoading(true);

    apiContracts.userController
      .validateToken({ login: state.login, token: values.token })
      .then(() => {
        setState({ step: 'confirmPassword', token: values.token });
      })
      .catch((err) => {
        reset();

        notification.error({
          message: t('passwordRecovery.check_the_data'),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const timerUI = `${zeroPad(timer.minutes)}:${zeroPad(timer.seconds)}`;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <Col>{t('passwordRecovery.time_left')}</Col>

          <Col>
            <Typography.Text strong type="warning">
              {timerUI}
            </Typography.Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <form onSubmit={handleSubmit(onSubmit)} data-test="form">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Controller
                name="token"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <CodeInput
                    name={name}
                    errors={errors}
                    value={value}
                    onChange={(v) => onChange(v)}
                    fields={6}
                    inputMode="tel"
                    isValid={Boolean(!errors.token)}
                    classNameContainer={cssCodeInput}
                    errorClassName={cssError}
                  />
                )}
              />
            </Col>

            <Col span={24}>
              <Button type="primary" size="large" htmlType="submit" loading={loading} className={cssSubmit}>
                {t('passwordRecovery.continue')}
              </Button>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

export default Verification;
