import React from 'react';
import { Select as SelectAntd } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';
import { css, cx } from '@emotion/css';
import { FieldErrors } from 'react-hook-form';

import { ColorBase, colorBaseMap, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

// TODO front prepare for v4 interface, cuz conflicted with internal LabeledValue interface
export interface LabeledValue {
  key: string | number;
  value?: string | number;
  label: React.ReactNode;
  disabled?: boolean;
}

// TODO front is null for consistance between rhf and antd?
export interface Props extends SelectProps<SelectValue | null> {
  label?: string;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  // TODO remove when full migrate to antd v4 will be done
  options: LabeledValue[];
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
}

export const Select = ({
  name,
  errors,
  requiredFields,
  label,
  classNameContainer,
  className,
  options,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  value,
  onChange,
  ...props
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <SelectAntd
        data-test={name}
        className={cx(
          css`
            width: 100%;

            &&&& .ant-select-selector {
              background: ${colorBaseMap[colorBase]};
              border-radius: 8px;
              border: 1px solid var(--pallete-redesign-gray-light);
              box-shadow: none;
              height: 51px;
              padding: 0 16px;
              font-size: 16px;
              font-style: normal;
            }

            &&&& .ant-select-selection {
              &-placeholder {
                line-height: 51px;
                font-weight: 400;
              }
              &-item {
                line-height: 51px;
                font-weight: 400;
              }
            }
            .ant-select-selection-item-content {
              line-height: 22px;
            }
          `,
          className,
        )}
        // need for render option list under select (not body)
        // TODO frond find solution for correct display the list near parent node
        // getPopupContainer={(triggerNode) => triggerNode}
        // TODO front improve filtering
        filterOption={(input, option) => {
          return (option?.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        // convert undefined to null for consistency between antd and react-hook-form
        onChange={(value, options) => onChange?.(value === undefined || value === null ? null : value, options)}
        // convert null to undefined for consistency between antd and react-hook-form
        value={value === null ? undefined : value}
        {...props}
      >
        {options.map((o) => (
          <SelectAntd.Option key={o.key} value={o.value} disabled={o.disabled}>
            {o.label}
          </SelectAntd.Option>
        ))}
      </SelectAntd>
    </InputContainer>
  );
};
