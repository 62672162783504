import { ThunkAction } from 'redux-thunk';
import { notification } from 'antd';

import { AppState } from 'store/reducer';
import { ActionTypes } from 'types';
import {
  DaysStatisticsDto,
  GetDashboardInfoParams,
  GetDaysStatisticsParams,
  GetInsuranceProgramStatisticsParams,
  GetPartnerDepartmentStatisticsParams,
  GetPartnerStatisticsParams,
  GetProductRiskStatisticsParams,
  InsuranceProgramStatisticsDto,
  PartnerDepartmentStatisticsDto,
  PartnerStatisticsDto,
  PartnerView,
  ProductRiskStatisticsDto,
  TopDashboardInfoDto,
  MobizonResponseDto,
  ImeiDBBalanceDto,
  MerchantBalancesResponse,
  GetMerchantBalancesParams,
  InsuranceCompanyView,
} from 'types/dto/contracts-service';
import { apiContracts } from 'api/contracts';
import onDownload from 'callbacks/onDownload';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Partners {
  loading: boolean;
  data: PartnerView[];
}
interface DashboardInfo {
  loading: boolean;
  data: TopDashboardInfoDto | null;
}
interface DaysStatistics {
  loading: boolean;
  data: DaysStatisticsDto[];
}
interface InsuranceProgramStatistics {
  loading: boolean;
  data: InsuranceProgramStatisticsDto[];
}
interface PartnerStatistics {
  loading: boolean;
  data: PartnerStatisticsDto[];
}
interface PartnerDepartmentStatistics {
  loading: boolean;
  data: PartnerDepartmentStatisticsDto[];
}
interface ProductRiskStatistics {
  loading: boolean;
  data: ProductRiskStatisticsDto[];
}

interface MobizonBalanceInfo {
  loading: boolean;
  data: MobizonResponseDto | null;
}

interface ImeiBalanceInfo {
  loading: boolean;
  data: ImeiDBBalanceDto | null;
}

interface FondyBalanceInfo {
  loading: boolean;
  data: MerchantBalancesResponse | null;
}

interface BalanceModal {
  isOpen?: boolean;
  loading?: boolean;
}

interface InsuranceCompanies {
  data?: InsuranceCompanyView[];
  loading?: boolean;
}

export const actions = {
  setPartners: (payload: Partial<Partners>) => ({ type: 'DASHBOARD_EXTEND/SET_PARTNERS', payload } as const),
  setDashboardInfo: (payload: Partial<DashboardInfo>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_DASHBOARD_INFO', payload } as const),
  setDaysStatistics: (payload: Partial<DaysStatistics>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_DAYS_STATISTICS', payload } as const),
  setInsuranceProgramStatistics: (payload: Partial<InsuranceProgramStatistics>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_INSURANCE_PROGRAM_STATISTICS', payload } as const),
  setPartnerStatistics: (payload: Partial<PartnerStatistics>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_PARTNER_STATISTICS', payload } as const),
  setPartnerDepartmentStatistics: (payload: Partial<PartnerDepartmentStatistics>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_PARTNER_DEPARTMENT_STATISTICS', payload } as const),
  setProductRiskStatistics: (payload: Partial<ProductRiskStatistics>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_PRODUCT_RISK_STATISTICS', payload } as const),
  setMobizonBalanceInfo: (payload: Partial<MobizonBalanceInfo>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_MOBIZON_BALANCE_INFO', payload } as const),
  setImeiBalanceInfo: (payload: Partial<ImeiBalanceInfo>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_IMEI_BALANCE_INFO', payload } as const),
  setFondyBalanceInfo: (payload: Partial<FondyBalanceInfo>) =>
    ({ type: 'DASHBOARD_EXTEND/SET_FONDY_BALANCE_INFO', payload } as const),
  setBalanceModal: (payload: BalanceModal) => ({ type: 'DASHBOARD_EXTEND/SET_BALANCE_MODAL', payload } as const),
  setInsuranceCompanies: (payload: InsuranceCompanies) =>
    ({ type: 'DASHBOARD_EXTEND/SET_INSURANCE_COMPANIES', payload } as const),
};

export const getPartners = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setPartners({ loading: true, data: [] }));

  apiContracts.partnerController
    .list2({ distinct: true, page_size: -1 })
    .then((res) => {
      dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch((error) => {
      dispatch(actions.setPartners({ loading: false, data: [] }));

      if (error?.response?.data?.message) {
        notification.error({ message: error.response.data.message });
      }
    });
};

export const getDashboardInfo =
  (query: GetDashboardInfoParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setDashboardInfo({ loading: true, data: null }));

    return apiContracts.statisticsController
      .getDashboardInfo(query)
      .then((res) => {
        dispatch(actions.setDashboardInfo({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setDashboardInfo({ loading: false, data: null }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getDaysStatistics =
  (query: GetDaysStatisticsParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setDaysStatistics({ loading: true, data: [] }));

    apiContracts.statisticsController
      .getDaysStatistics(query)
      .then((res) => {
        dispatch(actions.setDaysStatistics({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setDaysStatistics({ loading: false, data: [] }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getDaysStatisticsReport = (): ThunkType<void> => async (dispatch, getState) => {
  const data = getState().dashboardExtend.daysStatistics.data;

  onDownload(() =>
    apiContracts.statisticsController
      .printXlsxDaysStatistics(data, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const getInsuranceProgramStatistics =
  (query: GetInsuranceProgramStatisticsParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setInsuranceProgramStatistics({ loading: true, data: [] }));

    apiContracts.statisticsController
      .getInsuranceProgramStatistics(query)
      .then((res) => {
        dispatch(actions.setInsuranceProgramStatistics({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setInsuranceProgramStatistics({ loading: false, data: [] }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getInsuranceProgramStatisticsReport = (): ThunkType<void> => async (dispatch, getState) => {
  const data = getState().dashboardExtend.insuranceProgramStatistics.data;

  onDownload(() =>
    apiContracts.statisticsController
      .printXlsxInsuranceProgramStatistics(data, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const getPartnerStatistics =
  (query: GetPartnerStatisticsParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setPartnerStatistics({ loading: true, data: [] }));

    apiContracts.statisticsController
      .getPartnerStatistics(query)
      .then((res) => {
        dispatch(actions.setPartnerStatistics({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setPartnerStatistics({ loading: false, data: [] }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getPartnerStatisticsReport = (): ThunkType<void> => async (dispatch, getState) => {
  const data = getState().dashboardExtend.partnerStatistics.data;

  onDownload(() =>
    apiContracts.statisticsController
      .printXlsxPartnerStatistics(data, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const getPartnerDepartmentStatistics =
  (query: GetPartnerDepartmentStatisticsParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setPartnerDepartmentStatistics({ loading: true, data: [] }));

    apiContracts.statisticsController
      .getPartnerDepartmentStatistics(query)
      .then((res) => {
        dispatch(actions.setPartnerDepartmentStatistics({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setPartnerDepartmentStatistics({ loading: false, data: [] }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getPartnerDepartmentStatisticsReport = (): ThunkType<void> => async (dispatch, getState) => {
  const data = getState().dashboardExtend.partnerDepartmentStatistics.data;

  onDownload(() =>
    apiContracts.statisticsController
      .printXlsxPartnerDepartmentStatistics(data, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const getProductRiskStatistics =
  (query: GetProductRiskStatisticsParams): ThunkType<void> =>
  async (dispatch, getState) => {
    dispatch(actions.setProductRiskStatistics({ loading: true, data: [] }));

    apiContracts.statisticsController
      .getProductRiskStatistics(query)
      .then((res) => {
        dispatch(actions.setProductRiskStatistics({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setProductRiskStatistics({ loading: false, data: [] }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
      });
  };

export const getProductRiskStatisticsReport = (): ThunkType<void> => async (dispatch, getState) => {
  const data = getState().dashboardExtend.productRiskStatistics.data;

  onDownload(() =>
    apiContracts.statisticsController
      .printXlsxRiskStatistics(data, { format: 'blob' })
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const getMobizonBalanceInfo = (): ThunkType<void> => async (dispatch, getState) => {
  dispatch(actions.setMobizonBalanceInfo({ loading: true, data: null }));

  apiContracts.mobizonController
    .getMobizonBalanceInfo()
    .then((res) => {
      dispatch(actions.setMobizonBalanceInfo({ loading: false, data: res.data }));
    })
    .catch((error) => {
      dispatch(actions.setMobizonBalanceInfo({ loading: false, data: null }));

      if (error?.response?.data?.message) {
        notification.error({ message: error.response.data.message });
      }
    });
};

export const getImeiBalanceInfo = (): ThunkType<Promise<ImeiDBBalanceDto>> => async (dispatch, getState) => {
  dispatch(actions.setImeiBalanceInfo({ loading: true, data: null }));

  return apiContracts.deviceDetailsController
    .getImeiDbBalanceInfo()
    .then((res) => {
      dispatch(actions.setImeiBalanceInfo({ loading: false, data: res.data }));
      return res.data;
    })
    .catch((error) => {
      dispatch(actions.setImeiBalanceInfo({ loading: false, data: null }));

      if (error?.response?.data?.message) {
        notification.error({ message: error.response.data.message });
      }
      return Promise.reject();
    });
};

export const getFondyBalanceInfo =
  (query: GetMerchantBalancesParams): ThunkType<Promise<MerchantBalancesResponse>> =>
  async (dispatch, getState) => {
    dispatch(actions.setFondyBalanceInfo({ loading: true, data: null }));

    return apiContracts.fondyController
      .getMerchantBalances(query)
      .then((res) => {
        dispatch(actions.setFondyBalanceInfo({ loading: false, data: res.data }));
        return res.data;
      })
      .catch((error) => {
        dispatch(actions.setFondyBalanceInfo({ loading: false, data: null }));

        if (error?.response?.data?.message) {
          notification.error({ message: error.response.data.message });
        }
        return Promise.reject();
      });
  };

export const getInsuranceCompanys = (): ThunkType<void> => (dispatch) => {
  dispatch(actions.setInsuranceCompanies({ loading: true }));

  return apiContracts.insuranceCompanyController
    .list4({
      distinct: true,
      attributes: 'id,name',
      page_size: -1,
    })
    .then(({ data }) => {
      dispatch(actions.setInsuranceCompanies({ data: data.resultList, loading: false }));
    })
    .catch(() => {
      dispatch(actions.setInsuranceCompanies({ data: [], loading: false }));
    });
};

export interface StoreDashboardExtend {
  partners: Partners;
  info: DashboardInfo;
  daysStatistics: DaysStatistics;
  insuranceProgramStatistics: InsuranceProgramStatistics;
  partnerStatistics: PartnerStatistics;
  partnerDepartmentStatistics: PartnerDepartmentStatistics;
  productRiskStatistics: ProductRiskStatistics;
  mobizonBalanceInfo: MobizonBalanceInfo;
  imeiBalanceInfo: ImeiBalanceInfo;
  fondyBalanceInfo: FondyBalanceInfo;
  balanceModal: BalanceModal | null;
  insuranceCompanies: InsuranceCompanies;
}
export const initialState: StoreDashboardExtend = {
  partners: { loading: false, data: [] },
  info: { loading: false, data: null },
  daysStatistics: { loading: false, data: [] },
  insuranceProgramStatistics: { loading: false, data: [] },
  partnerStatistics: { loading: false, data: [] },
  partnerDepartmentStatistics: { loading: false, data: [] },
  productRiskStatistics: { loading: false, data: [] },
  mobizonBalanceInfo: { loading: false, data: null },
  imeiBalanceInfo: { loading: false, data: null },
  fondyBalanceInfo: { loading: false, data: null },
  balanceModal: { loading: false, isOpen: false },
  insuranceCompanies: {
    data: [],
    loading: false,
  },
};

const reducer = (state = initialState, action: InferActionTypes): StoreDashboardExtend => {
  switch (action.type) {
    case 'DASHBOARD_EXTEND/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_DASHBOARD_INFO':
      return { ...state, info: { ...state.info, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_DAYS_STATISTICS':
      return { ...state, daysStatistics: { ...state.daysStatistics, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_INSURANCE_PROGRAM_STATISTICS':
      return { ...state, insuranceProgramStatistics: { ...state.insuranceProgramStatistics, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_PARTNER_STATISTICS':
      return { ...state, partnerStatistics: { ...state.partnerStatistics, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_PARTNER_DEPARTMENT_STATISTICS':
      return { ...state, partnerDepartmentStatistics: { ...state.partnerDepartmentStatistics, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_PRODUCT_RISK_STATISTICS':
      return { ...state, productRiskStatistics: { ...state.productRiskStatistics, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_MOBIZON_BALANCE_INFO':
      return { ...state, mobizonBalanceInfo: { ...state.mobizonBalanceInfo, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_IMEI_BALANCE_INFO':
      return { ...state, imeiBalanceInfo: { ...state.imeiBalanceInfo, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_FONDY_BALANCE_INFO':
      return { ...state, fondyBalanceInfo: { ...state.fondyBalanceInfo, ...action.payload } };
    case 'DASHBOARD_EXTEND/SET_BALANCE_MODAL': {
      return {
        ...state,
        balanceModal: {
          ...state.balanceModal,
          ...action.payload,
        },
      };
    }

    case 'DASHBOARD_EXTEND/SET_INSURANCE_COMPANIES': {
      return {
        ...state,
        insuranceCompanies: {
          ...state.insuranceCompanies,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
