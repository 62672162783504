import React, { useEffect, useReducer } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Col, notification, Row, Spin, Typography } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useTranslation } from 'react-i18next';
import { Grid } from 'antd';
import { useTimer } from 'react-timer-hook';
import { css } from '@emotion/css';

import { useRequired } from 'hooks/useRequired';
import { NumberField } from 'components/next/NumberField/NumberField';
import { Button } from 'components/next/Button/Button';

import { schema, defaultValues, Values } from './Verification.schema';
import { State } from '../SignUp';
import { apiContracts } from 'api/contracts';
import config from 'config';
import { zeroPad } from 'utils/helpers';
import Step from '../Step/Step';
import Back from '../Back/Back';
import { Resend } from 'components/next/Resend/Resend';
import AuthContainer from 'components/next/AuthContainer/AuthContainer';
import { ErrorsDto } from 'types';

const cssSubmit = css`
  width: 150px;
`;

const COUNTDOWN = 2 * 60 * 1000;

interface _State {
  sending: boolean;
  hasError: boolean;
  submitting: boolean;
}
type Props = {
  steps: number;
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const Verification = ({ steps, state, setState }: Props) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const timer = useTimer({
    expiryTimestamp: new Date(Date.now() + config.SIGN_UP_TIMEOUT),
    onExpire: () => setState({ step: 0 }),
  });
  const timerResend = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
  });

  const [_state, _setState] = useReducer(
    (prevState: _State, nextState: Partial<_State>): _State => ({ ...prevState, ...nextState }),
    {
      sending: false,
      hasError: false,
      submitting: false,
    },
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setError,
  } = useForm({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { requiredFields } = useRequired(schema, watch());

  const _sendVerification = () => {
    _setState({ sending: true, hasError: false });

    apiContracts.managerSignupController
      .sendSmsVerification({
        partnerCode: state.signupAccount?.partnerCode,
        email: state.signupAccount?.email,
        phoneNumber: state.signupAccount?.phoneNumber,
      })
      .then(() => {
        _setState({ sending: false });

        timerResend.restart(new Date(Date.now() + COUNTDOWN));
      })
      .catch(() => {
        _setState({ hasError: true, sending: false });
      });
  };

  useEffect(() => {
    _sendVerification();
  }, []);

  const onSubmit = (values: Values) => {
    _setState({ submitting: true });

    apiContracts.managerSignupController
      .validateSmsVerification({
        email: state.signupAccount?.email,
        phoneNumber: state.signupAccount?.phoneNumber,
        partnerCode: state.signupAccount?.partnerCode,
        token: values.token,
      })
      .then((res) => {
        setState({ step: state.step + 1, signupAccount: { ...res.data, token: values.token } });
        _setState({ submitting: true });
      })
      .catch((error) => {
        _setState({ submitting: false });

        reset();

        if (error === undefined) {
          return;
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            if (err.code === 'INVALID_VERIFICATION_TOKEN') {
              notification.error({ message: t('validation.code_invalid') });

              return;
            }

            notification.error({ message: err.code });
          });
        } else {
          notification.error({ message: error.message });
        }
      });
  };

  const timerUI = `${zeroPad(timer.minutes)}:${zeroPad(timer.seconds)}`;
  const timerUIResend = `${zeroPad(timerResend.minutes)}:${zeroPad(timerResend.seconds)}`;

  return (
    <AuthContainer>
      <Spin spinning={_state.sending}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row justify="space-between" align="middle">
              <Col>{<Step step={state.step} steps={steps} />}</Col>

              <Col>
                <Typography.Text strong type="warning">
                  {timerUI}
                </Typography.Text>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Typography.Title level={screens.lg ? 1 : 3}>{t('signup.code_from_sms')}</Typography.Title>
          </Col>

          <Col span={24}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Controller
                    name="token"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <NumberField
                        name={name}
                        errors={errors}
                        requiredFields={requiredFields}
                        value={value}
                        onValueChange={(v) => onChange(v.value)}
                        label={t('signup.code_from_sms')}
                        colorBase="grey"
                        allowLeadingZeros
                        format={'####'}
                        placeholder="1234"
                      />
                    )}
                  />
                </Col>

                <Col span={24}>
                  <Resend isRunning={timerResend.isRunning} timerUI={timerUIResend} handleResend={_sendVerification} />
                </Col>

                <Col span={24}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    disabled={_state.hasError}
                    loading={_state.submitting}
                    className={cssSubmit}
                  >
                    {t('signup.next')}
                  </Button>
                </Col>

                <Col span={24}>
                  <Back handleBack={() => setState({ step: 0 })} />
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Spin>
    </AuthContainer>
  );
};

export default Verification;
