import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/next/Table/Table';
import { PartnerStatisticsDto } from 'types/dto/contracts-service';
import { currencyFormat } from 'utils/formatters';

interface Props {
  data: PartnerStatisticsDto[];
  currency: string;
}

const ByPartner = ({ data, currency }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<PartnerStatisticsDto>[] = [
    {
      title: t('dashboard_extend.byPartner.partnerName'),
      key: 'partnerDepartmentNumber',
      render: (_, record) => record.partnerName,
    },
    {
      title: t('dashboard_extend.byPartner.countContracts'),
      key: 'countContracts',
      render: (_, record) => record.countContracts,
    },
    {
      title: t('dashboard_extend.byPartner.contractPaymentSum'),
      key: 'contractPaymentSum',
      render: (_, record) => currencyFormat(record.contractPaymentSum ?? 0, currency),
    },
    {
      title: t('dashboard_extend.byPartner.countClaims'),
      key: 'countClaims',
      render: (_, record) => record.countClaims,
    },
    {
      title: t('dashboard_extend.byPartner.claimPaymentSum'),
      key: 'claimPaymentSum',
      render: (_, record) => currencyFormat(record.claimPaymentSum ?? 0, currency),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.partnerName ?? ''}
      scroll={{ x: 600, y: 265 }}
    />
  );
};

const MemoizedByPartner = React.memo(ByPartner);

export default MemoizedByPartner;
