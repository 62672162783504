import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'components/next/Button/Button';
import { IconXLSX } from 'components/next/Icon/Icon';
import { PartnerStatisticsDto } from 'types/dto/contracts-service';

import Card, { cssTitle } from '../Card/Card';
import Chart from './ByPartnerChart/ByPartnerChart';
import Table from './ByPartnerTable/ByPartnerTable';
import Switch from '../Switch/Switch';
import { transformData, transformDataForChart } from './ByPartner.model';
import { getPartnerStatisticsReport } from 'store/dashboardExtend/dashboardExtend';

interface Props {
  data: PartnerStatisticsDto[];
  currency: string;
}

const ByPartner = ({ data, currency }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isChart, setIsChart] = useState(false);

  const transformedData = useMemo(() => transformData(data), [data]);
  const transformedDataForChart = useMemo(() => transformDataForChart(transformedData), [transformedData]);
  const hasData = Boolean(transformedData.length);

  return (
    <Card
      isChart={isChart}
      hasData={hasData}
      title={
        <>
          <span className={cssTitle}>{t('dashboard_extend.byPartner.title')}</span>
          {hasData && (
            <Switch
              checked={isChart}
              onChange={() => {
                setIsChart(!isChart);
              }}
            />
          )}
        </>
      }
      extra={
        hasData && (
          <Button onClick={() => dispatch(getPartnerStatisticsReport())}>
            <IconXLSX color="primary" background="primary" />
            Excel
          </Button>
        )
      }
    >
      {isChart ? (
        <Chart data={transformedDataForChart} currency={currency} />
      ) : (
        <Table data={transformedData} currency={currency} />
      )}
    </Card>
  );
};

export default ByPartner;
