import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { isEqual } from 'lodash/fp';

import Modal from '../../components/Modal';
import Previewer from '../../components/Previewer';
import ItemsList, { builders, selectors } from '../../components/List';
import Status from '../../components/Status';
import { useModal } from 'screens/Appointments/hooks/useModal';
import { CONTRACT_STATUS_TYPES } from 'constants/appointments';
import { Picker, WithVariants, Contract } from 'types/appointments';
import { Check } from 'components/Icons';
import { MAX_SCROLLBAR_HEIGHT } from 'constants/appointments';
import styles from './ContractPicker.module.less';
import { IContract } from 'types/appointments';
import { defaultValues } from '../../hooks/useForm';

export const ContractPicker: React.FC<Picker<Contract> & WithVariants<IContract>> = ({
  defaultValue,
  onClose,
  variants,
  sortingClaims,
  setField,
}) => {
  const { isOpen, open, close, confirm, picked, onPick } = useModal(onClose, defaultValue);

  const { t } = useTranslation();

  const handleContractClick = (contract) => {
    if (picked?.contractId === contract.contractId) {
      onPick({ contractId: 0, contractNumber: '', status: null });
    } else {
      onPick({
        contractId: contract.contractId,
        contractNumber: contract.contractNumber,
        status: { statusType: contract.contractStatus, translation: t(contract.statusTranslationKey) },
      });
    }
  };

  const items = variants?.map((variant) => {
    const statusObj = CONTRACT_STATUS_TYPES.find(({ status }) => status === variant.contractStatus);
    return { ...variant, statusTranslationKey: statusObj?.translationKey };
  });

  const comp = ({ contractId, contractNumber, statusTranslationKey, contractStatus }) => {
    return (
      <div className={styles.wrapper}>
        <div className={styles.number_wrapper}>
          {contractId === picked?.contractId && (
            <div className={styles.check}>
              <Check />
            </div>
          )}
          <span className={styles.number}>{contractNumber}</span>
        </div>
        <Status status={contractStatus} translation={t(statusTranslationKey)} />
      </div>
    );
  };

  const handleConfirm = () => {
    setField && setField('type', defaultValues.type);
    picked?.contractId && sortingClaims && sortingClaims(picked.contractId);
    confirm();
  };

  return (
    <>
      <Previewer
        displayValue={defaultValue.contractNumber}
        onClick={open}
        title={defaultValue ? t('appointments.contract_id') : t('appointments.contract_title')}
        hideCheckbox
        looksLikeSelect
        status={defaultValue.status}
        disabled={!!defaultValue.contractNumber}
      />
      {isOpen && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => {
              onPick(defaultValue);
              close();
            }}
          >
            <Modal.Header
              title={t('appointments.contract_list_title')}
              onClose={() => {
                onPick(defaultValue);
                close();
              }}
            />
            <Modal.Body>
              <Scrollbars autoHeight autoHeightMax={MAX_SCROLLBAR_HEIGHT}>
                <ItemsList
                  items={items}
                  itemBuilder={builders.byContract(comp)}
                  selector={selectors.byContractId(picked?.contractNumber)}
                  onItemClick={handleContractClick}
                />
              </Scrollbars>
            </Modal.Body>
            <Modal.Confirm disabled={isEqual(defaultValue, picked)} onClick={handleConfirm} />
          </OutsideClickHandler>
        </Modal>
      )}
    </>
  );
};
