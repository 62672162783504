import { InsuranceProductCode } from 'types/dto/configuration-service';

const getRedirectState = (data: string) => {
  switch (data) {
    case 'PERSON':
      return InsuranceProductCode.CV;
    case 'ELECTRONIC_DEVICE':
      return InsuranceProductCode.MZ;
    case 'VEHICLE':
      return InsuranceProductCode.MK;
    case 'ESTATE':
      return InsuranceProductCode.MM;
  }
};

export default getRedirectState;
