import React from 'react';
import { Button as BaseButton } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import { css, cx } from '@emotion/css';
import palleteRedesign from 'styles/palleteRedesign';

const cssSizeSmall = css`
  &.ant-btn {
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const cssSizeLarge = css`
  &.ant-btn {
    height: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const Button = ({ className, ...props }: ButtonProps) => {
  const cssRoot = css`
    &.ant-btn {
      border-radius: 8px;
      height: 46px;
      color: var(--ant-primary-color);
      border: 2px solid var(--ant-primary-color);

      :disabled {
        background-color: ${palleteRedesign.gray.light2};
        color: ${palleteRedesign.basic.white};
        border: 2px solid ${palleteRedesign.gray.light2};
      }
    }
  `;

  const cssTypePrimary = css`
    &.ant-btn {
      color: ${palleteRedesign.basic.white};
    }
  `;

  return (
    <BaseButton
      className={cx(
        cssRoot,
        { [cssTypePrimary]: props.type === 'primary' },
        { [cssSizeSmall]: props.size === 'small' },
        { [cssSizeLarge]: props.size === 'large' },
        className,
      )}
      {...props}
    />
  );
};

export default Button;
