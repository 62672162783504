import { createTypes } from 'redux-compose-reducer';

import _ from 'lodash';
import moment, { Moment } from 'moment';
import { notification } from 'antd';

import * as contractsAPI from 'api/contracts';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import { adapter } from 'constants/validationErrors';
import onDownload from '../../callbacks/onDownload';
import { currencyAdapter } from './../../hooks/useCurrency';
import { IContract, IContractForm, ISelectProgramDuration, IUserContract, ICalc } from '../../types/contractDetails';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { getDMSProgram, makerRequestParams, reverseMoneyToNumber } from 'utils/helpers';
import { AppState } from 'store/reducer';
import { getContractType } from 'utils/getContractType';
import { getContractNumberForAPEXSK, getFormattedDuration } from './helper';
import { getAffordableInsuranceProgram } from '../../screens/ContractDetails/utils';
import { Dispatch } from 'redux';
import { t, TFunction } from 'i18next';
import { ContractDocumentDto, SendingType } from 'types/dto/contracts-service';
import { createUploadDevicePhotoArr } from 'components/DetailsCard/utils/createUploadDevicePhotoArr';
import { IUploadState } from 'components/UploadFileComponent/UploadFileComponent';
import dayjs from 'dayjs';
import { store } from 'store';
import { apiContracts } from 'api/contracts';
import { apiLocations } from 'api/locations';
import { apiConfiguration } from 'api/configuration';
import { InsuranceProductCode } from 'types/dto/configuration-service';

export enum CODE_TYPE {
  VIN = 'vin',
  IMIE = 'imei',
}

// type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const TYPES = createTypes('contractDetails', [
  'setContract',
  'addContract',
  'getDataFromPhoneNumber',
  'setInsuranceProlongRate',
  'getDataFromImei',
  'getUniqueImei',
  'getContractCalculation',
  'setInsuranceProgram',
  'setContractNumber',
  'setSaleDate',
  'setConclusionDate',
  'updateContractDetails',
  'setUploadDocument',
  'setAllDocumnets',
  'getInsuranceProgramRisks',
  'setInsuranceDetailProgram',
  'getInsurancePrograms',
  'getInsuranceObjectSubTypes',
  'getUsersInfo',
  'setEndDate',
  'setAccountNumber',
  'signContractClaim',
  'sendSignVerification',
  'printContract',
  'setInsuranceObjectSubTypeId',
  'setInitialContractState',
  'setInsuranceProgramDuration',
  'setInsuranceContractSum',
  'setCalculatedPaymentAmount',
  'setFixedPaymentAmount',
  'setContractNumberEnding',
  'setContractSignatory',
  'setContractBeneficiary',
  'setContractInfo',
  'removeReconciliation',
  'removeReconciliationWarnings',
  'setContractDeviceData',
  'setFixedDuration',
  'setPagination',
  'setPaymentsList',
  'setSorting',
  'getPrivateFiltersData',
  'setTablePage',
  'setBeneficiaryUpdated',
  'setPaymentAmountForObject',
  'setEstateTypes',
  'setEditFotoContractId',
  'setEstateType',
  'setEstateData',
  'setEstateSybTypes',
  'setGenNumber',
  'setContractDuration',
  'removeReconsiliation',
  'setChosenPolicy',
  'setInitialPolicy',
  'setInitialRates',
  'resetInitialPaymentAmount',
  'setIdentifier',
  'setNumberMainPart',
  'setImeiSerialNumber',
  'setObjectConfiguration',
  'setStartDate',
  'getCountries',
  'resetSignatory',
  'resetContractBeneficiary',
  'setInitialInsuranceProgram',
  'setInsuranceProductCode',
  'newDocumentUpload',
  'newDocumentUploadUpdate',
  'testAction',
  'setClientOnSignError',
  'setContractById',
  'sendClientSignVerification',
  'sendClientSign',
  'setSubAgreementStartDate',
  'setInsuranceSumId',
  'setUploadReceiptDate',
  'setAutogenerateNumber',
  'setProgramCode',
]);

const getIdentifier = (code) =>
  ({
    MZ_CI_A: '101',
    MZ_CI_B: '102',
    MZ_CI_C: '103',
    MK_D_I: '091',
  }[code]);

export const setBeneficiaryUpdated = (beneficiaryUpdated) => ({
  type: TYPES.setBeneficiaryUpdated,
  payload: beneficiaryUpdated,
});

export const resetSignatory = () => {
  return {
    type: TYPES.resetSignatory,
  };
};

export const resetContractBeneficiary = () => {
  return {
    type: TYPES.resetContractBeneficiary,
  };
};

export const setInitialProgram = (data) => {
  return {
    type: TYPES.setInitialInsuranceProgram,
    payload: data,
  };
};

export const setInitialContractState = () => ({
  type: TYPES.setInitialContractState,
});

export const setInsuranceProductCode = (code: string) => ({
  type: TYPES.setInsuranceProductCode,
  payload: { code },
});

export const setChosenPolicy = (policy: number) => ({
  type: TYPES.setChosenPolicy,
  payload: policy,
});

export const getInsurancePrograms = (insuranceType?: string) => async (dispatch: any) => {
  try {
    const response = await contractsAPI.getInsurancePrograms(insuranceType);
    dispatch({
      type: TYPES.getInsurancePrograms,
      payload: { insurancePrograms: response?.data?.resultList || [] },
    });
  } catch (error) {
    console.log('error: ', error);
    dispatch({ type: TYPES.getInsurancePrograms, payload: { errorMessage: error } });
  }
};

export const getInsuranceObjectSubTypes = (programId: number) => async (dispatch: any) => {
  try {
    const response = await contractsAPI.getInsuranceObjectSubTypes(programId);
    dispatch({ type: TYPES.getInsuranceObjectSubTypes, payload: { insuranceObjectTypes: response.data } });
  } catch (error) {
    console.log('error: ', error);
    dispatch({ type: TYPES.getInsuranceObjectSubTypes, payload: { errorMessage: error } });
  }
};

export const setContract = (id?: number) => async (dispatch: any) => {
  dispatch({ type: TYPES.setContract, payload: { pending: true } });
  try {
    if (id) {
      const response = await contractsAPI.getContractDetails(id);
      const contract = response.data ?? {};

      dispatch({
        type: TYPES.setContract,
        payload: {
          pending: false,
          contract: {
            ...contract,
            calculatedPaymentAmount: contract.calculatedPaymentAmount
              ? contract.calculatedPaymentAmount
              : contract.calculatedPaymentAmount === 0
              ? 0
              : contract.paymentAmount,
          },
        },
      });
      dispatch({ type: TYPES.setContractNumber, payload: contract.contractNumber });
    }
  } catch (error) {
    console.log('error: ', error);
    dispatch({ type: TYPES.setContract, payload: { pending: false, errorMessage: error } });
  }
  return;
};

export const getDataFromPhoneNumber = (phoneNumber: string) => async (dispatch: any) => {
  try {
    if (phoneNumber) {
      const response = await apiContracts.counterpartyController.list6({ phoneNumber });

      dispatch({ type: TYPES.getDataFromPhoneNumber, payload: { phoneData: response.data.resultList } });
    } else {
      dispatch({ type: TYPES.getDataFromPhoneNumber, payload: { phoneData: [] } });
    }
  } catch (error) {
    console.log('error: ', error);
    dispatch({ type: TYPES.getDataFromPhoneNumber, payload: { errorMessage: error } });
  }
};

export const getDataFromImei = (imei: string, t) => async (dispatch: any) => {
  dispatch({ type: TYPES.setImeiSerialNumber, payload: { imeiSerialNumber: imei } });
  try {
    const response = await contractsAPI.getImeiData(imei);
    dispatch({ type: TYPES.getDataFromImei, payload: { imeiData: response.data, imei: imei } });
    if (response.data.status === 'IMEI_NOT_FOUND') {
      notification.error({
        message: t('popup.IMEI_not_recognized'),
      });
    }
  } catch (error) {
    console.log('error: ', error);
    dispatch({ type: TYPES.getDataFromImei, payload: { errorMessage: error } });
  }
};

export const getUniqueImei = (data: string, type: CODE_TYPE, t) => async (dispatch: any, getState: any) => {
  try {
    const { id, originalContractId } = getState().contractDetails.contract;
    const requestData = {
      [type]: data,
      originalContractId: !originalContractId ? null : originalContractId,
      contractId: id,
    };
    await contractsAPI.getUniqueImeiData(requestData);
  } catch (error) {
    console.log('error: ', error);
    error?.response?.data?.errors?.forEach((el: any) => {
      if (el.code === 'NOT_UNIQUE') {
        notification.error({
          message: t('popup.warning'),
        });
      }
    });
  }
};

export const getUniqueNumber = (data: string, t) => async (dispatch: any, getState: any) => {
  try {
    const { id, originalContractId } = getState().contractDetails.contract;
    const requestData = {
      uniqueNumber: data,
      originalContractId: !originalContractId ? null : originalContractId,
      contractId: id,
    };
    await contractsAPI.getUniqueNumber(requestData);
  } catch (error) {
    console.log('error: ', error);
    error?.response?.data?.errors?.forEach((el: any) => {
      if (el.code === 'NOT_UNIQUE') {
        notification.error({
          message: t('popup.registration_number_already_registered_system'),
        });
      }
    });
  }
};

export const getContractCalculation =
  ({
    insuranceProgramId,
    saleSum,
    duration,
    isProlog,
    originalContractId,
    objectsQuantity,
    contractObjects,
    insurancePolicyId,
    endNumber,
    objectConfiguration,
    prolongatedFromInsuranceRateId,
    installmentPaymentPeriodType,
    contractInfo,
    insuranceSumId,
  }: ICalc) =>
  async (dispatch: any, getState: any) => {
    const {
      insurancePrograms,
      contract,
      estateSubTypes,
      contract: { partnerId, insuranceProgram: programm, durationUnit },
    } = getState().contractDetails;
    const insuranceProgram = insurancePrograms.find((insProg: any) => insProg.id === insuranceProgramId) || programm;
    let newDuration = duration;

    if (typeof newDuration === 'string') {
      newDuration = newDuration.replace(/[^\d]/g, '');
    }

    const contractDate = contract.signDate
      ? moment(contract.signDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    const date = contract.originalContractId
      ? moment(contract.originalContractSignDate).format('YYYY-MM-DD')
      : contractDate;

    const units = insuranceProgram
      ? insuranceProgram.policies?.reduce((acc, item) => {
          const trigger = item.rates?.find((el) => el.duration.duration === duration);
          return trigger ? trigger.duration.durationUnit : acc;
        }, '')
      : '';

    try {
      const response = await contractsAPI.getDataCalculation(
        insuranceProgramId,
        saleSum,
        newDuration,
        partnerId!,
        objectsQuantity,
        isProlog,
        originalContractId,
        contractObjects,
        units || durationUnit,
        insurancePolicyId,
        date,
        prolongatedFromInsuranceRateId,
        installmentPaymentPeriodType,
        contractInfo,
        insuranceSumId,
      );
      const existedRisks = response.data.risks
        ? response.data.risks
        : _.mergeWith(contract.risks, response.data.objects[0].risks);

      const mainRisks = response.data.objects.reduce((acc, item) => {
        const triggerEstate = estateSubTypes.find((el) => el.id === item.subtypeId);
        return triggerEstate ? [...acc, { ...item, ...triggerEstate }] : [...acc, { ...item }];
      }, []);

      const risks = insuranceProgram.code?.includes('MM') ? mainRisks : existedRisks;

      await dispatch({
        type: TYPES.getContractCalculation,
        payload: {
          ...contract,
          ...response.data,
          objectConfiguration,
          contractNumberEnding: endNumber ?? contract.contractNumberEnding,
          risks,
          duration,
          calculatedPaymentAmount: response.data.calculatedPaymentAmount,
        },
      });
      return response;
    } catch (error) {
      console.log('error: ', error);
      dispatch({ type: TYPES.getContractCalculation, payload: { ...contract, duration, insuranceProgram } });
      return error;
    }
  };

export const setInsuranceProgram =
  (insuranceProgramId: number, duration: number | string, isCreatePage?: boolean) =>
  async (dispatch: any, getState: any) => {
    const { contract, insurancePrograms } = getState().contractDetails;

    const insuranceProgram = insurancePrograms.find((insProg: any) => insProg.id === insuranceProgramId);
    dispatch({
      type: TYPES.setInsuranceProgram,
      payload: {
        ...contract,
        oldCalculatedPaymentAmount:
          !contract.oldCalculatedPaymentAmount && !isCreatePage
            ? contract.calculatedPaymentAmount
            : contract.oldCalculatedPaymentAmount,
        oldCalculatedInsuranceRate:
          !contract.oldCalculatedInsuranceRate && !isCreatePage
            ? contract.calculatedInsuranceRate
            : contract.oldCalculatedInsuranceRate,
        duration,
        insuranceProgram,
      },
    });
  };

export const getInsuranceProgramRisks = (id: number) => async (dispatch: any) => {
  try {
    const response = await contractsAPI.getInsuranceProgramRisks(id);
    const risks = response.data.length
      ? response.data.map((item: any) => ({
          ...item,
          franchise: 0,
          insuranceAmount: 0,
          paymentAmount: 0,
          riskNumber: item.id,
        }))
      : [];

    dispatch({ type: TYPES.getInsuranceProgramRisks, payload: risks });
  } catch (error) {
    console.log('error: ', error);
    dispatch({ type: TYPES.getInsuranceProgramRisks, errorMessage: error });
  }
};

export const getInsuranceProgramData = (id: number) => async (dispatch) => {
  try {
    const { data } = await contractsAPI.getInsuranceProgramData(id);
    const risks = data.programRisks.map((item) => {
      return item.risk;
    });

    dispatch({ type: TYPES.getInsuranceProgramRisks, payload: risks });
    const dataObject = await dispatch({ type: TYPES.setObjectConfiguration, payload: data.objectConfiguration });
    return dataObject;
  } catch (error) {}
};

export const getInsuranceDetailsProgram =
  (id: string, singDate?: string, isProlongation?: boolean) => async (dispatch: any, getState: any) => {
    const { location } = history;
    const { state: currentFormName } = location;
    const formName = currentFormName || id;
    const { partnerId } = getState().contractDetails.contract;

    const tenantParsed = getState().me.data?.decoded?.tenantParsed;
    const {
      contract: { initialInsuranceProgram, prolongateFromId },
    } = getState().contractDetails;

    try {
      const partner = partnerId || tenantParsed.partnerId;
      if (partner) {
        const initialDurationId = !isProlongation ? initialInsuranceProgram?.duration?.id || prolongateFromId : null;
        const nowDate = moment().format('YYYY-MM-DD');
        const period = singDate ? moment(singDate).format('YYYY-MM-DD') : nowDate;

        const res = await apiConfiguration.insuranceProgramController.listDetails({
          distinct: true,
          visible: String(true),
          insuranceProductCode: id ? id : InsuranceProductCode.MZ,
          partnerId: partner ?? '',
          partnerProgramActivationDate: `<=${period}`,
          partnerProgramDeactivationDate: `>=${period}`,
          applicablePeriodFrom: `<=${period}`,
          applicablePeriodTo: `>${period}`,
          prolongateFromId: initialDurationId ? initialDurationId : 'null',
          insuranceCompanyId: tenantParsed?.insuranceCompanyId?.toString(),
          page_size: -1,
        });

        dispatch({ type: TYPES.setInsuranceDetailProgram, payload: res.data });
        dispatch({
          type: TYPES.getInsurancePrograms,
          payload: { insurancePrograms: res.data, currentFormName: formName },
        });
        return res.data;
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({ type: TYPES.getInsurancePrograms, payload: { errorMessage: error } });
    }
  };

export const setContractNumber = (contractNumber: string) => (dispatch: any) => {
  dispatch({ type: TYPES.setContractNumber, payload: contractNumber });
};

export const setSaleDate = (date: string) => (dispatch: any) => {
  dispatch({ type: TYPES.setSaleDate, payload: date });
};

export const setConclusionDate = (date: string | Moment) => (dispatch: any) => {
  dispatch({ type: TYPES.setConclusionDate, payload: date });
};

export const setStartDate = (date: string | Moment) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setStartDate, payload: date });
};

export const setSubAgreementStartDate = (date: string | Moment) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setSubAgreementStartDate, payload: date });
};

export const setContractEndDate = (date: string) => (dispatch: any) => {
  dispatch({ type: TYPES.setEndDate, payload: date });
};

const userHelper = (users) => {
  const clearUsers = users.filter((item) => {
    if (item && !item.middleName) item.middleName = null;
    if (item && !item.passport) item.passport = null;

    return Object.values(item).every((item) => item || item === null);
  });
  return clearUsers.map((item) => {
    const haveBirthdayDate = item.userBirthday || item.birthday;
    return {
      birthday: haveBirthdayDate && moment(haveBirthdayDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      firstName: item.userFirstName || item.firstName,
      lastName: item.userLastName || item.lastName,
      middleName: item.useMiddleName || item.middleName || null,
      client: item.client ? item.client : false,
      calculatedPaymentAmount: reverseMoneyToNumber(item.paymentAmountForObject),
      passport: item.passport,
    };
  });
};

const makeContractForRequest = ({
  contractData,
  contract,
  contractNumber,
  insurancePrograms,
  user,
  isAdding,
  expectedCalculatedPaymentAmount,
}): Partial<IContract> => {
  const { insuranceProgram, contractObjects, paymentAmount } = contract;
  const formName = sessionStorage.getItem('insuranceProductCode') || contract.insuranceProductCode;
  const contractTypeConfig = {
    insurancePrograms: insurancePrograms,
    programCode: contract.insuranceProgram?.code,
    programId: contract.insuranceProgram.id,
  };
  const { isInternational, isEmailSignAvailable } = getAffordableInsuranceProgram(contractTypeConfig);

  const isMedicine = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['FD_A', 'FD_B', 'MED_DMS_S', 'MED_DMS_VIP'],
  });

  const {
    users,
    phoneNumber,
    streetAddress,
    userType,
    contractBeneficiary,
    numberCheck,
    clearUserData,
    isClient,
    paymentAmountForObject,
  } = contractData;

  const getAllUsers = () => {
    const personData = {
      ...contractData.users?.person,
      birthday: contractData.birthday,
      client: true,
      paymentAmountForObject,
    };

    return isClient
      ? {
          person: {
            ...personData,
          },
          ...clearUserData,
        }
      : {
          ...clearUserData,
        };
  };

  if (contractData.users && contractData.users.person) {
    contractData.users.person.client = true;
  }
  const usersContractObjects = userHelper(
    Object.values(contractData.users || userType === 'COMPANY' ? getAllUsers() : {}),
  );

  const currency = currencyAdapter(user.insuranceCompanyCurrency || contract.insuranceSumCurrency);

  const electronicsObjects = [
    {
      brand: contractData.brand,
      model: contractData.model,
      pid: contractData.productId,
      checkNumber: contractData.numberCheck.checkNumber,
      saleDate: moment(contractData.saleDate).format('YYYY-MM-DDTHH:mm:ss'),
      saleSum: contractData.sum?.split(currency)[0],
      subtypeId: contractData.productName,
      type: 'ELECTRONIC_DEVICE',
      subtypeName: contractData.productType,
    },
  ];

  const kaskoObject = [
    {
      brand: contractData.carBrand,
      model: contractData.carModel,
      pid: contractData.plateNumber,
      subtypeId: contractObjects[0].subtypeId,
      vinNumber: contractData.carcassNumber,
      manufactureDate: moment(contractData.manufactureDate).format('YYYY-MM-DD'),
      type: 'VEHICLE',
    },
  ];
  const covidObjects = usersContractObjects.map((item: IUserContract) => {
    item.type = 'PERSON';
    return item;
  });

  const setObject = () => {
    switch (formName) {
      case InsuranceProductCode.CV:
      case InsuranceProductCode.MED:
      case InsuranceProductCode.VZR:
        return covidObjects;
      case InsuranceProductCode.MZ:
      case InsuranceProductCode.IN:
        return electronicsObjects;
      case InsuranceProductCode.MK:
      case InsuranceProductCode.CRG:
        return kaskoObject;
      case InsuranceProductCode.MM:
        return contractData.estateData;
    }
  };

  const endNumber = contract.contractNumberEnding;

  const units = insuranceProgram
    ? insuranceProgram.policies?.reduce((acc, item) => {
        const trigger = item.rates?.find((el) => el.duration?.duration === parseFloat(contractData.validityPeriod));
        return trigger ? trigger.duration.durationUnit : acc;
      }, '')
    : '';
  const mainPart = contractNumber.slice(0, contractNumber.length - 3);

  let contractFullNumber = `${mainPart}${endNumber}`;

  if (isInternational) {
    contractFullNumber = contractNumber;
  }

  const getPeriodType = () => {
    return contractData.installment ? contractData.installment : contract.selectedInstallment?.periodType;
  };

  const startDate =
    typeof contract.startDate === 'string' ? contract.startDate : contract.startDate?.format('YYYY-MM-DDTHH:mm:ss');

  const payment = contractData.paymentAmount || paymentAmount;
  const isDMS = getDMSProgram(insurancePrograms, contract);
  const companyName = contract.contractBeneficiary.companyName;
  return {
    insuranceSumId: contractData.insuranceSumId || contract.insuranceSumId,
    contractInfo: contractData.contractInfo,
    startDate,
    subAgreementStartDate: contract.subAgreementStartDate,
    contractNumber: isDMS ? contractData.contractNumberMainPart : contractFullNumber,
    prolongation: contractData.prolongation,
    programChanging: contractData.programChanging,
    duration: parseFloat(contractData.validityPeriod),
    durationUnit: contract.durationUnit || units || 'MONTHS',
    clientId: contract.contractBeneficiary.id ? contract.contractBeneficiary.id : contractData.clientId,
    contractNumberEnding: endNumber,
    contractNumberMainPart: contractData.contractNumberMainPart,
    contractNumberPrefix: numberCheck.prefix,
    calculatedPaymentAmount: contract.calculatedPaymentAmount
      ? isAdding && contract.status === 'DRAFT' && contract.programChanging
        ? (Number(contract.calculatedPaymentAmount) + contract.originalContractPaymentAmount).toFixed(2)
        : isAdding && contract.status === 'DRAFT' && contract.prolongation
        ? expectedCalculatedPaymentAmount.toFixed(2)
        : contract.calculatedPaymentAmount.toFixed(2)
      : 0,
    paymentAmount: payment ? Number(String(payment).split(' ').join('')) : 0,
    calculatedInsuranceRate: isMedicine
      ? contract.calculatedInsuranceRate
      : isAdding && contract.status === 'DRAFT' && contract.programChanging
      ? contract.calculatedInsuranceRate + contract.originalContractInsuranceRate
      : isAdding && contract.status === 'DRAFT' && contract.prolongation
      ? expectedCalculatedPaymentAmount / contract.insuranceSum
      : contract.calculatedInsuranceRate ?? 0,
    sellerPhoneNumber: contractData.sellerPhoneNumber,
    sellerName: contractData.sellerName,
    originalContractId: contractData.originalContractId,
    conclusionDate: moment(contractData.conclusionDate).format('YYYY-MM-DDTHH:mm:ss'),
    insurancePolicy: contractData.insurancePolicy,
    prolongateFromId: contractData.prolongateFromId,
    signatory: {
      companyName: contractData.fullName || (users && users.person && users.person.fullName) || null,
      firstName: contractData.firstName || (users && users.person && users.person.firstName) || null,
      lastName: contractData.lastName || (users && users.person && users.person.lastName) || null,
      middleName: contractData.middleName || (users && users.person && users.person.middleName) || null,
      birthday: contractData.birthday ? moment(contractData.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD') : null,
      locality: contractData.regionAddress,
      phoneNumber: phoneNumber,
      street: streetAddress,
      type: userType,
      citizenship: contractData.citizenship,
      passport: contractData.passport,
      pinfl: contractData.pinfl,
      email: isEmailSignAvailable ? contractData.email : undefined,
    },
    documents: contractData.documents,
    contractBeneficiary:
      formName !== InsuranceProductCode.CV && formName !== InsuranceProductCode.MED
        ? {
            ...contractBeneficiary,
            companyName: companyName ? companyName : null,
            firstName: contractBeneficiary.firstName ? contractBeneficiary.firstName : null,
            lastName: contractBeneficiary.lastName ? contractBeneficiary.lastName : null,
            middleName: contractBeneficiary.middleName ? contractBeneficiary.middleName : null,
            type: contractBeneficiary.type || contractData.userType,
            street: isInternational ? streetAddress : contractBeneficiary.street,
            dateBirthday: contractBeneficiary.dateBirthday
              ? dayjs(contractBeneficiary.dateBirthday, 'YYYY.MM.DD').isValid()
                ? contractBeneficiary.dateBirthday
                : dayjs(contractBeneficiary.dateBirthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
              : null,
            locality: isInternational ? contractData.regionAddress : contractBeneficiary.locality,
            email: contractData.contractEmail,
            citizenship: contractData.contractRegionAddress,
            innCode: contractData.idNumber,
            passportCreator: contractData.whomAndWhenIssued,
          }
        : {
            companyName: contractData.fullName || null,
            firstName: contractData.firstName || (users && users.person && users.person.firstName) || null,
            lastName: contractData.lastName || (users && users.person && users.person.lastName) || null,
            locality: contractData.regionAddress,
            middleName: contractData.middleName || (users && users.person && users.person.middleName) || null,
            phoneNumber: contractData.phoneNumber,
            street: contractData.streetAddress,
            type: contractData.userType,
            citizenship: contractData.citizenship,
          },
    contractObjects: setObject(),
    insuranceProgram: {
      clientSignContractEnabled: insuranceProgram.clientSignContractEnabled,
      code: insuranceProgram.code,
      id: insuranceProgram.id,
      installmentPaymentSubscription: insuranceProgram.installmentPaymentSubscription,
      name: insuranceProgram.name,
      installmentPayments: insuranceProgram.installmentPayments,
    },
    insuranceSumCurrency: user.insuranceCompanyCurrency || contract.insuranceSumCurrency,
    paymentAmountCurrency: user.insuranceCompanyCurrency || contract.insuranceSumCurrency,
    insuranceSum: contract.insuranceSum,
    installmentPayment:
      getPeriodType() === 'ONE_PAYMENT'
        ? false
        : getPeriodType() && contract.insuranceProgram.installmentPaymentSubscription,
    contractInstallmentPayment: {
      installmentPaymentPeriodType: getPeriodType() === 'Один платіж' ? 'ONE_PAYMENT' : getPeriodType(),
    },
    prolongationPaymentAmount: contractData.prolongationValue
      ? reverseMoneyToNumber(contractData.prolongationValue)
      : contractData.calculatedPaymentAmount
      ? reverseMoneyToNumber(contractData.calculatedPaymentAmount)
      : 0,
  };
};

export const setUploadReceiptDate = (value: string) => ({ type: TYPES.setUploadReceiptDate, payload: value });

export const addContract =
  (
    contractData: any,
    form: any,
    options?: any,
    t?: any,
    sign?: { isSign: boolean; sendingType?: SendingType },
    newUploadDevicePhoto?: IUploadState[],
  ) =>
  async (dispatch: any, getState: any) => {
    const { contractNumber, contract, insurancePrograms, insuranceProgramDuration } = getState().contractDetails;
    const tenant = getState().me.data?.decoded?.tenant;
    const { location } = history;
    const { state: currentFormName } = location;
    const { partnerId, id } = getState().contractDetails.contract;
    const user = getState().me.data?.current;

    const config = {
      contractData,
      contract,
      contractNumber,
      insurancePrograms,
      user,
      isAdding: true,
      expectedCalculatedPaymentAmount: 0,
    };
    const newContract = makeContractForRequest(config);

    try {
      const response = await contractsAPI.addContractDetails({
        ...newContract,
        partnerId: id ? partnerId : tenant.split('/')[1],
      });
      if (newUploadDevicePhoto && newUploadDevicePhoto.length !== 0) {
        const responseData = await createUploadDevicePhotoArr(newUploadDevicePhoto, response.data.id);
        await Promise.all(responseData);
      }
      notification.success({
        message: t('popup.information_under_contract_successfully_saved'),
      });
      dispatch({ type: TYPES.setContract, payload: { contract: response.data, pending: false } });
      history.push({
        pathname: `${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${response.data.id}`,
        state: { currentFormName, isSigned: !!sign?.isSign },
      });

      if (options.sign?.isSign) {
        dispatch(
          sendSignVerification(response.data.id, form, options.action, t, options.isSmsDisalbed, sign?.sendingType),
        );
      }
      if (options.supplementaryAgreement) {
        options.subAgreementAction(false);
      }
    } catch (error) {
      console.log('error: ', error);
      const existErrors = {};
      let hasErrorCode = false;
      dispatch({ type: TYPES.setContract, payload: { contract, pending: false, errorMessage: error } });
      error?.response?.data?.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (el.code === 'CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT') {
            notification.error({
              message: t('popup.CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT'),
            });
            return;
          }

          if (el.code === 'ORIGINAL_CONTRACT_HAS_DRAFT_SUB_AGREEMENT') {
            notification.error({
              message: t('popup.additional_agreement'),
            });
            return;
          }

          if (el.code === 'SALE_SUM_LESS_THAN_300') {
            notification.error({
              message: t('popup.minimum_cost'),
            });
            return;
          }

          if (el.code === 'PAYMENT_SUM_SHOULD_BE_GREATER_THAN_ORIGINAL_PAYMENT_SUM') {
            notification.error({
              message: t('popup.costinsurance_cannot_less'),
            });
            return;
          }

          if (el.code === 'INSURED_PERSON_AGE_IS_NOT_SUITABLE') {
            notification.error({
              message: t('popup.individuals_cannot_insured'),
            });
          }

          if (el.code === 'PHONE_NUMBER_IS_NOT_ALLOWED') {
            hasErrorCode = true;
            notification.error({
              message: t('popup.corporate_phone_number'),
              description: t('popup.insurers_phone_number'),
            });
          }

          if (adapter(t)[el.field]) {
            const name = `${adapter(t)[el.field]['name']}`;
            const errorw = adapter(t)[el.field][el.code];
            const value = form.getFieldValue(name);
            if (el.code === 'DEPARTMENT_NOT_ACTIVE') {
              notification.error({
                message: t('popup.unit_not_active'),
              });
            }
            if (el.code === 'CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT') {
              notification.error({
                message: t('popup.CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT'),
              });
            }

            form.setFields({
              [name]: {
                value: value,
                errors: [new Error(errorw)],
              },
            });
          }

          if (el.code === 'FIELD_CAN_NOT_BE_EMPTY' && !existErrors[el.code]) {
            notification.error({
              message: t('popup.agreement_not_saved'),
              description: t('popup.fill_all_required_fields'),
            });
            existErrors[el.code] = el.code;
            return;
          }
        });

      if (newContract.contractObjects && !newContract.contractObjects.length) {
        notification.error({
          message: t('popup.error'),
          description: t('popup.necessary_add_insured_person'),
        });
        return;
      }

      //@ts-ignore
      if (!window.navigator.connection.downlink) {
        notification.error({
          message: t('popup.no_connection'),
          description: t('popup.check_your_network_connection'),
        });
      } else {
        !hasErrorCode &&
          notification.error({
            message: t('popup.something_went_wrong'),
            description: t('popup.contract_has_not_been_saved'),
          });
      }
    }
    return;
  };

export const addContract2 =
  (
    contractData: any,
    form: any,
    options: any,
    t,
    sign: { isSign: boolean; sendingType?: SendingType },
    newUploadDevicePhoto: IUploadState[],
  ) =>
  async (dispatch: any, getState: any) => {
    const { contractNumber, contract, insurancePrograms } = getState().contractDetails;
    const tenant = getState().me.data?.decoded?.tenant;

    const { location } = history;
    const { state: currentFormName } = location;
    const { partnerId, id } = getState().contractDetails.contract;
    const user = getState().me.data?.current;

    const config = {
      contractData,
      contract,
      contractNumber,
      insurancePrograms,
      user,
      isAdding: true,
      expectedCalculatedPaymentAmount: 0,
    };
    const newContract = makeContractForRequest(config);

    try {
      const response = await contractsAPI.addContractDetails({
        ...newContract,
        partnerId: id ? partnerId : tenant.split('/')[1],
      });

      if (newUploadDevicePhoto && newUploadDevicePhoto.length !== 0) {
        const responseData = await createUploadDevicePhotoArr(newUploadDevicePhoto, response.data.id);
        const data = await Promise.all(responseData);

        //@ts-ignore
        if (data[0].status === 201) {
          dispatch({ type: TYPES.newDocumentUpload, payload: { uploadData: data } });
          notification.success({
            message: t('popup.information_under_contract_successfully_saved'),
          });
          dispatch({ type: TYPES.setContract, payload: { contract: response.data, pending: false } });
          history.push({
            pathname: `${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${response.data.id}`,
            state: { currentFormName, isSigned: !!sign?.isSign },
          });
        }
      }
      if (options.sign?.isSign) {
        dispatch(
          sendSignVerification(response.data.id, form, options.action, t, options.isSmsDisalbed, sign?.sendingType),
        );
      }
      if (options.supplementaryAgreement) {
        options.subAgreementAction(false);
      }
    } catch (error) {
      console.log('error: ', error);
      const existErrors = {};
      let hasErrorCode = false;
      dispatch({ type: TYPES.setContract, payload: { contract, pending: false, errorMessage: error } });
      error?.response?.data?.errors &&
        error.response.data.errors.forEach((el: any) => {
          if (el.code === 'CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT') {
            notification.error({
              message: t('popup.CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT'),
            });
            return;
          }

          if (el.code === 'ORIGINAL_CONTRACT_HAS_DRAFT_SUB_AGREEMENT') {
            notification.error({
              message: t('popup.additional_agreement'),
            });
            return;
          }

          if (el.code === 'SALE_SUM_LESS_THAN_300') {
            notification.error({
              message: t('popup.minimum_cost'),
            });
            return;
          }

          if (el.code === 'PAYMENT_SUM_SHOULD_BE_GREATER_THAN_ORIGINAL_PAYMENT_SUM') {
            notification.error({
              message: t('popup.costinsurance_cannot_less'),
            });
            return;
          }

          if (el.code === 'INSURED_PERSON_AGE_IS_NOT_SUITABLE') {
            notification.error({
              message: t('popup.individuals_cannot_insured'),
            });
          }

          if (el.code === 'PHONE_NUMBER_IS_NOT_ALLOWED') {
            hasErrorCode = true;
            notification.error({
              message: t('popup.corporate_phone_number'),
              description: t('popup.insurers_phone_number'),
            });
          }

          if (adapter(t)[el.field]) {
            const name = `${adapter(t)[el.field]['name']}`;
            const errorw = adapter(t)[el.field][el.code];
            const value = form.getFieldValue(name);
            if (el.code === 'DEPARTMENT_NOT_ACTIVE') {
              notification.error({
                message: t('popup.unit_not_active'),
              });
            }
            if (el.code === 'CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT') {
              notification.error({
                message: t('popup.CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT'),
              });
            }

            form.setFields({
              [name]: {
                value: value,
                errors: [new Error(errorw)],
              },
            });
          }

          if (el.code === 'FIELD_CAN_NOT_BE_EMPTY' && !existErrors[el.code]) {
            notification.error({
              message: t('popup.agreement_not_saved'),
              description: t('popup.fill_all_required_fields'),
            });
            existErrors[el.code] = el.code;
            return;
          }
        });

      if (newContract.contractObjects && !newContract.contractObjects.length) {
        notification.error({
          message: t('popup.error'),
          description: t('popup.necessary_add_insured_person'),
        });
        return;
      }

      //@ts-ignore
      if (!window.navigator.connection.downlink) {
        notification.error({
          message: t('popup.no_connection'),
          description: t('popup.check_your_network_connection'),
        });
      } else {
        !hasErrorCode &&
          notification.error({
            message: t('popup.something_went_wrong'),
            description: t('popup.contract_has_not_been_saved'),
          });
      }
    }
    return;
  };

export const updateContractDetails =
  (
    contractData: any,
    form: any,
    options?: any,
    t?,
    sign?: { isSign: boolean; isSignByEmail?: boolean },
    newUploadDevicePhoto?: IUploadState[],
  ) =>
  async (dispatch: any, getState: any) => {
    const { contract, contractNumber, insurancePrograms, paymentsList } = getState().contractDetails;
    const tenant = getState().me.data?.decoded?.tenant;
    const { partnerId, id } = getState().contractDetails.contract;
    const user = getState().me.data?.current;
    const config = {
      contractData,
      contract,
      contractNumber,
      insurancePrograms,
      user,
      isAdding: !!sign?.isSign,
      expectedCalculatedPaymentAmount: paymentsList.statistic.expectedPaymentAmount,
    };
    const newContract = makeContractForRequest(config);
    if (newContract.contractObjects.length === 0) {
      notification.error({
        message: t('popup.error'),
        description: t('popup.necessary_add_insured_person'),
      });
    }

    try {
      const response = await contractsAPI.updateContractDetails(
        {
          ...newContract,
          partnerId: id ? partnerId : tenant.split('/')[1],
        },
        contract.id,
      );
      if (newUploadDevicePhoto && newUploadDevicePhoto.length !== 0) {
        await Promise.all(createUploadDevicePhotoArr(newUploadDevicePhoto, response.data.id));
      }

      notification.success({
        message: t('popup.information_under_contract_successfully_saved'),
      });

      if (options.sign?.isSign) {
        dispatch(
          sendSignVerification(
            response.data.id,
            form,
            options.action,
            t,
            options.isSmsDisalbed,
            options.sign?.sendingType,
          ),
        );
      }
      if (response.status === 200) {
        if (!options.sign?.isSign) {
          dispatch({ type: TYPES.testAction, payload: { uploadLoader: false } });
          dispatch({ type: TYPES.setClientOnSignError, payload: { isPhotoRequired: false } });
          notification.success({
            message: t('popup.information_under_contract_successfully_saved'),
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    } catch (error) {
      console.log('error: ', error);
      dispatch({ type: TYPES.setContract, payload: { contract, pending: false, errorMessage: error } });
      //@ts-ignore
      if (!window.navigator.connection.downlink) {
        notification.error({
          message: t('popup.no_connection'),
          description: t('popup.check_your_network_connection'),
        });
      } else if (
        error?.response?.data?.errors &&
        error.response.data.errors[0]?.code === 'CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT'
      ) {
        notification.error({
          message: t('popup.agreement_not_saved'),
          description: t('popup.CALCULATED_PAYMENT_AMOUNT_CANT_BE_GREATER_THAN_PAYMENT_AMOUNT'),
        });
      } else if (error?.response?.data?.errors && error.response.data.errors[0]?.code === 'SALE_SUM_LESS_THAN_300') {
        notification.error({
          message: t('popup.agreement_not_saved'),
          description: t('popup.minimum_cost_300'),
        });
      } else if (
        error?.response?.data?.errors &&
        error.response.data.errors[0]?.code === 'PHONE_NUMBER_IS_NOT_ALLOWED'
      ) {
        notification.error({
          message: t('popup.corporate_phone_number'),
          description: t('popup.insurers_phone_number'),
        });
      } else {
        notification.error({
          message: t('popup.something_went_wrong'),
          description: t('popup.contract_has_not_been_saved'),
        });
      }
      if (error?.response?.status === 400) {
        error?.response?.data?.errors &&
          error.response.data.errors.forEach((el: { field?: string; code?: string }) => {
            if (el.field && el.code && adapter(t)[el.field]) {
              const name = `${adapter(t)[el.field]['name']}`;
              const errorw = adapter(t)[el.field][el.code];
              const value = form.getFieldValue(name);

              form.setFields({
                [name]: {
                  value: value,
                  errors: [new Error(errorw)],
                },
              });
            }
          });
      }
    }
    return;
  };

export const updateContractDetailsAction =
  (
    claimId: number,
    code: string,
    mainFormData: any,
    isReconciliation: boolean,
    isProlongation: boolean,
    isSupplementAgreement: any,
  ) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: TYPES.testAction, payload: { uploadLoader: true } });
      const responseData = await contractsAPI.documentUpload(Number(claimId), code, mainFormData);
      if (responseData.status === 201) {
        dispatch({ type: TYPES.testAction, payload: { uploadLoader: false } });
        notification.success({
          message: `Файл добавлен`,
        });
        if (!isReconciliation && !isProlongation && !isSupplementAgreement) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    } catch (error) {
      console.log('error: ', error);
    }
    return;
  };

export const addReconciliation =
  (
    contractData: IContractForm & Partial<IContract>,
    form: WrappedFormUtils,
    newUploadDevicePhoto: IUploadState[],
    setReconciliationMode?: (value: boolean) => void,
    t?,
  ) =>
  async (dispatch, getState) => {
    const { contract, contractNumber, insurancePrograms } = getState().contractDetails;
    const { location } = history;
    const { state: currentFormName } = location;
    const user = getState().me.data?.current;
    const config = {
      contractData,
      contract,
      contractNumber,
      insurancePrograms,
      user,
      isAdding: false,
      expectedCalculatedPaymentAmount: 0,
    };
    const newContract = makeContractForRequest(config);
    const formName = currentFormName || contract.insuranceProductCode;
    try {
      const updatedContract = {
        ...newContract,
        documents: contract.documents?.map((item: ContractDocumentDto) => ({
          ...item,
          attachment: newUploadDevicePhoto?.length > 0 ? true : false,
        })),
      };
      const newContractData = await contractsAPI.sendReconciliation(
        { ...updatedContract, partnerId: contract.partnerId },
        contract.id,
      );
      if (newContractData.data.originalContractId) {
        await dispatch(setContract(newContractData.data.id));

        const originalContract = await contractsAPI.apiContracts.contractController.getDetails(contract.id);

        if (newUploadDevicePhoto && originalContract.data.documents) {
          if (originalContract.data.documents[0].receiptDate !== contract.documents[0].receiptDate) {
            await Promise.all(createUploadDevicePhotoArr(newUploadDevicePhoto, newContractData.data.id));
          }
        }
        await history.push({
          pathname: `${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${newContractData.data.id}`,
          state: formName,
        });
      } else {
        await dispatch(setContract(contract.id));
      }
      setReconciliationMode && setReconciliationMode(false);
      await dispatch(getInsuranceObjectSubTypes(contract.insuranceProgram.id));

      notification.success({
        message: t('popup.contract_verified_successfully'),
      });
    } catch (error) {
      console.log('error: ', error);
      error?.response?.data?.errors?.forEach((el: { field?: string; code?: string }) => {
        if (el.code === 'ORIGINAL_CONTRACT_HAS_DRAFT_SUB_AGREEMENT') {
          notification.error({
            message: t('popup.additional_agreement_created'),
            description: t('popup.please_go_to_the_section'),
          });
          return;
        }

        if (el.field && el.code && adapter(t)[el.field]) {
          const name = `${adapter(t)[el.field]['name']}`;
          const translatedError = adapter(t)[el.field][el.code];
          const value = form.getFieldValue(name);

          form.setFields({
            [name]: {
              value: value,
              errors: [new Error(translatedError)],
            },
          });
        }
        notification.error({
          message: t('popup.something_went_wrong'),
          description:
            el.code === 'SALE_DATE_CANT_BE_BEFORE_LAST_ACT_DATE'
              ? t('popup.selected_date_sale')
              : t('popup.contract_not_verified'),
        });
      });

      //@ts-ignore
      if (!window.navigator.connection.downlink) {
        notification.error({
          message: t('popup.no_connection'),
          description: t('popup.check_your_network_connection'),
        });
      }
    }
  };

export const signContractClaim =
  (
    token?: string,
    cb?: (id: number | undefined) => void,
    t?,
    id?: number,
    russianCitizen?: boolean,
    localeLanguage?: string,
  ) =>
  async (dispatch: any, getState: any) => {
    const { contract } = getState().contractDetails;
    try {
      await contractsAPI.signContractClaim(contract.id || id, token, russianCitizen, localeLanguage);
      notification.success({
        message: t('insured_events.signed_successfully'),
      });
      cb && cb(id);
      await dispatch(setContract(contract.id));
      await dispatch(getInsuranceObjectSubTypes(contract.insuranceProgram.id));
    } catch (error) {
      if (error?.response?.status === 404) {
        error?.response?.data?.errors &&
          error.response.data.errors.forEach((err) => {
            if (err.code === 'CONTRACT_DOWNLOAD_FAILED') {
              notification.error({ message: t('details_card.no_template') });
            } else {
              notification.error({ message: t('popup.error') });
            }
          });
      } else {
        notification.error({ message: t('popup.your_code_invalid') });
        dispatch({ type: TYPES.setTablePage, payload: { wrongCode: error } });
      }
    }
    return;
  };

export const sendSignVerification =
  (id: any, form: any, cb: any, t, isDisabled?: boolean, sendingType?: SendingType) => async (dispatch) => {
    try {
      if (!isDisabled) {
        await apiContracts.contractController.sendSignVerification({ id, sendingType });
      }
      cb(id)();
      await dispatch(setContract(id));
    } catch (error) {
      if (error.response.status === 400) {
        error?.response?.data?.errors &&
          error.response.data.errors.forEach((el: any) => {
            if (el.code === 'INVALID_PHONE_NUMBER') {
              form.setFields({
                phoneNumber: {
                  value: form.getFieldValue('phoneNumber'),
                  errors: [new Error(t('popup.phone_number_invalid'))],
                },
              });
            }
            if (el.code === 'DEVICE_PHOTO_NOT_UPLOADED') {
              dispatch({ type: TYPES.setClientOnSignError, payload: { isPhotoRequired: true } });
            }
          });
      }

      if (error.response.data.errors && error.response.data.errors[0].code === 'OBJECT_SALE_DATE_BEFORE_TODAY') {
        notification.warn({
          message: t('popup.contract_cannot_signed'),
          description: t('popup.date_sale_does_not_meet_conditions'),
        });
      } else {
        //@ts-ignore
        if (!window.navigator.connection.downlink) {
          notification.error({
            message: t('popup.no_connection'),
            description: t('popup.check_your_network_connection'),
          });
        } else {
          notification.error({
            message: t('popup.something_went_wrong'),
          });
        }
      }
    }
    return;
  };

export const printContract = (lang?: string, t?: any) => async (dispatch: any, getState: any) => {
  const { contract } = getState().contractDetails;
  dispatch({ type: TYPES.printContract, payload: { pending: true } });

  try {
    const response = await onDownload(() => contractsAPI.printContract(contract.id, lang));
    dispatch({ type: TYPES.printContract, payload: { pending: false, printContract: response.data } });
  } catch (e) {
    console.log('error: ', e);
    dispatch({ type: TYPES.printContract, payload: { errorMessage: e, pending: false } });
  }
};

export const contractPdfDownload = async (id: number, t: any) => onDownload(() => contractsAPI.downloadContractPdf(id));

export const setProgramPeriods =
  (id: number, policyId: number, t: TFunction, isChangeProg?: boolean) => async (dispatch, getState) => {
    try {
      const { detailProgram } = getState().contractDetails;
      const program = detailProgram.find((item) => item.id === id);
      const forbiddenDurations = [3, 6];

      const getDuration = () => {
        if (program && program.policies) {
          const trigger = program.policies.find((item) => item.id === policyId);
          if (trigger) {
            return trigger.rates;
          } else {
            return program.policies[0].rates;
          }
        } else {
          return [];
        }
      };

      const duration = getDuration();
      let formattedDurations = duration;

      if (isChangeProg) {
        formattedDurations = getDuration().filter((rate) => !forbiddenDurations.includes(rate.duration.duration));
      }

      const formattedDuration = getFormattedDuration(formattedDurations, t);
      dispatch({ type: TYPES.setInsuranceProgramDuration, payload: { insuranceProgramDuration: formattedDuration } });
      return formattedDuration;
    } catch (error) {
      console.error('error in actions - getProgramPeriods, error', error);
    }
  };

export const removeContractSignature = (id: number, cb: any, t) => async () => {
  try {
    await contractsAPI.withdrawSign(id);
    await cb();
    notification.success({
      message: t('popup.Signature_removed'),
    });
  } catch (error) {
    console.log('error: ', error);
    if (error?.response?.data?.errors) {
      return error.response.data.errors.forEach((el: any) => {
        if (el.code === 'SIGN_WITHDRAWAL_IMPOSSIBLE') {
          return notification.error({
            message: t('popup.SIGN_WITHDRAWAL_IMPOSSIBLE'),
          });
        }
        notification.error({
          message: t('popup.failed_remove_signature'),
        });
      });
    } else {
      notification.error({
        message: t('popup.failed_remove_signature'),
      });
    }
  }
};

export const removeContractReconciliationWarnings = (id: number | undefined) => async (dispatch) => {
  const { data } = await contractsAPI.withDrawCheckWarnings(id);
  dispatch({
    type: TYPES.removeReconciliationWarnings,
    payload: data?.warnings ?? [],
  });
  return data;
};

export const removeContractReconciliation = (id: number | undefined, cb: any, t) => async () => {
  try {
    await contractsAPI.withdrawCheck(id);
    await cb();
    notification.success({
      message: t('popup.animal_removed'),
    });
  } catch (error) {
    notification.error({
      message: t('popup.failed_reconcile'),
      description: t('popup.contract_included_act'),
    });
  }
};

export const setInsuranceContractSum = (payload) => ({
  type: TYPES.setInsuranceContractSum,
  payload,
});

export const setCalculatedPaymentAmount = (payload) => ({
  type: TYPES.setCalculatedPaymentAmount,
  payload,
});

export const setPaymentAmountForObject = (payload) => ({
  type: TYPES.setPaymentAmountForObject,
  payload,
});

export const setFixedPaymentAmount = (payload) => ({
  type: TYPES.setFixedPaymentAmount,
  payload,
});

export const getContractNumberEnding = (params) => async (dispatch, getState) => {
  const {
    contractDetails: { contractNumber, contract, insurancePrograms },
    user: userInfo,
  } = getState();
  try {
    const response = await contractsAPI.getContractNumberEnding(params);
    dispatch({
      type: TYPES.setContractNumberEnding,
      payload: response?.data?.contractNumberEnding,
    });
    if (contractNumber) {
      let newContractNumber = contractNumber;
      const [first, second] = contractNumber.split('-');
      if (second) {
        newContractNumber = `${first.slice(0, -3)}${response?.data?.contractNumberEnding} - ${second}`;
      } else {
        newContractNumber = `${contractNumber.slice(0, -3)}${response?.data?.contractNumberEnding}`;
      }

      const prefixCheck = contract?.contractNumberPrefix || (userInfo && userInfo.checkNumberPrefix);
      const getYear = moment().year().toString().slice(-2);

      let newNumber = newContractNumber;
      const contractTypeConfig = {
        insurancePrograms: insurancePrograms,
        programCode: contract.insuranceProgram?.code,
        programId: contract.insuranceProgram.id,
      };

      const { isInternational } = getAffordableInsuranceProgram(contractTypeConfig);

      if (isInternational) {
        newNumber = getContractNumberForAPEXSK(prefixCheck, contract.identifier || '090', getYear);
      }

      dispatch({ type: TYPES.setContractNumber, payload: newNumber });
    }
  } catch (e) {
    dispatch({
      type: TYPES.setContractNumberEnding,
      payload: '312',
    });
  }
};

export const getContractNumberEnd = (params) => async (dispatch, getState) => {
  const {
    contractDetails: {
      contractNumber,
      detailProgram,
      insurancePrograms,
      contract: { contractNumberPrefix, identifier },
      contract,
    },
    user: userInfo,
  } = getState();
  const { partnerName } = getState().me.data?.current;

  const isSota =
    partnerName?.includes('СОТА') ||
    contractNumberPrefix.includes('SA') ||
    contractNumberPrefix.includes('SO') ||
    false;

  try {
    const dataProgram = detailProgram.find((elem) => elem.id === params.insuranceProgramId) || [];
    const response =
      dataProgram?.policies.reduce((acc, item) => {
        if (item.id === params.chosenPolicy) {
          const trigger = item.rates.find((elem) => elem.duration.duration === params.duration);
          return trigger ?? acc;
        }
        return acc;
      }, {}) || {};

    if (
      (params.duration !== contract.duration || params.insuranceProgramId !== contract.insuranceProgram.id) &&
      response?.contractNumberEnding
    ) {
      dispatch({
        type: TYPES.setContractNumberEnding,
        payload: response?.contractNumberEnding,
      });
    }

    if (
      (params.duration !== contract.duration || params.insuranceProgramId !== contract.insuranceProgram.id) &&
      contractNumber &&
      response?.contractNumberEnding
    ) {
      let newContractNumber = contractNumber;
      const [first, second] = contractNumber.split('-');
      if (second && !isSota) {
        newContractNumber = `${first.slice(0, -3)}${response?.contractNumberEnding} - ${second}`;
      } else {
        newContractNumber = `${contractNumber.slice(0, -3)}${response?.contractNumberEnding}`;
      }
      const prefixCheck = contractNumberPrefix || (userInfo && userInfo.checkNumberPrefix);
      const getYear = moment().year().toString().slice(-2);

      let newNumber = newContractNumber;
      const contractTypeConfig = {
        insurancePrograms: insurancePrograms,
        programCode: contract.insuranceProgram?.code,
        programId: contract.insuranceProgram.id,
      };

      const { isInternational } = getAffordableInsuranceProgram(contractTypeConfig);

      if (isInternational) {
        newNumber = getContractNumberForAPEXSK(prefixCheck, identifier || '090', getYear);
      }

      dispatch({ type: TYPES.setContractNumber, payload: newNumber });
    } else {
      dispatch({ type: TYPES.setContractNumber, payload: contractNumber });
    }
  } catch (e) {
    // dispatch({
    //   type: TYPES.setContractNumberEnding,
    //   payload: '312',
    // });
  }
};

export const setFixedDuration = (payload) => {
  return {
    type: TYPES.setFixedDuration,
    payload,
  };
};

export const setContractSignatory = (payload) => ({
  type: TYPES.setContractSignatory,
  payload,
});

export const setContractBeneficiary = (payload) => ({
  type: TYPES.setContractBeneficiary,
  payload,
});

export const setContractInfo = (payload) => ({
  type: TYPES.setContractInfo,
  payload,
});

export const setContractDeviceData = (payload) => ({
  type: TYPES.setContractDeviceData,
  payload,
});

export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setPaymentsList = (contractId, t) => async (dispatch: any) => {
  try {
    const res = await contractsAPI.getPaymentDetailsTable(contractId);
    dispatch({ type: TYPES.setPaymentsList, payload: res.data });
  } catch (err) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.loading_payment_information'),
    });
  }
};

export const getAutogenerateNumber = (insuranceProgramcode) => async (dispatch: any) => {
  try {
    const responce =
      insuranceProgramcode &&
      (await contractsAPI.apiContracts.contractController.generateContractNumber({
        insuranceProgramCode: insuranceProgramcode,
      }));
    dispatch({ type: TYPES.setAutogenerateNumber, payload: responce.data });
  } catch (err) {
    err === undefined && dispatch({ type: TYPES.setAutogenerateNumber, payload: '' });
    notification.error({
      message: t('popup.error'),
      description: t('popup.loading_payment_information'),
    });
  }
};

export const getPrivateFiltersTable = (
  contractId,
  {
    filters,
    pagination,
  }: {
    filters: any;
    pagination: { current: number | string; perPage: number | string };
  },
) => {
  Object.keys(filters).forEach((key) => {
    if (filters[key] === null || !filters[key]) {
      delete filters[key];
    } else {
      filters[key] = makerRequestParams(filters[key]);
    }
  });
  return contractsAPI.getPaymentDetailsTable(contractId, {
    ..._.omit(filters),
    page_size: pagination.perPage,
    page: pagination.current,
    distinct: true,
  });
};

export const getTablePage = (contractId) => async (dispatch: any, getState: any) => {
  const {
    contractDetails: {
      paymentsList: { pagination, sorting, filters, data },
    },
  }: AppState = getState();
  dispatch({ type: TYPES.setTablePage, pending: true });

  try {
    const response = await contractsAPI.getTablePageData(contractId, { pagination, sorting, filters });

    dispatch({ type: TYPES.setTablePage, payload: response.data, pending: false });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    dispatch({ type: TYPES.setTablePage, payload: { resultList: data }, pending: false });
  }
};

export const getFilteredPayments = (contractId, filtersInfo: object, t) => async (dispatch: any, getState: any) => {
  const {
    contractDetails: {
      paymentsList: { pagination, sorting },
    },
  }: AppState = getState();

  try {
    const response = await getPrivateFiltersTable(contractId, {
      filters: {
        ...filtersInfo,
        sorting:
          (!sorting.direction && '-expectedPaymentDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination,
    });

    dispatch({ type: TYPES.getPrivateFiltersData, payload: response.data });
    dispatch({ type: TYPES.setPagination, payload: { current: pagination.current, perPage: pagination.perPage } });
  } catch (error) {
    notification.error({
      message: t('popup.application_private_filters'),
      description: t('popup.admin_try_again_later'),
    });
  }
};
export const getFilteredPrivateData = (filtersInfo: object) => async (dispatch: any, getState: any) => {
  const {
    contractDetails: {
      paymentsList: {
        pagination,
        sorting,
        filters: {
          periodStartDate,
          periodEndDate,
          expectedPaymentAmount,
          expectedPaymentDate,
          paymentAmount,
          paymentDate,
          checkNumber,
          leftToPay,
          contractId,
          insuranceCompanyId,
          partnerId,
          partnerDepartmentId,
        },
      },
    },
  }: AppState = getState();

  try {
    const response = await getPrivateFiltersTable(contractId, {
      filters: {
        ...filtersInfo,
        periodStartDate,
        periodEndDate,
        expectedPaymentAmount,
        expectedPaymentDate,
        paymentAmount,
        paymentDate,
        checkNumber,
        leftToPay,
        contractId,
        insuranceCompanyId,
        partnerId,
        partnerDepartmentId,
        sorting:
          (!sorting.direction && '-expectedPaymentDate,-id') || sorting.direction === 'DESC'
            ? `-${sorting.field},-id`
            : `${sorting.field},id`,
      },
      pagination: {
        current: 1,
        perPage: pagination.perPage,
      },
    });
    const payload = response.data ? response.data : [];

    dispatch({ type: TYPES.getPrivateFilteredData, payload });
  } catch (error) {
    notification.error({
      message: 'Помилка застосування приватних фільтрів',
      description: 'Спробуйте ще раз пізніше',
    });
  }
};

export const setSorting =
  ({ field, direction }: { field: string; direction: string }) =>
  (dispatch: any) => {
    dispatch({ type: TYPES.setSorting, payload: { field, direction } });
  };

export const getEstateTypes = () => async (dispatch: any) => {
  try {
    const { data } = await contractsAPI.getEstateTypes();
    dispatch({ type: TYPES.setEstateTypes, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const getEditedFotoContractId = (id: number) => async (dispatch: any) => {
  dispatch({ type: TYPES.setEditFotoContractId, payload: id });
};

export const setEstateData = (field, data) => {
  return {
    type: TYPES.setEstateData,
    payload: { field, data },
  };
};
export const getProgramCode = (code: string) => {
  return {
    type: TYPES.setProgramCode,
    payload: code,
  };
};

export const getEstateSubTypes = (id) => async (dispatch: any) => {
  try {
    const request = id ? (await contractsAPI.getEstateSubTypes(id)).data : [];
    dispatch({ type: TYPES.setEstateSybTypes, payload: request });
  } catch (error) {
    console.log(error);
  }
};

export const setIdentifier = (code: string, t) => (dispatch, getState) => {
  const {
    contractDetails: { contract, insurancePrograms },
    user: userInfo,
  } = getState();
  try {
    const identification = getIdentifier(code) || '090';
    dispatch({
      type: TYPES.setIdentifier,
      payload: identification,
    });
    const prefixCheck = contract?.contractNumberPrefix || (userInfo && userInfo.checkNumberPrefix);
    const getYear = moment().year().toString().slice(-2);
    const endNumber = contract.contractNumberEnding;

    let contractFullNumber = `${getYear}.${identification}.${prefixCheck || ''}${
      contract.contractNumberMainPart
    }${endNumber}`;
    const contractTypeConfig = {
      insurancePrograms: insurancePrograms,
      programCode: contract.insuranceProgram?.code,
      programId: contract.insuranceProgram.id,
    };

    const { isInternational } = getAffordableInsuranceProgram(contractTypeConfig);

    if (isInternational) {
      contractFullNumber = getContractNumberForAPEXSK(prefixCheck, identification, getYear);
    }

    dispatch(setContractNumber(contractFullNumber));
  } catch (e) {
    notification.error({
      message: t('popup.error'),
      description: t('popup.try_again_products'),
    });
  }
};

export const setNumberMainPart = (data: string) => {
  return {
    type: TYPES.setNumberMainPart,
    payload: data,
  };
};

export const setGenerationNumber = (insuranceProductCode) => async (dispatch: any) => {
  try {
    const request = await contractsAPI.generateContractNumber(insuranceProductCode);
    dispatch({ type: TYPES.setGenNumber, payload: request.data });
    dispatch({ type: TYPES.setNumberMainPart, payload: request.data });

    return request;
  } catch (error) {
    console.log(error);
  }
};

export const setContractDuration = (value: number, units: string) => {
  return {
    type: TYPES.setContractDuration,
    payload: { value, units },
  };
};

export const setAccountNumber = (value: number) => {
  return {
    type: TYPES.setAccountNumber,
    payload: value,
  };
};

export const sendClientOnSign = (id: number) => async (dispatch: any) => {
  try {
    await contractsAPI.onSendContractOnSign(id);
    await dispatch(setContract(id));
  } catch (e) {
    console.log('error: ', e);
    dispatch({ type: TYPES.setClientOnSignError, payload: { isPhotoRequired: true } });
  }
};

export const removeReconciliation = () => {
  return {
    type: TYPES.removeReconsiliation,
  };
};

export const setInitialRates = (data) => {
  return {
    type: TYPES.setInitialRates,
    payload: data,
  };
};

export const resetInitialPaymentAmount = () => {
  return {
    type: TYPES.resetInitialPaymentAmount,
  };
};

export const getCountries = () => async (dispatch: any) => {
  try {
    const params = { page_size: -1 };
    const { data } = await apiLocations.countriesController.list(params);

    dispatch({ type: TYPES.getCountries, payload: data.resultList });
  } catch (error) {
    console.log(error);
  }
};

export const getContractById = (id, successFn, errorFn) => async (dispatch) => {
  try {
    const response = await contractsAPI.getContractById(id);
    dispatch({ type: TYPES.setContractById, payload: { contractByIdInfo: response } });
    successFn();
  } catch (error) {
    errorFn(error?.response?.data?.message);
  }
};

export const sendClientSignVerification = (id, successFn, errorFn, t) => async (dispatch) => {
  try {
    await contractsAPI.sendClientSignVerification(id);
    dispatch({ type: TYPES.sendClientSignVerification });
    successFn(t('search_result.modal_again'));
  } catch (error) {
    errorFn(error?.response?.data?.message);
  }
};

export const sendClientSign = (id, token, successFn, errorFn) => async (dispatch) => {
  try {
    await contractsAPI.sendClientSign(id, token);
    dispatch({ type: TYPES.sendClientSign });
    successFn();
  } catch (error) {
    errorFn(error?.response?.data?.message);
  }
};

export const getDurationListeForProlongation = (id: number, t: TFunction) => async (dispatch: Dispatch) => {
  try {
    const result = await contractsAPI.getDurationListeForProlongation(id);

    let duration = result?.[0]?.rates || [];
    const formattedDuration: ISelectProgramDuration[] = getFormattedDuration(duration, t);
    let rate = duration[0]?.rate;

    dispatch({
      type: TYPES.setInsuranceProgramDuration,
      payload: {
        insuranceProgramDuration: formattedDuration,
      },
    });
    dispatch({
      type: TYPES.setInsuranceProlongRate,
      payload: {
        prolongRate: rate,
      },
    });
    return { duration: formattedDuration, contractNumberEnding: duration[0].contractNumberEnding };
  } catch (error) {
    console.log(error, 'error');
  }
};

export const setInsuranceSumId = (sumId: number) => (dispatch: Dispatch) => {
  dispatch({ type: TYPES.setInsuranceSumId, payload: sumId });
};
