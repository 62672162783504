import React from 'react';
import styles from './MonthInfo.module.less';
import classNames from 'classnames';
import moment from 'moment';
import numeral from 'numeral';
import 'moment/locale/uk';
import { IStatistics } from '../../../../api/statistics';
import { useTranslation } from 'react-i18next';
import { currentCurrency } from 'utils/helpers';
import { MonthInfoDto } from 'types/dto/contracts-service';
import i18n from 'translations/i18n';

moment.locale(i18n.language);
numeral.localeData().delimiters.thousands = ' ';

interface IDoubleTitleInputForm {
  monthInfo?: MonthInfoDto;
  readOnly?: boolean;
  color?: any;
  isCurrencyValue?: boolean;
}

const CURRENCY_FORMAT = '0,0.00';
const STATISTICS_NAMES = (t) => {
  return {
    insurancePayment: {
      title: t('dash_board.insurance_payments'),
      format: CURRENCY_FORMAT,
      prefix: currentCurrency(),
    },
    calculatedRefundSum: {
      title: t('dash_board.returns_under_terminated_contracts'),
      format: CURRENCY_FORMAT,
      prefix: currentCurrency(),
    },
    newClientCount: {
      title: t('dash_board.new_customers'),
    },
    returnedClientCount: {
      title: t('dash_board.returned_customers'),
    },
    terminatedContract: {
      title: t('dash_board.terminated_contracts'),
    },
  };
};

const MonthInfoItem = ({
  invert = false,
  item,
  date,
  title,
  format = '0',
  prefix,
  isCurrencyValue,
}: {
  title: string;
  format: string;
  item: IStatistics;
  isCurrencyValue?: boolean;
  date: string;
  invert: boolean;
  prefix?: string;
}) => (
  <div className={classNames(styles.input_holder, { [styles.currency_value]: isCurrencyValue })}>
    <p>{title}</p>
    <div className={styles.statistic_holder}>
      <p
        className={classNames(styles.colored_text, {
          [styles.textDanger]: invert
            ? item.currentValue >= item.previousValue
            : item.currentValue < item.previousValue,
          [styles.textSuccess]: invert
            ? item.currentValue < item.previousValue
            : item.currentValue >= item.previousValue,
        })}
      >
        {numeral(item.currentValue || 0).format(format)} {(prefix && ` ${prefix}`) || null}
      </p>
      <div className={styles.statistic_holder_inner}>
        <span className={styles.ellips}>
          {numeral(item.previousValue || 0).format(format)} {(prefix && ` ${prefix}`) || null}
        </span>
        <span>{moment(date).locale(i18n.language).format('MMM. YYYY')}</span>
      </div>
    </div>
  </div>
);

const MonthInfo = ({ monthInfo, isCurrencyValue }: IDoubleTitleInputForm) => {
  const { t } = useTranslation();
  return (
    <div className={styles.menuInputs__container}>
      {monthInfo &&
        Object.keys(STATISTICS_NAMES(t)).map((key) => {
          return (
            <MonthInfoItem
              item={(monthInfo as any)[key]}
              key={key}
              invert={key === 'terminatedContract'}
              title={STATISTICS_NAMES(t)[key].title}
              format={STATISTICS_NAMES(t)[key].format}
              prefix={STATISTICS_NAMES(t)[key].prefix}
              date={monthInfo.previousStartDate ?? ''}
              isCurrencyValue={isCurrencyValue}
            />
          );
        })}
    </div>
  );
};

export default MonthInfo;
