import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col, notification, Modal } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import isEmail from 'is-email';
import { AxiosResponse } from 'axios-next';

import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { apiContracts } from 'api/contracts';
import { UseHistory } from 'types';
import { Authorization } from 'types/whiteLabel';
import Link from 'components/nextRedesign/Link/Link';
import Button from 'components/nextRedesign/Button/Button';
import { InputField } from 'components/nextRedesign/InputField/InputField';
import { RequestParams, ResetPasswordRequestDto } from 'types/dto/contracts-service';

import Verification from '../Verification/Verification';
import { State } from '../ClientPasswordRecovery';
import { getSchema, defaultValues, Values } from './Recovery.schema';

const cssWrapper = css`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`;
const cssSubmit = css`
  margin: 24px 0 16px 0;
  width: 100%;
`;

type Props = {
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const Recovery = ({ state, setState }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();

  const whiteLabeling = useSelector((store: AppState) => store.me.whiteLabel?.data?.whiteLabeling);

  const [loading, setLoading] = useState<boolean>(false);

  const schema = getSchema({
    authorization: whiteLabeling?.authorization ?? Authorization.PHONE_AND_EMAIL,
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });

  const watchedFields = watch();
  const isAnyFieldEmpty = Object.values(watchedFields).every((value) => !value);

  const onSubmit = async (values: Values) => {
    setLoading(true);

    const sendRequest = (
      apiCall: (data: ResetPasswordRequestDto, params?: RequestParams) => Promise<AxiosResponse<void, any>>,
      value: { login: string },
    ) => {
      apiCall(value)
        .then(() => {
          setState({ login: values.login, isVerificationModalOpen: true });
        })
        .catch((error) => {
          if (error?.response?.data?.startsWith('User not found')) {
            return notification.error({ message: t('passwordRecovery.account_not_found') });
          }
          return notification.error({ message: t('signup.something_went_wrong_login') });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (isEmail(values.login.trim())) {
      sendRequest(apiContracts.userController.sendPasswordResetEmailToClient, {
        login: values.login ?? '',
      });
    } else {
      sendRequest(apiContracts.userController.sendPasswordResetSmsToClient, {
        login: values.login ?? '',
      });
    }
  };

  const getPlaceholder = () => {
    switch (whiteLabeling?.authorization) {
      case Authorization.PHONE_AND_EMAIL:
        return t('log_in.enter_email_or_phone_number');
      case Authorization.PHONE:
        return t('log_in.enter_phone_number');
      case Authorization.EMAIL:
        return t('log_in.enter_email');

      default:
        return t('log_in.enter_email_or_phone_number');
    }
  };

  return (
    <>
      <div className={cssWrapper}>
        <form onSubmit={handleSubmit(onSubmit)} data-test="form">
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Controller
                    name="login"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputField
                        name={name}
                        errors={errors}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('log_in.login')}
                        colorBase="grey"
                        placeholder={getPlaceholder()}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className={cssSubmit}
                loading={loading}
                disabled={isAnyFieldEmpty}
              >
                {t('log_in.next')}
              </Button>
            </Col>
          </Row>
        </form>
        <div>
          <Link
            onClick={() => {
              history.push(ROUTES.AUTH.CLIENT_LOGIN);
            }}
          >
            {t('log_in.back')}
          </Link>
        </div>
      </div>
      <Modal
        footer={null}
        maskClosable={false}
        destroyOnClose
        open={state.isVerificationModalOpen}
        onCancel={() => setState({ isVerificationModalOpen: false })}
        width={650}
        centered
      >
        <Verification state={state} setState={setState} />
      </Modal>
    </>
  );
};

export default Recovery;
