import React from 'react';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Typography } from 'antd';
import { css } from '@emotion/css';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import locales from 'config/locales';
import { Language as ILanguage } from 'types';

const cssLanguage = css`
  font-size: 18px;
  color: var(--ant-primary-color);
`;

const Language = () => {
  const { i18n } = useTranslation();

  const changeLocale = (locale: ILanguage) => () => {
    i18n.changeLanguage(locale);
  };

  const items: ItemType[] = Object.entries(locales)
    .filter(([locale]) => locale !== i18n.language)
    .map(([locale, options]) => ({ key: locale, label: options.title, onClick: changeLocale(locale as ILanguage) }));

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={(e) => e.preventDefault()}>
        <Typography.Text className={cssLanguage}>{locales[i18n.language as ILanguage].titleShort}</Typography.Text>{' '}
        <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default Language;
