import createInstance from './axios/axios';
import { AxiosResponse } from 'axios';
import momentTz from 'moment-timezone';
import _ from 'lodash';
import { ResultListDtoDepartmentView } from 'types/dto/contracts-service';
import { ResultListDtoInsuranceProgramView } from 'types/dto/configuration-service';

interface IData {
  partnerId?: number;
  insuranceCompanyId?: number;
}

const api = createInstance('CONTRACTS');
const contractsPdfAPI = createInstance('CONTRACTS', 'pdf');
const configApi = createInstance('CONFIGURATION');

export const getInsuranceProgram = (data: IData) => {
  return configApi.get<ResultListDtoInsuranceProgramView>('/insurance-programs?attributes=id,name&distinct', {
    params: { ...data },
  });
};

export const getPointsSale = (partnerId?: string) => {
  return api
    .get<ResultListDtoDepartmentView>(
      `/departments?distinct=true${partnerId ?? ''}&attributes=id,name,departmentNumber,partnerId`,
      {},
    )
    .then((response) => response);
};

export const printUserCard = (id: number): Promise<AxiosResponse<Blob>> => {
  const timeZone = momentTz.tz.guess(true);
  return contractsPdfAPI.get(`/users/${id}/pdf-report`, {
    params: {
      timeZone,
    },
  });
};
