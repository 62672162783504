const pallete = {
  primary: '#002856',

  basic: {
    white: '#ffffff',
    black: '#000000',
  },

  gray: {
    main: '#6A7983',
    light: '#A9B5BD',
    light2: '#B2B2B3',
    lighter: '#EEEFF4',
  },

  orange: {
    main: '#F2994A',
    light: '#FDF0E4',
  },

  success: {
    main: '#3A9A3B',
    light: '#EBF5EB',
  },

  warning: {
    main: '#FFB800',
    light: '#FFF8E5',
  },

  error: {
    main: '#AC262F',
    light: '#F7E9EA',
  },
};

export default pallete;
