import React from 'react';
import { css, cx } from '@emotion/css';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps, IconComponentProps } from '@ant-design/icons/lib/components/Icon';
// import { IconComponentProps } from '@ant-design/icons/lib/components/AntdIcon';
import { IconProps } from '@ant-design/compatible/lib/icon';

import { ReactComponent as XLSX } from './xlsx.svg';
import { ReactComponent as Dia } from './dia.svg';
import { ReactComponent as Plane } from './plane.svg';
import { ReactComponent as UIKit } from './ui-kit.svg';
import { ReactComponent as Dots } from './dots.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Gear } from './gear.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Warn } from './warn.svg';
import { ReactComponent as Bar } from './bar.svg';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Handshake } from './handshake.svg';
import { ReactComponent as Steps } from './steps.svg';
import { ReactComponent as Clipboard } from './clipboard.svg';
import { ReactComponent as Medical } from './medical.svg';
import { ReactComponent as Clinic } from './clinic.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Briefcase } from './briefcase.svg';
import { ReactComponent as Documents } from './documents.svg';
import { ReactComponent as Chat } from './chat.svg';
import { ReactComponent as ListBox } from './list-box.svg';
import { ReactComponent as StatusSuccess } from './status-success.svg';
import { ReactComponent as StatusError } from './status-error.svg';
import { ReactComponent as StatusEdit } from './status-edit.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as Remove } from './remove.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as PDF } from './pdf.svg';
import { ReactComponent as Back } from './back.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Invoice } from './invoice.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Discount } from './discount.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as MailFail } from './mail-fail.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Case } from './case.svg';
import { ReactComponent as Acts } from './acts.svg';
import { ReactComponent as Broken } from './broken.svg';
import { ReactComponent as Guard } from './guard.svg';
import { ReactComponent as Devices } from './devices.svg';
import { ReactComponent as Setting } from './setting.svg';
import { ReactComponent as Repair } from './repair.svg';
import { ReactComponent as Chats } from './chats.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as LogoInrisk } from './logoinrisk.svg';
import { ReactComponent as ArrowRight } from './arrowright.svg';
import { ReactComponent as LogoutRedesign } from './logout-redesign.svg';
import { ReactComponent as Policies } from './policies.svg';
import { ReactComponent as HomeRedesign } from './home-re.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Bonuses } from './bonuses.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Telegram } from './telegram.svg';
import { ReactComponent as CopyRedesign } from './copy-re.svg';
import { ReactComponent as Socket } from './socket.svg';
import { ReactComponent as Jutlo } from './jutlo.svg';
import { ReactComponent as Medicine } from './medicine.svg';
import { ReactComponent as Truck } from './truck.svg';
import { ReactComponent as Car } from './car.svg';
import { ReactComponent as Coronavirus } from './coronavirus.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as List } from './list.svg';

type BaseColor = 'primary' | 'warning' | 'error' | 'success';
type Color = BaseColor | 'inherit' | string;
type Background = BaseColor | 'none' | string;

const cssBackground = ({ background }: { background?: Background }) => css`
  &.anticon {
    padding: 8px;
    border-radius: 8px;
    background: ${backgroundMap[background ?? 'none'] ?? background};
  }
`;

const cssIcon = ({ color }: { color?: Color }) => css`
  &.anticon {
    font-size: 16px;
    color: ${colorMap[color ?? 'inherit'] ?? color};
  }
`;

const cssComplex = ({ background, color, className }: { background?: Background; color?: Color; className?: string }) =>
  cx(cssIcon({ color }), { [cssBackground({ background })]: Boolean(background) }, className);

const colorMap: Record<Color, string> = {
  primary: 'var(--ant-primary-color)',
  warning: 'var(--ant-warning-color)',
  error: 'var(--ant-error-color)',
  success: 'var(--ant-success-color)',
  inherit: 'inherit',
};

const backgroundMap: Record<Background, string> = {
  primary: 'var(--ant-primary-color-outline)',
  warning: 'var(--ant-warning-color-outline)',
  error: 'var(--ant-error-color-outline)',
  success: 'var(--ant-success-color-outline)',
  none: 'none',
};

interface Props extends IconProps {
  color?: Color;
  background?: Background;
}

export const IconXLSX = ({ color, background, className, ...props }: Props) => (
  <Icon component={XLSX} className={cssComplex({ color, background, className })} {...props} />
);
// TODO front check all style wrappers
export const IconDia = ({ color, background, className, ...props }: Props) => (
  <Icon component={Dia} className={cssComplex({ color, background, className })} {...props} />
);
export const IconPlane = ({ color, background, className, ...props }: Props) => (
  <Icon component={Plane} className={cssComplex({ color, background, className })} {...props} />
);
export const IconUIKit = ({ color, background, className, ...props }: Props) => (
  <Icon component={UIKit} className={cssComplex({ color, background, className })} {...props} />
);
export const IconDots = ({ color, background, className, ...props }: Props) => (
  <Icon component={Dots} className={cssComplex({ color, background, className })} {...props} />
);
export const IconBell = ({ color, background, className, ...props }: Props) => (
  <Icon component={Bell} className={cssComplex({ color, background, className })} {...props} />
);
export const IconGear = ({ color, background, className, ...props }: Props) => (
  <Icon component={Gear} className={cssComplex({ color, background, className })} {...props} />
);
export const IconLock = ({ color, background, className, ...props }: Props) => (
  <Icon component={Lock} className={cssComplex({ color, background, className })} {...props} />
);
export const IconWarn = ({ color, background, className, ...props }: Props) => (
  <Icon component={Warn} className={cssComplex({ color, background, className })} {...props} />
);
export const IconBar = ({ color, background, className, ...props }: Props) => (
  <Icon component={Bar} className={cssComplex({ color, background, className })} {...props} />
);
export const IconDashboard = ({ color, background, className, ...props }: Props) => (
  <Icon component={Dashboard} className={cssComplex({ color, background, className })} {...props} />
);
export const IconHandshake = ({ color, background, className, ...props }: Props) => (
  <Icon component={Handshake} className={cssComplex({ color, background, className })} {...props} />
);
export const IconSteps = ({ color, background, className, ...props }: Props) => (
  <Icon component={Steps} className={cssComplex({ color, background, className })} {...props} />
);
export const IconClipboard = ({ color, background, className, ...props }: Props) => (
  <Icon component={Clipboard} className={cssComplex({ color, background, className })} {...props} />
);
export const IconMedical = ({ color, background, className, ...props }: Props) => (
  <Icon component={Medical} className={cssComplex({ color, background, className })} {...props} />
);
export const IconClinic = ({ color, background, className, ...props }: Props) => (
  <Icon component={Clinic} className={cssComplex({ color, background, className })} {...props} />
);
export const IconUsers = ({ color, background, className, ...props }: Props) => (
  <Icon component={Users} className={cssComplex({ color, background, className })} {...props} />
);
export const IconBriefcase = ({ color, background, className, ...props }: Props) => (
  <Icon component={Briefcase} className={cssComplex({ color, background, className })} {...props} />
);
export const IconDocuments = ({ color, background, className, ...props }: Props) => (
  <Icon component={Documents} className={cssComplex({ color, background, className })} {...props} />
);
export const IconChat = ({ color, background, className, ...props }: Props) => (
  <Icon component={Chat} className={cssComplex({ color, background, className })} {...props} />
);
export const IconListBox = ({ color, background, className, ...props }: Props) => (
  <Icon component={ListBox} className={cssComplex({ color, background, className })} {...props} />
);
export const IconStatusSuccess = ({ color, background, className, ...props }: Props) => (
  <Icon component={StatusSuccess} className={cssComplex({ color, background, className })} {...props} />
);
export const IconStatusError = ({ color, background, className, ...props }: Props) => (
  <Icon component={StatusError} className={cssComplex({ color, background, className })} {...props} />
);
export const IconStatusEdit = ({ color, background, className, ...props }: Props) => (
  <Icon component={StatusEdit} className={cssComplex({ color, background, className })} {...props} />
);
export const IconAdd = ({ color, background, className, ...props }: Props) => (
  <Icon component={Add} className={cssComplex({ color, background, className })} {...props} />
);
export const IconRemove = ({ color, background, className, ...props }: Props) => (
  <Icon component={Remove} className={cssComplex({ color, background, className })} {...props} />
);
export const IconEye = ({ color, background, className, ...props }: Props) => (
  <Icon component={Eye} className={cssComplex({ color, background, className })} {...props} />
);
export const IconPDF = ({ color, background, className, ...props }: Props) => (
  <Icon component={PDF} className={cssComplex({ color, background, className })} {...props} />
);
export const IconBack = ({ color, background, className, ...props }: Props) => (
  <Icon component={Back} className={cssComplex({ color, background, className })} {...props} />
);
export const IconEdit = ({ color, background, className, ...props }: Props) => (
  <Icon component={Edit} className={cssComplex({ color, background, className })} {...props} />
);
export const IconInvoice = ({ color, background, className, ...props }: Props) => (
  <Icon component={Invoice} className={cssComplex({ color, background, className })} {...props} />
);
export const IconUser = ({ color, background, className, ...props }: Props) => (
  <Icon component={User} className={cssComplex({ color, background, className })} {...props} />
);
export const IconDiscount = ({ color, background, className, ...props }: Props) => (
  <Icon component={Discount} className={cssComplex({ color, background, className })} {...props} />
);
export const IconDownload = ({ color, background, className, ...props }: Props) => (
  <Icon component={Download} className={cssComplex({ color, background, className })} {...props} />
);
export const IconUpload = ({ color, background, className, ...props }: Props) => (
  <Icon component={Upload} className={cssComplex({ color, background, className })} {...props} />
);
export const IconMailFail = ({ color, background, className, ...props }: Props) => (
  <Icon component={MailFail} className={cssComplex({ color, background, className })} {...props} />
);
export const IconHome = ({ color, background, className, ...props }: Props) => (
  <Icon component={Home} className={cssComplex({ color, background, className })} {...props} />
);
export const IconSearch = ({ color, background, className, ...props }: Props) => (
  <Icon component={Search} className={cssComplex({ color, background, className })} {...props} />
);
export const IconCase = ({ color, background, className, ...props }: Props) => (
  <Icon component={Case} className={cssComplex({ color, background, className })} {...props} />
);
export const IconActs = ({ color, background, className, ...props }: Props) => (
  <Icon component={Acts} className={cssComplex({ color, background, className })} {...props} />
);
export const IconBroken = ({ color, background, className, ...props }: Props) => (
  <Icon component={Broken} className={cssComplex({ color, background, className })} {...props} />
);
export const IconGuard = ({ color, background, className, ...props }: Props) => (
  <Icon component={Guard} className={cssComplex({ color, background, className })} {...props} />
);
export const IconDevices = ({ color, background, className, ...props }: Props) => (
  <Icon component={Devices} className={cssComplex({ color, background, className })} {...props} />
);
export const IconSetting = ({ color, background, className, ...props }: Props) => (
  <Icon component={Setting} className={cssComplex({ color, background, className })} {...props} />
);
export const IconRepair = ({ color, background, className, ...props }: Props) => (
  <Icon component={Repair} className={cssComplex({ color, background, className })} {...props} />
);
export const IconChats = ({ color, background, className, ...props }: Props) => (
  <Icon component={Chats} className={cssComplex({ color, background, className })} {...props} />
);
export const IconQuestion = ({ color, background, className, ...props }: Props) => (
  <Icon component={Question} className={cssComplex({ color, background, className })} {...props} />
);
export const IconEmail = ({ color, background, className, ...props }: Props) => (
  <Icon component={Email} className={cssComplex({ color, background, className })} {...props} />
);
export const IconLogout = ({ color, background, className, ...props }: Props) => (
  <Icon component={Logout} className={cssComplex({ color, background, className })} {...props} />
);
export const IconLogoInrisk = ({ color, background, className, ...props }: Props) => (
  <Icon component={LogoInrisk} className={cssComplex({ color, background, className })} {...props} />
);
export const IconArrowRight = ({ color, background, className, ...props }: Props) => (
  <Icon component={ArrowRight} className={cssComplex({ color, background, className })} {...props} />
);
export const IconPolicies = ({ color, background, className, ...props }: Props) => (
  <Icon component={Policies} className={cssComplex({ color, background, className })} {...props} />
);
export const IconCopy = ({ color, background, className, ...props }: Props) => (
  <Icon component={Copy} className={cssComplex({ color, background, className })} {...props} />
);
export const IconBonuses = ({ color, background, className, ...props }: Props) => (
  <Icon component={Bonuses} className={cssComplex({ color, background, className })} {...props} />
);
export const IconInfo = ({ color, background, className, ...props }: Props) => (
  <Icon component={Info} className={cssComplex({ color, background, className })} {...props} />
);
export const IconTelegram = ({ color, background, className, ...props }: Props) => (
  <Icon component={Telegram} className={cssComplex({ color, background, className })} {...props} />
);
export const IconSocket = ({ color, background, className, ...props }: Props) => (
  <Icon component={Socket} className={cssComplex({ color, background, className })} {...props} />
);
export const IconJutlo = ({ color, background, className, ...props }: Props) => (
  <Icon component={Jutlo} className={cssComplex({ color, background, className })} {...props} />
);
export const IconMedicine = ({ color, background, className, ...props }: Props) => (
  <Icon component={Medicine} className={cssComplex({ color, background, className })} {...props} />
);
export const IconTruck = ({ color, background, className, ...props }: Props) => (
  <Icon component={Truck} className={cssComplex({ color, background, className })} {...props} />
);
export const IconCar = ({ color, background, className, ...props }: Props) => (
  <Icon component={Car} className={cssComplex({ color, background, className })} {...props} />
);
export const IconCoronavirus = ({ color, background, className, ...props }: Props) => (
  <Icon component={Coronavirus} className={cssComplex({ color, background, className })} {...props} />
);
export const IconCheck = ({ color, background, className, ...props }: Props) => (
  <Icon component={Check} className={cssComplex({ color, background, className })} {...props} />
);
export const IconList = ({ color, background, className, ...props }: Props) => (
  <Icon component={List} className={cssComplex({ color, background, className })} {...props} />
);

//Redesign dublicate
export const IconLogoutRedesign = ({ color, background, className, ...props }: Props) => (
  <Icon component={LogoutRedesign} className={cssComplex({ color, background, className })} {...props} />
);
export const IconHomeRedesign = ({ color, background, className, ...props }: Props) => (
  <Icon component={HomeRedesign} className={cssComplex({ color, background, className })} {...props} />
);
export const IconCopyRedesign = ({ color, background, className, ...props }: Props) => (
  <Icon component={CopyRedesign} className={cssComplex({ color, background, className })} {...props} />
);
