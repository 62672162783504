import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';
import { TABS } from 'screens/InsuranceProgramCreate/Table/types';
import { TYPES } from './actions';
import { IInsProgramCreate } from './types';
import { ContractDraftStoragePeriodType, WidgetType } from 'types/dto/configuration-service';

export const initialState: IInsProgramCreate = {
  programData: {},
  coefficientsk3andk6Data: { k3: [], k6: [] },
  changedCoefficient: { rate: 0, mappingFields: {}, tableModal: '', id: 0 },
  recordId: 0,
  insuranceCompanies: [],
  programTypes: [],
  managers: [],
  agents: [],
  errors: [],
  insTypes: [],
  insRules: [],
  insuranceTypesData: { resultList: [] },
  insuranceFilteredRisksData: { resultList: [] },
  activeTab: TABS.RISKS,
  risksList: [],
  currentRecord: {},
  currentStatus: '',
  chosenSettlement: [],
  tariffData: {},
  coefficientData: {},
  insurancePoliciesData: [],
  documents: [],
  document: '',
  insuranceObject: '',
  selectedDocumentsValues: {
    contractTemplate: null,
    claimTemplate: null,
    actTemplate: null,
    terminationTemplate: null,
  },
  parameters: {
    autoGenerationNumber: false,
    medApp: false,
    generalContract: false,
    prolongation: false,
    installments: false,
    requiredContractVerify: false,
    contractStartPeriod: '',
    roundingType: '',
    contractDraftTtl: ContractDraftStoragePeriodType.TWO_MONTH,
    widgetType: WidgetType.NONE,
  },
  payments: {
    settlementTypes: {
      settlementTypes: [],
      risks: [],
      documents: null,
      isLoading: false,
      documentTypeNamesList: [],
      allDocumentTypeNamesList: [],
      documentsConfiguration: null,
      documentsWhichAreNotConfigured: [],
    },
    refusalReasons: {
      templateData: [],
    },
  },

  terminationNotice: {
    terminationInitiatorsNames: [],
    acountTypes: [],
    terminationCauses: [],
    terminationsDocumentsNames: [],
  },
};

const getProgramData = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    programData: payload.data,
  };
};

const getInsuranceCompanies = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insuranceCompanies: payload.data.resultList,
  };
};

const setInsuranceProgramId = (state: IInsProgramCreate, { payload }: AnyAction) => {
  return {
    ...state,
    programData: {
      ...state.programData,
      changedInsuranceProgramId: payload,
    },
  };
};

const getProgramsType = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    programTypes: payload.data,
  };
};

const getManagers = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    managers: payload.data,
  };
};

const getAgents = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    agents: payload.data,
  };
};

const setError = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  const cleanedErrors = [...state.errors, payload].filter((item) => Object.keys(item)[0] !== Object.keys(payload)[0]);
  return {
    ...state,
    errors: [...cleanedErrors, payload],
  };
};

const getInsTypes = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insTypes: payload.data,
  };
};

const insuranceTypesAll = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insuranceTypesData: payload.data,
  };
};

const insuranceFilteredRisks = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insuranceFilteredRisksData: payload.data,
  };
};

const getInsRules = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insRules: payload.data,
  };
};

const getRisksList = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    risksList: payload.data,
  };
};

const setRecord = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    currentRecord: payload,
  };
};

const setStatus = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    currentStatus: payload,
  };
};

const setChosenSettlement = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    chosenSettlement: payload,
  };
};

const getTariffData = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    tariffData: payload,
  };
};

const getInsurancePolicie = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insurancePoliciesData: payload,
  };
};

const setDocuments = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    documents: payload,
  };
};
const setTabKey = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    activeTab: payload,
  };
};

const setInsuranceObject = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    insuranceObject: payload,
  };
};

const setSelectedDocumentsValues = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    selectedDocumentsValues: payload,
  };
};

const setParameters = (
  state: IInsProgramCreate,
  { payload: { fieldName, fieldValue } }: AnyAction,
): IInsProgramCreate => {
  return {
    ...state,
    parameters: {
      ...state.parameters,
      [fieldName]: fieldValue,
    },
  };
};

const setTerminationInitiatorsNames = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      terminationInitiatorsNames: payload,
    },
  };
};

const setAccountTypes = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      acountTypes: payload,
    },
  };
};

const setTerminationCauses = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      terminationCauses: payload,
    },
  };
};

const setTemplateData = (state: IInsProgramCreate, { payload: { data } }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    payments: {
      ...state.payments,
      refusalReasons: {
        ...state.payments.refusalReasons,
        templateData: data,
      },
    },
  };
};

const setTerminationDocumentsNames = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    terminationNotice: {
      ...state.terminationNotice,
      terminationsDocumentsNames: payload,
    },
  };
};

const getk3Coefficient = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    coefficientsk3andk6Data: {
      ...state.coefficientsk3andk6Data,
      k3: payload.data,
    },
  };
};
const getk6Coefficient = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    coefficientsk3andk6Data: {
      ...state.coefficientsk3andk6Data,
      k6: payload.data,
    },
  };
};
const changeCofficient = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    changedCoefficient: payload.data,
  };
};
const changeRecordId = (state: IInsProgramCreate, { payload }: AnyAction): IInsProgramCreate => {
  return {
    ...state,
    recordId: payload.data,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    getInsuranceCompanies,
    getProgramsType,
    getManagers,
    setInsuranceProgramId,
    getAgents,
    setError,
    getProgramData,
    getk3Coefficient,
    getk6Coefficient,
    changeCofficient,
    changeRecordId,
    getTariffData,
    getInsTypes,
    getInsRules,
    insuranceTypesAll,
    insuranceFilteredRisks,
    setTabKey,
    getRisksList,
    setRecord,
    setStatus,
    setChosenSettlement,
    getInsurancePolicie,
    setDocuments,
    setInsuranceObject,
    setSelectedDocumentsValues,
    setParameters,
    setTemplateData,
    setTerminationInitiatorsNames,
    setAccountTypes,
    setTerminationCauses,
    setTerminationDocumentsNames,
  },
});
