import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification, Typography } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';

import config from 'config';
import Button from 'components/nextRedesign/Button/Button';
import { CodeInput } from 'components/nextRedesign/CodeInput/CodeInput';
import palleteRedesign from 'styles/palleteRedesign';
import { apiContracts } from 'api/contracts';

import { State } from '../ClientSignUp';
import { schema, defaultValues, Values } from './Verification.schema';

const cssWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
`;
const cssHeader = css`
  &.ant-typography {
    color: ${palleteRedesign.basic.black};
    font-size: 44px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    margin: 0;
  }
`;
const cssText = css`
  margin: 30px 0;
  color: ${palleteRedesign.basic.black};
  font-size: 16px;
  text-align: center;
`;
const cssForm = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const cssCodeInput = css`
  display: flex;
  justify-content: center;
`;
const cssError = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
const cssSubmit = css`
  margin-top: 30px;
  width: 100%;
  max-width: 360px;
`;

type Props = {
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const Verification = ({ state, setState }: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const timer = useTimer({
    autoStart: false,
    expiryTimestamp: new Date(),
    onExpire: () => setState({ isVerificationModalOpen: false }),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const watchedFields = watch();
  const isAnyFieldEmpty = Object.values(watchedFields).every((value) => !value);

  useEffect(() => {
    if (!timer.isRunning) {
      timer.restart(new Date(Date.now() + config.AUTH_OTP_TIMEOUT));
    }
  }, [timer]);

  const onSubmit = (values: Values) => {
    setLoading(true);
    apiContracts.clientRegistrationController
      .validateEmailOtpCode({
        email: state.email,
        token: values.token,
      })
      .then(() => {
        setState({ step: 'password', isVerificationModalOpen: false });
      })
      .catch((error) => {
        notification.error({ message: t('signup.something_went_wrong_login') });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={cssWrapper}>
      <Typography.Title className={cssHeader}>{t('log_in.check_your_email')}</Typography.Title>
      <div className={cssText}>
        <div>{t('signup.register_new_account')}</div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} data-test="form" className={cssForm}>
        <Controller
          name="token"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <CodeInput
              name={name}
              errors={errors}
              value={value}
              onChange={(v) => onChange(v)}
              fields={6}
              inputMode="tel"
              isValid={Boolean(!errors.token)}
              classNameContainer={cssCodeInput}
              errorClassName={cssError}
            />
          )}
        />
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={cssSubmit}
          loading={loading}
          disabled={isAnyFieldEmpty}
        >
          {t('log_in.verify_code')}
        </Button>
      </form>
    </div>
  );
};

export default Verification;
