import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import uuid from 'uuid';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import history from 'routes/history';
import { AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import PrivateFilter from './PrivateFilters';
import TableTools from './TableTools/TableTools';
import { FilterIcon } from 'components/Icons';
import CustomTable from 'components/CustomTable';
import { getServices, setPagination, setSorting, setFilters } from 'store/institutions/actions';
import DropCustom from './TableTools/TableToolsHandler';
import columnsList from './columns';
import { formatData } from './utils';

import styles from './styles';

const AlignedContent = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ServicesTable: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { servicesData, count, pending, current, perPage } = useSelector(
    ({ institutions: { servicesData, pending, pagination, filters } }: AppState) => ({
      servicesData: servicesData?.resultList || [],
      count: servicesData?.count || 0,
      pending,
      current: pagination.current,
      perPage: pagination.perPage,
      filters,
    }),
  );

  const [filtersInfo, setInfo] = useState<any>({});
  const [clearedFilters, setClearedFilters] = useState<boolean>(false);

  const setFiltersInfo = (values: any) => {
    values && Object.keys(values).forEach((el) => !values[el] && delete values[el]);
    setInfo({ ...values });
  };

  const clearFilters = () => {
    dispatch(setFilters('', ''));
    dispatch(getServices());
    setClearedFilters(true);
    setFiltersInfo({});
  };

  const filteredBySearching = (filterBy: string, value: string, type: string) => {
    if (type === 'search') {
      return setFiltersInfo({
        [filterBy]: [value],
      });
    }
    getDataFromPrivateFilter({ [filterBy]: value });
  };

  const getColumnSearchProps = (dataIndex: string, dropDown: boolean = true) => {
    const filteredValue = (filtersInfo && filtersInfo[dataIndex]) || null;
    return (
      dropDown && {
        filteredValue,
        filters:
          (servicesData &&
            servicesData.length &&
            servicesData.map((item: any) => {
              return { value: item[dataIndex] ? String(item[dataIndex]) : '' };
            })) ||
          [],
        filterIcon: (filtered: boolean) => (
          <div>
            <FilterIcon color={filtered ? '#fff' : null} className={classnames({ active: filtered })} />
          </div>
        ),
        filterDropdown: (record: any) => {
          return (
            <PrivateFilter
              record={record}
              dataIndex={dataIndex}
              filtersInfo={filtersInfo}
              setFiltersInfo={setFiltersInfo}
              getDataFromPrivateFilter={getDataFromPrivateFilter}
              filteredBySearching={filteredBySearching}
              isClearedFilters={clearedFilters}
              clearAllFilters={() => setClearedFilters(false)}
            />
          );
        },
      }
    );
  };

  const columns = columnsList(t).map(({ title, value, ...rest }: { title: string; value: any }) => {
    const baseColumn = {
      title: title,
      dataIndex: value,
      key: value,
      sorter: true,
      ...rest,
    };

    switch (value) {
      case 'actions': {
        return {
          ...baseColumn,
          render: (id: any, record: any) => (
            <AlignedContent>
              <DropCustom Component={TableTools} record={record} />
            </AlignedContent>
          ),
        };
      }
      default:
        return { ...getColumnSearchProps(value), ...baseColumn };
    }
  });

  const onPageChange = (page: number, pageSize?: number | undefined): void => {
    dispatch(setPagination(page, pageSize || 0));
    getDataFromPrivateFilter(filtersInfo, { current: page, perPage: pageSize || 0 });
  };

  const onPageSizeChange = (pageSize: string): void => {
    dispatch(setPagination(1, Number(pageSize) || 0));
    getDataFromPrivateFilter(filtersInfo, { current: 1, perPage: Number(pageSize) });
  };

  const handleTableChange = (sorting: { order: string; field: string }) => {
    const formatDirection = (direction: string) => {
      if (direction) {
        return direction === 'ascend' ? 'ASC' : 'DESC';
      }
      return '';
    };

    const fieldName = sorting.field === 'name' ? 'serviceName' : sorting.field;

    const formatSorting = {
      field: fieldName,
      direction: formatDirection(sorting.order),
    };

    dispatch(setSorting(formatSorting));

    getDataFromPrivateFilter(filtersInfo);
  };

  const getDataFromPrivateFilter = (additionalFilters: any, pagination?: any) => {
    dispatch(setPagination(1, Number(perPage) || 1));
    if (pagination) {
      dispatch(setPagination(pagination.current, Number(pagination.perPage) || 1));
    } else {
      dispatch(setPagination(1, Number(perPage) || 1));
    }

    additionalFilters &&
      Object.keys(additionalFilters).forEach((key: any) => {
        if (!additionalFilters[key] || additionalFilters[key] === null || !additionalFilters[key].length) {
          additionalFilters && delete additionalFilters[key];
        }
      });

    setFiltersInfo({
      ...additionalFilters,
    });

    dispatch(
      setFilters(
        additionalFilters.name ? additionalFilters.name.join('|') : '',
        additionalFilters.serviceTypeName ? additionalFilters.serviceTypeName.join('|') : '',
      ),
    );

    dispatch(getServices());
  };

  const handleFilters = (value: {}) => setFiltersInfo({ ...filtersInfo, ...value });

  const onRowClick = (row: any) => {
    history.push(ROUTES.MEDAPP.SERVICES.DETAILS.ROOT.replace(':id', String(row.id)));
  };

  return (
    <CustomTable
      {...props}
      columns={columns}
      contentHeight={420}
      data={formatData(servicesData, current, perPage)}
      page={current}
      rowKey={(record: any) => record.id + uuid()}
      pageSize={perPage}
      total={count || 0}
      pending={pending}
      filtersInfo={filtersInfo}
      handleFilters={handleFilters}
      clearFilters={clearFilters}
      handleTableChange={handleTableChange}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      isRowClick={true}
      rowClickHandler={onRowClick}
      xValue="100%"
    />
  );
};

export default withStyles(styles)(ServicesTable);
