import { Modal, notification } from 'antd';
import history from '../../../../routes/history';
import { ROUTES } from '../../../../constants/routes';

type IError = {
  field?: string;
  code: string;
};

export const setAsyncError = (errors: IError[], form: any, asyncErr, nextStep?: any, t?: any) => {
  const { setFields, getFieldValue } = form;
  errors &&
    errors.forEach((el: IError) => {
      // for existed account
      if (el.code === 'ACCOUNT_ALREADY_EXISTS_FOR_ANOTHER_PARTNER') {
        return Modal.confirm({
          okText: 'Так',
          cancelText: 'Hі',
          centered: true,
          mask: true,
          maskClosable: true,
          title: t('signup.registered_another_company'),
          content: t('signup.want_continue_registration'),
          onOk: nextStep,
          onCancel: () => {
            const code = localStorage.getItem('companyCode');
            history.push(code ? ROUTES.AUTH.LOGIN : ROUTES.PARTNER_AUTH.LOGIN);
          },
        });
      }

      if (el.code === 'ACCOUNT_ALREADY_EXISTS') {
        return notification.error({
          message:
            'Такой пользователь уже есть в системе. Забыли пароль? Воспользуйтесь функцией восстановления пароля',
        });
      }

      if (el.code === 'EMAIL_ALREADY_REGISTERED_FOR_ANOTHER_USER') {
        return notification.error({ message: 'Данный емейл уже используется другим пользователем' });
      }

      if (el.code === 'INVALID_PHONE_NUMBER_FORMAT') {
        return notification.error({ message: 'Данный емейл уже используется другим пользователем' });
      }

      if (el.field) {
        // other validation errors
        if (el.field && asyncErr(t)[el.field]) {
          setFields({
            [el.field]: {
              value: form.getFieldValue(el.field!),
              errors: [new Error(asyncErr(t)[el.field][el.code])],
            },
          });

          // if we do not have el.field
        } else if (
          (el.field && !asyncErr(t)[el.field] && asyncErr(t)[el.code]) ||
          (!el.field && el.code && asyncErr(t)[el.code])
        ) {
          if (!asyncErr(t)[el.code].field) {
            return notification.error({ message: asyncErr(t)[el.code].message || asyncErr(t)[el.code] });
          }
          setFields({
            [asyncErr(t)[el.code].field]: {
              value: getFieldValue(asyncErr(t)[el.code].field),
              errors: [new Error(asyncErr(t)[el.code].message)],
            },
          });
        }
      }
    });
};
