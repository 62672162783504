import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { notification, Row, Col, Modal } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import { Password as PasswordField } from 'components/nextRedesign/Password/Password';
import Button from 'components/nextRedesign/Button/Button';
import { apiContracts } from 'api/contracts';
import { breakpoints } from 'styles/breakpoints';

import { schema, defaultValues, Values } from './Password.schema';
import { State } from '../ClientPasswordRecovery';
import Success from '../Success/Success';

const cssWrapper = css`
  max-width: 400px;
  @media (max-width: ${breakpoints.small}) {
    max-width: 300px;
  }
`;
const cssSubmit = css`
  margin-top: 40px;
  width: 100%;
`;

type Props = {
  state: State;
  setState: React.Dispatch<Partial<State>>;
};

const Password = ({ state, setState }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const watchedFields = watch();
  const isAnyFieldEmpty = Object.values(watchedFields).every((value) => !value);

  const onSubmit = (values: Values) => {
    setLoading(true);
    apiContracts.userController
      .resetPassword({
        token: state.token,
        newPassword: values.password,
        confirmNewPassword: values.confirmPassword,
      })
      .then(() => {
        setState({ isSuccessModalOpen: true });
      })
      .catch((error) => {
        notification.error({ message: t('signup.something_went_wrong_login') });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={cssWrapper}>
      <form onSubmit={handleSubmit(onSubmit)} data-test="form">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Controller
              name="password"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <PasswordField
                  name={name}
                  errors={errors}
                  value={value}
                  onChange={(v) => onChange(v)}
                  label={t('signup.password')}
                  colorBase="grey"
                  placeholder={t('log_in.enter_your_password')}
                />
              )}
            />
          </Col>

          <Col span={24}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <PasswordField
                  name={name}
                  errors={errors}
                  value={value}
                  onChange={(v) => onChange(v)}
                  label={t('signup.repeat_password')}
                  colorBase="grey"
                  placeholder={t('log_in.enter_your_password')}
                />
              )}
            />
          </Col>
        </Row>

        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className={cssSubmit}
          loading={loading}
          disabled={isAnyFieldEmpty}
        >
          {t('signup.confirm')}
        </Button>
      </form>
      <Modal
        footer={null}
        maskClosable={false}
        closable={false}
        destroyOnClose
        open={state.isSuccessModalOpen}
        onCancel={() => setState({ isSuccessModalOpen: false })}
        width={650}
        centered
      >
        <Success />
      </Modal>
    </div>
  );
};

export default Password;
