import i18n from 'translations/i18n';
import { refiner, object, Infer, string, nonempty } from 'utils/struct';

export const schema = object({
  token: refiner(nonempty(string()), (value) => {
    if (!/^[A-HJ-NPR-Z]{2}[0-9]{4}$/.test(value)) {
      return i18n.t('modal.not_the_correct_format').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  token: '',
};
