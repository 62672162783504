export default {
  ROLE_PARTNER_MANAGER: {
    // 2 vodafone manager

    // Услуги
    CHATS: {
      READ: false,
      BUTTONS: false,
      MENU: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    DASHBOARD: {
      ANALYTICS: true,
      EXTEND: true,
      READ: true,
      MEDICINE: false,
    },
    SEARCH: {
      READ: true,
      CONTRACT: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: false,
      INSURANCE: true,
    },
    INSURANCE: {
      READ: true,
      CREATE: true,
      PRINT: true,
      PAYMENT_DURATION: true,
    },
    TERMINATION: {
      READ: false,
      CREATE: false,
      PRINT: false,
      PAYOUT: false,
    },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: false,
      CREATE_SIDE_MENU_AND_CONTRACT_LIST: false,
      SEND_EMAIL_PAYMENT: true,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: true,
    },
    CONTRACTS_LIST_TOTAL: { READ: true },
    ACTS: {
      READ: false,
      PRINT: false,
      CREATE: false,
    },
    INSURANCE_LIST: {
      READ: true,
      PRINT: true,
      DEFAULT_PARTNER_SELECTED: true,
    },
    INSURANCE_DETAILS: {
      READ: false,
      PRINT: false,
    },
    TERMINATIONS_LIST: {
      READ: false,
      PRINT: false,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
    },
    USERS_ACTIVITIES: {
      READ: false,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SALE_POINTS: {
      READ: true,
    },
  },

  ROLE_INSURANCE_MANAGER: {
    // 4 менеджер ск

    // Услуги
    CHATS: {
      READ: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    DASHBOARD: {
      ANALYTICS: true,
      EXTEND: true,
      READ: true,
      MEDICINE: false,
      SETTLEMENT: true,
    },
    ADMIN_PANEL: {
      READ: true,
      USER_CARD: true,
    },
    SEARCH: {
      READ: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: false,
      INSURANCE: true,
    },
    // довідник брендів
    BRAND_PAGE: { READ: true },
    TERMINATION: {
      READ: true,
      CREATE: true,
      PRINT: true,
      PAYOUT: true,
    },
    INSURANCE: {
      READ: false,
      CREATE: false,
      PRINT: false,
      PAYMENT_DURATION: true,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: true,
    },
    CONTRACTS_LIST_TOTAL: { READ: true },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: false,
      WITHDRAWAL: true,
      RECONCILIATION: true,
      EDIT: false,
      CREATE_SIDE_MENU_AND_CONTRACT_LIST: false,
      SEND_EMAIL_PAYMENT: true,
    },
    CONTRACT_VZR_SUB_AGREEMENT_DRAFT: {
      SIGN: true,
    },
    CONTRACT_OSAGO: {
      RE_REGISTARTION: true,
    },
    CONTRACT_OSAGO_RE_REGISTRATION: {
      SIGN: true,
    },
    ACTS: {
      READ: true,
      PRINT: true,
      CREATE: true,
    },
    TERMINATIONS_LIST: {
      READ: true,
      PRINT: true,
    },
    INSURANCE_LIST: {
      READ: false,
      PRINT: false,
    },
    INSURANCE_DETAILS: {
      READ: false,
      PRINT: false,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
    },
    USERS_ACTIVITIES: {
      READ: true,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SALE_POINTS: {
      READ: true,
    },
    SUPPLEMENTARY_AGREEMENT: {
      EDIT: true,
    },
  },

  ROLE_INSURANCE_CLAIM_MANAGER: {
    //5 менеджер ск по выплатам

    // Услуги
    CHATS: {
      READ: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    ADMIN_PANEL: {
      READ: true,
      USER_CARD: false,
    },
    DASHBOARD: {
      ANALYTICS: true,
      EXTEND: true,
      READ: true,
      MEDICINE: false,
      SETTLEMENT: true,
    },
    SEARCH: {
      READ: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true, //?
      INSURANCE: true,
    },
    EXPRESSPAYMENT: {
      PAY: true,
      DELAY: true,
      ALERT: false,
    },
    INSURANCE: {
      READ: true,
      CREATE: true,
      PRINT: true,
      PAYMENT_DURATION: true,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: false,
    },
    CONTRACTS_LIST_TOTAL: { READ: true },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: false,
      RECONCILIATION: false,
      CREATE_SIDE_MENU_AND_CONTRACT_LIST: false,
    },
    INSURANCE_LIST: {
      READ: true,
      PRINT: true,
    },
    INSURANCE_DETAILS: {
      READ: true,
      PRINT: true,
    },
    TERMINATION: {
      READ: true,
      CREATE: true,
      PRINT: true,
      PAYOUT: true,
    },
    ACTS: {
      READ: false,
      PRINT: false,
      CREATE: false,
    },
    TERMINATIONS_LIST: {
      READ: true,
      PRINT: true,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
    },
    USERS_ACTIVITIES: {
      READ: false,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SALE_POINTS: {
      READ: true,
    },
    INSURED_EVENT: {
      REDIRECT_TO_INSURANCE: true,
    },
  },

  ROLE_HR_MANAGER: {
    CHATS: {
      READ: false,
      BUTTONS: false,
      MENU: false,
    },
    SUBLIMITS: {
      READ: false,
    },
    DASHBOARD: {
      READ: true,
      ANALYTICS: false,
      MEDICINE: true,
      SETTLEMENT: false,
    },
    TERMINATION: {
      READ: true,
      CREATE: true,
      PRINT: true,
      PAYOUT: false,
    },
    TERMINATIONS_LIST: {
      READ: true,
      PRINT: true,
    },
    INSURANCE: {
      READ: false,
      CREATE: false,
      PRINT: false,
    },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: true,
      EDIT: true,
      CREATE_SIDE_MENU_AND_CONTRACT_LIST: true,
    },
    CONTRACT_VZR_DRAFT: {
      SIGN: true,
    },
    CONTRACT_VZR_SUB_AGREEMENT_DRAFT: {
      SIGN: true,
    },
    SUPPLEMENTARY_AGREEMENT: {
      EDIT: false,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: true,
    },
    CONTRACTS_LIST_TOTAL: { READ: false },
    SEARCH: {
      READ: true,
      CONTRACT: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true,
      INSURANCE: true,
    },
    EXPRESSPAYMENT: {
      PAY: false,
      ALERT: true,
    },
    ACTS: {
      READ: false,
      PRINT: false,
      CREATE: false,
    },
    INSURANCE_LIST: {
      READ: false,
      PRINT: false,
      DEFAULT_PARTNER_SELECTED: true,
    },
    INSURANCE_DETAILS: {
      READ: false,
      PRINT: false,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
      LOCALITY: true,
      EMAIL: true,
      PHONE: true,
      INSURANCE_COMPANY: true,
      INSURANCE_COMPANY_VISIBILITY: false,
      PARTNER_COMPANY: true,
      REGION: false,
      ROLE_TOGGLE: true,
      INSURANCE_PROGRAMS: true,
      USER_ACTIVITY: true,
      ROLES_LIST: true,
    },
    USERS_ACTIVITIES: {
      READ: false,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    SALE_POINTS: {
      READ: true,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SIGN_DASHBOARD_CONTRACT: {
      HIDE: true,
    },
  },

  ROLE_PARTNER_ASSISTANCE: {
    // Услуги
    CHATS: {
      READ: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    ADMIN_PANEL: {
      READ: true,
      USER_CARD: false,
    },
    DASHBOARD: {
      ANALYTICS: true,
      EXTEND: true,
      READ: true,
      MEDICINE: true,
      SETTLEMENT: false,
    },
    SEARCH: {
      READ: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true, //?
      INSURANCE: true,
    },
    EXPRESSPAYMENT: {
      PAY: true,
      DELAY: true,
      ALERT: false,
    },
    INSURANCE: {
      READ: true,
      CREATE: true,
      PRINT: true,
      PAYMENT_DURATION: true,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: false,
    },
    CONTRACTS_LIST_TOTAL: { READ: true },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: false,
      RECONCILIATION: false,
      CREATE_SIDE_MENU_AND_CONTRACT_LIST: false,
    },
    INSURANCE_LIST: {
      READ: true,
      PRINT: true,
    },
    INSURANCE_DETAILS: {
      READ: true,
      PRINT: true,
    },
    TERMINATION: {
      READ: false,
      CREATE: false,
      PRINT: false, //?
      PAYOUT: false,
    },
    ACTS: {
      READ: false,
      PRINT: false,
      CREATE: false,
    },
    TERMINATIONS_LIST: {
      READ: false,
      PRINT: false,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
    },
    USERS_ACTIVITIES: {
      READ: false,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: true,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SALE_POINTS: {
      READ: true,
    },
  },
};
