import React from 'react';
import Icon from '@ant-design/icons';
import { IconProps } from '@ant-design/compatible/lib/icon';

import { ReactComponent as Tile } from './tile.svg';
import { ReactComponent as Rows } from './rows.svg';
import { ReactComponent as Circle } from './circle.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Cart } from './cart.svg';
import { ReactComponent as Dots } from './dots.svg';
import { ReactComponent as Coronavirus } from './coronavirus.svg';
import { ReactComponent as LogoInrisk } from './logoInrisk.svg';
import { ReactComponent as Briefcase } from './briefcase.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Security } from './security.svg';
import { ReactComponent as UserShield } from './user-shield.svg';
import { ReactComponent as UserGroup } from './user-group.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as Email } from '../../next/Icon/email.svg';

export type Type =
  | 'tile'
  | 'rows'
  | 'circle'
  | 'email'
  | 'phone'
  | 'cart'
  | 'dots'
  | 'briefcase'
  | 'user'
  | 'security'
  | 'userShield'
  | 'userGroup'
  | 'trash'
  | 'add'
  | 'check'
  | 'warning'
  | 'filter'
  | 'close'
  | 'clock';

// TODO front add tree-shaking
export const iconMap: Record<Type, (props: IconProps) => JSX.Element> = {
  tile: (props) => <Icon component={Tile} {...props} />,
  rows: (props) => <Icon component={Rows} {...props} />,
  circle: (props) => <Icon component={Circle} {...props} />,
  email: (props) => <Icon component={Email} {...props} />,
  phone: (props) => <Icon component={Phone} {...props} />,
  cart: (props) => <Icon component={Cart} {...props} />,
  dots: (props) => <Icon component={Dots} {...props} />,
  briefcase: (props) => <Icon component={Briefcase} {...props} />,
  user: (props) => <Icon component={User} {...props} />,
  security: (props) => <Icon component={Security} {...props} />,
  userShield: (props) => <Icon component={UserShield} {...props} />,
  userGroup: (props) => <Icon component={UserGroup} {...props} />,
  trash: (props) => <Icon component={Trash} {...props} />,
  add: (props) => <Icon component={Add} {...props} />,
  check: (props) => <Icon component={Check} {...props} />,
  warning: (props) => <Icon component={Warning} {...props} />,
  filter: (props) => <Icon component={Filter} {...props} />,
  close: (props) => <Icon component={Close} {...props} />,
  clock: (props) => <Icon component={Clock} {...props} />,
};

export const LogoInriskIcon = (props: IconProps) => <Icon component={LogoInrisk} {...props} />;
