import React from 'react';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

const cssSteps = css`
  color: var(--ant-primary-color);
`;

interface Props {
  step: number;
  steps: number;
}

const Step = ({ step, steps }: Props) => {
  const { t } = useTranslation();

  return <span className={cssSteps}>{`${t('signup.step')} ${step + 1}/${steps - 1}`}</span>;
};

export default Step;
