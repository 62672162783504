import { object, Infer, optional, number, nullable } from 'utils/struct';

export const schema = object({
  insuranceCompanyId: optional(nullable(number())),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  insuranceCompanyId: null,
};
