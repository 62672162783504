import CreateInstance from './axios/axios';
import { AxiosResponse } from 'axios';
import moment from 'moment-timezone';

import { IInsuranceParams } from 'types/registerTypes';
import { filtersDefaultValue } from 'screens/RegisterOfTypes/utils';

const configApi = CreateInstance('CONFIGURATION');

export const getinsuranceRules = async (): Promise<AxiosResponse> => {
  return await configApi.get(`/insurance-rules`);
};

export const getinsuranceTypes = async (params: IInsuranceParams): Promise<AxiosResponse> => {
  return await _getinsuranceTable({ ...params, path: 'insurance-types' });
};

export const getinsuranceRisks = async (params: IInsuranceParams): Promise<AxiosResponse> => {
  return await _getinsuranceTable({ ...params, path: 'risks' });
};

const _getinsuranceTable = async ({
  isObligatory,
  ruleId,
  companyId,
  path,
  insuranceTypeId,
  pageSize,
  page,
  sorting,
}: IInsuranceParams) => {
  const params = {
    count: true,
    page_size: pageSize,
    page,
  };

  if (!sorting?.direction) {
    params['sorting'] = 'id';
  } else if (sorting.direction === 'DESC') {
    params['sorting'] = '-id';
  } else if (sorting.direction === 'ASC') {
    params['sorting'] = 'id';
  }

  if (isObligatory !== undefined && isObligatory !== filtersDefaultValue) {
    params['isObligatory'] = isObligatory;
  }

  if (!!ruleId && ruleId !== filtersDefaultValue) {
    params['insuranceRuleId'] = ruleId;
  }

  if (!!companyId && companyId !== filtersDefaultValue) {
    params['insuranceCompanyId'] = companyId;
  }

  if (!!insuranceTypeId && insuranceTypeId !== filtersDefaultValue) {
    if (path === 'risks') {
      params['insuranceTypeId'] = insuranceTypeId;
    } else {
      params['id'] = insuranceTypeId;
    }
  }

  return await configApi.get(`${path}/all`, {
    params: {
      ...params,
    },
  });
};

export const createInsuranceTypes = async (body): Promise<AxiosResponse> => {
  return await configApi.post(`/insurance-types`, body);
};

export const updateInsuranceTypes = async (body, id): Promise<AxiosResponse> => {
  return await configApi.put(`/insurance-types/${id}`, body);
};
export const updateInsuranceRisks = async (body, id): Promise<AxiosResponse> => {
  return await configApi.put(`/risks/${id}`, body);
};

export const deleteInsuranceTypes = async (id: number): Promise<AxiosResponse> => {
  return await configApi.delete(`/insurance-types/${id}`);
};

export const createInsuranceRisks = async (body): Promise<AxiosResponse> => {
  return await configApi.post(`/risks`, body);
};

export const deleteInsuranceRisks = async (id: number): Promise<AxiosResponse> => {
  return await configApi.delete(`/risks/${id}`);
};

export const downloadInsuranceTypeXSLX = async (filtersInfo: IInsuranceParams): Promise<AxiosResponse<Blob>> => {
  return _downloadXSLX(filtersInfo, '/insurance-types/print/xlsx');
};

export const downloadRisksXSLX = async (filtersInfo: IInsuranceParams): Promise<AxiosResponse> => {
  return _downloadXSLX(filtersInfo, '/risks/print/xlsx');
};

const _downloadXSLX = async (filtersInfo: IInsuranceParams, path: string): Promise<AxiosResponse<Blob>> => {
  const { isObligatory, ruleId, companyId, name } = filtersInfo;

  const timeZone = moment.tz.guess(true);

  const params = {
    timeZone,
    page_size: 300,
  };

  if (isObligatory !== filtersDefaultValue) {
    params['isObligatory'] = isObligatory;
  }

  if (ruleId !== filtersDefaultValue) {
    params['insuranceRuleId'] = ruleId;
  }

  if (companyId !== filtersDefaultValue) {
    params['insuranceCompanyId'] = companyId;
  }

  if (name !== filtersDefaultValue) {
    params['name'] = name;
  }

  return configApi.get(path, {
    responseType: 'arraybuffer',
    params,
  });
};
