import { DocumentTypeCode } from 'types/dto/contracts-service';
import { getUniqueListBy } from 'utils/helpers';
import * as contractsFilesAPI from '../../../api/contracts';
import { IUploadState } from '../../UploadFileComponent/UploadFileComponent';

export const createUploadDevicePhotoArr = (docFiles: IUploadState[], createdId: any) => {
  const code = DocumentTypeCode.PHOTO_DEVICE;
  const arrWithotDublicate = getUniqueListBy(docFiles, 'fileName');
  const arrForUpload = arrWithotDublicate.filter((file) => !Boolean(file.createDate));
  let requestArr: any = [];
  const mainFormData = new FormData();
  Array.from(arrForUpload).forEach((el: any) => {
    mainFormData.append('file', el.file, el.fileName);
  });
  requestArr.push(contractsFilesAPI.documentUpload(Number(createdId), code, mainFormData));

  return [...requestArr];
};
