export default {
  ROLE_PARTNER_SELLER: {
    // Услуги
    CHATS: {
      READ: false,
      BUTTONS: false,
      MENU: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    // 1 seller
    DASHBOARD: {
      READ: true,
      ANALYTICS: false,
      MEDICINE: false,
    },
    TERMINATION: {
      READ: true,
      CREATE: true,
      PRINT: true,
      PAYOUT: false,
    },
    TERMINATIONS_LIST: {
      READ: false,
      PRINT: false,
    },
    INSURANCE: {
      READ: true,
      CREATE: true,
      PRINT: true,
    },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: true,
      EDIT: true,
      CREATE_SIDE_MENU_AND_CONTRACT_LIST: true,
      SEND_EMAIL_PAYMENT: true,
    },
    CONTRACT_VZR_DRAFT: {
      SIGN: true,
    },
    CONTRACT_VZR_SUB_AGREEMENT_DRAFT: {
      SIGN: true,
    },
    CONTRACT_OSAGO: {
      SIGN: true,
      SUB_AGREEMENT: true,
    },
    CONTRACT_OSAGO_RE_REGISTRATION: {
      SIGN: true,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: false,
    },
    CONTRACTS_LIST_TOTAL: { READ: false },
    SEARCH: {
      READ: true,
      CONTRACT: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true,
      INSURANCE: true,
    },
    EXPRESSPAYMENT: {
      PAY: false,
      ALERT: true,
    },
    ACTS: {
      READ: false,
      PRINT: false,
      CREATE: false,
    },
    INSURANCE_LIST: {
      READ: false,
      PRINT: false,
    },
    INSURANCE_DETAILS: {
      READ: true,
      PRINT: true,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
    },
    USERS_ACTIVITIES: {
      READ: false,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SALE_POINTS: {
      READ: false,
    },
    SUPPLEMENTARY_AGREEMENT: {
      EDIT: true,
    },
    VERIFY: {
      HIDE: true,
    },
  },

  ROLE_AGENT_SELLER: {
    // Услуги
    CHATS: {
      READ: false,
      BUTTONS: false,
      MENU: false,
    },
    // Сублимиты
    SUBLIMITS: {
      READ: false,
    },
    // 1 seller
    DASHBOARD: {
      READ: true,
      ANALYTICS: false,
      EXTEND: true,
      MEDICINE: false,
    },
    TERMINATION: {
      READ: true,
      CREATE: false,
      PRINT: false,
      PAYOUT: false,
    },
    TERMINATIONS_LIST: {
      READ: false,
      PRINT: false,
    },
    INSURANCE: {
      READ: false,
      CREATE: false,
      PRINT: false,
    },
    CONTRACT: {
      READ: true,
      PRINT: true,
      CREATE: true,
      EDIT: true,
      CREATE_SIDE_MENU_AND_CONTRACT_LIST: true,
      SEND_EMAIL_PAYMENT: true,
    },
    CONTRACT_VZR_DRAFT: {
      SIGN: true,
    },
    CONTRACT_VZR_SUB_AGREEMENT_DRAFT: {
      SIGN: true,
    },
    CONTRACT_OSAGO: {
      SIGN: true,
      SUB_AGREEMENT: true,
    },
    CONTRACT_OSAGO_RE_REGISTRATION: {
      SIGN: true,
    },
    CONTRACTS_LIST: {
      READ: true,
      PRINT: true,
    },
    CONTRACTS_LIST_TOTAL: { READ: false },
    SEARCH: {
      READ: true,
      CONTRACT: true,
      OFFERTERMS: true,
      EXPRESSPAYMENT: true,
      INSURANCE: true,
    },
    EXPRESSPAYMENT: {
      PAY: false,
      ALERT: true,
    },
    ACTS: {
      READ: false,
      PRINT: false,
      CREATE: false,
    },
    INSURANCE_LIST: {
      READ: false,
      PRINT: false,
    },
    INSURANCE_DETAILS: {
      READ: true,
      PRINT: true,
    },
    HELP: {
      READ: true,
    },
    SETTINGS: {
      READ: true,
    },
    USER_CARD: {
      READ: true,
      NAME: true,
      PASSWORD: true,
    },
    USERS_ACTIVITIES: {
      READ: false,
    },
    DEPARTMENTS: {
      READ: false,
    },
    INSURANCE_PROGRAMS: {
      READ: false,
    },
    ACCESS_POLICY: {
      READ: false,
    },
    INSURANCE_COMPANY: {
      READ: false,
    },
    PROCESS_ANALYTICS: {
      READ: false,
    },
    TELEGRAM: {
      READ: true,
    },
    WIKI: {
      READ: true,
    },
    SALE_POINTS: {
      READ: false,
    },
    SUPPLEMENTARY_AGREEMENT: {
      EDIT: true,
    },
    VERIFY: {
      HIDE: true,
    },
  },
};
