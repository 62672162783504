import CreateIstance from './axios';
import { TStepValues as TInsurerToken } from '../components/auth/SignUp/types';
import onDownload from '../callbacks/onDownload';
import momentTz from 'moment-timezone';
import { UserCredentialsDto, UserCredentialsWithOTP } from 'types/dto/auth-service';
import {
  ResetPasswordDto,
  ClientSignupAccountDto,
  PaymentResultDto,
  PaymentButtonRequestDto,
  InsuranceCompanyBasicInformationDto,
} from 'types/dto/contracts-service';
import { Api } from 'types/dto/auth-service';
import { onFulfilledResponce, onRejectedResponce, onFulfilledRequest, onRejectedRequest } from 'api/axios/axios-next';

export const apiAuth = new Api({
  baseURL: `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_AUTH_SERVICE}`,
});

apiAuth.instance.interceptors.request.use(onFulfilledRequest, onRejectedRequest);
apiAuth.instance.interceptors.response.use(onFulfilledResponce, onRejectedResponce(apiAuth.instance));

const contractApi = CreateIstance('CONTRACTS');

export const checkInsuranceCode = (code: string) => {
  return contractApi.post<void>(`/insurance-companies/code/${code}/validation`);
};

export const login2fa = ({ username, password }: UserCredentialsDto) => {
  const code = localStorage.getItem('companyCode');
  return contractApi.post(`/auth/2fa/login`, { username, password });
};

export const login2faCheck = ({ username, password, id, otp }: UserCredentialsWithOTP & { id: number }) =>
  contractApi.post(`/auth/2fa/check/${id}`, { username, password, otp });

export const sendResetPasswordSms = (login: string, isClient: boolean) => {
  const formData = new FormData();
  formData.append('login', login);
  const code = localStorage.getItem('companyCode');

  return isClient
    ? contractApi.post(`users/clients/password/send-reset-sms?insuranceCompanyCode=${code}`, {
        login,
      })
    : contractApi.post(`/users/password/send-reset-sms`, {
        login,
      });
};

export const verifySmsCode = (login: string, token: string) => {
  return contractApi.post<string>(`/users/password/reset/token`, {
    token,
    login,
  });
};

export const resetPassword = ({ newPassword, confirmNewPassword, token }: ResetPasswordDto) =>
  contractApi.post<void>(`/users/password/reset`, {
    confirmNewPassword,
    newPassword,
    token,
  });

export const validateInsurerUser = (fields: Pick<TInsurerToken, 'phoneNumber'>) => {
  return contractApi.post<void>('/client-signup/validate-account', fields);
};

export const getInsurerSignupToken = (fields: Pick<TInsurerToken, 'phoneNumber'>) => {
  return contractApi.post<void>(`/client-signup/sms-verification?insuranceCompanyCode=`, fields);
};

export const sendInsurerSignupToken = (token: string, fields: Pick<TInsurerToken, 'phoneNumber' | 'token'>) => {
  return contractApi.post<ClientSignupAccountDto>(`/client-signup/sms-verification/check`, fields);
};

export const registerInsurer = (fields: TInsurerToken) => {
  return contractApi.post<void>(`/client-signup/`, fields);
};

export const getExternalContractData = (externalId: number): Promise<any> => {
  return contractApi.get(`/contracts/external/${externalId}`);
};

export const getInsuranceCompanyData = (companyId: number) => {
  return contractApi.get<InsuranceCompanyBasicInformationDto>(`/insurance-companies/basic-info/${companyId}`);
};

export const sendExternalSMS = (externalId: number) => {
  return contractApi.post<void>(`/contracts/external/${externalId}/client-sign-verification`);
};

export const signExternalContract = (externalId: number, token, russianCitizen: boolean) => {
  return contractApi.post<void>(
    `/contracts/external/${externalId}/client-sign?${
      russianCitizen !== undefined ? `russianCitizen=${russianCitizen}&` : ''
    }token=${token}`,
  );
};

export const getFondyData = (externalId: number) => {
  return contractApi.get<PaymentButtonRequestDto>(`/contract-payments/external/generate-payment/${externalId}`);
};

export const getOrderData = (orderId: number) => {
  return contractApi.get<PaymentResultDto>(`/contract-payments/payment-result/${orderId}`);
};

export const getPdfReport: any = (externalId: number, _, t: any) => {
  const timeZone = momentTz.tz.guess(true);
  return onDownload(() =>
    contractApi.get(`/contracts/external/${externalId}/pdf-report`, {
      responseType: 'blob',
      params: {
        timeZone,
      },
    }),
  );
};

export const getOfferFile = async (externalId: number) => {
  const value = await contractApi.get<string>(`/contracts/external/${externalId}/offer-url`, {
    responseType: 'text',
  });
  const link = document.createElement('a');
  link.href = value.data;
  link.click();
};
