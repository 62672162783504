import numeral from 'numeral';
import { Dayjs } from 'dayjs';

import config from 'config';
import { store } from 'store';

export const currencyFormat = (value: number, currency?: string) => {
  const symbol = store.getState().insuranceConfiguration.data?.currency?.symbol;
  const formattedValue = numeral(value).format(config.format.financial);

  return currency ? `${formattedValue} ${currency}` : `${formattedValue} ${symbol ?? '₴'}`;
};

export const percentFormat = (value: number) => `${value.toFixed(2)} %`;

export const dateFormat = (value: Dayjs) => value.format(config.format.date);

export const dateFormatBack = (value: Dayjs) => value.format(config.format.dateBack);
