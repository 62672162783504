import React, { useContext, createContext } from 'react';
import PubNub from 'pubnub';
import { useSelector } from 'react-redux';
import { AppState } from 'store/reducer';

const PubNubCtx = createContext<PubNub>(null);

export const usePubNub = () => {
  return useContext(PubNubCtx);
};

const PubNubProvider: React.FC = ({ children }) => {
  const uuid = useSelector((store: AppState) => store.me.data?.details?.id);

  const pnConfig = {
    uuid,
    publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
    subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  };

  const pubNub = new PubNub(pnConfig);

  const overriddenPubNub = {
    ...(pubNub ?? {}),
    publish: (channel: string) => (message: string | File) => {
      const apiMethod = typeof message === 'string' ? 'publish' : 'sendFile';
      const dataKey = typeof message === 'string' ? 'message' : 'file';
      pubNub[apiMethod]({
        channel,
        [dataKey]: message,
      });
    },
  };

  return <PubNubCtx.Provider value={overriddenPubNub}>{children}</PubNubCtx.Provider>;
};

// Highest Order Component для подключения PubNub (usePubNub используется в
// нескольких хуках)
export const withPubNub = (Component: React.FC<{}>) => () => {
  return (
    <PubNubProvider>
      <Component />
    </PubNubProvider>
  );
};
