import React from 'react';
import styles from './styles.module.less';
import moment from 'moment';
import { IContract } from '../../types/contractDetails';
import { moneyFormatter } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
  contract: {
    data: IContract;
  };
}

export const CollapsedContract: React.FC<IProps> = ({ contract }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.insurance_contract')}</span>
        <span>{contract.data.contractNumber}</span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.object_insurance')}</span>
        <span>
          {contract?.data.contractObjects[0].subtypeName} {contract?.data?.contractObjects[0].brand}{' '}
          {contract.data.contractObjects[0].model}
        </span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.insurer')}</span>
        <span>
          {contract?.data?.signatory.lastName} {contract.data.signatory.firstName} {contract.data.signatory.middleName}
        </span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.identifier')}</span>
        <span>{contract?.data?.contractObjects[0].pid} </span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.insurance_program')}</span>
        <span>{contract?.data?.insuranceProgram.name} </span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.validity')}</span>
        <span>
          {contract?.data?.duration}{' '}
          {contract.data.durationUnit === 'MONTHS' ? t('clientSign.months') : t('clientSign.days')}
        </span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.agreement_with')}</span>
        <span>{moment(contract?.data?.startDate).format('DD.MM.YYYY')}</span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.agreement_on')}</span>
        <span>{moment(contract?.data?.endDate).format('DD.MM.YYYY')}</span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.cost_equipment')}</span>
        <span>{moneyFormatter(contract?.data?.insuranceSum)} ₴</span>
      </div>
      <div className={styles.Client__ContractBlock}>
        <span>{t('clientSign.total_cost_insurance')}</span>
        <span>{moneyFormatter(contract?.data?.paymentAmount)} ₴</span>
      </div>
    </>
  );
};
