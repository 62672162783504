import dayjs from 'dayjs';
import i18n from 'translations/i18n';

import { StatusType } from 'types/dto/contracts-service';
import { object, Infer, optional, date, string, enums, nullable, refiner } from 'utils/struct';

export const schema = object({
  insuranceObjectPid: optional(string()),
  saleRegisterStatus: optional(nullable(enums(Object.values(StatusType)))),
  dateFrom: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  insuranceObjectPid: '',
  saleRegisterStatus: null,
  dateFrom: dayjs().startOf('month'),
  dateTo: dayjs(),
};
