import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18next';

import Routes from '../routes/Routes';
import { AppState } from 'store/reducer';
import { ConfigProvider } from 'antd';
import { langMapToFront } from 'utils/language';

const App: React.FC = () => {
  const currentCurrency = useSelector((store: AppState) => store.insuranceConfiguration.data);
  const pathName = window.location.pathname;
  const whiteLabeling = useSelector((state: AppState) => state.me.whiteLabel?.data?.whiteLabeling);
  const lang = useSelector((state: AppState) => state.me.data?.details?.lang);

  useLayoutEffect(() => {
    ConfigProvider.config({
      theme: {
        primaryColor: whiteLabeling?.color,
      },
    });
  }, [whiteLabeling]);

  useEffect(() => {
    !pathName.includes('contracts-list/contract/') && window.localStorage.removeItem('setSupplementaryAgreement');
  }, [pathName]);

  useEffect(() => {
    if (currentCurrency) {
      // @ts-ignore:next-line
      currentCurrency.currency && sessionStorage.setItem('currentCurrency', currentCurrency.currency.code);
    }
  }, [currentCurrency]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    const locale = langMapToFront[lang];

    i18n.changeLanguage(locale);
  }, [lang]);

  return <Routes />;
};

export default App;
