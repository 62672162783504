import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { CompanyView } from 'types/dto/contracts-service';
import { getDateRange, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/InsurancePrograms/Filters/Filters.schema';
import config from 'config';
import { apiContracts } from 'api/contracts';
import onDownload from 'callbacks/onDownload';
import { apiConfiguration } from 'api/configuration';
import {
  InsuranceProgramView,
  List3Params,
  List4Params,
  ResultListDtoInsuranceProgramView,
} from 'types/dto/configuration-service';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoInsuranceProgramView | null;
}
type Filters = Values & { initialization: boolean };
interface Companies {
  loading: boolean;
  data: CompanyView[];
}
interface Programs {
  loading: boolean;
  data: InsuranceProgramView[];
}
interface InsuranceRules {
  loading: boolean;
  data: InsuranceProgramView[];
}
interface InsuranceTypeNames {
  loading: boolean;
  data: InsuranceProgramView[];
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'INSURANCE_PROGRAMS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'INSURANCE_PROGRAMS/SET_FILTERS', payload } as const),
  setCompanies: (payload: Partial<Companies>) => ({ type: 'INSURANCE_PROGRAMS/SET_COMPANIES', payload } as const),
  setPrograms: (payload: Partial<Programs>) => ({ type: 'INSURANCE_PROGRAMS/SET_PROGRAMS', payload } as const),
  setInsuranceRules: (payload: Partial<InsuranceRules>) =>
    ({ type: 'INSURANCE_PROGRAMS/SET_INSURANCE_RULES', payload } as const),
  setInsuranceTypeNames: (payload: Partial<InsuranceTypeNames>) =>
    ({ type: 'INSURANCE_PROGRAMS/SET_INSURANCE_TYPE_NAMES', payload } as const),
};

export const downloadReport = (): ThunkType<void> => async (dispatch, getState) => {
  const insurancePrograms = getState().insurancePrograms;
  const sorting = getSortBy(insurancePrograms.data.sorting);

  await onDownload(() =>
    apiContracts.insuranceProgramController
      .printXlsxReport1(
        {
          // TODO back
          // @ts-ignore
          timeZone: dayjs.tz.guess(),
          insuranceCompanyId: insurancePrograms.filters.insuranceCompanyId?.toString(),
          id: insurancePrograms.filters.id?.toString(),
          insuranceRules: insurancePrograms.filters.insuranceRules ?? undefined,
          insuranceTypeName: insurancePrograms.filters.insuranceTypeName ?? undefined,
          createdDate: getDateRange(insurancePrograms.filters?.dateFrom, insurancePrograms.filters?.dateTo) ?? '',
          sorting,
          ...getPrivateFilters(insurancePrograms.data.privateFilters),
        },
        { format: 'blob' },
      )
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const requestData =
  (params: List4Params = {}): ThunkType<Promise<ResultListDtoInsuranceProgramView>> =>
  (dispatch, getState) => {
    const insurancePrograms = getState().insurancePrograms;
    const sorting = getSortBy(insurancePrograms.data.sorting);

    return apiConfiguration.insuranceProgramController
      .list4({
        count: true,
        distinct: true,
        attributes:
          'id,code,name,createdDate,startDate,endDate,active,insuranceRules,insuranceTypeName,insuranceCompanyId',
        insuranceCompanyId: insurancePrograms.filters.insuranceCompanyId?.toString(),
        id: insurancePrograms.filters.id?.toString(),
        insuranceRules: insurancePrograms.filters.insuranceRules ?? undefined,
        insuranceTypeName: insurancePrograms.filters.insuranceTypeName ?? undefined,
        createdDate: getDateRange(insurancePrograms.filters?.dateFrom, insurancePrograms.filters?.dateTo) ?? '',
        sorting: sorting ? `${sorting},-id` : '-id',
        ...getPrivateFilters(insurancePrograms.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const getData = (): ThunkType<void> => (dispatch, getState) => {
  const insurancePrograms = getState().insurancePrograms;

  dispatch(actions.setData({ loading: true }));

  dispatch(requestData({ ...getPageBy(insurancePrograms.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export const getCompanies = (): ThunkType<void> => (dispatch, getState) => {
  dispatch(actions.setCompanies({ loading: true }));

  apiContracts.companyController
    .list9({
      distinct: true,
      page_size: -1,
      partnerId: 'null',
    })
    .then((res) => {
      dispatch(actions.setCompanies({ loading: false, data: res.data }));
    })
    .catch(() => {
      dispatch(actions.setCompanies({ loading: false }));
    });
};

export const getPrograms =
  (query: List4Params = {}): ThunkType<void> =>
  (dispatch, getState) => {
    dispatch(actions.setPrograms({ loading: true, data: [] }));

    apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        attributes: 'id,name',
        partnerId: '<>null',
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setPrograms({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPrograms({ loading: false }));
      });
  };

export const getInsuranceRules =
  (value: string): ThunkType<void> =>
  (dispatch, getState) => {
    dispatch(actions.setInsuranceRules({ loading: true }));

    apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        page_size: -1,
        attributes: 'insuranceRules',
        insuranceRules: value,
      })
      .then((res) => {
        dispatch(actions.setInsuranceRules({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setInsuranceRules({ loading: false }));
      });
  };

export const getInsuranceTypeName =
  (value: string): ThunkType<void> =>
  (dispatch, getState) => {
    dispatch(actions.setInsuranceTypeNames({ loading: true }));

    apiConfiguration.insuranceProgramController
      .list4({
        distinct: true,
        page_size: -1,
        attributes: 'insuranceTypeName',
        insuranceTypeName: value,
      })
      .then((res) => {
        dispatch(actions.setInsuranceTypeNames({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setInsuranceTypeNames({ loading: false }));
      });
  };

export const initializeFilters = (): ThunkType<void> => (dispatch, getState) => {
  const insuranceCompany = getState().me.data?.details?.insuranceCompany;

  if (insuranceCompany) {
    dispatch(actions.setFilters({ insuranceCompanyId: insuranceCompany.id }));
    dispatch(
      actions.setCompanies({ data: [{ insuranceCompanyId: insuranceCompany.id, name: insuranceCompany.name }] }),
    );
  }

  dispatch(actions.setFilters({ initialization: false }));

  dispatch(getCompanies());
  dispatch(getPrograms({ insuranceCompanyId: insuranceCompany ? insuranceCompany.id?.toString() : undefined }));
};

export interface StoreInsurancePrograms {
  data: Data;
  filters: Filters;
  companies: Companies;
  programs: Programs;
  insuranceRules: InsuranceRules;
  insuranceTypeNames: InsuranceTypeNames;
}
export const initialState: StoreInsurancePrograms = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'createdDate', order: 'descend' },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  companies: { loading: false, data: [] },
  programs: { loading: false, data: [] },
  insuranceRules: { loading: false, data: [] },
  insuranceTypeNames: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreInsurancePrograms => {
  switch (action.type) {
    case 'INSURANCE_PROGRAMS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'INSURANCE_PROGRAMS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'INSURANCE_PROGRAMS/SET_COMPANIES':
      return { ...state, companies: { ...state.companies, ...action.payload } };
    case 'INSURANCE_PROGRAMS/SET_PROGRAMS':
      return { ...state, programs: { ...state.programs, ...action.payload } };
    case 'INSURANCE_PROGRAMS/SET_INSURANCE_RULES':
      return { ...state, insuranceRules: { ...state.insuranceRules, ...action.payload } };
    case 'INSURANCE_PROGRAMS/SET_INSURANCE_TYPE_NAMES':
      return { ...state, insuranceTypeNames: { ...state.insuranceTypeNames, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
