import React, { useReducer } from 'react';

import Registration from './Registration/Registration';
import Verification from './Verification/Verification';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import Congratulation from './Congratulation/Congratulation';
import { SignupAccountDto } from 'types/dto/contracts-service';

export interface State {
  step: number;
  signupAccount: SignupAccountDto | null;
}

const SignUp = () => {
  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      step: 0,
      signupAccount: null,
    },
  );

  const steps: Array<({ steps }: { steps: number }) => React.ReactElement> = [
    ({ steps }) => <Registration steps={steps} state={state} setState={setState} />,
    ({ steps }) => <Verification steps={steps} state={state} setState={setState} />,
    ({ steps }) => <PersonalInfo steps={steps} state={state} setState={setState} />,
    () => <Congratulation state={state} />,
  ];

  const Step = steps[state.step];

  return <Step steps={steps.length} />;
};

export default SignUp;
