import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { css } from '@emotion/css';
import { Dayjs } from 'dayjs';

import ClientAuthContainer from 'components/nextRedesign/ClientAuthContainer/ClientAuthContainer';
import { breakpoints } from 'styles/breakpoints';

import SignUp from './SignUp/SignUp';
import Password from './Password/Password';

const cssHeader = css`
  &.ant-typography {
    font-size: 44px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: ${breakpoints.small}) {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }
`;

export type Step = 'singup' | 'password';
export interface State {
  step: Step;
  isVerificationModalOpen: boolean;
  isSuccessModalOpen: boolean;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthDate: null | Dayjs;
  locality: null | string;
  street: string;
  password: string;
  confirmPassword: string;
}

const ClientSignUp = () => {
  const { t } = useTranslation();

  const [state, setState] = useReducer(
    (prevState: State, nextState: Partial<State>): State => ({ ...prevState, ...nextState }),
    {
      step: 'singup',
      isVerificationModalOpen: false,
      isSuccessModalOpen: false,
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      birthDate: null,
      locality: null,
      street: '',
      password: '',
      confirmPassword: '',
    },
  );

  const steps: Record<Step, React.ReactNode> = {
    singup: SignUp({ state, setState }),
    password: Password({ state, setState }),
  };

  return (
    <ClientAuthContainer>
      <Typography.Title className={cssHeader}>
        {state.step === 'singup' ? t('signup.signup_to_system') : t('signup.create_your_password')}
      </Typography.Title>

      {steps[state.step]}
    </ClientAuthContainer>
  );
};

export default ClientSignUp;
