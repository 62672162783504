import React from 'react';
import { Tabs as TabsAntd } from 'antd';
import { TabsProps } from 'rc-tabs/lib/Tabs';
import { css, cx } from '@emotion/css';

import palleteRedesign from 'styles/palleteRedesign';

const Tabs = ({ className, ...props }: TabsProps) => {
  return (
    <TabsAntd
      destroyInactiveTabPane
      className={cx(
        css`
          .ant-tabs-nav {
            .ant-tabs-tab {
              margin: 0;
              padding: 9px 16px;
              font-weight: 500;
              color: ${palleteRedesign.gray.light};
            }
          }
        `,
        className,
      )}
      {...props}
    />
  );
};

export default Tabs;
