import { PayoutType, ResolutionType } from 'types/dto/contracts-service';

export interface IServiceCenterInfo {
  name: string;
  address: string;
  phoneNumber: string;
}

interface IDocumentPreviewStorageFile {
  fileName: string;
  createdDate: string;
  fileWrapper: any;
}

export interface IDocumentsPreview {
  type: string;
  storageFiles: IDocumentPreviewStorageFile[];
  documentTypeCode: string;
  storageFileTotalPage: number;
}

export interface IInsuredEventDocument {
  claimId: number;
  obligatory: boolean;
  documentType: {
    id: number;
    name: string;
    code: string;
  };
  id: number;
  information?: any;
  informationObligatory: boolean;
  receiptDate: string;
  claimSum: string | number;
  signDate?: string;
  attachment: boolean;
  calculable: boolean;
  calculationParticipation: boolean;
}

export enum EPaymentType {
  ORDINARY = 'ORDINARY',
  EXPRESS = 'EXPRESS',
}

export enum EPaymentStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface IMiniKaskoClaimInfo {
  mileage: string;
  insuranceReportData: string;
  authoritiesReportData: string;
  secondDriverPhoneNumber: string;
  secondDriverLastName: string;
  secondDriverFirstName: string;
  secondDriverMiddleName: string;
  secondDriverRegionAddress: string;
  secondDriverStreetAddress: string;
  secondDriverExistVehicleDamage: string;
  secondDriverContractNumber: string;
  secondDriverContractDateFrom: string;
  secondDriverContractValidFrom: string;
  secondDriverContractValidTo: string;
  secondDriverInsuranceCompany: string;
  firstDriverPhoneNumber: string;
  firstDriverLastName: string;
  firstDriverFirstName: string;
  firstDriverMiddleName: string;
  firstDriverRegionAddress: string;
  firstDriverStreetAddress: string;
  firstDriverExistVehicleDamage: string;
  firstDriverContractNumber: string;
  firstDriverContractDateFrom: string;
  firstDriverContractValidFrom: string;
  firstDriverContractValidTo: string;
  firstDriverInsuranceCompany: string;
  vehicleDamage: string;
  euroProtocol: boolean;
}

export interface IInsuranceObject {
  registrationNumber?: string | string[] | number;
  manufactureDate?: string;
  id?: number;
  birthday?: string;
  type?: string;
  name?: null | string;
  pid?: null | string;
  model?: null | string;
  brand?: null | string;
}

interface IContract {
  phoneNumber?: string;
  beneficiary?: null | string;
  client: {
    type?: string;
    companyName?: string;
    fullName?: null | string;
    phone?: null | string;
    phoneNumber?: null | string;
    lastName?: null | string;
    firstName?: null | string;
    middleName?: null | string;
    locality?: null | string;
    street?: null | string;
  };
  conclusionDate?: null | string;
  contractNumber?: null | string | number;
  currency?: null | string;
  endDate?: null | string;
  id: null | string | number;
  prolongationStartDate?: string;
  startDate?: null | string;
  status?: null | string;
  paymentStatus?: null | string;
  insuranceProgramCode: string;
  insuranceProgramId: number;
}

interface IOriginalCOntract {
  endDate: string;
  id: number;
  startDate: string;
}

export interface IInsuredEvent {
  politicallySignificant?: boolean;
  recipient?: any;
  phoneNumber?: string;
  pid?: string;
  id?: number;
  claimNumber?: string;
  claimStatus?: string;
  pidVerified?: boolean;
  claimInfo?: IMiniKaskoClaimInfo | any;
  medicalPlace?: string;
  medicalName?: string;
  insuranceCompanyId?: number;
  insObjectId?: number;
  contract: IContract;
  insuranceObject: IInsuranceObject;
  riskId: null | number;
  claimDate: null | string;
  signDate: string | null;
  incidentDate: null | string;
  place: null | string;
  description: null | string;
  damage: null | string;
  participants: null | string;
  guiltyPersons: null | string;
  competentAuthorities: null | string;
  medicalTestPlace: null | string;
  claimSum: null | string | number;
  expertSum: null | number;
  documents: IInsuredEventDocument[];
  paymentRecipient?: {
    code?: string | number;
    accountNumber?: null | string | number;
    accountType?: null | string;
    bankName?: null | string;
    companyName?: null | string;
    counterpartyType?: null | string;
    firstName?: null | string;
    id?: null | string | number;
    lastName?: null | string;
    locality?: null | string;
    middleName?: null | string;
    phoneNumber?: null | string;
    street?: null | string;
    type?: null | string;
  } | null;
  objectRecipient?: {
    code?: string;
    id: number;
    type: string;
    counterpartyType?: string;
    firstName: string;
    middleName: string;
    lastName: string;
    companyName?: null | string;
    destination?: string;
    phoneNumber?: string;
  } | null;
  contactEmail: null | string;
  contactPhone: null | string;
  resolutionType: null | ResolutionType;
  payoutType: null | PayoutType;
  paymentType: null | EPaymentType;
  partnerManagerPhoneNumber: null | string;
  partnerManagerName: null | string;
  claimStatusHistoryDtoList: any[];
  applications?: IApplication[];
  serviceCenter?: IServiceCenterInfo;
  paymentRejectionCause?: IPaymentRejectionCause;
  originalContract?: IOriginalCOntract;
}

export class MiniKaskoClaimInfo implements IMiniKaskoClaimInfo {
  mileage = '';
  insuranceReportData = '';
  authoritiesReportData = '';
  secondDriverPhoneNumber = '';
  secondDriverLastName = '';
  secondDriverFirstName = '';
  secondDriverMiddleName = '';
  secondDriverRegionAddress = '';
  secondDriverStreetAddress = '';
  secondDriverExistVehicleDamage = '';
  secondDriverContractNumber = '';
  secondDriverContractDateFrom = '';
  secondDriverContractValidFrom = '';
  secondDriverContractValidTo = '';
  secondDriverInsuranceCompany = '';
  firstDriverPhoneNumber = '';
  firstDriverLastName = '';
  firstDriverFirstName = '';
  firstDriverMiddleName = '';
  firstDriverRegionAddress = '';
  firstDriverStreetAddress = '';
  firstDriverExistVehicleDamage = '';
  firstDriverContractNumber = '';
  firstDriverContractDateFrom = '';
  firstDriverContractValidFrom = '';
  firstDriverContractValidTo = '';
  firstDriverInsuranceCompany = '';
  vehicleDamage = '';
  euroProtocol = false;
}

export class InsuredEvent implements IInsuredEvent {
  claimInfoMobi = {
    place: '',
    description: '',
    damage: '',
    competentAuthorities: '',
  };
  claimInfoCovid = {
    medicalInstitutionAddress: '',
    medicalInstitutionName: '',
  };
  claimDate = null;
  claimNumber = undefined;
  competentAuthorities = null;
  paymentType = EPaymentType.ORDINARY;
  contactEmail = null;
  contactPhone = null;
  signDate = null;
  contract = {
    beneficiary: null,
    client: {
      fullName: null,
      phone: null,
    },
    conclusionDate: null,
    contractNumber: null,
    currency: null,
    endDate: null,
    id: null,
    startDate: null,
    status: null,
    insuranceProgramCode: '',
    insuranceProgramId: 0,
  };
  damage = null;
  description = null;
  documents = [];
  guiltyPersons = null;
  id = undefined;
  incidentDate = null;
  insuranceObject = {
    birthday: '',
    name: null,
    pid: null,
  };
  medicalTestPlace = null;
  participants = null;
  place = null;
  paymentRecipient = null;
  riskId = null;
  expertSum = null;
  claimSum = null;
  resolutionType = null;
  partnerManagerPhoneNumber = null;
  partnerManagerName = null;
  payoutType = null;
  claimStatusHistoryDtoList = [];
}

export interface IApplication {
  id?: number;
  role: string;
  applicantName: string;
  applicationDate: string;
  details?: string;
  description?: string;
}

export interface IPaymentRejectionCause {
  id: number;
  cause: string;
}

export interface IDocumentTypeCodes {
  claimId: number;
  documentTypeCodes: string[];
}
