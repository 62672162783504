import { createTypes } from 'redux-compose-reducer';
import { Dispatch } from 'redux';
import * as DepartmentsAPI from './../../api/departments';
import { AppState } from '../reducer';
import { getInsuranceProgram } from '../../api/userCard';
import { notification } from 'antd';
import { ROUTES } from '../../constants/routes';
import { adapter } from './fieldsErrors';
import { InsuranceProgramView } from 'types/dto/configuration-service';
import { DepartmentProgramDto } from 'types/dto/contracts-service';

export const preparePrograms = (data: any, status?: boolean | null) => {
  return (
    data &&
    data.map((item: any) => {
      return {
        active: !!status,
        insuranceProgram: item,
        name: item.name,
        activationDate: null,
        deactivationDate: null,
      };
    })
  );
};

export const TYPES = createTypes('departmentDetails', [
  'setLoading',
  'setData',
  'setSwitchValue',
  'populateDepartmentFields',
  'setPrograms',
  'clear',
  'toggleCurrentActiveProgram',
  'setActivationDate',
  'setDeactivationDate',
]);

export const setLoading = (loading: boolean) => ({ type: TYPES.setLoading, payload: loading });

export const fetch = (id: number, t) => async (dispatch: Dispatch, getState: () => AppState) => {
  dispatch(setLoading(true));
  try {
    const { data: department } = await DepartmentsAPI.findById(id);

    if (!(department.departmentPrograms ?? []).length && department?.partner?.id) {
      dispatch(setNewInsurancePrograms(department?.partner?.id, t));
    }

    dispatch({ type: TYPES.setData, payload: department });
  } catch (e) {
    notification.error({
      message: t('popup.loading_unit'),
    });
  }
  dispatch(setLoading(false));
};

export const setSwitchValue = (value: boolean) => ({
  type: TYPES.setSwitchValue,
  payload: value,
});

export const populateDepartmentFields = (values: any) => ({
  type: TYPES.populateDepartmentFields,
  payload: values,
});

const setPrograms = (data: InsuranceProgramView[], status: boolean) => ({
  type: TYPES.setPrograms,
  payload: preparePrograms(data, status),
});

export const toggleProgramsActivity = (data: DepartmentProgramDto[]) => ({
  type: TYPES.setPrograms,
  payload: data,
});

export const setNewInsurancePrograms =
  (partnerId: number, t): any =>
  async (dispatch, getState) => {
    try {
      const { data } = await getInsuranceProgram({ partnerId });
      const { data: list } = getState().departmentDetails;

      dispatch(setPrograms(data.resultList ?? [], list.active));
    } catch (error) {
      notification.error({
        message: t('popup.downloading_insurance_programs'),
        description: t('popup.try_again_products'),
      });
    }
  };

export const onSave =
  (newData, form, t): any =>
  async (dispatch, getState) => {
    const { data } = getState().departmentDetails;
    dispatch(setLoading(true));
    try {
      const { data: department } = await DepartmentsAPI.save({
        ...data,
        ...newData,
      });
      dispatch({ type: TYPES.setData, payload: department });
      window.history.replaceState(
        null,
        '',
        ROUTES.DEPARTMENTS.READ.ROOT.replace(':id', (department.id ?? 0).toString()),
      );

      // history.replace(ROUTES.DEPARTMENTS.READ.ROOT.replace(':id', department.id));
    } catch (e) {
      e?.response?.data?.errors &&
        e.response.data.errors.forEach((el: any) => {
          if (adapter[el.field]) {
            const name = `${adapter[el.field]['name']}`;
            const errorw = adapter[el.field][el.code];
            const value = form.getFieldValue(name);

            return form.setFields({
              [name]: {
                value: value,
                errors: [new Error(errorw)],
              },
            });
          }
        });

      notification.error({
        message: t('popup.saving_unit'),
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const onUpdate =
  (newData, form, t): any =>
  async (dispatch, getState) => {
    const { data } = getState().departmentDetails;
    dispatch(setLoading(true));
    try {
      const { data: department } = await DepartmentsAPI.update({
        ...data,
        ...newData,
      });
      dispatch({ type: TYPES.setData, payload: department });
    } catch (e) {
      e?.response?.data?.errors &&
        e.response.data.errors.forEach((el: any) => {
          if (adapter[el.field]) {
            const name = `${adapter[el.field]['name']}`;
            const errorw = adapter[el.field][el.code];
            const value = form.getFieldValue(name);

            return form.setFields({
              [name]: {
                value: value,
                errors: [new Error(errorw)],
              },
            });
          }
        });
      notification.error({
        message: t('popup.unit_update'),
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const clear = () => ({ type: TYPES.clear });

export const toggleCurrentActiveProgram = (id: number, value: boolean) => ({
  type: TYPES.toggleCurrentActiveProgram,
  payload: { id, value },
});

export const setActivationDate = (id: number, date: string) => ({
  type: TYPES.setActivationDate,
  payload: { id, date },
});
export const setDeactivationDate = (id: number, date: string) => ({
  type: TYPES.setDeactivationDate,
  payload: { id, date },
});
