import i18next from 'i18next';

import { PartnerStatisticsDto } from 'types/dto/contracts-service';

import { Data } from './ByPartnerChart/ByPartnerChart';

export const transformData = (data: PartnerStatisticsDto[]): PartnerStatisticsDto[] => {
  return data.sort((a, b) => (b.contractPaymentSum ?? 0) - (a.contractPaymentSum ?? 0));
};

const TOP = 10;
export const transformDataForChart = (data: PartnerStatisticsDto[]): Data[] => {
  const top: Data[] = data.slice(0, TOP).map((el) => ({
    xField: el.partnerName ?? '',
    yField_1: el.claimPaymentSum ?? 0,
    yField_2: el.contractPaymentSum ?? 0,
    raw: el,
  }));
  const othersRaw = data.slice(TOP).reduce(
    (acc, el) => {
      acc.claimPaymentSum = (acc.claimPaymentSum ?? 0) + (el.claimPaymentSum ?? 0);
      acc.contractPaymentSum = (acc.contractPaymentSum ?? 0) + (el.contractPaymentSum ?? 0);
      acc.countClaims = (acc.countClaims ?? 0) + (el.countClaims ?? 0);
      acc.countContracts = (acc.countContracts ?? 0) + (el.countContracts ?? 0);

      return acc;
    },
    {
      claimPaymentSum: 0,
      contractPaymentSum: 0,
      countClaims: 0,
      countContracts: 0,
    },
  );
  const others = {
    xField: i18next.t('dashboard_extend.OTHER'),
    yField_1: othersRaw.claimPaymentSum ?? 0,
    yField_2: othersRaw.contractPaymentSum ?? 0,
    raw: { ...othersRaw, partnerName: 'OTHER' },
  };

  return data.length > TOP ? [...top, others] : top;
};
