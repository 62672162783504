import createInstance from './axios/axios';
import { IPartner } from '../screens/PartnerCreation/components/PartnerForm/types';
import { IInsCompanies, IPartnerOptionsTab, IPartnerRegionsTab, ISubDivision } from '../store/partner/types';
import { AxiosResponse } from 'axios';
import { ResultListDtoDepartmentView, PartnerRegionDto, AgentDto } from 'types/dto/contracts-service';

const partnersAPI = createInstance('CONTRACTS');

export const getInsuranceCompanies = async (): Promise<AxiosResponse<IInsCompanies[]>> => {
  return partnersAPI.get(`/insurance-companies?distinct`, {
    params: {
      attributes: 'id,name',
      page_size: -1,
    },
  });
};

export const getPartnerOptionsTabData = async (partnerId): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.get(`/partners/${partnerId}/program-parameters`);
};

export const getRegionsData = async (partnerId: number) => {
  return partnersAPI.get<PartnerRegionDto[]>(`/partners/${partnerId}/regions`);
};

export const createRegion = async (partnerId: number, data: IPartnerRegionsTab[]): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.post(`/partners/${partnerId}/regions`, data);
};

export const updateRegion = async (
  partnerId: number,
  data: IPartnerRegionsTab,
  regionId?: number,
): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.put(`/partners/${partnerId}/regions/${regionId}`, data);
};

export const updatePartnerOptionsTabData: any = async (
  partnerId: number,
  data: IPartnerOptionsTab,
): Promise<AxiosResponse<IPartner>> => {
  return partnersAPI.put(`/partners/${partnerId}/program-parameters`, data);
};

export const getAgentsData = async (partnerId: number) => {
  return partnersAPI.get<AgentDto[]>(`/agents`, {
    params: {
      partnerId,
    },
  });
};

export const createSubdivision = async (data): Promise<AxiosResponse<ISubDivision>> => {
  return partnersAPI.post('/departments/import', data);
};

export const getSudivisionsList = async (partnerId: number, params) => {
  return partnersAPI.get<ResultListDtoDepartmentView>(`/departments?partnerId=${partnerId}&count=true`, {
    params: {
      ...params,
    },
  });
};
