import { createTypes } from 'redux-compose-reducer';
import { AppState } from 'store/reducer';
import * as medAppAPI from 'api/institution';
import { notification } from 'antd';

export const TYPES = createTypes('medAppList', [
  'setMedApp',
  'setPagination',
  'serviceTypeServices',
  'removeInstitution',
]);

export const setPagination = (current: number, perPage: number) => (dispatch: any) => {
  dispatch({ type: TYPES.setPagination, payload: { current, perPage } });
};

export const setMedApp = () => async (dispatch: any, getState: any) => {
  const {
    medApp: { pagination },
  }: AppState = getState();
  dispatch({ type: TYPES.setMedApp, pending: true });

  try {
    const response = await medAppAPI.getInstitutions(pagination);
    const payload = response.data ? response.data : [];
    dispatch({ type: TYPES.setMedApp, payload: payload });
    dispatch({
      type: TYPES.setPagination,
      payload: { current: pagination.current, perPage: pagination.perPage },
      pending: false,
    });
  } catch (error) {
    dispatch({ type: TYPES.setMedApp, pending: false });
  }
};

export const addInstitution = (values: any, onSuccess: () => void) => async (dispatch: any) => {
  try {
    await medAppAPI.addInstitution(values);
    onSuccess();
  } catch (err) {
    console.log(err);
  }
};

export const removeInstitution = (id: number) => async (dispatch: any) => {
  try {
    const res = await medAppAPI.removeInstitution(id);
    dispatch({ type: TYPES.removeInstitution, pending: true });
    if (res.status === 200) {
      notification.success({
        message: 'Institution was deleted successfully',
        description: '',
      });
      dispatch({ type: TYPES.removeInstitution, pending: false });
    }
  } catch (err) {
    dispatch({ type: TYPES.removeInstitution, pending: false });
    console.log(err);
  }
};

export const updateInstitution = (values: any, id: number, onSuccess: () => void) => async (dispatch: any) => {
  try {
    await medAppAPI.updateInstitution(values, id);
    onSuccess();
  } catch (err) {
    console.log(err);
  }
};

export const getServiceTypeServices = () => async (dispatch: any) => {
  try {
    const response = await medAppAPI.getServiceTypeServices();
    dispatch({ type: TYPES.serviceTypeServices, payload: response });
  } catch (err) {
    console.log(err);
  }
};
