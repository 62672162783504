import React, { FC, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Spin } from 'antd';
import styles from './style.module.less';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

type IPdfPreview = {
  currentFileUrl: string | null;
  width?: number;
  isEditOpen?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const PdfPreview: FC<IPdfPreview> = ({ isEditOpen, currentFileUrl, width = 350 }) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const changePage = (offset) => setPageNumber((prevPageNumber) => prevPageNumber + offset);

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);
  const { t } = useTranslation();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  if (currentFileUrl) {
    return (
      <div className={styles.pdfPage}>
        <Document
          file={currentFileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{
            cMapUrl: 'cmaps/',
            cMapPacked: true,
          }}
          renderMode="canvas"
          loading={<Spin />}
          // width={350}
          // height={350}
        >
          <Page
            height={150}
            pageNumber={pageNumber}
            className={cs(
              styles.pdfPage__page,
              isEditOpen && {
                [styles.pdfPage__page__modalOpen]: true,
              },
            )}
            width={isEditOpen ? 600 : width}
          />
          {isEditOpen && (
            <div className={styles.pdfPage__buttons}>
              <Button disabled={pageNumber <= 1} onClick={previousPage}>
                {t('details_card.previous_button_pdf')}
              </Button>
              <b>
                {pageNumber}/{numPages}
              </b>
              <Button disabled={pageNumber >= numPages} onClick={nextPage}>
                {t('details_card.next_button_pdf')}
              </Button>
            </div>
          )}
        </Document>
      </div>
    );
  }

  return null;
};

export default PdfPreview;
