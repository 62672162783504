import { useState, useEffect } from 'react';
import { cloneDeep, isEqual } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { useData } from './useData';
import { Values } from 'types/appointments';
import { RawService } from 'types/institutions';
import { CONTRACT_STATUS_TYPES } from 'constants/appointments';

// TODO rewrite this using react-hook-form

export const defaultValues: Values = {
  reason: '',
  wishes: '',
  clinic: {
    id: -1,
    legalName: '',
    city: '',
    street: '',
    locality: '',
    houseNumber: '',
    isOnline: false,
    propertyType: '',
    coverage: null,
  },
  type: {
    id: -1,
    name: '',
    parentId: -1,
  },
  dateSlot: {
    date: undefined,
    time: {
      start: undefined,
      end: undefined,
    },
  },
  dateSlotDrEleks: {
    timeTableEndTime: '',
    timeTableStartTime: '',
    userRef: '',
    venueRef: -1,
    date: undefined,
    time: {
      start: undefined,
      end: undefined,
    },
  },
  service: {
    id: -1,
    name: '',
    parentId: -1,
  },
  serviceDrEleks: {
    serviceID: -1,
    serviceName: '',
    serviceDesc: '',
    servicePriceRate: 0,
  },
  contract: {
    contractNumber: '',
    contractId: -1,
    status: null,
  },
  doctor: {
    userLogin: '',
    userName: '',
    userRef: '',
  },
  attributes: {},
};

export const useForm = (attrs?: Record<string, any>) => {
  const { t } = useTranslation();

  const defaultContract = localStorage.getItem('defaultContract');

  const data = useData();

  const [contract, setContract] = useState(defaultValues.contract);
  const [reason, setReason] = useState(defaultValues.reason);
  const [wishes, setWishes] = useState(defaultValues.wishes);
  const [clinic, setClinic] = useState(cloneDeep(defaultValues.clinic));
  const [type, setType] = useState(cloneDeep(defaultValues.type));
  const [dateSlot, setDateSlot] = useState(cloneDeep(defaultValues.dateSlot));
  const [dateSlotDrEleks, setDateSlotDrEleks] = useState(cloneDeep(defaultValues.dateSlotDrEleks));
  const [service, setService] = useState<RawService>(cloneDeep(defaultValues.service));
  const [serviceDrEleks, setServiceDrEleks] = useState(cloneDeep(defaultValues.serviceDrEleks));
  const [doctor, setDoctor] = useState(cloneDeep(defaultValues.doctor));
  const [attributes, setAttributes] = useState(attrs ? cloneDeep(attrs) : {});
  const [isDoctorEleksForm, setIsDoctorEleksForm] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (defaultContract) {
      setContract({
        contractNumber: JSON.parse(defaultContract).contractNumber,
        contractId: JSON.parse(defaultContract).contractId,
        status: JSON.parse(defaultContract).status,
      });
    }
  }, []);

  useEffect(() => {
    if (!defaultContract && data.contracts.length) {
      const statusType = data.contracts[0]?.contractStatus;

      const statusCheck = CONTRACT_STATUS_TYPES.find(({ status }) => status === statusType);
      setContract({
        contractNumber: data.contracts[0]?.contractNumber || '',
        contractId: data.contracts[0]?.contractId || -1,
        status: {
          statusType: statusType || '',
          translation: statusCheck ? t(statusCheck.translationKey) : '',
        },
      });
    }
  }, [defaultContract, data]);

  const matcher = {
    reason: {
      value: reason,
      set: setReason,
    },
    wishes: {
      value: wishes,
      set: setWishes,
    },
    clinic: {
      value: clinic,
      set: setClinic,
    },
    type: {
      value: type,
      set: setType,
    },
    dateSlot: {
      value: dateSlot,
      set: setDateSlot,
    },
    contract: {
      value: contract,
      set: setContract,
    },
    service: {
      value: service,
      set: setService,
    },
    serviceDrEleks: {
      value: serviceDrEleks,
      set: setServiceDrEleks,
    },
    doctor: {
      value: doctor,
      set: setDoctor,
    },
    dateSlotDrEleks: {
      value: dateSlotDrEleks,
      set: setDateSlotDrEleks,
    },
    attributes: {
      value: attributes,
      set: setAttributes,
    },
  };

  const values = {
    reason,
    wishes,
    type,
    dateSlot,
    clinic,
    contract,
    service,
    serviceDrEleks,
    doctor,
    dateSlotDrEleks,
    attributes,
  };

  const validate = () => {
    const errsKeys: (keyof typeof matcher)[] = [];
    if (!reason || reason.length <= 20) {
      errsKeys.push('reason');
    }
    if (isEqual(clinic, defaultValues.clinic)) {
      errsKeys.push('clinic');
    }
    if (isEqual(type, defaultValues.type)) {
      errsKeys.push('type');
    }
    if (!isDoctorEleksForm && isEqual(service, defaultValues.service)) {
      errsKeys.push('service');
    }
    if (isDoctorEleksForm && isEqual(serviceDrEleks, defaultValues.serviceDrEleks)) {
      errsKeys.push('serviceDrEleks');
    }
    if (isDoctorEleksForm && isEqual(doctor, defaultValues.doctor)) {
      errsKeys.push('doctor');
    }
    if (!isDoctorEleksForm && isEqual(dateSlot, defaultValues.dateSlot)) {
      errsKeys.push('dateSlot');
    }
    if (!isDoctorEleksForm && (dateSlot.date === undefined || dateSlot.time.start === undefined)) {
      errsKeys.push('dateSlot');
    }
    if (isDoctorEleksForm && isEqual(dateSlotDrEleks, defaultValues.dateSlotDrEleks)) {
      errsKeys.push('dateSlotDrEleks');
    }
    if (isDoctorEleksForm && (dateSlotDrEleks.date === undefined || dateSlotDrEleks.time.start === undefined)) {
      errsKeys.push('dateSlotDrEleks');
    }
    setErrors((err) => ({ ...err, ...errsKeys.reduce((err, key) => ((err[key] = true), err), {}) }));
    return errsKeys.length === 0;
  };

  const setField = (key: keyof typeof matcher, value: any) => {
    matcher[key].set(value);
  };

  const handleSubmit = (handlerFn: (formData: Values) => void) => {
    if (validate()) {
      handlerFn(values);
    }
  };

  const clearError = (key: keyof typeof matcher) => {
    setErrors((err) => ({ ...err, [key]: false }));
  };

  return {
    values,
    setField,
    errors,
    handleSubmit,
    data,
    clearError,
    defaultValues,
    setIsDoctorEleksForm,
  };
};
