import { notification } from 'antd';
import { getInsTypesData } from 'api/insuranceProgramCreate';
import { getCompanies } from 'api/insurancePrograms';
import * as registerApi from 'api/registerTypes';
import { Action, Dispatch } from 'redux';
import { createTypes } from 'redux-compose-reducer';
import { ThunkAction } from 'redux-thunk';
import { TABS } from 'screens/RegisterOfTypes/utils';
import { AppState } from 'store/reducer';
import {
  IInsuranceParams,
  InsuranceRisksTable,
  InsuranceTypesTable,
  IRegisterTypesOption,
  ISorting,
} from 'types/registerTypes';
import { StoreType } from 'types/store';
import onDownload, { Callback } from '../../callbacks/onDownload';

export const TYPES = createTypes('registerTypes', [
  'setInsuranceCompanies',
  'setInsuranceRules',
  'setInsuranceTypesData',
  'setInsuranceRisksData',
  'setActiveTab',
  'setInsTypesList',
  'setInsuranceTypesPage',
  'setInsuranceRisksPage',
  'setInsuranceTypesSorting',
  'setInsuranceRisksSorting',
  'setFiltersInfo',
]);
type ThunkResult = ThunkAction<void, StoreType, null, Action>;

export const setActiveTab =
  (activeTab: string): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: TYPES.setActiveTab, payload: { activeTab } });
    } catch (e) {
      console.error(e);
    }
  };

export const setInsuranceTypesPage =
  (page: number, pageSize: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    _setPage(TYPES.setInsuranceTypesPage, { page, pageSize }, dispatch);
  };

export const setInsuranceRisksPage =
  (page: number, pageSize: number): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    _setPage(TYPES.setInsuranceRisksPage, { page, pageSize }, dispatch);
  };

const _setPage = (type: string, payload: { page: number; pageSize: number }, dispatch: Dispatch) => {
  try {
    dispatch({ type, payload });
  } catch (e) {
    console.error(e);
  }
};

export const getInsuranceCompanies =
  (): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { data } = await getCompanies();
      dispatch({ type: TYPES.setInsuranceCompanies, payload: { data } });
    } catch (e) {
      console.error(e);
    }
  };

export const getinsuranceRules =
  (): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { data } = await registerApi.getinsuranceRules();
      dispatch({ type: TYPES.setInsuranceRules, payload: { data } });
    } catch (e) {
      console.error(e);
    }
  };

export const getinsuranceTypes =
  (): ThunkResult =>
  async (dispatch, getState: () => AppState): Promise<void> => {
    const {
      registerTypes: {
        insuranceTypes: { insuranceTypesSorting, insuranceTypesPage, insuranceTypesPageSize, insuranceTypesTableData },
      },
    } = getState();
    dispatch(
      _getInsuranceTableData(
        TYPES.setInsuranceTypesData,
        registerApi.getinsuranceTypes,
        {
          sorting: insuranceTypesSorting,
          page: insuranceTypesPage,
          pageSize: insuranceTypesPageSize,
        },
        insuranceTypesTableData,
      ),
    );
  };

export const getinsuranceRisks =
  (): ThunkResult =>
  async (dispatch, getState: () => AppState): Promise<void> => {
    const {
      registerTypes: {
        insuranceRisks: { insuranceRisksPage, insuranceRisksPageSize, insuranceRisksSorting, insuranceRisksTableData },
      },
    } = getState();
    dispatch(
      _getInsuranceTableData(
        TYPES.setInsuranceRisksData,
        registerApi.getinsuranceRisks,
        {
          sorting: insuranceRisksSorting,
          page: insuranceRisksPage,
          pageSize: insuranceRisksPageSize,
        },
        insuranceRisksTableData,
      ),
    );
  };

const _getInsuranceTableData =
  (
    type: string,
    callback: (
      params: IInsuranceParams,
    ) => Promise<{ data: { resultList: InsuranceTypesTable | IRegisterTypesOption; count: number } }>,
    params: IInsuranceParams,
    initialValue: InsuranceTypesTable[] | InsuranceRisksTable[],
  ): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
    try {
      const {
        registerTypes: {
          filtersInfo: { companyId, isObligatory, insuranceTypeId, ruleId },
        },
      } = getState();
      dispatch({ type, payload: { data: initialValue, pending: true } });
      const { data } = await callback({
        sorting: params.sorting,
        page: params.page,
        pageSize: params.pageSize,
        companyId,
        ruleId,
        isObligatory,
        insuranceTypeId,
      });
      dispatch({ type, payload: { data: data.resultList || data, count: data.count, pending: false } });
    } catch (e) {
      console.error(e);
    }
  };

export const createInsuranceTypes =
  (createData): ThunkResult =>
  async (dispatch): Promise<void> => {
    try {
      await registerApi.createInsuranceTypes(createData);
      dispatch(getinsuranceTypes());
      dispatch(getinsuranceRules());
    } catch (e) {
      console.error(e);
    }
  };

export const getInsTypesList = (): ThunkResult => async (dispatch: Dispatch) => {
  try {
    const { data } = await getInsTypesData();
    dispatch({ type: TYPES.setInsTypesList, payload: { insTypesList: data } });
  } catch (e) {
    console.error(e);
  }
};

export const createInsuranceRisks =
  (createData): ThunkResult =>
  async (dispatch): Promise<void> => {
    try {
      await registerApi.createInsuranceRisks(createData);
      dispatch(getinsuranceRisks());
    } catch (e) {
      console.error(e);
    }
  };

export const updateInsuranceTypes =
  (t, updateData, id): ThunkResult =>
  async (dispatch): Promise<void> => {
    try {
      await registerApi.updateInsuranceTypes(updateData, id);
      dispatch(getinsuranceTypes());
      dispatch(getinsuranceRules());
    } catch (e) {
      console.error(e);
      _showErrorMessage({
        error: e,
        errorCodes: ['RISK_WITH_CURRENT_INSURANCE_TYPE_EXISTS'],
        errorMessage: t('register_of_types.modal_insurance_types_changing_error'),
      });
    }
  };

export const deleteInsuranceTypes =
  (t, id: number): ThunkResult =>
  async (dispatch): Promise<void> => {
    try {
      await registerApi.deleteInsuranceTypes(id);
      dispatch(getinsuranceTypes());
    } catch (e) {
      console.error(e);
      _showErrorMessage({
        error: e,
        errorCodes: ['RISK_WITH_CURRENT_INSURANCE_TYPE_EXISTS'],
        errorMessage: t('register_of_types.modal_insurance_types_changing_error'),
      });
    }
  };

const _showErrorMessage = ({ error, errorCodes, errorMessage }) => {
  error.response.data.errors?.forEach((el: { code: string }) => {
    if (errorCodes.includes(el.code)) {
      notification.error({
        message: errorMessage,
      });
    }
  });
};

export const updateInsuranceRisks =
  (updateData, id, t): ThunkResult =>
  async (dispatch): Promise<void> => {
    try {
      await registerApi.updateInsuranceRisks(updateData, id);
      dispatch(getinsuranceRisks());
    } catch (e) {
      console.error(e);
      _showErrorMessage({
        error: e,
        errorCodes: ['RISK_IS_IN_USE'],
        errorMessage: t('register_of_types.modal_insurance_risk_changing_error'),
      });
    }
  };

export const deleteInsuranceRisks =
  (id: number, t): ThunkResult =>
  async (dispatch): Promise<void> => {
    try {
      await registerApi.deleteInsuranceRisks(id);
      dispatch(getinsuranceRisks());
    } catch (e) {
      console.error(e);
      _showErrorMessage({
        error: e,
        errorCodes: ['RISK_IS_IN_USE'],
        errorMessage: t('register_of_types.modal_insurance_risk_changing_error'),
      });
    }
  };

export const setSorting =
  (tab: string, sorting: ISorting): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      if (tab === TABS.InsuranceRisks) {
        dispatch({ type: TYPES.setInsuranceRisksSorting, payload: { sorting } });
      } else {
        dispatch({ type: TYPES.setInsuranceTypesSorting, payload: { sorting } });
      }
    } catch (e) {
      console.error(e);
    }
  };

export const setFiltersInfo =
  (filtersInfo: IInsuranceParams): ThunkResult =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: TYPES.setFiltersInfo, payload: filtersInfo });
    } catch (e) {
      console.error(e);
    }
  };

export const downloadRegisterTypeXSLX =
  (isRisks: boolean, t): ThunkResult =>
  async (dispatch): Promise<void> => {
    if (isRisks) {
      dispatch(_downloadXSLX(t, registerApi.downloadRisksXSLX));
    } else {
      dispatch(_downloadXSLX(t, registerApi.downloadInsuranceTypeXSLX));
    }
  };

export const _downloadXSLX =
  (t, callback): ThunkResult =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<Callback> => {
    const {
      registerTypes: { filtersInfo },
    } = getState();
    return onDownload(() => callback(filtersInfo));
  };
