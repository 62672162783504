import React from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import { ROUTES } from 'constants/routes';
import MedAppTable from './components/MedAppTable';
import Button from 'components/Button';
import Plus from 'components/Button/icons/Plus';
import ClinicsFilters from './Filters/Filters';
import history from 'routes/history';

const MedApp = () => {
  const { t } = useTranslation();

  const leadToCompanyConfiguration = (id: number | string) => {
    return history.push(`${ROUTES.MEDAPP.CLINICS.DETAILS.ROOT.replace(':id', `${id}`)}`);
  };

  const handleCreate = () => {
    return history.push(ROUTES.MEDAPP.CLINICS.DETAILS.ROOT.replace(':id', 'new'));
  };

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('clinics.settings'), href: ROUTES.SETTINGS.ROOT },
          {
            label: t('clinics.header'),
          },
        ]}
      />
      <PageHeader title={t('clinics.header')}>
        <Button startIcon={<Plus />} onClick={handleCreate}>
          {t('clinics.add')}
        </Button>
      </PageHeader>
      <ClinicsFilters />
      <MedAppTable leadToCompanyConfiguration={leadToCompanyConfiguration} />
    </>
  );
};

export default MedApp;
