import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageHeader from 'components/PageHeader';
import MedAppDetailsForm from './MedAppDetailsForm';
import { useAsync } from '../../hooks/useAsync';
import { getInstitution } from 'api/institution';

const MedAppDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const institutionId = location.pathname.split('/')[3];
  const isEdit = institutionId !== 'new';

  const { value: institutionData, doFetch: fetchInstitution } = useAsync(getInstitution);

  useEffect(() => {
    if (isEdit) {
      fetchInstitution(institutionId);
    }
  }, []);

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('clinics.settings'), href: ROUTES.SETTINGS.ROOT },
          { label: t('clinics.header'), href: ROUTES.MEDAPP.CLINICS.ROOT },
          {
            label: isEdit ? t('clinics.lpu_data') : t('clinics.lpu_new'),
          },
        ]}
      />
      <PageHeader title={isEdit ? t('clinics.lpu_data_full') : t('clinics.lpu_new_full')} />
      <MedAppDetailsForm institutionData={institutionData} isEdit={isEdit} />
    </>
  );
};

export default MedAppDetails;
