/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface LocalityView {
  /** @format int64 */
  id?: number;
  region?: string;
  district?: string;
  city?: string;
  fullLocation?: string;
}

export interface CountryView {
  /** @format int64 */
  id?: number;
  name?: string;
  nativeName?: string;
  countryCode?: string;
}

export interface ResultListDtoCountryView {
  resultList?: CountryView[];
  /** @format int64 */
  count?: number;
  total?: CountryView;
}

export interface SwaggerResource {
  name?: string;
  url?: string;
  swaggerVersion?: string;
  /** @deprecated */
  location?: string;
}

export enum DocExpansion {
  None = 'none',
  List = 'list',
  Full = 'full',
}

export enum ModelRendering {
  Example = 'example',
  Model = 'model',
}

export enum OperationsSorter {
  Alpha = 'alpha',
  Method = 'method',
}

export enum TagsSorter {
  Alpha = 'alpha',
}

export interface UiConfiguration {
  deepLinking?: boolean;
  displayOperationId?: boolean;
  /** @format int32 */
  defaultModelsExpandDepth?: number;
  /** @format int32 */
  defaultModelExpandDepth?: number;
  defaultModelRendering?: ModelRendering;
  displayRequestDuration?: boolean;
  docExpansion?: DocExpansion;
  filter?: object;
  /** @format int32 */
  maxDisplayedTags?: number;
  operationsSorter?: OperationsSorter;
  showExtensions?: boolean;
  tagsSorter?: TagsSorter;
  validatorUrl?: string;
  /** @deprecated */
  apisSorter?: string;
  /** @deprecated */
  jsonEditor?: boolean;
  /** @deprecated */
  showRequestHeaders?: boolean;
  supportedSubmitMethods?: string[];
}

export interface SecurityConfiguration {
  /** @deprecated */
  apiKey?: string;
  /** @deprecated */
  apiKeyVehicle?: string;
  /** @deprecated */
  apiKeyName?: string;
  clientId?: string;
  clientSecret?: string;
  realm?: string;
  appName?: string;
  scopeSeparator?: string;
  additionalQueryStringParams?: Record<string, object>;
  useBasicAuthenticationWithAccessCodeGrant?: boolean;
}

export interface LocationsParams {
  address: string;
}

export interface PostDepartmentsParams {
  /** @default "" */
  query?: string;
}

export interface ListParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  name?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  nativeName?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  countryCode?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios-next';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'http://test.eks.inrisk.com.ua:443',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title INRISK Location Service
 * @version 0.0.1
 * @baseUrl http://test.eks.inrisk.com.ua:443
 *
 * Location Service
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  locationController = {
    /**
     * No description
     *
     * @tags location-controller
     * @name Locations
     * @summary locations
     * @request GET:/locations
     * @secure
     */
    locations: (query: LocationsParams, params: RequestParams = {}) =>
      this.request<LocalityView[], any>({
        path: `/locations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Security requirements: hasRole('ROLE_ADMIN')
     *
     * @tags location-controller
     * @name UpdateLocations
     * @summary update locations
     * @request POST:/locations
     * @secure
     */
    updateLocations: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/locations`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags location-controller
     * @name PostDepartments
     * @summary post departments
     * @request GET:/locations/post-departments
     * @secure
     */
    postDepartments: (query: PostDepartmentsParams, params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/locations/post-departments`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Security requirements: hasRole('ROLE_ADMIN')
     *
     * @tags location-controller
     * @name UpdatePostDepartments
     * @summary update post departments
     * @request POST:/locations/post-departments
     * @secure
     */
    updatePostDepartments: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/locations/post-departments`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags location-controller
     * @name GetAllLocalities
     * @summary get all localities
     * @request GET:/locations/all
     * @secure
     */
    getAllLocalities: (params: RequestParams = {}) =>
      this.request<LocalityView[], any>({
        path: `/locations/all`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  };
  countriesController = {
    /**
     * @description Query CountryView using dynamic search of TableViewRepository
     *
     * @tags countries-controller
     * @name List
     * @summary list
     * @request GET:/countries
     * @secure
     */
    list: (query: ListParams, params: RequestParams = {}) =>
      this.request<ResultListDtoCountryView, any>({
        path: `/countries`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  apiResourceController = {
    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SwaggerResources
     * @summary swagger resources
     * @request GET:/swagger-resources
     * @secure
     */
    swaggerResources: (params: RequestParams = {}) =>
      this.request<SwaggerResource[], any>({
        path: `/swagger-resources`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SwaggerResources3
     * @summary swagger resources
     * @request PUT:/swagger-resources
     * @secure
     */
    swaggerResources3: (params: RequestParams = {}) =>
      this.request<SwaggerResource[], any>({
        path: `/swagger-resources`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SwaggerResources1
     * @summary swagger resources
     * @request POST:/swagger-resources
     * @secure
     */
    swaggerResources1: (params: RequestParams = {}) =>
      this.request<SwaggerResource[], any>({
        path: `/swagger-resources`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SwaggerResources6
     * @summary swagger resources
     * @request DELETE:/swagger-resources
     * @secure
     */
    swaggerResources6: (params: RequestParams = {}) =>
      this.request<SwaggerResource[], any>({
        path: `/swagger-resources`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SwaggerResources4
     * @summary swagger resources
     * @request OPTIONS:/swagger-resources
     * @secure
     */
    swaggerResources4: (params: RequestParams = {}) =>
      this.request<SwaggerResource[], any>({
        path: `/swagger-resources`,
        method: 'OPTIONS',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SwaggerResources5
     * @summary swagger resources
     * @request HEAD:/swagger-resources
     * @secure
     */
    swaggerResources5: (params: RequestParams = {}) =>
      this.request<SwaggerResource[], any>({
        path: `/swagger-resources`,
        method: 'HEAD',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SwaggerResources2
     * @summary swagger resources
     * @request PATCH:/swagger-resources
     * @secure
     */
    swaggerResources2: (params: RequestParams = {}) =>
      this.request<SwaggerResource[], any>({
        path: `/swagger-resources`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name UiConfiguration
     * @summary ui configuration
     * @request GET:/swagger-resources/configuration/ui
     * @secure
     */
    uiConfiguration: (params: RequestParams = {}) =>
      this.request<UiConfiguration, any>({
        path: `/swagger-resources/configuration/ui`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name UiConfiguration3
     * @summary ui configuration
     * @request PUT:/swagger-resources/configuration/ui
     * @secure
     */
    uiConfiguration3: (params: RequestParams = {}) =>
      this.request<UiConfiguration, any>({
        path: `/swagger-resources/configuration/ui`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name UiConfiguration1
     * @summary ui configuration
     * @request POST:/swagger-resources/configuration/ui
     * @secure
     */
    uiConfiguration1: (params: RequestParams = {}) =>
      this.request<UiConfiguration, any>({
        path: `/swagger-resources/configuration/ui`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name UiConfiguration6
     * @summary ui configuration
     * @request DELETE:/swagger-resources/configuration/ui
     * @secure
     */
    uiConfiguration6: (params: RequestParams = {}) =>
      this.request<UiConfiguration, any>({
        path: `/swagger-resources/configuration/ui`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name UiConfiguration4
     * @summary ui configuration
     * @request OPTIONS:/swagger-resources/configuration/ui
     * @secure
     */
    uiConfiguration4: (params: RequestParams = {}) =>
      this.request<UiConfiguration, any>({
        path: `/swagger-resources/configuration/ui`,
        method: 'OPTIONS',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name UiConfiguration5
     * @summary ui configuration
     * @request HEAD:/swagger-resources/configuration/ui
     * @secure
     */
    uiConfiguration5: (params: RequestParams = {}) =>
      this.request<UiConfiguration, any>({
        path: `/swagger-resources/configuration/ui`,
        method: 'HEAD',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name UiConfiguration2
     * @summary ui configuration
     * @request PATCH:/swagger-resources/configuration/ui
     * @secure
     */
    uiConfiguration2: (params: RequestParams = {}) =>
      this.request<UiConfiguration, any>({
        path: `/swagger-resources/configuration/ui`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SecurityConfiguration
     * @summary security configuration
     * @request GET:/swagger-resources/configuration/security
     * @secure
     */
    securityConfiguration: (params: RequestParams = {}) =>
      this.request<SecurityConfiguration, any>({
        path: `/swagger-resources/configuration/security`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SecurityConfiguration3
     * @summary security configuration
     * @request PUT:/swagger-resources/configuration/security
     * @secure
     */
    securityConfiguration3: (params: RequestParams = {}) =>
      this.request<SecurityConfiguration, any>({
        path: `/swagger-resources/configuration/security`,
        method: 'PUT',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SecurityConfiguration1
     * @summary security configuration
     * @request POST:/swagger-resources/configuration/security
     * @secure
     */
    securityConfiguration1: (params: RequestParams = {}) =>
      this.request<SecurityConfiguration, any>({
        path: `/swagger-resources/configuration/security`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SecurityConfiguration6
     * @summary security configuration
     * @request DELETE:/swagger-resources/configuration/security
     * @secure
     */
    securityConfiguration6: (params: RequestParams = {}) =>
      this.request<SecurityConfiguration, any>({
        path: `/swagger-resources/configuration/security`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SecurityConfiguration4
     * @summary security configuration
     * @request OPTIONS:/swagger-resources/configuration/security
     * @secure
     */
    securityConfiguration4: (params: RequestParams = {}) =>
      this.request<SecurityConfiguration, any>({
        path: `/swagger-resources/configuration/security`,
        method: 'OPTIONS',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SecurityConfiguration5
     * @summary security configuration
     * @request HEAD:/swagger-resources/configuration/security
     * @secure
     */
    securityConfiguration5: (params: RequestParams = {}) =>
      this.request<SecurityConfiguration, any>({
        path: `/swagger-resources/configuration/security`,
        method: 'HEAD',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags api-resource-controller
     * @name SecurityConfiguration2
     * @summary security configuration
     * @request PATCH:/swagger-resources/configuration/security
     * @secure
     */
    securityConfiguration2: (params: RequestParams = {}) =>
      this.request<SecurityConfiguration, any>({
        path: `/swagger-resources/configuration/security`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),
  };
}
