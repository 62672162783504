import React, { FC } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { DocPdfIcon } from 'components/Icons';
import PdfPreview from 'components/PdfPreview';

import styles from './ImageModal.module.less';

type Iprops = {
  visible: boolean;
  fileUrl: string | undefined;
  fileName: string | undefined;
  closeEditModal: () => void;
};

const ImageModal: FC<Iprops> = ({ visible, fileUrl, fileName, closeEditModal }) => {
  const { t } = useTranslation();
  const isFilePdf = fileName ? fileName.includes('.pdf') : false;

  const renderSpinner = () => (
    <div className={styles.Spinner}>
      <Spin />
    </div>
  );

  return (
    <Modal
      width={800}
      visible={visible}
      onCancel={closeEditModal}
      zIndex={100000}
      className={styles.Modal}
      footer={[
        <a href={fileUrl} target="_blank" rel="noopener noreferrer" download>
          <Button type="primary">{t('chats.modal_download')}</Button>
        </a>,
      ]}
    >
      <div>
        <div className={styles.modal__body}>
          {isFilePdf ? (
            fileUrl ? (
              <PdfPreview currentFileUrl={fileUrl} width={150} isEditOpen={visible} />
            ) : (
              <div className={styles.modal__emptyPdf}>{isFilePdf ? <DocPdfIcon /> : renderSpinner()}</div>
            )
          ) : (
            <img className={styles.modal__img} src={fileUrl} alt="file" />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImageModal;
