import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Typography } from 'antd';
import { css } from '@emotion/css';

import locales, { LocaleOptions } from 'config/locales';
import { AppState } from 'store/reducer';
import { Language as ILanguage } from 'types';
import palleteRedesign from 'styles/palleteRedesign';

const cssLanguage = css`
  font-size: 15px;
  color: ${palleteRedesign.basic.black};
`;

const cssLink = css`
  &:hover {
    color: inherit;
  }
`;

type LanguageProps = {
  onChange?: () => void;
};

const Language = ({ onChange }: LanguageProps) => {
  const { i18n } = useTranslation();
  const whiteLabeling = useSelector((store: AppState) => store.me.whiteLabel?.data?.whiteLabeling);

  const whiteLabelingLanguages = whiteLabeling?.language?.length
    ? (whiteLabeling?.language.reduce((acc, lang) => {
        acc[lang] = locales[lang];
        return acc;
      }, {}) as Record<ILanguage, LocaleOptions>)
    : null;

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const changeLocale = (locale: ILanguage) => () => {
    i18n.changeLanguage(locale);
    onChange && onChange();
  };

  const menu = (
    <Menu>
      {Object.entries(whiteLabelingLanguages ?? locales)
        .filter(([locale]) => locale !== i18n.language)
        .map(([locale, options]) => (
          <Menu.Item key={locale} onClick={changeLocale(locale as ILanguage)}>
            {options.title}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={onClick} className={cssLink}>
        <Typography.Text className={cssLanguage}>{locales[i18n.language as ILanguage].titleShort}</Typography.Text>{' '}
        <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default Language;
