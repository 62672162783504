import { createTypes } from 'redux-compose-reducer';
import { notification } from 'antd';
import { getInsuranceCompanies } from '../../api/partnerCreate';
import {
  createProgram,
  deleteRiskData,
  fetchProgramData,
  getTariffData,
  getAgentsData,
  getInsRulesData,
  getInsTypesData,
  getManagersData,
  getProgramsTypeData,
  getRiskListData,
  saveRisksData,
  updateProgram,
  updateRisksData,
  createTariffData,
  updateTariffData,
  createInsurancePolicieData,
  updateInsurancePolicieData,
  deleteInsurancePolicieData,
  createCompensationData,
  updateCompensationData,
  deleteCompensationData,
  sendDocumentData,
  getDocumentsData,
  deleteTariffData,
  deleteFixedTariffData,
  createInstallmentData,
  updateInstallmentData,
  getDocumentTypeListByNameData,
  createRefusalReasonData,
  updateRefusalReasonData,
  deleteRefusalReasonData,
  getTerminationInitiatorsNamesData,
  getAccountTypesData,
  createTerminationInitiatorData,
  getTerminationCausesData,
  deleteTerminationInitiatorData,
  updateTerminationInitiatorData,
  createTerminationDocumentsData,
  updateTerminationDocumentsData,
  deleteTerminationDocumentsData,
  getInsuranceTypesAllTypeFunc,
} from '../../api/insuranceProgramCreate';
import history from '../../routes/history';
import { ROUTES } from '../../constants/routes';
import { AppState } from '../reducer';
import { ICause, ICompensation, IInstallmentData, IInsurancePolicies, ITariffTypes } from './types';
import { modifyInsTypes, modifyRiskListData, modifyProgramsData } from './helper';
import { ACTION_TYPE } from 'types/actionTypes';
import { IRecord } from 'screens/InsuranceProgramCreate/Table/Coefficients/types';
import { apiConfiguration } from 'api/configuration';
import { ProlongationConfigurationDto, ResolutionType } from 'types/dto/configuration-service';
import { t } from 'i18next';

export const TYPES = createTypes('partnersCreate', [
  'getInsuranceCompanies',
  'getProgramsType',
  'getManagers',
  'getAgents',
  'setError',
  'getProgramData',
  'insuranceTypesAll',
  'insuranceFilteredRisks',
  'getk3Coefficient',
  'getk6Coefficient',
  'changeCofficient',
  'changeRecordId',
  'getTariffData',
  'setInsuranceProgramId',
  'getInsTypes',
  'getInsRules',
  'setTabKey',
  'getRisksList',
  'setRecord',
  'setStatus',
  'setChosenSettlement',
  'getInsurancePolicie',
  'setDocuments',
  'setInsuranceObject',
  'setSelectedDocumentsValues',
  'setParameters',
  'setDocumentTypeNamesList',
  'setAllDocumentTypeNamesList',
  'setDocumentsConfiguration',
  'setTemplateData',
  'setTerminationInitiatorsNames',
  'setAccountTypes',
  'setTerminationCauses',
  'setTerminationDocumentsNames',
]);

export const setProgramData = (data) => {
  return {
    type: TYPES.getProgramData,
    payload: { data },
  };
};

export const setChangedCoefficient = (data) => {
  return {
    type: TYPES.changeCofficient,
    payload: { data },
  };
};

export const setk3Coefficient = (data) => {
  return {
    type: TYPES.getk3Coefficient,
    payload: { data },
  };
};
export const setk6Coefficient = (data) => {
  return {
    type: TYPES.getk6Coefficient,
    payload: { data },
  };
};

export const setRecordId = (data) => {
  return {
    type: TYPES.changeRecordId,
    payload: { data },
  };
};

export const setChosenSettlement = (data) => {
  return {
    type: TYPES.setChosenSettlement,
    payload: data,
  };
};

export const setTabKey = (tabKey: string) => ({ type: TYPES.setTabKey, payload: tabKey });

export const setError = (payload: { [key: string]: string }) => ({
  type: TYPES.setError,
  payload,
});

export const setInsuranceObject = (insuranceObject) => ({
  type: TYPES.setInsuranceObject,
  payload: insuranceObject,
});

export const setSelectedDocumentsValues = (selectedValues) => ({
  type: TYPES.setSelectedDocumentsValues,
  payload: selectedValues,
});

export const fetchProgram = (id: number, isCopy?: boolean) => async (dispatch) => {
  try {
    const { data } = isCopy ? await apiConfiguration.insuranceProgramController.copy(id) : await fetchProgramData(id);
    dispatch(setProgramData(data));
  } catch (e) {
    dispatch(setError({ fetchProgram: e?.message }));
  }
};

export const saveProgram = (programData, id, t, isCopy: boolean) => async (dispatch, getState) => {
  const programDataId: number = getState().insuranceProgramCreate.programData.id;
  try {
    const { data } =
      !id && !isCopy ? await createProgram(programData) : await updateProgram(programDataId, programData);
    (!id || isCopy) && history.push(`${ROUTES.INSURANCE_PROGRAMS.READ.replace(':id', data.id)}`);
    data?.id && !isCopy && dispatch(fetchProgram(data?.id, isCopy));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    if (e?.response?.data?.errors) {
      e?.response?.data?.errors.forEach((error) => {
        if (error.code === 'NOT_UNIQUE_INSURANCE_PROGRAM_CODE') {
          notification.error({
            message: t('insurance_program_list.code_save_validation'),
          });
        }
        if (error.code === 'NOT_UNIQUE_INSURANCE_PROGRAM_NAME') {
          notification.error({
            message: t('insurance_program_list.name_save_validation'),
          });
        }
        if (error.code === 'CONTRACT_TEMPLATE_IS_MANDATORY') {
          notification.error({
            message: `${t('insurance_program_list.documents_ind_agreement_conditions_is_required')}`,
          });
        }
        if (error.code === 'OFFER_DOCUMENT_IS_MANDATORY') {
          notification.error({
            message: `${t('insurance_program_list.documents_offer_is_required')}`,
          });
        }
        if (error.code === 'ADD_AT_LEAST_ONE_TERMINATION_INITIATOR_AND_DOCUMENT_FOR_ACTIVATE_PROGRAM') {
          notification.error({
            message: `${t('insurance_program_list.termination_add_one_initiator_and_document')}`,
          });
        }
        if (error.code === 'ADD_AT_LEAST_ONE_PAYMENT_REJECTION_CAUSE_FOR_ACTIVATE_PROGRAM') {
          notification.error({
            message: `${t('insurance_program_list.termination_add_one_payment_rejection_cause')}`,
          });
        }
      });
    }
    dispatch(setError({ saveProgram: e?.message }));
  }
};

export const saveRisks =
  ({ risksData, id, riskId, closeModal }, t) =>
  async (dispatch) => {
    try {
      await saveRisksData(id, risksData);

      await dispatch(fetchProgram(id));
      notification.success({
        message: t('popup.save'),
      });
      closeModal();
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach((error) => {
          if (error.code === 'Insurance rule for current risk is incorrect') {
            notification.error({
              message: t('insurance_program_list.ins_rule_validation'),
            });
          }
          if (error.code === 'PROGRAM_RISK_WITH_CURRENT_RISK_EXISTS') {
            notification.error({
              message: t('insurance_program_list.risk_save_exist'),
            });
          }
        });
      }
      dispatch(setError({ saveRisks: e?.message }));
    }
  };

export const updateRisks =
  ({ risksData, id, programId, closeModal }, t) =>
  async (dispatch) => {
    try {
      await updateRisksData(id, risksData);

      await dispatch(fetchProgram(programId));
      notification.success({
        message: t('insurance_configuration.data_updated'),
      });
      closeModal();
    } catch (e) {
      if (e?.response?.data?.errors) {
        e?.response?.data?.errors.forEach((error) => {
          if (error.code === 'Insurance rule for current risk is incorrect') {
            notification.error({
              message: t('insurance_program_list.ins_rule_validation'),
            });
          }
          if (error.code === 'PROGRAM_RISK_WITH_CURRENT_RISK_EXISTS') {
            notification.error({
              message: t('insurance_program_list.risk_save_exist'),
            });
          }
        });
      }
      dispatch(setError({ saveRisks: e?.message }));
    }
  };

export const getCompanies = (t) => async (dispatch) => {
  try {
    const { data } = await getInsuranceCompanies();
    dispatch({ type: TYPES.getInsuranceCompanies, payload: { data } });
  } catch (e) {
    dispatch(setError({ getCompanies: e?.message }));
    notification.error({
      message: t('popup.getting_insurance_companies'),
      description: t('popup.try_again_products'),
    });
  }
};

export const getProgramsType = (t?: any) => async (dispatch) => {
  try {
    const { data } = await getProgramsTypeData();
    const modifyData = modifyProgramsData(data, t);
    dispatch({ type: TYPES.getProgramsType, payload: { data: modifyData } });
  } catch (e) {
    dispatch(setError({ getProgramsType: e?.message }));
  }
};

export const getManagers = (companyId, form) => async (dispatch) => {
  try {
    const { data } = await getManagersData(companyId);
    dispatch({ type: TYPES.getManagers, payload: { data } });

    if (data.length) {
      form.setFieldsValue({
        managerName: data[0].id,
        managerEmail: data[0].email,
        managerPhoneNumber: data[0].phoneNumber.substring(4),
      });
    }
  } catch (e) {
    dispatch({ type: TYPES.getManagers, payload: { data: [] } });
    dispatch(setError({ getManager: e.message }));
  }
};

export const getAgents = () => async (dispatch) => {
  try {
    const { data } = await getAgentsData();
    dispatch({ type: TYPES.getAgents, payload: { data } });
  } catch (e) {
    dispatch(setError({ getAgent: e?.message }));
  }
};

export const getInsTypes = (t?: any) => async (dispatch) => {
  try {
    const { data } = await getInsTypesData();
    const modifyData = modifyInsTypes(data, t);
    dispatch({ type: TYPES.getInsTypes, payload: { data: modifyData } });
  } catch (e) {
    dispatch(setError({ getInsTypes: e?.message }));
  }
};

export const getInsRules = () => async (dispatch) => {
  try {
    const { data } = await getInsRulesData();
    dispatch({ type: TYPES.getInsRules, payload: { data } });
  } catch (e) {
    dispatch(setError({ getInsRules: e.message }));
  }
};

export const getInsuranceTypesAllType = (insuranceCompanyId: number) => async (dispatch) => {
  try {
    const { data } = await getInsuranceTypesAllTypeFunc(insuranceCompanyId);
    dispatch({ type: TYPES.insuranceTypesAll, payload: { data } });
  } catch (e) {
    dispatch(setError({ getInsRules: e?.message }));
  }
};

export const FilteredRisks = (typeId: string, name?: string) => async (dispatch) => {
  try {
    const { data } = await apiConfiguration.riskController.list({ insuranceTypeId: typeId, name, page_size: -1 });
    dispatch({ type: TYPES.insuranceFilteredRisks, payload: { data } });
  } catch (e) {
    dispatch(setError({ getInsRules: e?.message }));
  }
};

export const getRisksList = (programId: number, t?: any) => async (dispatch) => {
  try {
    const { data } = await getRiskListData(programId);
    const modifyData = modifyRiskListData(data, t);
    dispatch({ type: TYPES.getRisksList, payload: { data: modifyData } });
  } catch (e) {
    dispatch(setError({ getRisksList: e?.message }));
  }
};

export const getCurrentRecord = (record: IRecord) => async (dispatch) => {
  dispatch({ type: TYPES.setRecord, payload: record });
};

export const getCurrentModalStatus = (status: string) => async (dispatch) => {
  dispatch({ type: TYPES.setStatus, payload: status });
};

export const deleteRisk = (mainId: number, t) => async (dispatch, getState) => {
  const {
    insuranceProgramCreate: {
      programData: { id },
    },
  }: AppState = getState();
  try {
    await deleteRiskData(mainId);
    notification.success({
      message: t('popup.deleted'),
    });
    await dispatch(fetchProgram(id));
  } catch (e) {
    dispatch(setError({ getRisksList: e.message }));
  }
};

export const getTariff = (id: number) => async (dispatch) => {
  try {
    const { data } = await getTariffData(id);
    await dispatch({ type: TYPES.getTariffData, payload: data });
    return data;
  } catch (e) {
    dispatch(setError({ getTariffList: e.message }));
  }
};

export const setProgramId = (id: number) => async (dispatch) => {
  await dispatch({ type: TYPES.setInsuranceProgramId, payload: id });
};

export const sendTariff =
  (crateId: number, tariffData: Partial<ITariffTypes>, type: string, singleTariffId: number, t) =>
  async (dispatch, getState) => {
    try {
      type === ACTION_TYPE.CREATE
        ? await createTariffData(crateId, tariffData)
        : await updateTariffData(singleTariffId, tariffData);
      const {
        insuranceProgramCreate: {
          programData: { id },
        },
      }: AppState = getState();
      await dispatch(fetchProgram(id));
      notification.success({
        message: t('popup.save'),
      });
    } catch (e) {
      dispatch(setError({ getTariffList: e.message }));
      notification.error({
        message: t('popup.try_again_products'),
      });
    }
  };

const deleteTariff = async (id: number, getState: () => AppState, deleteMethod: (id: number) => void, dispatch) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await deleteMethod(id);
    await dispatch(fetchProgram(programId));
  } catch (e) {
    dispatch(setError({ getTariffList: e.message }));
  }
};

export const deleteNonFixedTariff = (id: number) => async (dispatch, getState) => {
  deleteTariff(id, getState, deleteTariffData, dispatch);
};

export const deleteFixedTariff = (insuranceRateSumId: number) => async (dispatch, getState) => {
  deleteTariff(insuranceRateSumId, getState, deleteFixedTariffData, dispatch);
};

export const insurancePolicieAction =
  (closeModal: () => void, actionType: string, createId: number, insurancePoliceData: IInsurancePolicies, t) =>
  (dispatch) => {
    if (actionType === 'CREATE') {
      dispatch(createInsurancePolicie(closeModal, createId, insurancePoliceData, t));
    } else {
      dispatch(updateInsurancePolicie(closeModal, createId, insurancePoliceData, t));
    }
  };

export const createInsurancePolicie =
  (closeModal: () => void, id: number, insurancePoliceData: IInsurancePolicies, t) => async (dispatch, getState) => {
    try {
      const {
        insuranceProgramCreate: {
          programData: { id: programId },
        },
      }: AppState = getState();
      await createInsurancePolicieData(id, insurancePoliceData);
      await dispatch(fetchProgram(programId));
      notification.success({
        message: t('popup.save'),
      });
      closeModal();
    } catch (e) {
      if (e?.response?.status === 400) {
        e.response.data.errors &&
          e.response.data.errors.forEach((el: { code: string }) => {
            if (el.code === 'Applicable period To is less than insurance program created date') {
              const errorMessage = t('validation.app_period_is_less_than_ins_prg_created_date');
              dispatch(setError({ getInsurancePolicie: errorMessage }));
            }
            if (el.code === 'There is a gap between policies date range') {
              const errorMessage = t('validation.gap_between_policies_date_range');
              dispatch(setError({ getInsurancePolicie: errorMessage }));
            }
            if (el.code === 'INSURANCE_POLICY_WITH_SPECIFIED_NAME_EXISTS') {
              const errorMessage = t('validation.specified_name_exists');
              dispatch(setError({ getInsurancePolicie: errorMessage }));
            }
          });
      }
      notification.error({
        message: t('popup.try_again_products'),
      });
    }
  };

export const updateInsurancePolicie =
  (closeModal: () => void, id: number, insurancePoliceData: IInsurancePolicies, t) => async (dispatch, getState) => {
    try {
      const {
        insuranceProgramCreate: {
          programData: { id: programId },
        },
      }: AppState = getState();
      await updateInsurancePolicieData(id, insurancePoliceData);
      await dispatch(fetchProgram(programId));
      notification.success({
        message: t('popup.save'),
      });
      closeModal();
    } catch (e) {
      if (e?.response?.status === 400) {
        e.response.data.errors &&
          e.response.data.errors.forEach((el: { code: string }) => {
            if (el.code === 'Applicable period To is less than insurance program created date') {
              const errorMessage = t('validation.app_period_is_less_than_ins_prg_created_date');
              dispatch(setError({ getInsurancePolicie: errorMessage }));
            }
            if (el.code === 'There is a gap between policies date range') {
              const errorMessage = t('validation.gap_between_policies_date_range');
              dispatch(setError({ getInsurancePolicie: errorMessage }));
            }
            if (el.code === 'INSURANCE_POLICY_WITH_SPECIFIED_NAME_EXISTS') {
              const errorMessage = t('validation.specified_name_exists');
              dispatch(setError({ getInsurancePolicie: errorMessage }));
            }
          });
      }
      notification.error({
        message: t('popup.try_again_products'),
      });
    }
  };

export const deleteInsurandcePolicie = (createId: number, t) => async (dispatch, getState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await deleteInsurancePolicieData(createId);
    await dispatch(fetchProgram(programId));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    dispatch(setError({ getInsurancePolicie: e.message }));
    notification.error({
      message: t('popup.try_again_products'),
    });
  }
};

const compensationAction = (method, params, t) => async (dispatch, getState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await method(...params);
    await dispatch(fetchProgram(programId));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    dispatch(setError({ compensation: e.message }));
    notification.error({
      message: t('popup.try_again_products'),
    });
  }
};

export const sendCompensation =
  (actionType: string, politicalId: number, singleCompensationId: number, CompensationBody: ICompensation, t) =>
  async (dispatch) => {
    if (actionType === 'CREATE') {
      dispatch(createCompensation(politicalId, CompensationBody, t));
    } else {
      dispatch(updateCompensation(singleCompensationId, CompensationBody, t));
    }
  };

const createCompensation = (createId: number, CompensationBody: ICompensation, t) => async (dispatch) => {
  dispatch(compensationAction(createCompensationData, [createId, CompensationBody], t));
};

const updateCompensation = (updateId: number, CompensationBody: ICompensation, t) => async (dispatch) => {
  dispatch(compensationAction(updateCompensationData, [updateId, CompensationBody], t));
};

export const deleteCompensation = (deleteId: number, t) => async (dispatch) => {
  dispatch(compensationAction(deleteCompensationData, [deleteId], t));
};

export const sendDocument = (file: File, docType: string, t) => async (dispatch, getState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await sendDocumentData(programId, file, docType);
    await dispatch(fetchProgram(programId));
    notification.success({
      message: t('popup.save'),
    });
  } catch (e) {
    dispatch(setError({ documents: e.message }));
    notification.error({
      message: t('popup.try_again_products'),
    });
  }
};

export const getDocuments = (insuranceObject: string, t) => async (dispatch) => {
  try {
    const data = await getDocumentsData(insuranceObject);
    dispatch({ type: TYPES.setDocuments, payload: data.data });
    return data;
  } catch (e) {
    dispatch(setError({ documents: e.message }));
  }
};

export const setParameters = (fieldInfo: { fieldName: string; fieldValue: string }, t) => async (dispatch) => {
  try {
    let newValue;
    if (typeof fieldInfo.fieldValue === 'boolean') {
      newValue = fieldInfo.fieldValue;
    } else {
      newValue = fieldInfo.fieldValue === t('insurance_program_list.parameters_yes') ? true : false;
    }
    dispatch({ type: TYPES.setParameters, payload: { fieldName: fieldInfo.fieldName, fieldValue: newValue } });
  } catch (e) {
    dispatch(setError({ parameters: e.message }));
  }
};

export const sendInstallment =
  (actionType: string, insuranceProgramId: number | string, data: IInstallmentData[]) => async (dispatch, getState) => {
    try {
      const {
        insuranceProgramCreate: {
          programData: { id: programId },
        },
      }: AppState = getState();
      if (actionType === ACTION_TYPE.UPDATE) {
        await updateInstallmentData(insuranceProgramId, data);
      } else {
        await createInstallmentData(insuranceProgramId, data);
      }
      await dispatch(fetchProgram(programId));
    } catch (e) {
      dispatch(setError({ installment: e.message }));
    }
  };

export const sendPrologation =
  (actionType: string, insuranceProgramId: number, data: ProlongationConfigurationDto, prologationName: string) =>
  async (dispatch, getState) => {
    try {
      const {
        insuranceProgramCreate: {
          programData: { id: programId },
        },
      }: AppState = getState();
      if (actionType === ACTION_TYPE.UPDATE) {
        await apiConfiguration.prolongationConfigurationController.updateProlongationConfiguration(
          insuranceProgramId,
          data,
        );
      } else {
        await apiConfiguration.prolongationConfigurationController.createProlongationConfiguration(
          insuranceProgramId,
          data,
        );
      }
      await dispatch(fetchProgram(programId));
      dispatch(setParameters({ fieldName: 'prolongation', fieldValue: prologationName }, t));
    } catch (e) {
      dispatch(setError({ installment: e?.message }));
    }
  };

const _sendPayments = (cb, params) => async (dispatch, getState: () => AppState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await cb(...params);
    await dispatch(fetchProgram(programId));
  } catch (e) {
    dispatch(setError({ refusalReason: e?.message }));
  }
};

export const getTerminationInitiatorsNames = () => async (dispatch) => {
  try {
    const data = await getTerminationInitiatorsNamesData();
    dispatch({ type: TYPES.setTerminationInitiatorsNames, payload: data.data });
  } catch (e) {
    dispatch(setError({ terminationInitiators: e.message }));
  }
};

export const getAccountTypes = () => async (dispatch) => {
  try {
    const data = await getAccountTypesData();
    dispatch({ type: TYPES.setAccountTypes, payload: data.data });
  } catch (e) {
    dispatch(setError({ terminationInitiators: e.message }));
  }
};

export const getTerminationCauses = () => async (dispatch) => {
  try {
    const data = await getTerminationCausesData();
    dispatch({ type: TYPES.setTerminationCauses, payload: data.data });
  } catch (e) {
    dispatch(setError({ terminationInitiators: e.message }));
  }
};

export const createTerminationInitiator = (terminationInitiatorData) => async (dispatch, getState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await createTerminationInitiatorData(programId, terminationInitiatorData);
    await dispatch(fetchProgram(programId));
  } catch (e) {
    dispatch(setError({ terminationInitiators: e.message }));
  }
};

export const createRefusalReason = (insuranceProgramId: number, data) => async (dispatch) => {
  try {
    dispatch(_sendPayments(createRefusalReasonData, [insuranceProgramId, data]));
  } catch (e) {
    dispatch(setError({ refusalReason: e.message }));
  }
};

export const updateRefusalReason = (paymentRejectionCauseId: number, data) => async (dispatch) => {
  try {
    dispatch(_sendPayments(updateRefusalReasonData, [paymentRejectionCauseId, data]));
  } catch (e) {
    dispatch(setError({ refusalReason: e.message }));
  }
};

export const deleteRefusalReason = (paymentRejectionCauseId: number) => async (dispatch) => {
  try {
    dispatch(_sendPayments(deleteRefusalReasonData, [paymentRejectionCauseId]));
  } catch (e) {
    dispatch(setError({ refusalReason: e.message }));
  }
};

export const setTemplateData = (templateData: ICause[]) => async (dispatch) => {
  try {
    dispatch({ type: TYPES.setTemplateData, payload: { data: templateData } });
  } catch (e) {
    dispatch(setError({ refusalReason: e.message }));
  }
};

export const deleteTerminationInitiator = (terminationInitiatorId) => async (dispatch, getState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await deleteTerminationInitiatorData(terminationInitiatorId);
    await dispatch(fetchProgram(programId));
  } catch (e) {
    dispatch(setError({ terminationInitiators: e.message }));
  }
};

export const updateTerminationInitiator =
  (terminationInitiatorId, terminationInitiatorData) => async (dispatch, getState) => {
    try {
      const {
        insuranceProgramCreate: {
          programData: { id: programId },
        },
      }: AppState = getState();
      await updateTerminationInitiatorData(terminationInitiatorId, terminationInitiatorData);
      await dispatch(fetchProgram(programId));
    } catch (e) {
      dispatch(setError({ terminationInitiators: e.message }));
    }
  };

export const createTerminationDocuments = (terminationDocumentsData) => async (dispatch, getState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await createTerminationDocumentsData(programId, terminationDocumentsData);
    await dispatch(fetchProgram(programId));
  } catch (e) {
    dispatch(setError({ terminationDcouments: e.message }));
  }
};

export const updateTerminationDocuments =
  (documentConfigurationId, terminationDocumentsData) => async (dispatch, getState) => {
    try {
      const {
        insuranceProgramCreate: {
          programData: { id: programId },
        },
      }: AppState = getState();
      await updateTerminationDocumentsData(documentConfigurationId, terminationDocumentsData);
      await dispatch(fetchProgram(programId));
    } catch (e) {
      dispatch(setError({ terminationDcouments: e.message }));
    }
  };

export const getTerminationDocumentsNames = (documentName: string) => async (dispatch, getState) => {
  try {
    const { data } = await getDocumentTypeListByNameData(documentName);
    dispatch({ type: TYPES.setTerminationDocumentsNames, payload: data });
  } catch (e) {
    dispatch(setError({ terminationDcouments: e.message }));
  }
};

export const deleteTerminationDocuments = (documentConfigurationId: number) => async (dispatch, getState) => {
  try {
    const {
      insuranceProgramCreate: {
        programData: { id: programId },
      },
    }: AppState = getState();
    await deleteTerminationDocumentsData(documentConfigurationId);
    await dispatch(fetchProgram(programId));
  } catch (e) {
    dispatch(setError({ terminationDcouments: e.message }));
  }
};
