import { useEffect, useState } from 'react';
import { getContracts } from 'api/contracts';
import { getBrandSales, getСlaimPayments, getMonthInfo, getOrdinaryPayments } from 'api/statistics';
import { IContract } from 'types/actsList';
import moment from 'moment';
import {
  MonthInfoDto,
  SalesStatisticsDtoLong,
  SalesStatisticsDtoBigDecimal,
  ClaimPaymentsStatisticDto,
  ContractView,
} from 'types/dto/contracts-service';

const POLL_INTERVAL = 3 * 60 * 1000;

export interface IStatisticsState {
  contracts: ContractView[];
  monthInfo?: MonthInfoDto;
  brandSales: SalesStatisticsDtoLong[];
  ordinaryPayments: SalesStatisticsDtoBigDecimal[];
  claimPayments: ClaimPaymentsStatisticDto[];
}

function useStatistics({ company, insuranceObjectType, deviceTypeId }: any) {
  const [state, setState] = useState<IStatisticsState>({
    contracts: [],
    brandSales: [],
    ordinaryPayments: [],
    claimPayments: [],
    monthInfo: undefined,
  });

  useEffect(() => {
    fetch(company, { insuranceObjectType, deviceTypeId }).then(setState);
    const timeout = poll(company, { insuranceObjectType, deviceTypeId }, (params: any) => setState(params));
    return () => clearTimeout(timeout);
  }, [company, insuranceObjectType, deviceTypeId]);
  return state;
}

function poll(company: any, params: any, cb: any) {
  return setTimeout(async () => {
    const statistics = await fetch(company, params);
    cb(statistics);
    poll(company, params, cb);
  }, POLL_INTERVAL);
}

async function fetch(company: any, params: any) {
  if (!company) {
    return {
      contracts: [],
      brandSales: [],
      ordinaryPayments: [],
      claimPayments: [],
      monthInfo: undefined,
    };
  }
  if (company.name === 'Усі') {
    company = '';
  }

  const { deviceTypeId, insuranceObjectType } = params;
  const { partnerId, insuranceCompanyId, partnerDepartmentId } = company;

  const {
    data: { resultList: contracts = [] },
  } = await getContracts({
    partnerId,
    insuranceCompanyId,
    partnerDepartmentId,
    sorting: '-conclusionDate',
    page_size: 20,
    conclusionDate: `${moment(new Date()).startOf('month').format('YYYY-MM-DD')}..${moment(new Date())
      .endOf('month')
      .format('YYYY-MM-DD')}`,
  });
  const { data: brandSales = [] } =
    insuranceCompanyId || deviceTypeId
      ? await getBrandSales({
          partnerId,
          insuranceCompanyId,
          partnerDepartmentId,
          deviceTypeId,
          insuranceObjectType,
        })
      : {};
  const { data: monthInfo } = await getMonthInfo(company);
  const { data: ordinaryPayments } = await getOrdinaryPayments(company);
  const { data: claimPayments } = await getСlaimPayments(company);
  return { contracts, monthInfo, brandSales, ordinaryPayments, claimPayments };
}

export default useStatistics;
