import { Language } from 'types';

export enum Authorization {
  PHONE_AND_EMAIL = 'PHONE_AND_EMAIL',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export interface WhiteLabeling {
  color?: string;
  logo?: string;
  logoSmall?: string;
  email?: string;
  phoneNumber?: string;
  telegram?: string;
  authorization?: Authorization;
  language?: Language[];
}
