import { css } from '@emotion/css';
import { Card } from 'antd';
import React from 'react';
import palleteRedesign from 'styles/palleteRedesign';
import { NotificationEntityType, NotificationLog } from 'types/dto/notification-service';
import Icon from 'components/nextRedesign/Icon/Icon';
import dayjs from 'dayjs';
import config from 'config';
import Link from 'components/nextRedesign/Link/Link';
import { useHistory } from 'react-router-dom';
import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/reducer';
import { actions, getNotification, updateNotification } from 'store/me/me';
import Status from '../AlertStatus/AlertStatus';
import { useTranslation } from 'react-i18next';

const cssCard = css`
  position: relative;
  background: ${palleteRedesign.gray.lighter};
  border-color: ${palleteRedesign.gray.light};
  border-radius: 8px;
  color: ${palleteRedesign.gray.main};
  font-size: 12px;

  .ant-card-head {
    border: none;
  }

  .ant-card-body {
    padding: 24px 16px 16px 16px;
  }
`;

const cssStatus = css`
  position: absolute;
  top: -16px;
  left: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
`;

const cssCloseBtn = css`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
`;

const cssDate = css`
  position: absolute;
  top: -22px;
  right: 0px;
`;

const cssLink = css`
  text-decoration: underline;
  text-decoration-skip-ink: none;
`;

interface Props {
  notification: NotificationLog;
  onClose: () => void;
}

export const AlertCard = ({ notification, onClose }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const dispatch = useDispatch<AppDispatch>();

  const redirectMap: Record<NotificationEntityType, (id: number) => void> = {
    TERMINATION: (id) => {
      const searchParams = new URLSearchParams({ id: id.toString(), tab: 'TERMINATIONS' }).toString();
      history.push({
        pathname: ROUTES.CLIENT.POLICIES,
        search: searchParams,
      });
    },
    CLAIM: (id) => {
      const searchParams = new URLSearchParams({ id: id.toString(), tab: 'CLAIMS' }).toString();
      history.push({
        pathname: ROUTES.CLIENT.POLICIES,
        search: searchParams,
      });
    },
    CONTRACT: (id) => {
      const searchParams = new URLSearchParams({ id: id.toString() }).toString();
      history.push({
        pathname: ROUTES.CLIENT.POLICIES,
        search: searchParams,
      });
    },
  };

  const hideNotification = () => {
    //TODO back change interface
    //@ts-ignore
    dispatch(updateNotification(notification.id ?? 0, { ...notification, visible: false }))
      .then(() => {
        dispatch(getNotification());
      })
      .catch(() => {});
  };

  return (
    <Card className={cssCard} bordered>
      <>
        <div className={cssStatus}>
          <Status status={notification.type} iconBackground={true} />
        </div>

        <div className={cssCloseBtn} onClick={hideNotification}>
          <Icon type="close" color="gray" size="10px" />
        </div>
        <div className={cssDate}>{dayjs(notification?.createdAt).format(config.format.date)}</div>

        <div>{notification.header}</div>
        <div
          className={css`
            font-size: 16px;
            line-height: 18px;
            color: ${palleteRedesign.basic.black};
          `}
        >
          {notification.body}
        </div>
        <div>{notification.footer}</div>
        {/* {notification?.link ? (
          <Link
            onClick={() => {
              redirectMap['TERMINATION'](75908);
              onClose();
            }}
            className={cssLink}
          >
            {'Extension'}
          </Link>
        ) : null} */}
        {notification.entityType && notification.entityId ? (
          <Link
            onClick={() => {
              if (!notification.entityType || !notification.entityId) {
                return;
              }

              redirectMap[notification.entityType](notification.entityId);
              dispatch(actions.setNotification({ isOpenAlertPanel: false }));
            }}
            className={cssLink}
          >
            {t('profile.view')}
          </Link>
        ) : null}
      </>
    </Card>
  );
};
