import React from 'react';
import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import cs from 'classnames';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditBoxIcon, CrossIcon } from 'components/Icons';
import { removeInstitution, setMedApp } from 'store/medApp/actions';
import { useDispatch } from 'react-redux';

import style from './TableTools.module.less';
import styles from 'components/tables/AdminTable/TableTools/TableTools.module.less';

const TableTools = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEdit = () => {
    return history.push(`${ROUTES.MEDAPP.CLINICS.DETAILS.ROOT.replace(':id', `${props.record.id}`)}`);
  };

  const handleRemove = (id: number) => {
    dispatch(removeInstitution(id));
    dispatch(setMedApp());
  };

  return (
    <div className={styles.container}>
      <Button className={cs(styles.button, style.button)} type="link" onClick={handleEdit}>
        <div className={style.wrapIcon}>
          <EditBoxIcon />
        </div>
        <span>{t('brands_page.edit')}</span>
      </Button>

      <Button className={cs(styles.button, style.button)} type="link" onClick={() => handleRemove(props.record.id)}>
        <div className={style.wrapIcon}>
          <CrossIcon />
        </div>
        <span>{t('brands_page.remove')}</span>
      </Button>
    </div>
  );
};

export default TableTools;
