import createInstance from './axios';
import {
  ResultListDtoPartnerView,
  MonthInfoDto,
  SalesStatisticsDtoLong,
  ClaimPaymentsStatisticDto,
  CompanyView,
  SalesStatisticsDtoBigDecimal,
} from 'types/dto/contracts-service';
import { ResultListDtoInsuranceProgramView } from 'types/dto/configuration-service';

const contractApi = createInstance('CONTRACTS');
const configApi = createInstance('CONFIGURATION');

export interface IOption {
  id: number;
  name: string;
}

export interface IStatistics {
  currentValue: number;
  previousValue: number;
}

export const getPartners = () => {
  const params = {
    distinct: true,
    page_size: 300,
  };

  return contractApi.get<ResultListDtoPartnerView>('/partners', { params });
};

export const getMonthInfo = ({ ...params }) => {
  return contractApi.get<MonthInfoDto>('/statistics/month-info/', {
    params,
  });
};

export const getBrandSales = ({ ...params }) => {
  return contractApi.get<SalesStatisticsDtoLong[]>('/statistics/brand-sales', {
    params,
  });
};

export const getOrdinaryPayments = ({ ...params }) => {
  return contractApi.get<SalesStatisticsDtoBigDecimal[]>('/statistics/payments', {
    params: {
      ...params,
    },
  });
};

export const getСlaimPayments = ({ ...params }) => {
  return contractApi.get<ClaimPaymentsStatisticDto[]>('/statistics/claim-payments', {
    params: {
      ...params,
    },
  });
};

export const getInsurancePrograms = (paramsDto?: {
  insuranceCompanyId?: number;
  partnerId?: number;
  attributes?: string;
}) => {
  const params = {
    distinct: true,
    attributes: 'id,name',
    ...paramsDto,
  };

  return configApi.get<ResultListDtoInsuranceProgramView>(`/insurance-programs`, { params });
};

export const getTerminationInsuranceProgram = (partnerId?: number, insuranceCompanyId?: number): Promise<any> => {
  const partnerIdLocal = partnerId ? partnerId : '<>null';
  const insuranceCompanyIdLocal = insuranceCompanyId ? insuranceCompanyId : '<>null';

  return contractApi
    .get<CompanyView[]>(
      `/companies?partnerId=${partnerIdLocal}&insuranceCompanyId=${insuranceCompanyIdLocal}&partnerDepartmentId=null`,
    )
    .then((response) => ({
      data: response.data.map((el) => ({ id: el.partnerId, name: el.name, shortName: el.shortName })),
    }));
};

export const insuranceCompanies = () => {
  const params = {
    distinct: true,
    attributes: 'id,name',
    page_size: -1,
  };

  return contractApi.get<IOption[]>('/insurance-companies', { params });
};
