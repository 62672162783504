const columnsList = (t) => {
  return [
    {
      title: '№',
      value: 'number',
      key: 'a1',
      width: 70,
      filtered: false,
      filters: null,
      filterIcon: null,
      filterDropdown: null,
      sorter: false,
    },
    {
      title: t('sublimits.subsublimits'),
      value: 'name',
      key: 'a2',
      sorter: true,
    },
    {
      title: t('sublimits.sublimits'),
      value: 'sublimitName',
      key: 'a3',
      sorter: true,
    },
    {
      title: '',
      value: 'actions',
      key: 'a4',
      width: 70,
      filtered: false,
      filters: null,
      filterIcon: null,
      filterDropdown: null,
      sorter: false,
    },
  ];
};

export default columnsList;
