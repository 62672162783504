const columnsList = (t) => {
  return [
    {
      title: '№',
      value: 'importNumber',
    },
    {
      title: t('contract_details.package_number_import_export'),
      value: 'packageNumber',
    },
    {
      title: t('contract_details.transfer_type_import_export'),
      value: 'contractTransferType',
    },
    {
      title: t('contract_details.operation_date_import_export'),
      value: 'operationDate',
    },
    {
      title: t('contract_details.success_import_export'),
      value: 'success',
    },
    {
      title: t('contract_details.message_import_export'),
      value: 'message',
    },
  ];
};
export default columnsList;
