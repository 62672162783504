import React, { FunctionComponent, useState } from 'react';
import { Dropdown } from 'antd';
import { MenuIcon } from '../../../Icons';

const DropCustom: FunctionComponent<{
  id: any;
  removeContractSign?: (id: number) => void;
  data?: any;
  dataId?: string;
  dataIdOptions?: string;
  Component: any;
}> = (props) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const handleVisibleChange = (flag: boolean): void => setIsVisible(flag);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        data-id={props.dataId}
        overlay={() => (
          <props.Component dataId={props.dataIdOptions} {...props} handleVisibleChange={handleVisibleChange} />
        )}
        placement="bottomRight"
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={isVisible}
      >
        <span className="ant-dropdown-link">
          <MenuIcon />
        </span>
      </Dropdown>
    </div>
  );
};

export default DropCustom;
