import i18n from 'translations/i18n';
import { object, Infer, date, nullable, refiner } from 'utils/struct';

export const schema = object({
  dateFrom: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required');
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required');
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  dateFrom: null,
  dateTo: null,
};
