import { InsuranceProgramPartnerType } from 'types/insuranceProgramsList';

export interface IInsuranceProgram {
  contractType: string;
  code: string;
  active: boolean;
  name: string;
  insuranceCompany: string;
  claimManagerName: string;
  claimManagerEmail: string;
  claimManagerPhoneNumber: string;
  agentName: string;
  agentEmail: string;
  agentPhoneNumber: string;
  createdDate: string;
  startDate: string;
  endDate: string;
  businessExpensesPercent: number;
  programRisks: any[];
  programPartners: InsuranceProgramPartnerType[];
}

export class InsuranceProgram implements IInsuranceProgram {
  contractType = '';
  name = '';
  code = '';
  active = false;
  insuranceCompany = '';
  claimManagerName = '';
  claimManagerEmail = '';
  claimManagerPhoneNumber = '';
  agentName = '';
  agentEmail = '';
  agentPhoneNumber = '';
  createdDate = '';
  startDate = '';
  endDate = '';
  businessExpensesPercent = 0;
  programRisks = [];
  programPartners = [];
}
