import React from 'react';
import { css, cx } from '@emotion/css';
import { Grid } from 'antd';

import background from 'assets/img/bg-auth3.png';

import Header from './Header/Header';

const cssWrapper = css`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff url(${background}) center right/cover no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    align-items: flex-start;
    padding-left: 160px;
    padding-top: 60px;
    padding-bottom: 55px;
  }
`;
const cssCardBase = ({ maxWidth }: { maxWidth?: string }) => css`
  max-width: ${maxWidth};
`;
const cssCard = css`
  background: #fff;
  padding: 24px 10px;
  border-radius: 14px;
  max-width: 326px;
`;

interface Props {
  maxWidth?: string;
  children: React.ReactNode;
}

const AuthContainer = ({ children, maxWidth = '326px' }: Props) => {
  const screens = Grid.useBreakpoint();

  return (
    <div className={cssWrapper}>
      <Header />

      <div className={cx(cssCardBase({ maxWidth }), { [cssCard]: !screens.lg })}>{children}</div>
    </div>
  );
};

export default AuthContainer;
