import { t } from 'i18next';
import isEmail from 'is-email';
import { parsePhoneNumber } from 'awesome-phonenumber';

import { string, object, Infer, refiner } from 'utils/struct';

export const schema = object({
  partnerCode: refiner(string(), (value) => {
    if (!value) {
      return t('schema.required');
    }

    if (value.length !== 8) {
      return t('validation.partnerCode');
    }

    return true;
  }),
  email: refiner(string(), (value) => {
    if (!value) {
      return t('schema.required');
    }

    if (!isEmail(value)) {
      return t('schema.email');
    }

    return true;
  }),
  phoneNumber: refiner(string(), (value) => {
    if (!value) {
      return t('schema.required');
    }

    if (!parsePhoneNumber(value).valid) {
      return t('schema.phoneNumber');
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  partnerCode: '',
  email: '',
  phoneNumber: '',
};
