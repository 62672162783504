import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { actions } from 'store/dashboardExtend/dashboardExtend';
import { AppDispatch, AppState } from 'store/reducer';
import { BalanceForm } from './Form/BalanceForm';

const BalanceModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const modal = useSelector((state: AppState) => state.dashboardExtend.balanceModal);

  return (
    <Modal
      footer={null}
      maskClosable={false}
      destroyOnClose
      visible={modal?.isOpen}
      onCancel={() => {
        dispatch(actions.setBalanceModal({ isOpen: false }));
      }}
      width={800}
      title={t('dashboard_extend.balance_title')}
    >
      <BalanceForm />
    </Modal>
  );
};

export default BalanceModal;
