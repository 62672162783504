import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Row, Col, notification, Divider, Checkbox } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import { AppState } from 'store/reducer';
import { useRequired } from 'hooks/useRequired';
import { apiAuth } from 'api/auth';
import { actions, getMe } from 'store/me/me';
import { ErrorsDto, UseHistory } from 'types';
import { Authorization } from 'types/whiteLabel';
import Link from 'components/nextRedesign/Link/Link';
import Icon from 'components/nextRedesign/Icon/Icon';
import Button from 'components/nextRedesign/Button/Button';
import { Password } from 'components/nextRedesign/Password/Password';
import { InputField } from 'components/nextRedesign/InputField/InputField';
import Language from 'components/nextRedesign/ClientAuthContainer/Language/Language';
import { InputContainer } from 'components/nextRedesign/InputContainer/InputContainer';
import { breakpoints } from 'styles/breakpoints';

import { getSchema, defaultValues, Values } from './Login.schema';

const cssWrapper = css`
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`;
const cssExternalLinks = css`
  margin-top: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.small}) {
    margin-top: 60px;
  }
  span {
    margin-left: 5px;
  }
`;
const cssPartnerLink = css`
  display: flex;
  align-items: center;
`;
const cssIcon = css`
  padding: 0 !important;
  margin-right: 10px;
`;
const cssSubmit = css`
  margin-top: 24px;
  width: 100%;
`;

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const dispatch = useDispatch();

  const whiteLabeling = useSelector((store: AppState) => store.me.whiteLabel?.data?.whiteLabeling);

  const [loading, setLoading] = useState(false);

  const schema = getSchema({
    authorization: whiteLabeling?.authorization ?? Authorization.PHONE_AND_EMAIL,
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<Values>({
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues,
  });
  const { requiredFields } = useRequired(schema, watch());
  const watchedFields = watch();
  const isAnyFieldEmpty = Object.values(watchedFields).every((value) => !value);

  const onSubmit = async (values: Values) => {
    setLoading(true);

    apiAuth.authenticationEndpoints
      .clientLoginWithout2Fa({
        username: values.username.trim(),
        password: values.password.trim(),
      })
      .then(async (response) => {
        // setState({
        //   step: 'verification',
        //   username: values.username,
        //   password: values.password,
        //   userId: response.data.identity ?? 0,
        // });
        localStorage.setItem('accessToken', response.data.access_token ?? '');
        await dispatch(getMe());
        history.push(ROUTES.DASHBOARD.ROOT);
        dispatch(actions.setNotification({ isOpenAlertPanel: true }));
      })
      .catch((error) => {
        const errorMap = {
          default: t('log_in.check_the_data'),
          USER_INACTIVE: t('log_in.user_inactive'),
          USER_ONLY_CLIENT: t('log_in.user_only_client'),
          USER_NOT_CLIENT: t('log_in.user_not_client'),
        };

        if (typeof error?.response?.data === 'string') {
          notification.error({ message: errorMap.default });
        } else {
          (error?.response?.data as ErrorsDto | undefined)?.errors.forEach((err) => {
            const message = errorMap[err.code] ?? errorMap.default;

            notification.error({ message });
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPlaceholder = () => {
    switch (whiteLabeling?.authorization) {
      case Authorization.PHONE_AND_EMAIL:
        return t('log_in.enter_email_or_phone_number');
      case Authorization.PHONE:
        return t('log_in.enter_phone_number');
      case Authorization.EMAIL:
        return t('log_in.enter_email');

      default:
        return t('log_in.enter_email_or_phone_number');
    }
  };

  const onChangeLang = () => {
    reset();
  };

  return (
    <>
      <div className={cssWrapper}>
        <form onSubmit={handleSubmit(onSubmit)} data-test="form">
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Row gutter={[0, 16]}>
                <Col span={24}>
                  <Controller
                    name="username"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputField
                        name={name}
                        errors={errors}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('log_in.login')}
                        colorBase="grey"
                        placeholder={getPlaceholder()}
                      />
                    )}
                  />
                </Col>

                <Col span={24}>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <Password
                        name={name}
                        errors={errors}
                        onChange={(v) => onChange(v)}
                        value={value}
                        label={t('log_in.password')}
                        colorBase="grey"
                        placeholder={t('log_in.enter_your_password')}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row justify="space-between" align="middle" gutter={[0, 32]}>
                <Col span={12}>
                  <Controller
                    name="remember"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <InputContainer name={name} errors={errors} requiredFields={requiredFields}>
                        <Checkbox onChange={({ target }) => onChange(target.checked)} checked={value} data-test={name}>
                          {t('log_in.remember_me')}
                        </Checkbox>
                      </InputContainer>
                    )}
                  />
                </Col>

                <Col>
                  <InputContainer colorBase="none">
                    <Link
                      onClick={() => {
                        history.push(ROUTES.AUTH.FORGOT_PASSWORD);
                      }}
                    >
                      {t('log_in.forgot_password')}
                    </Link>
                  </InputContainer>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className={cssSubmit}
                loading={loading}
                disabled={isAnyFieldEmpty}
              >
                {t('log_in.log_in')}
              </Button>
            </Col>
          </Row>
        </form>

        <div className={cssExternalLinks}>
          {t('log_in.no_account')}
          <Link
            onClick={() => {
              history.push(ROUTES.AUTH.CLIENT_SIGNUP);
            }}
          >
            {t('log_in.register')}
          </Link>
        </div>
        <Divider style={{ margin: '20px 0' }} />
        <Row justify="space-between" align="middle" style={{ width: '100%' }}>
          <Col>
            <Link
              className={cssPartnerLink}
              onClick={() => {
                history.push(ROUTES.PARTNER_AUTH.LOGIN);
              }}
            >
              <Icon type="briefcase" size="24px" color="black" className={cssIcon} />
              {t('log_in.enter_as_partner')}
            </Link>
          </Col>
          <Col>
            <Language onChange={onChangeLang} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;
