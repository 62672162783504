import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import history from 'routes/history';
import PageBreadcrumbs from 'components/PageBreadcrumbs';
import PageHeader from 'components/PageHeader';
import SublimitForm from './components/SublimitForm';
import SubsublimitForm from './components/SubsublimitForm';
import { getSublimits, getSubsublimitById } from 'store/sublimits/actions';
import { AppState } from 'store/reducer';

const SublimitDetails: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sublimitId = history.location.pathname.split('/')[3];
  const isEdit = sublimitId !== 'new';

  const fetchSublimits = () => dispatch(getSublimits());
  const fetchSubsublimitById = (id) => dispatch(getSubsublimitById(id));

  const { sublimitsData, subsublimitData, error } = useSelector(
    ({ sublimits: { sublimitsData, subsublimitById, error } }: AppState) => ({
      sublimitsData: sublimitsData?.resultList || [],
      subsublimitData: subsublimitById || {},
      error,
    }),
  );

  useEffect(() => {
    if (isEdit) fetchSubsublimitById(sublimitId);
    fetchSublimits();
  }, []);

  return (
    <>
      <PageBreadcrumbs
        data={[
          { label: t('sublimits.home'), href: ROUTES.DASHBOARD.ROOT },
          { label: t('sublimits.list'), href: ROUTES.MEDAPP.SUBLIMITS.ROOT },
          { label: t('sublimits.sublimit_data') },
        ]}
      />
      <PageHeader title={t('sublimits.sublimit_data')} />
      <SublimitForm subsublimitData={subsublimitData} isEdit={isEdit} />
      <SubsublimitForm
        sublimitsData={sublimitsData}
        subsublimitData={subsublimitData}
        errorMessage={error}
        isEdit={isEdit}
      />
    </>
  );
};

export default SublimitDetails;
