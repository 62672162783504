import dayjs from 'dayjs';
import i18n from 'i18next';
import { parsePhoneNumber } from 'awesome-phonenumber';

import { optional, string, refiner, object, Infer, email, nullable, date, phoneNumber } from 'utils/struct';

export const schema = object({
  email: refiner(email(string()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  firstName: refiner(string(), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  lastName: refiner(string(), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  phoneNumber: refiner(optional(string()), (value) => {
    if (value && (value.length < 1 || value.length > 13)) {
      return i18n.t('schema.sizeRange', { min: 1, max: 13 }).toString();
    }

    if (value && !parsePhoneNumber(value).valid) {
      return i18n.t('schema.phoneNumber').toString();
    }

    return true;
  }),
  locality: optional(nullable(string())),
  birthDate: refiner(optional(nullable(date())), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    if (dayjs(value).isAfter(dayjs().subtract(18, 'years'))) {
      return i18n.t('osago.birthday', { context: 'min', count: 18 }).toString();
    }

    if (dayjs(value).isBefore(dayjs().subtract(100, 'years').subtract(1, 'day'))) {
      return i18n.t('osago.birthday', { context: 'max', count: 100 }).toString();
    }

    return true;
  }),
  street: refiner(optional(string()), (value) => {
    if (!value) {
      return true;
    }

    if (value.length < 1 || value.length > 255) {
      return i18n.t('schema.sizeRange', { min: 1, max: 255 }).toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  locality: null,
  birthDate: null,
  street: '',
};
