import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, notification, Row, Typography, Grid, Modal } from 'antd';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { css } from '@emotion/css';

import { InputField } from 'components/next/InputField/InputField';
import { useRequired } from 'hooks/useRequired';
import { NumberField } from 'components/next/NumberField/NumberField';
import { Button } from 'components/next/Button/Button';
import { apiContracts } from 'api/contracts';
import AuthContainer from 'components/next/AuthContainer/AuthContainer';

import { schema, defaultValues, Values } from './Registration.schema';
import { State } from '../SignUp';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';
import Step from '../Step/Step';
import { ErrorsDto } from 'types';

const cssSubmit = css`
  width: 150px;
`;

interface Props {
  steps: number;
  state: State;
  setState: React.Dispatch<Partial<State>>;
}

const Registration = ({ steps, state, setState }: Props) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setError,
  } = useForm({
    resolver: superstructResolver(schema),
    defaultValues,
    mode: 'onChange',
  });
  const { requiredFields } = useRequired(schema, watch());

  const next = (values: Values) => {
    setState({
      step: state.step + 1,
      signupAccount: {
        phoneNumber: values.phoneNumber,
        email: values.email,
        partnerCode: values.partnerCode,
      },
    });
  };

  const onSubmit = (values: Values) => {
    setLoading(true);

    apiContracts.managerSignupController
      .validateAccount({
        partnerCode: values.partnerCode,
        email: values.email,
        phoneNumber: values.phoneNumber,
      })
      .then(() => {
        setLoading(false);

        next(values);
      })
      .catch((error) => {
        setLoading(false);

        if (error === undefined) {
          return;
        }

        if (Array.isArray(error?.response?.data.errors)) {
          (error?.response?.data as ErrorsDto).errors.forEach((err) => {
            if (err.code === 'ACCOUNT_ALREADY_EXISTS_FOR_ANOTHER_PARTNER') {
              Modal.confirm({
                title: t('signup.registered_another_company'),
                content: t('signup.want_continue_registration'),
                okText: t('modal.so'),
                cancelText: t('modal.no'),
                centered: true,
                mask: true,
                onOk: () => {
                  next(values);
                },
                onCancel: () => {
                  reset();
                },
              });

              return;
            }

            if (err.code === 'EMAIL_ALREADY_REGISTERED_FOR_ANOTHER_COUNTERPARTY') {
              setError('email', { message: t('validation.EMAIL_ALREADY_REGISTERED_FOR_ANOTHER_COUNTERPARTY') });

              return;
            }

            if (err.code === 'PARTNER_NOT_FOUND') {
              setError('partnerCode', { message: t('validation.PARTNER_NOT_FOUND') });

              return;
            }

            if (err.code === 'ACCOUNT_ALREADY_EXISTS') {
              notification.error({ message: t('validation.ACCOUNT_ALREADY_EXISTS') });

              return;
            }

            notification.error({ message: err.code });
          });
        } else {
          notification.error({ message: error.message });
        }
      });
  };

  return (
    <AuthContainer>
      <Row gutter={[16, 16]}>
        <Col span={24}>{<Step step={state.step} steps={steps} />}</Col>

        <Col span={24}>
          <Typography.Title level={screens.lg ? 1 : 3}>{t('signup.registration')}</Typography.Title>
        </Col>

        <Col span={24}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Controller
                  name="partnerCode"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <NumberField
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onValueChange={(v) => onChange(v.value)}
                      label={t('signup.usreou')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col span={24}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <InputField
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onChange={(v) => onChange(v)}
                      label={t('signup.enter_email')}
                      colorBase="grey"
                    />
                  )}
                />
              </Col>

              <Col span={24}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <NumberField
                      name={name}
                      errors={errors}
                      requiredFields={requiredFields}
                      value={value}
                      onValueChange={(v) => onChange(v.formattedValue)}
                      label={t('signup.enter_phone_number')}
                      colorBase="grey"
                      format={(v) => `+${v}`}
                    />
                  )}
                />
              </Col>

              <Col span={24}>
                <Button type="primary" htmlType="submit" loading={loading} className={cssSubmit}>
                  {t('signup.next')}
                </Button>
              </Col>
            </Row>
          </form>
        </Col>

        <Col span={24}>
          {t('signup.have_a_account')}{' '}
          <Typography.Link onClick={() => history.push(ROUTES.PARTNER_AUTH.LOGIN)}>
            {t('signup.log_in')}
          </Typography.Link>
        </Col>
      </Row>
    </AuthContainer>
  );
};

export default Registration;
