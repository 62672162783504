import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { css, cx } from '@emotion/css';
import { Grid } from 'antd';
import { Spin } from 'antd';

import { UseHistory } from 'types';
import { ROUTES } from 'constants/routes';
import { AppState } from 'store/reducer';
import Header from './Header/Header';
import palleteRedesign from 'styles/palleteRedesign';
import { breakpoints } from 'styles/breakpoints';

const cssWrapper = css`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 60px 0;
  background: ${palleteRedesign.basic.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.small}) {
    padding: 20px 0;
  }
`;
const cssCardBase = css`
  max-width: 400px;
  @media (max-width: ${breakpoints.small}) {
    max-width: 300px;
  }
`;
const cssCardSignUp = css`
  max-width: 812px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${breakpoints.small}) {
    max-width: 300px;
  }
`;
const cssCard = css`
  padding: 24px 0;
`;

interface Props {
  children: React.ReactNode;
}

const ClientAuthContainer = ({ children }: Props) => {
  const screens = Grid.useBreakpoint();
  const history = useHistory<UseHistory>();

  const loading = useSelector((store: AppState) => store.me.whiteLabel?.loading);

  return (
    <div className={cssWrapper}>
      {loading ? (
        <Spin
          className={cx(css`
            .ant-spin-dot {
              height: 80px;
              width: 80px;
            }

            .ant-spin-dot-item {
              width: 15px;
              height: 15px;
            }
          `)}
        />
      ) : (
        <>
          <Header />
          <div
            className={cx(cssCardBase, {
              [cssCard]: !screens.lg,
              [cssCardSignUp]: history.location.pathname === ROUTES.AUTH.CLIENT_SIGNUP,
            })}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default ClientAuthContainer;
