import React from 'react';
import { Card, Checkbox, Col, Divider, Row, Tooltip } from 'antd';
import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DefaultOptionType } from 'antd/lib/select';

import PageHeader from 'components/next/PageHeader/PageHeader';
import { Button } from 'components/next/Button/Button';
import { Select } from 'components/next/Select/Select';
import { InputField } from 'components/next/InputField/InputField';
import { NumberField } from 'components/next/NumberField/NumberField';
import { Group, Stub } from 'components/next/Group/Group';
import { DatePicker, RangePicker } from 'components/next/DatePicker/DatePicker';
import * as Icons from 'components/next/Icon/Icon';
import { ROUTES } from 'constants/routes';
import { UseHistory } from 'types';

import { iconMap as iconMapRedesign } from 'components/nextRedesign/Icon/SVG';
import { Password } from 'components/next/Password/Password';
import { AutoComplete } from 'components/next/AutoComplete/AutoComplete';
import { AutoCompleteGoogle } from 'components/next/AutoCompleteGoogle/AutoCompleteGoogle';
import { TextField } from 'components/next/TextField/TextField';
import Controlled from './Controlled/Controlled';

const UI = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();

  const selectOptions: DefaultOptionType[] = Array.from({ length: 5 }, (v, k) => k).map((el) => ({
    value: el,
    label: el,
  }));

  const autoCompleteOptions: DefaultOptionType[] = [
    { value: 'ff', label: 'ff' },
    { value: 'tt', label: 'tt' },
  ];

  const IconsNext = Object.values(Icons).map((Icon, i) => {
    return (
      <Tooltip title={Icon.name} key={i}>
        <Icon style={{ padding: '8px', fontSize: '20px' }} />
      </Tooltip>
    );
  });

  const IconsRedesign = Object.entries(iconMapRedesign).map(([type, Icon]) => {
    return (
      <Tooltip title={type} key={type}>
        <Icon
          className={css`
            &.anticon {
              font-size: 20px;
              padding: 8px;
            }
          `}
        />
      </Tooltip>
    );
  });

  return (
    <>
      <PageHeader
        breadcrumb={{
          routes: [
            { path: ROUTES.SETTINGS.ROOT, breadcrumbName: t('settings.title') },
            { path: '', breadcrumbName: 'UIKit' },
          ],
        }}
        title="UIKit"
        subTitle="Customizable next components based on Ant Design"
        onBack={() => history.goBack()}
      />

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Row gutter={[16, 40]}>
              <Button>Button</Button>
              <Button size="small">small Button</Button>
              <Button size="large">large Button</Button>
              <TextField label="text" colorBase="grey">
                {0}
              </TextField>
            </Row>

            <Row gutter={[16, 40]}>
              <Col>
                <Checkbox>label</Checkbox>
              </Col>
            </Row>

            <Controlled />

            <Divider plain orientation="left">
              Groupped disabled
            </Divider>

            <Row gutter={[16, 40]}>
              <Col span={24}>
                <Group>
                  <InputField label="string" colorBase="grey" disabled />
                  <Password label={'password'} colorBase="grey" disabled />
                  <AutoComplete label={'autoComplete'} options={autoCompleteOptions} disabled />
                  <AutoCompleteGoogle
                    // onPlaceSelected={(place) => onChange(place.formatted_address)}
                    label={'AutoCompleteGoogle'}
                    language="en-GB"
                    disabled
                  />
                  <NumberField label="number" colorBase="grey" disabled />
                  <Select label="select" colorBase="grey" options={selectOptions} disabled />
                  <DatePicker label="date" colorBase="grey" disabled />
                  <Button type="primary" disabled>
                    button
                  </Button>
                </Group>
              </Col>
            </Row>

            <Divider plain orientation="left">
              Groupped clearable
            </Divider>

            <Row gutter={[16, 40]}>
              <Col span={24}>
                <Group>
                  <InputField label="string" colorBase="grey" allowClear />
                  <Password label={'password'} colorBase="grey" allowClear />
                  <AutoComplete label={'autoComplete'} options={autoCompleteOptions} allowClear />
                  <AutoCompleteGoogle
                    // onPlaceSelected={(place) => onChange(place.formatted_address)}
                    label={'AutoCompleteGoogle'}
                    language="en-GB"
                    allowClear
                  />
                  <NumberField label="number" colorBase="grey" allowClear />
                  <Select label="select" colorBase="grey" allowClear options={selectOptions} />
                  <DatePicker label="date" colorBase="grey" allowClear />
                  <TextField label="text" colorBase="grey">
                    {0}
                  </TextField>
                  <Stub colorBase="grey" width="100px" />
                </Group>
              </Col>
            </Row>

            <Divider plain orientation="left">
              Groupped pickers
            </Divider>

            <Row gutter={[16, 40]}>
              <Col span={24}>
                <Group>
                  <DatePicker label="date" colorBase="grey" />
                  <DatePicker label="month" picker="month" colorBase="grey" />
                  <RangePicker label="range" colorBase="grey" />
                </Group>
              </Col>
            </Row>

            <Divider plain orientation="left">
              Groupped labeled-less
            </Divider>

            <Row gutter={[16, 40]}>
              <Col span={24}>
                <Group>
                  <InputField colorBase="grey" />
                  <Password colorBase="grey" />
                  <AutoComplete options={autoCompleteOptions} />
                  <AutoCompleteGoogle
                    // onPlaceSelected={(place) => onChange(place.formatted_address)}
                    language="en-GB"
                  />
                  <NumberField colorBase="grey" />
                  <Select colorBase="grey" options={selectOptions} />
                  <DatePicker colorBase="grey" />
                  <TextField colorBase="grey">{0}</TextField>
                  <Stub colorBase="grey" width="100px" />
                </Group>
              </Col>
            </Row>
          </Card>
        </Col>

        <Divider plain orientation="left">
          Groupped white
        </Divider>

        <Col span={24}>
          <Group>
            <InputField label="string" colorBase="white" />
            <Password label={'password'} colorBase="white" autoComplete="new-password" />
            <AutoComplete label={'autoComplete'} options={autoCompleteOptions} colorBase="white" />
            <AutoCompleteGoogle
              // onPlaceSelected={(place) => onChange(place.formatted_address)}
              label={'AutoCompleteGoogle'}
              language="en-GB"
              colorBase="white"
            />
            <NumberField label="number" colorBase="white" />
            <Select label="select" colorBase="white" options={selectOptions} />
            <DatePicker label="date" colorBase="white" />
            <TextField label="text" colorBase="white">
              {0}
            </TextField>
            <Stub colorBase="white" width="100px" />
          </Group>
        </Col>

        <Divider plain orientation="left">
          Groupped with errors
        </Divider>

        <Col span={24}>
          <Group>
            <InputField
              label="string"
              colorBase="white"
              errors={{ string: { type: '', message: 'required' } }}
              name="string"
            />
            <TextField label="text" colorBase="white">
              {0}
            </TextField>
          </Group>
        </Col>

        <Divider plain orientation="left">
          Groupped multiline
        </Divider>

        <Col span={24}>
          <Group>
            <TextField label={'2 line field'} colorBase="white" multiple responsive>
              <div>{0}</div>
              <div>+{0}</div>
            </TextField>

            <TextField label={'3 line field'} colorBase="white" multiple responsive>
              <div>{0}</div>
              <div>+{0}</div>
              <div>{0}</div>
            </TextField>

            <TextField label={'1 line field'} colorBase="white" responsive>
              {0}
            </TextField>

            <Stub colorBase="white" />

            <Button type="primary">button</Button>
          </Group>
        </Col>

        <Divider plain orientation="left">
          Groupped buttons
        </Divider>

        <Col span={24}>
          <Group>
            <Button type="primary">submit</Button>
            <Button type="secondary">submit</Button>
            <Button danger>submit</Button>
            <Button>submit</Button>
            <Button type="dashed">submit</Button>
          </Group>
        </Col>

        <Divider plain orientation="left">
          Icons
        </Divider>

        <Col span={24}>
          <Icons.IconXLSX style={{ fontSize: '28px', padding: '8px' }} />
          <Icons.IconXLSX color="primary" style={{ padding: '8px' }} />
          <Icons.IconXLSX color="primary" background="primary" style={{ padding: '8px' }} />
          <Button>
            <Icons.IconXLSX color="primary" background="primary" />
            Text
          </Button>
          <Button>
            <Icons.IconXLSX style={{ fontSize: '28px' }} />
            Text
          </Button>
        </Col>

        <Col span={24}>{IconsNext}</Col>

        <Divider plain orientation="left">
          Icons redesign
        </Divider>

        <Col span={24}>{IconsRedesign}</Col>
      </Row>
    </>
  );
};

export default UI;
