import React from 'react';
import numeral from 'numeral';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { Table } from 'components/next/Table/Table';
import config from 'config/config';
import { InsuranceProgramStatisticsDto } from 'types/dto/contracts-service';

import Footer from './Footer/Footer';

interface Props {
  data: InsuranceProgramStatisticsDto[];
  currency: string;
  total: number;
}

const ByProgramTable = ({ data, currency, total }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<InsuranceProgramStatisticsDto>[] = [
    {
      title: t('dashboard_extend.byProgram.programName'),
      key: 'programName',
      render: (_, record) => record.programName,
    },
    {
      title: t('dashboard_extend.byProgram.contractPaymentSum'),
      key: 'contractPaymentSum',
      render: (_, record) => `${numeral(record.contractPaymentSum).format(config.format.financial)} ${currency}`,
    },
    {
      title: t('dashboard_extend.byProgram.countContracts'),
      key: 'countContracts',
      render: (_, record) => record.countContracts,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      footer={() => <Footer total={total} currency={currency} />}
      rowKey={(record) => record.programName ?? ''}
      scroll={{ x: 700, y: 265 }}
    />
  );
};

const MemoizedByProgramTable = React.memo(ByProgramTable);

export default MemoizedByProgramTable;
