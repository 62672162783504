import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { Input } from 'antd';
import { useSelector } from 'react-redux';
import { FieldErrors } from 'react-hook-form';
import { InputProps } from 'antd/lib/input';

import { AppState } from 'store/reducer';

import { ColorBase, Position, InputContainer } from '../InputContainer/InputContainer';
import { AnyObject } from 'types';

interface Props extends NumberFormatProps<InputProps> {
  label?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
}

export const NumberField = ({
  name,
  errors,
  requiredFields,
  label,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  value,
  onValueChange,
  ...props
}: Props) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      isRequired={isRequired}
      hasError={hasError}
      label={label}
      colorBase={colorBase}
      errorPosition={errorPosition}
    >
      <NumberFormat
        data-test={name}
        name={name}
        customInput={Input}
        // convert undefined to null for consistency with react-hook-form
        onValueChange={(values, info) => {
          onValueChange?.(
            values.floatValue === undefined ? { ...values, floatValue: null as unknown as number } : values,
            info,
          );
        }}
        // convert null to empty string for consistency with react-hook-form
        value={value ?? ''}
        bordered={false}
        {...props}
      />
    </InputContainer>
  );
};

export const CurrencyField = ({ colorBase = 'none', ...props }: Props) => {
  const currency = useSelector((store: AppState) => store.userLegacy.currentCompanyCurrency);

  return (
    <NumberField
      colorBase={colorBase}
      suffix={` ${currency ?? '₴'}`}
      thousandSeparator=" "
      allowEmptyFormatting
      {...props}
    />
  );
};

export const PercentField = ({ colorBase = 'none', ...props }: Props) => {
  return <NumberField colorBase={colorBase} suffix=" %" allowEmptyFormatting {...props} />;
};
