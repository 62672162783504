import { AdminTypes } from 'types/AdminTypes';
import { composeReducer } from 'redux-compose-reducer';
import moment from 'moment';
import config from 'config';

const initialState: AdminTypes = {
  resultList: [],
  pagination: {
    current: 1,
    perPage: config.ui.pagination.size,
  },
  sorting: {
    field: 'signupDate',
    direction: 'DESC',
  },
  filters: {
    dateFrom: moment().startOf('month').format('YYYY-MM-DD'),
    dateTo: `${moment().format('YYYY-MM-DD')}`,
    insuranceCompanyName: '',
    insuranceCompany: '',
    departmentName: '',
    username: '',
    departmentId: {
      id: null,
      name: null,
    },
    insuranceCompanyId: {
      id: null,
      name: null,
    },
  },
  privateFilterItems: {},
  insuranceCompanies: [],
  companies: [],
  userNames: [],
  departments: [],
  count: 0,
  pending: false,
  error: false,
};

export default composeReducer({
  initialState,
});
