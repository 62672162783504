import React from 'react';
import { Button as BaseButton } from 'antd';
import { ButtonProps, ButtonType } from 'antd/lib/button/button';
import { css, cx } from '@emotion/css';

const cssRoot = css`
  &.ant-btn {
    border-radius: 14px;
    height: 46px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;
const cssSizeSmall = css`
  &.ant-btn {
    height: 40px;
  }
`;
const cssSizeLarge = css`
  &.ant-btn {
    height: 50px;
  }
`;
const cssTypeSecondary = css`
  &.ant-btn {
    background: var(--ant-primary-color-outline);
  }
`;

export interface Props extends Omit<ButtonProps, 'type'> {
  type?: ButtonType | 'secondary';
}

export const Button = ({ type, className, ...props }: Props) => (
  <BaseButton
    className={cx(
      cssRoot,
      { [cssTypeSecondary]: type === 'secondary' },
      { [cssSizeSmall]: props.size === 'small' },
      { [cssSizeLarge]: props.size === 'large' },
      className,
    )}
    type={type === 'secondary' ? undefined : type}
    {...props}
  />
);

export default Button;
