import { TYPES } from './actions';
import { composeReducer } from 'redux-compose-reducer';
import { userType } from 'types/user';
import { AnyAction } from 'redux';

const initialState: userType = {
  currentCompanyCurrency: '',
};

const setCurrentCompanyCurrency = (state: userType, { payload }: AnyAction): userType => {
  return {
    ...state,
    currentCompanyCurrency: payload,
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    setCurrentCompanyCurrency,
  },
});
