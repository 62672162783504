import dayjs from 'dayjs';
import { DaysStatisticsDto } from 'types/dto/contracts-service';

import { Data } from './ByDaysChart/ByDaysChart';

export const transformDataForChart = (data: DaysStatisticsDto[], isMonthPeriod: boolean): Data[] => {
  return data.reduce((acc: Data[], el) => {
    acc.push(
      {
        xField: el.signDate ? (isMonthPeriod ? el.signDate : dayjs(el.signDate).format('MMM')) : '',
        yField: el.contractPaymentSum ?? 0,
        seriesField: 'seriesField_1',
        raw: { ...el, seriesField_1: el.contractPaymentSum ?? 0, seriesField_2: el.claimPaymentSum ?? 0 },
      },
      {
        xField: el.signDate ? (isMonthPeriod ? el.signDate : dayjs(el.signDate).format('MMM')) : '',
        yField: el.claimPaymentSum ?? 0,
        seriesField: 'seriesField_2',
        raw: { ...el, seriesField_1: el.contractPaymentSum ?? 0, seriesField_2: el.claimPaymentSum ?? 0 },
      },
    );

    return acc;
  }, []);
};
