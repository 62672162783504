import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';
import cls from 'classnames';

import { Values } from 'types/appointments';
import Typography from 'components/Typography';

import styles from './Form.module.less';

interface FormProps {
  values: Values;
  setField(key: string, value: any): void;
  errors: Record<string, boolean>;
  clearError(errKey: string): void;
  isDoctorEleks: boolean;
}

const Form: React.FC<FormProps> = ({ values, setField, errors, clearError, isDoctorEleks }) => {
  const { t } = useTranslation();

  const handleMessageChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setField('reason', e.target.value);
  };

  const handleWishesChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setField('wishes', e.target.value);
  };

  const handleKeyDown = () => {
    clearError('reason');
  };

  return (
    <Paper className={cls(styles.formContainer, { [styles.withoutWishes]: isDoctorEleks })} elevation={0}>
      <Typography className={styles.required} type="title">
        {t('appointments.reason_title')}
      </Typography>
      <div className={styles.reasonWrapper}>
        <textarea
          value={values.reason}
          placeholder={t('appointments.textarea_placeholder')}
          className={errors.reason && styles.error}
          onChange={handleMessageChange}
          onKeyDown={handleKeyDown}
        />
        {!!errors.reason && <span className={styles.errorText}>{t('appointments.wishes_error')}</span>}
      </div>
      {!isDoctorEleks && (
        <>
          <Typography type="title">{t('appointments.wishes_title')}</Typography>
          <textarea
            value={values.wishes}
            placeholder={t('appointments.textarea_placeholder')}
            onChange={handleWishesChange}
          />
        </>
      )}
    </Paper>
  );
};

export default Form;
