import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import { object, Infer, optional, number, date, string, nullable, refiner } from 'utils/struct';

export const schema = object({
  type: optional(nullable(string())),
  insuranceProductId: optional(nullable(number())),
  insuranceProgramId: optional(nullable(number())),
  partnerId: optional(nullable(number())),
  dateFrom: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  type: null,
  insuranceProductId: null,
  insuranceProgramId: null,
  partnerId: null,
  dateFrom: dayjs().subtract(3, 'months'),
  dateTo: dayjs(),
};
