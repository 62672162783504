import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { css } from '@emotion/css';

import { currencyFormat } from 'utils/formatters';

const cssRoot = css`
  padding: 8px;
`;

interface Props {
  total: number;
  currency: string;
}

const Footer = ({ total, currency }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={cssRoot}>
      <Typography.Text strong>
        {t('dashboard_extend.byProgram.total')}: {currencyFormat(total, currency)}
      </Typography.Text>
    </div>
  );
};

export default Footer;
