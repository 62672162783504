import { parsePhoneNumber } from 'awesome-phonenumber';

import { Language } from 'types';

export const validatePhoneNumber = (value: string) => {
  return parsePhoneNumber(value);
};

export const checkInn = (inn: string) => {
  if (inn.length !== 10) {
    return false;
  }

  const checkSum =
    ((-1 * Number(inn.charAt(0)) +
      5 * Number(inn.charAt(1)) +
      7 * Number(inn.charAt(2)) +
      9 * Number(inn.charAt(3)) +
      4 * Number(inn.charAt(4)) +
      6 * Number(inn.charAt(5)) +
      10 * Number(inn.charAt(6)) +
      5 * Number(inn.charAt(7)) +
      7 * Number(inn.charAt(8))) %
      11) %
    10;

  if (checkSum !== Number(inn.charAt(9))) {
    return false;
  }

  return true;
};

export const checkVinNumber = (vinNumber: string) => {
  return /^[0-9ABCDEFGHJKLMNPRSTUVWXYZБГДЕЭЖЗИЛПФЦЧШЩЮЯ]{5,17}$/i.test(vinNumber);
};

// pattern code must be uniq, be carefully
const replacingMap = {
  Б: '[ABCEHIKMOPTXАБВГДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯЪЭ]',
  Л: '[ABCDEFGHIJKLMNOPRSTUVWXYZАБВГДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯЪЭ]',
  Д: '[ABCEHIKMOPTXАБВГДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯЪЭ]',
  // extend for Д pattern
  AA: '([E|Е]D|D[C|С]|D[I|І]|[P|Р]D)',
  Ц: '\\d',
  Я: '[ABCDEFGHIJKLMNOPRSTUVWXYZАБВГДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯЪЭ]',
};

const pids = [
  // basic
  ...[
    ...[...['ДДЦЦЦЦЛЛ', 'ДДЛЛЦЦЦЦ'], ...['AAЦЦЦЦЛЛ', 'AAЛЛЦЦЦЦ']],
    ...['ЦЦББЦЦЦЦ', 'БЦЦЦЦББ', 'ЦЦЦЦБЦ'],
    ...['ЦЦЦЦЦЦББ', 'ЦЦЦЦЦББ', 'ЦЦЦЦББ', 'ЦЦЦЦБББ'],
    ...['ББЦЦЦЦ', 'ББЦЦЦЦЦ', 'БББЦЦЦЦ'],
  ],
  // MIA
  ...['ЦЦЦЦЦЦ', 'ББЦЦЦЛ'],
  // military
  ...['ЦЦЦ'],
  // temp
  ...['ЯЦББЦЦЦЦ', 'ЯЦЦЦЦЦББ'],
  // diplomatic
  ...[
    ...['CDPЦЦЦ'],
    ...['DPЦЦЦЦЦЦ', 'DPЦЦЦЦЦ', 'DPЦЦЦЦ'],
    ...['SЦЦЦЦЦЦ', 'FЦЦЦЦЦЦ', 'CЦЦЦЦЦЦ', 'DЦЦЦЦЦЦ', 'TЦЦЦЦЦЦ', 'MЦЦЦЦЦЦ', 'KЦЦЦЦЦЦ', 'HЦЦЦЦЦЦ', 'PЦЦЦЦЦЦ'],
    ...['SЦЦЦЦЦ'],
    ...['CDPЦЦЦЦ', 'CMDЦЦЦЦ'],
    ...['ЦЦЦFЦЦЦЦЦ', 'ЦЦЦSЦЦЦЦЦ', 'ЦЦЦBЦЦЦЦЦ', 'ЦЦЦCЦЦЦЦЦ', 'ЦЦЦDЦЦЦЦЦ'],
    ...['ЦЦЦDPЦЦЦЦ', 'ЦЦЦCCЦЦЦЦ'],
    ...['ЦЦЦCDPЦЦЦЦ'],
  ],
];

const patternsPid = pids.map((p) =>
  `^${p}$`.replace(new RegExp(Object.keys(replacingMap).join('|'), 'gi'), (m) => replacingMap[m]),
);

export const checkPid = (pid: string) => {
  return patternsPid.some((p) => new RegExp(p).test(pid));
};

// TODO front is it need wrapper for getting value from localStorage instead of just helper
// TODO front collect all slices from local storage
export const isLanguage = (slice: string | null) => {
  return typeof slice === 'string' && Object.values(Language).includes(slice as Language);
};
