import { t } from 'i18next';

import { object, Infer, string, refiner } from 'utils/struct';

export const schema = object({
  token: refiner(string(), (value) => {
    if (!value) {
      return t('schema.required');
    }

    if (value.length !== 4) {
      return t('modal.not_the_correct_format');
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  token: '',
};
