import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, notification } from 'antd';
import dayjs from 'dayjs';

import { getTerminationClaimPdf } from 'store/termination/actions';
import { getTerminationsDetailsTable } from 'api/contracts';
import { MenuIcon } from 'components/Icons';
import TableTools from './TableTools';
import Table from 'components/Table';
import columnsList from './columns';
import { moneyFormatter } from 'utils/helpers';
import { ROUTES } from 'constants/routes';
import { checkPermissions } from 'roles/userCan';
import { useCurrency } from '../../../../hooks/useCurrency';
import { AppState } from 'store/reducer';
import { ContractTerminationDetailsDto, ContractTerminationStatus } from 'types/dto/contracts-service';

import styles from '../ContractDetailsTables.module.less';

interface ITableProps {
  contractId: number;
}

export default ({ contractId }: ITableProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { currentCompanyCurrency } = useSelector((store: AppState) => ({
    currentCompanyCurrency: store.userLegacy.currentCompanyCurrency,
  }));

  const { currency } = useCurrency();

  const [terminationDetails, setTerminationDetails] = useState<ContractTerminationDetailsDto | null>(null);
  useEffect(() => {
    getTerminationsDetailsTable(contractId)
      .then((res) => {
        setTerminationDetails(res.data);
      })
      .catch(() => {
        notification.error({
          message: t('contract_details.error_terminations'),
          description: t('contract_details.loading_information_terminations'),
        });
      });
  }, []);

  const columns = columnsList(t).map(({ title, value }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
    };
    switch (value) {
      case 'contractPaymentOrderId':
      case 'terminationNumber': {
        return {
          ...basicColumn,
          width: 250,
        };
      }
      case 'realEndDate':
      case 'terminationDate':
      case 'payoutDate':
      case 'signDate': {
        return {
          ...basicColumn,
          render: (date: string | undefined) => (date ? dayjs(date).format('DD.MM.YYYY') : ''),
        };
      }
      case 'cause': {
        return {
          ...basicColumn,
          width: 300,
        };
      }
      case 'terminationStatus': {
        return {
          ...basicColumn,
          width: 135,
          render: (value: ContractTerminationStatus | undefined) => value && t(`enums.TerminationStatus.${value}`),
        };
      }
      case 'calculatedRefundSum': {
        return {
          ...basicColumn,
          render: (value: number) => `${moneyFormatter(value)} ${currentCompanyCurrency || currency}`,
        };
      }
      case 'actions': {
        return {
          ...basicColumn,
          title: '',
          render: (id: number, record: any) => {
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Dropdown
                  overlay={() => (
                    <TableTools
                      id={id}
                      record={record}
                      handleDownload={() => dispatch(getTerminationClaimPdf(Number(record.id), t))}
                    />
                  )}
                  placement="bottomRight"
                  trigger={['hover']}
                >
                  <span className="ant-dropdown-link">
                    <MenuIcon />
                  </span>
                </Dropdown>
              </div>
            );
          },
          width: 50,
          fixed: 'right' as 'right',
        };
      }
      default: {
        return basicColumn;
      }
    }
  });

  const onRowClick = useCallback((rowId) => {
    history.push(`${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${contractId}/terminations/${rowId}`);
  }, []);

  return (
    <Table
      onRow={(record) => ({
        onClick: () => {
          checkPermissions('TERMINATION', 'READ') && onRowClick(record.id);
        },
      })}
      className={styles.table}
      columns={columns}
      pagination={false}
      scroll={{ x: 1600 }}
      rowKey={(record: any) => `${record.actions}`}
      dataSource={terminationDetails ? [terminationDetails] : []}
      bordered
    />
  );
};
