import createInstance from './axios';
import { Values } from 'types/appointments';

const appointmentApi = createInstance('APPOINTMENT');

export const getHistory = async (body) => {
  const { data } = await appointmentApi.post('/appointment/history', body);
  return data;
};

export const cancelAppointment = async (id: string) => {
  return await appointmentApi.post(`/appointment/cancel?id=${id}`);
};

export const getSublimitsInfo = async (insuranceProgramId) => {
  const { data } = await appointmentApi.get(`/insurance-program/${insuranceProgramId}/details`);
  return data;
};

export const addSublimitInfo = async (body) => {
  const { data } = await appointmentApi.post('/insurance-program/details', body);
  return data;
};

export const editSublimitInfo = async (id, body) => {
  const { data } = await appointmentApi.put(`/insurance-program/details/${id}`, body);
  return data;
};

export const deleteSublimitInfo = async (id) => {
  const { data } = await appointmentApi.delete(`/insurance-program/details/${id}`);
  return data;
};

export const createAppointment = async (body) => {
  const { data } = await appointmentApi.post('/appointment/application', body);
  return data;
};

export const getSubevent = async (id) => {
  const { data } = await appointmentApi.get(`/appointment/subevent/${id}`);
  return data;
};

export const getClaim = async (id) => {
  const { data } = await appointmentApi.get(`/contract-claims/${id}`);
  return data;
};
