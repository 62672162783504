import React from 'react';
import { Dayjs } from 'dayjs';
import { PickerDateProps, RangePickerProps } from 'antd/es/date-picker/generatePicker';
import { css, cx } from '@emotion/css';
import i18next from 'i18next';
import { FieldErrors } from 'react-hook-form';

import { PickerAdapter } from 'utils/dayjs-adapter';
import locales from 'config/locales';
import { AnyObject, Language } from 'types';
import config from 'config';

import { ColorBase, colorBaseMap, Position, InputContainer } from '../InputContainer/InputContainer';

const cssRoot = css`
  && {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--pallete-redesign-gray-light);
    box-shadow: none;
    height: auto;
    padding: 12px 16px;
    input {
      font-size: 16px;
      font-weight: 400;
      line-height: 167%;
    }
  }
`;

type Props = PickerDateProps<Dayjs>;

export const DatePickerBase = (props: Props) => {
  return (
    <PickerAdapter
      format={config.format.date}
      locale={locales[i18next.language as Language].antd.DatePicker}
      {...props}
    />
  );
};

type DatePickerProps = Props & {
  label?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
};

export const DatePicker = ({
  label,
  classNameContainer,
  name,
  errors,
  requiredFields,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  ...props
}: DatePickerProps) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <PickerAdapter
        data-test={name}
        name={name}
        className={cx(
          cssRoot,
          css`
            && {
              background: ${colorBaseMap[colorBase]};

              &.ant-picker-disabled {
                background: ${colorBaseMap[colorBase]};
              }
            }
          `,
        )}
        format={config.format.date}
        locale={locales[i18next.language as Language].antd.DatePicker}
        {...props}
      />
    </InputContainer>
  );
};

export const MonthPicker = ({
  label,
  classNameContainer,
  name,
  errors,
  requiredFields,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  ...props
}: DatePickerProps) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <PickerAdapter.MonthPicker
        data-test={name}
        name={name}
        className={cx(
          cssRoot,
          css`
            && {
              background: ${colorBaseMap[colorBase]};

              &.ant-picker-disabled {
                background: ${colorBaseMap[colorBase]};
              }
            }
          `,
        )}
        format={config.format.monthYear}
        locale={locales[i18next.language as Language].antd.DatePicker}
        {...props}
      />
    </InputContainer>
  );
};

export const YearPicker = ({
  label,
  classNameContainer,
  name,
  errors,
  requiredFields,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  ...props
}: DatePickerProps) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <PickerAdapter.YearPicker
        data-test={name}
        name={name}
        className={cx(
          cssRoot,
          css`
            && {
              background: ${colorBaseMap[colorBase]};

              &.ant-picker-disabled {
                background: ${colorBaseMap[colorBase]};
              }
            }
          `,
        )}
        format={'YYYY'}
        locale={locales[i18next.language as Language].antd.DatePicker}
        {...props}
      />
    </InputContainer>
  );
};

type RangeDatePickerProps = RangePickerProps<Dayjs> & {
  label?: string;
  name?: string;
  errors?: FieldErrors;
  requiredFields?: AnyObject;
  classNameContainer?: string;
  colorBase?: ColorBase;
  errorPosition?: Position;
  isRequired?: boolean;
  hasError?: boolean;
};

export const RangeDatePicker = ({
  label,
  classNameContainer,
  name,
  errors,
  requiredFields,
  colorBase = 'none',
  errorPosition,
  isRequired,
  hasError,
  ...props
}: RangeDatePickerProps) => {
  return (
    <InputContainer
      name={name}
      errors={errors}
      requiredFields={requiredFields}
      label={label}
      className={classNameContainer}
      colorBase={colorBase}
      errorPosition={errorPosition}
      isRequired={isRequired}
      hasError={hasError}
    >
      <PickerAdapter.RangePicker
        data-test={name}
        name={name}
        className={cx(
          cssRoot,
          css`
            && {
              background: ${colorBaseMap[colorBase]};

              &.ant-picker-disabled {
                background: ${colorBaseMap[colorBase]};
              }
            }
          `,
        )}
        locale={locales[i18next.language as Language].antd.DatePicker}
        format={config.format.date}
        {...props}
      />
    </InputContainer>
  );
};
