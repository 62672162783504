import dayjs from 'dayjs';
import i18n from 'translations/i18n';

import { object, Infer, optional, number, date, nullable, refiner } from 'utils/struct';

export const schema = object({
  insuranceCompanyId: optional(nullable(number())),
  partnerId: optional(nullable(number())),
  regionId: optional(nullable(number())),
  partnerDepartmentId: optional(nullable(number())),
  dateFrom: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  insuranceCompanyId: null,
  partnerId: null,
  regionId: null,
  partnerDepartmentId: null,
  dateFrom: dayjs().startOf('month'),
  dateTo: dayjs(),
};
