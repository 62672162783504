import i18next from 'i18next';

import { InsuranceProgramStatisticsDto } from 'types/dto/contracts-service';

import { Data } from './ByProgramChart/ByProgramChart';

export const transformData = (data: InsuranceProgramStatisticsDto[]): InsuranceProgramStatisticsDto[] =>
  data.sort((a, b) => (b.contractPaymentSum ?? 0) - (a.contractPaymentSum ?? 0));

const TOP = 10;
export const transformDataForChart = (data: InsuranceProgramStatisticsDto[]): Data[] => {
  const top: Data[] = data.slice(0, TOP).map((el) => ({
    colorField: el.programName ?? '',
    angleField: el.contractPaymentSum ?? 0,
    raw: el,
  }));
  const othersRaw = data.slice(TOP).reduce(
    (acc, el) => {
      acc.contractPaymentSum = (acc.contractPaymentSum ?? 0) + (el.contractPaymentSum ?? 0);

      return acc;
    },
    { contractPaymentSum: 0 },
  );
  const others: Data = {
    colorField: i18next.t('dashboard_extend.OTHER'),
    angleField: othersRaw.contractPaymentSum ?? 0,
    raw: { ...othersRaw, programName: 'OTHER' },
  };

  return data.length > TOP ? [...top, others] : top;
};

export const getTotal = (data: InsuranceProgramStatisticsDto[]) =>
  data.reduce((r, d) => r + (d.contractPaymentSum ?? 0), 0);
