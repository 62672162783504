import React, { FunctionComponent, useState } from 'react';
import { Dropdown } from 'antd';
import { MenuIcon } from 'components/Icons';

const DropCustom: FunctionComponent<any> = ({ data, open, Component, ...props }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const handleVisibleChange = (flag: boolean): void => setIsVisible(flag);
  const setCurrentBrand = () => {
    open(data);
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlay={() => <Component {...props} open={setCurrentBrand} handleVisibleChange={handleVisibleChange} />}
        placement="bottomRight"
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={isVisible}
      >
        <span className="ant-dropdown-link" style={{ cursor: 'pointer' }}>
          <MenuIcon />
        </span>
      </Dropdown>
    </div>
  );
};

export default DropCustom;
