import { ThunkAction } from 'redux-thunk';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import { ListParams, ResultListDtoNotificationLog } from 'types/dto/notification-service';
import { apiNotification } from 'api/notification';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Notification extends List {
  loading?: boolean;
  data?: ResultListDtoNotificationLog | null;
}

export const actions = {
  setNotification: (payload: Partial<Notification>) =>
    ({ type: 'CLIENT_NOTIFICATIONS/SET_NOTIFICATION', payload } as const),
};

export const getNotification =
  (query: ListParams): ThunkType<void> =>
  async (dispatch, getState) => {
    const pagination = getState().clientNotifications.notification?.pagination;
    dispatch(actions.setNotification({ loading: true }));
    return apiNotification.notificationController
      .list({
        ...query,
        count: true,
        sorting: '-createdAt',
        page: pagination?.page,
        page_size: pagination?.pageSize,
      })
      .then((res) => {
        dispatch(actions.setNotification({ loading: false, data: res.data }));
      })
      .catch((error) => {
        dispatch(actions.setNotification({ loading: false, data: null }));
      });
  };

export interface StoreClientNotifications {
  notification: Notification | null;
}

const initialState: StoreClientNotifications = {
  notification: {
    loading: false,
    data: null,
    pagination: {
      page: 1,
      pageSize: 10,
    },
  },
};

const reducer = (state = initialState, action: InferActionTypes): StoreClientNotifications => {
  switch (action.type) {
    case 'CLIENT_NOTIFICATIONS/SET_NOTIFICATION':
      return { ...state, notification: { ...state.notification, ...action.payload } };
    default:
      return state;
  }
};

export default reducer;
