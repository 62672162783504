import React, { useState } from 'react';
import Modal, { ModalProvider } from 'styled-react-modal';
import styled from 'styled-components';
import { CloseIcon } from 'components/Icons';
import Input from './Modal/Input';
import { useTranslation } from 'react-i18next';

const StyledModal = Modal.styled`
  background: #FFFFFF;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.12);
  border-radius: 14px;
  min-width: 354px;
`;

const Header = styled.div`
  padding: 20px 16px 4px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #363636;
  margin-right: 16px;
`;

const ClostBtn = styled.button`
  background: #f7f7f9;
  border-radius: 6px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 0;
`;

const Content = styled.div`
  padding: 16px;
  margin-bottom: 17px;
`;

const Footer = styled.div`
  border-top: 1px solid rgba(149, 159, 166, 0.2);
  display: flex;
`;

const Button = styled.button`
  padding: 16px 16px 16px 17px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  border: none;
  border-left: 1px solid #eaeced;
  background: none;
  cursor: pointer;
  flex: 1;
  outline: none;

  &:first-child {
    border-left: none;
    padding: 16px;
  }
`;

// @ts-ignore
const CancelButton = styled(Button)`
  color: #a9b5bd;

  &:hover {
    color: #3a9a3b;
  }
`;

const ConfirmButton = styled(Button)`
  color: #a9b5bd;

  &:hover {
    color: #3a9a3b;
  }
`;

const TextAreaModal = ({ initialValue, visible, onClose, onChange, label }: any) => {
  const [value, setValue] = useState(initialValue);
  const { t } = useTranslation();

  return (
    <ModalProvider>
      <StyledModal isOpen={visible} onBackgroundClick={() => onClose()}>
        <Header>
          <Title>{t('modal.editing')}</Title>
          <ClostBtn onClick={() => onClose()}>
            <CloseIcon />
          </ClostBtn>
        </Header>
        <Content>
          <Input value={value} label={label} onChange={(e: any) => setValue(e.target.value)} />
        </Content>
        <Footer>
          <CancelButton onClick={() => onClose()}>{t('modal.cancel')}</CancelButton>
          <ConfirmButton
            onClick={() => {
              onChange(value);
              onClose();
            }}
          >
            {t('modal.save_changes')}
          </ConfirmButton>
        </Footer>
      </StyledModal>
    </ModalProvider>
  );
};

TextAreaModal.defaultProps = {
  onClose: () => {},
  onChange: () => {},
};

export default TextAreaModal;
