import { AnyAction } from 'redux';
import { composeReducer } from 'redux-compose-reducer';

import { TYPES } from './actions';
import { UIState } from 'types/ui';

const initialState: UIState = {
  modals: {
    uploadModal: {
      isOpen: false,
      data: [],
    },
    editModal: {
      isOpen: false,
      data: [],
    },
    agentModal: {
      isOpen: false,
      data: [],
    },
    programModal: {
      isOpen: false,
      data: [],
    },
    subDivisionModal: {
      isOpen: false,
      data: [],
    },
    addNewRegion: {
      isOpen: false,
      data: [],
    },
    addDataViaUpload: {
      isOpen: false,
      data: [],
    },
    risksModal: {
      isOpen: false,
      data: [],
    },
    prologationCangeModal: {
      isOpen: false,
      data: [],
    },
    tariffsModal: {
      isOpen: false,
      data: [],
    },
    coefficientsModal: {
      isOpen: false,
      data: [],
    },
    insurancePoliciesModal: {
      isOpen: false,
      data: [],
    },
    addTokenModal: {
      isOpen: false,
      data: [],
    },
    compensationModal: {
      isOpen: false,
      data: [],
    },
    insuranceTypeModal: {
      isOpen: false,
      data: [],
    },
    insuranceRisksModal: {
      isOpen: false,
      data: [],
    },
    installmentsModal: {
      isOpen: false,
      data: [],
    },
    ruptureInitiator: {
      isOpen: false,
      data: [],
    },
    terminationDocuments: {
      isOpen: false,
      data: [],
    },
    sublimitsModal: {
      isOpen: false,
      data: [],
    },
    settlementTypesModal: {
      isOpen: false,
      data: [],
    },
    duplicateModel: {
      isOpen: false,
      data: [],
    },
    refusalReasonModal: {
      isOpen: false,
      data: [],
    },
    paymentRecipientModal: {
      isOpen: false,
      data: [],
    },
    uploadDevicePhotoModal: {
      isOpen: false,
      data: [],
    },
    discountsModal: {
      isOpen: false,
      data: [],
    },
    parnerCoefficientsModal: {
      isOpen: false,
      data: [],
    },
    parametrsDiscountModal: {
      isOpen: false,
      data: [],
    },
    promoCodeModal: {
      isOpen: false,
      data: [],
    },
  },
};

const toggleModal = (state: UIState = initialState, { payload: { isOpen, name, data } }: AnyAction): UIState => {
  return {
    modals: {
      ...state.modals,
      [name]: {
        isOpen,
        data,
      },
    },
  };
};

export default composeReducer({
  types: TYPES,
  initialState,
  reducers: {
    toggleModal,
  },
});
