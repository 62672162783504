import React from 'react';
import columns from './columns';
import { IContract } from '../../../../types/actsList';
import numeral from 'numeral';
import moment from 'moment';
import Table from '../../../Table';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/reducer';
import { useCurrency } from '../../../../hooks/useCurrency';
import { ContractView } from 'types/dto/contracts-service';

interface IContractsProps {
  data: ContractView[];
  onContractDetailsClick: () => any;
  classes: any;
}

const CURRENCY_FORMAT = '0,0.00';
const formatCurrency = (value: string | number, currency: string = '₴') =>
  numeral(value).format(CURRENCY_FORMAT) + ` ${currency}`;

const Contracts: React.FC<IContractsProps> = ({ classes, data: rawData, onContractDetailsClick }) => {
  const { t } = useTranslation();
  const { currency } = useCurrency();

  const data = rawData.map((d) => ({
    ...d,
    conclusionDate: moment(d.conclusionDate).format('DD.MM.YYYY'),
    insuranceAmount: formatCurrency(d.insuranceAmount ?? 0, currency),
    paymentAmount: formatCurrency((d as any, currency).paymentAmount, currency),
  }));

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container spacing={2} justify={'space-between'} alignItems={'center'}>
          <Grid item>
            <span>ON-LINE {t('dash_board.insurance_scoreboard')}</span>
          </Grid>
          <Grid item>
            <Button onClick={onContractDetailsClick}>{t('dash_board.read_more_button')}</Button>
          </Grid>
        </Grid>
      </div>
      <Table
        scroll={{ x: 1200, y: 460 }}
        columns={columns(t)}
        pagination={false}
        rowKey={'id'}
        dataSource={data}
        bordered
      />
    </div>
  );
};

export default withStyles(styles)(Contracts);
