import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import { object, Infer, optional, number, date, string, nullable, refiner } from 'utils/struct';

export const schema = object({
  insuranceCompanyId: optional(nullable(number())),
  partnerId: optional(nullable(number())),
  partnerDepartmentId: optional(nullable(number())),
  username: optional(string()),
  dateFrom: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value) => {
    if (!value) {
      return i18n.t('schema.required').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InitialValues = Record<keyof Values, any>;

export const initialValues: InitialValues = {
  insuranceCompanyId: null,
  partnerId: null,
  partnerDepartmentId: null,
  username: '',
  dateFrom: dayjs().startOf('month'),
  dateTo: dayjs(),
};
