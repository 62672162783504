import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { SorterResult } from 'antd/lib/table/interface';
import { Key } from 'react';

export type AnyObject = { [x: string]: any };

// locale should be in BCP-47 language tag format (/^[a-z]{2}-[A-Z]{2}$/)
// 'Primary language subtag - Region subtag'
// locale validator https://schneegans.de/lv/
export enum Language {
  uk_UA = 'uk-UA',
  ru_RU = 'ru-RU',
  en_US = 'en-US',
}

// THE_MAIN_DIFFERENCE from Language that Locale just helper for sending value to back
// (collection of all available choices in one place)
// you may notice that keys isn't the same as Language
export enum Locale {
  ua = 'ua',
  ru = 'ru',
  en = 'en',
  uz = 'uz',
}

export interface Params {
  id: string;
  terminationId: string;
  tab: string;
  orderId: string;
  partnerId: string;
}
export interface LocationState {}
export type UseHistory = LocationState | undefined;

export interface Option {
  value: string | number;
  key: string | number;
  title: string;
}

interface ErrorDto {
  code: string;
  details?: string;
}
export interface ErrorsDto {
  errors: ErrorDto[];
}
export interface ErrorEDto {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

export type SortBy = SorterResult<any> | SorterResult<any>[];

export interface PageBy {
  page: number;
  pageSize: number;
}

export type PrivateFilters = Record<string, (Key | boolean)[] | null>;

export interface List {
  sorting?: SortBy;
  pagination?: PageBy;
  privateFilters?: PrivateFilters;
}

// redux actions types
type PropertyTypes<T> = T extends { [x: string]: infer U } ? U : never;
export type ActionTypes<T extends { [x: string]: (...args: any[]) => any }> = ReturnType<PropertyTypes<T>>;

export interface IMapAutoComplate extends FormComponentProps {
  fieldId: string;
  fieldName: string;
  filterType: string;
}

// dynamic values from configuration
export enum ProgramCode {
  MZ_CI_A = 'MZ_CI_A',
  MZ_CI_B = 'MZ_CI_B',
  MZ_CI_C = 'MZ_CI_C',
  MK_D_I = 'MK_D_I',
  OSAGO_ECONOM = 'OSAGO_ECONOM',
  VZR_UKRAINE_B = 'VZR_UKRAINE_B',
  VZR_GOLD = 'VZR_GOLD',
  VZR_SILVER = 'VZR_SILVER',
  VZR_PLATINUM = 'VZR_PLATINUM',
  VZR_UKRAINE_A_VISIT = 'VZR_UKRAINE_A_VISIT',
  MED_DMS_S = 'MED_DMS_S',
  MED_DMS_WOG = 'MED_DMS_WOG',
}

export interface LabelInValue<T> {
  key: T;
  label: string;
}

export enum PartnerTabKey {
  MAIN = 'MAIN',
  INSURANCE_COMPANIES = 'INSURANCE_COMPANIES',
  AGENTS = 'AGENTS',
  PROGRAMS = 'PROGRAMS',
  SUBDIVISIONS = 'SUBDIVISIONS',
  USERS = 'USERS',
  USERS_ACTIVITY = 'USERS_ACTIVITY',
  REGIONS = 'REGIONS',
  OPTIONS = 'OPTIONS',
  NEWOPTIONS = 'NEWOPTIONS',
  INTERNET_SALES = 'INTERNET_SALES',
  WHITE_LABEL = 'WHITE_LABEL',
  DISCOUNTS = 'DISCOUNTS',
}

export enum ProfileTabKey {
  PERSONAL_INFO = 'PERSONAL_INFO',
  PASSWORD = 'PASSWORD',
  INSURED_PERSONS = 'INSURED_PERSONS',
}
