import { ThunkAction } from 'redux-thunk';
import dayjs from 'dayjs';

import { AppState } from 'store/reducer';
import { ActionTypes, List } from 'types';
import {
  PartnerView,
  ListParams,
  ResultListDtoAuthUserView,
  InsuranceCompanyView,
  CompanyView,
  List9Params,
  List2Params,
} from 'types/dto/contracts-service';
import { getDateRange3, getPageBy, getPrivateFilters, getSortBy } from 'utils/request';
import { Values, initialValues } from 'screens/Users/Filters/Filters.schema';
import config from 'config';
import { apiContracts } from 'api/contracts';
import onDownload from 'callbacks/onDownload';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

interface Data extends List {
  loading: boolean;
  data: ResultListDtoAuthUserView | null;
}
type Filters = Values & { initialization: boolean };
interface InsuranceCompanies {
  loading: boolean;
  data: InsuranceCompanyView[];
}
interface Partners {
  loading: boolean;
  data: PartnerView[];
}
interface PartnerDepartments {
  loading: boolean;
  data: CompanyView[];
}

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'USERS/SET_DATA', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'USERS/SET_FILTERS', payload } as const),
  setInsuranceCompanies: (payload: Partial<InsuranceCompanies>) =>
    ({ type: 'USERS/SET_INSURANCE_COMPANIES', payload } as const),
  setPartners: (payload: Partial<Partners>) => ({ type: 'USERS/SET_PARTNERS', payload } as const),
  setPartnerDepartments: (payload: Partial<PartnerDepartments>) =>
    ({ type: 'USERS/SET_PARTNER_DEPARTMENTS', payload } as const),
};

export const downloadReport = (): ThunkType<void> => async (dispatch, getState) => {
  const users = getState().users;
  const sorting = getSortBy(users.data.sorting);

  await onDownload(() =>
    apiContracts.userController
      .printXlsxReport(
        {
          timeZone: dayjs.tz.guess(),
          insuranceCompanyId: users.filters.insuranceCompanyId?.toString(),
          partnerId: users.filters.partnerId?.toString(),
          partnerDepartmentId: users.filters?.partnerDepartmentId?.toString(),
          username: users.filters?.username ?? undefined,
          signupDate: getDateRange3(users.filters?.dateFrom, users.filters?.dateTo) ?? '',
          sorting,
          ...getPrivateFilters(users.data.privateFilters),
        },
        { format: 'blob' },
      )
      .then((res) => ({ data: res.data as unknown as Blob, headers: res.headers })),
  );
};

export const requestData =
  (params: ListParams = {}): ThunkType<Promise<ResultListDtoAuthUserView>> =>
  async (dispatch, getState) => {
    const users = getState().users;
    const sorting = getSortBy(users.data.sorting);

    return apiContracts.userController
      .list({
        count: true,
        insuranceCompanyId: users.filters.insuranceCompanyId?.toString(),
        partnerId: users.filters.partnerId?.toString(),
        partnerDepartmentId: users.filters?.partnerDepartmentId?.toString(),
        username: users.filters?.username || undefined,
        signupDate: getDateRange3(users.filters?.dateFrom, users.filters?.dateTo) ?? '',
        sorting,
        ...getPrivateFilters(users.data.privateFilters),
        ...params,
      })
      .then((res) => res.data);
  };

export const loadData = (): ThunkType<void> => async (dispatch, getState) => {
  const users = getState().users;

  dispatch(actions.setData({ loading: true, data: null }));

  dispatch(requestData({ ...getPageBy(users.data.pagination) }))
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false, data: null }));
    });
};

export const loadInsuranceCompanies = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setInsuranceCompanies({ loading: true, data: [] }));

  apiContracts.insuranceCompanyController
    .list4({ distinct: true, page_size: -1, attributes: 'id,name', insuranceCompanyId: '<>null' })
    .then((res) => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: res.data.resultList ?? [] }));
    })
    .catch(() => {
      dispatch(actions.setInsuranceCompanies({ loading: false, data: [] }));
    });
};

export const loadPartners =
  (query: List2Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartners({ loading: true, data: [] }));

    apiContracts.partnerController
      .list2({
        distinct: true,
        page_size: -1,
        ...query,
      })
      .then((res) => {
        dispatch(actions.setPartners({ loading: false, data: res.data.resultList ?? [] }));
      })
      .catch(() => {
        dispatch(actions.setPartners({ loading: false, data: [] }));
      });
  };

export const loadPartnerDepartments =
  (query: List9Params = {}): ThunkType<void> =>
  async (dispatch) => {
    dispatch(actions.setPartnerDepartments({ loading: true, data: [] }));

    apiContracts.companyController
      .list9(query)
      .then((res) => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: res.data }));
      })
      .catch(() => {
        dispatch(actions.setPartnerDepartments({ loading: false, data: [] }));
      });
  };

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  const insuranceCompany = getState().me.data?.details?.insuranceCompany;

  if (insuranceCompany) {
    dispatch(actions.setFilters({ insuranceCompanyId: insuranceCompany.id }));
    dispatch(actions.setInsuranceCompanies({ data: [{ id: insuranceCompany.id, name: insuranceCompany.name }] }));
  }

  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadInsuranceCompanies());
  dispatch(loadPartners({ insuranceCompanyId: insuranceCompany ? insuranceCompany.id?.toString() : '<>null' }));
};

export interface StoreUsers {
  data: Data;
  filters: Filters;
  insuranceCompanies: InsuranceCompanies;
  partners: Partners;
  partnerDepartments: PartnerDepartments;
}
export const initialState: StoreUsers = {
  data: {
    loading: false,
    data: null,
    pagination: { page: 1, pageSize: config.ui.pagination.size },
    sorting: { columnKey: 'signupDate', order: 'descend' },
    privateFilters: {},
  },
  filters: { ...initialValues, initialization: true },
  insuranceCompanies: { loading: false, data: [] },
  partners: { loading: false, data: [] },
  partnerDepartments: { loading: false, data: [] },
};

const reducer = (state = initialState, action: InferActionTypes): StoreUsers => {
  switch (action.type) {
    case 'USERS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'USERS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'USERS/SET_INSURANCE_COMPANIES':
      return { ...state, insuranceCompanies: { ...state.insuranceCompanies, ...action.payload } };
    case 'USERS/SET_PARTNERS':
      return { ...state, partners: { ...state.partners, ...action.payload } };
    case 'USERS/SET_PARTNER_DEPARTMENTS':
      return { ...state, partnerDepartments: { ...state.partnerDepartments, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
