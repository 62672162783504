import { css } from '@emotion/css';
import { Col, Drawer, Row } from 'antd';
import Icon from 'components/nextRedesign/Icon/Icon';
import React, { MouseEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import palleteRedesign from 'styles/palleteRedesign';
import { UseHistory } from 'types';
import { AlertCard } from './AlertCard/AlertCard';
import { AppDispatch, AppState } from 'store/reducer';
import { ROUTES } from 'constants/routes';
import { actions } from 'store/me/me';

const cssLinkBtn = css`
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: ${palleteRedesign.gray.main};
`;

const cssTitle = css`
  font-size: 28px;
  font-weight: 700;
  color: ${palleteRedesign.gray.main};
`;

const cssDrawer = css`
  .ant-drawer-header {
    padding: 32px;
    border: none;
  }

  .ant-drawer-body {
    padding: 32px;
  }

  .ant-drawer-content-wrapper {
    height: 94%;
    box-shadow: none;
  }

  .ant-drawer-content {
    border-radius: 0 0 0 8px;
    box-shadow: 0px 7px 17px 0px rgba(0, 40, 86, 0.12);
  }
`;

const cssDrawerIcon = css`
  position: absolute;
  box-sizing: content-box !important;
  left: 0;
  top: 120px;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 8px solid ${palleteRedesign.gray.lighter};
  background: ${palleteRedesign.basic.white};
  transform: translate(-50%, -50%);
`;

export const AlertPanel = () => {
  const { t } = useTranslation();
  const history = useHistory<UseHistory>();
  const notification = useSelector((state: AppState) => state.me.notification);
  const dispatch = useDispatch<AppDispatch>();

  // const drawerRef = useRef<HTMLDivElement>(null);

  // const handleClick = (e) => {
  //   if (!drawerRef.current) {
  //     return;
  //   }

  //   if (!drawerRef.current.contains(e.target)) {
  //     console.log('click outside');
  //     onClose();
  //     return;
  //   }
  //   console.log('click inside');
  // };

  // useEffect(() => {
  //   if (!notification?.isOpenAlertPanel) {
  //     return;
  //   }

  //   document.addEventListener('mousedown', handleClick);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClick);
  //   };
  // }, [notification?.isOpenAlertPanel]);

  const onClose = () => {
    dispatch(actions.setNotification({ isOpenAlertPanel: false }));
  };

  return (
    // <div ref={drawerRef}>
    <Drawer
      title={<span className={cssTitle}>{t('dashboard_client.alert_panel_title')}</span>}
      width={300}
      maskStyle={{}}
      closable={false}
      mask={false}
      open={notification?.isOpenAlertPanel ?? false}
      className={cssDrawer}
      extra={
        <span
          className={cssLinkBtn}
          onClick={() => {
            onClose();
            history.push(ROUTES.CLIENT.NOTIFICATION);
          }}
        >
          {t('dashboard_client.show_all')}
        </span>
      }
    >
      <div className={cssDrawerIcon}>
        <Icon type="close" size="12px" color={'gray'} onClick={onClose} />
      </div>
      <Row gutter={[16, 48]}>
        {notification?.data?.resultList?.map((notification) => (
          <Col span={24} key={notification.id}>
            <AlertCard notification={notification} onClose={onClose} />
          </Col>
        ))}
      </Row>
    </Drawer>
    // </div>
  );
};
