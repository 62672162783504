import React, { useCallback, useEffect, useState } from 'react';
import { css, cx } from '@emotion/css';
import { Layout, Menu, MenuProps, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import pallete from 'styles/pallete';
import { AppState } from 'store/reducer';
import history from 'routes/history';
import { getProducts } from 'store/insuranceProducts/actions';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'constants/routes';
import { checkPermissions, getUserRoleNext } from 'roles/userCan';
import { DRON_UA, LIFECELL, LIFECELL2, SOTA_TOV, TECHNO, TEST_COVID, VF_RETAIL_TOV } from 'constants/partners';
import { apiAuth } from 'api/auth';
import { can } from 'utils/can';
import { PermissionEnum, RoleEnum } from 'types/dto/auth-service';
import {
  IconActs,
  IconArrowRight,
  IconBroken,
  IconCase,
  IconChats,
  IconDevices,
  IconEdit,
  IconEmail,
  IconGuard,
  IconHome,
  IconLogoInrisk,
  IconLogout,
  IconQuestion,
  IconRemove,
  IconRepair,
  IconSearch,
  IconSetting,
} from '../Icon/Icon';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { Link } from 'react-router-dom';

const cssRoot = css`
  overflow-x: clip;
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1000;
  padding: 20px 0;
  /* Hide scrollbar for Chrome, Safari and Opera/ */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

const cssSiderWrapperCollapsed = css`
  width: 60px;
`;

const cssSiderWrapper = css`
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1000;
  width: 310px;
  transition: all 0.3s;
`;

const cssLogoWrapper = css`
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  padding: 0 10px;
`;

const cssLogo = css`
  margin-right: 10px;
`;

const cssButton = css`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--pallete-bluegray-lightest);
  margin: 0 auto;
`;

const cssCollapseButton = css`
  border: 6px solid var(--pallete-bluegray-lighter);
  transition: border 0.1s;
  position: absolute;
  right: 0;
  top: 70px;
  transform: translateX(50%);
  background: var(--pallete-basic-white);
  cursor: pointer;
  width: 46px;
  height: 46px;
  border-radius: 17px;
  z-index: 1001;
`;

const cssButtonActive = css`
  border: 6px solid #a5a5a8;
  cursor: pointer;
`;

const cssMask = css`
  z-index: 999;
  height: 100vh;
  background: transparent;
  transition: background 0.2s;
  width: 0px;
  position: fixed;
`;

const cssMaskActive = css`
  background: rgba(49, 49, 49, 0.39);
  width: 100%;
`;

const cssMenuWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const cssMenuItemInfo = css`
  display: flex;
  align-items: center;

  &&& .anticon {
    color: var(--pallete-basic-white);
    background-color: var(--ant-primary-color);
  }
`;

const cssMenuItemHelp = css`
  display: flex;
  align-items: center;
`;

const cssInner = css`
  line-height: 1.3;
`;

const cssMenu = css`
  .ant-menu-item {
    line-height: 42px;
    .anticon {
      min-width: 32px;
      padding: 8px;
      border-radius: 8px;
      color: var(--pallete-bluegray-main);
      line-height: 0 !important;
      background-color: var(--pallete-bluegray-lightest);
    }
  }

  .ant-menu-item:hover {
    background-color: var(--ant-primary-1);
  }

  &.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 16px;
  }

  && .ant-menu-item-selected {
    background: none;

    .anticon {
      color: var(--pallete-basic-white);
      background-color: var(--ant-primary-color);
    }
  }
`;

export const getTelegramUrl = (partnerName) => {
  switch (partnerName) {
    case SOTA_TOV:
      return 'https://t.me/joinchat/Sh50INDflysyYTRi';
    case TECHNO:
      return 'https://t.me/joinchat/-2b4p9K0xqwxMjVi';
    default:
      return 'https://t.me/shidzahid';
  }
};

interface Props {
  openModal: () => void;
}

const Sider = ({ openModal }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const insuranceCompany = useSelector((store: AppState) => store.me.data?.details?.insuranceCompany);
  const telegramChatLink = useSelector((store: AppState) => store.me.data?.current?.telegramChatLink);
  const partnerName = useSelector((store: AppState) => store.me.data?.current?.partnerName);
  const partnerDepartmentName = useSelector((store: AppState) => store.me.data?.current?.partnerDepartmentName);
  const email = useSelector((store: AppState) => store.me.data?.details?.email);

  const [collapsed, setCollapsed] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState(['home']);

  const onLogoutClick = useCallback(async () => {
    const role = getUserRoleNext();

    await apiAuth.authenticationEndpoints.logout();

    dispatch({ type: 'RESET_STORE' });

    sessionStorage.removeItem('currentCurrency');
    localStorage.removeItem('companyCode');

    history.push(role === RoleEnum.ROLE_CLIENT ? ROUTES.AUTH.CLIENT_LOGIN : ROUTES.PARTNER_AUTH.LOGIN);
    localStorage.removeItem('accessToken');
  }, []);

  const getTelegramPermission = () => {
    if (partnerName) {
      return [VF_RETAIL_TOV, SOTA_TOV, TECHNO, LIFECELL, TEST_COVID, DRON_UA, LIFECELL2].includes(partnerName);
    }
    return true;
  };

  useEffect(() => {
    // TODO fix this request
    dispatch(getProducts(t));
    const pathArr = history.location.pathname.split('/');
    const rootPath = pathArr[1];
    const isCreateContract = pathArr[2] === 'contract' && pathArr[3] === 'create';

    if (selectedKeys[0] === rootPath && selectedKeys[0] !== 'createContract' && !isCreateContract) return;

    if (ROUTES.CONTRACTS_LIST.ROOT.includes(rootPath)) setCollapsed(true);
    if (rootPath === 'dashboard') setCollapsed(true);
    if (rootPath !== 'dashboard') {
      if (isCreateContract) {
        return setSelectedKeys(['createContract']);
      }
      setSelectedKeys([rootPath]);
    } else {
      setSelectedKeys(['dashboard']);
    }
  }, [history.location]);

  useEffect(() => {
    document.body.style.overflow = collapsed ? '' : 'hidden';
  }, [collapsed]);

  const onClick: MenuProps['onClick'] = (e) => {
    const disabledMenuItems = ['telegram', 'help', 'wiki'];
    setCollapsed(true);

    if (disabledMenuItems.includes(e.key)) {
      return;
    }

    setSelectedKeys([e.key]);
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const itemsTop: (ItemType & { visible: boolean; 'data-test'?: string })[] = [
    {
      key: 'dashboard',
      icon: <IconHome />,
      label: <Link to={ROUTES.DASHBOARD.ROOT}>{t('side_menu.home')}</Link>,
      visible: true,
      'data-test': 'Dashboard-Button',
    },
    {
      key: 'search',
      icon: <IconSearch />,
      label: <Link to={ROUTES.SEARCH.ROOT}>{t('side_menu.search')}</Link>,
      visible: checkPermissions('SEARCH', 'READ', true),
      'data-test': 'Search-Button',
    },
    {
      key: 'contract',
      icon: <IconEdit />,
      label: t('side_menu.new_contract'),
      visible: checkPermissions('CONTRACT', 'CREATE_SIDE_MENU_AND_CONTRACT_LIST', true),
      onClick: openModal,
      'data-test': 'Create-Contract-Button',
    },
    {
      key: 'contracts-list',
      icon: <IconCase />,
      label: <Link to={ROUTES.CONTRACTS_LIST.ROOT}>{t('side_menu.insurance_contracts')}</Link>,
      visible: checkPermissions('CONTRACTS_LIST', 'READ', true),
      'data-test': 'Contract-List-Button',
    },
    {
      key: 'acts',
      icon: <IconActs />,
      label: <Link to={ROUTES.ACTS_LIST.ROOT}>{t('side_menu.acts_of_work_performed')}</Link>,
      visible: checkPermissions('ACTS', 'READ'),
      'data-test': 'Acts-List-Button',
    },
    {
      key: 'terminations-list',
      icon: <IconBroken />,
      label: <Link to={ROUTES.TERMINATIONS_LIST.ROOT}>{t('side_menu.register_of_ruptures')}</Link>,
      visible: can('read', PermissionEnum.TERMINATION_REGISTRY),
      'data-test': 'Terminations-List-Button',
    },
    {
      key: 'insured-events',
      icon: <IconGuard />,
      label: <Link to={ROUTES.INSURED_EVENTS.LIST.ROOT}>{t('side_menu.insurance_cases')}</Link>,
      visible: checkPermissions('INSURANCE_LIST', 'READ'),
      'data-test': 'Insured-Events-Button',
    },

    {
      key: 'registration-devices',
      icon: <IconDevices />,
      label: <Link to={ROUTES.REGISTRATION_DEVICES.ROOT}>{t('side_menu.register_equipment_sales')}</Link>,
      visible: checkPermissions('REGISTER_DEVICES', 'READ', true),
      'data-test': 'Registration-Devices-Button',
    },
  ];

  const itemsBottom: (ItemType & { visible: boolean; 'data-test'?: string })[] = [
    {
      key: 'settings',
      icon: <IconSetting />,
      label: <Link to={ROUTES.SETTINGS.ROOT}>{t('side_menu.settings')}</Link>,
      visible: true,
      'data-test': 'Settings-Button',
    },
    {
      key: 'help',
      icon: <IconRepair />,
      className: cssMenuItemHelp,
      label: (
        <>
          <div className={cssInner}>{t('side_menu.INRISK_system', { version: process.env.REACT_APP_VERSION })}</div>
          <div className={cssInner}>&#169; CHI Software</div>
        </>
      ),
      visible: checkPermissions('HELP', 'READ'),
      'data-test': 'Help-Button',
    },
    {
      key: 'telegram',
      icon: <IconChats />,

      label: t('side_menu.online_chat'),
      visible: getTelegramPermission() && checkPermissions('TELEGRAM', 'READ'),
      onClick: () => window.open(!telegramChatLink ? getTelegramUrl(partnerName) : telegramChatLink, '_blank'),
      'data-test': 'Telegram-Button',
    },
    {
      key: 'wiki',
      icon: <IconQuestion />,
      label: t('side_menu.wiki_guide'),
      visible: true,
      onClick: () =>
        window.open('https://mediawiki.inrisk.com.ua/wiki/%D0%A1%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0_INRISK', '_blank'),
      'data-test': 'Wiki-Button',
    },
    {
      key: 'info',
      icon: <IconEmail />,
      className: cssMenuItemInfo,
      label: (
        <>
          <div className={cssInner}>{email}</div>
          {partnerDepartmentName && <div className={cssInner}>{partnerDepartmentName}</div>}
        </>
      ),
      visible: true,
      'data-test': 'Info-Button',
    },
    {
      key: 'logout',
      icon: <IconLogout />,
      label: t('side_menu.log_out'),
      visible: true,
      onClick: onLogoutClick,
      'data-test': 'Logout-Button',
    },
  ];
  const itemsTopVisible = itemsTop.filter((item) => item.visible).map(({ visible, ...item }) => item);
  const itemsBottomVisible = itemsBottom.filter((item) => item.visible).map(({ visible, ...item }) => item);
  return (
    <div className={cx(cssSiderWrapper, { [cssSiderWrapperCollapsed]: collapsed })}>
      <div className={cx(cssButton, cssCollapseButton, { [cssButtonActive]: !collapsed })} onClick={toggle}>
        {collapsed ? (
          <IconArrowRight color={'var(--pallete-bluegray-main)'} />
        ) : (
          <IconRemove color={'var(--pallete-bluegray-main)'} />
        )}
      </div>
      <>
        <Layout.Sider
          theme="light"
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={cssRoot}
          collapsedWidth={60}
          width={310}
          id="sider-Menu"
        >
          <div className={cssLogoWrapper}>
            <Tooltip title={insuranceCompany?.name && collapsed ? insuranceCompany.name : null} placement="right">
              <IconLogoInrisk
                color="primary"
                background="primary"
                className={cx('margin-right: 0;', { [cssLogo]: !collapsed })}
                style={{ fontSize: '24px' }}
              />
            </Tooltip>
            {insuranceCompany?.name && !collapsed && <div>{insuranceCompany.name}</div>}
          </div>
          <div className={cssMenuWrapper}>
            <Menu className={cssMenu} selectedKeys={selectedKeys} items={itemsTopVisible} onClick={onClick} />
            <Menu className={cssMenu} selectedKeys={selectedKeys} items={itemsBottomVisible} onClick={onClick} />
          </div>
        </Layout.Sider>
        <div onClick={() => setCollapsed(!collapsed)} className={cx(cssMask, { [cssMaskActive]: !collapsed })} />
      </>
    </div>
  );
};

export default Sider;
