import i18n from 'i18next';

import { nonempty, string, refiner, object, Infer } from 'utils/struct';

export const schema = object({
  newPassword: refiner(nonempty(string()), (value) => {
    if (!/^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9])(.{8,})*$/.test(value)) {
      return i18n.t('passwordRecovery.password_must_contain_at_least_8_characters').toString();
    }

    return true;
  }),
  confirmNewPassword: refiner(string(), (value, ctx) => {
    const { newPassword } = ctx.branch[0] as Values;

    if (value !== newPassword) {
      return i18n.t('passwordRecovery.passwordMatch').toString();
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const defaultValues: Values = {
  newPassword: '',
  confirmNewPassword: '',
};
