import { Locale } from 'antd/lib/locale-provider';
import en_US from 'antd/lib/locale/en_US';
import ru_RU from 'antd/lib/locale/ru_RU';
import uk_UA from 'antd/lib/locale/uk_UA';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import 'dayjs/locale/uk';

import { AnyObject, Language } from 'types';
import { en_US as en_US_TRANSLATIONS } from 'translations/en';
import { ru_RU as ru_RU_TRANSLATIONS } from 'translations/ru';
import { uk_UA as uk_UA_TRANSLATIONS } from 'translations/uk';

export interface LocaleOptions {
  title: string;
  // TODO front can be changed to title?
  titleShort: string;
  translation: AnyObject;
  antd: Locale;
}

// TODO front formats from config should belong to locale
const locales: Record<Language, LocaleOptions> = {
  [Language.en_US]: {
    title: 'English',
    titleShort: 'EN',
    translation: en_US_TRANSLATIONS,
    antd: en_US,
  },
  [Language.ru_RU]: {
    title: 'Русский',
    titleShort: 'RU',
    translation: ru_RU_TRANSLATIONS,
    antd: ru_RU,
  },
  [Language.uk_UA]: {
    title: 'Українська',
    titleShort: 'UA',
    translation: uk_UA_TRANSLATIONS,
    antd: uk_UA,
  },
};

export default locales;
