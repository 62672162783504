import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/next/Button/Button';

interface Props {
  handleBack: () => void;
}

const Back = ({ handleBack }: Props) => {
  const { t } = useTranslation();

  return (
    <Button icon={<ArrowLeftOutlined />} type="text" onClick={handleBack}>
      {t('signup.back')}
    </Button>
  );
};

export default Back;
