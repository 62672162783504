import React, { useEffect, useState } from 'react';
import columnsList from './columns';
import Table from 'components/Table';
import styles from 'components/tables/ContractDetailsTables/ContractDetailsTables.module.less';
import { getTransferHistories } from 'api/contracts';
import { importExportDataFormatter } from 'components/tables/ContractDetailsTables/ImportExportTable/helpers/importExportDataFormatter';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type successType = {
  status: any;
  type: any;
};

export interface TranferHistoriesData {
  importNumber?: number;
  packageNumber: number;
  contractTransferType: string;
  operationDate: string;
  success: boolean | successType;
  message: string;
  operationType: string;
}

interface Props {
  originalContractId: number | string;
}

const ImportExportTable: React.FC<Props> = ({ originalContractId }) => {
  const [data, setData] = useState<TranferHistoriesData[] | []>([]);
  const { t } = useTranslation();

  const getData = async (originalContractId: string | number) => {
    const { data } = await getTransferHistories(originalContractId);
    setData(data);
  };

  useEffect(() => {
    getData(originalContractId);
  }, [originalContractId]);

  const columns = columnsList(t).map(({ title, value }) => {
    const basicColumn = {
      title: title,
      dataIndex: value,
      key: value,
      width: 80,
    };

    switch (value) {
      case 'importNumber': {
        return {
          ...basicColumn,
          width: 15,
          render: (_, __, index) => index + 1,
        };
      }
      case 'contractTransferType': {
        return {
          ...basicColumn,
          render: (type) =>
            type?.toUpperCase() === 'IMPORT_VISIT_UKRAINE'
              ? t('contract_details.import_visit_ukraine_import_export')
              : t('contract_details.export_1c_import_export'),
        };
      }
      case 'packageNumber': {
        return {
          ...basicColumn,
          width: 60,
          render: (number) => ('0000000000' + number).slice(-10),
        };
      }
      case 'operationDate': {
        return {
          ...basicColumn,
          render: (date) => moment(date).utc(true).local().format('DD.MM.YYYY LT'),
        };
      }
      case 'success': {
        return {
          ...basicColumn,
          width: 60,
          render: ({ status, type }) => {
            const operationType = type === 'IMPORT_VISIT_UKRAINE';
            return operationType
              ? status
                ? t('contract_details.imported_import_export')
                : t('contract_details.not_imported_import_export')
              : status
              ? t('contract_details.exported_import_export')
              : t('contract_details.not_exported_import_export');
          },
        };
      }
      case 'message': {
        return {
          ...basicColumn,
          width: 170,
        };
      }
      default: {
        return basicColumn;
      }
    }
  });

  return (
    <Table
      className={styles.table}
      columns={columns}
      pagination={false}
      scroll={{ x: 1280 }}
      rowKey={(record: any) => `${record.packageNumber}`}
      dataSource={importExportDataFormatter(data, t)}
      bordered
    />
  );
};

export default ImportExportTable;
