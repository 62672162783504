/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ReferralTokenDto {
  /** @format int64 */
  id?: number;
  token?: string;
}

export enum BonusType {
  BONUS = 'BONUS',
  CONVERSION = 'CONVERSION',
}

export interface ContractReferralBonusesView {
  /** @format int64 */
  id?: number;
  /** @format int64 */
  contractId?: number;
  contractPaymentAmount?: number;
  bonusSum?: number;
  /** @format int64 */
  referralTokenId?: number;
  /** @format int64 */
  referralProgramId?: number;
  /** @format int64 */
  ownerId?: number;
  ownerType?: OwnerType;
  contractNumber?: string;
  bonusType?: BonusType;
  /** @format date-time */
  createDate?: string;
}

export enum OwnerType {
  CLIENT = 'CLIENT',
}

export interface ResultListDtoContractReferralBonusesView {
  resultList?: ContractReferralBonusesView[];
  /** @format int64 */
  count?: number;
  total?: ContractReferralBonusesView;
}

export interface GetClientReferralTokenParams {
  /** @format int64 */
  insuranceCompanyId: number;
  /** @format int64 */
  partnerId: number;
}

export interface ListParams {
  /** Page number to query. Starts from 1 (if not specified, default 1) */
  page?: number;
  /** Page size (if not specified, default 50) */
  page_size?: number;
  /** Name of sorting field (by default ASC direction, '-' prefix means DESC direction). Use ',' as delimiter */
  sorting?: string;
  /** Fields for selection (default all fields). Use ',' as delimiter */
  attributes?: string;
  /** Fields for total row. Use ',' as delimiter */
  totalAttributes?: string;
  /** Whether result should be distinct or not */
  distinct?: boolean;
  /** Whether include results count or not (used for pagination) */
  count?: boolean;
  /** Whether include results total or not (used for total row) */
  total?: boolean;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  id?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractId?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  contractPaymentAmount?: string;
  /** Filter value. View field type is [BigDecimal] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  bonusSum?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  referralTokenId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  referralProgramId?: string;
  /** Filter value. View field type is [Long] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  ownerId?: string;
  /** Filter value. View field type is [OwnerType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  ownerType?: string;
  /** Filter value. View field type is [String] .Recommended next filters: value attribute should be equals, '<>' not equals to value, several possible values using '|' */
  contractNumber?: string;
  /** Filter value. View field type is [BonusType] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  bonusType?: string;
  /** Filter value. View field type is [LocalDateTime] .Recommended next filters: value attribute should be equals, ..' between values to specify range, '<=' less or equals to value, '<=' greater or equals to value, several possible values using '|', '<>' not equals to value */
  createDate?: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios-next';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || 'https://test.eks.inrisk.com.ua' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Loyalty Service
 * @version 1.0
 * @baseUrl https://test.eks.inrisk.com.ua
 *
 * Loyalty programs and bonuses
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  referralTokenController = {
    /**
     * No description
     *
     * @tags referral-token-controller
     * @name GetClientReferralToken
     * @summary get client referral token
     * @request GET:/referral-token/client
     * @secure
     */
    getClientReferralToken: (query: GetClientReferralTokenParams, params: RequestParams = {}) =>
      this.request<ReferralTokenDto, any>({
        path: `/referral-token/client`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
  contractReferralBonusesController = {
    /**
     * @description Query ContractReferralBonusesView using dynamic search of TableViewRepository
     *
     * @tags contract-referral-bonuses-controller
     * @name List
     * @summary list
     * @request GET:/referral-bonuses/contract
     * @secure
     */
    list: (query: ListParams, params: RequestParams = {}) =>
      this.request<ResultListDtoContractReferralBonusesView, any>({
        path: `/referral-bonuses/contract`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  };
}
