import React from 'react';
import { css } from '@emotion/css';
import { Typography } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import { State } from '../SignUp';
//@ts-ignore
import presentation from 'assets/Presentation-InRisk.pdf';
import AuthContainer from 'components/next/AuthContainer/AuthContainer';
import { Button } from 'components/next/Button/Button';
import history from 'routes/history';
import { ROUTES } from 'constants/routes';

const cssSubmit = css`
  width: 150px;
`;

interface Props {
  state: State;
}

const Congratulation = ({ state }: Props) => {
  const { t } = useTranslation();

  const fullName = [state.signupAccount?.lastName, state.signupAccount?.firstName, state.signupAccount?.middleName]
    .filter(Boolean)
    .join(' ');
  const mailto = `mailto:${state.signupAccount?.email}`;

  return (
    <AuthContainer>
      <Typography.Title level={3}>{t('signup.respected', { name: fullName })}</Typography.Title>

      <Typography.Title level={5}>{t('signup.thank_you_for_registering')}</Typography.Title>

      <p>
        <Trans
          i18nKey="signup.your_account_has_been_submitted"
          values={{ email: state.signupAccount?.email }}
          components={{ href: <Typography.Link href={mailto} /> }}
        />
      </p>

      <p>
        <Trans
          i18nKey="signup.before_working_with_the_system"
          values={{ link: t('signup.user_manual') }}
          components={{ href: <Typography.Link href={presentation} rel="noopener noreferrer" target="_blank" /> }}
        />
      </p>

      <Button size="large" type="primary" className={cssSubmit} onClick={() => history.push(ROUTES.PARTNER_AUTH.LOGIN)}>
        {t('signup.log_in')}
      </Button>
    </AuthContainer>
  );
};

export default Congratulation;
