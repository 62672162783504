import createInstance from './axios';
import { IPagination } from '../components/TableFooter/TableFooter';
import moment from 'moment';
import { ResultListDtoUserActivityLogView } from 'types/dto/contracts-service';

const axios = createInstance('CONTRACTS');

export const fetch = async (query: any = {}, pagination: IPagination) => {
  let activityDate = '';
  if (query?.activityDate) {
    query.activityDate.forEach((item) => {
      if (!activityDate) {
        activityDate = item;
      }
      if (moment(item).isBefore(activityDate)) {
        activityDate = item;
      }
    });
  } else {
    activityDate = '';
  }

  if (query?.sorting?.includes('partner')) {
    if (query.sorting.includes('-')) {
      query.sorting = query?.sorting?.slice(0, 1) + 'partnerId';
    } else {
      query.sorting = 'partnerId';
    }
  }

  if (query?.sorting?.includes('partnerDepartment')) {
    if (query.sorting.includes('-')) {
      query.sorting = query?.sorting?.slice(0, 1) + 'partnerDepartmentId';
    } else {
      query.sorting = 'partnerDepartmentId';
    }
  }

  return axios.get<ResultListDtoUserActivityLogView>(`/users/activity-logs?count`, {
    params: {
      attributes:
        'shortName,activityDate,login,partnerDepartmentName,partnerName,role,action,entityId,id,partnerId,partnerDepartmentId,details',
      // entityType: query.entityType || ((query.login || query.shortName) && 'USER') || undefined,
      logContractId: query.logContractId,
      activityDate: `${activityDate ? moment(activityDate).toISOString(false) : new Date(query.from).toISOString()}..${
        activityDate ? moment(activityDate).add(1, 'd').toISOString(false) : new Date(query.to).toISOString()
      }`,
      page_size: pagination.size,
      page: pagination.page,
      insuranceCompanyId: query.insuranceCompanyId,
      partnerId: Array.isArray(query.partnerId) ? query.partnerId.join('|') : query.partnerId,
      partnerDepartmentId: Array.isArray(query.partnerDepartmentId)
        ? query.partnerDepartmentId.join('|')
        : query.partnerDepartmentId,
      shortName: query.shortName,
      sorting: query.sorting,
      login: Array.isArray(query.login) ? query.login?.join('|') : query.login,
      role: Array.isArray(query.role) ? query.role?.join('|') : query.role,
      action: Array.isArray(query.action) ? query.action?.join('|') : query.action,
      partnerName: Array.isArray(query.partnerName) ? query.partnerName?.join('|') : query.partnerName,
      partnerDepartmentName: Array.isArray(query.partnerDepartmentName)
        ? query.partnerDepartmentName?.join('|')
        : query.partnerDepartmentName,
    },
  });
};

export const getUserActivityActionTypes = () => {
  return axios.get<Record<string, string>>('/users/activity-logs/action-types');
};
