import React, { HTMLAttributes } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import Breadcrumbs from '../uikit/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    marginBottom: 8,
  },
});

export interface IPageBreadcrumb {
  href?: string;
  label: string;
  state?: string;
}

export interface IPageBreadcrumbsProps extends HTMLAttributes<HTMLDivElement> {
  data?: IPageBreadcrumb[];
}

const PageBreadcrumbs: React.FC<IPageBreadcrumbsProps> = ({ children, data = [], className, ...props }) => {
  const classes = useStyles();
  const removeSupplementaryAgreement = () => window.localStorage.removeItem('setSupplementaryAgreement');
  return data.length ? (
    <Breadcrumbs {...props} className={cs(classes.root, className)}>
      {data.map((breadcrumb) =>
        breadcrumb.href ? (
          <Link
            key={breadcrumb.label}
            to={{ pathname: breadcrumb.href, state: breadcrumb.state }}
            onClick={removeSupplementaryAgreement}
          >
            {breadcrumb.label}
          </Link>
        ) : (
          <span key={breadcrumb.label}>{breadcrumb.label}</span>
        ),
      )}
    </Breadcrumbs>
  ) : null;
};
export default PageBreadcrumbs;
