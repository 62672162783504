import { ThunkAction } from 'redux-thunk';
import { notification } from 'antd';

import { Permissions } from 'types/permissions';
import { AppState } from 'store/reducer';
import { ActionTypes, ErrorsDto, List } from 'types';
import { RoleView } from 'types/dto/auth-service';
import { Values, initialValues } from 'screens/Permissions/Filters/Filters.schema';
import { Values as ValuesForm } from 'screens/Permissions/Form/Form.schema';
import { InsuranceCompanyView } from 'types/dto/contracts-service';
import { apiAuth } from 'api/auth';
import { apiContracts } from 'api/contracts';
import i18n from 'translations/i18n';

export type InferActionTypes = ActionTypes<typeof actions>;
type ThunkType<R> = ThunkAction<R, AppState, unknown, InferActionTypes>;

export interface DetailsData extends RoleView {
  permissions: Permissions;
}
interface Details {
  isCreate?: boolean;
  loading?: boolean;
  data?: DetailsData;
  loadingSave?: boolean;
}
interface Data extends List {
  loading: boolean;
  data: RoleView[];
}
type Filters = Values & {
  initialization: boolean;
};
interface InsuranceCompanies {
  loading: boolean;
  data: InsuranceCompanyView[];
  // additional map for improve performance to work with companies
  map: Record<string, InsuranceCompanyView>;
}

const getCompanyMap = (data: InsuranceCompanyView[]) =>
  data.reduce((acc: Record<string, InsuranceCompanyView>, c) => {
    acc[c.id ?? 0] = c;

    return acc;
  }, {});

export const actions = {
  setData: (payload: Partial<Data>) => ({ type: 'PERMISSIONS/SET_DATA', payload } as const),
  setInsuranceCompanies: (payload: Partial<InsuranceCompanies>) =>
    ({ type: 'PERMISSIONS/SET_INSURANCE_COMPANIES', payload } as const),
  setFilters: (payload: Partial<Filters>) => ({ type: 'PERMISSIONS/SET_FILTERS', payload } as const),
  setDefault: (payload: Permissions) => ({ type: 'PERMISSIONS/SET_DEFAULT', payload } as const),
  setDetails: (payload: Details | null) => ({ type: 'PERMISSIONS/SET_DETAILS', payload } as const),
};

const requestData = (): ThunkType<Promise<RoleView[]>> => async (dispatch, getState) => {
  const permissions = getState().permissions;

  return apiAuth.roleController
    .listRoles({ insuranceCompanyId: permissions.filters?.insuranceCompanyId ?? undefined })
    .then((res) => res.data);
};

export const loadData = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setData({ loading: true }));

  return dispatch(requestData())
    .then((res) => {
      dispatch(actions.setData({ loading: false, data: res }));
    })
    .catch(() => {
      dispatch(actions.setData({ loading: false }));
    });
};

export const initializeFilters = (): ThunkType<void> => async (dispatch, getState) => {
  const insuranceCompany = getState().me.data?.details?.insuranceCompany;

  if (insuranceCompany) {
    const data = [{ id: insuranceCompany.id, name: insuranceCompany.name }];

    dispatch(actions.setFilters({ insuranceCompanyId: insuranceCompany.id }));
    dispatch(actions.setInsuranceCompanies({ data, map: getCompanyMap(data) }));

    dispatch(actions.setFilters({ initialization: false }));

    return;
  }

  dispatch(actions.setFilters({ initialization: false }));

  dispatch(loadCompanies());
};

export const loadCompanies = (): ThunkType<void> => async (dispatch) => {
  dispatch(actions.setInsuranceCompanies({ loading: true }));

  return apiContracts.insuranceCompanyController
    .list4({ distinct: true, attributes: 'id,name', page_size: -1 })
    .then((res) => {
      const data = res.data.resultList ?? [];
      const map = getCompanyMap(data);

      dispatch(actions.setInsuranceCompanies({ loading: false, data, map }));
    })
    .catch((error) => {
      dispatch(actions.setInsuranceCompanies({ loading: false }));

      if (Array.isArray(error.response.data.errors)) {
        (error.response.data as ErrorsDto).errors.forEach((err) => {
          notification.error({ message: err.code });
        });
      }
    });
};

export const getDefault = (): ThunkType<Promise<Permissions>> => async (dispatch, getState) => {
  const permissions = getState().permissions;

  if (!permissions.default) {
    const { data } = await apiAuth.roleController.getDefaultPermissions();

    dispatch(actions.setDefault(data as Permissions));

    return data as Permissions;
  }

  return permissions.default;
};

export const openDetailsCreate = (): ThunkType<void> => async (dispatch, getState) => {
  try {
    dispatch(actions.setDetails({ loading: true, isCreate: true }));

    const defaultPermissions = await dispatch(getDefault());

    dispatch(actions.setDetails({ loading: false, data: { permissions: defaultPermissions } }));
  } catch (error) {
    dispatch(actions.setDetails(null));
  }
};

export const openDetailsUpdate =
  (record: RoleView): ThunkType<void> =>
  async (dispatch) => {
    try {
      dispatch(actions.setDetails({ loading: true, isCreate: false }));

      const { data } = await apiAuth.roleController.readRole(record.roleId ?? 0);

      dispatch(actions.setDetails({ loading: false, data: { ...record, permissions: data as Permissions } }));
    } catch (error) {
      dispatch(actions.setDetails(null));
    }
  };

export const createPermissions =
  (values: ValuesForm): ThunkType<Promise<undefined>> =>
  (dispatch, getState) => {
    const insuranceCompanies = getState().permissions.insuranceCompanies;

    dispatch(actions.setDetails({ loadingSave: true }));

    return apiAuth.roleController
      .createRole({
        roleName: values.role,
        insuranceCompany: {
          id: values.insuranceCompanyId ?? undefined,
          name: values.insuranceCompanyId
            ? insuranceCompanies.map[values.insuranceCompanyId.toString()]?.name ?? 'SYSTEM'
            : 'SYSTEM',
        },
        permissions: values.permissions ?? undefined,
      })
      .then(() => {
        notification.success({ message: i18n.t('permissions.successCreate') });

        return Promise.resolve(undefined);
      })
      .catch((error) => {
        dispatch(actions.setDetails({ loadingSave: false }));

        if (Array.isArray(error?.response?.data?.errors)) {
          (error.response.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: err.code });
          });
        }

        return Promise.reject(undefined);
      });
  };

export const updatePermissions =
  (values: ValuesForm): ThunkType<Promise<undefined>> =>
  (dispatch, getState) => {
    const details = getState().permissions.details;
    const insuranceCompanies = getState().permissions.insuranceCompanies;

    dispatch(actions.setDetails({ loadingSave: true }));

    return apiAuth.roleController
      .updateRole(details?.data?.roleId ?? 0, {
        permissions: values.permissions ?? undefined,
        roleName: values.role,
        insuranceCompany: {
          id: values.insuranceCompanyId ?? undefined,
          name: values.insuranceCompanyId
            ? insuranceCompanies.map[values.insuranceCompanyId.toString()]?.name ?? 'SYSTEM'
            : 'SYSTEM',
        },
      })
      .then(() => {
        notification.success({ message: i18n.t('permissions.successUpdate') });

        return Promise.resolve(undefined);
      })
      .catch((error) => {
        dispatch(actions.setDetails({ loadingSave: false }));

        if (Array.isArray(error?.response?.data?.errors)) {
          (error.response.data as ErrorsDto).errors.forEach((err) => {
            notification.error({ message: err.code });
          });
        }

        return Promise.reject(undefined);
      });
  };

export const deletePermissions =
  (roleId: number): ThunkType<Promise<undefined>> =>
  (dispatch, getState) => {
    return apiAuth.roleController
      .deleteRole(roleId)
      .then(() => {
        notification.success({ message: i18n.t('permissions.successDelete') });

        return undefined;
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  };

export interface StorePermissions {
  data: Data;
  insuranceCompanies: InsuranceCompanies;
  filters: Filters;
  details: Details | null;
  default: Permissions | null;
}
const initialState: StorePermissions = {
  data: { loading: false, data: [] },
  insuranceCompanies: { loading: false, data: [], map: {} },
  filters: { ...initialValues, initialization: true },
  details: null,
  default: null,
};

const reducer = (state = initialState, action: InferActionTypes): StorePermissions => {
  switch (action.type) {
    case 'PERMISSIONS/SET_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'PERMISSIONS/SET_INSURANCE_COMPANIES':
      return { ...state, insuranceCompanies: { ...state.insuranceCompanies, ...action.payload } };
    case 'PERMISSIONS/SET_FILTERS':
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case 'PERMISSIONS/SET_DEFAULT':
      return { ...state, default: action.payload };
    case 'PERMISSIONS/SET_DETAILS':
      return { ...state, details: action.payload === null ? null : { ...state.details, ...action.payload } };

    default:
      return state;
  }
};

export default reducer;
