import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import Table from 'components/Table';
import columnsList from './columns';
import { getSubAgreements } from 'api/contracts';

import styles from '../ContractDetailsTables.module.less';
import { redirectToContract } from 'constants/contractData';
import { useTranslation } from 'react-i18next';

interface ITableProps {
  originalContractId: number;
  originalContractNumber: string;
}

export default ({ originalContractId, originalContractNumber }: ITableProps) => {
  const [subAgreements, setSubAgreements] = useState<any>([{}]);
  const { t } = useTranslation();
  useEffect(() => {
    originalContractId &&
      getSubAgreements({
        originalContractId,
        sorting: '-startDate,-id',
        distinct: true,
      })
        .then((res) => {
          setSubAgreements(
            (res.data.resultList ?? [])
              .map((item) => {
                return {
                  ...item,
                  number: item.subAgreementNumber ? `${item.number}-${item.subAgreementNumber}` : item.number,
                };
              })
              .filter((item) => item.number !== originalContractNumber),
          );
        })
        .catch(() => {
          notification.error({
            message: t('contract_details.error_sub_agreements'),
            description: t('contract_details.download_information_sub_agreements'),
          });
        });
  }, [originalContractId]);

  const subAgreementsWithDate =
    subAgreements && subAgreements.length
      ? subAgreements.map((item) => {
          return {
            ...item,
            conclusionDate: item.subAgreementConclusionDate ? item.subAgreementConclusionDate : item.conclusionDate,
            startDate: item.subAgreementStartDate ? item.subAgreementStartDate : item.startDate,
            saleDate: item.saleDate ? item.saleDate : item.conclusionDate,
          };
        })
      : [];

  return (
    <Table
      className={styles.table}
      columns={columnsList(t)}
      pagination={false}
      scroll={{ x: 2880 }}
      onRow={(record) => ({
        onClick: (evt) => {
          evt.stopPropagation();
          redirectToContract(record.id, record);
        },
      })}
      rowKey={(record: any) => `${record.id}`}
      dataSource={subAgreementsWithDate}
      bordered
    />
  );
};
