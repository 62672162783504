import createInstance from './axios';

import { DepartmentDto, ResultListDtoInsuranceCompanyView } from 'types/dto/contracts-service';

const DepartmentsAPI = createInstance('CONTRACTS');

export const findById = (id: number) => {
  return DepartmentsAPI.get<DepartmentDto>('/departments/' + id);
};

export const getInsuranceCompanies = () => {
  return DepartmentsAPI.get<ResultListDtoInsuranceCompanyView>('/insurance-companies/?distinct&count', {
    params: {
      attributes: 'id,abbreviation,enterpriseCode,name,phoneNumber,contactPersonFullName,createdDate',
      page_size: -1,
    },
  });
};

export const save = (data) => {
  return DepartmentsAPI.post<DepartmentDto>('/departments/', data);
};

export const update = (data) => {
  return DepartmentsAPI.put<DepartmentDto>('/departments/' + data.id, data);
};
