import React from 'react';
import moment from 'moment';
import { notification } from 'antd';

import ContractDetailsTable from 'components/tables/ContractDetailsTables/ContractDetailsTable/ContractDetailsTable';
import PaymentsTable from 'components/tables/ContractDetailsTables/PaymentsTable/PaymentsTable';
import RepaymentsTable from 'components/tables/ContractDetailsTables/RepaymentsTable/RepaymentsTable';
import TerminationsTable from 'components/tables/ContractDetailsTables/TerminationsTable/TerminationsTable';
import SubAgreementsTable from 'components/tables/ContractDetailsTables/SubAgreementsTable/SubAgreementsTable';
import UserActivityTable from 'components/tables/ContractDetailsTables/UserActivityTable';
import * as contractsAPI from 'api/contracts';
import { IContract } from 'types/contractDetails';
import { ROUTES } from 'constants/routes';
import ImportExportTable from 'components/tables/ContractDetailsTables/ImportExportTable/ImportExportTable';
import { JutloDetailsTable } from 'components/tables/ContractDetailsTables/JutloDetailsTable/JutloDetailsTable';
import _ from 'lodash';
import { getContractType } from 'utils/getContractType';
import { ContractClaimDetailsDto, ResultListDtoContractView } from 'types/dto/contracts-service';

const getContractNumber = (contract, isDMS?: boolean, prefix?: string) => {
  return `${isDMS ? contract.contractNumber : contract.contractNumber.slice(0, contract.contractNumber.length - 3)}${
    isDMS ? '' : contract.contractNumberEnding
  }`;
};

export const contractBreadcrumbs = (
  contract: IContract,
  contractNumber: string,
  t,
  isDMS?: boolean,
  prefix?: string,
) => [
  {
    label: t('contract_details.home'),
    href: ROUTES.DASHBOARD.ROOT,
  },
  {
    label: t('contract_details.register_insurance_contracts'),
    href: ROUTES.CONTRACTS_LIST.ROOT,
  },
  {
    label: contract.id
      ? `${t('contract_details.insurance_contract')} №${
          contract.originalContractNumber || getContractNumber(contract, isDMS, prefix)
        }`
      : t('contract_details.insurance_contract'),
    ...(contract?.originalContractId
      ? { href: `${ROUTES.CONTRACTS_LIST.CONTRACT.ROOT}/${contract.originalContractId}` }
      : {}),
  },
];

export const statusFormat = (status: string, startDate: string, t) => {
  switch (status) {
    case 'ACTIVE': {
      return t('contract_details.acting');
    }
    case 'PARTIALLY_PAID': {
      return t('contract_details.not_valid');
    }
    case 'DRAFT': {
      return t('contract_details.draft');
    }
    case 'FINISHED': {
      return t('contract_details.completed');
    }
    case 'PROLONGATED': {
      return t('contract_details.prolonged');
    }
    case 'PARTIALLY_ACTIVE_COVID':
    case 'PARTIALLY_ACTIVE':
      return t('contract_details.signed_action_will_begin', { date: moment(startDate).format('DD.MM.YYYY') });
  }
};

export enum ADD_TYPE {
  PROLONGATION = 'prolongation',
  SUBCONTRACT = 'subContract',
  CHANGE_PROG = 'changeProg',
}

export enum PROGRAMS_CHANGE {
  MZ_L = 'Мобізахист Лайт',
  MZ_B = 'Мобізахист Б',
}

export const tabsTables = (contract: IContract, savedRisks, isChangeProg, t, repaymentsDetails) => {
  const code = contract.insuranceProgram?.code || contract.insuranceProductCode;
  const risks =
    (savedRisks && !contract.id) || isChangeProg
      ? _.mergeWith(savedRisks, contract.objects[0].risks)
      : contract.contractObjects[0].risks;

  const clearRisks = (risks ?? []).filter((item) => item.name);

  return [
    {
      title:
        code && code.includes('MM') ? t('contract_details.object_insurance') : t('contract_details.insurance_risks'),
      key: '1',
      component:
        code && code.includes('MM') ? (
          <JutloDetailsTable data={savedRisks ?? []} objects={contract.objects} />
        ) : (
          <ContractDetailsTable data={contract && clearRisks} keyStep="1" />
        ),
    },
    {
      title: t('contract_details.payments'),
      key: '2',
      component: <PaymentsTable contractId={getContractId(contract)} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.disbursement'),
      key: '3',
      component: <RepaymentsTable repaymentsDetails={repaymentsDetails} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.rupture'),
      key: '4',
      component: <TerminationsTable contractId={contract.id!} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.additional_agreements'),
      key: '5',
      component: (
        <SubAgreementsTable
          originalContractId={contract.originalContractId! || contract.id!}
          originalContractNumber={contract.originalContractNumber! || contract.contractNumber!}
        />
      ),
      disabled: !Number(contract.originalContractId! || contract.id!),
    },
    {
      title: t('contract_details.export_import'),
      key: '6',
      component: <ImportExportTable originalContractId={contract.originalContractId || contract.id || 0} />,
      disabled: !Number(contract.id!),
    },
    {
      title: t('contract_details.user_activity'),
      key: '7',
      component: (
        <UserActivityTable
          conclusionDate={contract.conclusionDate}
          logContractId={contract.originalContractId || contract.id!}
        />
      ),
      disabled: !Number(contract.id!),
    },
  ];
};

export const successNotification = (t) => {
  notification.success({
    message: t('contract_details.offer_submitted_successfully'),
  });
};
export const errorNotification = (t) => {
  notification.error({
    message: t('contract_details.error_sending_offer'),
  });
};

export const getAffordableInsuranceProgram = (contractTypeConfig) => {
  const isMedicine = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['FD_A', 'FD_B'],
  });

  const isVZR = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['VZR'],
  });

  const isInternational = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MM_D', 'MED_A', 'MED_ZS'],
  });

  const isInternationalMyHome = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MED_A', 'MED_ZS'],
  });

  const isInternationalCheckMyHome = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MM_D'],
  });

  const vfAccidentMax = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['CV_VF_MAX'],
  });

  const isSpecialCitrus = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MZ_CI_C'],
  });

  const isCovide19Insk = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['CV_A_I', 'CV_B_I'],
  });

  const isMiniHousing = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MM_A', 'MM_B', 'MM_C'],
  });

  const isMiniCASCO = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MK_A_I', 'MK_B_I'],
  });

  const isMZLayt = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MZ_L'],
  });

  const isVZRUkraine = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['VZR_UKRAINE'],
  });

  const isDMS = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['MED_DMS_S', 'MED_DMS_VIP'],
  });

  const isDronUA = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['ECO_A', 'ECO_B', 'ECO_L'],
  });

  const isEmailSignAvailable = getContractType({
    ...contractTypeConfig,
    exspectedCode: [
      'MZ_A',
      'MZ_B',
      'MZ_L',
      'MZ_IN_A',
      'MZ_IN_B',
      'MZ_IN_L',
      'INN_ZAHYST_PRYSTRIY',
      'INN_DISPLAY_PANEL',
      'INN_ZAH_DISPLAY',
      'ECO_A',
      'ECO_B',
      'ECO_L',
      'MZ_CI_A',
      'MZ_CI_B',
      'MZ_CI_C',
    ],
  });

  const isGetDataFromDiaAvailable = getContractType({
    ...contractTypeConfig,
    exspectedCode: [
      'MZ_A',
      'MZ_B',
      'MZ_L',
      'MZ_IN_A',
      'MZ_IN_B',
      'MZ_IN_L',
      'INN_ZAHYST_PRYSTRIY',
      'INN_DISPLAY_PANEL',
      'INN_ZAH_DISPLAY',
      'ECO_A',
      'ECO_B',
      'ECO_L',
      'MZ_CI_A',
      'MZ_CI_B',
      'MZ_CI_C',
      'MZ_M_10000',
      'MZ_M_20000',
      'MZ_M_3000',
      'MZ_M_5000',
      'MZ_A_I',
      'MZ_B_I',
      'MZ_PG',
      'CV_A',
      'CV_B',
      'CV_FD_A_FORMULA',
      'CV_FD_B_FORMULA',
    ],
  });

  const isVZRForeigner = getContractType({
    ...contractTypeConfig,
    exspectedCode: ['VZR_SILVER', 'VZR_GOLD', 'VZR_PLATINUM'],
  });

  return {
    isMedicine,
    isVZR,
    isInternational,
    isInternationalMyHome,
    isInternationalCheckMyHome,
    vfAccidentMax,
    isSpecialCitrus,
    isCovide19Insk,
    isMiniHousing,
    isMiniCASCO,
    isMZLayt,
    isVZRUkraine,
    isDMS,
    isDronUA,
    isEmailSignAvailable,
    isGetDataFromDiaAvailable,
    isVZRForeigner,
  };
};

export const getIfUserTypeNotCompany = (contract, userType) => {
  const userTypeInitialValue = (contract && contract.signatory && contract.signatory.type) || 'PERSON';
  const isUserTypeNotCompany = userType ? userType !== 'COMPANY' : userTypeInitialValue !== 'COMPANY';
  return isUserTypeNotCompany;
};

export const checkProlongationStatus = (frequencyStatusValues) => {
  const truthyStatuses = ['PAYMENT_REJECTED', 'ANNUL'];
  const falsyData = frequencyStatusValues.filter(
    (frequencyStatusValue) => !truthyStatuses.includes(frequencyStatusValue.claimStatus),
  );
  const falsyStatuses = falsyData.map((item) => item.claimStatus);

  const trueSelected = frequencyStatusValues.some((item) => truthyStatuses.includes(item.claimStatus));
  const falseSelected = frequencyStatusValues.some((item) => falsyStatuses.includes(item.claimStatus));
  return trueSelected && !falseSelected;
};

const expressPaymentStatuses = ['PAYMENT', 'REPORTED', 'AYMENT_AWAITING'];

export const checkExpressPayment = (repaymentsDetails: ContractClaimDetailsDto[]): boolean => {
  if (!repaymentsDetails.length) return false;

  const expressPayment = repaymentsDetails.find((item) => item.resolutionType === 'EXPRESS_PAYMENT');
  return checkExpressPaymentStatuses(expressPayment);
};

const checkExpressPaymentStatuses = (expressPayment: ContractClaimDetailsDto | undefined): boolean => {
  return !!(expressPayment && expressPaymentStatuses.includes(expressPayment.claimStatus || ''));
};

export const getRulesForProlong = (
  contract: IContract,
  checkProlongationStatus: (repaymentsDetails: ContractClaimDetailsDto[]) => boolean,
  repaymentsDetails: ContractClaimDetailsDto[],
  isMZLayt: boolean,
) => {
  if (contract) {
    const { insuranceProductCode, verifiedAt, current, hasClaims, duration } = contract;
    let prolongStatus: boolean = checkProlongationStatus(repaymentsDetails);
    return (
      insuranceProductCode === 'MZ' &&
      duration === 12 &&
      !isMZLayt &&
      verifiedAt &&
      current &&
      (!hasClaims || prolongStatus)
    );
  }
  return false;
};
export const getRulesForChangeProgram = (
  contract: IContract,
  checkProlongationStatus: (repaymentsDetails: ContractClaimDetailsDto[]) => boolean,
  repaymentsDetails: ContractClaimDetailsDto[],
) => {
  if (contract) {
    const { insuranceProductCode, verifiedAt, current, hasClaims, duration } = contract;
    let prolongStatus: boolean = checkProlongationStatus(repaymentsDetails);
    return insuranceProductCode === 'MZ' && verifiedAt && current && (!hasClaims || prolongStatus);
  }
  return false;
};

const getContractId = (contract: IContract): number => {
  return contract.prolongation ? contract.id ?? 0 : contract.originalContractId || (contract.id ?? 0);
};
export const fetchDataSubAgreement = async (contract) => {
  return contractsAPI
    .getSubAgreements({
      originalContractId: contract.originalContractId || contract.id!,
      sorting: '-startDate,-id',
      distinct: true,
    })
    .then((res: { data: ResultListDtoContractView }) => res.data.resultList && res.data.resultList[0]);
};
