import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, ConfigProvider, DatePicker, Input } from 'antd';

import styles from './PrivateFilter.module.less';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducer';
import ua from 'antd/lib/locale-provider/uk_UA';
import 'moment/locale/uk';
import moment, { Moment } from 'moment';
import { paaFormat } from 'utils/statuses';
import _ from 'lodash';

import { reverseRoles, roles } from 'screens/Insurance/components/Appeals/columns';
import {
  reverseUserActivities,
  userActivities,
} from 'screens/UserCard/partials/UserTable/patrials/UserActivity/constants';
import { fetch } from 'store/usersActivities/actions';
import { useTranslation } from 'react-i18next';
import { moneyFormatter, reverseMoneyToNumber } from 'utils/helpers';
import { getActionType } from 'screens/Integrations/components/Table/utils/formatter';
import { getCorrectList, getOptions } from 'screens/Discounts/utils';
import { setChoosenFilters } from 'store/discounts/actions';

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const unParsePartnerType = {
  'Cервісна компанія': 'SERVICE_COMPANY',
  'Cтраховий агент': 'INSURANCE_AGENT',
  'Тестовий партнер': 'TEST_PARTNER',
};

interface IProps {
  record: {
    filters: IFilters[];
    clearFilters: () => void;
    confirm: () => void;
  };
  dataId?: string;
  dataIndex: string;
  filteredBySearching?: any;
  isDate?: boolean;
  isContractFilter?: boolean;
  getDataFromPrivateFilter: any;
  getDataPrivateFilter?: any;
  filtersInfo: any;
  setFiltersInfo: any;
  terminations?: boolean;
  isDiscount?: boolean;
  isPromo?: boolean;
  isClearedFilters?: boolean | undefined;
  logContractId?: number;
  isUserActivityTable?: boolean;
  clearAllFilters?: () => void;
  getTablePage?: () => void;
  getPrivateFiltersValues?: (value: any, t) => void;
  clearFilter?: (fieldName: string) => void;
  privateFilterItems?: { [key: string]: any };
}

interface IFilters {
  value: string;
}

interface IState {
  checkedList: CheckboxValueType[];
  indeterminate: boolean;
  checkAll: boolean;
}

const PrivateFilter = ({
  record,
  dataIndex,
  dataId,
  getDataFromPrivateFilter,
  filtersInfo,
  setFiltersInfo,
  isDate = false,
  isContractFilter = false,
  isClearedFilters,
  getDataPrivateFilter,
  logContractId,
  isUserActivityTable,
  clearAllFilters,
  getPrivateFiltersValues,
  clearFilter,
  privateFilterItems,
  isDiscount,
  isPromo,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [checkBoxData, setCheckBoxData] = useState<IState>({
    checkedList: [],
    indeterminate: true,
    checkAll: false,
  });
  const activeTab: string = useSelector((store: AppState) => store.discounts.activeTabeName.tabName ?? '');
  const [filterBy, setFilterBy] = useState('');
  const [filterOptions, setFilterOptions] = useState<Array<string>>([]);
  const [dateValue, setDateValue] = useState<Array<Moment | null>>([null, null]);
  const { setSelectedKeys }: any = record;

  const { privateFilterItems: contractListprivateFilterItems } = useSelector(
    ({ contractList: { privateFilterItems } }: AppState) => ({
      privateFilterItems,
    }),
  );

  const privateFilterInfo =
    privateFilterItems && Object.keys(privateFilterItems).length ? privateFilterItems : contractListprivateFilterItems;

  const { query } = useSelector((store: AppState) => ({ query: store.usersActivities.query }));

  const activeUserTabFilter = () => {
    if (isUserActivityTable) {
      dispatch(fetch(logContractId));
    }
  };

  useEffect(() => {
    const { filters } = record;
    const extraFilters = privateFilterInfo?.[dataIndex] || [];
    if (!dateValue[0]) {
      const existedFilters = filters && filters.length ? filters.map(({ value }: { value: string }) => value) : [];
      setFilterOptions([...new Set([...existedFilters, ...extraFilters])]);
    }
  }, [record, privateFilterInfo, dataIndex, setFilterOptions]);

  useEffect(() => {
    const checkedFieldName = dataIndex === 'originalContractNumber' ? 'number' : dataIndex;
    if (!isContractFilter && privateFilterInfo && privateFilterInfo[checkedFieldName]) {
      setCheckBoxData((prev) => ({
        ...prev,
        checkedList: privateFilterInfo[checkedFieldName],
      }));
    }
  }, []);

  useEffect(() => {
    if (!!isClearedFilters) {
      setCheckBoxData({
        checkedList: [],
        indeterminate: true,
        checkAll: false,
      });
      setFilterBy('');
      setDateValue([null, null]);
      setFiltersInfo({});
      clearAllFilters && clearAllFilters();
    }
  }, [isClearedFilters]);

  useEffect(() => {
    if (isContractFilter && query && query[dataIndex]) {
      let queries = query[dataIndex];
      if (dataIndex === 'role') {
        queries = roles[queries];
      }

      if (dataIndex === 'action') {
        queries = userActivities[queries];
      }

      setCheckBoxData((prev) => ({
        ...prev,
        checkedList: queries,
      }));
    }
  }, []);

  useEffect(() => {
    setCheckBoxData({
      checkedList: [],
      indeterminate: true,
      checkAll: false,
    });
  }, [activeTab]);

  const handleSearch = (value: string) => {
    setFilterBy(value);
    if (!isDate) {
      // @ts-ignore
      setFilterOptions([
        ...new Set([...checkBoxData.checkedList, ...record.filters.map((item: IFilters) => item.value)]),
      ]);
      if (getDataPrivateFilter) {
        getDataPrivateFilter({
          ...filtersInfo,
          [dataIndex === 'originalContractNumber' ? 'number' : dataIndex]: value,
        });
      }
    }
    const options = filterOptions.filter((option) => option.includes(value));
    value !== '' && setFilterOptions(options);
  };

  const handleFilterValues = (checkedList: CheckboxValueType[]): void => {
    setCheckBoxData({
      checkedList,
      indeterminate: checkedList.length < filterOptions.length,
      checkAll: checkedList.length === filterOptions.length,
    });
    setSelectedKeys(checkedList);
    const list = checkedList.map((item) => item.toString().split('%')[0]).join('|');
    const filterColumn = { name: dataIndex, value: isPromo ? list : checkedList.join('|') };
    dispatch(setChoosenFilters(filterColumn));
  };

  const onCheckAllChange = (e: any) => {
    const { checked } = e.target;
    const value: IState = {
      checkedList: checked
        ? filterOptions.map((item) => {
            if (dataIndex === 'role') {
              item = roles[item];
            }

            if (dataIndex === 'action') {
              item = userActivities[item];
            }
            if (dataIndex === 'status' && isPromo) {
              item = t(`discounts.Table_Row_status_${item}`);
            }
            if (dataIndex === 'type' && isPromo) {
              item = t(`enums.PromoCodeType.${item}`);
            }

            return isDate && item !== 'null' ? moment(item).format('DD.MM.YYYY') : item;
          })
        : [],
      indeterminate: false,
      checkAll: checked,
    };
    setCheckBoxData(value);
  };

  const closeFilter = () => {
    const { confirm } = record;

    confirm();
    setFilterBy('');
    setDateValue([null, null]);
  };

  const handleReset = () => {
    const { confirm } = record;
    setCheckBoxData({
      checkedList: [],
      indeterminate: true,
      checkAll: false,
    });
    dispatch(setChoosenFilters({ name: dataIndex, value: '' }));

    confirm();
  };

  const setTodayDate = () => {
    setDateValue([moment(), moment()]);
  };

  const getSortedItems = (dataIndex: string, filteredItems: string[]) => {
    if (dataIndex === 'maxNumberOfUses' || dataIndex === 'amount') {
      return filteredItems.sort((a, b) => Number(a) - Number(b));
    } else if (dataIndex === 'partnerName' || dataIndex === 'insuranceCompanyName' || dataIndex === 'promo') {
      return filteredItems.sort((a, b) => a.localeCompare(b, 'ru', { sensitivity: 'base' }));
    } else {
      return filteredItems;
    }
  };

  const changeDate = (value: any, dateString: any) => {
    // filteredBySearching(dataIndex, dateString, 'date');

    const newFilterOptions = [
      ...checkBoxData.checkedList,
      ...record.filters.map((item: IFilters) => item.value),
    ].filter((el: string) => moment(el).isAfter(moment(dateString[0])) && moment(el).isBefore(moment(dateString[1])));
    // @ts-ignore
    setFilterOptions([...new Set(newFilterOptions)]);
    setDateValue(value);
  };

  const filteredContracts = () => {
    const { confirm } = record;
    confirm();
    let checkedList = isDate
      ? checkBoxData.checkedList.map((item: string) => {
          return item.split('.').reverse().join('-');
        })
      : checkBoxData.checkedList;

    if (dataIndex === 'active') {
      checkedList = checkedList.map((stat: string) => {
        return stat === 'Активована';
      });
    }
    if (dataIndex === 'participateInAgentAct') {
      checkedList = checkedList.map((stat: string) => stat && _.invert(paaFormat(t))[stat]);
    }

    if (dataIndex === 'role') {
      checkedList = checkedList.map((stat: string) => reverseRoles[stat]);
    }

    if (dataIndex === 'action') {
      checkedList = checkedList.map((stat: string) => reverseUserActivities[stat]);
    }

    if (dataIndex === 'type') {
      if (!isDiscount) {
        checkedList = checkedList.map((stat: string) => unParsePartnerType[stat]);
      }
      let firstItem = checkedList.find((element) => element !== undefined);
      if (!firstItem) {
        checkedList = checkBoxData.checkedList.map((stat: string) => _.invert(getActionType(t))[stat]);
      }
    }

    if (dataIndex === 'insuranceAmount' || dataIndex === 'paymentAmount' || dataIndex === 'calculatedPaymentAmount') {
      checkedList = checkedList.map((stat) => reverseMoneyToNumber(String(stat)));
    }

    getDataFromPrivateFilter({
      ...filtersInfo,
      [dataIndex === 'originalContractNumber' ? 'number' : dataIndex]: getCorrectList(
        checkedList,
        dataIndex,
        isDiscount ?? false,
        isPromo ?? false,
      ),
    });
    setDateValue([null, null]);

    if (!isContractFilter && typeof getPrivateFiltersValues === 'function') {
      dispatch(
        getPrivateFiltersValues(
          {
            filterField: dataIndex,
            filterParams: checkBoxData.checkedList.join('|'),
          },
          t,
        ),
      );
    }
    activeUserTabFilter();
  };

  let filteredItems = isDate
    ? filterOptions
        .map((item) => {
          return item !== 'null' ? moment(item).format('DD.MM.YYYY') : '';
        })
        .filter((item) => item && item.indexOf(filterBy) !== -1 && item !== 'Invalid date')
    : filterOptions.filter(
        (item) =>
          item &&
          item !== 'undefined' &&
          item !== 'null' &&
          typeof item !== 'number' &&
          item.toLocaleLowerCase().indexOf(filterBy.toLocaleLowerCase()) !== -1,
      );
  if (dataIndex === 'startDate' || dataIndex === 'endDate') {
    filteredItems = filterOptions.map((stat) => moment(stat).format('DD.MM.YYYY'));
  }
  if (dataIndex === 'role') {
    filteredItems = filterOptions.map((stat) => roles[stat]);
  }

  if (dataIndex === 'action') {
    filteredItems = filterOptions.map((stat) => userActivities[stat]);
  }

  if (dataIndex === 'type') {
    filteredItems = filterOptions.map((stat) => getActionType(t)[stat] || stat);
  }
  if (
    dataIndex === 'insuranceAmount' ||
    dataIndex === 'paymentAmount' ||
    dataIndex === 'calculatedPaymentAmount' ||
    dataIndex === 'paid'
  ) {
    filteredItems = filterOptions.map((stat) => moneyFormatter(stat !== 'undefined' ? Number(stat) : null));
  }

  useEffect(() => {
    if (
      !isContractFilter &&
      privateFilterInfo &&
      privateFilterInfo[dataIndex] &&
      filteredItems.length === checkBoxData.checkedList.length
    ) {
      if (filteredItems.length) {
        setCheckBoxData({
          checkedList: filteredItems,
          checkAll: true,
          indeterminate: false,
        });
      }
    }
  }, [privateFilterInfo, filteredItems.length, checkBoxData.checkedList?.length]);
  return (
    <div className={styles.filterContainer} data-id={dataId}>
      <Card
        title={t('contract_list.filters_select')}
        bordered={false}
        extra={<span onClick={closeFilter}>&times;</span>}
      >
        <Input
          placeholder={t('contract_list.search_select')}
          prefix={<SearchOutlined style={{ color: '#A9B5BD' }} />}
          onChange={(evt) => handleSearch(evt.target.value)}
          value={filterBy}
        />
        <div className={styles.filterContainer_content}>
          <div style={{ borderBottom: '1px solid #E9E9E9' }}>
            <Checkbox
              style={{ padding: '9px 0 9px 16px' }}
              indeterminate={checkBoxData.indeterminate}
              onChange={onCheckAllChange}
              checked={checkBoxData.checkAll}
            >
              {t('contract_list.select_all_in_select')}
            </Checkbox>
          </div>
          <div className={styles.filterContainer_content_ItemsWrapper}>
            <CheckboxGroup
              options={Array.from(
                new Set(
                  getOptions(
                    dataIndex,
                    getSortedItems(dataIndex, filterOptions),
                    isDiscount ?? false,
                    isPromo ?? false,
                  ),
                ),
              )}
              value={checkBoxData.checkedList}
              onChange={handleFilterValues}
            />
          </div>
        </div>
        <div className={styles.footerFilterCard}>
          <Button type="link" data-id={`${dataId}-reset`} onClick={handleReset}>
            {t('contract_list.clean_up_select')}
          </Button>
          <Button type="link" data-id={`${dataId}-apply`} onClick={filteredContracts}>
            {t('contract_list.filter_select')}
          </Button>
        </div>
      </Card>
      {isDate && (
        <ConfigProvider locale={ua}>
          {/*  TODO front legacy */}
          {/*  @ts-ignore */}
          <RangePicker
            renderExtraFooter={() => (
              <Button type="link" onClick={setTodayDate}>
                {t('contract_list.today')}
              </Button>
            )}
            separator={''}
            allowClear={false}
            locale="ua"
            // @ts-ignore
            value={dateValue[0] ? dateValue : [moment(), moment()]}
            onChange={changeDate}
            format="YYYY-MM-DD"
          />
        </ConfigProvider>
      )}
    </div>
  );
};

export default PrivateFilter;
