import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import { object, Infer, optional, number, date, nullable, refiner } from 'utils/struct';

export const schema = object({
  insuranceProgramId: optional(nullable(number())),
  partnerId: optional(nullable(number())),
  dateFrom: refiner(nullable(date()), (value, ctx) => {
    const { dateTo } = ctx.branch[0] as Values;

    if (!value) {
      return i18n.t('schema.required');
    }

    if (dayjs(value).isAfter(dayjs().endOf('day'))) {
      return i18n.t('termination_list.dateFrom', { context: 'today' });
    }

    if (dateTo) {
      if (dayjs(value).isAfter(dayjs(dateTo))) {
        return i18n.t('termination_list.dateFrom', { context: 'dateTo' });
      }

      if (dayjs(value).isBefore(dayjs(dateTo).subtract(1, 'year'))) {
        return i18n.t('termination_list.date', { context: 'period' });
      }

      return true;
    }

    return true;
  }),
  dateTo: refiner(nullable(date()), (value, ctx) => {
    const { dateFrom } = ctx.branch[0] as Values;

    if (!value) {
      return i18n.t('schema.required');
    }

    if (dayjs(value).isAfter(dayjs().endOf('day'))) {
      return i18n.t('termination_list.dateTo', { context: 'today' });
    }

    if (dateFrom) {
      if (dayjs(value).isBefore(dayjs(dateFrom))) {
        return i18n.t('termination_list.dateTo', { context: 'dateFrom' });
      }

      if (dayjs(value).isAfter(dayjs(dateFrom).add(1, 'year'))) {
        return i18n.t('termination_list.date', { context: 'period' });
      }

      return true;
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  insuranceProgramId: null,
  partnerId: null,
  dateFrom: dayjs().startOf('month'),
  dateTo: dayjs(),
};
