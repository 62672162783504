import createInstance from './axios';
import { InsuranceProgramPartnerType } from 'types/insuranceProgramsList';
import { AxiosResponse } from 'axios';

const programsAPI = createInstance('CONTRACTS');
const configApi = createInstance('CONFIGURATION');

export const getCompanies = (): Promise<any> => {
  return programsAPI.get('/companies?partnerId=null').then((response: any) => ({
    data: response.data.map((el: any) => ({ id: el.insuranceCompanyId, name: el.name })),
  }));
};

export const getAllInsurancePrograms = (
  partnerId: string,
  insuranceProductId: string,
): Promise<
  AxiosResponse<{
    resultList: object[];
  }>
> => {
  return configApi.get(`/insurance-programs?distinct=true&page_size=-1${partnerId}${insuranceProductId}`, {
    params: {
      attributes: `id,name,code,partnerId`,
      distinct: true,
    },
  });
};

export const getInsuranceProgramPartners = (
  id: number | string,
): Promise<AxiosResponse<InsuranceProgramPartnerType[]>> => {
  return programsAPI.get(`/insurance-programs/${id}/partner-agents`);
};

export const getProgramInfo = (id: number): Promise<any> => programsAPI.get(`/insurance-programs/${id}`);
