import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import { object, Infer, optional, date, string, nullable, refiner } from 'utils/struct';

export const schema = object({
  contractStatus: optional(nullable(string())),
  contractNumber: optional(string()),
  insuranceProductCode: optional(string()),
  name: optional(string()),
  startDate: refiner(optional(nullable(date())), (value, ctx) => {
    const { endDate } = ctx.branch[0] as Values;
    if (value && dayjs(value).isAfter(dayjs().endOf('day'))) {
      return i18n.t('contract_list.dateFrom', { context: 'today' });
    }

    if (endDate) {
      if (value && dayjs(value).isAfter(dayjs(endDate))) {
        return i18n.t('contract_list.dateFrom', { context: 'dateTo' });
      }

      if (value && dayjs(value).isBefore(dayjs(endDate).subtract(1, 'year'))) {
        return i18n.t('contract_list.date', { context: 'period' });
      }

      return true;
    }

    return true;
  }),
  endDate: refiner(optional(nullable(date())), (value, ctx) => {
    const { startDate } = ctx.branch[0] as Values;

    if (value && dayjs(value).isAfter(dayjs().endOf('day'))) {
      return i18n.t('contract_list.dateTo', { context: 'today' });
    }

    if (startDate) {
      if (value && dayjs(value).isBefore(dayjs(startDate))) {
        return i18n.t('contract_list.dateTo', { context: 'dateFrom' });
      }

      if (value && dayjs(value).isAfter(dayjs(startDate).add(1, 'year'))) {
        return i18n.t('contract_list.date', { context: 'period' });
      }

      return true;
    }

    return true;
  }),
});

export type Values = Required<Infer<typeof schema>>;

export const initialValues: Values = {
  contractStatus: null,
  contractNumber: '',
  insuranceProductCode: '',
  name: '',
  startDate: null,
  endDate: null,
};
