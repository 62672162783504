export enum PropertyTypes {
  STATE = 'Державна',
  DEPARTMENTAL = 'Відомча',
  PRIVATE = 'Приватна',
}

export const PROPERTY_TYPES = ['Державна', 'Відомча', 'Приватна'];

export const FACILITY_TYPES = [
  'Вузькопрофільна клініка',
  'Стоматологія',
  'Амбулаторно-поліклінічна допомога',
  'Виклик лікаря  додому/офісу',
  'Невідкладна допомога',
  'Лабораторія',
  'Стаціонар',
  'Багатопрофільна',
];

export const CLASSES = ['A+', 'A', 'B', 'C', 'D'];

export const DEFAULT_HOURS_BEFORE_CANCELLATION = 6;

export const HOURS_BEFORE_CANCELLATION = [6, 12, 24];
