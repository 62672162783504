import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import styles from './Payments.module.less';
import { moneyFormatter } from 'utils/helpers';
import { paymentsSelector } from './selectors/selector';
import { useTranslation } from 'react-i18next';
import { useCurrency } from '../../../../hooks/useCurrency';
import { AppState } from 'store/reducer';

interface IPaymentsStatistics {
  insuranceSumCurrency?: string;
  isVZR?: boolean;
}

export const PaymentsStatistics = ({ insuranceSumCurrency, isVZR }: IPaymentsStatistics) => {
  const { statistic, total } = useSelector(paymentsSelector);
  const { expectedPaymentAmount, paymentAmount, leftToPay } = statistic;
  const { t } = useTranslation();
  const { currentCompanyCurrency } = useSelector((store: AppState) => ({
    currentCompanyCurrency: store.userLegacy.currentCompanyCurrency,
  }));

  const { currency } = useCurrency(isVZR ? 'TJS' : undefined);

  const currentCurrency = insuranceSumCurrency || currentCompanyCurrency || currency;

  const getTotalCount = () => {
    return total;
  };
  const getActivePay = () => {
    return `${moneyFormatter(paymentAmount)} ${currentCurrency}`;
  };
  const getLeftPay = () => {
    return `${moneyFormatter(leftToPay)} ${currentCurrency}`;
  };
  const getToPay = () => {
    return `${moneyFormatter(expectedPaymentAmount)} ${currentCurrency}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={classNames(styles.itemContainer, styles.sum)}>
          <div className={styles.label}>{t('contract_details.number_payments_payments_table')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{getTotalCount()}</div>
          </div>
        </div>
        <div className={classNames(styles.itemContainer, styles.active)}>
          <div className={styles.label}>{t('contract_details.amount_expected_payments_payments_table')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{getToPay()}</div>
          </div>
        </div>
        <div className={classNames(styles.itemContainer, styles.sumPayments)}>
          <div className={styles.label}>{t('contract_details.amount_payments_paid_payments_table')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{getActivePay()}</div>
          </div>
        </div>
        <div className={classNames(styles.itemContainer, styles.sum)}>
          <div className={styles.label}>{t('contract_details.balance_paid_payments_table')}</div>
          <div className={styles.values}>
            <div className={styles.value}>{getLeftPay()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
