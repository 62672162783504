import React from 'react';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row, Divider } from 'antd';

import { useRequired } from 'hooks/useRequired';
import { Button } from 'components/next/Button/Button';
import { Select } from 'components/next/Select/Select';
import { NumberField } from 'components/next/NumberField/NumberField';
import { Password } from 'components/next/Password/Password';
import { initialValues, schema, Values } from './Controlled.schema';
import { AutoCompleteGoogle } from 'components/next/AutoCompleteGoogle/AutoCompleteGoogle';
import { DefaultOptionType } from 'antd/lib/select';

interface Props {}

const Controlled = (props: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<Values>({
    mode: 'onChange',
    shouldUnregister: true,
    resolver: superstructResolver(schema),
    defaultValues: initialValues,
  });
  const { requiredFields } = useRequired(schema, watch());
  const numberAsNumber = watch('numberAsNumber');
  const numberAsString = watch('numberAsString');
  const select = watch('select');

  console.log('numberAsNumber', numberAsNumber);
  console.log('numberAsString', numberAsString);
  console.log('select', select);

  const selectOptions: DefaultOptionType[] = Array.from({ length: 5 }, (v, k) => k).map((el) => ({
    value: el,
    label: el,
  }));

  return (
    <form
      onSubmit={handleSubmit(
        (values) => console.log('values', values),
        (errors) => console.log('errors', errors),
      )}
    >
      <Divider plain orientation="left">
        Controlled
      </Divider>

      <Row gutter={[16, 40]}>
        <Col span={4}>
          <Controller
            name="password"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Password
                name={name}
                errors={errors}
                requiredFields={requiredFields}
                onChange={(v) => onChange(v)}
                value={value}
                label={'password'}
                colorBase="grey"
              />
            )}
          />
        </Col>

        <Col span={4}>
          <Controller
            name="autoCompleteGoogle"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <AutoCompleteGoogle
                name={name}
                errors={errors}
                requiredFields={requiredFields}
                onPlaceSelected={(place) => onChange(place.formatted_address)}
                onChange={(v) => onChange(v)}
                value={value}
                label={'google en lang'}
                language="en-GB"
              />
            )}
          />
        </Col>

        <Col span={4}>
          <Controller
            name="autoCompleteGoogle2"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <AutoCompleteGoogle
                name={name}
                errors={errors}
                requiredFields={requiredFields}
                onPlaceSelected={(place) => onChange(place.formatted_address)}
                onChange={(v) => onChange(v)}
                value={value}
                label={'google ua lang'}
                language="uk"
              />
            )}
          />
        </Col>

        <Col span={4}>
          <Controller
            name="select"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <Select
                name={name}
                errors={errors}
                requiredFields={requiredFields}
                onChange={(v) => onChange(v)}
                value={value}
                label="select"
                colorBase="grey"
                options={selectOptions}
                allowClear
              />
            )}
          />
        </Col>

        <Col span={4}>
          <Controller
            name="numberAsNumber"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <NumberField
                name={name}
                errors={errors}
                requiredFields={requiredFields}
                onValueChange={(v) => onChange(v.floatValue)}
                value={value}
                label="numberAsNumber"
                colorBase="grey"
                allowClear
              />
            )}
          />
        </Col>

        <Col span={4}>
          <Controller
            name="numberAsString"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <NumberField
                name={name}
                errors={errors}
                requiredFields={requiredFields}
                onValueChange={(value) => onChange(value.value)}
                value={value}
                label="numberAsString"
                colorBase="grey"
                allowClear
              />
            )}
          />
        </Col>
      </Row>

      <Row gutter={[16, 32]}>
        <Col>
          <Button htmlType="submit" type="primary">
            submit
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setValue('select', null);
              setValue('numberAsNumber', null);
              setValue('numberAsString', '');
            }}
          >
            reset
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default Controlled;
