import i18n, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Language } from 'types';
import locales from 'config/locales';
import { isLanguage } from 'utils/validators';

const resources = Object.entries(locales).reduce((acc, [key, value]) => {
  acc[key as Language] = {
    translation: value.translation,
  };

  return acc;
}, {} as Record<Language, ResourceLanguage>);

const languageRaw = localStorage.getItem('language');
const lng = isLanguage(languageRaw) ? (languageRaw as Language) : Language.en_US;

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng,
    fallbackLng: Language.en_US,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    pluralSeparator: '__',
    contextSeparator: '__',
    returnNull: false,
  })
  .then((res) => {
    localStorage.setItem('language', lng);
  })
  .catch((err) => console.error(err));

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language', lng);
});

export default i18n;
